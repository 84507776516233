//MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import {
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../common";
import AutocompleteCategoriesInput from "../../../../common/AutocompleteCategoriesInput";
import {
  countries,
  genders,
  maritalStatus,
} from "../../../../common/DropDownsData";

const ProfileInformation = ({
  values,
  edit,
  updatedProfile,
  setUpdatedProfile,
  onBlur,
  errors,
  touched,
  onChange,
  profile,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  return (
    <Grid item md={12} xs={12}>
      <Typography variant="h4" color="textPrimary">
        {t("personalInformation")}
      </Typography>
      <Box clone paddingY={2}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t(" name")}
              name="fullName"
              onChange={(e) => {
                onChange(e);
                if (e.target.value !== profile.user?.fullName) {
                  setUpdatedProfile({
                    ...updatedProfile,
                    fullName: e.target.value,
                  });
                } else {
                  let newObj = {};
                  Object.keys(updatedProfile).forEach((key) => {
                    if (key !== "fullName")
                      newObj = { ...newObj, [key]: updatedProfile[key] };
                  });
                  setUpdatedProfile(newObj);
                }
              }}
              onBlur={onBlur}
              value={values?.fullName}
              errors={touched?.fullName && errors?.fullName}
              disabled={!edit}
              id="fullName"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormSelectInput
              disabled={edit ? false : true}
              name="gender"
              isRequired
              value={values.gender}
              selectOptions={genders.map((gender) => ({
                label: isEnglish ? gender.name : gender.nameAr,
                value: gender.value,
              }))}
              fullEvent={(e) => onChange(e)}
              onChange={(e) => {
                if (e !== profile.gender) {
                  setUpdatedProfile({
                    ...updatedProfile,
                    gender: e,
                  });
                } else {
                  let newObj = {};
                  Object.keys(updatedProfile).forEach((key) => {
                    if (key !== "gender")
                      newObj = { ...newObj, [key]: updatedProfile[key] };
                  });
                  setUpdatedProfile(newObj);
                }
              }}
              label={t("gender")}
              id="gender"
              autoComplete="new-password"
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <FormSelectInput
              disabled={edit ? false : true}
              name="maritalStatus"
              value={values.maritalStatus}
              selectOptions={maritalStatus.map((status) => ({
                label: isEnglish ? status.name : status.nameAr,
                value: status.value,
              }))}
              fullEvent={(e) => onChange(e)}
              onChange={(e) => {
                if (e !== profile.maritalStatus) {
                  setUpdatedProfile({
                    ...updatedProfile,
                    maritalStatus: e,
                  });
                } else {
                  let newObj = {};
                  Object.keys(updatedProfile).forEach((key) => {
                    if (key !== "maritalStatus")
                      newObj = { ...newObj, [key]: updatedProfile[key] };
                  });
                  setUpdatedProfile(newObj);
                }
              }}
              label={t("maritalStatus")}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <DatePickerField
              isRequired
              label={t("dob")}
              name="dob"
              onChange={(e) => {
                let chosenDate = moment(e).format("YYYY-MM-DD");
                if (chosenDate !== profile.dateOfBirth) {
                  setUpdatedProfile({
                    ...updatedProfile,
                    dateOfBirth: chosenDate,
                  });
                } else {
                  let newObj = {};
                  Object.keys(updatedProfile).forEach((key) => {
                    if (key !== "dateOfBirth")
                      newObj = { ...newObj, [key]: updatedProfile[key] };
                  });
                  setUpdatedProfile(newObj);
                }
              }}
              value={
                updatedProfile.dateOfBirth
                  ? updatedProfile.dateOfBirth
                  : profile.dateOfBirth
              }
              disabled={!edit}
              disableToolbar={false}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <AutocompleteCategoriesInput
              disabled={edit ? false : true}
              name="nationality"
              isRequired
              value={
                updatedProfile["nationality"]
                  ? updatedProfile.nationality
                  : values.nationality
              }
              array={countries(t)}
              label={t("nationality")}
              onChange={(e) => {
                if (e !== profile.nationality.code) {
                  setUpdatedProfile({
                    ...updatedProfile,
                    nationality: e,
                  });
                } else {
                  let newObj = {};
                  Object.keys(updatedProfile).forEach((key) => {
                    if (key !== "nationality")
                      newObj = { ...newObj, [key]: updatedProfile[key] };
                  });
                  setUpdatedProfile(newObj);
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ProfileInformation;
