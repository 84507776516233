export const employeesStatus = [
  { value: "FULL_TIME", name: "Full Time", nameAr: "دوام كامل" },
  { value: "PART_TIME", name: "Part Time", nameAr: "دوام جزئي" },
  { value: "PENDING", name: "Pending", nameAr: "قيد الإنتظار" },
  { value: "TRIAL_PERIOD", name: "Trial Period", nameAr: "فترة تجربة" },
  { value: "CONTRACTED", name: "Contracted", nameAr: "متعاقد" },
  { value: "RESIGNED", name: "Resigned", nameAr: "استقالة" },
  { value: "LAID_OFF", name: "Terminated", nameAr: "مسرح" },
];

export const portalSections = [
  { value: "has_attendance_access", name: "Attendance", nameAr: "Attendance" },
  { value: "has_document_access", name: "Docs", nameAr: "Docs" },
  { value: "has_indemnity_access", name: "Indemnity", nameAr: "Indemnity" },
  {
    value: "has_vacations_access",
    name: "Vacation Requests Approvals",
    nameAr: "Vacation Requests Approvals",
  },
];

// export const ATTENDANCE_ACCESS = 'ATTENDANCE_ACCESS';
// export const DOCUMENTS_ACCESS  = 'DOCUMENTS_ACCESS ';
// export const INDEMNITY_ACCESS  = 'INDEMNITY_ACCESS ';
// export const VACATION_ACCESS  = 'VACATION_ACCESS ';

export const requestsStatus = [
  { value: "All", name: "All", nameAr: "الكل" },
  { value: "PENDING", name: "Pending", nameAr: "قيد الإنتظار" },
  { value: "APPROVED", name: "Approved", nameAr: "موافق" },
  { value: "DECLINED", name: "Declined", nameAr: "مرفوض" },
  { value: "CANCELLED", name: "Cancelled", nameAr: "ملغي" },
];

export const requestsKind = [
  { value: "All", name: "All", nameAr: "الكل" },
  { value: "LEAVE", name: "Leave", nameAr: "اجازة" },
  { value: "UPDATE_PROFILE", name: "Update Profile", nameAr: "تحديث بيانات" },
  { value: "RESIGNATION", name: "Resignation", nameAr: "استقالة" },
  { value: "EARLY_SALARY", name: "Early Salary", nameAr: "راتب مبكر" },
  { value: "OVERTIME", name: "Overtime", nameAr: "Overtime" },
  { value: "GENERAL", name: "General", nameAr: "عام" },
];

export const leavesKind = [
  { value: "ANNUAL", name: "Annual", nameAr: "سنوية" },
  { value: "CASUAL", name: "Casual", nameAr: "عرضي" },
  { value: "SICK", name: "Sick", nameAr: "مرضية" },
  {
    value: "UNPAID",
    name: "No Salary",
    nameAr: "بدون مرتب",
  },
  { value: "SHORT", name: "Short Leave", nameAr: "Short Leave" },
  { value: "OFFSET", name: "Offset", nameAr: "Offset" },
];

export const maritalStatus = [
  { value: "SINGLE", name: "Single", nameAr: "أعزب" },
  { value: "MARRIED", name: "Married", nameAr: "متزوج" },
  { value: "DIVORCED", name: "Divorced", nameAr: "مطلق" },
];

export const genders = [
  { value: "MALE", name: "Male", nameAr: "ذكر" },
  { value: "FEMALE", name: "Female", nameAr: "أنثى" },
];

export const countries = (t) => [
  { value: "NK", label: t("common:nonKuwait"), sort: "gcc" },
  { value: "KW", label: t("common:kuwait"), sort: "gcc" },
  { value: "AE", label: t("common:unitedArabEmirates"), sort: "gcc" },
  { value: "BH", label: t("common:bahrain"), sort: "gcc" },
  { value: "OM", label: t("common:oman"), sort: "gcc" },
  { value: "QA", label: t("common:qatar"), sort: "gcc" },
  { value: "SA", label: t("common:saudiArabia"), sort: "gcc" },
  { value: "YE", label: t("common:yemen"), sort: "gcc" },
  { value: "US", label: t("common:usa"), sort: "common" },
  { value: "EG", label: t("common:egypt"), sort: "common" },
  { value: "IN", label: t("common:india"), sort: "common" },
  { value: "JO", label: t("common:jordan"), sort: "common" },
  { value: "LB", label: t("common:lebanon"), sort: "common" },
  { value: "PH", label: t("common:philippines"), sort: "common" },
  { value: "LK", label: t("common:sriLanka"), sort: "common" },
  { value: "SY", label: t("common:syria"), sort: "common" },
  { value: "BL", label: t("common:saintBarthelemy"), sort: "other" },
  { value: "AD", label: t("common:andorra"), sort: "other" },
  { value: "AF", label: t("common:afghanistan"), sort: "other" },
  { value: "AG", label: t("common:antiguaAndBarbuda"), sort: "other" },
  { value: "AI", label: t("common:anguilla"), sort: "other" },
  { value: "AL", label: t("common:albania"), sort: "other" },
  { value: "AM", label: t("common:armenia"), sort: "other" },
  { value: "AO", label: t("common:angola"), sort: "other" },
  { value: "AQ", label: t("common:antarctica"), sort: "other" },
  { value: "AR", label: t("common:argentina"), sort: "other" },
  { value: "AS", label: t("common:americanSamoa"), sort: "other" },
  { value: "AT", label: t("common:austria"), sort: "other" },
  { value: "AU", label: t("common:australia"), sort: "other" },
  { value: "AW", label: t("common:aruba"), sort: "other" },
  { value: "AX", label: t("common:allandIslands"), sort: "other" },
  { value: "AZ", label: t("common:azerbaijan"), sort: "other" },
  { value: "BA", label: t("common:bosnia"), sort: "other" },
  { value: "BB", label: t("common:barbados"), sort: "other" },
  { value: "BD", label: t("common:bangladesh"), sort: "other" },
  { value: "BE", label: t("common:belgium"), sort: "other" },
  { value: "BF", label: t("common:burkinaFaso"), sort: "other" },
  { value: "BG", label: t("common:bulgaria"), sort: "other" },
  { value: "BI", label: t("common:burundi"), sort: "other" },
  { value: "BJ", label: t("common:benin"), sort: "other" },
  { value: "BM", label: t("common:bermuda"), sort: "other" },
  { value: "BN", label: t("common:brunei"), sort: "other" },
  { value: "BO", label: t("common:bolivia"), sort: "other" },
  { value: "BR", label: t("common:brazil"), sort: "other" },
  { value: "BS", label: t("common:bahamas"), sort: "other" },
  { value: "BT", label: t("common:bhutan"), sort: "other" },
  { value: "BV", label: t("common:bouvet"), sort: "other" },
  { value: "BW", label: t("common:botswana"), sort: "other" },
  { value: "BY", label: t("common:belarus"), sort: "other" },
  { value: "BZ", label: t("common:belize"), sort: "other" },
  { value: "CA", label: t("common:canada"), sort: "other" },
  { value: "CC", label: t("common:cocosIslands"), sort: "other" },
  { value: "CD", label: t("common:congoDR"), sort: "other" },
  { value: "CF", label: t("common:centralAfricaRepublic"), sort: "other" },
  { value: "CG", label: t("common:congoRG"), sort: "other" },
  { value: "CH", label: t("common:switzerland"), sort: "other" },
  { value: "CI", label: t("common:coteDeIvoire"), sort: "other" },
  { value: "CK", label: t("common:cookIslands"), sort: "other" },
  { value: "CL", label: t("common:chile"), sort: "other" },
  { value: "CM", label: t("common:cameroon"), sort: "other" },
  { value: "CN", label: t("common:china"), sort: "other" },
  { value: "CO", label: t("common:colombia"), sort: "other" },
  { value: "CR", label: t("common:costaRica"), sort: "other" },
  { value: "CU", label: t("common:cuba"), sort: "other" },
  { value: "CV", label: t("common:capeVerde"), sort: "other" },
  { value: "CW", label: t("common:curacao"), sort: "other" },
  { value: "CX", label: t("common:christmasIsland"), sort: "other" },
  { value: "CY", label: t("common:cyprus"), sort: "other" },
  { value: "CZ", label: t("common:czechRepublic"), sort: "other" },
  { value: "DE", label: t("common:germany"), sort: "other" },
  { value: "DJ", label: t("common:djibouti"), sort: "other" },
  { value: "DK", label: t("common:denmark"), sort: "other" },
  { value: "DM", label: t("common:dominica"), sort: "other" },
  { value: "DO", label: t("common:dominicanRepublic"), sort: "other" },
  { value: "DZ", label: t("common:algeria"), sort: "other" },
  { value: "EC", label: t("common:ecuador"), sort: "other" },
  { value: "EE", label: t("common:estonia"), sort: "other" },
  { value: "EH", label: t("common:westernSahara"), sort: "other" },
  { value: "ER", label: t("common:eritrea"), sort: "other" },
  { value: "ES", label: t("common:spain"), sort: "other" },
  { value: "ET", label: t("common:ethiopia"), sort: "other" },
  { value: "FI", label: t("common:finland"), sort: "other" },
  { value: "FJ", label: t("common:fiji"), sort: "other" },
  { value: "FK", label: t("common:falklandIslands"), sort: "other" },
  { value: "FM", label: t("common:micronesia"), sort: "other" },
  { value: "FO", label: t("common:faroeIslands"), sort: "other" },
  { value: "FR", label: t("common:france"), sort: "other" },
  { value: "GA", label: t("common:gabon"), sort: "other" },
  { value: "GB", label: t("common:uk"), sort: "other" },
  { value: "GD", label: t("common:grenada"), sort: "other" },
  { value: "GE", label: t("common:georgia"), sort: "other" },
  { value: "GF", label: t("common:frenchGuiana"), sort: "other" },
  { value: "GG", label: t("common:guernsey"), sort: "other" },
  { value: "GH", label: t("common:ghana"), sort: "other" },
  { value: "GI", label: t("common:gibraltar"), sort: "other" },
  { value: "GL", label: t("common:greenland"), sort: "other" },
  { value: "GM", label: t("common:gambia"), sort: "other" },
  { value: "GN", label: t("common:guinea"), sort: "other" },
  { value: "GP", label: t("common:guadeloupe"), sort: "other" },
  { value: "GQ", label: t("common:equatorialGuinea"), sort: "other" },
  { value: "GR", label: t("common:greece"), sort: "other" },
  {
    value: "GS",
    label: t("common:southGeorgia"),
    sort: "other",
  },
  { value: "GT", label: t("common:guatemala"), sort: "other" },
  { value: "GU", label: t("common:guam"), sort: "other" },
  { value: "GW", label: t("common:guineaBissau"), sort: "other" },
  { value: "GY", label: t("common:guyana"), sort: "other" },
  { value: "HK", label: t("common:hongKong"), sort: "other" },
  { value: "HM", label: t("common:heardIsland"), sort: "other" },
  { value: "HN", label: t("common:honduras"), sort: "other" },
  { value: "HR", label: t("common:croatia"), sort: "other" },
  { value: "HT", label: t("common:haiti"), sort: "other" },
  { value: "HU", label: t("common:hungary"), sort: "other" },
  { value: "ID", label: t("common:indonesia"), sort: "other" },
  { value: "IE", label: t("common:ireland"), sort: "other" },
  { value: "IM", label: t("common:isleOfMan"), sort: "other" },
  { value: "IO", label: t("common:britishIndianOcean"), sort: "other" },
  { value: "IQ", label: t("common:iraq"), sort: "other" },
  { value: "IR", label: t("common:iran"), sort: "other" },
  { value: "IS", label: t("common:iceland"), sort: "other" },
  { value: "IT", label: t("common:italy"), sort: "other" },
  { value: "JE", label: t("common:jersey"), sort: "other" },
  { value: "JM", label: t("common:jamaica"), sort: "other" },
  { value: "JP", label: t("common:japan"), sort: "other" },
  { value: "KE", label: t("common:kenya"), sort: "other" },
  { value: "KG", label: t("common:kyrgyzstan"), sort: "other" },
  { value: "KH", label: t("common:cambodia"), sort: "other" },
  { value: "KI", label: t("common:kiribati"), sort: "other" },
  { value: "KM", label: t("common:comoros"), sort: "other" },
  { value: "KN", label: t("common:saintKitts"), sort: "other" },
  { value: "KP", label: t("common:northKorea"), sort: "other" },
  { value: "KR", label: t("common:southKorea"), sort: "other" },
  { value: "KY", label: t("common:caymanIslands"), sort: "other" },
  { value: "KZ", label: t("common:kazakhstan"), sort: "other" },
  { value: "LA", label: t("common:laos"), sort: "other" },
  { value: "LC", label: t("common:saintLucia"), sort: "other" },
  { value: "LI", label: t("common:liechtenstein"), sort: "other" },
  { value: "LR", label: t("common:liberia"), sort: "other" },
  { value: "LS", label: t("common:lesotho"), sort: "other" },
  { value: "LT", label: t("common:lithuania"), sort: "other" },
  { value: "LU", label: t("common:luxembourg"), sort: "other" },
  { value: "LV", label: t("common:latvia"), sort: "other" },
  { value: "LY", label: t("common:libya"), sort: "other" },
  { value: "MA", label: t("common:morocco"), sort: "other" },
  { value: "MC", label: t("common:monaco"), sort: "other" },
  { value: "MD", label: t("common:moldova"), sort: "other" },
  { value: "ME", label: t("common:montenegro"), sort: "other" },
  { value: "MF", label: t("common:saintMartin"), sort: "other" },
  { value: "MG", label: t("common:madagascar"), sort: "other" },
  { value: "MH", label: t("common:marshallIslands"), sort: "other" },
  {
    value: "MK",
    label: t("common:macedonia"),
    sort: "other",
  },
  { value: "ML", label: t("common:mali"), sort: "other" },
  { value: "MM", label: t("common:myanmar"), sort: "other" },
  { value: "MN", label: t("common:mongolia"), sort: "other" },
  { value: "MO", label: t("common:macao"), sort: "other" },
  { value: "MP", label: t("common:northernMarianaIslands"), sort: "other" },
  { value: "MQ", label: t("common:martinique"), sort: "other" },
  { value: "MR", label: t("common:mauritania"), sort: "other" },
  { value: "MS", label: t("common:montserrat"), sort: "other" },
  { value: "MT", label: t("common:malta"), sort: "other" },
  { value: "MU", label: t("common:mauritius"), sort: "other" },
  { value: "MV", label: t("common:maldives"), sort: "other" },
  { value: "MW", label: t("common:malawi"), sort: "other" },
  { value: "MX", label: t("common:mexico"), sort: "other" },
  { value: "MY", label: t("common:malaysia"), sort: "other" },
  { value: "MZ", label: t("common:mozambique"), sort: "other" },
  { value: "NA", label: t("common:namibia"), sort: "other" },
  { value: "NC", label: t("common:newCaledonia"), sort: "other" },
  { value: "NE", label: t("common:niger"), sort: "other" },
  { value: "NF", label: t("common:norfolkIsland"), sort: "other" },
  { value: "NG", label: t("common:nigeria"), sort: "other" },
  { value: "NI", label: t("common:nicaragua"), sort: "other" },
  { value: "NL", label: t("common:netherlands"), sort: "other" },
  { value: "NO", label: t("common:norway"), sort: "other" },
  { value: "NP", label: t("common:nepal"), sort: "other" },
  { value: "NR", label: t("common:nauru"), sort: "other" },
  { value: "NU", label: t("common:niue"), sort: "other" },
  { value: "NZ", label: t("common:newZealand"), sort: "other" },

  { value: "PA", label: t("common:panama"), sort: "other" },
  { value: "PE", label: t("common:peru"), sort: "other" },
  { value: "PF", label: t("common:frenchPolynesia"), sort: "other" },
  { value: "PG", label: t("common:papuNewGuinea"), sort: "other" },
  { value: "PK", label: t("common:pakistan"), sort: "other" },
  { value: "PL", label: t("common:poland"), sort: "other" },
  { value: "PM", label: t("common:saintPierreMiquelon"), sort: "other" },
  { value: "PN", label: t("common:pitcairn"), sort: "other" },
  { value: "PR", label: t("common:puertoRico"), sort: "other" },
  { value: "PS", label: t("common:palestine"), sort: "other" },
  { value: "PT", label: t("common:portugal"), sort: "other" },
  { value: "PW", label: t("common:palau"), sort: "other" },
  { value: "PY", label: t("common:paraguay"), sort: "other" },
  { value: "RE", label: t("common:reunion"), sort: "other" },
  { value: "RO", label: t("common:romania"), sort: "other" },
  { value: "RS", label: t("common:serbia"), sort: "other" },
  { value: "RU", label: t("common:russia"), sort: "other" },
  { value: "RW", label: t("common:rwanda"), sort: "other" },
  { value: "SB", label: t("common:solomonIslands"), sort: "other" },
  { value: "SC", label: t("common:seychelles"), sort: "other" },
  { value: "SD", label: t("common:sudan"), sort: "other" },
  { value: "SE", label: t("common:sweden"), sort: "other" },
  { value: "SG", label: t("common:singapore"), sort: "other" },
  { value: "SH", label: t("common:saintHelena"), sort: "other" },
  { value: "SI", label: t("common:slovenia"), sort: "other" },
  { value: "SJ", label: t("common:svalbard"), sort: "other" },
  { value: "SK", label: t("common:slovakia"), sort: "other" },
  { value: "SL", label: t("common:sierraLeone"), sort: "other" },
  { value: "SM", label: t("common:sanMarino"), sort: "other" },
  { value: "SN", label: t("common:senegal"), sort: "other" },
  { value: "SO", label: t("common:somalia"), sort: "other" },
  { value: "SR", label: t("common:suriname"), sort: "other" },
  { value: "SS", label: t("common:southSudan"), sort: "other" },
  { value: "ST", label: t("common:saoTome"), sort: "other" },
  { value: "SV", label: t("common:elSalvador"), sort: "other" },
  { value: "SX", label: t("common:sintMaarten"), sort: "other" },
  { value: "SZ", label: t("common:swaziland"), sort: "other" },
  { value: "TC", label: t("common:turksAndCaicos"), sort: "other" },
  { value: "TD", label: t("common:chad"), sort: "other" },
  { value: "TF", label: t("common:frenchSouthernTerritories"), sort: "other" },
  { value: "TG", label: t("common:togo"), sort: "other" },
  { value: "TH", label: t("common:thailand"), sort: "other" },
  { value: "TJ", label: t("common:tajikistan"), sort: "other" },
  { value: "TK", label: t("common:tokelau"), sort: "other" },
  { value: "TL", label: t("common:timor"), sort: "other" },
  { value: "TM", label: t("common:turkmenistan"), sort: "other" },
  { value: "TN", label: t("common:tunisia"), sort: "other" },
  { value: "TO", label: t("common:tonga"), sort: "other" },
  { value: "TR", label: t("common:turkey"), sort: "other" },
  { value: "TT", label: t("common:trinidadAndTobago"), sort: "other" },
  { value: "TV", label: t("common:tuvalu"), sort: "other" },
  { value: "TW", label: t("common:taiwan"), sort: "other" },
  { value: "TZ", label: t("common:tazania"), sort: "other" },
  { value: "UA", label: t("common:ukraine"), sort: "other" },
  { value: "UG", label: t("common:uganda"), sort: "other" },
  { value: "UY", label: t("common:uruguay"), sort: "other" },
  { value: "UZ", label: t("common:uzbekistan"), sort: "other" },
  { value: "VA", label: t("common:vaticanCity"), sort: "other" },
  { value: "VC", label: t("common:saintVincent"), sort: "other" },
  { value: "VE", label: t("common:venezuela"), sort: "other" },
  { value: "VG", label: t("common:britishVirginIslands"), sort: "other" },
  { value: "VI", label: t("common:usVirginIslands"), sort: "other" },
  { value: "VN", label: t("common:vietnam"), sort: "other" },
  { value: "VU", label: t("common:vanuatu"), sort: "other" },
  { value: "WF", label: t("common:wallis"), sort: "other" },
  { value: "WS", label: t("common:samoa"), sort: "other" },
  { value: "XK", label: t("common:kosovo"), sort: "other" },
  { value: "YT", label: t("common:mayotte"), sort: "other" },
  { value: "ZA", label: t("common:southAfrica"), sort: "other" },
  { value: "ZM", label: t("common:zambia"), sort: "other" },
  { value: "ZW", label: t("common:zimbabwe"), sort: "other" },
];

export const months = (t) => {
  return [
    { label: t("common:january"), value: 1 },
    { label: t("common:february"), value: 2 },
    { label: t("common:march"), value: 3 },
    { label: t("common:april"), value: 4 },
    { label: t("common:may"), value: 5 },
    { label: t("common:june"), value: 6 },
    { label: t("common:july"), value: 7 },
    { label: t("common:august"), value: 8 },
    { label: t("common:september"), value: 9 },
    { label: t("common:october"), value: 10 },
    { label: t("common:november"), value: 11 },
    { label: t("common:december"), value: 12 },
  ];
};

export const days = [
  { value: 0, name: "Monday", nameAr: "دوام كامل", selected: false },
  { value: 1, name: "Tuesday", nameAr: "دوام جزئي", selected: false },
  { value: 2, name: "Wednesday", nameAr: "قيد الإنتظار", selected: false },
  { value: 3, name: "Thursday", nameAr: "فترة تجربة", selected: false },
  { value: 4, name: "Friday", nameAr: "متعاقد", selected: false },
  { value: 5, name: "Saturday", nameAr: "استقالة", selected: false },
  { value: 6, name: "Sunday", nameAr: "مصرح", selected: false },
];
export const uploadOptions = [
  { value: "Employees", name: "Employees", nameAr: "موظفين" },
  { value: "Vacations", name: "Vacations", nameAr: "اجازات" },
  {
    value: "Payroll",
    name: "Payroll Modifications",
    nameAr: "تعديلات الرواتب",
  },
];
