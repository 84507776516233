import { Box, Grid } from "@material-ui/core";
//Components
import { FormInputField, Section } from "../../../../common";

import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const Salary = ({ state, setState, handleChange }) => {
  const { t } = useTranslation(["common", "employeeAdd"]);

  return (
    <Section bgcolor="white">
      <Grid container>
        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              type="text"
              label={t("common:bankName")}
              name="bankName"
              onChange={handleChange}
              value={state.bankName}
              id="bankName"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              type="text"
              label={t("common:iban")}
              name="iban"
              onChange={handleChange}
              value={state.iban}
              id="iban"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              isRequired
              strict
              type="text"
              helperText={t("common:salaryHelper")}
              label={t("common:salary")}
              name="baseSalary"
              onChange={handleChange}
              value={state.baseSalary}
              id="baseSalary"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
      </Grid>
    </Section>
  );
};

export default Salary;
