import { styled } from "@material-ui/core/styles";
import { Grid, Container, Box } from "@material-ui/core";

export const MyGrid = styled(Grid)({
  minHeight: "76vh",
  textAlign: "center",
});
export const MySideVideo = styled(Grid)({
  backgroundImage: 'url("/images/loginSideImage.png")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  height: "100vh",
});
export const MyContainer = styled(Container)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});
export const MyBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(15.8),
  height: theme.spacing(6),
  marginTop: theme.spacing(5),
}));
