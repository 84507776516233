import React from "react";
import RequestApproverItem from "./RequestApproverItem";
import { connect } from "react-redux";

const Rules = ({ approverRules, openModal, setOpenModal }) => {
  const approverRulesList = approverRules?.map((rule) => {
    return (
      <>
        <RequestApproverItem key={rule?.id} rule={rule} />
      </>
    );
  });

  return approverRulesList;
};

const mapStateToProps = ({ company }) => ({
  approverRules: company.approverRules,
});

export default connect(mapStateToProps)(Rules);
