import React from 'react'
import {TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
//Translation
import {useTranslation} from 'react-i18next'

const MultilineInput = ({
  onChange = () => {},
  isRequired,
  label,
  rows,
  placeholder,
  ...inputProps
}) => {
  const {
    i18n: {language},
  } = useTranslation()
  const isEnglish = language === 'en'
  const useStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.primary.main,

      '& .MuiFormLabel-root': {
        color: theme.palette.primary.main,
        fontSize: '1rem',
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid #3D5E77',
      },
      '& .MuiInputLabel-formControl': {
        transformOrigin: isEnglish ? 'top left' : 'top right',
        display: 'flex',
        flexDirection: isEnglish ? 'row' : 'row-reverse',
        top: 0,
        paddingRight: isEnglish ? 0 : 4,
        left: undefined,
        right: isEnglish ? null : 0,
      },
    },
  }))

  const classes = useStyles()

  return (
    <TextField
      className={classes.root}
      fullWidth
      onChange={onChange}
      inputProps={{
        style: {textAlign: isEnglish ? 'left' : 'right'},
      }}
      label={label}
      multiline
      rows={rows}
      placeholder={placeholder}
      autoComplete="off"
    />
  )
}

export default MultilineInput
