import React, { useState } from "react";

import ExpiringDocumentsModal from "../../common/ExpiringDocumentsModal";
import HomeCard from "../../common/HomeCard";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment/moment";
import { normalizeEmployeeDocument } from "../../../helpers/normalizeEmployeeDocument";
import { useTranslation } from "react-i18next";

const EmployeeDocuments = ({ allEmployeeDocuments }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);
  const isEnglish = language === "en";

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const labels = [
    t("common:documentName"),
    t("common:documentType"),
    t("common:documentExp"),
    t("common:employeeName"),
    t("common:department"),
    t("common:position"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={(label, +i)} colSpan={2} />
  ));

  const today = moment(new Date()).format("yyyy-MM-DD");
  const threeMonthFromToday = moment(today)
    .add(3, "months")
    .format("yyyy-MM-DD");

  const data = allEmployeeDocuments
    .filter((document) => {
      return (
        document.employee.employmentStatus !== "LAID_OFF" &&
        document.employee.employmentStatus !== "RESIGNED" &&
        !moment(document.employee.leaveDate).isBefore(today) &&
        moment(document.exp).isBetween(today, threeMonthFromToday)
      );
    })
    .map((document) => normalizeEmployeeDocument(document, isEnglish));

  const rows = data.map((entry, i) => {
    return (
      <TableRow
        onClick={() => {
          setModalData(entry);
          setOpenModal(true);
        }}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.documentName}
        </RequestTableCell>
        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.documentType}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.exp}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.employeeName}
        </RequestTableCell>
        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.department}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.position}
        </RequestTableCell>
      </TableRow>
    );
  });

  return (
    <HomeCard title={t("home:documentsAboutToExpireEmployee")}>
      <StyledHomeTable header={header} body={rows} data={data} />
      <ExpiringDocumentsModal
        isEnglish={isEnglish}
        openModal={openModal}
        setOpenModal={setOpenModal}
        request={modalData}
        t={t}
      />
    </HomeCard>
  );
};

const mapStateToProps = ({ company }) => ({
  allEmployeeDocuments: company.allEmployeeDocuments,
});

export default connect(mapStateToProps)(EmployeeDocuments);
