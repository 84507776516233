import React, { useState } from "react";

//Material UI
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

//Components

import {
  AddFileButton,
  FormInputField,
  DatePickerField,
  DocumentFile,
  FormSelectInput,
} from "../../../../common";

//Date
import moment from "moment";

//Actions
import {
  createDocumentFiles,
  updateDocumentFile,
  deleteDocumentFile,
  deleteDocument,
  createEmployeeDocumentFiles,
  updateEmployeeDocumentFile,
  deleteEmployeeDocumentFile,
} from "../../../../../redux/actions";

//Redux
import { connect } from "react-redux";

//Translation
import { useTranslation } from "react-i18next";

const Document = ({
  document,
  handleBlur,
  deleteDocument,
  createEmployeeDocumentFiles,
  updateEmployeeDocumentFile,
  deleteEmployeeDocumentFile,
  documentTypes,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["companyCreate", "common"]);
  const isEnglish = language === "en";
  const [typeId, setTypeId] = useState(document.type?.id);
  const [documentState, setDocument] = useState({ ...document, typeId });
  const files = document.files.map((doc) => {
    return (
      <DocumentFile
        key={`file ${doc.id}`}
        file={doc}
        updateFile={(e) =>
          updateEmployeeDocumentFile({
            id: doc.id,
            file: e.target.files[0],
            documentId: document.id,
          })
        }
        deleteFile={() =>
          deleteEmployeeDocumentFile({
            ...doc,
            documentId: document.id,
          })
        }
      />
    );
  });

  return (
    <>
      <Box
        clone
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={12} xs={12}>
          <Typography variant="h3">{t("companyCreate:document")}</Typography>
          <IconButton onClick={() => deleteDocument(document)}>
            <Delete />
          </IconButton>
        </Grid>
      </Box>
      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          label={t("companyCreate:documentName")}
          name="documentName"
          onChange={(e) => {
            setDocument({ ...document, name: e.target.value });
          }}
          onBlur={(e) =>
            handleBlur({ ...document, name: e.target.value, typeId: typeId })
          }
          value={documentState.name}
          id="documentName"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          label={t("companyCreate:documentReferenceNumber")}
          name="documentReferenceNumber"
          onChange={(e) => {
            setDocument({ ...document, referenceNumber: e.target.value });
          }}
          onBlur={(e) =>
            handleBlur({
              ...document,
              referenceNumber: e.target.value,
              typeId: typeId,
            })
          }
          value={documentState.referenceNumber}
          id="documentReferenceNumber"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          label={t("companyCreate:documentIssueDate")}
          name="documentexp"
          onChange={(e) => {
            setDocument({
              ...document,
              typeId: typeId,
              issueDate: moment(e).format("yyyy-MM-DD"),
            });
          }}
          onBlur={(e) =>
            handleBlur({
              ...document,
              issueDate: moment(e).format("yyyy-MM-DD"),
              typeId: typeId,
            })
          }
          value={documentState.issueDate}
          disableToolbar={false}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          label={t("companyCreate:documentExpiration")}
          name="documentexp"
          onChange={(e) => {
            setDocument({
              ...document,
              exp: moment(e).format("yyyy-MM-DD"),
            });
          }}
          onBlur={(e) =>
            handleBlur({
              ...document,
              exp: moment(e).format("yyyy-MM-DD"),
              typeId: typeId,
            })
          }
          value={documentState.exp}
          disableToolbar={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormSelectInput
          // disabled={!edit}
          label={t("common:documentType")}
          name="documentType"
          selectOptions={documentTypes.map((type) => ({
            value: type.id,
            label: isEnglish ? type.name : type.nameAr,
          }))}
          onChange={(e) => {
            setTypeId(Number(e));
          }}
          onBlur={(e) =>
            handleBlur({
              ...document,
              typeId: typeId,
            })
          }
          value={typeId}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography>{t("companyCreate:attatchments")}</Typography>
      </Grid>

      <Grid item md={12} xs={12}>
        <AddFileButton
          fileId={`document_id${document.id}`}
          onChange={(e) => createEmployeeDocumentFiles([{ ...e[0] }])}
          documentId={document.id}
        />
      </Grid>
      {files}
    </>
  );
};

const mapDispatchToProps = {
  createEmployeeDocumentFiles,
  createDocumentFiles,
  updateDocumentFile,
  updateEmployeeDocumentFile,
  deleteDocumentFile,
  deleteEmployeeDocumentFile,
  deleteDocument,
};

export default connect(null, mapDispatchToProps)(Document);
