import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
  Section,
} from "../../common";
import React, { useState } from "react";
//Actions
import { updateGeneralRequest } from "../../../redux/actions";

import Field from "./Field";
import MultilineInput from "../../common/MultilineInput";
//Moment
import { connect } from "react-redux";
//Utils
import moment from "moment";
//Translation
import { useTranslation } from "react-i18next";
import LeaveFile from "../../common/LeaveFile";
import LeaveFileField from "./LeaveFileField";

const General = ({
  general,
  request,
  updateGeneralRequest,
  user,
  employeeProfile,
}) => {
  const [generalState, setGeneral] = useState({ ...general });
  const { t } = useTranslation("requests");
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [notes, setNotes] = useState("");
  const hasHrAccess =
    user.isEmployee && employeeProfile && employeeProfile.hrAccess;
  if (!general) return <></>;
  console.log("general", general);
  console.log("request", request);
  return (
    <Section>
      <Box clone bgcolor="white" borderRadius={10} marginY={10}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h3" color="primary">
              {t("GeneralDetails")}
            </Typography>
          </Grid>

          <Field label={t("effectiveDate")} value={general.date} />

          <Field label={t("reason")} value={general.reason} />

          <Field label={t("body")} value={general.body} />

          {general.document && <LeaveFileField file={general.document} />}

          {user.isEmployee && request.status === "PENDING" && (
            <Grid item md={12} xs={12}>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Box clone marginRight={1}>
                  <Button
                    onClick={() => setEditOpen(true)}
                    variant="contained"
                    color="primary"
                  >
                    <Typography>{t("edit")} </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          {(user.isCompanyOwner || hasHrAccess) && (
            <>
              {request.status === "PENDING" && (
                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box clone marginRight={1}>
                      <Button
                        onClick={() => setOpen(true)}
                        variant="contained"
                        color="primary"
                      >
                        <Typography>{t("approve")} </Typography>
                      </Button>
                    </Box>
                    <Box clone marginRight={1}>
                      <Button
                        onClick={() => setOpenDecline(true)}
                        variant="contained"
                        color="secondary"
                      >
                        <Typography>{t("decline")} </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}
              {request.status === "APPROVED" && (
                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box clone marginRight={1}>
                      <Button
                        onClick={() => setOpenDecline(true)}
                        variant="contained"
                        color="secondary"
                      >
                        <Typography>{t("cancel")} </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <CompanyStructureModal
        title={t("approveGeneral")}
        open={open}
        onClose={() => setOpen(false)}
        fadeIn={open}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid
            item
            container
            justifyContent="center"
            spacing={3}
            style={{ margin: 4 }}
          >
            <Grid item md={12}>
              <MultilineInput
                fullWidth
                multiline
                rows={2}
                label={t("notes")}
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder="optional"
              />
            </Grid>
            <Grid item md={12} xs={11}>
              <DatePickerField
                label={t("common:date")}
                helperText={t("effectiveDateHelp")}
                name="date"
                onChange={(e) => {
                  setGeneral({
                    ...generalState,
                    date: moment(e).format("yyyy-MM-DD"),
                  });
                }}
                value={new Date(generalState.date)}
                disableToolbar={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin={4}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              updateGeneralRequest(
                {
                  employeeId: request.employee.id,
                  id: request.id,
                  status: "APPROVED",
                  date: generalState.date,
                  notes,
                },
                () => setOpen(false),
              )
            }
          >
            <Typography>{t("yes")} </Typography>
          </Button>

          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            <Typography>{t("no")} </Typography>
          </Button>
        </Box>
      </CompanyStructureModal>
      <CompanyStructureModal
        open={openDecline}
        onClose={() => setOpenDecline(false)}
        fadeIn={openDecline}
        title={t("approveUpdate")}
      >
        <Grid container direction="column">
          <Grid item md={12} style={{ margin: 10 }}>
            <MultilineInput
              fullWidth
              multiline
              rows={2}
              label={t("notes")}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              placeholder="optional"
            />
          </Grid>
          <Grid item md={12} style={{ margin: 10 }}>
            <Box
              paddingY={4}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateGeneralRequest(
                    {
                      employeeId: request.employee.id,
                      id: request.id,
                      status: "DECLINED",
                      date: general.date,
                      notes,
                    },

                    () => setOpenDecline(false),
                  )
                }
              >
                <Typography>{t("yes")} </Typography>
              </Button>

              <Button
                onClick={() => setOpenDecline(false)}
                variant="contained"
                color="secondary"
              >
                <Typography>{t("no")} </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
      <CompanyStructureModal
        title={t("common:generalRequest")}
        open={editOpen}
        onClose={() => setEditOpen(false)}
        fadeIn={editOpen}
      >
        <Grid container spacing="3" justifyContent="center">
          <Grid
            item
            container
            spacing={3}
            justifyContent="center"
            style={{ margin: 4 }}
          >
            <Grid item md={12} xs={11}>
              <DatePickerField
                label={t("common:date")}
                name="date"
                onChange={(e) => {
                  setGeneral({
                    ...generalState,
                    date: moment(e).format("yyyy-MM-DD"),
                  });
                }}
                value={new Date(generalState.date)}
                disableToolbar={false}
              />
            </Grid>
            <Grid item md={12} xs={11}>
              <FormInputField
                isRequired
                label={t("reason")}
                name="reason"
                type="text"
                onBlur={() => {
                  if (generalState.reason) {
                    setEmpty(false);
                  } else {
                    setEmpty(true);
                  }
                }}
                onChange={(e) => {
                  if (generalState.reason.length < 50) {
                    setGeneral({
                      ...generalState,
                      reason: e.target.value,
                    });
                  }
                }}
                errors={empty && t("reasonEmpty")}
                value={generalState.reason}
                id="reason"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item md={12} xs={11}>
              <TextField
                label={t("body")}
                multiline
                fullWidth
                isRequired={true}
                value={generalState.body}
                onChange={(e) =>
                  setGeneral({
                    ...generalState,
                    body: e.target.value,
                  })
                }
              />
              <Box marginTop={1}>
                <LeaveFile
                  value={generalState.document}
                  setValue={(value) =>
                    setGeneral({
                      ...generalState,
                      document: value,
                    })
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} xs={11} style={{ margin: 10 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              onClick={() => {
                if (generalState.reason) {
                  updateGeneralRequest(
                    { ...generalState, id: request.id, notes },
                    () => setEditOpen(false),
                  );
                } else {
                  setEmpty(true);
                }
              }}
            >
              <Button color="primary" variant="contained">
                {t("common:submit")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setEditOpen(false)}
              >
                {t("common:cancel")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
    </Section>
  );
};

const mapStateToProps = ({ auth, employee }) => ({
  user: auth.user,
  employeeProfile: employee.profile,
});

const mapDispatchToProps = {
  updateGeneralRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
