import { Box, Button, Grid, Modal, useMediaQuery } from "@material-ui/core";
import { Send } from "@material-ui/icons/";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import AnnouncementInput from "../../../../../components/common/AnnouncementInput";
import AutocompleteInput from "../../../../../components/common/AutocompleteInput";
import MultilineInput from "../../../../../components/common/MultilineInput";
import { createAnnouncement } from "../../../../../redux/actions";
import useStyles from "./styles";
import { filterEmployees } from "./utils";

const NewAnnouncement = ({
  employees,
  departments,
  positions,
  resources,
  createAnnouncement,
  open,
  setOpen,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const history = useHistory();
  const isEnglish = language === "en";
  const classes = useStyles();
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id)
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ])
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);
  const [_resources, setResources] = useState([]);

  const employeesList = filterEmployees(employees, department, position);

  const [_employees, setEmployees] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    setDepartment([]);
    setPosition([]);
    setEmployees([]);
    setResources([]);
  };

  const handleSubmit = () => {
    if (title !== "" && message !== "") {
      let announcement = {
        title: title,
        titleAr: title,
        postDate: new Date(),
        body: message,
        bodyAr: message,
        resources: _resources.map((res) => res.value),
      };
      if (_employees.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            employees: _employees.map((emp) => emp.value),
          },
          () => {
            handleClose();
            history.push("/announcements");
          }
        );
      } else if (position.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            positions: position.map((pos) => pos.value),
          },
          () => {
            handleClose();
            history.push("/announcements");
          }
        );
      } else if (department.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            departments: department.map((dep) => dep.value),
          },
          () => {
            handleClose();
            history.push("/announcements");
          }
        );
      }
    }
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Grid container>
          <Box
            width={matchesXS ? "85%" : "60%"}
            height={matchesXS ? "85%" : "80%"}
            top={matchesXS ? "5%" : "10%"}
            left={matchesXS ? "5%" : "20%"}
            bgcolor="white"
            position="absolute"
            borderRadius={10}
            overflow="scroll"
          >
            <Box clone padding={6}>
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <AnnouncementInput
                    label={t("title")}
                    value={title}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setTitle(e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <MultilineInput
                    label={t("announcement")}
                    placeholder={t("enterAnnouncement")}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={department}
                    options={departments.map((dep) => ({
                      label: isEnglish ? dep.name : dep.nameAr,
                      value: dep.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setDepartment(e)}
                    label={t("department")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    multiple={true}
                    value={position}
                    options={_positions().map((pos) => ({
                      label: isEnglish ? pos.name : pos.nameAr,
                      value: pos.id,
                    }))}
                    onChange={(e) => setPosition(e)}
                    label={t("position")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={_employees}
                    options={employeesList.map((emp) => ({
                      label: emp.user ? emp.user?.fullName : emp?.fullName,
                      value: emp.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setEmployees(e)}
                    label={t("employees")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={_resources}
                    options={resources.map((res) => ({
                      label: isEnglish ? res.name : res.nameAr,
                      value: res.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setResources(e)}
                    label={t("resources")}
                  />
                </Grid>

                <Grid item md={12} sm={12}>
                  <Box
                    clone
                    paddingTop={
                      matchesXS
                        ? 10
                        : matchesSM
                        ? 8
                        : matchesMD
                        ? 0
                        : matchesLG
                        ? 0
                        : 15
                    }
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={
                        isEnglish
                          ? "flex-end"
                          : isEnglish && matchesXS
                          ? "center"
                          : "flex-start"
                      }
                      spacing={2}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        {isEnglish && (
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.cancelButton}
                              onClick={handleClose}
                            >
                              {t("cancel")}
                            </Button>
                          </Grid>
                        )}
                        <Grid item>
                          <Button
                            onClick={handleSubmit}
                            variant="contained"
                            className={classes.sendButton}
                            startIcon={
                              !isEnglish && (
                                <Send
                                  style={{
                                    transform: "scaleX(-1)",
                                  }}
                                />
                              )
                            }
                            endIcon={isEnglish && <Send />}
                          >
                            {t("send")}
                          </Button>
                        </Grid>
                        {!isEnglish && (
                          <Grid item>
                            <Button
                              variant="contained"
                              className={classes.cancelButton}
                              onClick={handleClose}
                            >
                              {t("cancel")}
                            </Button>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({ company, employees }) => ({
  resources: company.resources,
  departments: company.departments,
  employees: employees.employees,
  positions: company.positions,
});

const mapDispatchToProps = {
  createAnnouncement,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAnnouncement);
