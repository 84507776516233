import React, { useState } from "react";

import DepartmentAccordion from "../../../../common/DepartmentAccordion";
import DepartmentEmployees from "./Avatars/DepartmentEmployees";
import DepartmentMenu from "./DepartmentMenu";
import { Link } from "react-router-dom";
import { MoreVert } from "@material-ui/icons";
import Positions from "./Positions";
import { Typography } from "@material-ui/core";
import useStyles from "../../styles";
import { useTranslation } from "react-i18next";

const Department = ({ department }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  const classes = useStyles();

  const [openModal, setOpenModal] = useState({
    edit: false,
    add: false,
  });
  const [anchorEl, setAnchorEl] = useState({
    department: null,
    employees: null,
  });

  const openDepartment = Boolean(anchorEl.department);

  const openDepartmentActions = (event) => {
    setAnchorEl({ ...anchorEl, department: event.currentTarget });
  };

  const closeDepartmentActions = () => {
    setAnchorEl({ ...anchorEl, department: null });
  };

  const postionsInDepartment =
    department.positions.length && department.positions.length;
  const employeesInDepartment =
    department.positions.length &&
    department.positions
      .map((position, i) => position.employees.length)
      .reduce((a, b) => a + b);

  return (
    <>
      <DepartmentAccordion
        title={
          <Typography
            component={Link}
            color="primary"
            variant="subtitle2"
            to={`/employeesList/${department.id}`}
            className={classes.menuButton}
          >
            {isEnglish ? department.name : department.nameAr}
          </Typography>
        }
        employeeAvatars={
          <DepartmentEmployees
            department={department}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        }
        description={`${employeesInDepartment} ${t(
          "common:employees"
        )} ${postionsInDepartment} ${t("common:positions")}`}
        secondaryAction={
          <MoreVert onClick={openDepartmentActions} color="primary" />
        }
      >
        <Positions department={department} />
      </DepartmentAccordion>

      <DepartmentMenu
        department={department}
        classes={classes}
        openDepartment={openDepartment}
        idDepartment={department.id}
        anchorEl={anchorEl}
        openModal={openModal}
        setOpenModal={setOpenModal}
        closeDepartmentActions={closeDepartmentActions}
      />
    </>
  );
};

export default Department;
