import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";

//MaterialUI

const DonutChart = ({ radius, labels, label }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const dataMock = [
    {
      title: labels[0].label,
      value: labels[0].value,
      color: "#4071F2",
    },
    {
      title: labels[1].label,
      value: labels[1].value,
      color: "#69C895",
    },
  ];

  let total = 0.0;
  dataMock.forEach((data) => (total += data.value));

  return (
    <PieChart
      label={label}
      labelStyle={(index) => ({
        fill: dataMock[index].color,
        fontSize: "5px",
        fontFamily: "sans-serif",
      })}
      labelPosition={isEnglish ? 115 : -115}
      radius={radius}
      background="#E3F0FD"
      totalValue={total}
      startAngle={150}
      lineWidth={40}
      data={dataMock}
    />
  );
};

export default DonutChart;
