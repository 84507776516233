import CompanyDocumentForm from "./CompanyDocumentForm";
import EmployeeProfileDocumentForm from "./EmployeeProfileDocumentForm";

const Form = ({
  forCompanyPage,
  modifiedDocument,
  setModifiedDocument,
  modifiedFiles,
  setModifiedFiles,
  edit,
}) => {
  const CurrentForm = forCompanyPage ? (
    <CompanyDocumentForm
      modifiedDocument={modifiedDocument}
      setModifiedDocument={setModifiedDocument}
      modifiedFiles={modifiedFiles}
      setModifiedFiles={setModifiedFiles}
      edit={edit}
    />
  ) : (
    <EmployeeProfileDocumentForm
      modifiedDocument={modifiedDocument}
      setModifiedDocument={setModifiedDocument}
      modifiedFiles={modifiedFiles}
      setModifiedFiles={setModifiedFiles}
      edit={edit}
    />
  );

  return CurrentForm;
};

export default Form;
