//MaterialUI
import { Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import React, { useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
//Redux
import { connect } from "react-redux";
import { useHistory } from "react-router/cjs/react-router.min";

import { verifyAccount } from "../../../../redux/actions";
import { CustomTabPanel } from "../../../common";
//Validators
import schema from "../Schema";
// import { Attendances } from "../../../../redesign/components/Employee";
import Attendance from "./Attendance";
import Documents from "./Documents";
//Components
import File from "./File";
import Password from "./Password";

const EmployeeDashboardProfile = ({ profile, user, verifyAccount }) => {
  ReactGA.pageview(window.location.pathname);
  const { t } = useTranslation("employeeDashboard");
  const history = useHistory();

  const [alert, setAlert] = useState("");

  const [value, setValue] = useState(0);

  const tabsLabel = [
    t("file"),
    t("documents"),
    t("companyProfile:password"),
    t("attendance"),
  ];

  const tabs = tabsLabel.map((label, idx) => (
    <Tab
      key={label}
      label={label}
      style={{
        color: value === idx ? "#778ea0" : "#A9B4C4",
        fontWeight: value === idx ? "bold" : "",
      }}
    />
  ));

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    return event;
  };

  if (!profile)
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        height={"100vh"}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#fff"}
          height={667}
          width={375}
        />
      </Box>
    );

  return (
    <Box clone paddingY={6} maxHeight={"100%"}>
      <Grid container justifyContent="flex-start">
        <Box clone paddingX={4} display="flex" flexDirection="row">
          <Tabs value={value} onChange={handleTabChange}>
            {tabs}
          </Tabs>
        </Box>

        <Formik
          initialValues={{
            fullName: profile.user?.fullName,
            gender: profile.gender,
            email: profile.user.email,
            maritalStatus: profile.maritalStatus,
            dob: profile.dateOfBirth,
            phoneNumber: profile.phoneNumber,
            nationality: profile.nationality ? profile.nationality.code : "NK",
            country: profile.address.country?.code,
            address1: profile.address.address1,
            address2: profile.address.address2,
            cityOrTown: profile.address.cityOrTown,
            postcode: profile.address.postcode,
            zipCode: profile.address.zipCode,
            stateOrProvince: profile.address.stateOrProvince,
            paciNumber: profile.address.paciNumber,
            pocName: profile.poc.name,
            pocEmail: profile.poc.email,
            pocNumber: profile.poc.number,
            pocCountry: profile.poc.address.country?.code,
            pocAddress1: profile.poc.address.address1,
            pocaddress2: profile.poc.address.address2,
            pocCityOrTown: profile.poc.address.cityOrTown,
            pocPostcode: profile.poc.address.postcode,
            pocZipCode: profile.poc.address.zipCode,
            pocStateOrProvince: profile.poc.address.stateOrProvince,
            pocPaciNumber: profile.poc.address.paciNumber,
          }}
          validateOnBlur
          validationSchema={schema}
        >
          {({ values, errors, handleChange, handleBlur, touched }) => (
            <Box clone>
              <Grid item md={11} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  bgcolor="white"
                  borderRadius={10}
                >
                  {alert.length > 0 && (
                    <Grid item md={12} xs={12}>
                      <Alert severity="success" onClick={() => setAlert("")}>
                        {alert}
                      </Alert>
                    </Grid>
                  )}
                  {!user.verified && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      paddingX={3}
                      paddingTop={3}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          verifyAccount({ email: user.email }, history, () =>
                            setAlert(t("verifyAccountEmail"))
                          )
                        }
                      >
                        {`${t("verifyAccount")} : ${user.email}`}
                      </Button>
                    </Box>
                  )}
                  <CustomTabPanel value={value} index={0}>
                    <File
                      touched={touched}
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    <Documents />
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={2}>
                    <Password />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    {/* <Attendances /> */}
                    <Attendance />
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Box>
          )}
        </Formik>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ employee, auth }) => ({
  profile: employee.profile,
  user: auth.user,
});

const mapDispatchToProps = {
  verifyAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDashboardProfile);
