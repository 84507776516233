//Material Ui
import { Box, Card, Grid } from "@material-ui/core";

//Components
import FormFileField from "./FormFileField";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";
//Utils
import { validURL } from "./utils";

const DocumentFile = ({ file, updateFile, deleteFile, edit = true }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("companyProfile");
  const isEnglish = language === "en";

  const getPlaceHolder = () => {
    if (file.file) {
      if (isEnglish && validURL(file.file)) {
        return file.file.split("/").pop();
      } else {
        return file.file.split("/").pop();
      }
    } else {
      return t("pleaseSelectFile");
    }
  };

  return (
    <Box clone display="flex" padding={2}>
      <Grid item md={3} xs={6}>
        <Box
          clone
          display="flex"
          borderRadius={10}
          justifyContent="center"
          alignItems="center"
          padding={3}
          width={"100%"}
        >
          <Card>
            <FormFileField
              isRequired
              fileId={`file ${file.id}`}
              file={file.file}
              placeholder={getPlaceHolder()}
              label={t("hi")}
              disabled={!edit}
              name="documentName"
              onChange={updateFile}
              deleteFile={deleteFile}
              style={{ display: "none" }}
            />
          </Card>
        </Box>
      </Grid>
    </Box>
  );
};

export default DocumentFile;
