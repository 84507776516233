import { Grid } from "@material-ui/core";
import PortalAccess from "./PortalAccess";
import React from "react";
import RequestApprovals from "./RequestApprovals";
import RequestsAccess from "./RequestsAccess";

const AccessAndPermissions = () => {
  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <RequestsAccess />
      </Grid>
      <Grid item lg={6} xs={12}>
        <RequestApprovals />
      </Grid>
      <Grid item lg={6} xs={12}>
        <PortalAccess />
      </Grid>
    </Grid>
  );
};

export default AccessAndPermissions;
