import { Box, Grid, Hidden } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Redirect } from "react-router";
// Actions
import { resetErrors } from "../../../redux/actions";
import Navbar from "../../Navbar";
// Components
import Form from "./form";
import SideVideo from "./SideVideo";

export const Login = ({ user, resetErrors }) => {
  ReactGA.pageview(window.location.pathname);
  useEffect(() => {
    resetErrors();
    return;
  }, [resetErrors]);
  if (user) return <Redirect to="/" />;

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Hidden mdDown>
        <Grid item md={6}>
          <SideVideo />
        </Grid>
      </Hidden>
      <Box clone height={"100%"}>
        <Grid item md={6} xs={12}>
          <Navbar />
          <Box clone minHeight={"70vh"}>
            <Grid container justifyContent="center" alignContent="center">
              <Grid item md={5} xs={10}>
                <Form />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

const mapDispatchToProps = { resetErrors };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
