import { Avatar, Box, ButtonBase } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";

import AccountPopover from "./AccountPopover";

const AccountButton = ({ user, company }) => {
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopoverOpen = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpenPopover(false);
  }, []);

  const logo = user?.isEmployee
    ? user.employee?.image ?? ""
    : company?.logo ?? "";

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handlePopoverOpen}
        ref={anchorRef}
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 60,
          width: 60,
          borderRadius: "50%",
        }}
      >
        <Avatar
          style={{
            height: 40,
            width: 40,
          }}
          src={logo}
        >
          <Person />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handlePopoverClose}
        open={openPopover}
      />
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  company: company.profile,
});

export default connect(mapStateToProps)(AccountButton);
