import { Box, Paper, Popover } from "@material-ui/core";

//Components
import { CompanyStructureModal } from "../../../../common";
import LocationForm from "./LocationForm";
import React from "react";
import StyledListItem from "../../../../common/StyledListItem";
import StyledMenuUserItem from "../../../../common/StyledMenuItem";
import { connect } from "react-redux";
import { deleteDepartment } from "../../../../../redux/actions/structure";
import { makeStyles } from "@material-ui/core/styles";
//Translation
import { useTranslation } from "react-i18next";

const LocationMenu = ({
  location,
  openLocation,
  idLocation,
  anchorEl,
  openModal,
  setOpenModal,
  closeLocationActions,
  deleteDepartment,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const openEditLocationModal = () => {
    setOpenModal(true);
  };

  const closeEditLocationModal = () => {
    setOpenModal(false);
  };

  const useStyles = makeStyles((theme) => ({
    popover: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const classes = useStyles();
  //   let employees = [];
  //   department.positions.forEach(
  //     (position) => (employees = [...employees, ...position.employees])
  //   );
  return (
    <>
      <Popover
        classes={{ paper: classes.popover }}
        idDepartment={idLocation}
        open={openLocation}
        anchorEl={anchorEl.location}
        onClose={closeLocationActions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.popover}>
          <StyledMenuUserItem>
            <StyledListItem
              onClick={openEditLocationModal}
              title={t("companyProfile:editLocation")}
            />
          </StyledMenuUserItem>

          {/* <StyledMenuUserItem>
            <StyledListItem
              disabled={employees.length}
              onClick={() => deleteDepartment({ id: department.id })}
              title={t("companyProfile:deleteDepartment")}
            />
          </StyledMenuUserItem> */}
        </Paper>
      </Popover>
      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          height="350px"
          open={openModal}
          onClose={closeEditLocationModal}
          fadeIn={openModal}
          title={t("companyProfile:editLocation")}
        >
          <LocationForm setOpen={setOpenModal} location={location} />
        </CompanyStructureModal>
      </Box>
    </>
  );
};

const mapDispatchToProps = {
  deleteDepartment,
};

export default connect(null, mapDispatchToProps)(LocationMenu);
