import { Button, Card, CardContent, CardHeader } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import LocationItem from "./LocationItem";
import LocationsModal from "./LocationsModal";

const Locations = ({ locations }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [modifiedLocation, setModifiedLocation] = useState({
    name: "",
    nameAr: "",
    latitude: "",
    longitude: "",
  });

  const locationsList = locations.map((location, idx) => {
    return (
      <LocationItem
        key={location.id}
        location={location}
        setModifiedLocation={setModifiedLocation}
        setOpen={setOpen}
        setSelectedLocation={setSelectedLocation}
        isLast={idx === locations.length - 1}
      />
    );
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t("locations")}
          action={
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              {t("companyProfile:addLocation")}
            </Button>
          }
        />
        <CardContent>{locationsList}</CardContent>
      </Card>

      <LocationsModal
        location={selectedLocation}
        open={open}
        setOpen={setOpen}
        modifiedLocation={modifiedLocation}
        setModifiedLocation={setModifiedLocation}
      />
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  locations: company.locations,
});

export default connect(mapStateToProps)(Locations);
