//Material-UI
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core/";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getComparator, stableSort } from "../../../helpers/EmployeeSorting";
import {
  LoadingSpinnerComponent,
  PageHeader,
  TablePagination,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
  FormSelectInput,
  SearchInput,
} from "../../common";
import { employeesStatus } from "../../common/DropDownsData";
import DepositForm from "./DepositForm";
//Components
import EmployeeItem from "./EmployeeItem";
//Styles
import useStyles from "./styles";
//Data & Utils
import { fetchEmployeesNextDeposit } from "../../../redux/actions";
import { filterEmployees } from "./utils";

const Deposits = ({ departments, positions, employeesLoading, locations }) => {
  ReactGA.pageview(window.location.pathname);

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "breadcrumbs"]);
  const isEnglish = language === "en";
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const [order, setOrder] = useState();

  const [orderBy, setOrderBy] = useState();

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [alert, setAlert] = useState("");

  const openDepositModal = () => {
    setOpen(true);
  };

  const closeDepositModal = () => {
    setOpen(false);
  };

  const deposited = () => {
    setAlert(t("depositCreated"));
    setSelected([]);
    setTimeout(() => setAlert(""), 5000);
  };

  const _departments = [
    { id: "All", name: "All", nameAr: "الكل" },
    ...departments,
  ];

  const _locations = [{ id: "All", name: "All", nameAr: "الكل" }, ...locations];
  const [location, setLocation] = useState("All");

  const [department, setDepartment] = useState("All");

  const _positions = () => {
    let foundDepartment = departments.find((dep) => dep.id === department);
    if (foundDepartment) {
      return [
        { id: "All", name: "All", nameAr: "الكل" },
        ...foundDepartment.positions,
      ];
    }
    return [{ id: "All", name: "All", nameAr: "الكل" }, ...positions];
  };

  const _status = [
    { value: "All", name: "All", nameAr: "الكل" },
    ...employeesStatus,
  ];

  const [status, setStatus] = useState("All");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [position, setPosition] = useState("All");
  const [query, setQuery] = useState("");

  const columns = [
    { id: 1, label: t("employeeID") },
    { id: 2, label: t("employeeName") },
    { id: 3, label: t("department") },
    { id: 4, label: t("position") },
    { id: 5, label: t("status") },
    { id: 6, label: t("lastUpdated") },
    { id: 7, label: t("netSalary") },
    { id: 8, label: t("location") },
  ];

  const handleSelectAllClick = () => {
    if (selected.length === filteredEmployees.length) {
      setSelected([]);
    } else {
      const newSelecteds = filteredEmployees;
      setSelected(newSelecteds);
    }
  };

  const handleClick = (_event, employeeId) => {
    let employee = selected.find((id) => id === employeeId);
    if (employee) setSelected(selected.filter((id) => id !== employeeId));
    else setSelected([...selected, employeeId]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (employee) => selected.indexOf(employee) !== -1;
  const [employees, setEmployees] = useState([]);
  const [nextDeposit, setNextDeposit] = useState([]);
  const dispatch = useDispatch();
  const getEmployees = async () => {
    dispatch(fetchEmployeesNextDeposit()).then((res) => setNextDeposit(res));
    return nextDeposit.map((employee) => {
      return {
        id: employee.employee?.id,
        user: employee.employee?.user
          ? {
              fullName: employee.employee?.user?.fullName,
            }
          : null,
        fullName: employee.employee?.fullName,
        baseSalary: employee.employee?.baseSalary,
        lastUpdated: employee.employee.lastUpdated,
        leaveDate: employee.employee?.leaveDate,
        department: employee.employee?.department
          ? employee.employee?.department
          : null,
        position: employee.employee?.position
          ? employee.employee?.position
          : null,
        location: employee.employee?.location
          ? employee.employee?.location
          : null,
        locations: employee.employee?.locations
          ? employee.employee?.locations
          : null,
        employmentStatus: employee.employee?.employmentStatus,
        netSalary: employee.netSalary,
        totalNetSalary: employee.totalNetSalary,
        nextSalaryDeposit: {
          salaryModifications: employee.salaryModifications
            ? employee.salaryModifications
            : [],
          recurringSalaryModifications: employee.recurringSalaryModifications
            ? employee.recurringSalaryModifications
            : [],
          overtimeCredits: employee.overtimeCredits
            ? employee.overtimeCredits
            : [],
        },
      };
    });
  };
  useEffect(() => {
    const getEmployeesData = async () => {
      const employeesData = await getEmployees();
      setEmployees(employeesData);
    };

    if (nextDeposit) {
      getEmployeesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextDeposit]);

  console.log("employees", employees);

  const filteredEmployees = filterEmployees(
    employees,
    department,
    position,
    status,
    query,
    location,
  ).filter((employee) => employee.employmentStatus !== "RESIGNED");

  const listOfEmployees = stableSort(
    filteredEmployees,
    getComparator(order, orderBy),
  )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((employee, i) => {
      const isItemSelected = isSelected(employee);
      const labelId = `enhanced-table-checkbox-${i}`;
      return (
        <EmployeeItem
          handleClick={handleClick}
          isItemSelected={isItemSelected}
          labelId={labelId}
          employeesStatus={employeesStatus}
          employee={employee}
          key={
            employee.user
              ? employee.user?.fullName + employee.id
              : employee?.fullName + employee.id
          }
        />
      );
    });

  let total = 0.0;
  selected.forEach((employee) => {
    total += +employee.netSalary;
    // employee?.nextSalaryDeposit?.salaryModifications.forEach(
    //   (mod) => (total += +mod.amount)
    // );
    // employee?.nextSalaryDeposit?.recurringSalaryModifications.forEach(
    //   (mod) => (total += +mod.amount)
    // );
  });

  const onChangeDepartment = (e) => {
    setDepartment(e);
    setSelected([]);
    setPage(0);
  };

  const onChangePosition = (e) => {
    setPosition(e);
    setSelected([]);
    setPage(0);
  };
  const onChangeStatus = (e) => {
    setStatus(e);
    setSelected([]);
    setPage(0);
  };

  const onChangeLocation = (e) => {
    setLocation(e);
    setSelected([]);
    setPage(0);
  };

  const onSearch = (e) => {
    setQuery(e.target.value);
    setSelected([]);
  };

  useEffect(() => {
    let searchParamsFromLocalStorage = localStorage
      ?.getItem("searchParams")
      ?.split(",");

    if (
      history?.location?.state &&
      history?.location?.state?.from === "/employeesList"
    ) {
      let searchParams = history?.location?.state?.search?.split("&");
      localStorage.setItem("searchParams", searchParams);

      let _order =
        searchParams[
          searchParams?.findIndex((param) => param?.startsWith("order"))
        ]?.split("=")[1];

      let _orderBy =
        searchParams[
          searchParams?.findIndex((param) => param?.startsWith("orderBy"))
        ]?.split("=")[1];

      params.set("order", _order);

      params.set("orderBy", _orderBy);

      setOrder(_order);
      setOrderBy(_orderBy);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`,
      );
    }
    if (searchParamsFromLocalStorage) {
      let _order =
        searchParamsFromLocalStorage[
          searchParamsFromLocalStorage?.findIndex((param) =>
            param?.startsWith("order"),
          )
        ]?.split("=")[1];

      let _orderBy =
        searchParamsFromLocalStorage[
          searchParamsFromLocalStorage?.findIndex((param) =>
            param?.startsWith("orderBy"),
          )
        ]?.split("=")[1];

      params.set("order", _order);

      params.set("orderBy", _orderBy);

      setOrder(_order);
      setOrderBy(_orderBy);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.deposits.name}`),
      isLast: true,
    },
  ];

  return (
    <Box>
      <Grid
        container
        justifyContent={matchesMD ? "center" : "flex-start"}
        spacing={4}
      >
        <Grid item md={11} xs={12}>
          <Grid
            container
            justifyContent={matchesMD ? "center" : "flex-start"}
            spacing={4}
          >
            {alert && (
              <Grid item md={12} xs={12}>
                <Alert severity="success">{alert}</Alert>
              </Grid>
            )}
            <Grid item md={12} xs={9}>
              <PageHeader
                paths={navigationPaths}
                title={t(`breadcrumbs:${paths.deposits.header}`)}
              />
            </Grid>
            <Grid item md={12} xs={9}>
              <Box clone paddingY={1}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <FormSelectInput
                      isRequired={false}
                      value={department}
                      selectOptions={_departments.map((dep) => ({
                        label: isEnglish ? dep.name : dep.nameAr,
                        value: dep.id,
                      }))}
                      onChange={onChangeDepartment}
                      label={t("department")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormSelectInput
                      isRequired={false}
                      value={position}
                      selectOptions={_positions().map((pos) => ({
                        label: isEnglish ? pos.name : pos.nameAr,
                        value: pos.id,
                      }))}
                      onChange={onChangePosition}
                      label={t("position")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormSelectInput
                      isRequired={false}
                      value={status}
                      selectOptions={_status.map((stat) => ({
                        label: isEnglish ? stat.name : stat.nameAr,
                        value: stat.value,
                      }))}
                      onChange={onChangeStatus}
                      label={t("status")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormSelectInput
                      isRequired={false}
                      value={location}
                      selectOptions={_locations.map((loc) => {
                        return {
                          label: isEnglish ? loc.name : loc.nameAr,
                          value: loc.id,
                        };
                      })}
                      onChange={onChangeLocation}
                      label={t("location")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <SearchInput
                      onChange={onSearch}
                      placeholder={t("search")}
                      position="start"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} xs={10}>
              <Box clone bgcolor="white" borderRadius={15}>
                <TableContainer>
                  {employees.length === 0 ? (
                    <LoadingSpinnerComponent />
                  ) : (
                    <>
                      <Table>
                        <TableHead>
                          <CustomTableRow>
                            {filteredEmployees.length > 0 ? (
                              <CustomTableCell padding="checkbox">
                                <Button
                                  onClick={handleSelectAllClick}
                                  variant="contained"
                                  color={
                                    selected.length === filteredEmployees.length
                                      ? "secondary"
                                      : "primary"
                                  }
                                >
                                  {selected.length === filteredEmployees.length
                                    ? t("deselectAll")
                                    : t("selectAll")}
                                </Button>
                              </CustomTableCell>
                            ) : null}
                            {columns.map((column) => (
                              <CustomTableCell key={column.id}>
                                {column.label}
                              </CustomTableCell>
                            ))}
                          </CustomTableRow>
                        </TableHead>
                        <TableBody>{listOfEmployees}</TableBody>
                      </Table>
                    </>
                  )}
                </TableContainer>
              </Box>
              <Toolbar>
                {selected.length > 0 ? (
                  <>
                    <Box clone paddingRight={3}>
                      <Grid item>
                        <Button
                          variant="contained"
                          component="div"
                          onClick={openDepositModal}
                          className={classes.depositButton}
                        >
                          {t("deposit")}
                        </Button>
                      </Grid>
                    </Box>
                    <Box clone p={2}>
                      <Typography>
                        {" "}
                        {t("total")} {total} KWD{" "}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </Toolbar>

              <Box bgcolor="#F6F8FA">
                <CompanyStructureModal
                  open={open}
                  onClose={closeDepositModal}
                  fadeIn={open}
                  title={`${t("depositSalaryFor")} ${selected.length} ${t(
                    "employeesObject",
                  )}`}
                >
                  <DepositForm
                    total={total}
                    closeModal={closeDepositModal}
                    deposited={deposited}
                    selected={selected}
                  />
                </CompanyStructureModal>
              </Box>
              <TablePagination
                count={filteredEmployees.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company, employees }) => ({
  departments: company.departments,
  employees: employees.employees,
  positions: company.positions,
  employeesLoading: employees.employeesLoading,
  locations: company.locations,
});
export default connect(mapStateToProps)(Deposits);
