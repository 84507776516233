import { Box, Typography } from "@material-ui/core";

import React from "react";

const PageTitle = ({ title }) => {
  return (
    <Box paddingY={1}>
      <Typography variant="h3">{title}</Typography>
    </Box>
  );
};

export default PageTitle;
