//Material UI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";

import {
  LoadingSpinnerComponent,
  PageHeader,
  TablePagination,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
import { fetchHypotheticalIndemnities } from "../../../redux/actions";
import { CustomTableRow, SearchInput } from "../../common";
import AutocompleteInput from "../../common/AutocompleteInput";
import { employeesStatus } from "../../common/DropDownsData";
//Components
import IndemnityEmployeeItem from "./IndemnityEmployeeItem";
//Data & Utils
import { filterEmployees } from "./utils";

const HypotheticalIndemnities = ({
  departments,
  hypotheticalIndemnities,
  hypotheticalIndemnitiesLoading,
  fetchHypotheticalIndemnities,
  positions,
}) => {
  ReactGA.pageview(window.location.pathname);
  const employees = hypotheticalIndemnities.map((indemnity) => ({
    ...indemnity.employee,
    indemnity: {
      resignation: indemnity.resignation,
      termination: indemnity.termination,
      accrual: indemnity.accrual,
    },
  }));

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "breadcrumbs"]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");
  const isEnglish = language === "en";
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id),
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ]),
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState([]);

  useEffect(() => {
    fetchHypotheticalIndemnities();
  }, [fetchHypotheticalIndemnities]);

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "employeeId":
        if (Number(b.id) < Number(a.id)) {
          return -1;
        }
        if (Number(b.id) > Number(a.id)) {
          return 1;
        }
        return 0;
      case "employeeName":
        const nameB = b.user ? b.user?.fullName : b?.fullName;
        const nameA = a.user ? a.user?.fullName : a?.fullName;
        if (nameB < nameA) {
          return -1;
        }
        if (nameB > nameA) {
          return 1;
        }
        return 0;
      case "department":
        if (
          b.department.name < a.department.name ||
          b.department.nameAr < a.department.nameAr
        ) {
          return -1;
        }
        if (
          b.department.name > a.department.name ||
          b.department.nameAr > a.department.nameAr
        ) {
          return 1;
        }
        return 0;
      case "position":
        if (
          b.position.name < a.position.name ||
          b.position.nameAr < a.position.nameAr
        ) {
          return -1;
        }
        if (
          b.position.name > a.position.name ||
          b.position.nameAr > a.position.nameAr
        ) {
          return 1;
        }
        return 0;
      case "status":
        if (b.employmentStatus < a.employmentStatus) {
          return -1;
        }
        if (b.employmentStatus > a.employmentStatus) {
          return 1;
        }
        return 0;
      case "termination":
        if (b.indemnity.termination < a.indemnity.termination) {
          return -1;
        }
        if (b.indemnity.termination > a.indemnity.termination) {
          return 1;
        }
        return 0;
      case "resignation":
        if (b.indemnity.resignation < a.indemnity.resignation) {
          return -1;
        }
        if (b.indemnity.resignation > a.indemnity.resignation) {
          return 1;
        }
        return 0;
      case "accrual":
        if (b.indemnity.accrual.vacation < a.indemnity.accrual.vacation) {
          return -1;
        }
        if (b.indemnity.accrual.vacation > a.indemnity.accrual.vacation) {
          return 1;
        }
        return 0;

      default:
        return orderBy;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "employeeId", label: t("employeeID") },
    { id: "employeeName", label: t("employeeName") },
    { id: "department", label: t("department") },
    { id: "position", label: t("position") },
    { id: "status", label: t("status") },
    { id: "termination", label: t("termination") },
    { id: "resignation", label: t("resignation") },
    { id: "accrual", label: t("vacationAccrual") },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      setOrderBy(property);
      onRequestSort(event, property);
    };

    const useStyles = makeStyles(() => ({
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }));

    const classes = useStyles();

    return (
      <TableHead>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </CustomTableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const employeesList = filterEmployees(
    employees,
    department,
    position,
    status,
    query,
  );

  const listOfEmployees = stableSort(
    employeesList,
    getComparator(order, orderBy),
  )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((employee) => (
      <IndemnityEmployeeItem
        employeesStatus={employeesStatus}
        employee={employee}
        indemnity={employee.indemnity}
        key={
          employee.user
            ? employee.user?.fullName + employee.id
            : employee?.fullName + employee.id
        }
      />
    ));

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.hypotheticalIndemnities.name}`),
      isLast: true,
    },
  ];

  return (
    <Box>
      <Grid
        container
        justifyContent={matchesMD ? "center" : "flex-start"}
        spacing={4}
      >
        <Grid item md={11} xs={12}>
          <Grid
            container
            justifyContent={matchesMD ? "center" : "flex-start"}
            spacing={4}
          >
            <Grid item md={12} xs={9}>
              <PageHeader
                paths={navigationPaths}
                title={t(`breadcrumbs:${paths.hypotheticalIndemnities.header}`)}
                paddingBottom={3}
              />
            </Grid>
            <Grid item md={12} xs={9}>
              <Box clone paddingY={1}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      value={department}
                      options={departments.map((dep) => ({
                        label: isEnglish ? dep.name : dep.nameAr,
                        value: dep.id,
                      }))}
                      multiple={true}
                      onChange={(e) => setDepartment(e)}
                      label={t("department")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      value={position}
                      options={_positions().map((pos) => ({
                        label: isEnglish ? pos.name : pos.nameAr,
                        value: pos.id,
                      }))}
                      multiple={true}
                      onChange={(e) => setPosition(e)}
                      label={t("position")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      value={status}
                      options={employeesStatus.map((stat) => ({
                        label: isEnglish ? stat.name : stat.nameAr,
                        value: stat.value,
                      }))}
                      multiple={true}
                      onChange={(e) => setStatus(e)}
                      label={t("status")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <SearchInput
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder={t("search")}
                      position="start"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} xs={10}>
              <Box clone bgcolor="white" borderRadius={15}>
                <TableContainer>
                  {hypotheticalIndemnitiesLoading ? (
                    <LoadingSpinnerComponent />
                  ) : (
                    <Table>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />

                      <TableBody>{listOfEmployees}</TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Box>

              <TablePagination
                count={employees.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company, employees }) => ({
  departments: company.departments,
  hypotheticalIndemnities: employees.hypotheticalIndemnities,
  hypotheticalIndemnitiesLoading: employees.hypotheticalIndemnitiesLoading,
  positions: company.positions,
});

const mapDispatchToProps = {
  fetchHypotheticalIndemnities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HypotheticalIndemnities);
