import { Card, CardActions, CardHeader, Grid, List } from "@material-ui/core";

import { DetailsItem } from "../../../common";
import React from "react";
import { useTranslation } from "react-i18next";

const CompanyPocDetails = ({ company }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const companyPocDetails = [
    { label: t("name"), value: company?.poc?.name },
    { label: t("email"), value: company?.poc?.email },
    { label: t("phoneNumber"), value: company?.poc?.number },
    { label: t("address1"), value: company?.poc?.address?.address1 },
    { label: t("address2"), value: company?.poc?.address?.address2 },
    { label: t("zipcode"), value: company?.poc?.address?.zipCode },
    { label: t("country"), value: company?.poc?.country?.name },
    { label: t("cityOrTown"), value: company?.poc?.cityOrTown },
    { label: t("stateOrProvince"), value: company?.poc?.stateOrProvince },
    { label: t("paciNumber"), value: company?.poc?.paciNumber },
  ];

  return (
    <Card>
      <CardHeader title={t("poc")} />

      <List disablepadding>
        <Grid container>
          {companyPocDetails.map((detail, idx) => {
            return (
              <Grid item md={6} key={idx}>
                <DetailsItem label={detail.label} value={detail.value} />
              </Grid>
            );
          })}
        </Grid>
      </List>

      <CardActions></CardActions>
    </Card>
  );
};

export default CompanyPocDetails;
