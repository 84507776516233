import * as XLSX from "xlsx/xlsx.mjs";

import { Box, Button } from "@material-ui/core";

import React from "react";
import { getPdfFields } from "./getPdfFields";
import { getXlsxData } from "./getXlsxData";
import { useTranslation } from "react-i18next";

const GenerateXlsxButton = ({
  employeesForPdf,
  pdfFields,
  setPdfFields,
  setOpenModal,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const createXlsx = () => {
    let xlsxData = getXlsxData(
      employeesForPdf,
      setPdfFields,
      getPdfFields,
      setOpenModal,
      t,
      isEnglish,
    );

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(xlsxData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "employees");
    XLSX.writeFile(workbook, `employees-report.xlsx`);
    setOpenModal(false);
    setPdfFields(getPdfFields(t));
  };

  return (
    <Box clone marginX={2}>
      <Button variant="contained" color="primary" onClick={createXlsx}>
        {t("generateXlsx")}
      </Button>
    </Box>
  );
};

export default GenerateXlsxButton;
