import React, { useState } from "react";
import { Link } from "react-router-dom";

//Components
import { CustomTableCell, CustomTableRow } from "../../../common";
import ResourceModal from "./ResourceModal";

//Material UI
import { KeyboardArrowDown, KeyboardArrowUp, Send } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";

//Styles
import useStyles from "../styles";

//Translation
import { useTranslation } from "react-i18next";
import ResourceDetail from "./ResourceDetail";

const Resource = ({ resource }) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const SendIcon = withStyles((theme) => ({
    root: {
      transform: "flipX(-1)",
    },
  }))(Send);

  return (
    <>
      <CustomTableRow backgroundColor>
        <CustomTableCell
          colSpan={matchesMD ? 2 : 5}
          onClick={() => setOpen(!open)}
        >
          <Typography
            component={Link}
            color="primary"
            variant="subtitle2"
            className={classes.menuButton}
          >
            {isEnglish ? resource.name : resource.nameAr}
          </Typography>
        </CustomTableCell>

        <CustomTableCell colSpan={matchesMD ? 2 : 1} size="small">
          <Box align={isEnglish ? "right" : "left"}>
            <IconButton
              disableRipple
              size="small"
              color="primary"
              className={classes.menuButton}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>

            <IconButton
              disableRipple
              className={classes.menuButton}
              size="small"
              onClick={() => setOpenModal(true)}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </CustomTableCell>
      </CustomTableRow>
      <CustomTableRow backgroundColor={open}>
        <CustomTableCell colSpan={matchesMD ? 4 : 11} size="small">
          <ResourceDetail resource={resource} open={open} />
        </CustomTableCell>
      </CustomTableRow>
      <ResourceModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        resource={resource}
        t={t}
        isEnglish={isEnglish}
      />
    </>
  );
};

export default Resource;
