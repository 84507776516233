//Material UI
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DeleteOutline, Edit, Print } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { updateEmployee } from "../../redux/actions";
import { getEmployeeLocations } from "../../utils";
import { createEmployeeProfilePdf } from "../pages/EmployeeProfile/utils";
import CompanyStructureModal from "./CompanyStructureModal";
//Data
import { employeesStatus } from "./DropDownsData";

const ProfileSection = ({ profile, isCompanyOwner = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeProfile"]);
  const isEnglish = language === "en";

  if (!profile) return <></>;

  const resignationRequest = profile.requests.find(
    (request) => request.kind === "RESIGNED" && request.status === "APPROVED"
  );

  const employeeStatus = employeesStatus.find(
    (status) => status.value === profile.employmentStatus
  );

  const profilesItems = [
    {
      title: t("employeeProfile:employeeID"),
      info: profile.referenceNumber ? profile.referenceNumber : profile.id,
    },
    {
      title: t("employeeProfile:employmentDate"),
      info: profile.employmentDate,
    },
    {
      title: t("employeeProfile:leaveDate"),
      info: profile.leaveDate
        ? profile.leaveDate
        : resignationRequest
        ? resignationRequest.date
        : "-",
    },
    {
      title: t("employeeProfile:gender"),
      info: profile.gender,
    },
    {
      title: t("employeeProfile:bankName"),
      info: profile.bankName ?? "-",
    },
    {
      title: t("employeeProfile:iban"),
      info: profile.iban ?? "-",
    },
    {
      title: t("employeeProfile:number"),
      info: profile.phoneNumber,
    },
    {
      title: t("employeeProfile:nationality"),
      info: profile.nationality.name,
    },
    {
      title: t("employeeProfile:status"),
      info: isEnglish ? employeeStatus.name : employeeStatus.nameAr,
    },
    {
      title: t("employeeProfile:note"),
      info: profile.note ?? "- -",
    },
    {
      title: t("employeeProfile:dateOfBirth"),
      info: profile.dateOfBirth,
    },
    {
      title: t("employeeProfile:address"),
      info: `${
        profile.address.country ? profile.address.country.name : "-"
      } - ${profile.address.address1} - ${profile.address.address2}`,
    },
    {
      title: t("employeeProfile:poc"),
      info: `${profile.poc.name} - ${profile.poc.email} - ${profile.poc.number}`,
    },
    {
      title: t("employeeProfile:pocAddress"),
      info: `${
        profile.poc.address.country ? profile.poc.address.country.name : "-"
      } - ${profile.poc.address.address1} - ${profile.poc.address.address2}`,
    },
    {
      title: t("employeeProfile:lastUpdated"),
      info: profile.lastUpdated,
    },
  ];

  const handleDelete = () => {
    dispatch(updateEmployee({ id: profile.id, archived: true }))
      .then(() => {
        setOpenDeleteModal(false);

        history.push("/employeesList");
        // add succes alert here
      })
      .catch((error) => {
        <Alert severity="error">
          {t("employeeProfile:failedToDeleteEmployee")}
        </Alert>;
      });
  };

  const profilesList = profilesItems.map((item) => (
    <Grid
      key={item.title}
      container
      justifyContent={matchesMD ? "flex-start" : undefined}
    >
      <Grid item xl={9} lg={10} md={12} sm={6} xs={12}>
        <Box clone padding={1}>
          <Typography variant="h5" color="textPrimary">
            {item.title}
          </Typography>
        </Box>
        <Box clone padding={1}>
          <Typography variant="subtitle2" color="textSecondary">
            {item.info}
          </Typography>
        </Box>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  ));

  return (
    <>
      {isCompanyOwner && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            paddingY={1}
          >
            <Button
              onClick={() =>
                createEmployeeProfilePdf(t, isEnglish, profile, employeeStatus)
              }
              variant="contained"
              color={"primary"}
              endIcon={<Print />}
              style={{
                marginRight: 5,
              }}
            >
              <Typography>{t("common:print")}</Typography>
            </Button>
            <Button
              component={Link}
              to={`/editEmployee/${profile.id}`}
              variant="contained"
              color={"primary"}
              endIcon={<Edit />}
            >
              <Typography>{t("common:edit")}</Typography>
            </Button>
            {!profile.archived && (
              <Button
                onClick={() => setOpenDeleteModal(true)}
                variant="contained"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: 5,
                }}
              >
                <DeleteOutline />
              </Button>
            )}
          </Box>
        </>
      )}
      <Box clone bgcolor="background.default">
        <Grid
          container
          alignItems="center"
          justifyContent={matchesMD ? "flex-start" : undefined}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              clone
              height={"60px"}
              width={"60px"}
              marginRight={isEnglish ? 1 : 0}
              marginLeft={isEnglish ? 0 : 1}
            >
              <Avatar alt="Image" src={profile.image} />
            </Box>

            <Grid
              direction="column"
              container
              alignItems={matchesMD ? "flex-start" : undefined}
              justifyContent={matchesMD ? "flex-start" : undefined}
            >
              <Grid item>
                <Grid item md={12}>
                  <Typography variant="subtitle2" color="primary">
                    {isEnglish
                      ? profile.position.name
                      : profile.position.nameAr}
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Typography variant="h3" color="textPrimary">
                    {profile.user ? profile.user?.fullName : profile?.fullName}
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Box clone whiteSpace="nowrap">
                    <Typography variant="h5" color="primary">
                      {isEnglish
                        ? profile.department.name
                        : profile.department.nameAr}
                    </Typography>
                  </Box>
                </Grid>
                {profile.locations.length > 0 && (
                  <Grid item md={12}>
                    <Box clone>
                      <Typography variant="h5" color="primary">
                        {getEmployeeLocations(profile, isEnglish)}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid item lg={10} md={12} xs={10}>
            {profilesList}
          </Grid>
        </Grid>
      </Box>
      <CompanyStructureModal
        open={openDeleteModal}
        fadeIn={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("common:deleteEmployee")}
      >
        <Grid container spacing={3} style={{ margin: 5 }}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("common:employeeDeleteConfirm")}
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            paddingX={2}
            paddingY={2}
          >
            <Grid item container md={12} sm={12} xs={11}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDelete}
                >
                  {t("common:confirm")}
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </CompanyStructureModal>
    </>
  );
};

export default ProfileSection;
