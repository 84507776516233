import { Box } from "@material-ui/core";
import { LocationsAndDepartments as LocationsAndDepartmentsComponent } from "../../../components/Employer/index";
import { PageHeader } from "../../../components/common";
import React from "react";
import { paths } from "../../../utils";
import { useTranslation } from "react-i18next";

const LocationsAndDepartments = () => {
  const { t } = useTranslation(["breadcrumbs"]);

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.company.name}`),
      isLast: true,
    },
    {
      name: t(`breadcrumbs:${paths.company.locationsAndDepartments.name}`),
      isLast: true,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader
        title={t(`breadcrumbs:${paths.company.locationsAndDepartments.header}`)}
        paths={navigationPaths}
        paddingBottom={5}
      />

      <LocationsAndDepartmentsComponent />
    </Box>
  );
};

export default LocationsAndDepartments;
