import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { CompanyStructureModal } from "../../../../../../components/common";
import AutoCompleteCategories from "../../../../../../components/common/AutocompleteCategoriesInput";
import AutocompleteInput from "../../../../../../components/common/AutocompleteInput";
import { portalSections } from "../../../../../../components/common/DropDownsData";
import { createEmployeePortalPermission } from "../../../../../../redux/actions";

// import AutoCompleteCategories from "../../../../common/AutocompleteCategoriesInput";
// import AutocompleteInput from "../../../../common/AutocompleteInput";

const AddPermission = ({
  openModal,
  setOpenModal,
  selectedEmployee,
  employees,
  createEmployeePortalPermission,
  locations: locationsState,
  departments: departmentsState,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";
  const [employee, setEmployee] = useState("");
  const [sections, setSections] = useState([]);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);

  const handleSubmit = () => {
    let kindValues = sections.map((section) => section.value);
    let locationIds = locations.map((location) => Number(location.value));
    let departmentIds = departments.map((department) =>
      Number(department.value),
    );
    createEmployeePortalPermission(
      {
        employeeId: employee,
        kinds: kindValues,
        locations: locationIds ?? [],
        departments: departmentIds ?? [],
      },
      () => setOpenModal(false),
    );
    setEmployee("");
    setSections([]);
    setLocations([]);
  };

  const handleSectionsChange = (e) => {
    if (sections.includes(e)) {
      setSections((prevState) =>
        prevState.filter((section) => section.value !== e.value),
      );
    } else {
      setSections([...e]);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEmployee("");
    setSections([]);
    setLocations([]);
  };

  return (
    <>
      <CompanyStructureModal
        open={openModal}
        onClose={handleCloseModal}
        fadeIn={openModal}
        title={t("companyProfile:portalAccessPermissions")}
      >
        <Box height="auto" maxHeight={"60vh"} margin={2}>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
              <AutoCompleteCategories
                isRequired={true}
                excludeFlags={true}
                label={t("employeeName")}
                name="name"
                onChange={(e) => {
                  setEmployee(e);
                }}
                value={employee}
                array={employees
                  ?.filter(
                    (emp) =>
                      emp.employmentStatus !== "RESIGNED" ||
                      emp.employmentStatus !== "LAID_OFF",
                  )
                  .map((emp) => ({
                    label: emp.user ? emp.user?.fullName : emp?.fullName,
                    value: emp.id,
                  }))}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <AutocompleteInput
                value={sections}
                options={portalSections
                  .filter((section) => {
                    const sectionsSelected = sections.map((sec) => sec.value);
                    return !sectionsSelected.includes(section.value);
                  })

                  .map((section) => ({
                    label: isEnglish ? section.name : section.nameAr,
                    value: section.value,
                  }))}
                multiple={true}
                onChange={handleSectionsChange}
                label={t("companyProfile:portalSections")}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <AutocompleteInput
                value={locations}
                options={locationsState

                  .filter((loc) => {
                    const locationsSelected = locations.map((loc) => loc.value);
                    return !locationsSelected.includes(loc.id);
                  })

                  .map((loc) => ({
                    label: isEnglish ? loc.name : loc.nameAr,
                    value: loc.id,
                  }))}
                multiple={true}
                onChange={(e) => setLocations(e)}
                label={t("branches")}
              />
            </Grid>
            <Grid item md={12} sm={12}>
              <AutocompleteInput
                value={departments}
                options={departmentsState

                  .filter((dep) => {
                    const departmentsSelected = locations.map(
                      (dep) => dep.value,
                    );
                    return !departmentsSelected.includes(dep.id);
                  })

                  .map((dep) => ({
                    label: isEnglish ? dep.name : dep.nameAr,
                    value: dep.id,
                  }))}
                multiple={true}
                onChange={(e) => setDepartments(e)}
                label={t("departments")}
              />
            </Grid>
            <Box
              clone
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              paddingX={2}
              paddingY={2}
            >
              <Grid item md={12} sm={12} xs={11}>
                <Button
                  disableRipple
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    sections.length === 0 ||
                    (locations.length === 0 && departments.length === 0) ||
                    employee?.length === 0 ||
                    employee === null
                  }
                >
                  <Typography>{t("common:save")}</Typography>
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ employees, company }) => ({
  employees: employees.employees,
  locations: company.locations,
  departments: company.departments,
});

const mapDispatchToProps = {
  createEmployeePortalPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPermission);
