import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ScheduleItem from "./ScheduleItem";
import { days as availableDays } from "../../../DropDownsData";
import { useTranslation } from "react-i18next";

const ShiftsForm = ({ schedulesState, setSchedulesState }) => {
  const { t } = useTranslation("common");
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    let daysSelectedFromState = [];

    if (schedulesState?.length > 0) {
      daysSelectedFromState = schedulesState
        .map((sched) => sched.days)
        .reduce((prev, current) => [...prev, ...current]);
    }

    if (selectedDays.length === 0) {
      let newState = [];
      for (let i = 0; i < availableDays.length; i++) {
        if (
          daysSelectedFromState.length > 0 &&
          daysSelectedFromState.includes(availableDays[i].value)
        ) {
          newState.push({ ...availableDays[i], selected: true });
        } else {
          newState.push(availableDays[i]);
        }
      }

      setSelectedDays(newState);
    }
  }, [schedulesState, selectedDays.length]);

  return (
    <Box overflow="scroll" height="600px">
      {schedulesState?.map((schedule, idx) => {
        return (
          <ScheduleItem
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            schedule={schedule}
            schedulesState={schedulesState}
            setSchedulesState={setSchedulesState}
            scheduleIdx={idx}
          />
        );
      })}

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSchedulesState([
            ...schedulesState,
            {
              days: [],
              shifts: [{ startTime: "", endTime: "" }],
            },
          ]);
        }}
        style={{
          textTransform: "none",
        }}
      >
        {t("addSchedule")}
      </Button>
    </Box>
  );
};

export default ShiftsForm;
