import React, { useState } from "react";

//Components
import CompanyImage from "./CompanyImage";
import CompanyInformation from "./CompanyInformation.js";
import ContactInformation from "./ContactInformation";
import CompanyAddress from "./CompanyAddress";
import PocAddress from "./PocAddress";

//MaterialUI
import { Grid, useMediaQuery, Box, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Edit } from "@material-ui/icons";

//Styles
import useStyles from "../styles";

//Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const File = ({ address, poc }) => {
  const { t } = useTranslation("common");
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [zipCodeHelper, setZipCodeHelper] = useState("");
  const [postcodeHelper, setPostcodeHelper] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [phoneHelper, setPhoneHelper] = useState("");
  const [zipCodeHelperPoc, setZipCodeHelperPoc] = useState("");
  const [postcodeHelperPoc, setPostcodeHelperPoc] = useState("");

  const [addressState, setAddress] = useState({
    ...address,
    country: address?.country?.code,
  });

  const [pocState, setPoc] = useState({ ...poc });

  return (
    <Box clone minHeight={"400px"} paddingY={5} paddingX={matchesMD ? 4 : 10}>
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={
                (edit && postcodeHelper.length !== 0) ||
                (edit && addressState.postcode.length === 0) ||
                (edit && zipCodeHelper.length !== 0) ||
                (edit && addressState.zipCode.length === 0) ||
                (edit && emailHelper.length !== 0) ||
                (edit && pocState.email.length === 0) ||
                (edit && phoneHelper.length !== 0) ||
                (edit && pocState.number.length === 0) ||
                (edit && zipCodeHelperPoc.length !== 0) ||
                (edit && postcodeHelperPoc.length !== 0)
              }
              className={classes.addDepartment}
              variant="contained"
              color={edit ? "secondary" : "primary"}
              startIcon={<Edit />}
              onClick={() => setEdit(!edit)}
            >
              {edit ? t("done") : t("edit")}
            </Button>
          </Box>
        </Grid>
        <CompanyImage edit={edit} />
        <CompanyInformation edit={edit} />
        <CompanyAddress
          addressState={addressState}
          setAddress={setAddress}
          address={address}
          edit={edit}
          zipCodeHelper={zipCodeHelper}
          setZipCodeHelper={setZipCodeHelper}
          postcodeHelper={postcodeHelper}
          setPostcodeHelper={setPostcodeHelper}
        />
        <ContactInformation
          edit={edit}
          pocState={pocState}
          setPoc={setPoc}
          poc={poc}
          emailHelper={emailHelper}
          setEmailHelper={setEmailHelper}
          phoneHelper={phoneHelper}
          setPhoneHelper={setPhoneHelper}
        />
        <PocAddress
          edit={edit}
          zipCodeHelperPoc={zipCodeHelperPoc}
          setZipCodeHelperPoc={setZipCodeHelperPoc}
          postcodeHelperPoc={postcodeHelperPoc}
          setPostcodeHelperPoc={setPostcodeHelperPoc}
        />
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  address: company.address,
  poc: company.poc,
});

export default connect(mapStateToProps)(File);
