import { Button, useTheme } from "@material-ui/core";

import { CustomTableCell } from "../../../../components/common";
import React from "react";
import getCorrectTimezone from "../../../../utils";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const AttendanceCheckoutCell = ({
  attendance,
  isCheckoutPending,
  isEmployeeWithAccess,
  isEmployee,
  handleOpen,
  handlePendingCheckout,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const theme = useTheme();
  const isEnglish = language === "en";

  const defaultStyle = {
    textTransform: "none",
    backgroundColor: theme.palette.common.blue,
    color: "white",
  };

  const employeeStyle = {
    backgroundColor: "red",
    color: "white",
    textTransform: "none",
  };

  const style =
    isCheckoutPending || isEmployeeWithAccess ? defaultStyle : employeeStyle;
  // prettier-ignore
  const label =  (isCheckoutPending || isEmployeeWithAccess ) ? t("checkOut") : isEmployee ? t("pending")  : moment(getCorrectTimezone(attendance.checkOut)).format("LLL")

  const onClick = () => {
    if (isCheckoutPending) return handleOpen();
    if (isEmployeeWithAccess) return handlePendingCheckout(attendance.id);
    if (isEmployee) return () => {};
  };
  return (
    <CustomTableCell align={isEnglish ? "left" : "right"}>
      {isCheckoutPending || isEmployeeWithAccess || isEmployee ? (
        <Button
          size="small"
          variant="contained"
          style={style}
          onClick={onClick}
        >
          {label}
        </Button>
      ) : (
        label
      )}
    </CustomTableCell>
  );
};

export default AttendanceCheckoutCell;
