//MaterialUI
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { FormInputField } from "../../../../../../components/common";
import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const AutoCheckout = ({ company, updateCompanyProfile }) => {
  const { t } = useTranslation("common");

  const [enableAutomaticCheckout, setEnableAutomaticCheckout] = useState({
    autoCheckout: company?.autoCheckout,
    autoCheckoutAfter: company?.autoCheckoutAfter,
  });

  const handleSwitchChange = () => {
    setEnableAutomaticCheckout({
      ...enableAutomaticCheckout,
      autoCheckout: !enableAutomaticCheckout.autoCheckout,
    });

    updateCompanyProfile({
      ...company,
      autoCheckout: !enableAutomaticCheckout.autoCheckout,
    });
  };

  return (
    <>
      <Grid item md={12} xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={enableAutomaticCheckout.autoCheckout}
              onChange={() => handleSwitchChange()}
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
              {t("enableAutomaticCheckout")}
            </Typography>
          }
        />
      </Grid>

      {enableAutomaticCheckout.autoCheckout && (
        <Grid item lg={6} xs={12} style={{ marginLeft: 20 }}>
          <FormInputField
            isRequired
            label={t("checkOutLimits")}
            name="checkinLimits"
            disabled={!enableAutomaticCheckout.autoCheckout}
            onChange={(e) => {
              setEnableAutomaticCheckout({
                ...enableAutomaticCheckout,
                autoCheckoutAfter: e.target.value,
              });
              updateCompanyProfile({
                ...company,
                autoCheckoutAfter: e.target.value,
              });
            }}
            value={enableAutomaticCheckout.autoCheckoutAfter}
            helperText={t("enableAutomaticCheckoutAfter")}
            id="automaticCheckout"
            autoComplete="new-password"
            type="number"
          />
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(AutoCheckout);
