import {
  CustomTableCell,
  CustomTableRow,
} from "../../../../../../components/common";
import { DeleteOutline, Edit, FileCopyOutlined } from "@material-ui/icons";
//Material UI
import { IconButton, Typography } from "@material-ui/core";

import React from "react";
import moment from "moment";

const ModificationRow = ({
  modificationItem,
  setModification,
  setOpen,
  setOpenDeleteModal,
  employeeId,
  enableActions,
}) => {
  return (
    <CustomTableRow hover role="checkbox" key={modificationItem.description}>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {modificationItem.reason}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        <Typography variant="subtitle2" color="textPrimary">
          {modificationItem.amount}
        </Typography>
      </CustomTableCell>

      <CustomTableCell>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          style={{ whiteSpace: "nowrap" }}
        >
          {modificationItem.month
            ? moment(new Date(modificationItem.month)).format("MMM-YYYY")
            : moment(new Date(modificationItem.startDate)).format("MMM-YYYY")}
        </Typography>
      </CustomTableCell>

      <CustomTableCell>
        {modificationItem.attachment ? (
          <a
            href={modificationItem.attachment}
            target="_blank"
            download
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FileCopyOutlined />
          </a>
        ) : (
          <Typography>-</Typography>
        )}
      </CustomTableCell>

      {enableActions && (
        <CustomTableCell>
          <IconButton
            color="primary"
            onClick={() => {
              setOpen(true);
              setModification({ ...modificationItem, employeeId });
            }}
          >
            <Edit />
          </IconButton>
        </CustomTableCell>
      )}

      <CustomTableCell>
        <IconButton
          color="primary"
          onClick={() => {
            setOpenDeleteModal(true);
            setModification({ ...modificationItem, employeeId });
          }}
        >
          <DeleteOutline />
        </IconButton>
      </CustomTableCell>
    </CustomTableRow>
  );
};

export default ModificationRow;
