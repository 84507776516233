import { Box } from "@material-ui/core";
import { CompanyStructureModal } from "../../../../common";
import LocationLoaded from "../LocationLoaded";
import LocationNotLoaded from "../LocationNotLoaded";
import React from "react";
import UserLocationDenied from "../UserLocationDenied";

const CheckOutModal = ({
  t,
  location,
  openCheckOutModal,
  onClickCancel,
  onClickCheckOut,
  onClickLoaded,
  onClickNotLoaded,
  onCloseModal,
}) => {
  return (
    <CompanyStructureModal
      open={openCheckOutModal}
      onClose={onCloseModal}
      fadeIn={openCheckOutModal}
      height={"allowed" in location ? "250px" : "inherit"}
      title={
        !("allowed" in location)
          ? t("userLocationDenied")
          : t("locationLoading")
      }
    >
      <Box margin={4}>
        {!("allowed" in location) && (
          <UserLocationDenied
            onCheckOut
            onClickCancel={onClickCancel}
            onClickCheck={onClickCheckOut}
          />
        )}
        {location.loaded && "allowed" in location && (
          <LocationLoaded onClick={onClickLoaded} />
        )}
        {!location.loaded && <LocationNotLoaded onClick={onClickNotLoaded} />}
      </Box>
    </CompanyStructureModal>
  );
};

export default CheckOutModal;
