import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

const Field = ({ label, value, md = 3, xs = 6 }) => {
  return (
    <>
      <Box clone minHeight={"50px"}>
        <Grid item md={md} xs={xs}>
          <Typography color="primary">{label}</Typography>
        </Grid>
      </Box>
      <Box clone height={"100%"}>
        <Grid item md={md} xs={xs}>
          <Typography>{value}</Typography>
        </Grid>
      </Box>
    </>
  );
};

export default Field;
