import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { RequestsTable } from "../../../common";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import EditLeave from "./EditLeave";

const VacationDebits = ({
  employee,
  user,
  setType,
  setOpen,
  setOpenEdit,
  setLeaveId,
}) => {
  const { t } = useTranslation("common");

  const leaveDebitLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:amount"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:document"),
    t("common:edit"),
    t("common:delete"),
  ];

  if (!user.isCompanyOwner) {
    leaveDebitLabels.pop();
  }
  console.log("debit employee", employee);
  let leaveDebits = employee.leaveBalance.leavedebits
    .filter((leave) => leave.isCounted)
    .map((leave) => {
      return {
        id: leave.id,
        reason: leave.reason,
        kind: leave.kind,
        amount: leave.amount,
        startDate: leave.date,
        endDate: leave.endDate,
        document: leave.document,
      };
    });

  return (
    <RequestsTable
      title={t("vacationDebits")}
      forLeaveDebit
      data={leaveDebits}
      labels={leaveDebitLabels}
      canDeleteRecord={user.isCompanyOwner}
      setOpenEdit={setOpenEdit}
      setLeaveId={setLeaveId}
      setType={setType}
      action={
        user &&
        user.isCompanyOwner && (
          <Button
            size="small"
            endIcon={<Add />}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => {
              setType("debit");
              setOpen(true);
            }}
          >
            {t("debitAdd")}
          </Button>
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(VacationDebits);
