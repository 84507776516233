export const filterEmployeesByDepartment = (employees, selectedDepartments) => {
  let filteredEmployees = employees.filter((employee) => {
    if (selectedDepartments?.length === 0) {
      return employee;
    }

    return selectedDepartments?.some(
      (dep) => dep.value === employee.department.id
    );
  });

  return filteredEmployees;
};
