//Material UI
import { Box, Grid } from "@material-ui/core";

//Components
import Departments from "./Departments";
import Location from "./Location/index";
import React from "react";

const CompanyStructure = ({ departments }) => {
  return (
    <Grid item xs={12}>
      <Box
        minHeight={"400px"}
        boxShadow="none"
        borderRadius={10}
        borderwidth={1}
      >
        <Location />
        <Departments />
      </Box>
    </Grid>
  );
};

export default CompanyStructure;
