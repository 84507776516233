import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  deleteRecurringSalaryModification,
  deleteSalaryModification,
} from "../../../../../../../redux/actions";

import { CompanyStructureModal } from "../../../../../../../components/common";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const DeleteModificationModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  modification,
  deleteSalaryModification,
  deleteRecurringSalaryModification,
  isRecurring,
}) => {
  const { t } = useTranslation("common");

  const deleteFunction = isRecurring
    ? deleteRecurringSalaryModification
    : deleteSalaryModification;

  const handleDelete = () => {
    deleteFunction({ id: modification?.id }, () => setOpenDeleteModal(false));
  };

  return (
    <CompanyStructureModal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      fadeIn={openDeleteModal}
      title={t("employeeSalary:confirmDeleteModification")}
    >
      <Box height={"auto"} maxHeight="90vh" margin={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("common:modificationDeleteConfirm")}
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            paddingX={2}
            paddingY={2}
          >
            <Grid item container md={12} sm={12} xs={11}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDelete}
                >
                  {t("common:confirm")}
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CompanyStructureModal>
  );
};

const mapDispatchToPros = {
  deleteSalaryModification,
  deleteRecurringSalaryModification,
};

export default connect(null, mapDispatchToPros)(DeleteModificationModal);
