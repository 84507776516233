import moment from "moment";

export const getModificationValues = (
  recurringSalaryModifications,
  salaryModifications,
  earnings,
  deductions,
  recurringEarnings,
  recurringDeductions,
  initalTotalEarnings,
  initialTotalDeductions,
  deposits,
  deposit,
  employee,
  month,
) => {
  console.log("===== employee =====", employee);
  console.log("=====month =====", month);
  if (deposit === "All") {
    recurringSalaryModifications?.forEach((modification) => {
      if (Number(modification.amount) >= 0) {
        recurringEarnings.push(modification);
        initalTotalEarnings += Number(modification.amount);
      } else {
        recurringDeductions.push(modification);
        initialTotalDeductions += Number(modification.amount);
      }
    });

    salaryModifications?.forEach((modification) => {
      if (Number(modification.amount) >= 0) {
        earnings.push(modification);
        initalTotalEarnings += Number(modification.amount);
      } else {
        deductions.push(modification);
        initialTotalDeductions += Number(modification.amount);
      }
    });
  } else if (deposit === "Custom") {
    let _month = moment(month).format("MM-YYYY");

    employee.salaryModifications.forEach((modification) => {
      let modificationMonth = moment(modification.month).format("MM-YYYY");

      if (
        moment(_month, "MM-YYYY").isSame(moment(modificationMonth, "MM-YYYY"))
      ) {
        if (Number(modification.amount) >= 0) {
          earnings.push(modification);
          initalTotalEarnings += Number(modification.amount);
        } else {
          deductions.push(modification);
          initialTotalDeductions += Number(modification.amount);
        }
      }
    });

    employee.recurringSalaryModifications.forEach((modification) => {
      let startMonth = moment(modification.startDate).format("MM-YYYY");
      let endMonth = modification.endDate
        ? moment(modification.endDate).format("MM-YYYY")
        : null;
      if (
        moment(_month, "MM-YYYY").isSame(moment(startMonth, "MM-YYYY")) ||
        (endMonth &&
          moment(_month, "MM-YYYY").isBetween(
            moment(startMonth, "MM-YYYY"),
            moment(endMonth, "MM-YYYY"),
          )) ||
        (!endMonth &&
          moment(_month, "MM-YYYY").isAfter(moment(startMonth, "MM-YYYY")))
      ) {
        if (Number(modification.amount) >= 0) {
          recurringEarnings.push(modification);
          initalTotalEarnings += Number(modification.amount);
        } else {
          recurringDeductions.push(modification);
          initialTotalDeductions += Number(modification.amount);
        }
      }
    });
  } else {
    let selectedDeposit = deposits.find((_deposit) => _deposit.id === deposit);
    selectedDeposit.recurringSalaryModifications.forEach((modification) => {
      if (Number(modification.amount) >= 0) {
        earnings.push(modification);
        initalTotalEarnings += Number(modification.amount);
      } else {
        deductions.push(modification);
        initialTotalDeductions += Number(modification.amount);
      }
    });

    selectedDeposit.salaryModifications.forEach((modification) => {
      if (Number(modification.amount) >= 0) {
        earnings.push(modification);
        initalTotalEarnings += Number(modification.amount);
      } else {
        deductions.push(modification);
        initialTotalDeductions += Number(modification.amount);
      }
    });
  }

  let totalEarnings = initalTotalEarnings;
  let totalDeductions = initialTotalDeductions;
  return [totalEarnings, totalDeductions];
};
