import {
  ADD_OVERTIME_CREDIT,
  ADD_REQUEST,
  ADD_REQUEST_LOADING,
  CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN,
  EDIT_ANNOUNCEMENT,
  EMPLOYEE_ACTIVE_ATTENDANCE,
  EMPLOYEE_ADD_EMPLOYEE_DOCUMENT,
  EMPLOYEE_DEPOSITS,
  EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT,
  EMPLOYEE_LEAVE_SALARY,
  EMPLOYEE_NEXT_DEPOSIT,
  EMPLOYEE_OVERTIME_CREDITS,
  EMPLOYEE_PROFILE,
  MY_ATTENDANCES,
  UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE,
} from "../actions/types";

const initialState = {
  profile: null,
  deposits: [],
  nextDeposit: null,
  attendances: [],
  activeAttendance: [],
  overtimeCredits: [],
  leaveSalary: null,
  addRequestLoading: false,
};

export const employeeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EMPLOYEE_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case EMPLOYEE_DEPOSITS:
      return {
        ...state,
        deposits: payload,
      };

    case EMPLOYEE_NEXT_DEPOSIT:
      return {
        ...state,
        nextDeposit: payload,
      };

    case ADD_REQUEST_LOADING:
      return {
        ...state,
        addRequestLoading: payload,
      };
    case ADD_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          requests: [...state.profile.requests, payload.request],
        },
        addRequestLoading: false,
      };
    case EMPLOYEE_ADD_EMPLOYEE_DOCUMENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          documents: [...state.profile.documents, payload],
        },
      };
    case EDIT_ANNOUNCEMENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          announcements: state.profile.announcements.map((announcement) => {
            if (announcement.id === payload.id) {
              return { ...announcement, seen: payload.seen };
            }
            return announcement;
          }),
        },
      };
    case EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT:
      return {
        ...state,
        profile: {
          ...state.profile,
          documents: state.profile.documents.map((document) => {
            if (document.id === payload.id) {
              return payload;
            } else {
              return document;
            }
          }),
        },
      };
    case MY_ATTENDANCES:
      return {
        ...state,
        attendances: payload,
      };
    case EMPLOYEE_ACTIVE_ATTENDANCE:
      return {
        ...state,
        activeAttendance: payload,
      };
    case EMPLOYEE_LEAVE_SALARY:
      return {
        ...state,
        leaveSalary: payload,
      };
    case CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN:
      return {
        ...state,
        activeAttendance: [...state.activeAttendance, payload.attendance],
      };
    case UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE:
      return {
        ...state,
        activeAttendance: state.activeAttendance.filter(
          ({ id }) => id !== payload.id
        ),
      };
    case EMPLOYEE_OVERTIME_CREDITS:
      return {
        ...state,
        overtimeCredits: payload,
      };
    case ADD_OVERTIME_CREDIT:
      return {
        ...state,
        overtimeCredits: [...state.overtimeCredits, payload],
      };

    default:
      return state;
  }
};
