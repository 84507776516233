import { Button, Grid } from "@material-ui/core";

import { Add } from "@material-ui/icons";
import React from "react";
import ShiftItem from "../ShiftItem";

const Shifts = ({
  schedulesState,
  setSchedulesState,
  schedule,
  scheduleIdx,
}) => {
  // local state and update parent state on change (manage each block seperately)

  const handleAddShift = () => {
    const modifiedSchedules = schedulesState.map((sched, idx) => {
      if (idx === scheduleIdx) {
        return {
          ...schedule,
          shifts: [...schedule.shifts, { startTime: "", endTime: "" }],
        };
      } else {
        return sched;
      }
    });

    setSchedulesState(modifiedSchedules);
  };

  return (
    <Grid item container md={8}>
      {schedule.shifts.map((shift, idx) => {
        return (
          <ShiftItem
            shift={shift}
            key={idx}
            shiftIdx={idx}
            schedule={schedule}
            scheduleIdx={scheduleIdx}
            schedulesState={schedulesState}
            setSchedulesState={setSchedulesState}
          />
        );
      })}
      <Grid item container md={12}>
        <Grid item md={3}></Grid>
        <Grid item md={9}>
          <Button
            startIcon={<Add />}
            onClick={handleAddShift}
            variant="text"
            style={{
              textTransform: "none",
            }}
          >
            Add Shift
            {/* // {t("addShift")} */}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Shifts;
