//Mutations
import {
  CHANGE_PASSWORD,
  LOGIN,
  OWNER_CREATE,
  RESET_CHANGE_PASSWORD,
  SEND_PASSWORD_RESET_EMAIL,
  UPDATE_PROFILE_TERMS,
  VERIFY_ACCOUNT,
} from "./Mutations";
// Action Types
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
//Actions
import { fetchCompany, fetchResources } from "./company";
import { fetchDepartments, fetchLocations, fetchPositions } from "./structure";

// Graphql
import client from "./client";
import { fetchEmployeeProfile } from "./employee";
import { fetchEmployees } from "./employees";
import jwt_decode from "jwt-decode";

export const login = (userData, history, path) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: LOGIN,
      variables: userData,
    });

    const { token, success, errors, user } = res.data.login;
    if (success) {
      setAuthToken(token, user);
      dispatch(setCurrentUser(user));
      if (user.isCompanyOwner) {
        dispatch(fetchCompany());
        dispatch(fetchResources());
      } else if (user.isEmployee) {
        if (user.hasDocumentAccess) {
          fetchCompany();
        }
        if (
          user.hasDocumentAccess ||
          user.hasVacationsAccess ||
          user.hasIndemnityAccess ||
          user.hasAttendanceAccess
        ) {
          dispatch(fetchEmployees());
          dispatch(fetchLocations());
          dispatch(fetchDepartments());
          dispatch(fetchPositions());
        }

        dispatch(fetchEmployeeProfile());
      }
      if (history) {
        if (path) {
          history.replace(path);
        } else {
          history.replace("/");
        }
      }
    } else if (errors) {
      dispatch({
        type: GET_ERRORS,
        errors: errors,
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateTerms = (userData, closeModal) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_PROFILE_TERMS,
      variables: userData,
    });
    const { user } = res.data.updateUserProfileMutation;

    dispatch(setCurrentUser(user));
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));

    if (closeModal) {
      closeModal();
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const register = (userData, history) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: OWNER_CREATE,
      variables: userData,
    });

    const { success, errors } = res.data.register;
    if (success) {
      dispatch(
        login(
          { email: userData.email, password: userData.password1 },
          history,
          "/company/create/"
        )
      );
    } else if (errors) {
      dispatch({
        type: GET_ERRORS,
        errors: errors,
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const logout = (history) => {
  if (history) history.replace("/login");
  setAuthToken();
  return setCurrentUser(null);
};

const setAuthToken = (token, user) => {
  if (token) {
    localStorage.setItem("token", token);
    if (user) localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
};

const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const checkForExpiredToken = () => (dispatch) => {
  // Get token
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  if (token) {
    const currentTime = Date.now() / 1000;

    // Decode token and get user info
    const decodedToekn = jwt_decode(token);

    // Check token expiration
    if (decodedToekn.exp >= currentTime) {
      // Set auth token header
      setAuthToken(token);
      // Set user
      dispatch(setCurrentUser(user));
      if (user.isCompanyOwner) {
        dispatch(fetchCompany());
        dispatch(fetchResources());
      } else if (user.isEmployee) {
        dispatch(fetchEmployeeProfile());
      }
    } else {
      dispatch(logout());
    }
  }
};

export const changePassword = (userData, user, alert) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CHANGE_PASSWORD,
      variables: userData,
    });

    const { success, errors, token } = res.data.passwordChange;
    if (success) {
      setAuthToken(token, user);
      if (alert) alert();
    } else if (errors) {
      dispatch({
        type: GET_ERRORS,
        errors: errors,
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const forgotPassword = (userData, setSuccess) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: SEND_PASSWORD_RESET_EMAIL,
      variables: userData,
    });

    const { success, errors } = res.data.sendPasswordResetEmail;
    if (success) {
      if (setSuccess) setSuccess();
    } else if (errors) {
      dispatch({
        type: GET_ERRORS,
        errors: errors,
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const resetChangePassword =
  (userData, setSuccess) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: RESET_CHANGE_PASSWORD,
        variables: userData,
      });

      const { success, errors } = res.data.passwordReset;
      if (success) {
        if (setSuccess) setSuccess();
      } else if (errors) {
        dispatch({
          type: GET_ERRORS,
          errors: errors,
        });
      }
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const verifyAccount = (userData, history, alert) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: VERIFY_ACCOUNT,
      variables: userData,
    });

    const { success, errors } = res.data.resendActivationEmail;

    if (success) {
      logout();
      if (history) history.push("/");
      if (alert) alert();
    } else if (errors) {
      dispatch({
        type: GET_ERRORS,
        errors: errors,
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
