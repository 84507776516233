import { TableCell, Typography } from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const StyledHomeTableHeader = ({ label, colSpan = 4 }) => {
  const {
    i18n: { language },
  } = useTranslation(["home"]);

  const isEnglish = language === "en";
  return (
    <TableCell
      style={{
        backgroundColor: "white",
        borderBottom: "none",
        textDecoration: "none",
        textTransform: "none",
        paddingLeft: 0,
        whiteSpace: "nowrap",
      }}
      key={label}
      colSpan={colSpan}
    >
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align={isEnglish ? "left" : "right"}
      >
        {label}
      </Typography>
    </TableCell>
  );
};

export default StyledHomeTableHeader;
