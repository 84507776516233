//Material UI
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";

import { Add } from "@material-ui/icons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//Translation
import { useTranslation } from "react-i18next";

const AddFileButton = ({ label, onChange, fileId, documentId }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { t } = useTranslation(["companyCreate", "common"]);
  const isEnglish = language === "en";

  const useStyles = makeStyles(
    (theme) => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,
      },
      label: {
        textDecoration: "underline",
        cursor: "pointer",
      },

      formControlRoot: {
        padding: 4,
        "& .MuiFormLabel-root": {
          color: "white",
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInputLabel-formControl": {
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
      },
      addButton: {
        "&:hover": {
          backgroundColor: theme.palette.common.lightBlue,
          color: "white",
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();

  const handleFileUpload = (e) => {
    onChange([{ file: e.target.files[0], documentId: +documentId }]);
  };

  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          disableRipple
          color="primary"
          variant="contained"
          className={classes.addButton}
        >
          <TextField
            InputProps={{ className: classes.input }}
            label={label}
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            id={fileId}
          />
          <FormLabel htmlFor={fileId}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{t("companyCreate:addFile")}</Typography>
              <Add />
            </Box>
          </FormLabel>
        </Button>
      </Box>
    </FormControl>
  );
};

export default AddFileButton;
