import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      whiteSpace: "nowrap",
      "& > *": {
        borderBottom: "unset",
      },
      backgroundColor: theme.palette.common.aliceBlue,
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    paper: {
      border: "1px solid",
    },
    menuItem: {
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.common.lightBlue,
      },
    },
    menuButton: {
      color: theme.palette.common.blue,
      textDecoration: "none",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.common.lightBlue,

        fontWeight: "bold",
      },
      "&:active": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
    },

    addDepartment: {
      "&:hover": {
        backgroundColor: theme.palette.common.lightBlue,
        color: "white",
      },
    },
    actionButton: {
      backgroundColor: theme.palette.common.lightBlue,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.common.green,
        color: "white",
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
