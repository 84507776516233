import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchDepartments,
  fetchLocations,
  fetchPositions,
} from "../../../../../redux/actions";

import AutocompleteInput from "../../../../../components/common/AutocompleteInput";
import { employeesStatus } from "../../../../../components/common/DropDownsData";
import { useTranslation } from "react-i18next";

const Filters = ({
  allDepartments,
  allPositions,
  allLocations,
  selectedDepartments,
  setSelectedDepartments,
  selectedPositions,
  setSelectedPositions,
  selectedStatus,
  setSelectedStatus,
  selectedLocations,
  setSelectedLocations,
  showSalary,
  setShowSalary,
}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const isEnglish = language === "en";

  const _positions = () => {
    let foundDepartments = allDepartments.filter((dep) =>
      selectedDepartments.some((dep1) => dep1.value === dep.id)
    );
    if (selectedDepartments.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ])
      );
      return selectedDepartmentsPositions;
    }
    return [...allPositions];
  };

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchDepartments());
    dispatch(fetchPositions());
  }, [dispatch]);

  return (
    <>
      <Grid item lg={3} xs={12}>
        <AutocompleteInput
          value={selectedDepartments}
          options={allDepartments
            .filter((dep) => {
              const departmentsSelected = selectedDepartments.map(
                (dep) => dep.value
              );
              return !departmentsSelected.includes(dep.id);
            })

            .map((dep) => ({
              label: isEnglish ? dep?.name : dep?.nameAr,
              value: dep.id,
            }))}
          multiple={true}
          onChange={(e) => {
            setSelectedDepartments(e);
          }}
          label={t("department")}
        />
      </Grid>
      <Grid item lg={3} xs={12}>
        <AutocompleteInput
          multiple={true}
          value={selectedPositions}
          options={_positions()
            .filter((pos) => {
              const positionsSelected = selectedPositions.map(
                (pos) => pos.value
              );
              return !positionsSelected.includes(pos.id);
            })

            .map((pos) => ({
              label: isEnglish ? pos?.name : pos?.nameAr,
              value: pos.id,
            }))}
          onChange={(e) => setSelectedPositions(e)}
          label={t("position")}
        />
      </Grid>
      <Grid item lg={3} xs={12}>
        <AutocompleteInput
          isRequired={false}
          multiple={true}
          value={selectedStatus}
          options={employeesStatus

            .filter((stat) => {
              const statusesSelected = selectedStatus.map((stat) => stat.value);
              return !statusesSelected.includes(stat.value);
            })

            .map((stat) => ({
              label: isEnglish ? stat?.name : stat?.nameAr,
              value: stat.value,
            }))}
          onChange={(e) => setSelectedStatus(e)}
          label={t("status")}
        />
      </Grid>
      <Grid item lg={3} xs={12}>
        <AutocompleteInput
          value={selectedLocations}
          options={allLocations

            .filter((loc) => {
              const locationsSelected = selectedLocations.map(
                (loc) => loc.value
              );
              return !locationsSelected.includes(loc.id);
            })

            .map((loc) => ({
              label: isEnglish ? loc?.name : loc?.nameAr,
              value: loc.id,
            }))}
          multiple={true}
          onChange={(e) => setSelectedLocations(e)}
          label={t("location")}
        />
      </Grid>
      <Grid item lg={3} xs={12} style={{ paddingTop: 5 }}>
        <FormControlLabel
          style={{ marginLeft: "4px" }}
          control={
            <Switch
              color="primary"
              checked={showSalary}
              onChange={() => setShowSalary(!showSalary)}
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="subtitle2" color="textPrimary">
              {t("netSalary")}
            </Typography>
          }
        />
      </Grid>
    </>
  );
};
const mapStateToProps = ({ company }) => ({
  allDepartments: company.departments,
  allPositions: company.positions,
  allLocations: company.locations,
});

export default connect(mapStateToProps)(Filters);
