import React from "react";

//MaterialUI
import { Box, Grid, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

//Icons
import { Save } from "@material-ui/icons/";

//Translation
import { useTranslation } from "react-i18next";

const FormStepperFooter = ({ step, isLastStep, handleNext, handleBack }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("common");

  const buttonProps = isLastStep
    ? {
        variant: "contained",
        size: "large",
        startIcon: <Save />,
      }
    : {
        variant: "text",
      };

  return (
    <Box paddingTop={4}>
      <Grid
        container
        justifyContent={isLastStep && !matchesSM ? "flex-end" : "center"}
      >
        <Grid item>
          <Button disabled={step === 0} onClick={handleBack}>
            {t("back")}
          </Button>

          <Button
            id={`company-create-${step}`}
            color="primary"
            type="submit"
            onClick={handleNext}
            {...buttonProps}
          >
            {t(isLastStep ? "save" : "next")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormStepperFooter;
