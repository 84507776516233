//Material UI
import { Avatar, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import { CustomTableCell, CustomTableRow } from "../../common";

const IndemnityEmployeeItem = ({ employee, indemnity, employeesStatus }) => {
  const [status] = useState(
    employeesStatus.find(
      (_status) => _status.value === employee.employmentStatus,
    ),
  );
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <Box clone whiteSpace="nowrap">
      <CustomTableRow to={`employees/${employee.id}`}>
        <CustomTableCell>{employee.id}</CustomTableCell>
        <CustomTableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {employee.user ? employee.user?.fullName : employee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.department.name : employee.department.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.position.name : employee.position.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? status.name : status.nameAr}
        </CustomTableCell>
        <CustomTableCell>{indemnity.termination} </CustomTableCell>
        <CustomTableCell>{indemnity.resignation}</CustomTableCell>
        <CustomTableCell>{indemnity.accrual.vacation}</CustomTableCell>
      </CustomTableRow>
    </Box>
  );
};

export default IndemnityEmployeeItem;
