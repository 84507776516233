import React, { useEffect } from "react";

//Components
import Document from "./Document";

//Material UI
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { connect, useDispatch } from "react-redux";

//Date
import moment from "moment";

//Actions
import {
  createEmployeeDocuments,
  updateEmployeeDocument,
} from "../../../../../redux/actions";

//Translation
import { useTranslation } from "react-i18next";
import { fetchDocumentTypes } from "../../../../../redux/actions/employees";

const Documents = ({
  profile,
  documents,
  createEmployeeDocuments,
  updateEmployeeDocument,
  documentTypes,
}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation(["companyCreate", "common"]);
  const isEnglish = language === "en";

  const handleBlur = (document) => {
    updateEmployeeDocument(document, true);
  };

  const useStyles = makeStyles(
    (theme) => ({
      addButton: {
        textTransform: "none",
        "&:hover": {
          backgroundColor: theme.palette.common.lightBlue,
          color: "white",
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, [dispatch]);

  const documentList = documents.map((document) => (
    <Document
      key={`Document ${document.id}`}
      document={document}
      documentTypes={documentTypes}
      handleBlur={handleBlur}
      employeeId={profile.id}
    />
  ));

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Box clone display="flex" justifyContent="flex-end">
        <Grid item md={12} xs={12}>
          <Button
            color="primary"
            variant="contained"
            endIcon={<Add />}
            className={classes.addButton}
            onClick={() => {
              createEmployeeDocuments(
                [
                  {
                    name: isEnglish ? "Enter Name" : "ادخل اسم",
                    exp: moment(new Date()).format("YYYY-MM-DD"),
                    referenceNumber: "",
                    issueDate: moment(new Date()).format("YYYY-MM-DD"),
                    typeId: documentTypes[0].id,
                  },
                ],
                true
              );
            }}
          >
            <Typography>{t("companyCreate:addDocument")}</Typography>
          </Button>
        </Grid>
      </Box>
      {documentList}
    </Grid>
  );
};

const mapStateToProps = ({ employee, employees }) => ({
  profile: employee.profile,
  documents: employee.profile.documents || [],
  documentTypes: employees.documentTypes,
});

const mapDispatchToProps = {
  createEmployeeDocuments,
  updateEmployeeDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
