//MaterialUI
import { Box, Button, Grid, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";

import AttendanceSettings from "./AttendanceSettings";
import CheckinSettings from "./CheckinSettings";
import { Edit } from "@material-ui/icons";
import LeaveDetails from "./LeaveDetails";
import Overtime from "./Overtime";
import WorkingHoursDetails from "./WorkingHoursDetails";
//Styles
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const CompanySettings = ({ companySchedule }) => {
  const { t } = useTranslation("common");
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box clone minHeight={"400px"} paddingY={5} paddingX={matchesMD ? 4 : 10}>
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className={classes.addDepartment}
              variant="contained"
              color={edit ? "secondary" : "primary"}
              startIcon={<Edit />}
              onClick={() => setEdit(!edit)}
            >
              {edit ? t("done") : t("edit")}
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <LeaveDetails edit={edit} />
        </Grid>
        <Grid item md={12} xs={12}>
          <WorkingHoursDetails edit={edit} />
        </Grid>
        <Grid item md={12} xs={12}>
          <CheckinSettings edit={edit} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Overtime edit={edit} />
        </Grid>

        <Grid item md={12} xs={12}>
          <AttendanceSettings edit={edit} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanySettings;
