//MaterialUI
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { FormInputField } from "../../../../../../components/common";
import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CheckinLimits = ({ company, updateCompanyProfile, edit }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [limits, setLimits] = useState({
    checkInTimeLimit: company?.checkInTimeLimit,
    checkInCutoff: company?.checkInCutoff,
    checkOutTimeLimit: company?.checkOutTimeLimit,
    checkOutCutoff: company?.checkOutCutoff,
  });

  const handleCheckInSwitchChange = (key) => {
    setLimits({ ...limits, checkInCutoff: !limits.checkInCutoff });
    updateCompanyProfile({
      ...company,
      checkInCutoff: !limits.checkInCutoff,
    });
  };

  const handleCheckOutSwitchChange = (key) => {
    setLimits({ ...limits, checkOutCutoff: !limits.checkOutCutoff });
    updateCompanyProfile({
      ...company,
      checkOutCutoff: !limits.checkOutCutoff,
    });
  };

  return (
    <>
      <Grid item lg={12} xs={12}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={limits.checkInCutoff}
              onChange={() => handleCheckInSwitchChange()}
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
              {t("companyProfile:enableCheckInLimits")}
            </Typography>
          }
        />
      </Grid>

      {limits.checkInCutoff && (
        <Grid item md={6} style={{ marginLeft: 20 }}>
          <FormInputField
            isRequired
            label={t("companyProfile:checkinLimits")}
            name="checkinLimits"
            disabled={!edit || !limits.checkInCutoff}
            onChange={(e) => {
              setLimits({
                ...limits,
                checkInTimeLimit: e.target.value,
              });
              updateCompanyProfile({
                ...company,
                checkInTimeLimit: e.target.value,
              });
            }}
            value={limits.checkInTimeLimit}
            helperText={t("companyProfile:checkinLimitsHelperText")}
            id="checkinInLimits"
            autoComplete="new-password"
            type="number"
          />
        </Grid>
      )}

      <Grid item lg={12} xs={12} style={{ marginTop: 10 }}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={limits.checkOutCutoff}
              onChange={() => handleCheckOutSwitchChange()}
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
              {t("companyProfile:enableCheckOutLimits")}
            </Typography>
          }
        />
      </Grid>

      {limits.checkOutCutoff && (
        <Grid item md={6} style={{ marginLeft: 20 }}>
          <FormInputField
            isRequired
            label={t("companyProfile:checkoutLimits")}
            name="checkoutLimits"
            disabled={!edit || !limits.checkOutCutoff}
            onChange={(e) => {
              setLimits({
                ...limits,
                checkOutTimeLimit: e.target.value,
              });
              updateCompanyProfile({
                ...company,
                checkOutTimeLimit: e.target.value,
              });
            }}
            value={limits.checkOutTimeLimit}
            helperText={t("companyProfile:checkoutLimitsHelperText")}
            id="checkinOutLimits"
            autoComplete="new-password"
            type="number"
          />
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(CheckinLimits);
