import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import Actions from "./Actions";
import { Card } from "@material-ui/core";
import EmployeesTable from "./EmployeesTable";
import GenerateReportModal from "./GenerateReportModal";
import { LoadingSpinnerComponent } from "../../common";
import { fetchEmployees } from "../../../../redux/actions";
import { filterEmployees } from "./utils";
import { getPdfFields } from "./GenerateReportModal/getPdfFields";
import { useTranslation } from "react-i18next";

const Employees = ({ allEmployees, employeesLoading }) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation(["common"]);
  const isEnglish = language === "en";

  const [employees, setEmployees] = useState(allEmployees);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showSalary, setShowSalary] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [pdfFields, setPdfFields] = useState(
    isEnglish ? getPdfFields(t) : getPdfFields(t)
  );

  const filteredEmployees = filterEmployees(
    employees,
    selectedDepartments,
    selectedPositions,
    selectedStatus,
    selectedLocation,
    query
  );

  useEffect(() => {
    dispatch(fetchEmployees());
    setEmployees(allEmployees);
  }, [allEmployees, dispatch]);

  return (
    <>
      <Card>
        {employeesLoading ? (
          <LoadingSpinnerComponent />
        ) : (
          <>
            <Actions
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
              selectedPositions={selectedPositions}
              setSelectedPositions={setSelectedPositions}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedLocations={selectedLocation}
              setSelectedLocations={setSelectedLocation}
              showSalary={showSalary}
              setShowSalary={setShowSalary}
              query={query}
              setQuery={setQuery}
              setOpenModal={setOpenModal}
            />
            <EmployeesTable
              filteredEmployees={filteredEmployees}
              showSalary={showSalary}
              setShowSalary={setShowSalary}
            />
          </>
        )}
      </Card>
      <GenerateReportModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        pdfFields={pdfFields}
        setPdfFields={setPdfFields}
        filteredEmployees={filteredEmployees}
      />
    </>
  );
};

const mapStateToProps = ({ employees }) => ({
  allEmployees: employees.employees,
  employeesLoading: employees.employeesLoading,
});

export default connect(mapStateToProps)(Employees);
