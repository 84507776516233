import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  useMediaQuery,
} from "@material-ui/core";
// Moment
import moment from "moment";
import React, { useEffect, useState } from "react";
// Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  LoadingSpinnerComponent,
  PageHeader,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
// Store
import { fetchPayrollPage } from "../../../redux/actions";
import { SearchInput } from "../../common";
// Components
import AutocompleteInput from "../../common/AutocompleteInput";
import EmployeeRow from "./EmployeeRow";
import ReportsModal from "./ReportsModal.js";
import { normalizeData, payrollPdfFields } from "./data";
//Data & Utils
import { filterEmployees } from "./utils";
import { ComparatorUtil, SortUtil } from "../../../utils";
import { EnhancedTableHeaders, PayrollPageHeaderComponent } from "./components";

/**
 * @category Pages
 * @namespace Payroll
 * @param {import('./types').PayrollProps} PayrollProps
 * @returns {React.JSX.Element}
 * @component
 */

const Payroll = ({
  employees,
  departments,
  positions,
  locations,
  fetchPayrollPage,
  payrollLoading,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "breadcrumbs"]);
  const isEnglish = language === "en";
  const [pdfFields, setPdfFields] = useState(
    isEnglish ? payrollPdfFields(t) : payrollPdfFields(t),
  );

  const [openModal, setOpenModal] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");
  const [_month, setMonth] = useState(moment(new Date()).format("yyyy-MM"));
  const [reportEmployees, setReportEmployees] = useState([]);

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id),
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ]),
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);

  const [location, setLocation] = useState([]);

  const [query, setQuery] = useState("");

  const handleMonthChange = (e) => {
    let newMonth = moment(new Date(e)).format("yyyy-MM");

    setMonth(newMonth);
  };

  useEffect(() => {
    fetchPayrollPage({ month: _month });
  }, [fetchPayrollPage, _month]);

  useEffect(() => {
    setPdfFields(payrollPdfFields(t));
  }, [isEnglish, t]);

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "employeeName":
        const valueA = a.employee.user?.fullName.toLowerCase();
        const valueB = b.employee.user?.fullName.toLowerCase();
        return valueA?.localeCompare(valueB);
      case "totalSalary":
        return a.salary.netSalary - b.salary.netSalary;
      case "difference":
        const calculateDifference = (obj) => {
          const modificationAmount = obj.modifications.reduce(
            (total, modification) => total + (Number(modification.amount) || 0),
            0,
          );
          return Number(obj.salary.netSalary) - modificationAmount;
        };
        return calculateDifference(b) - calculateDifference(a);
      default:
        return orderBy;
    }
  }

  const getComparator = (order, orderBy) =>
    ComparatorUtil(order, orderBy, descendingComparator);
  const stableSort = (array, comparator) => SortUtil(array, comparator);

  const headCells = [
    { id: "employeeName", label: t("employeeName") },
    { id: "totalSalary", label: t("totalSalary") },
    { id: "difference", label: t("difference") },
  ];

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const employeesList = filterEmployees(
    employees,
    department,
    position,
    location,
    query,
  );

  const employeesForPdf = normalizeData(
    employeesList,
    pdfFields,
    isEnglish,
    _month,
  );

  const listOfEmployees = stableSort(
    employeesList,
    getComparator(order, orderBy),
  )
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((employee) => (
      <EmployeeRow
        t={t}
        isEnglish={isEnglish}
        employee={employee}
        key={employee.id}
      />
    ));

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.payroll.name}`),
      isLast: true,
    },
  ];

  return (
    <Grid
      container
      justifyContent={matchesMD ? "center" : "flex-start"}
      spacing={4}
    >
      <Grid item md={11} xs={12}>
        <Grid
          container
          justifyContent={matchesMD ? "center" : "flex-start"}
          spacing={4}
        >
          <Grid item md={12} xs={9}>
            <PageHeader
              paths={navigationPaths}
              title={t(`breadcrumbs:${paths.payroll.header}`)}
            />
          </Grid>
          <Grid item md={12} xs={9}>
            <Box clone paddingY={1}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <AutocompleteInput
                    value={department}
                    options={departments.map((dep) => ({
                      label: isEnglish ? dep.name : dep.nameAr,
                      value: dep.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setDepartment(e)}
                    label={t("department")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AutocompleteInput
                    multiple={true}
                    value={position}
                    options={_positions().map((pos) => ({
                      label: isEnglish ? pos.name : pos.nameAr,
                      value: pos.id,
                    }))}
                    onChange={(e) => setPosition(e)}
                    label={t("position")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AutocompleteInput
                    value={location}
                    options={locations

                      .filter((loc) => {
                        const locationsSelected = location.map(
                          (loc) => loc.value,
                        );
                        return !locationsSelected.includes(loc.id);
                      })

                      .map((loc) => ({
                        label: isEnglish ? loc?.name : loc?.nameAr,
                        value: loc.id,
                      }))}
                    multiple={true}
                    onChange={(e) => setLocation(e)}
                    label={t("location")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <SearchInput
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t("search")}
                    position="start"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {payrollLoading ? (
            <LoadingSpinnerComponent />
          ) : (
            <Grid item md={12} xs={10}>
              <Box bgcolor="white" borderRadius={15}>
                <PayrollPageHeaderComponent
                  handleMonthChange={handleMonthChange}
                  displayMonth={_month}
                  onClick={() => {
                    setOpenModal(true);
                    setReportEmployees(employeesForPdf);
                  }}
                  t={t}
                />
                <TableContainer>
                  <Table>
                    <EnhancedTableHeaders
                      order={order}
                      orderBy={orderBy}
                      onSorting={handleSort}
                      headCells={headCells}
                      isEnglish={isEnglish}
                    />
                    <TableBody>{listOfEmployees}</TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {openModal && (
        <ReportsModal
          isVisible={openModal}
          documentHeaders={pdfFields}
          closeModal={() => setOpenModal(false)}
          department={department}
          position={position}
          location={location}
          searchQuery={query}
          month={_month}
        />
      )}
    </Grid>
  );
};
const mapStateToProps = ({ company, employees }) => ({
  departments: company.departments,
  employees: employees.payroll,
  payrollLoading: employees.payrollLoading,
  positions: company.positions,
  locations: company.locations,
});

const mapDispatchToProps = {
  fetchPayrollPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
