// Styling
import "./index.css";
//I18n
import "./i18n";

//Error Logging
import * as serviceWorker from "./serviceWorker";

/* istanbul ignore file */
import React, { Suspense } from "react";

//apolloClient
import { ApolloProvider } from "@apollo/client";
import { Box } from "@material-ui/core";
import App from "./App";
// Store
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
//Components
import { ThemeProvider } from "@material-ui/core/styles";
import ReactLoading from "react-loading";
import store from "./redux";
import client from "./redux/actions/client";
import theme from "./styles/theme";

// Sentry.init({
//   dsn: "https://be64be3e72394ccf95eb51c8eda03f93@o1253403.ingest.sentry.io/4503935327535104",
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.createRoot(document.getElementById("root")).render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense
          fallback={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              height={"100vh"}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fff"}
                height={667}
                width={375}
              />
            </Box>
          }
        >
          <div style={{ overflowX: "hidden" }}>
            <App />
          </div>
        </Suspense>
      </Provider>
    </ThemeProvider>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
