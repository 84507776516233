import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

// Components
import { FormInputField, FormSelectInput } from "../../../common";

// Data
import { countries } from "../../../common/DropDownsData";

// MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";

const ContactInformation = ({ register, errors, control, errorFields }) => {
  const { t } = useTranslation("common");

  return (
    <Box clone width={"100%"}>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("name")}
            name="name"
            errors={errors.name?.message}
            inputRef={register}
            id="name"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("email")}
            name="email"
            errors={errors.email?.message}
            inputRef={register}
            id="email"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("phoneNumber")}
            name="number"
            errors={errors.number?.message}
            inputRef={register}
            id="number"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>{t("pocAddress")}</Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Controller
            name="address.country"
            control={control}
            render={({ ref, ...props }) => (
              <FormSelectInput
                isRequired
                selectOptions={countries(t)}
                label={t("country")}
                error={errors.address?.country?.message}
                inputRef={ref}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("address1")}
            name="address.address1"
            errors={errors.address?.address1?.message}
            inputRef={register}
            id="address.address1"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            label={t("address2")}
            name="address.address2"
            errors={errors.address?.address2?.message}
            inputRef={register}
            id="address.address2"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("cityOrTown")}
            name="address.cityOrTown"
            errors={errors.address?.cityOrTown?.message}
            inputRef={register}
            id="address.cityOrTown"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            label={t("postcode")}
            name="address.postcode"
            errors={errors.address?.postcode?.message}
            inputRef={register}
            id="address.postcode"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            label={t("zipcode")}
            name="address.zipCode"
            errors={errors.address?.zipCode?.message}
            inputRef={register}
            id="address.zipCode"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            isRequired
            label={t("stateOrProvince")}
            name="address.stateOrProvince"
            errors={errors.address?.stateOrProvince?.message}
            inputRef={register}
            id="address.stateOrProvince"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            label={t("paciNumber")}
            name="address.paciNumber"
            errors={errors.address?.paciNumber?.message}
            inputRef={register}
            id="address.paciNumber"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInformation;
