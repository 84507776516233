//MaterialUI
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import { connect } from "react-redux";
//Actions
import { updateCompanyProfile } from "../../../../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

//Components

const AttendanceSystem = ({ company, updateCompanyProfile, edit }) => {
  const { t } = useTranslation("common");

  const [enableAttendaceSystem, setEnableAttendaceSystem] = useState(
    company?.useAttendance
  );

  const handleSwitchChange = () => {
    setEnableAttendaceSystem(!enableAttendaceSystem);

    updateCompanyProfile({
      ...company,
      useAttendance: !enableAttendaceSystem,
    });
  };

  return (
    <>
      <Box clone paddingX={2} paddingTop={6}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <FormControlLabel
              disabled={!edit}
              control={
                <Switch
                  color="primary"
                  checked={enableAttendaceSystem}
                  onChange={() => handleSwitchChange()}
                />
              }
              labelPlacement="start"
              label={
                <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
                  {t("enableAttendaceSystem")}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSystem);
