import React, { useState } from "react";

//Components

//MaterialUI
import {
  Grid,
  useMediaQuery,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";

//Components
import { FormInputField } from "../../../../common";

//Translation
import { useTranslation } from "react-i18next";

//Redux
import { connect } from "react-redux";
import { changePassword, resetErrors } from "../../../../../redux/actions";

const Password = ({ user, changePassword, resetErrors, errorFields }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [alert, setAlert] = useState("");
  const [data, setData] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box clone minHeight={"400px"} paddingY={5} paddingX={matchesMD ? 4 : 10}>
      <Grid container justifyContent="center" spacing={4}>
        {alert.length > 0 && (
          <Grid item md={12} xs={12}>
            <Alert severity="success" onClick={() => setAlert("")}>
              {alert}
            </Alert>
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h2" color="textPrimary">
              {t("companyProfile:changePassword")}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={7} xs={11}>
          <FormInputField
            disabled={!user.verified}
            isRequired
            type="password"
            label={t("companyProfile:oldPassword")}
            name="oldPassword"
            onChange={(e) => setData({ ...data, oldPassword: e.target.value })}
            value={data.oldPassword}
            error={data.oldPassword === ""}
            id="oldPassword"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={7} xs={11}>
          <FormInputField
            disabled={!user.verified}
            isRequired
            type="password"
            label={t("companyProfile:newPassword")}
            name="newPassword1"
            onChange={(e) => setData({ ...data, newPassword1: e.target.value })}
            value={data.newPassword1}
            error={data.newPassword1 === ""}
            id="newPassword1"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={7} xs={11}>
          <FormInputField
            disabled={!user.verified}
            isRequired
            type="password"
            label={t("companyProfile:confirmNewPassword")}
            name="newPassword2"
            onChange={(e) => setData({ ...data, newPassword2: e.target.value })}
            value={data.newPassword2}
            error={data.newPassword2 === ""}
            id="newPassword2"
            autoComplete="new-password"
          />
        </Grid>
        {errorFields.map((errorField, idx) => (
          <Box key={errorField.code + idx} clone width="100%">
            <Typography align="center" style={{ color: "red" }}>
              {errorField.message}
            </Typography>
          </Box>
        ))}
        <Grid item md={12} xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (
                  data.oldPassword !== "" &&
                  data.newPassword1 !== "" &&
                  data.newPassword2 !== ""
                ) {
                  resetErrors();
                  changePassword(data, user, () =>
                    setAlert(t("companyProfile:passwordChanged"))
                  );
                }
              }}
            >
              {t("changePassword")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ auth, errors }) => ({
  user: auth.user,
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = {
  resetErrors,
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
