//Material UI//
import { Box, Divider, Grid, Typography } from "@material-ui/core";

//Icons
import { NotesSharp } from "@material-ui/icons";
//Components
import SearchEmployees from "./SearchEmployee";
import { useTranslation } from "react-i18next";

const ApproveRequests = () => {
  const { t } = useTranslation(["common", "companyProfile"]);
  return (
    <Grid item md={12}>
      <Grid container alignItems="center">
        <Grid item md={1}>
          <NotesSharp color="primary" fontSize="large" />
        </Grid>
        <Grid item md={11}>
          <Typography variant="subtitle1">
            {t("companyProfile:requestAccess")}
          </Typography>
        </Grid>
      </Grid>

      <Box paddingY={4}>
        <Grid item md={6}>
          <Typography variant="subtitle2" color="textSecondary">
            {t("companyProfile:requestAccessDescription")}
          </Typography>
        </Grid>
      </Box>
      <Box clone paddingX={7}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("common:employeeName")}
        </Typography>
      </Box>
      <Box paddingY={3}>
        <Grid item md={12}>
          <Box paddingY={2}>
            <Divider fullwidth />
          </Box>
          <Box paddingY={2}>
            <SearchEmployees />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ApproveRequests;
