import {
  IconButton,
  TablePagination as MuiTablePagination,
  makeStyles,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import React from "react";
import { useTranslation } from "react-i18next";

function TablePaginationActions(props) {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  const classes = useStyles();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const isFirtPage = page === 0;
  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isFirtPage}
        aria-label="previous page"
      >
        {isEnglish ? (
          <KeyboardArrowLeft
            style={{ color: isFirtPage ? "#F6F8FA" : "#3D5E77" }}
          />
        ) : (
          <KeyboardArrowRight
            style={{ color: isFirtPage ? "#F6F8FA" : "#3D5E77" }}
          />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label="next page"
      >
        {isEnglish ? (
          <KeyboardArrowRight
            style={{ color: isLastPage ? "#F6F8FA" : "#3D5E77" }}
          />
        ) : (
          <KeyboardArrowLeft
            style={{ color: isLastPage ? "#F6F8FA" : "#3D5E77" }}
          />
        )}
      </IconButton>
    </div>
  );
}

export const TablePagination = ({
  onChangePage,
  count,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const useStyles = makeStyles((theme) => ({
    icon: {
      color: "#3D5E77",
    },
  }));

  const classes = useStyles();

  return (
    count > 5 && (
      <MuiTablePagination
        SelectProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        labelRowsPerPage={t("rowsPerPage")}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          isEnglish
            ? `${from} - ${to} ${t("of")} ${count} `
            : `${from} - ${to} ${t("of")} ${count}`
        }
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    )
  );
};

export default TablePagination;
