import { Box, Button, Grid } from "@material-ui/core";

import { CompanyStructureModal } from "../../../components/common";
import React from "react";
import TimePickerField from "../../../components/common/TimePickerField";
import { connect } from "react-redux";
import moment from "moment";
import { revertTimeZone } from "../../../utils";
import { updateEmployeeAttendance } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const AttendanceModal = ({
  openModalState,
  setOpenModalState,
  attendanceState,
  setAttendanceState,
  updateEmployeeAttendance,
}) => {
  const { t } = useTranslation("common");

  const handleUpdateTimes = () => {
    const checkIn = revertTimeZone(attendanceState.checkIn);
    const checkOut = revertTimeZone(attendanceState.checkOut);
    updateEmployeeAttendance({
      ...attendanceState,
      id: attendanceState.id,
      employeeId: attendanceState.employee.id,
      checkIn: checkIn,
      checkOut: checkOut,
    });

    setOpenModalState(false);
  };

  const onClose = () => {
    setOpenModalState(false);
  };

  return (
    <CompanyStructureModal
      onClose={onClose}
      open={openModalState}
      fadeIn={openModalState}
      title={t("modifyCheckin/CheckoutTime")}
    >
      <Box height="auto" maxHeight="90vh" margin={4}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TimePickerField
              label={t("checkInTime")}
              name="checkIn"
              onChange={(e) =>
                setAttendanceState({
                  ...attendanceState,
                  checkIn: moment(e),
                })
              }
              value={attendanceState.checkIn}
            />
          </Grid>

          <Grid item md={6}>
            <TimePickerField
              label={t("checkOutTime")}
              name="checkOut"
              onChange={(e) => {
                const checkOutValue = moment(new Date(e));
                const checkInValue = moment(attendanceState.checkIn);
                const isNextDay = checkOutValue.hour() < checkInValue.hour();
                if (isNextDay) {
                  checkInValue.add(1, "days"); // add 1 day to checkIn
                }
                checkOutValue
                  .year(checkInValue.year())
                  .month(checkInValue.month())
                  .date(checkInValue.date())
                  .format("LLL");

                console.log(checkOutValue);
                setAttendanceState({
                  ...attendanceState,
                  checkOut: checkOutValue,
                });
              }}
              value={attendanceState.checkOut}
            />
          </Grid>

          <Grid item container justifyContent="flex-end" md={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateTimes}
              disabled={!attendanceState.checkIn || !attendanceState.checkOut}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CompanyStructureModal>
  );
};

const mapDispatchToProps = {
  updateEmployeeAttendance,
};

export default connect(null, mapDispatchToProps)(AttendanceModal);
