import React from "react";
import { Stepper, Step, StepLabel, Typography, Grid } from "@material-ui/core";
import FormStepperFooter from "./FormStepperFooter";

//Translation
import { useTranslation } from "react-i18next";

const FormStepper = ({ step, setStep, steps, children }) => {
  const { t } = useTranslation("common");

  const isLastStep = step === steps.length - 1;

  const isStepOptional = (step) => step === 1;

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">{t("optional")}</Typography>
            );
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container justifyContent="center">
        {children}
      </Grid>
      <FormStepperFooter
        step={step}
        isLastStep={isLastStep}
        handleBack={handleBack}
      />
    </>
  );
};

export default FormStepper;
