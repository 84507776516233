import { Box, Button, Card, CardHeader, Grid } from "@material-ui/core";

import { Add } from "@material-ui/icons";
import HolidayModal from "./HolidayModal";
import HolidayRow from "./HolidayRow";
import DeleteHolidayModal from "./DeleteHolidayModal/index";
import { LoadingSpinnerComponent } from "../../../common";
import Table from "../../../common/Table";
import { connect } from "react-redux";
import moment from "moment/moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const OfficialHolidays = ({
  employee,
  updateOrCreateOfficialHoliday,
  holidays,
  vacationLoading,
}) => {
  const { t } = useTranslation(["companyProfile", "common"]);

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [holiday, setHoliday] = useState({
    name: "",
    nameAr: "",
    start: moment(new Date()).format("yyyy-MM-DD"),
    end: moment(new Date()).format("yyyy-MM-DD"),
  });

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  let headerLabels = [
    t("common:name"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:edit"),
    t("common:delete"),
  ];

  let data = holidays && [...holidays];

  let tableBody;

  if (holidays) {
    tableBody = data
      ?.sort((a, b) => new Date(b.checkIn) - new Date(a.checkIn))
      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map((holiday, i) => {
        return (
          <HolidayRow
            holiday={holiday}
            setHoliday={setHoliday}
            setOpen={setOpen}
            setOpenDeleteModal={setOpenDeleteModal}
            key={i}
          />
        );
      });
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={12} xs={12}>
          <Box clone display="flex" flexDirection="row-reverse" paddingTop={2}>
            <Grid item md={12} xs={10}>
              <Button
                endIcon={<Add />}
                onClick={() => setOpen(true)}
                color="primary"
                variant="contained"
              >
                {t("companyProfile:addHoliday")}
              </Button>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Card>
            <CardHeader title={t("companyProfile:officialHolidays")} />

            {vacationLoading ? (
              <LoadingSpinnerComponent />
            ) : (
              <Table
                data={data || []}
                labels={headerLabels}
                body={tableBody}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                withPagination
              />
            )}
          </Card>
        </Grid>
      </Grid>
      <HolidayModal
        open={open}
        setOpen={setOpen}
        holiday={holiday}
        setHoliday={setHoliday}
      />
      <DeleteHolidayModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        holiday={holiday}
      />
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  holidays: company?.vacation?.officialHolidays,
  vacationLoading: company.vacationLoading,
});

export default connect(mapStateToProps)(OfficialHolidays);
