//Material UI
import { Avatar, Box, Typography } from "@material-ui/core";
//Moment
import moment from "moment";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import { CustomTableCell, CustomTableRow } from "../../common";

const DocumentItem = ({ document }) => {
  console.log(">>>>>", document);

  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <Box clone whiteSpace="nowrap">
      <CustomTableRow to={`employees/${document.employee.id}`}>
        <CustomTableCell>{document.referenceNumber}</CustomTableCell>
        <CustomTableCell>{document.name}</CustomTableCell>
        <CustomTableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={document.employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {document.employee.user
                  ? document.employee.user?.fullName
                  : document.employee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish
            ? document.employee.department.name
            : document.employee.department.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish
            ? document.employee.position.name
            : document.employee.position.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {moment(new Date(document.exp)).format("DD-MM-yyyy")}{" "}
        </CustomTableCell>
        <CustomTableCell>
          {" "}
          {isEnglish ? document.type?.name : document.type?.nameAr}
        </CustomTableCell>
      </CustomTableRow>
    </Box>
  );
};

export default DocumentItem;
