import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createGeneralRequest } from "../../../../../redux/actions";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../common";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import moment from "moment";
// import { Alert } from "@material-ui/lab";
import LeaveFile from "../../../../common/LeaveFile";

const GeneralRequestModal = ({ open, setOpen }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  // const history = useHistory();

  const [generalRequest, setGeneralRequest] = useState({
    date: moment().format("YYYY-MM-DD"),
    reason: "",
    body: "",
    document: "",
  });
  // const [error, setError] = useState("");
  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    dispatch(createGeneralRequest(generalRequest));
    onClose();
  };

  return (
    <CompanyStructureModal
      open={open}
      handleClose={onClose}
      fadeIn={open}
      title={t("generalRequest")}
    >
      <Box height={"auto"} maxHeight={"90vh"} margin={4}>
        {/* {error && <Alert severity="error">{error}</Alert>} */}
        <Box display="flex" flexDirection="column">
          <Box marginBottom={1}>
            <DatePickerField
              isRequired={true}
              label={t("date")}
              value={generalRequest.date}
              onChange={(date) =>
                setGeneralRequest({
                  ...generalRequest,
                  date: date,
                })
              }
            />
          </Box>
          <Box marginBottom={1}>
            <FormInputField
              label={t("reason")}
              value={generalRequest.reason}
              onChange={(e) =>
                setGeneralRequest({
                  ...generalRequest,
                  reason: e.target.value,
                })
              }
            />
          </Box>
          {/* create a text input for the request body multiple lines of text */}
          <Grid item md={12} xs={12}>
            <TextField
              label={t("body")}
              multiline
              fullWidth
              isRequired={true}
              value={generalRequest.body}
              onChange={(e) =>
                setGeneralRequest({
                  ...generalRequest,
                  body: e.target.value,
                })
              }
            />
          </Grid>
          <Box marginTop={1}>
            <LeaveFile
              value={generalRequest.document}
              setValue={(value) =>
                setGeneralRequest({
                  ...generalRequest,
                  document: value,
                })
              }
            />
          </Box>
          <Grid container item direction="row" justifyContent="flex-end">
            <Box margin={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose}
                fullWidth
              >
                {t("cancel")}
              </Button>
            </Box>
            <Box margin={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                fullWidth
              >
                {t("submit")}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </CompanyStructureModal>
  );
};

export default GeneralRequestModal;
