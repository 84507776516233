import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import DeleteModificationModal from "./DeleteModificationModal";
import ModificationModal from "./ModificationModal";
import ModificationRow from "./ModificationRow";
import { PayrollTable } from "../../../../common";
import React from "react";
import RecurringModificationModal from "./RecurringModificationModal";
import RecurringModificationRow from "./RecurringModificationRow";
import { useState } from "react";

const ModificationTable = ({
  employee,
  label,
  actionLabel,
  headerLabels,
  data,
  enableActions,
  isEarning,
  isRecurring,
}) => {
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const initialState = isRecurring
    ? {
        amount: 0.0,
        reason: "",
        startDate: new Date(),
        endDate: null,
        receiveDuringVacation: true,
        attachment: "",
        employeeId: +employee.id,
      }
    : {
        amount: 0.0,
        reason: "",
        month: new Date(),
        attachment: "",
        employeeId: +employee.id,
      };

  const [modification, setModification] = useState(initialState);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const Row = isRecurring ? RecurringModificationRow : ModificationRow;
  const EditOrCreateModal = isRecurring
    ? RecurringModificationModal
    : ModificationModal;

  const clearModalFields = () => {
    if (isRecurring) {
      setModification({
        amount: 0.0,
        reason: "",
        startDate: new Date(),
        endDate: null,
        receiveDuringVacation: true,
        attachment: "",
        employeeId: +employee.id,
      });
    } else {
      setModification({
        amount: 0.0,
        reason: "",
        month: new Date(),
        attachment: "",
        employeeId: +employee.id,
      });
    }
  };
  const tableBody = data
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((earning, i) => (
      <Row
        modificationItem={earning}
        setModification={setModification}
        setOpen={setOpen}
        setOpenDeleteModal={setOpenDeleteModal}
        employeeId={employee.id}
        key={(earning, +i)}
        enableActions={enableActions}
      />
    ));

  return (
    <>
      <PayrollTable
        data={data}
        labels={headerLabels}
        tableBody={tableBody}
        employee={employee}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        title={label}
        withPagination
        action={
          enableActions && (
            <Button
              size="small"
              endIcon={<Add />}
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setOpen(true);
                clearModalFields();
              }}
            >
              {actionLabel}
            </Button>
          )
        }
      />
      <EditOrCreateModal
        modification={modification}
        setModification={setModification}
        open={open}
        setOpen={setOpen}
        employeeId={+employee.id}
        isEarning={isEarning}
      />
      <DeleteModificationModal
        modification={modification}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        isRecurring={isRecurring}
      />
    </>
  );
};

export default ModificationTable;
