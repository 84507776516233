//Material UI
import { Avatar, Box, Typography } from "@material-ui/core";
//Moment
import moment from "moment";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import { CustomTableCell, CustomTableRow } from "../../../common";

const RequestItem = ({ request, requestsStatus, requestsKind }) => {
  const status = requestsStatus.find(
    (_status) => _status.value === request.status,
  );

  const kind = requestsKind.find((_kind) => _kind.value === request.kind);

  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <Box clone whiteSpace="nowrap">
      <CustomTableRow to={`/request/${request.id}`}>
        <CustomTableCell>{request.id}</CustomTableCell>
        <CustomTableCell>
          {" "}
          {isEnglish ? kind?.name : kind?.nameAr}
          {kind.name === "Leave" ? ` / ${request.leaveRequest.kind}` : ""}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? status.name : status.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {moment(new Date(request.createdOn)).format("DD-MM-yyyy")}{" "}
        </CustomTableCell>
      </CustomTableRow>
    </Box>
  );
};

export default RequestItem;
