import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  fetchEmployeesWithHrAccess,
  updateHrAccess,
} from "../../../../../redux/actions";
//Components
import AutocompleteInput from "../../../../common/AutocompleteInput";

//Material UI

const SearchEmployees = ({ employees, hrEmployees, updateHrAccess }) => {
  const [employeesWithHrAcces, setEmployeesWithHrAcces] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployeesWithHrAccess());

    setEmployeesWithHrAcces(
      hrEmployees.map((emp) => ({
        label: emp.user ? emp.user?.fullName : emp?.fullName,
        value: emp.id,
      }))
    );
  }, [dispatch, hrEmployees]);

  const handleChange = (e) => {
    if (e.length > employeesWithHrAcces.length) {
      let addedEmployees = e.filter(
        (emp) => employeesWithHrAcces.indexOf(emp) === -1
      );
      addedEmployees.forEach((emp) =>
        updateHrAccess({ employeeId: emp.value, status: true })
      );
    } else {
      let removedEmployees = employeesWithHrAcces.filter(
        (emp) => e.indexOf(emp) === -1
      );
      removedEmployees.forEach((emp) =>
        updateHrAccess({ employeeId: emp.value, status: false })
      );
    }
    setEmployeesWithHrAcces(e);
  };

  return (
    <AutocompleteInput
      multiple={true}
      value={employeesWithHrAcces}
      options={employees
        .filter((employee) => !employee.hrAccess && employee.user)
        .map((employee) => ({
          label: employee.user ? employee.user?.fullName : employee?.fullName,
          value: employee.id,
        }))}
      onChange={(e) => handleChange(e)}
    />
  );
};
const mapStateToProps = ({ employees }) => ({
  employees: employees.employees,
  hrEmployees: employees.employeesWithHrAccess,
});

const mapDispatchToProps = {
  updateHrAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchEmployees);
