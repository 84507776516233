import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Section } from "../../common";

const Resource = ({ resources, resourcesLoading }) => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation("announcement");
  const isEnglish = language === "en";
  const { resourceId } = useParams();

  if (resourcesLoading) return <></>;

  const resource = resources.find((res) => res.id === resourceId);

  if (!resource) return <Redirect to="/" />;

  let description = isEnglish ? resource.description : resource.descriptionAr;
  description = description
    .split("/n")
    .map((line) => <Typography key={line}>{line}</Typography>);

  const links = resource.links.map((link) => (
    <ListItem key={link.link}>
      <a href={link.link} target="_blank" rel="noreferrer">
        {isEnglish
          ? link.description
            ? link.description
            : link.link
          : link.descriptionAr
          ? link.descriptionAr
          : link.link}
      </a>
    </ListItem>
  ));

  const files = resource.files.map((file) => (
    <ListItem key={file.file}>
      <a href={file.file} download target="_blank" rel="noreferrer">
        {isEnglish
          ? file.description
            ? file.description
            : file.file
          : file.descriptionAr
          ? file.descriptionAr
          : file.file}
      </a>
    </ListItem>
  ));

  return (
    <Section>
      <Box clone bgcolor="white" borderRadius={10}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h3" color="primary">
              {isEnglish ? resource.name : resource.nameAr}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            {description}
          </Grid>
          {files.length !== 0 ||
            (links.length !== 0 && (
              <Grid item md={12} xs={12}>
                <Typography variant="h3" color="primary">
                  {t("attachments")}
                </Typography>
              </Grid>
            ))}
          {files.length !== 0 && (
            <Grid item md={6} xs={12}>
              <Typography variant="h4" color="secondary">
                {t("files")}
              </Typography>
              <List>{files}</List>
            </Grid>
          )}
          {links.length !== 0 && (
            <Grid item md={6} xs={12}>
              <Typography variant="h4" color="secondary">
                {t("links")}
              </Typography>
              <List>{links}</List>
            </Grid>
          )}
        </Grid>
      </Box>
    </Section>
  );
};

const mapStateToProps = ({ company }) => ({
  resources: company.resources,
  resourcesLoading: company.resourcesLoading,
});

export default connect(mapStateToProps)(Resource);
