import { Box, Button, Card, CardContent, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import CompanyAddress from "./CompanyAddress";
import CompanyImage from "./CompanyImage";
import CompanyInformation from "./CompanyInformation.js";
import ContactInformation from "./ContactInformation";
import { FileCopy } from "@material-ui/icons";
import { PageHeader } from "../../../common";
import PocAddress from "./PocAddress";
import { fetchCompany } from "../../../../../redux/actions";
import { paths } from "../../../../utils";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const CompanyEdit = ({ address, poc, fetchCompany }) => {
  const { t } = useTranslation(["breadcrumbs"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const [zipCodeHelper, setZipCodeHelper] = useState("");
  const [postcodeHelper, setPostcodeHelper] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [phoneHelper, setPhoneHelper] = useState("");
  const [zipCodeHelperPoc, setZipCodeHelperPoc] = useState("");
  const [postcodeHelperPoc, setPostcodeHelperPoc] = useState("");

  const [addressState, setAddress] = useState({
    ...address,
    country: address?.country?.code,
  });

  const [pocState, setPoc] = useState({ ...poc });

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.company.profile.name}`),
      link: paths.company.profile.link,
    },
    {
      name: t(`breadcrumbs:${paths.company.profile.edit.name}`),
      isLast: true,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader paths={navigationPaths} paddingBottom={5} />
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" spacing={4}>
            <CompanyImage />
            <CompanyInformation />
            <CompanyAddress
              addressState={addressState}
              setAddress={setAddress}
              address={address}
              zipCodeHelper={zipCodeHelper}
              setZipCodeHelper={setZipCodeHelper}
              postcodeHelper={postcodeHelper}
              setPostcodeHelper={setPostcodeHelper}
            />
            <ContactInformation
              pocState={pocState}
              setPoc={setPoc}
              poc={poc}
              emailHelper={emailHelper}
              setEmailHelper={setEmailHelper}
              phoneHelper={phoneHelper}
              setPhoneHelper={setPhoneHelper}
            />
            <PocAddress
              zipCodeHelperPoc={zipCodeHelperPoc}
              setZipCodeHelperPoc={setZipCodeHelperPoc}
              postcodeHelperPoc={postcodeHelperPoc}
              setPostcodeHelperPoc={setPostcodeHelperPoc}
            />
            <Grid item md={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color={"primary"}
                  startIcon={<FileCopy />}
                  onClick={() => {
                    history.push("/");
                    dispatch(fetchCompany());
                  }}
                >
                  {t("save")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  address: company.address,
  poc: company.poc,
});

const mapDispatchToProps = {
  fetchCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit);
