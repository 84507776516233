import React from "react";
import { useState } from "react";

//Translation
import { useTranslation } from "react-i18next";

//Components
import Topbar from "./Topbar";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const { t } = useTranslation("common");
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  let navItems = [];
  navItems = [
    {
      to: "/",
      title: t("home"),
    },
    {
      to: "/register",
      title: t("register"),
    },
  ];

  return (
    <>
      <Topbar
        navItems={navItems}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        isMobileNavOpen={isMobileNavOpen}
      />
      <MobileNav
        navItems={navItems}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
    </>
  );
};

export default Navbar;
