//Material UI
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
//Moment
import moment from "moment";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";

import { TablePagination } from "../../redesign/components/common";
import CustomTableCell from "./CustomTableCell";
//Components
import CustomTableRow from "./CustomTableRow";
import { leavesKind, requestsStatus } from "./DropDownsData";

const EmployeeVacationTable = ({ data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const columns = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:amount"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:createdOn"),
    t("common:status"),
    t("common:lastModified"),
    t("common:modifiedBy"),
  ];
  const headerRow = columns.map((column, i) => (
    <CustomTableCell key={(column, +i)}>
      <Typography variant="subtitle2" color="textPrimary">
        {column}
      </Typography>
    </CustomTableCell>
  ));

  const tableRow = data
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((request, i) => {
      const kind = () => {
        let kindObj = leavesKind.find(
          (kind) => kind.value === request.leaveRequest.kind
        );
        if (kindObj) {
          if (isEnglish) return kindObj.name;
          return kindObj.nameAr;
        }
        return "";
      };
      const status = () => {
        let statusObj = requestsStatus.find(
          (status) => status.value === request.status
        );
        if (isEnglish) return statusObj.name;
        return statusObj.nameAr;
      };
      return (
        <CustomTableRow to={`/request/${request.id}`} key={(request, +i)}>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {request.id}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {request.leaveRequest.reason}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {kind()}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {request.leaveRequest.amount}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {moment(new Date(request.leaveRequest.date)).format("DD-MM-yyyy")}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {moment(new Date(request.leaveRequest.endDate)).format(
                "DD-MM-yyyy"
              )}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {moment(new Date(request.createdOn)).format("DD-MM-yyyy hh:mm a")}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {status()}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {moment(new Date(request.lastModified)).format(
                "DD-MM-yyyy hh:mm a"
              )}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="subtitle2" color="textSecondary">
              {request.lastModifiedBy?.fullName}
            </Typography>
          </CustomTableCell>
        </CustomTableRow>
      );
    });

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 5);
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <Box clone bgcolor="inherit">
              <CustomTableRow>{headerRow}</CustomTableRow>
            </Box>
          </TableHead>

          <TableBody>{tableRow}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default EmployeeVacationTable;
