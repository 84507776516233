// Reducers
import { authReducer } from "./authentication";
import { combineReducers } from "redux";
import { companyReducer } from "./company";
import { employeeReducer } from "./employee";
import { employeesReducer } from "./employees";
import { errorReducer } from "./errors";
import { requestsReducer } from "./requests";

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  company: companyReducer,
  employees: employeesReducer,
  employee: employeeReducer,
  requests: requestsReducer,
});

export default rootReducer;
