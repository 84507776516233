import { Documents } from "../../../common/index";
import { connect } from "react-redux";

const CompanyDocuments = ({ documents }) => {
  return <Documents forCompanyPage data={documents} />;
};

const mapStateToProps = ({ company }) => ({
  documents: company.documents,
});

export default connect(mapStateToProps)(CompanyDocuments);
