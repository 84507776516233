import { Add, Edit } from "@material-ui/icons";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  createDepartment,
  updateDepartment,
} from "../../../../../../../redux/actions";

import { FormInputField } from "../../../../../../../components/common";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";

const DepartmentForm = ({ employees, department, setOpen }) => {
  const dispatch = useDispatch();

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";

  const [departmentState, setDepartment] = useState(
    department
      ? department
      : {
          name: "",
          nameAr: "",
        }
  );

  const classes = useStyles();

  const handleAddDepartment = () => {
    if (department) {
      dispatch(
        updateDepartment(
          {
            ...departmentState,
            departmentId: department.id,
          },
          setOpen
        )
      );
    } else {
      dispatch(createDepartment(departmentState, setOpen));
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container md={12} spacing={3} style={{ margin: 4 }}>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={isEnglish}
              label={t("common:name")}
              value={departmentState.name}
              onChange={(e) =>
                setDepartment({ ...departmentState, name: e.target.value })
              }
              id="name"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={!isEnglish}
              label={t("common:arabicName")}
              value={departmentState.nameAr}
              onChange={(e) =>
                setDepartment({ ...departmentState, nameAr: e.target.value })
              }
              id="arabicName"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>

        <Box
          clone
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          paddingX={2}
          paddingY={5}
        >
          <Grid item md={12} sm={12} xs={11} style={{ margin: 10 }}>
            <Button
              disableRipple
              className={classes.actionButton}
              variant="contained"
              onClick={handleAddDepartment}
              endIcon={department ? <Edit /> : <Add />}
            >
              <Typography>
                {department ? t("common:save") : t("common:add")}
              </Typography>
            </Button>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ employees }) => ({
  employees: employees.employees,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentForm);
