//Material UI
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
} from "../../../../components/common";
import React, { useState } from "react";
import { deleteLeaveCredit, deleteLeaveDebit } from "../../../../redux/actions";
import EditLeave from "../../Employer/EmployeeProfile/Vacations/EditLeave";

import { DeleteOutline, Edit } from "@material-ui/icons";
import Table from "../Table";
import { connect } from "react-redux";
import { getFileName } from "../../../../helpers/getFileName";
import { useTranslation } from "react-i18next";

const RequestsTable = ({
  labels,
  data,
  toRow,
  type = "vacation",
  icon,
  title,
  canDeleteRecord = false,
  forLeaveCredit = false,
  forLeaveDebit = false,
  deleteLeaveCredit,
  deleteLeaveDebit,
  leaveCreditDebitLoading,
  action,
  setOpenEdit,
  setLeaveId,
  setType,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [requestId, setRequestId] = useState();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  console.log("data", data);
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setRequestId(id);
  };

  const handleDeleteLeaveRecord = (leaveId) => {
    if (forLeaveCredit) {
      deleteLeaveCredit({ id: leaveId }, () => setOpenDeleteModal(false));
    } else {
      deleteLeaveDebit({ id: leaveId }, () => setOpenDeleteModal(false));
    }
  };

  let tableBody = [];

  if (data.length !== 0) {
    const fields = Object.keys(data[0]);

    tableBody = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((request, i) => {
        return (
          <CustomTableRow
            to={toRow ? `${toRow}/${request.id}` : null}
            key={(request.id, +i)}
          >
            {fields.map((field) => {
              const getDocumentElement = () => {
                if (field === "document" && request[field] !== null) {
                  const documentName = getFileName(request[field]);
                  return (
                    <a
                      href={request[field]}
                      target="_blank"
                      download
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {documentName}
                    </a>
                  );
                } else if (field === "document" && request[field] === null) {
                  return <Typography>-</Typography>;
                } else {
                  return request[field];
                }
              };

              return (
                <CustomTableCell align={isEnglish ? "left" : "right"}>
                  {getDocumentElement()}
                </CustomTableCell>
              );
            })}
            {canDeleteRecord && (
              <CustomTableCell>
                <IconButton
                  onClick={() => {
                    setOpenEdit(true);
                    setLeaveId(request.id);
                    setType(forLeaveCredit ? "credit" : "debit");
                  }}
                >
                  <Edit color="primary" />
                </IconButton>
              </CustomTableCell>
            )}
            {canDeleteRecord && (
              <CustomTableCell>
                <IconButton onClick={() => handleOpenDeleteModal(request.id)}>
                  <DeleteOutline color="error" />
                </IconButton>
              </CustomTableCell>
            )}
          </CustomTableRow>
        );
      });
  }
  console.log("forLeaveDebit", forLeaveDebit);
  return (
    <>
      <Card>
        <CardHeader title={title} action={action} />
        <Table
          data={data}
          labels={labels}
          body={tableBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          withPagination
          tableContainerWidth={"96%"}
        />
      </Card>
      <CompanyStructureModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        fadeIn={openDeleteModal}
        title={t("deleteLeaveRecord")}
      >
        {leaveCreditDebitLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Grid container spacing={3} style={{ margin: 4 }}>
            <Grid item md={12}>
              <Typography variant="h3">
                {t("common:leaveRecordDeleteConfirm")}
              </Typography>
            </Grid>
            <Box
              clone
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              paddingX={2}
              paddingY={2}
            >
              <Grid item container md={12} sm={12} xs={11}>
                <Grid item md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDeleteLeaveRecord(requestId)}
                  >
                    {t("common:confirm")}
                  </Button>
                </Grid>
                <Grid item md={3}>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    {t("common:cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ employees }) => ({
  leaveCreditDebitLoading: employees.leaveCreditDebitLoading,
});

const mapDispatchToProps = {
  deleteLeaveCredit,
  deleteLeaveDebit,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
