import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    addButton: {
      "&:hover": {
        backgroundColor: theme.palette.common.lightBlue,
        color: "#white",
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
