import { gql } from "@apollo/client";

//Company
export const COMPANY_QUERY = gql`
  query getCompany {
    company {
      id
      approved
      checkInOutsideLocation
      checkInCutoff
      checkInTimeLimit
      checkInDistanceLimit
      checkOutCutoff
      checkOutTimeLimit
      useAttendance
      autoDeductionFromAttendance
      autoCheckout
      autoCheckoutAfter
      name
      nameAr
      cameraCheckinEnabled
      overtimeEnabled
      logo
      sector {
        id
        name
        nameAr
      }
      address {
        id
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        stateOrProvince
        paciNumber
        zipCode
        postcode
      }
      poc {
        id
        name
        email
        number
        address {
          id
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          stateOrProvince
          paciNumber
          zipCode
          postcode
        }
      }
      schedules {
        id
        days {
          id
          abbreviation
          abbreviationAr
          number
          name
          nameAr
          order
        }
        shifts {
          id
          endTime
          startTime
        }
      }
      vacation {
        wholeBalance
        annualLeave
        accumulatedFor
        sickLeave
        casualLeave
        shortLeave
        offsetLeave
        officialHolidays {
          id
          name
          nameAr
          start
          end
        }
      }
      payrollDay
    }
  }
`;

export const EMPLOYEE_INDEMNITY = gql`
  query getEmployeeIndemnity($id: Int!, $date: Date) {
    hypotheticalIndemnity(id: $id, date: $date) {
      accrual {
        vacation
        total
        lateDeposit {
          breakdown
          total
        }
      }
      termination
      resignation
    }
  }
`;

export const EMPLOYEE_DOCUMENTS_QUERY = gql`
  query getEmployeeDocuments($exp: Date) {
    employeeDocuments(exp: $exp) {
      id
      name
      exp
      referenceNumber
      type {
        id
        name
        nameAr
      }
      employee {
        fullName
        id
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        employmentStatus
        leaveDate
      }
    }
  }
`;

export const EXPIRING_COMPANY_DOCUMENTS_QUERY = gql`
  query getExpiringCompanyDocuments($exp: Date) {
    expiringCompanyDocuments(exp: $exp) {
      id
      name
      referenceNumber
      exp
    }
  }
`;

export const SECTORS_QUERY = gql`
  query getSectors {
    sectors {
      id
      name
      nameAr
    }
  }
`;

export const RESOURCES_QUERY = gql`
  query getResources {
    resources {
      id
      name
      nameAr
      description
      descriptionAr
      files {
        file
        description
        descriptionAr
      }
      links {
        link
        description
        descriptionAr
      }
    }
  }
`;

export const COMPANY_DOCUMENTS_QUERY = gql`
  query getCompanyDocuments {
    companyDocuments {
      id
      name
      exp
      referenceNumber
      location {
        id
        name
        nameAr
      }
      files {
        id
        file
        document {
          id
        }
      }
    }
  }
`;

export const COMPANY_EXPIRING_DOCUMENTS_QUERY = gql`
  query getExpiringCompanyDocuments {
    expiringCompanyDocuments {
      id
      name
      referenceNumber
      exp
    }
  }
`;

//Structure
export const LOCATIONS_QUERY = gql`
  query getCompanyLocations {
    locations {
      id
      name
      nameAr
      latitude
      longitude
      company {
        id
        name
        nameAr
      }
      employees {
        user {
          id
          fullName
        }
        id
        fullName
        image
      }
    }
  }
`;

export const DEPARTMENTS_QUERY = gql`
  query getCompanyDepartments {
    departments {
      id
      name
      nameAr
      positions {
        id
        name
        nameAr
        employees {
          fullName
          id
          user {
            fullName
            email
          }
          image
        }
      }
    }
  }
`;

export const POSITIONS_QUERY = gql`
  query getCompanyPositions {
    positions {
      id
      name
      nameAr
      department {
        id
        name
        nameAr
      }
    }
  }
`;

//Employees
export const EMPLOYEES_QUERY = gql`
  query getCompanyEmployees {
    employees {
      fullName
      id
      user {
        fullName
        email
        isEmployee
        hasAttendanceAccess
        hasDocumentAccess
        hasIndemnityAccess
        hasVacationsAccess
      }
      address {
        id
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        postcode
        zipCode
        stateOrProvince
        paciNumber
      }
      poc {
        id
        name
        email
        number
        address {
          id
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          postcode
          zipCode
          stateOrProvince
          paciNumber
        }
      }
      phoneNumber
      gender
      image
      department {
        id
        name
        nameAr
      }
      position {
        id
        name
        nameAr
      }
      nationality {
        name
        code
      }
      attendances {
        date
        id
        checkIn
        checkOut
        employee {
          id
          fullName
          user {
            fullName
          }
        }
      }
      locations {
        id
        name
        nameAr
        latitude
        longitude
      }
      location {
        id
        name
        nameAr
        latitude
        longitude
      }

      nextSalaryDeposit {
        salaryModifications {
          id
          month
          reason
          amount
        }
        recurringSalaryModifications {
          id
          reason
          amount
          startDate
          endDate
          receiveDuringVacation
        }
      }
      archived
      employmentStatus
      leaveDate
      hrAccess
      maritalStatus
      dateOfBirth
      baseSalary
      lastUpdated
      employmentDate
      referenceNumber
      checkInOutsideLocation
      overrideCheckInOutsideLocation
      baseSalary
      bankName
      iban
      leaveBalance {
        annualBalance
      }
    }
  }
`;

export const EMPLOYEES_LEAVE_BALANCE_QUERY = gql`
  query getEmployeesLeaveBalance {
    employees {
      id
      fullName
      leaveBalance {
        annualBalance
        casualBalance
        sickBalance
        offsetBalance
        annualBalanceUncapped
        leavedebits {
          id
          reason
          amount
          kind
          isCounted
          startTime
          endTime
          date
          endDate
          document
          balanceAdjustment
        }
        leavecredits {
          id
          reason
          kind
          reason
          amount
          document
        }
      }
    }
  }
`;

export const EMPLOYEES_PAYROLL_QUERY = gql`
  query getEmployeesPayroll($month: Date!, $to_month: Date) {
    employeesPayroll(month: $month, toMonth: $to_month) {
      month
      toMonth
      employee {
        id
        iban
        bankName
        fullName
        image
        phoneNumber
        baseSalary
        company {
          name
          workHoursPerDay
          workDaysPerMonth
          payrollDay
        }
        documents {
          id
          name
          referenceNumber
          type {
            id
            name
          }
        }
        attendances {
          date
          checkIn
          checkOut
        }
        recurringSalaryModifications {
          id
          reason
          amount
          startDate
          endDate
        }
        salaryModifications {
          id
          reason
          month
          amount
        }
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        locations {
          id
          name
          nameAr
        }
        leaveBalance {
          annualBalance
          casualBalance
          sickBalance
          offsetBalance
          annualBalanceUncapped
          leavedebits {
            date
            endDate
            balanceAdjustment
          }
          companyVacation {
            annualLeave
            sickLeave
            casualLeave
            offsetLeave
          }
        }
        referenceNumber
      }
      salary {
        totalSalary
        netSalary
        grossSalary
      }
      modifications {
        id
        reason
        amount
      }
      recurringModifications {
        id
        reason
        amount
        startDate
        endDate
      }
      workingDays
      lateHours
      daysOff
      overtimeHours
      overtimeCost
      totalWorkingTime
      hypotheticalIndemnities {
        termination
        resignation
      }
    }
  }
`;

export const PAYROLL_PAGE_QUERY = gql`
  query getEmployeesPayroll($month: Date!, $to_month: Date) {
    employeesPayroll(month: $month, toMonth: $to_month) {
      month
      toMonth
      employee {
        id
        iban
        bankName
        fullName
        phoneNumber
        baseSalary
        company {
          name
          workHoursPerDay
          payrollDay
          overtimeEnabled
        }
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        locations {
          id
          name
          nameAr
        }
        leaveBalance {
          annualBalance
          offsetBalance
        }
        referenceNumber
      }
      salary {
        totalSalary
        netSalary
        grossSalary
      }
      modifications {
        id
        reason
        amount
      }
      recurringModifications {
        id
        reason
        amount
        startDate
        endDate
      }
      civilId
      workingDays
      lateHours
      daysOff
      overtimeHours
      overtimeCost
      totalWorkingTime
      hypotheticalIndemnities {
        termination
        resignation
        accrual {
          vacation
        }
      }
      unpaid {
        days
        deduction
      }
    }
  }
`;

export const EMPLOYEES_WITH_HR_ACCESS_QUERY = gql`
  query getEmployeesWithHrAccess {
    hrEmployees {
      fullName
      id
      user {
        fullName
        email
        isEmployee
      }
      image
      department {
        id
        name
        nameAr
      }
      position {
        id
        name
        nameAr
      }
      hrAccess
    }
  }
`;

export const EMPLOYEES_WITH_PERMISSIONS_QUERY = gql`
  query getEmployeesWithPermissions {
    employeesWithAccess {
      id
      fullName
      user {
        id
        hasDocumentAccess
        hasIndemnityAccess
        hasVacationsAccess
        hasAttendanceAccess
      }
      managedLocations {
        id
        name
        nameAr
        latitude
        longitude
      }
      managedDepartments {
        id
        name
        nameAr
      }
    }
  }
`;

export const EMPLOYEE_DETAILS_QUERY = gql`
  query getEmployeeDetails($employeeId: Int) {
    employee(employeeId: $employeeId) {
      id
      archived
      fullName
      checkInOutsideLocation
      overrideCheckInOutsideLocation
      nextSalaryDeposit {
        baseSalary
        salaryModifications {
          id
          reason
          month
          amount
        }
        recurringSalaryModifications {
          id
          reason
          amount
          startDate
          endDate
          receiveDuringVacation
        }
        total
      }
      referenceNumber
      user {
        fullName
        email
        hasAttendanceAccess
        hasDocumentAccess
        hasIndemnityAccess
        hasVacationsAccess
        approverRules {
          id
          departments {
            id
            name
            nameAr
          }
          users {
            employee {
              id
              fullName
            }
          }
          kind
          locations {
            id
            name
            nameAr
          }
          order
        }
      }
      phoneNumber
      gender
      image
      lastUpdated
      employmentDate
      leaveDate
      company {
        id
        logo
        name
        nameAr
      }
      attendances {
        id
        checkIn
        checkOut
      }
      department {
        id
        name
        nameAr
      }
      position {
        id
        name
        nameAr
      }
      nationality {
        name
        code
      }
      locations {
        id
        name
        nameAr
        latitude
        longitude
      }
      location {
        id
        name
        nameAr
        latitude
        longitude
      }
      employmentStatus
      note
      hrAccess
      maritalStatus
      dateOfBirth
      address {
        id
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        postcode
        zipCode
        stateOrProvince
        paciNumber
      }
      poc {
        id
        name
        email
        number
        address {
          id
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          postcode
          zipCode
          stateOrProvince
          paciNumber
        }
      }
      baseSalary
      bankName
      iban
      documents {
        id
        referenceNumber
        issueDate
        name
        exp
        type {
          id
          name
          nameAr
        }
        files {
          id
          file
        }
      }
      overtimeCredits {
        id
        date
        amount
        overtimeCost
        overtimeRequest {
          request {
            status
          }
        }
      }
      leaveBalance {
        annualBalance
        casualBalance
        sickBalance
        offsetBalance
        annualBalanceUncapped
        leavedebits {
          id
          reason
          amount
          kind
          isCounted
          date
          endDate
          document
          balanceAdjustment
        }
        leavecredits {
          id
          reason
          kind
          reason
          amount
          document
        }
      }
      schedules {
        id
        shifts {
          startTime
          endTime
        }
        days {
          id
          name
          nameAr
          number
          order
        }
      }
      requests {
        id
        createdOn
        lastModified
        kind
        status
        notes
        lastModifiedBy {
          fullName
        }
        leaveRequest {
          reason
          kind
          amount
          date
          endDate
        }
        resignationRequest {
          reason
          date
        }
        updateProfileRequest {
          old
          new
        }
        overtimeRequest {
          id
          request {
            id
            status
            kind
            notes
          }
          date
          amount
        }
      }
      salaryModifications {
        id
        reason
        month
        amount
        attachment
      }
      recurringSalaryModifications {
        id
        reason
        amount
        startDate
        endDate
        attachment
      }
    }
  }
`;

export const EMPLOYEE_ALLOWANCES_QUERY = gql`
  query getEmployeeAllowances($employeeId: Int) {
    employee(employeeId: $employeeId) {
      id
      fullName
      user {
        fullName
        email
      }
      baseSalary
      recurringSalaryModifications {
        id
        reason
        amount
        startDate
        endDate
        receiveDuringVacation
      }
    }
  }
`;

export const EMPLOYEE_SALARY_DEPOSITS_QUERY = gql`
  query getEmployeeSalaryDeposits($employeeId: Int) {
    salaryDeposit(employeeId: $employeeId) {
      id
      month
      employee {
        fullName
        id
      }
      total
      salaryModifications {
        id
        amount
        reason
        month
        attachment
      }
      recurringSalaryModifications {
        id
        amount
        reason
        startDate
        endDate
        attachment
      }
    }
  }
`;

export const EMPLOYEE_NEXT_SALARY_DEPOSIT_QUERY = gql`
  query getEmployeeNextSalaryDeposit($employeeId: Int, $month: Date) {
    nextSalaryDeposit(employeeId: $employeeId, month: $month) {
      employee {
        id
        fullName
      }
      month
      salaryModifications {
        id
        reason
        month
        amount
        attachment
      }
      recurringSalaryModifications {
        id
        reason
        amount
        startDate
        endDate
        receiveDuringVacation
        attachment
      }
      overtimeCredits {
        date
        overtimeCost
      }
      netSalary
      totalNetSalary
    }
  }
`;

export const EMPLOYEES_NEXT_SALARY_DEPOSIT_QUERY = gql`
  query getEmployeeNextSalaryDeposit($month: Date) {
    employeesNextSalaryDeposit(month: $month) {
      employee {
        id
        fullName
        lastUpdated
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        locations {
          id
          name
          nameAr
          latitude
          longitude
        }
        location {
          id
          name
          nameAr
          latitude
          longitude
        }
        employmentStatus
        baseSalary
        leaveDate
      }
      month
      salaryModifications {
        id
        reason
        month
        amount
        attachment
      }
      recurringSalaryModifications {
        id
        reason
        amount
        startDate
        endDate
        receiveDuringVacation
        attachment
      }
      overtimeCredits {
        date
        overtimeCost
      }
      totalNetSalary
      netSalary
    }
  }
`;

export const DOCUMENT_TYPES_QUERY = gql`
  query getDocumentTypes {
    documentTypes {
      id
      name
      nameAr
    }
  }
`;

export const EMPLOYEE_ATTENDANCES_QUERY = gql`
  query getEmployeeAttendances($dateMonth: Date, $employeeId: Int) {
    employeeAttendances(dateMonth: $dateMonth, employeeId: $employeeId) {
      date
      id
      checkIn
      checkOut
      checkInLongitude
      checkInLatitude
      checkOutLongitude
      checkOutLatitude
      image
      imageCheckout
      location {
        id
        name
        nameAr
        latitude
        longitude
      }
      employee {
        id
        fullName
        image
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
        locations {
          id
          name
          nameAr
          latitude
          longitude
        }
        user {
          fullName
        }
      }
    }
  }
`;

//Employee
export const EMPLOYEE_PROFILE_QUERY = gql`
  query getEmployeeProfile {
    employee {
      fullName
      id
      referenceNumber
      checkInOutsideLocation
      overrideCheckInOutsideLocation
      user {
        fullName
        email
        hasAttendanceAccess
        hasDocumentAccess
        hasIndemnityAccess
        hasVacationsAccess
        approverRules {
          id
          departments {
            id
            name
            nameAr
          }
          users {
            employee {
              id
              fullName
            }
          }
          kind
          locations {
            id
            name
            nameAr
          }
          order
        }
      }
      iban
      bankName
      archived
      phoneNumber
      gender
      image
      lastUpdated
      employmentDate
      leaveDate
      company {
        id
        logo
        name
        nameAr
        locations {
          id
          name
          nameAr
        }
        cameraCheckinEnabled
        overtimeEnabled
        checkInDistanceLimit
      }
      department {
        id
        name
        nameAr
      }
      position {
        id
        name
        nameAr
      }
      location {
        id
        name
        nameAr
        latitude
        longitude
      }
      locations {
        id
        name
        nameAr
        latitude
        longitude
      }
      managedLocations {
        id
        name
        nameAr
      }
      nationality {
        name
        code
      }
      announcements {
        id
        announcement {
          id
          title
          titleAr
          body
          bodyAr
          postDate
          resources {
            id
            name
            nameAr
            description
            descriptionAr
            files {
              file
              description
              descriptionAr
            }
            links {
              link
              description
              descriptionAr
            }
          }
        }
        seen
      }
      employmentStatus
      hrAccess
      maritalStatus
      dateOfBirth
      address {
        id
        country {
          name
          code
        }
        address1
        address2
        cityOrTown
        postcode
        zipCode
        stateOrProvince
        paciNumber
      }
      poc {
        id
        name
        email
        number
        address {
          id
          country {
            name
            code
          }
          address1
          address2
          cityOrTown
          postcode
          zipCode
          stateOrProvince
          paciNumber
        }
      }
      baseSalary
      documents {
        id
        referenceNumber
        issueDate
        name
        exp
        files {
          id
          file
        }
        type {
          id
          name
        }
      }
      leaveBalance {
        annualBalance
        casualBalance
        sickBalance
        offsetBalance
        annualBalanceUncapped
        leavedebits {
          id
          reason
          amount
          kind
          date
          endDate
          isCounted
          balanceAdjustment
        }
        leavecredits {
          reason
          amount
          kind
          id
        }
      }
      requests {
        id
        createdOn
        lastModified
        status
        kind
        notes
        lastModifiedBy {
          fullName
        }
        leaveRequest {
          reason
          kind
          amount
          date
          endDate
        }
        overtimeRequest {
          id
          request {
            id
            status
            kind
            notes
          }
          date
          amount
        }
        resignationRequest {
          reason
          date
        }
        updateProfileRequest {
          old
          new
        }
        generalRequest {
          date
          reason
          body
        }
      }
    }
  }
`;

export const EMPLOYEE_ACTIVE_ATTENDANCE_QUERY = gql`
  query getEmployeeActiveAttendance($employeeId: Int) {
    employeeActiveAttendance(employeeId: $employeeId) {
      id
      date
      checkIn
      checkOut
      checkInLatitude
      checkInLongitude
      checkOutLatitude
      checkOutLongitude
      location {
        id
        name
        nameAr
        latitude
        longitude
      }
      employee {
        location {
          id
          name
          nameAr
          longitude
          latitude
        }
      }
    }
  }
`;

export const MY_ATTENDANCES_QUERY = gql`
  query getEmployeeAttendances($dateMonth: Date, $employeeId: Int) {
    employeeAttendances(dateMonth: $dateMonth, employeeId: $employeeId) {
      date
      id
      checkIn
      checkOut
      checkInLatitude
      checkInLongitude
      checkOutLatitude
      checkOutLongitude
      employee {
        location {
          id
          name
          nameAr
          longitude
          latitude
        }
      }
      location {
        id
        name
        nameAr
        latitude
        longitude
      }
    }
  }
`;

export const EMPLOYEE_LEAVE_SALARY_QUERY = gql`
  query getEmployeeLeaveSalary(
    $employeeId: Int
    $startDate: Date
    $endDate: Date
  ) {
    employeeLeaveSalary(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      annualBalance
      annualBalanceUncapped
      sickBalance
      casualBalance
      shortLeaveBalance
      month
    }
  }
`;

//Requests
export const REQUESTS_QUERY = gql`
  query getRequests {
    requests {
      id
      isLastApprover
      notes
      employee {
        fullName
        id
        archived
        employmentStatus
        hrAccess
        image
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
      }
      leaveRequest {
        date
        endDate
        amount
      }
      overtimeRequest {
        id
        request {
          id
          status
          kind
          notes
        }
        date
        amount
      }
      resignationRequest {
        reason
        date
      }
      updateProfileRequest {
        old
        new
      }
      generalRequest {
        date
        reason
        body
      }
      status
      kind
      createdOn
      lastModified
      lastModifiedBy {
        fullName
      }
    }
  }
`;

export const REQUEST_DETAIL_QUERY = gql`
  query getRequestDetail($employeeId: Int!, $id: Int!) {
    request(employeeId: $employeeId, id: $id) {
      id
      isLastApprover
      employee {
        fullName
        id
        company {
          id
          name
          nameAr
          logo
        }
        leaveBalance {
          annualBalance
          casualBalance
          sickBalance
          offsetBalance
          shortLeaveBalance
        }
        user {
          fullName
        }
        department {
          id
          name
          nameAr
        }
        position {
          name
          nameAr
        }
      }
      status
      kind
      createdOn
      lastModified
      notes
      lastModifiedBy {
        fullName
      }
      leaveRequest {
        id
        reason
        amount
        kind
        date
        endDate
        document
        startTime
        endTime
        leaveDebit {
          kind
          amount
          isCounted
        }
      }
      overtimeRequest {
        id
        request {
          id
          status
          kind
          notes
        }
        date
        amount
      }
      resignationRequest {
        reason
        date
      }
      updateProfileRequest {
        old
        new
      }
      generalRequest {
        date
        reason
        body
        document
      }
    }
  }
`;

export const EMPLOYEE_REQUEST_DETAIL_QUERY = gql`
  query getRequestDetail($id: Int!) {
    request(id: $id) {
      id
      employee {
        fullName
        id
        user {
          fullName
        }
        leaveBalance {
          annualBalance
          casualBalance
          sickBalance
          offsetBalance
        }
        department {
          id
          name
          nameAr
        }
        position {
          name
          nameAr
        }
      }
      status
      kind
      createdOn
      lastModified
      notes
      lastModifiedBy {
        fullName
      }
      leaveRequest {
        id
        reason
        amount
        kind
        date
        endDate
        document
        leaveDebit {
          amount
          kind
          isCounted
        }
      }
      overtimeRequest {
        id
        request {
          id
          status
          kind
          notes
        }
        date
        amount
      }
      resignationRequest {
        reason
        date
      }
      updateProfileRequest {
        old
        new
      }
      generalRequest {
        date
        reason
        body
        document
      }
    }
  }
`;

export const HYPOTHETICAL_INDEMNITY = gql`
  query hypotheticalIndemnities {
    hypotheticalIndemnities {
      termination
      resignation
      accrual {
        vacation
        total
        lateDeposit {
          breakdown
          total
        }
      }
      employee {
        fullName
        image
        id
        employmentStatus
        user {
          fullName
        }
        department {
          id
          nameAr
          name
        }
        position {
          id
          name
          nameAr
        }

        hrAccess
      }
    }
  }
`;

export const ANNOUNCEMENTS_QUERY = gql`
  query getAnnouncements {
    announcements {
      id
      title
      titleAr
      body
      bodyAr
      postDate
      announcementNotifications {
        seen
        employee {
          fullName
          id
          user {
            fullName
          }
          department {
            id
            name
            nameAr
          }
          position {
            id
            name
            nameAr
          }
        }
      }
      resources {
        id
        name
        nameAr
        description
        descriptionAr
        files {
          file
          description
          descriptionAr
        }
        links {
          link
          description
          descriptionAr
        }
      }
    }
  }
`;

export const REQUEST_APPROVERS_STATUS_QUERY = gql`
  query getRequestApproversStatus($id: Int!) {
    requestApproversStatus(id: $id) {
      id
      request {
        id
      }
      approvedBy {
        firstName
        lastName
        fullName
        employee {
          id
          department {
            name
          }
        }
      }
      status
    }
  }
`;

export const APPROVER_REQUESTS_QUERY = gql`
  query getApproverRequests($employeeId: Int) {
    companyRequests(employeeId: $employeeId) {
      id
      notes
      employee {
        fullName
        id
        archived
        employmentStatus
        hrAccess
        image
        user {
          fullName
          isEmployee
          isCompanyOwner
        }
        department {
          id
          name
          nameAr
        }
        position {
          id
          name
          nameAr
        }
      }
      leaveRequest {
        date
        endDate
        amount
      }
      overtimeRequest {
        id
        request {
          id
          status
          kind
          notes
        }
        date
        amount
      }
      resignationRequest {
        reason
        date
      }
      updateProfileRequest {
        old
        new
      }
      generalRequest {
        date
        reason
        body
      }
      status
      kind
      createdOn
      lastModified
      lastModifiedBy {
        fullName
      }
    }
  }
`;

export const APPROVER_RULES_QUERY = gql`
  query getApproverRules {
    requestsApprovers {
      order
      id
      departments {
        id
        name
        nameAr
      }
      users {
        employee {
          fullName
          id
          hrAccess
        }
      }
      locations {
        id
        name
        nameAr
      }
    }
  }
`;
