import "jspdf-autotable";

import "../../../../utils/fonts/Amiri-Regular-normal";

import jsPDF from "jspdf";

import {
  employeesStatus,
  maritalStatus,
} from "../../../../../components/common/DropDownsData";

export const getHeaders = (employeeObj, t, isEnglish) => {
  if (employeeObj) {
    const headers = Object.keys(employeeObj);

    const translatedHeaders = headers.map((head) => t(head));

    if (isEnglish) {
      return translatedHeaders;
    } else {
      return translatedHeaders.reverse();
    }
  }
};

export const getBody = (employees, isEnglish) => {
  const employeeValues = employees.map((employee) => Object.values(employee));

  if (isEnglish) {
    return employeeValues;
  } else {
    let reversedValues = employeeValues.map((arrayValue) => {
      arrayValue.reverse();
      return arrayValue;
    });
    return reversedValues;
  }
};

export const getContactInformation = (address) => {
  return `${address?.country ? address?.country?.name : "-"} - ${
    address?.address1
  } - ${address?.address2}`;
};

export const getLocations = (locations) => {
  if (locations.length === 0) return "-";

  let locationsArray = [];

  for (const location of locations) {
    locationsArray.push(location?.name);
  }

  return locationsArray.join(",");
};

export const normalizeData = (employees, myArray, isEnglish) => {
  const normalizedEmployess = [];
  const fields = myArray
    .filter((field) => field.active)
    .map((field) => field.value);

  for (const employee of employees) {
    let modifiedEmployee = {};

    if (fields.includes("ID")) {
      modifiedEmployee["id"] = employee.referenceNumber
        ? employee.referenceNumber
        : employee.id;
    }

    if (fields.includes("EMPLOYEE_NAME")) {
      modifiedEmployee["name"] = employee?.fullName;
    }

    if (fields.includes("NATIONALITY")) {
      modifiedEmployee["nationality"] = employee?.nationality
        ? employee.nationality.name
        : "-";
    }

    if (fields.includes("LOCATIONS")) {
      modifiedEmployee["locations"] = getLocations(employee.locations);
    }
    if (fields.includes("DEPARTMENT")) {
      modifiedEmployee["department"] = isEnglish
        ? employee.department.name
        : employee.department.nameAr;
    }
    if (fields.includes("POSITION")) {
      modifiedEmployee["position"] = isEnglish
        ? employee.position.name
        : employee.position.nameAr;
    }

    if (fields.includes("EMPLOYMENT_STATUS")) {
      const employeeStatus = employeesStatus.find(
        (status) => status.value === employee.employmentStatus,
      );
      modifiedEmployee["employmentStatus"] = isEnglish
        ? employeeStatus.name
        : employeeStatus.nameAr;
    }

    if (fields.includes("GENDER")) {
      modifiedEmployee["gender"] = employee.gender;
    }
    if (fields.includes("MARITAL_STATUS")) {
      const employeeMaritalStatus = maritalStatus.find(
        (status) => status.value === employee?.maritalStatus,
      );

      modifiedEmployee["maritalStatus"] =
        employeeMaritalStatus && isEnglish
          ? employeeMaritalStatus?.name
          : employeeMaritalStatus && !isEnglish
            ? employeeMaritalStatus?.nameAr
            : "-";
    }

    if (fields.includes("DATE_OF_BIRTH")) {
      modifiedEmployee["dateOfBirth"] = employee.dateOfBirth;
    }
    if (fields.includes("EMPLOYMENT_DATE")) {
      modifiedEmployee["employmentDate"] = employee.employmentDate;
    }
    if (fields.includes("LEAVE_DATE")) {
      modifiedEmployee["leaveDate"] = employee.leaveDate ?? "-";
    }
    if (fields.includes("CONTACT_INFORMATION")) {
      modifiedEmployee["contactInformation"] = getContactInformation(
        employee.address,
      );
    }
    if (fields.includes("POINT_OF_CONTACT_INFORMATION")) {
      modifiedEmployee["pointOfContactInformation"] = getContactInformation(
        employee?.poc?.address,
      );
    }

    if (fields.includes("BASE_SALARY")) {
      modifiedEmployee["baseSalary"] = employee.baseSalary;
    }

    if (fields.includes("PHONE_NUMBER")) {
      modifiedEmployee["phoneNumber"] = employee.phoneNumber ?? "-";
    }

    if (fields.includes("ANNUAL_BALANCE")) {
      modifiedEmployee["annualBalance"] = employee.leaveBalance.annualBalance;
    }

    normalizedEmployess.push(modifiedEmployee);
  }

  return normalizedEmployess;
};

export const createPDF = (
  employeesForPdf,
  setPdfFields,
  payrollPdfFields,
  setOpenModal,
  t,
  isEnglish,
  month,
) => {
  const pdf = new jsPDF("landscape");
  pdf.setFont("Amiri-Regular");

  const headers = getHeaders(employeesForPdf[0], t, isEnglish);
  const body = getBody(employeesForPdf, isEnglish);

  const tableHeaderStyles = {
    fillColor: [255, 255, 255],
    textColor: "#7387A1",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 5,
    cellPadding: 2,
    overflow: "visible",
  };

  const tableBodyStyles = {
    fillColor: [255, 255, 255],
    textColor: "#133761",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 5,
    cellPadding: 2,
    overflow: "visible",
    font: "Amiri-Regular",
  };

  const headStyles = isEnglish
    ? { ...tableHeaderStyles, halign: "left" }
    : { ...tableHeaderStyles, font: "Amiri-Regular", halign: "right" };

  const bodyStyles = isEnglish
    ? { ...tableBodyStyles, halign: "left" }
    : { ...tableBodyStyles, halign: "right" };

  const title = `${t("employeesReport")}`;

  const pageWidth =
    pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

  pdf.setTextColor("#002553");

  if (!isEnglish) {
    pdf.setFont("Amiri-Regular");
  }

  pdf.text(title, pageWidth / 2, 15, { align: "center" });

  pdf.autoTable({
    startY: 30,
    styles: { fillColor: [255, 255, 255] },
    head: [headers],
    body: body,
    theme: "plain",
    tableLineColor: "#D6DBE3",
    tableLineWidth: 0.2,
    headStyles: headStyles,
    bodyStyles: bodyStyles,
  });

  pdf.save(`employees-report.pdf`);
  setOpenModal(false);
  setPdfFields(payrollPdfFields(t));
};
