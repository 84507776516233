import moment from "moment";

export const normalizeLeaveRequest = (request) => {
  return {
    id: request.id,
    name: request.employee.user
      ? request.employee.user?.fullName
      : request.employee?.fullName,
    createdOn: request.createdOn,
    startDate: moment(request.leaveRequest.date).format("DD-MM-yyyy"),
    endDate: moment(request.leaveRequest.endDate).format("DD-MM-yyyy"),
  };
};
