export const filterDocuments = (
  employeeDocuments,
  department,
  position,
  documentType,
  query
) => {
  let employeeDepartmentDocuments = employeeDocuments.filter((document) => {
    if (department.length === 0) {
      return document;
    }

    return department.some(
      (dep) => dep.value === document.employee.department.id
    );
  });

  let employeePositionDocuments = employeeDepartmentDocuments.filter(
    (document) => {
      if (position.length === 0) {
        return document;
      }
      return position.some(
        (pos) => pos.value === document.employee.position.id
      );
    }
  );

  let employeeDocumentTypes = employeePositionDocuments.filter((document) => {
    if (documentType.length === 0) {
      return document;
    }
    if (document.type) {
      return documentType.some((doc) => doc.value === document.type.id);
    }
    return false;
  });

  return employeeDocumentTypes.filter((document) => {
    const name = document.employee.user
      ? document.employee.user?.fullName
      : document.employee?.fullName;
    return (
      name.toLowerCase().includes(query.toLowerCase()) ||
      document.employee.id.includes(query) ||
      document.name.toLowerCase().includes(query.toLowerCase())
    );
  });
};
