import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { updateEmployeePortalPermission } from "../../../../../redux/actions";
import { CompanyStructureModal, FormInputField } from "../../../../common";
import AutocompleteInput from "../../../../common/AutocompleteInput";
import { portalSections } from "../../../../common/DropDownsData";

const EditPermission = ({
  openModal,
  setOpenModal,
  selectedEmployee,
  employees,
  updateEmployeePortalPermission,
  locations: locationsState,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  let employeeSections = [];

  if (selectedEmployee.hasAttendanceAccess) {
    let section = portalSections.find(
      (section) => section.value === "has_attendance_access"
    );

    employeeSections.push({
      label: isEnglish ? section.name : section.nameAr,
      value: section.value,
    });
  }

  if (selectedEmployee.hasDocumentAccess) {
    let section = portalSections.find(
      (section) => section.value === "has_document_access"
    );
    employeeSections.push({
      label: isEnglish ? section.name : section.nameAr,
      value: section.value,
    });
  }

  if (selectedEmployee.hasIndemnityAccess) {
    let section = portalSections.find(
      (section) => section.value === "has_indemnity_access"
    );
    employeeSections.push({
      label: isEnglish ? section.name : section.nameAr,
      value: section.value,
    });
  }

  if (selectedEmployee.hasVacationsAccess) {
    let section = portalSections.find(
      (section) => section.value === "has_vacations_access"
    );
    employeeSections.push({
      label: isEnglish ? section.name : section.nameAr,
      value: section.value,
    });
  }

  let employeeLocations = [];

  if (selectedEmployee?.locations?.length > 0) {
    for (const location of selectedEmployee.locations) {
      employeeLocations.push({
        label: isEnglish ? location.name : location.nameAr,
        value: location.id,
      });
    }
  }

  const [sections, setSections] = useState(employeeSections);
  const [locations, setLocations] = useState(employeeLocations);

  const handleSubmit = () => {
    let kindValues = sections.map((section) => section.value);
    let locationIds = locations.map((location) => Number(location.value));
    updateEmployeePortalPermission(
      {
        employeeId: selectedEmployee.id,
        kinds: kindValues,
        locations: locationIds,
      },
      () => setOpenModal(false)
    );
  };

  const handleSectionsChange = (e) => {
    if (sections.includes(e)) {
      setSections((prevState) =>
        prevState.filter((section) => section.value !== e.value)
      );
    } else {
      setSections([...e]);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <CompanyStructureModal
        open={openModal}
        onClose={handleCloseModal}
        fadeIn={openModal}
        title={t("companyProfile:editPortalAccessPermissions")}
      >
        <Box height="auto" maxHeight={"60vh"} margin={2}>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
              <FormInputField
                label={t("employeeName")}
                value={selectedEmployee?.fullName}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <AutocompleteInput
                value={sections}
                options={portalSections
                  .filter((section) => {
                    const sectionsSelected = sections?.map((sec) => sec.value);
                    return !sectionsSelected.includes(section.value);
                  })

                  .map((section) => ({
                    label: isEnglish ? section.name : section.nameAr,
                    value: section.value,
                  }))}
                multiple={true}
                onChange={handleSectionsChange}
                label={t("companyProfile:portalSections")}
              />
            </Grid>

            <Grid item md={12} sm={12}>
              <AutocompleteInput
                value={locations}
                options={locationsState

                  .filter((loc) => {
                    const locationsSelected = locations.map((loc) => loc.value);
                    return !locationsSelected.includes(loc.id);
                  })

                  .map((loc) => ({
                    label: isEnglish ? loc.name : loc.nameAr,
                    value: loc.id,
                  }))}
                multiple={true}
                onChange={(e) => setLocations(e)}
                label={t("branches")}
              />
            </Grid>
            <Box
              clone
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              paddingX={2}
              paddingY={2}
            >
              <Grid item md={12} sm={12} xs={11}>
                <Button
                  disableRipple
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={sections.length === 0 || locations.length === 0}
                >
                  <Typography>{t("common:save")}</Typography>
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ employees, company }) => ({
  employees: employees.employees,
  locations: company.locations,
});

const mapDispatchToProps = {
  updateEmployeePortalPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPermission);
