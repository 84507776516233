import React, { Fragment } from "react";

import { Box, Button, Grid } from "@material-ui/core";


/**
 * @description Indemnity page header component
 * @category Components - Web
 * @namespace IndemintyPageHeaderComponent
 * @param {import('./types').IndemnityPageHeaderComponentProps} IndemnityPageHeaderComponentProps - props for component
 * @returns {React.JSX.Element}
 */

export const IndemnityPageHeaderComponent = ({
  onClick,
  t,
}) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Box paddingX={4} display={"flex"} flexDirection={"row-reverse"}>
          <Button variant="contained" color="primary" onClick={onClick}>
            {t("indemnitySlip")}
          </Button>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default IndemnityPageHeaderComponent;
