export const filterEmployees = (employees, departments, positions) => {
  let departmentEmployees = employees.filter((employee) => {
    if (departments.length === 0) {
      return employee;
    }

    return departments.some((dep) => dep.value === employee.department.id);
  });

  let positionEmployees = departmentEmployees.filter((employee) => {
    if (positions.length === 0) {
      return employee;
    }
    return positions.some((pos) => pos.value === employee.position.id);
  });

  return positionEmployees;
};
