import { Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { DeleteOutline } from "@material-ui/icons";
import TimePickerField from "../../../TimePickerField";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const ShiftItem = ({
  shiftIdx,
  shift,
  removeShift,
  scheduleIdx,
  register,
  schedule,
  schedulesState,
  setSchedulesState,
}) => {
  const { t } = useTranslation("common");
  const [startTime, setStartTime] = useState(
    shift.startTime
      ? moment(shift.startTime, "HH:mm:ss").format("HH:mm:ss")
      : moment(new Date())
  );
  const [endTime, setEndTime] = useState(
    shift.endTime
      ? moment(shift.endTime, "HH:mm:ss").format("HH:mm:ss")
      : moment(new Date())
  );

  const handleStartTimeChange = (e) => {
    setStartTime(e);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e);
  };

  const handleRemoveShift = () => {
    const modifiedShifts = schedule.shifts.filter(
      (shift, idx) => idx !== shiftIdx
    );

    const modifiedSchedules = schedulesState.map((sched, idx) => {
      if (idx === scheduleIdx) {
        return {
          ...schedule,
          shifts: modifiedShifts,
        };
      } else {
        return sched;
      }
    });

    setSchedulesState(modifiedSchedules);
  };

  useEffect(() => {
    const modifiedShift = {
      startTime: moment(startTime, "HH:mm:ss").format("HH:mm:ss"),
      endTime: moment(endTime, "HH:mm:ss").format("HH:mm:ss"),
    };

    const modifiedShifts = schedule.shifts.map((_shift, idx) => {
      if (idx === shiftIdx) {
        return modifiedShift;
      } else {
        return _shift;
      }
    });

    const modifiedSchedules = schedulesState?.map((sched, idx) => {
      if (idx === scheduleIdx) {
        return {
          ...schedule,
          shifts: modifiedShifts,
        };
      } else {
        return sched;
      }
    });

    setSchedulesState(modifiedSchedules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  return (
    <Grid
      item
      container
      justifyContent="flex-end"
      key={shiftIdx}
      md={12}
      spacing={2}
      style={{ marginTop: 1 }}
    >
      <Grid item md={4}>
        <TimePickerField
          label={t("startTime")}
          name="startTime"
          onChange={handleStartTimeChange}
          value={startTime}
          defaultValue={startTime}
        />
      </Grid>
      <Grid item md={4}>
        <TimePickerField
          label={t("endTime")}
          name="endTime"
          onChange={handleEndTimeChange}
          value={endTime}
          defaultValue={endTime}
        />
      </Grid>
      <Grid item md={1}>
        {shiftIdx !== 0 && (
          <IconButton onClick={handleRemoveShift}>
            <DeleteOutline color="primary" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ShiftItem;
