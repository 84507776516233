//Material UI
import {
  Box,
  Button,
  Grid,
  TableHead as HeaderLabels,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { CustomTableRow, DatePickerField, SearchInput } from "../../common";
import {
  LoadingSpinnerComponent,
  PageHeader,
  TablePagination,
} from "../../../redesign/components/common";
import React, { useEffect, useState } from "react";

import AttendanceReportModal from "./AttendanceReportModal";
import AutocompleteInput from "../../common/AutocompleteInput";
//Components
import EmployeeAttendanceItem from "./EmployeeAttendanceItem";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { fetchEmployeeAttendances } from "../../../redux/actions/employees";
//Data & Utils
import { filterEmployees } from "./utils";
// Moment
import moment from "moment";
import { paths } from "../../../redesign/utils";
import { useTheme } from "@material-ui/styles";
// Store
//Translation
import { useTranslation } from "react-i18next";

const Attendance = ({
  company,
  departments,
  employeeAttendances,
  attendancesLoading,
  positions,
  locations,
  fetchEmployeeAttendances,
  user,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [reportEmployees, setReportEmployees] = useState([]);

  ReactGA.pageview(window.location.pathname);

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "breadcrumbs"]);

  const isEnglish = language === "en";
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [month, setMonth] = useState(moment(new Date()).format("yyyy-MM"));

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id),
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ]),
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);

  const [location, setLocation] = useState([]);

  const [query, setQuery] = useState("");

  const handleMonthChange = (e) => {
    let newMonth = moment(new Date(e)).format("yyyy-MM");

    setMonth(newMonth);
  };

  useEffect(() => {
    fetchEmployeeAttendances({ dateMonth: month });
  }, [month, fetchEmployeeAttendances]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "employeeName", label: t("employeeName") },
    { id: "checkIn", label: t("checkInTime") },
    { id: "checkOut", label: t("checkOutTime") },
    { id: "location", label: t("location") },
    { id: "checkInDistance", label: t("checkInDistance") },
    { id: "checkOutDistance", label: t("checkOutDistance") },
    { id: "duration", label: t("duration") },
    { id: "image", label: t("image") },
    { id: "checkoutImage", label: t("checkoutImage") },
    { id: "edit", label: t("edit") },
  ];

  function TableHead(props) {
    return (
      <HeaderLabels>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              style={{
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
              align={isEnglish ? "left" : "right"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </CustomTableRow>
      </HeaderLabels>
    );
  }

  let employeesList = filterEmployees(
    employeeAttendances,
    department,
    position,
    location,
    query,
  );

  const listOfEmployees = employeesList
    .sort((a, b) => new Date(b.checkIn) - new Date(a.checkIn))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    .map((attendance) => (
      <EmployeeAttendanceItem
        attendance={attendance}
        key={`Attendance ID${attendance.id}`}
        isCompanyOwner={user.isCompanyOwner}
      />
    ));

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.attendances.name}`),
      isLast: true,
    },
  ];

  return (
    <>
      <Grid container justifyContent={matchesMD ? "center" : "flex-start"}>
        <Grid item md={11} xs={12}>
          <Grid
            container
            justifyContent={matchesMD ? "center" : "flex-start"}
            spacing={4}
          >
            <Grid item md={12} xs={9}>
              <PageHeader
                paths={navigationPaths}
                title={t(`breadcrumbs:${paths.attendances.header}`)}
              />
            </Grid>
            <Grid item md={12} xs={9}>
              <Box clone paddingY={1}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      value={department}
                      options={departments.map((dep) => ({
                        label: isEnglish ? dep.name : dep.nameAr,
                        value: dep.id,
                      }))}
                      multiple={true}
                      onChange={(e) => setDepartment(e)}
                      label={t("department")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      multiple={true}
                      value={position}
                      options={_positions().map((pos) => ({
                        label: isEnglish ? pos.name : pos.nameAr,
                        value: pos.id,
                      }))}
                      onChange={(e) => setPosition(e)}
                      label={t("position")}
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <AutocompleteInput
                      multiple={true}
                      value={location}
                      options={locations.map((loc) => ({
                        label: isEnglish ? loc.name : loc.nameAr,
                        value: loc.id,
                      }))}
                      onChange={(e) => setLocation(e)}
                      label={t("location")}
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <SearchInput
                      onChange={(e) => setQuery(e.target.value)}
                      placeholder={t("search")}
                      position="start"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={12} xs={10}>
              <Box clone bgcolor="white" borderRadius={15}>
                <TableContainer>
                  {attendancesLoading ? (
                    <LoadingSpinnerComponent />
                  ) : (
                    <>
                      <Box
                        paddingX={4}
                        paddingY={2}
                        display="flex"
                        flexDirection="row-reverse"
                        alignItems="center"
                      >
                        <DatePickerField
                          fullWidth={false}
                          disableToolbar={false}
                          yearMonthView
                          openTo="month"
                          onChange={(e) => handleMonthChange(e)}
                          value={month}
                          label={t("common:month")}
                        />
                        {user && user.isCompanyOwner && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setOpenModal(true);
                              setReportEmployees(employeesList);
                            }}
                          >
                            {t("common:download")}
                          </Button>
                        )}
                      </Box>
                      <Table>
                        <TableHead />
                        <TableBody>{listOfEmployees}</TableBody>
                      </Table>
                      {employeesList.length === 0 && (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <Typography color="primary" align="center">
                            {t("noRecords")}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </TableContainer>
              </Box>

              <TablePagination
                count={employeesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AttendanceReportModal
        company={company}
        openModal={openModal}
        setOpenModal={setOpenModal}
        attendancePageMonth={month}
        reportEmployees={reportEmployees}
        setReportEmployees={setReportEmployees}
        attendancePageEmployees={employeesList}
        department={department}
        position={position}
        location={location}
        query={query}
      />
    </>
  );
};

const mapStateToProps = ({ company, employees, auth }) => ({
  departments: company.departments,
  employeeAttendances: employees.attendances,
  attendancesLoading: employees.attendancesLoading,
  positions: company.positions,
  locations: company.locations,
  company: company.profile,
  user: auth.user,
});

const mapDispatchToProps = {
  fetchEmployeeAttendances,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
