//Material UI
import { Box, Grid, useMediaQuery } from "@material-ui/core";

//Components
import Overview from "./Overview";
import { ProfileSection } from "../../../common";
import React from "react";
import ReactGA from "react-ga";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";

const EmployeeHome = ({ user, profile }) => {
  ReactGA.pageview(window.location.pathname);
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  if (!user) return <Redirect to="/login" />;

  return (
    <Box clone paddingY={6} maxHeight={"100%"}>
      <Grid
        container
        justifyContent={matchesMD ? "center" : "flex-start"}
        spacing={4}
      >
        <Grid item md={3} xs={9}>
          <ProfileSection profile={profile} />
        </Grid>

        <Grid item md={8} xs={12}>
          <Box clone maxHeight={"100%"}>
            <Grid container justifyContent="center">
              <Box clone bgcolor="white" borderRadius={10}>
                <Grid item md={11} xs={11}>
                  <Overview />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ auth, employee }) => ({
  user: auth.user,
  profile: employee.profile,
});

export default connect(mapStateToProps)(EmployeeHome);
