import { Box, Button, Grid, Typography } from "@material-ui/core";

import AddPermission from "./AddPermission";
import { NotesSharp } from "@material-ui/icons";
import PermissionEmployeeItem from "./PermissionEmployeeItem";
import { connect } from "react-redux";
import { fetchEmployeesWithPermissions } from "../../../../../redux/actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Portal = ({ permissionEmployees, fetchEmployeesWithPermissions }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [openModal, setOpenModal] = useState(false);

  const employeesList = permissionEmployees?.map((employee) => {
    return (
      <PermissionEmployeeItem
        key={employee?.id}
        employee={employee}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    );
  });

  return (
    <>
      <Grid
        item
        container
        md={12}
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <NotesSharp color="primary" fontSize="large" />

            <Typography variant="subtitle1">
              {t("companyProfile:portalAccessPermissions")}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" flexDirection="row-reverse">
            <Grid item md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenModal(true)}
              >
                {t("add")}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item container>
        {employeesList}
      </Grid>
      <AddPermission openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

const mapStateToProps = ({ employees, company }) => ({
  permissionEmployees: employees.permissionEmployees,
});

const mapDispatchToProps = {
  fetchEmployeesWithPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
