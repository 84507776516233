import React from "react";
import { useTranslation } from "react-i18next";
import { MySideVideo, MyContainer, MyBox } from "./styles/LoginStyles";
import { Box, Button } from "@material-ui/core";
import video from "../../../images/sidevideo.png";
const SideVideo = () => {
  const { t } = useTranslation("login");
  return (
    <MySideVideo>
      <MyContainer maxWidth="sm">
        <MyBox clone mt={5}>
          <Button
            variant="contained"
            style={{
              color: "#509CF9",
              backgroundColor: "#FFF",
            }}
          >
            {t("start")}
          </Button>
        </MyBox>
        <Box alignSelf="center" mb={10}>
          <img src={video} alt="Stafi video" />
        </Box>
      </MyContainer>
    </MySideVideo>
  );
};

export default SideVideo;
