import {
  AppBar,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import AccountButton from "./AccountButton";
import LanguageSwitch from "./LanguageSwitch";
import { Link } from "react-router-dom";
import { Logo } from "../../../../../components/common";
import { Menu } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const TOP_NAV_HEIGHT = 64;

const TopNav = (props) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const { onMobileNavOpen, user, ...other } = props;

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const theme = useTheme();
  // const { pathname } = useLocation();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      style={{
        backgroundColor: theme.palette.background.default,
        top: 0,
        margin: 2,
      }}
      {...other}
    >
      <Box
        display="flex"
        flexDirection="row-reverse"
        alignItems={"center"}
        spacing={2}
        paddingRight={isEnglish && 2}
        paddingLeft={!isEnglish ? 2 : 0}
        style={{
          minHeight: TOP_NAV_HEIGHT,
          width: "100%",
        }}
      >
        {!lgUp && <Box flexGrow={0}>{!lgUp && <AccountButton />}</Box>}
        {!lgUp && <LanguageSwitch />}

        {!lgUp && (
          <>
            <Box
              flexGrow={1}
              style={{
                borderRadius: 1,
                display: "flex",
                padding: 10,
              }}
            >
              <Box component={Link} to={"/"}>
                <Logo />
              </Box>

              <IconButton onClick={onMobileNavOpen}>
                <Menu color="primary" />
              </IconButton>
            </Box>
          </>
        )}
        {/* {lgUp &&
          (user?.isCompanyOwner
            ? navBarActions(pathname)
            : employeeNavBarActions(pathname))} */}

        {lgUp && <AccountButton />}
        {lgUp && <LanguageSwitch />}
      </Box>
    </AppBar>
  );
};

export default TopNav;
