import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { addApproverRule, updateApproverRule } from "../../../../redux/actions";
import AutoCompleteCategories from "../../../common/AutocompleteCategoriesInput";
import AutocompleteInput from "../../../common/AutocompleteInput";

const ApproversForm = ({
  setOpen,
  rule,
  employees,
  departments,
  locations,
  addApproverRule,
  updateApproverRule,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";

  const theme = useTheme();

  const filteredEmployees = employees

    ?.filter(
      (emp) =>
        emp.employmentStatus !== "RESIGNED" ||
        emp.employmentStatus !== "LAID_OFF"
    )
    .map((emp) => ({
      label: emp.user ? emp.user?.fullName : emp?.fullName,
      value: emp.id,
      hrAccess: emp.hrAccess,
    }));

  const [departmentsState, setDepartmentsState] = useState(
    rule
      ? rule?.departments?.map((department) => {
          return {
            label: department.name,
            value: department.id,
          };
        })
      : []
  );

  const [locationsState, setLocationsState] = useState(
    rule
      ? rule?.locations?.map((location) => {
          return {
            label: location.name,
            value: location.id,
          };
        })
      : []
  );

  const [approversState, setApproversState] = useState(
    rule
      ? rule?.users?.map((user) => {
          return {
            employeeId: user.employee.id,
            id: rule.id,
            hrAccess: user.employee.hrAccess,
          };
        })
      : []
  );

  let lastApprover = approversState.slice(-1).pop();

  const getLastApproverId = () => {
    if (approversState.length === 0) return 1;

    const lastApprover = approversState.pop();
    return Number(lastApprover.id) + 1;
  };

  const onClickAddApprover = () => {
    let newState = [
      ...approversState,
      { employeeId: null, id: getLastApproverId(), hrAccess: false },
    ];
    setApproversState(newState);
  };

  const onClickRemoveApprover = (approverId) => {
    const updatedApproverState = approversState.filter((approver) =>
      rule ? approver.employeeId !== approverId : approver.id !== approverId
    );

    setApproversState(updatedApproverState);
  };

  const handleApproverEmployeeChange = (e, approverId) => {
    const updatedApproversState = approversState.map((approver) => {
      const _employee = filteredEmployees.find(
        (emp) => Number(emp.value) === Number(e)
      );
      if (rule) {
        if (approver.employeeId === approverId) {
          return {
            ...approver,
            employeeId: e,
            hrAccess: _employee.hrAccess,
          };
        } else {
          return approver;
        }
      } else {
        if (approver.id === approverId) {
          return {
            ...approver,
            employeeId: e,
            hrAccess: _employee.hrAccess,
          };
        } else {
          return approver;
        }
      }
    });

    setApproversState(updatedApproversState);
  };

  const handleAddRule = () => {
    const departmentIds = departmentsState.map((dep) => Number(dep.value));
    const locationIds = locationsState.map((location) =>
      Number(location.value)
    );
    const employeeIds = approversState.map((approver) =>
      Number(approver.employeeId)
    );
    const order = 4;

    if (rule) {
      updateApproverRule({
        approverRuleId: rule.id,
        departmentIds,
        employeeIds,
        locationIds,
        order,
      });
    } else {
      addApproverRule({ departmentIds, employeeIds, locationIds, order });
    }

    setOpen(false);
  };

  return (
    <>
      <Box height={"auto"} maxHeight={"60vh"} margin={4}>
        <Grid container spacing={3}>
          <Grid item md={6} sm={6}>
            <AutocompleteInput
              value={departmentsState}
              options={departments
                .filter((dep) => {
                  const departmentsSelected = departmentsState.map(
                    (dep) => dep.value
                  );
                  return !departmentsSelected.includes(dep.id);
                })

                .map((dep) => ({
                  label: isEnglish ? dep.name : dep.nameAr,
                  value: dep.id,
                }))}
              multiple={true}
              onChange={(e) => {
                setDepartmentsState(e);
              }}
              label={t("departments")}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <AutocompleteInput
              value={locationsState}
              options={locations

                .filter((loc) => {
                  const locationsSelected = locationsState.map(
                    (loc) => loc.value
                  );
                  return !locationsSelected.includes(loc.id);
                })

                .map((loc) => ({
                  label: isEnglish ? loc.name : loc.nameAr,
                  value: loc.id,
                }))}
              multiple={true}
              onChange={(e) => setLocationsState(e)}
              label={t("locations")}
            />
          </Grid>

          <Grid item md={12}>
            <Typography>{t("addRequestApprovers")}</Typography>
          </Grid>

          {approversState?.map((approver, idx) => {
            const isLastApproverSelectInput = idx === approversState.length - 1;
            return (
              <Grid
                key={approver.employeeId}
                item
                container
                md={12}
                xs={12}
                alignItems="center"
              >
                <Grid item md={11} xs={12}>
                  <AutoCompleteCategories
                    label={
                      approver.hrAccess
                        ? `${t("common:approverWithPriority")} ${idx + 1} (${t(
                            "hasHrAccess"
                          )})`
                        : `${t("common:approverWithPriority")} ${idx + 1}`
                    }
                    isRequired={false}
                    excludeFlags={true}
                    name="employeeId"
                    onChange={(e) => {
                      let id = rule ? approver.employeeId : approver.id;
                      handleApproverEmployeeChange(e, id);
                    }}
                    value={approver.employeeId}
                    array={
                      isLastApproverSelectInput && lastApprover
                        ? filteredEmployees
                        : filteredEmployees.filter(
                            (employee) => !employee.hrAccess
                          )
                    }
                  />
                </Grid>

                <Grid item container md={1}>
                  <IconButton
                    style={{ color: "red" }}
                    onClick={() =>
                      onClickRemoveApprover(
                        rule ? approver.employeeId : approver.id
                      )
                    }
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

          <Grid item container md={11} justifyContent="flex-end">
            <Tooltip
              placement="top"
              title={
                lastApprover && lastApprover.hrAccess
                  ? t("lastApproverTooltip")
                  : t("add")
              }
            >
              <Box>
                <IconButton
                  color="primary"
                  onClick={onClickAddApprover}
                  disabled={lastApprover && lastApprover.hrAccess}
                >
                  <Add />
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box
        clone
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        paddingX={2}
        paddingY={2}
      >
        <Grid item md={12} sm={12} xs={11}>
          <Button
            disableRipple
            disabled={
              departmentsState.length === 0 ||
              locationsState.length === 0 ||
              approversState.length === 0
            }
            style={{
              backgroundColor: theme.palette.common.lightBlue,
              color: "white",
            }}
            variant="contained"
            onClick={handleAddRule}
            endIcon={rule ? <Edit /> : <Add />}
          >
            <Typography>{rule ? t("common:save") : t("common:add")}</Typography>
          </Button>
        </Grid>
      </Box>
    </>
  );
};
const mapStateToProps = ({ company, employees }) => ({
  departments: company.departments,
  locations: company.locations,
  employees: employees.employees,
});

const mapDispatchToProps = {
  addApproverRule,
  updateApproverRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproversForm);
