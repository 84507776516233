import { Button, Typography } from "@material-ui/core";

import React from "react";
import { Save } from "@material-ui/icons";
import { connect } from "react-redux";
import { createRecurringSalaryModification } from "../../../../../../../redux/actions";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const CreateRecurringModificationButton = ({
  modification,
  isEarning,
  createRecurringSalaryModification,
  handleClose,
}) => {
  const { t } = useTranslation(["employeeSalary"]);

  return (
    <Button
      color="primary"
      variant="contained"
      endIcon={<Save />}
      onClick={() =>
        createRecurringSalaryModification(
          {
            ...modification,
            amount: isEarning
              ? modification.amount >= 0
                ? modification.amount
                : modification.amount * -1
              : modification.amount >= 0
              ? modification.amount * -1
              : modification.amount,
            startDate: moment(new Date(modification.startDate)).format(
              "YYYY-MM-DD"
            ),
            endDate: modification.endDate
              ? moment(new Date(modification.endDate)).format("YYYY-MM-DD")
              : null,
            receiveDuringVacation: modification.receiveDuringVacation,
            attachment: modification.attachment,
          },
          handleClose
        )
      }
    >
      <Typography>{t("employeeSalary:save")}</Typography>
    </Button>
  );
};

const mapDispatchToProps = {
  createRecurringSalaryModification,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateRecurringModificationButton);
