import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";

export const ActionsPopover = (props) => {
  const { actions, anchorEl, onClose, open = false, ...other } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ style: { width: 220 } }}
      {...other}
    >
      {actions.map((option) => {
        return (
          <MenuItem
            onClick={() => {
              option.onClick?.();
              onClose?.();
            }}
            key={option.label}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{option.label}</Typography>
              }
            />
          </MenuItem>
        );
      })}
    </Popover>
  );
};
