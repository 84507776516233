import { Card, CardHeader } from "@material-ui/core";

import React from "react";
import Table from "../Table";

const PayrollTable = ({
  title,
  action,
  labels,
  tableBody,
  data,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  withPagination,
}) => {
  return (
    <>
      <Card>
        <CardHeader title={title} action={action} />

        <Table
          data={data}
          labels={labels}
          body={tableBody}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          withPagination={withPagination}
        />
      </Card>
    </>
  );
};

export default PayrollTable;
