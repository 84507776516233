import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

const SalaryModification = ({
  sectionTitle,
  modifications,
  allowances,
  selectedAllowances,
  setSelectedAllowances,
}) => {
  const checkAllowanceSelected = (allowance) => {
    if (allowance.receiveDuringVacation === true) {
      return true;
    }
    selectedAllowances.forEach((selectedAllowance) => {
      if (selectedAllowance.id === allowance.id) {
        return true;
      } else {
        return false;
      }
    });
  };

  const handleAllowanceSelected = (e, allowance) => {
    if (e.target.checked === false) {
      const newAllowances = selectedAllowances.filter(
        (selectedAllowance) => selectedAllowance.id !== allowance.id,
      );
      setSelectedAllowances(newAllowances);
    } else {
      setSelectedAllowances([...selectedAllowances, allowance]);
    }
  };

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";

  useEffect(() => {
    if (allowances) {
      const recieveDuringVacation = allowances.filter(
        (allowance) => allowance.receiveDuringVacation === true,
      );
      setSelectedAllowances(recieveDuringVacation);
    }
  }, [allowances, setSelectedAllowances]);

  return (
    <Grid
      item
      container
      md={12}
      spacing={1}
      style={{ marginTop: 9, marginBottom: 9 }}
    >
      {modifications && modifications.length > 0 && (
        <>
          <Grid item md={12}>
            <Typography
              align={isEnglish ? "left" : "right"}
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {sectionTitle}
            </Typography>
          </Grid>

          {modifications.map((modification, idx) => {
            return (
              <>
                <Grid item md={6} key={idx}>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    style={{ fontWeight: 600 }}
                  >
                    {sectionTitle === `${t("common:overtimeCredits")}:`
                      ? `Overtime Credit ${idx + 1}`
                      : modification.reason}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    style={{ fontWeight: 600 }}
                  >
                    {sectionTitle === `${t("common:overtimeCredits")}:`
                      ? Number(modification.overtimeCost).toFixed(3)
                      : modification.amount}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </>
      )}

      {selectedAllowances && selectedAllowances.length > 0 && (
        <>
          <Grid item md={12}>
            <Typography
              align={isEnglish ? "left" : "right"}
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: 600 }}
            >
              {sectionTitle}
            </Typography>
          </Grid>

          {allowances.map((allowance) => {
            return (
              <Grid item md={12}>
                <FormControlLabel
                  label={`${allowance.reason} (${allowance.amount})`}
                  control={
                    <Checkbox
                      disabled={allowance.receiveDuringVacation ? true : false}
                      value={allowance.id}
                      size="medium"
                      checked={checkAllowanceSelected(allowance)}
                      onChange={(e) => {
                        handleAllowanceSelected(e, allowance);
                      }}
                    />
                  }
                />
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default SalaryModification;
