//Material UI
import { Box, Button, Grid, Typography } from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
//Moment
import moment from "moment";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { createResignationRequest } from "../../../../../redux/actions";
//Components
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../common";

const ResignationRequestModal = ({ open, setOpen }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const [resignationRequest, setResignationRequest] = useState({
    reason: "",
    date: moment().format("YYYY-MM-DD"),
  });
  // const [error, setError] = useState("");
  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    dispatch(createResignationRequest(resignationRequest, history));
    onClose();
  };

  return (
    <CompanyStructureModal
      open={open}
      handleClose={onClose}
      fadeIn={open}
      title={t("resignationRequest")}
    >
      <Box height={"auto"} maxHeight={"90vh"} margin={4}>
        {/* {error && <Alert severity="error">{error}</Alert>} */}
        <Box display="flex" flexDirection="column">
          <Box marginBottom={1}>
            <FormInputField
              label={t("reason")}
              value={resignationRequest.reason}
              onChange={(e) =>
                setResignationRequest({
                  ...resignationRequest,
                  reason: e.target.value,
                })
              }
            />
          </Box>
          <Box marginTop={1}>
            <DatePickerField
              isRequired={true}
              label={t("date")}
              value={resignationRequest.date}
              onChange={(date) =>
                setResignationRequest({
                  ...resignationRequest,
                  date: moment(date).format("YYYY-MM-DD"),
                })
              }
            />
          </Box>
        </Box>
        <Grid item md={12} xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            marginTop={2}
          >
            <Box clone>
              <Button variant="contained" color="secondary" onClick={onClose}>
                <Typography>{t("cancel")}</Typography>
              </Button>
            </Box>
            <Box clone marginX={1}>
              <Button variant="contained" color="primary" onClick={onSubmit}>
                <Typography>{t("confirm")}</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </CompanyStructureModal>
  );
};
export default ResignationRequestModal;
