export let getPdfFields = (t) => [
  {
    value: "ID",
    label: t("referenceNumber"),
    active: true,
  },
  {
    value: "EMPLOYEE_NAME",
    label: t("employeeName"),
    active: true,
  },

  {
    value: "NATIONALITY",
    label: t("nationality"),
    active: true,
  },

  {
    value: "LOCATIONS",
    label: t("locations"),
    active: true,
  },

  {
    value: "DEPARTMENT",
    label: t("department"),
    active: true,
  },
  {
    value: "POSITION",
    label: t("position"),
    active: true,
  },
  {
    value: "EMPLOYMENT_STATUS",
    label: t("employmentStatus"),
    active: true,
  },
  {
    value: "GENDER",
    label: t("gender"),
    active: true,
  },
  {
    value: "MARITAL_STATUS",
    label: t("maritalStatus"),
    active: true,
  },
  {
    value: "DATE_OF_BIRTH",
    label: t("dateOfBirth"),
    active: true,
  },
  {
    value: "EMPLOYMENT_DATE",
    label: t("employmentDate"),
    active: true,
  },
  {
    value: "LEAVE_DATE",
    label: t("leaveDate"),
    active: true,
  },
  {
    value: "CONTACT_INFORMATION",
    label: t("contactInformation"),
    active: true,
  },
  {
    value: "POINT_OF_CONTACT_INFORMATION",
    label: t("pointOfContactInformation"),
    active: true,
  },

  {
    value: "BASE_SALARY",
    label: t("baseSalary"),
    active: true,
  },
  {
    value: "PHONE_NUMBER",
    label: t("phone"),
    active: true,
  },
  {
    value: "ANNUAL_BALANCE",
    label: t("annualBalance"),
    active: true,
  },
];
