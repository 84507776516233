import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  return (
    <Button
      onClick={() => i18n.changeLanguage(isEnglish ? "ar" : "en")}
      style={{
        backgroundColor: "transparent",
        textDecoration: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      {isEnglish ? "عربي" : "English"}
    </Button>
  );
};

export default LanguageSwitch;
