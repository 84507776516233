import {Link} from 'react-router-dom'
import React from 'react'
import {TableRow} from '@material-ui/core'
import {useTheme} from '@material-ui/styles'
import {useTranslation} from 'react-i18next'

const ButtonTableRow = ({
  children,
  onClick,
  borderBottom,
  backgroundColor,
  selected,
  ariaChecked,
  role,
  key,
  tabIndex,
  component,
}) => {
  const {
    i18n: {language},
  } = useTranslation()
  const isEnglish = language === 'en'
  const theme = useTheme()

  return (
    <TableRow
      component={Link}
      onClick={onClick}
      style={{
        whiteSpace: 'nowrap',
        borderBottom: borderBottom ? 'unset' : 'none',
        textDecoration: 'none',
        backgroundColor: backgroundColor
          ? theme.palette.common.aliceBlue
          : undefined,
      }}
      align={isEnglish ? 'left' : 'right'}
      selected={selected}
      aria-checked={ariaChecked}
      role={role}
      key={key}
      tabIndex={tabIndex}
    >
      {children}
    </TableRow>
  )
}

export default ButtonTableRow
