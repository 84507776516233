import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";

//MaterialUI

const DonutChart = ({ radius, labels, label }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  let dataMock = [];

  if (labels.length > 0) {
    dataMock.push(
      {
        title: labels[0].label,
        value: labels[0].value,
        color: "#4071F2",
      },
      {
        title: labels[1].label,
        value: labels[1].value,
        color: "#69C895",
      }
    );
  }

  let total = 0.0;
  if (labels && dataMock.length > 0) {
    dataMock.forEach((data) => (total += data.value));
  } else {
    total = 100;
  }

  const getFillColor = (idx) => {
    let fillColor = "#000";
    if (dataMock.length > 0) {
      fillColor = dataMock[idx].color;
    }
    return fillColor;
  };

  return (
    <PieChart
      label={label}
      labelStyle={(index) => ({
        fill: getFillColor(index),
        fontSize: "5px",
        fontFamily: "sans-serif",
      })}
      labelPosition={isEnglish ? 120 : -120}
      radius={radius}
      background={dataMock.length > 0 ? "#E3F0FD" : "lightGrey"}
      totalValue={total}
      startAngle={98}
      lineWidth={40}
      data={dataMock.length > 0 && dataMock}
    />
  );
};

export default DonutChart;
