import {
  Box,
  CardContent,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Clear, CloudDownload, InsertDriveFile } from "@material-ui/icons";
import { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";

const FormFileField = ({
  label,
  name,
  onChange,
  file = "",
  fileId,
  placeholder,
  isRequired,
  deleteFile,
  disabled = false,
  download = true,
  ...inputProps
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const useStyles = makeStyles(
    (theme) => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      iconRight: {
        position: "absolute",
        right: "-30px",
        top: "-30px",
        color: "gray",
      },
      iconLeft: {
        position: "absolute",
        left: "-30px",
        top: "-30px",
        color: "gray",
      },
      label: {
        textDecoration: "underline",
        cursor: "pointer",
        width: "100%",
      },

      formControlRoot: {
        padding: 3,
        "& .MuiFormLabel-root": {
          color: theme.palette.text.secondary,
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInputLabel-formControl": {
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();

  const [fileName, setFileName] = useState(file?.name ?? "");

  const handleChange = (e) => {
    const { files } = e.target;
    if (files.length) {
      setFileName(files[0].name);
    }
    onChange(e);
  };

  const handleDelete = (e) => {
    setFileName("");
    deleteFile(e);
  };

  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      {!disabled && (
        <IconButton className={classes.iconRight} onClick={handleDelete}>
          <Clear />
        </IconButton>
      )}
      {download && (
        <a href={file} target="_blank" download rel="noreferrer">
          <IconButton className={classes.iconLeft}>
            <CloudDownload />
          </IconButton>
        </a>
      )}
      <Input
        required={isRequired}
        label={label}
        type="file"
        onChange={handleChange}
        name={name}
        style={{ display: "none" }}
        id={fileId}
        disabled={disabled}
        inputProps={inputProps}
      />

      <FormLabel htmlFor={fileId}>
        <Box clone display="flex" justifyContent="center">
          <CardContent>
            <InsertDriveFile color="primary" fontSize="large" />
          </CardContent>
        </Box>
        <Box clone display="flex" justifyContent="center">
          <Typography
            className={classes.label}
            variant="subtitle2"
            color="primary"
            align="center"
          >
            {placeholder && placeholder.replace("_", " ")}
          </Typography>
        </Box>
        {fileName && (
          <Box clone display="flex" justifyContent="center">
            <Typography variant="h4" color="textPrimary">
              {fileName}
            </Typography>
          </Box>
        )}
      </FormLabel>
    </FormControl>
  );
};

export default FormFileField;
