import {
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import { FormInputField } from "../../../../../../components/common";
import { connect } from "react-redux";
import { updateCompanyVacation } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const Form = ({ updateCompanyVacation, vacation, edit, setEdit }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [vacationState, setVacation] = useState(vacation);

  const handleSwitchChange = () => {
    setVacation({
      ...vacationState,
      wholeBalance: !vacationState.wholeBalance,
    });
  };

  const handleUpdate = () => {
    updateCompanyVacation(vacationState);
    setEdit(false);
  };

  const handleCancel = () => {
    setVacation(vacation);
    setEdit(false);
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:annualLeave")}
          name="annualLeave"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              annualLeave: e.target.value,
            });
          }}
          value={vacationState?.annualLeave}
          helperText={t("companyProfile:annualLeaveHelperText")}
          id="annualLeave"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:accumulatedFor")}
          name="accumulatedFor"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              accumulatedFor: e.target.value,
            });
          }}
          value={vacationState?.accumulatedFor}
          helperText={t("companyProfile:accumulatedForHelperText")}
          id="accumulatedFor"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:sickLeave")}
          name="sickLeave"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              sickLeave: e.target.value,
            });
          }}
          value={vacationState?.sickLeave}
          helperText={t("companyProfile:sickLeaveHelperText")}
          id="sickLeave"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:casualLeave")}
          name="casualLeave"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              casualLeave: e.target.value,
            });
          }}
          value={vacationState?.casualLeave}
          helperText={t("companyProfile:casualLeaveHelperText")}
          id="casualLeave"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:shortLeave")}
          name="shortLeave"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              shortLeave: e.target.value,
            });
          }}
          value={vacationState.shortLeave}
          helperText={t("companyProfile:shortLeaveHelperText")}
          id="shortLeave"
          autoComplete="shortLeave"
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:offsetLeave")}
          name="shortLeave"
          onChange={(e) => {
            setVacation({
              ...vacationState,
              offsetLeave: e.target.value,
            });
          }}
          value={vacationState.offsetLeave}
          helperText={t("companyProfile:offsetLeaveHelperText")}
          id="offsetLeave"
          autoComplete="offsetLeave"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Grid container spacing={3}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={vacationState.wholeBalance}
                onChange={() => handleSwitchChange()}
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
                {t("companyProfile:wholeBalance")}
              </Typography>
            }
          />
        </Grid>
      </Grid>

      <Grid item container direction="row-reverse" lg={12} xs={12} spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            {t("save")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {t("cancel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  updateCompanyVacation,
};

export default connect(null, mapDispatchToProps)(Form);
