export const filterEmployeesByPosition = (employees, selectedPositions) => {
  let filteredEmployees = employees.filter((employee) => {
    if (selectedPositions?.length === 0) {
      return employee;
    }

    return selectedPositions.some((pos) => pos.value === employee.position.id);
  });

  return filteredEmployees;
};
