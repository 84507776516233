import React, { useState } from "react";

import { IconButton } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import Position from "./Position";
import PositionEmployees from "./Avatars/PositionEmployees";
import PositionMenu from "./PositionMenu";
import useStyles from "../../styles";
import { useTranslation } from "react-i18next";

const Positions = ({ department }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [positionId, setPositionId] = useState(null);

  const handleClick = (event, positionId) => {
    setAnchorEl(event.currentTarget);
    setPositionId(positionId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPosition = Boolean(anchorEl);
  const idPosition = openPosition ? "simple-popper" : undefined;

  const positionList = department.positions.map((position) => (
    <Position
      position={position}
      employeeAvatars={
        <PositionEmployees position={position} department={department} />
      }
      action={
        <>
          <IconButton
            disableRipple
            className={classes.menuButton}
            size="small"
            aria-describedby={idPosition}
            onClick={(e) => handleClick(e, position.id)}
          >
            <MoreVert />
          </IconButton>
          <PositionMenu
            position={department?.positions?.find(
              (pos) => pos.id === positionId
            )}
            departmentId={department.id}
            classes={classes}
            openPosition={openPosition}
            idPosition={idPosition}
            handleClose={handleClose}
            anchorEl={anchorEl}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </>
      }
      description={`${position.employees.length} ${t("common:employees")}`}
      departmentId={department.id}
      key={position.id}
    />
  ));
  return positionList;
};

export default Positions;
