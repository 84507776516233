export const filterEmployees = (
  employees,
  department,
  position,
  status,
  query
) => {
  let departmentEmployees = employees.filter((employee) => {
    if (department === "All") {
      return employee;
    }
    return employee.department.id === department;
  });
  let positionEmployees = departmentEmployees.filter((employee) => {
    if (position === "All") {
      return employee;
    }
    return employee.position.id === position;
  });
  let statusEmployee = positionEmployees.filter((employee) => {
    if (status === "All") {
      return employee;
    }
    return employee.employmentStatus === status;
  });

  return statusEmployee.filter((employee) => {
    const name = employee.user ? employee.user?.fullName : employee?.fullName;
    return (
      name.toLowerCase().includes(query.toLowerCase()) ||
      employee.id.includes(query)
    );
  });
};

export const filterRequests = (
  requests,
  department,
  position,
  status,
  kind,
  query
) => {
  let departmentRequests = requests.filter((request) => {
    if (department.length === 0) {
      return request;
    }

    return department.some(
      (dep) => dep.value === request.employee.department.id
    );
  });

  let positionRequests = departmentRequests.filter((request) => {
    if (position.length === 0) {
      return request;
    }
    return position.some((pos) => pos.value === request.employee.position.id);
  });
  let statusRequests = positionRequests.filter((request) => {
    if (status.length === 0) {
      return request;
    }
    return status.some((stat) => stat.value === request.status);
  });
  let kindsRequests = statusRequests.filter((request) => {
    if (kind.length === 0) {
      return request;
    }
    return kind.some((_kind) => _kind.value === request.kind);
  });

  return kindsRequests.filter((request) => {
    const fullName = request.employee.user
      ? request.employee.user?.fullName
      : request.employee?.fullName;
    return (
      fullName.toLowerCase().includes(query.toLowerCase()) ||
      request.employee.id.includes(query)
    );
  });
};
