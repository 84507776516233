import React, { useState } from "react";

import CreateLeaveDebit from "./CreateLeaveDebit";
import { Grid } from "@material-ui/core";
import VacationCredits from "./VacationCredits";
import VacationDebits from "./VacationDebits";
import VacationRequests from "./VacationRequests";
import VacationsHeader from "./VacationsHeader";
import VacationsOverview from "./VacationsOverview";
import EditLeave from "./EditLeave";

const Vacations = ({ employee }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("credit");
  const [openEdit, setOpenEdit] = useState(false);
  const [leaveId, setLeaveId] = useState();
  const leaveBalance = employee.leaveBalance;
  const leaveDebits = leaveBalance.leavedebits;
  const leaveCredits = leaveBalance.leavecredits;
  const leave =
    type === "credit"
      ? leaveCredits.find((leave) => leave.id === leaveId)
      : leaveDebits.find((leave) => leave.id === leaveId);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <VacationsHeader employee={employee} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <VacationsOverview employee={employee} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <VacationRequests employee={employee} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <VacationCredits
            employee={employee}
            setType={setType}
            setOpen={setOpen}
            setOpenEdit={setOpenEdit}
            setLeaveId={setLeaveId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <VacationDebits
            employee={employee}
            setType={setType}
            setOpen={setOpen}
            setOpenEdit={setOpenEdit}
            setLeaveId={setLeaveId}
          />
        </Grid>
      </Grid>
      <CreateLeaveDebit
        open={open}
        setOpen={setOpen}
        type={type}
        employee={employee}
      />
      <EditLeave
        open={openEdit}
        setOpen={setOpenEdit}
        leave={leave}
        employee={employee}
        type={type}
      />
    </>
  );
};

export default Vacations;
