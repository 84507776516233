import { getBody, getHeaders } from "./createPDF";

export const getXlsxData = (
  employeesForXlsx,
  setPdfFields,
  payrollPdfFields,
  setOpenModal,
  t,
  isEnglish
) => {
  const headers = getHeaders(employeesForXlsx[0], t, isEnglish);
  const body = getBody(employeesForXlsx, isEnglish);

  const data = [headers, ...body];

  return data;
};
