import { Box, Drawer, Grid, useTheme } from "@material-ui/core";

import { Link } from "react-router-dom";
import { Logo } from "../../../../components/common";
import SidebarMenu from "../SideBarMenu";

const MobileNav = (props) => {
  const { open, onClose, user } = props;
  const theme = useTheme();

  const MOBILE_NAV_WIDTH = 280;

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          width: MOBILE_NAV_WIDTH,
          backgroundColor: theme.palette.background.default,
        },
      }}
      variant="temporary"
    >
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          container
          alignItems="center"
          spacing={2}
          style={{
            margin: 5,
            padding: 5,
          }}
        >
          <Box
            component={Link}
            to={"/"}
            style={{
              borderColor: "transparent",
              borderRadius: 1,
              borderStyle: "solid",
              borderWidth: 1,
              display: "flex",
              height: 40,
              padding: "4px",
              width: 40,
            }}
          >
            <Logo />
          </Box>
        </Grid>
        <Grid
          item
          component="nav"
          spacing={2}
          container
          style={{
            marginTop: 20,
            flexGrow: 1,
            paddingX: 2,
          }}
        >
          <SidebarMenu user={user} />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default MobileNav;
