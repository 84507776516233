import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Icon,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add, Cancel, Done, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import NewAnnouncements from "./NewAnnouncements";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AnnouncementsComponent = ({ announcements }) => {
  ReactGA.pageview(window.location.pathname);

  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "announcement"]);
  const isEnglish = language === "en";

  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item container direction="row-reverse" lg={11} xs={12}>
          <Button
            endIcon={<Add color="white" />}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("common:newAnnouncement")}
          </Button>
        </Grid>
        <Grid item container lg={11} xs={12}>
          {announcements.map((announcement, idx) => (
            <Grid
              item
              lg={12}
              xs={12}
              style={{ marginBottom: 5 }}
              className={classes.root}
            >
              <Accordion
                key={idx}
                expanded={expanded === idx}
                onChange={handleChange(idx)}
                square={true}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item md={10} xs={6}>
                      <Typography variant="h3" color="primary">
                        {isEnglish ? announcement.title : announcement.titleAr}
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <Typography color="primary">
                        {moment(new Date(announcement.postDate)).format(
                          "DD-MM-yyyy hh:mm:a"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Typography variant="h5" color="secondary">
                        {t("body")}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Typography color="primary">
                        {isEnglish ? announcement.body : announcement.bodyAr}
                      </Typography>
                    </Grid>
                    {announcement.resources.length !== 0 && (
                      <>
                        <Grid item md={6} xs={12}>
                          <Typography variant="h5" color="secondary">
                            {t("resources")} ({announcement.resources.length})
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {announcement.resources.map((resource) => (
                            <Typography
                              key={resource.id}
                              component={Link}
                              to={`/resource/${resource.id}`}
                              color="primary"
                            >
                              {isEnglish ? resource.name : resource.nameAr}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    )}
                    <Grid item md={12} xs={12}>
                      <Typography variant="h5" color="secondary">
                        {t("recipients")} (
                        {announcement.announcementNotifications.length})
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {announcement.announcementNotifications.map(
                        (notification) => (
                          <Box
                            clone
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            borderRadius={0}
                          >
                            <Grid container spacing={3}>
                              <Grid item md={3} xs={6}>
                                {notification.employee.user
                                  ? notification.employee.user?.fullName
                                  : notification.employee?.fullName}
                              </Grid>
                              <Grid item md={3} xs={6}>
                                {isEnglish
                                  ? notification.employee.department.name
                                  : notification.employee.department.nameAr}
                              </Grid>
                              <Grid item md={3} xs={6}>
                                {isEnglish
                                  ? notification.employee.position.name
                                  : notification.employee.position.nameAr}
                              </Grid>
                              <Box
                                clone
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Grid item md={3} xs={6}>
                                  <Box
                                    clone
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <Typography>
                                      {t("seen")}
                                      {notification.seen ? (
                                        <Icon>
                                          <Done />
                                        </Icon>
                                      ) : (
                                        <Icon>
                                          <Cancel />
                                        </Icon>
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        )
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <NewAnnouncements open={open} setOpen={setOpen} />
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  announcements: company.announcements,
});

export default connect(mapStateToProps)(AnnouncementsComponent);
