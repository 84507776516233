import "jspdf-autotable";

import jsPDF from "jspdf";
import moment from "moment";

import { getPdfTemplate } from "../../../helpers/getPdfTemplate";
import { leavesKind } from "../../common/DropDownsData";

export const createRequestPdf = (
  t,
  isEnglish,
  profile,
  requestKind,
  requestStatus,
  createdOn,
  lastModifiedOn,
  lastModifiedBy,
  leave,
  leaveBalance
) => {
  const pdf = new jsPDF();
  var pdfWidth = pdf.internal.pageSize.width;
  const pageWidth = pdfWidth || pdf.internal.pageSize.getWidth();
  const currentHeight = 15;

  getPdfTemplate(
    pdf,
    pageWidth,
    pdfWidth,
    currentHeight,
    t,
    isEnglish,
    profile.company.name,
    profile.company.logo
  );

  const requestDetailsTitle = t("requestDetails");
  pdf.setFontSize(13);
  pdf.text(15, currentHeight + 40, requestDetailsTitle, "left");

  // Request ID
  const requestField = t("request");
  const requestId = requestKind;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 50, requestField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 50, requestId, "left");

  // Request Status
  const requestStatusTitle = t("requestStatus");
  const requestStatusValue = requestStatus;
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 50, requestStatusTitle, "left");
  pdf.text(165, currentHeight + 50, requestStatusValue, "left");

  // Employee Name
  const employeeNameField = t("employeeName");
  const employeeName = profile.user
    ? profile.user?.fullName
    : profile?.fullName;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 60, employeeNameField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 60, employeeName, "left");

  // Employee Department
  const employeeDepartmentTitle = t("employeeDepartment");
  const employeeDepartment = isEnglish
    ? profile.department.name
    : profile.department.nameAr;
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 60, employeeDepartmentTitle, "left");
  pdf.text(165, currentHeight + 60, employeeDepartment, "left");

  // Employee Position
  const employeePositionField = t("employeePosition");
  const employeePosition = isEnglish
    ? profile.position.name
    : profile.position.nameAr;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 70, employeePositionField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 70, employeePosition, "left");

  // Created On
  const createdOnTitle = t("createdOn");
  const createdOnValue = createdOn;
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 70, createdOnTitle, "left");
  pdf.text(165, currentHeight + 70, createdOnValue, "left");

  // Last modified on
  const lastModifiedOnField = t("lastModifiedOn");
  const lastModifiedOnValue = lastModifiedOn;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 80, lastModifiedOnField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 80, lastModifiedOnValue, "left");

  // Last modified by
  const lastModifiedByTitle = t("lastModifiedBy");
  const lastModifiedByValue = lastModifiedBy;
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 80, lastModifiedByTitle, "left");
  pdf.text(165, currentHeight + 80, lastModifiedByValue, "left");

  const leaveDetailsTitle = t("leaveDetails");
  pdf.setFontSize(13);
  pdf.text(15, currentHeight + 110, leaveDetailsTitle, "left");

  // Reason
  const reasonField = t("lastModifiedOn");
  const reasonValue = leave.reason;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 120, reasonField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 120, reasonValue, "left");

  // Leave kind
  const leaveKindTitle = t("kind");
  const leaveKindObj = leavesKind.find(
    (kindObj) => kindObj.value === leave.kind
  );
  const leaveKind = isEnglish ? leaveKindObj.name : leaveKindObj.nameAr;
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 120, leaveKindTitle, "left");
  pdf.text(165, currentHeight + 120, leaveKind, "left");

  // Start Date
  const startDateField = t("startDate");
  const startDateValue = moment(new Date(leave.date)).format("DD-MM-yyyy");
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 130, startDateField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 130, startDateValue, "left");

  // End Date
  const endDateField = t("endDate");
  const endDateValue = moment(new Date(leave.endDate)).format("DD-MM-yyyy");
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 130, endDateField, "left");
  pdf.text(165, currentHeight + 130, endDateValue, "left");

  // Amount
  const amountField = t("amount");
  const amountValue = `${leave.amount} ${t("days")}`;
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 140, amountField, "left");
  pdf.setFontSize(12);
  pdf.text(60, currentHeight + 140, amountValue, "left");

  // Balance
  const balanceField = t("balance");
  const balanceFieldValue = leaveBalance.toString();
  pdf.setFontSize(12);
  pdf.text(105, currentHeight + 140, balanceField, "left");
  pdf.text(165, currentHeight + 140, balanceFieldValue, "left");

  pdf.save(`${employeeName}-${leaveKind}-${requestKind}-request.pdf`);
};
