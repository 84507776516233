import {
  Box,
  Table as MuiTable,
  TableBody,
  Typography,
} from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";
import TablePagination from "../TablePagination";
import { EnhancedTableHead } from "./EnhancedTableHead";

const EnhancedTable = ({
  data,
  labels,
  body,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  withPagination,
  employerDashboard,
  employeesPage,
  showSalary,
  setShowSalary,
}) => {
  const { t } = useTranslation("common");

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 5);
    setPage(0);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      {data && data?.length > 0 && (
        <MuiTable>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onRequestSort={handleRequestSort}
            labels={labels}
            employeesPage={employeesPage}
            showSalary={showSalary}
            setShowSalary={setShowSalary}
          />
          <TableBody>{body}</TableBody>
        </MuiTable>
      )}
      {data?.length === 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          margin={4}
        >
          <Typography color="primary" align="center">
            {t("noRecords")}
          </Typography>
        </Box>
      )}

      {(employerDashboard || withPagination) && data?.length > 0 && (
        <TablePagination
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default EnhancedTable;
