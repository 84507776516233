import {MenuItem} from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

const StyledMenuUserItem = ({children}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      alignItems: 'center',
      '&:focus': {
        backgroundColor: theme.palette.common.blue,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          backgroundColor: theme.palette.common.blue,
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }))
  const classes = useStyles()
  return <MenuItem className={classes.root}>{children}</MenuItem>
}

export default StyledMenuUserItem
