import CompanyBasicDetails from "./CompanyBasicDetails";
import CompanyPocDetails from "./CompanyPocDetails";
import CompanyProfileHeader from "./CompanyProfileHeader";
import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

const File = ({ company, sectors, address, poc }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <CompanyProfileHeader company={company} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CompanyBasicDetails company={company} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <CompanyPocDetails company={company} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

export default connect(mapStateToProps)(File);
