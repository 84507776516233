import { createTheme } from "@material-ui/core/styles";

const staffiBlack = "#232529";
const staffiBlackText = "#37454B";
const staffiGrey = "#A9B4C4";
const staffiGreyBackground = "#E4ECF3";
const staffiBlue = "#3D5E77";
const staffiDarkBlue = "#37556b";
const staffiDodgerBlue = "#B79344";
const staffiLightBlue = "#778ea0";
const staffiOffBlue = "#F6F8FA";
const StaffiOffBlueDark = "#b6c6d7";
const staffiGreen = "#60CA6D";
const staffiDarkGreen = "#717978";
const staffiWhite = "#FFFFFF";
const staffiCement = "#e0e0e0";
const staffiRed = "#ff0033";

// 1rem -> 16px
const theme = createTheme({
  palette: {
    common: {
      black: staffiBlack,
      blue: staffiBlue,
      lightBlue: staffiLightBlue,
      aliceBlue: staffiOffBlue,
      darkAliceBlue: StaffiOffBlueDark,
      dodgerBlue: staffiDodgerBlue,
      white: staffiWhite,
      grey: staffiGrey,

      green: staffiGreen,
      darkGreen: staffiDarkGreen,
      greyEightyEight: staffiCement,
      red: staffiRed,
    },
    primary: { main: staffiBlue, dark: staffiDarkBlue },
    secondary: { main: staffiLightBlue },
    success: { main: staffiGreen },
    text: {
      primary: staffiBlackText,
      secondary: staffiGrey,
    },
    action: {
      active: staffiOffBlue,
    },
    background: {
      paper: staffiWhite,
      default: staffiGreyBackground,
    },
  },
  spacing: 10,
  typography: {
    fontFamily: "Helvetica, Arial",
    h1: {
      fontSize: "2.187rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.56rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    button: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 20,
      },
    },
    MuiCardHeader: {
      root: {
        padding: "20px",
      },
      action: {
        marginTop: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});

export default theme;
