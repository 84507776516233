//MaterialUI
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CameraCheckin = ({ company, updateCompanyProfile }) => {
  const { t } = useTranslation("common");

  const [enableCameraCheckin, setEnableCameraCheckin] = useState(
    company?.cameraCheckinEnabled
  );

  const handleSwitchChange = () => {
    setEnableCameraCheckin(!enableCameraCheckin);

    updateCompanyProfile({
      ...company,
      cameraCheckinEnabled: !enableCameraCheckin,
    });
  };

  return (
    <Grid item md={12} xs={12}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={enableCameraCheckin}
            onChange={() => handleSwitchChange()}
          />
        }
        labelPlacement="start"
        label={
          <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
            {t("enableCameraCheckin")}
          </Typography>
        }
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(CameraCheckin);
