import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { updateAnnouncement } from "../../../../redux/actions";

const Notification = ({ announcements, updateAnnouncement }) => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation("announcement");
  const isEnglish = language === "en";
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    let foundAnnoucement = announcements.find(
      (announcement) => announcement.id === announcementId
    );
    if (foundAnnoucement) {
      setAnnouncement(foundAnnoucement);
      if (!foundAnnoucement.seen) {
        updateAnnouncement({ id: foundAnnoucement.id, seen: true });
      }
    }
  }, [announcements, announcementId, updateAnnouncement]);

  if (!announcement) return <></>;

  const resources = announcement.announcement.resources;

  return (
    <Grid container justifyContent="flex-start" spacing={3}>
      <Grid item md={10} xs={10}>
        <Box clone paddingY={6} bgcolor="white" borderRadius={10}>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography variant="h3" color="primary">
                {isEnglish
                  ? announcement.announcement.title
                  : announcement.announcement.titleAr}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="primary">
                {isEnglish
                  ? announcement.announcement.body
                  : announcement.announcement.bodyAr}
              </Typography>
            </Grid>
            {resources.map((resource) => (
              <Grid key={resource.id} item md={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h3" color="primary">
                      {isEnglish ? resource.name : resource.nameAr}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {isEnglish
                      ? resource.description
                          .split("/n")
                          .map((line) => (
                            <Typography key={line}>{line}</Typography>
                          ))
                      : resource.descriptionAr
                          .split("/n")
                          .map((line) => (
                            <Typography key={line}>{line}</Typography>
                          ))}
                  </Grid>
                  {resource.files.length !== 0 ||
                    (resource.links.length !== 0 && (
                      <Grid item md={12} xs={12}>
                        <Typography variant="h3" color="primary">
                          {t("attachments")}
                        </Typography>
                      </Grid>
                    ))}
                  {resource.files.length !== 0 && (
                    <Grid item md={6} xs={12}>
                      <Typography variant="h4" color="secondary">
                        {t("files")}
                      </Typography>
                      <List>
                        {resource.files.map((file) => (
                          <ListItem key={file.file}>
                            <a
                              href={file.file}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              {isEnglish
                                ? file.description
                                  ? file.description
                                  : file.file
                                : file.descriptionAr
                                ? file.descriptionAr
                                : file.file}
                            </a>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  )}
                  {resource.links.length !== 0 && (
                    <Grid item md={6} xs={12}>
                      <Typography variant="h4" color="secondary">
                        {t("links")}
                      </Typography>
                      <List>
                        {resource.links.map((link) => (
                          <ListItem key={link.link}>
                            <a
                              href={link.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {isEnglish
                                ? link.description
                                  ? link.description
                                  : link.link
                                : link.descriptionAr
                                ? link.descriptionAr
                                : link.link}
                            </a>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapsStateToPorps = ({ employee }) => ({
  announcements: employee.profile?.announcements || [],
});

const MapDispatchToProps = {
  updateAnnouncement,
};

export default connect(mapsStateToPorps, MapDispatchToProps)(Notification);
