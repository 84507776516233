import { Avatar, Box, TableCell, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomTableCell, CustomTableRow } from "../../../../components/common";
import { employeesStatus } from "../../../../components/common/DropDownsData";

const EmployeeRow = ({ employee, showSalary, setShowSalary }) => {
  const [status] = useState(
    employeesStatus.find(
      (_status) => _status.value === employee.employmentStatus
    )
  );
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  const getEmployeeLocations = () => {
    let empLocations = [];

    employee?.locations?.forEach((location) => {
      if (isEnglish) {
        empLocations.push(location?.name);
      } else {
        empLocations.push(location?.nameAr);
      }
    });

    return empLocations.join(",");
  };
  return (
    <Box clone whiteSpace="nowrap">
      <CustomTableRow to={`employees/${employee.id}`}>
        <CustomTableCell>
          {employee.referenceNumber ? employee.referenceNumber : employee.id}
        </CustomTableCell>
        <CustomTableCell
          style={{
            whiteSpace: "nowrap",
            borderBottom: "none",
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {employee.user ? employee.user?.fullName : employee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.department.name : employee.department.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.position.name : employee.position.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? status.name : status.nameAr}
        </CustomTableCell>
        <CustomTableCell>{getEmployeeLocations()}</CustomTableCell>
        <CustomTableCell>
          {moment(new Date(employee.lastUpdated)).format("DD-MM-yyyy")}
        </CustomTableCell>
        {showSalary && (
          <TableCell
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
            align="center"
          >
            {employee.baseSalary}{" "}
          </TableCell>
        )}
      </CustomTableRow>
    </Box>
  );
};

export default EmployeeRow;
