//Material UI
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
// Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { updateEmployeeAttendance } from "../../../redux/actions";
import getCorrectTimezone, { revertTimeZone } from "../../../utils";
//Components
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
} from "../../common";
import TimePickerField from "../../common/TimePickerField";
import {
  calculateDuration,
  checkInDistanceToDisplay,
  checkOutDistanceToDisplay,
} from "./utils";
import { Edit } from "@material-ui/icons";

const EmployeeAttendanceItem = ({
  attendance,
  updateEmployeeAttendance,
  isCompanyOwner,
  user,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const [openModal, setOpenModal] = useState(false);

  const [attendanceState, setAttendanceState] = useState({
    checkIn: moment(getCorrectTimezone(attendance.checkIn)),
    checkOut: attendance.checkOut
      ? moment(getCorrectTimezone(attendance.checkOut))
      : "",
  });

  const isEnglish = language === "en";
  let checkOutDate = moment(attendance.checkOut).format("LTS");

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleUpdateTimes = () => {
    const checkIn = revertTimeZone(attendanceState.checkIn);
    const checkOut = revertTimeZone(attendanceState.checkOut);
    updateEmployeeAttendance({
      ...attendance,
      id: attendance.id,
      employeeId: attendance.employee.id,
      checkIn: checkIn,
      checkOut: checkOut,
    });

    setOpenModal(false);
  };

  return (
    <>
      <Box clone whiteSpace="nowrap">
        <CustomTableRow onClick={isCompanyOwner && handleOpenModal}>
          <CustomTableCell
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingLeft={1}
            >
              <Avatar alt="Logo" src={attendance.employee.image} />
              <Box clone paddingX={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  {attendance.employee?.fullName}
                </Typography>
              </Box>
            </Box>
          </CustomTableCell>
          <CustomTableCell>
            {/* {moment(attendance.checkIn).format("LLL")} */}
            {moment(getCorrectTimezone(attendance.checkIn)).format("LLL")}
          </CustomTableCell>
          <CustomTableCell>
            {checkOutDate !== "Invalid date" ? (
              moment(getCorrectTimezone(attendance.checkOut)).format("LLL")
            ) : (
              <Button
                onClick={handleOpenModal}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  textTransform: "none",
                }}
                variant="contained"
              >
                {t("pending")}
              </Button>
            )}
          </CustomTableCell>
          <CustomTableCell>
            {attendance.location
              ? isEnglish
                ? attendance.location.name
                : attendance.location.nameAr
              : "-"}
          </CustomTableCell>
          <CustomTableCell>
            {checkInDistanceToDisplay(attendance, t)}
          </CustomTableCell>
          <CustomTableCell>
            {checkOutDistanceToDisplay(attendance, t)}
          </CustomTableCell>
          <CustomTableCell>{calculateDuration(attendance)}</CustomTableCell>

          {attendance.image ? (
            <CustomTableCell
              style={{
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <Box
                clone
                display="flex"
                flexDirection="row"
                alignItems="center"
                paddingLeft={1}
                height={"40px"}
                width={"40px"}
              >
                <Avatar alt="employee-checkin" src={attendance.image} />
              </Box>
            </CustomTableCell>
          ) : (
            <CustomTableCell>
              <Typography>-</Typography>
            </CustomTableCell>
          )}

          {attendance.imageCheckout ? (
            <CustomTableCell
              style={{
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <Box
                clone
                display="flex"
                flexDirection="row"
                alignItems="center"
                paddingLeft={1}
                height={"40px"}
                width={"40px"}
              >
                <Avatar
                  alt="employee-checkout"
                  src={attendance.imageCheckout}
                />
              </Box>
            </CustomTableCell>
          ) : (
            <CustomTableCell>
              <Typography>-</Typography>
            </CustomTableCell>
          )}
          {(isCompanyOwner || user.hasAttendanceAccess) &&
            attendance.checkOut && (
              <CustomTableCell>
                <Edit onClick={handleOpenModal} />
              </CustomTableCell>
            )}
        </CustomTableRow>
      </Box>
      <CompanyStructureModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        fadeIn={openModal}
        title={t("modifyCheckin/CheckoutTime")}
      >
        <Box height="auto" maxHeight="90vh" margin={4}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TimePickerField
                label={t("checkInTime")}
                name="checkIn"
                onChange={(e) =>
                  setAttendanceState({
                    ...attendanceState,
                    checkIn: moment(e),
                  })
                }
                value={attendanceState.checkIn}
              />
            </Grid>

            <Grid item md={6}>
              <TimePickerField
                label={t("checkOutTime")}
                name="checkOut"
                onChange={(e) => {
                  const checkOutValue = moment(new Date(e));
                  const checkInValue = moment(attendance.checkIn);
                  const isNextDay = checkOutValue.hour() < checkInValue.hour();
                  if (isNextDay) {
                    checkInValue.add(1, "days"); // add 1 day to checkIn
                  }
                  checkOutValue
                    .year(checkInValue.year())
                    .month(checkInValue.month())
                    .date(checkInValue.date())
                    .format("LLL");

                  console.log(checkOutValue);
                  setAttendanceState({
                    ...attendanceState,
                    checkOut: checkOutValue,
                  });
                }}
                value={attendanceState.checkOut}
              />
            </Grid>

            <Grid item container justifyContent="flex-end" md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateTimes}
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});
const mapDispatchToProps = {
  updateEmployeeAttendance,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeAttendanceItem);
