import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import React from "react";
import { RequestsTable } from "../../../common";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const VacationCredits = ({
  employee,
  user,
  setType,
  setOpen,
  setOpenEdit,
  setLeaveId,
}) => {
  const { t } = useTranslation("common");

  const leaveCreditLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:amount"),
    t("common:document"),
    t("common:edit"),
    t("common:delete"),
  ];

  if (!user.isCompanyOwner) {
    leaveCreditLabels.pop();
  }

  let leaveCredits = employee.leaveBalance.leavecredits.map((leave) => {
    return {
      id: leave.id,
      reason: leave.reason,
      kind: leave.kind,
      amount: leave.amount,
      document: leave.document,
    };
  });

  return (
    <RequestsTable
      title={t("vacationCredits")}
      forLeaveCredit
      data={leaveCredits}
      labels={leaveCreditLabels}
      canDeleteRecord={user.isCompanyOwner}
      setOpenEdit={setOpenEdit}
      setLeaveId={setLeaveId}
      setType={setType}
      action={
        user &&
        user.isCompanyOwner && (
          <Button
            size="small"
            endIcon={<Add />}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => {
              setType("credit");
              setOpen(true);
            }}
          >
            {t("creditAdd")}
          </Button>
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(VacationCredits);
