import {
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
//Components
import { CompanyStructureModal, CustomTableCell, CustomTableRow } from ".";
import React, { useState } from "react";
import getCorrectTimezone, { revertTimeZone } from "../../utils";

import { TablePagination } from "../../redesign/components/common";
import TimePickerField from "./TimePickerField";
import { calcDistance } from "./utils";
import { connect } from "react-redux";
//Moment
import moment from "moment";
import theme from "../../styles/theme";
import { updateEmployeeAttendance } from "../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

const AttendanceTable = ({
  hasLocation,
  attendances,
  title,
  icon,
  companyOwner,
  myAttendanceHistory,
  employeeDashboard,
  handlePendingCheckout,
  updateEmployeeAttendance,
}) => {
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [attendanceForModal, setAttendanceForModal] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  let headerLabels = [
    t("checkInTime"),
    t("checkOutTime"),
    t("location"),
    t("checkInDistance"),
    t("checkOutDistance"),
    t("duration"),
  ];

  if (companyOwner) headerLabels.push(t("image"));
  if (companyOwner) headerLabels.push(t("checkoutImage"));

  const headerTitles = headerLabels.map((label, i) => (
    <CustomTableCell key={(label, +i)}>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align={isEnglish ? "left" : "right"}
      >
        {label}
      </Typography>
    </CustomTableCell>
  ));

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 5);
    setPage(0);
  };

  const employeeAttendancesList = attendances.map((attendance, i) => {
    const calculateDuration = () => {
      if (attendance.checkIn && attendance.checkOut) {
        let diffInMins =
          (new Date(attendance.checkOut).getTime() -
            new Date(attendance.checkIn).getTime()) /
          1000 /
          60;
        let hours = diffInMins / 60;
        let fullHours = Math.floor(hours).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        let mins = (hours - fullHours) * 60;
        let fullMins = Math.round(mins).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        return `${fullHours}:${fullMins}`;
      } else {
        return "-";
      }
    };

    const attendanceLatitude = attendance?.location?.latitude;

    const attendanceLongitude = attendance?.location?.longitude;

    const checkInDistance = () => {
      if (
        !attendance.checkInLatitude ||
        !attendance.checkInLongitude ||
        !hasLocation ||
        !attendance.location
      ) {
        return "-";
      }

      return `${calcDistance(
        attendance?.checkInLatitude,
        attendanceLatitude,
        attendance?.checkInLongitude,
        attendanceLongitude
      )} ${t("kilometers")}`;
    };

    const checkOutDistance = () => {
      if (
        !attendance.checkOutLatitude ||
        !attendance.checkOutLongitude ||
        !hasLocation ||
        !attendance.location
      ) {
        return "-";
      }

      return `${calcDistance(
        attendance.checkOutLatitude,
        attendanceLatitude,
        attendance.checkOutLongitude,
        attendanceLongitude
      )} ${t("kilometers")}`;
    };

    const companyOwnerCheckInDistance = () => {
      if (
        !attendance.checkInLatitude ||
        !attendance.checkInLongitude ||
        !attendance.location
      ) {
        return "-";
      }

      return `${calcDistance(
        attendance.checkInLatitude,
        attendanceLatitude,
        attendance.checkInLongitude,
        attendanceLongitude
      )} ${t("kilometers")}`;
    };

    const companyOwnerCheckOutDistance = () => {
      if (
        !attendance.checkOutLatitude ||
        !attendance.checkOutLongitude ||
        !attendance.location
      ) {
        return "-";
      }

      return `${calcDistance(
        attendance.checkOutLatitude,
        attendanceLatitude,
        attendance.checkOutLongitude,
        attendanceLongitude
      )} ${t("kilometers")}`;
    };

    const getAttendanceCheckoutCell = () => {
      const hasNullValue = attendance.checkOut === null;
      if (hasNullValue) {
        if (companyOwner) {
          return (
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.common.blue,
                color: "white",
              }}
              onClick={() => {
                setAttendanceForModal({
                  ...attendance,
                  checkIn: moment(getCorrectTimezone(attendance.checkIn)),
                });
                setOpenModal(true);
              }}
            >
              {t("checkOut")}
            </Button>
          );
        }

        if (employeeDashboard) {
          return (
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.palette.common.blue,
                color: "white",
              }}
              onClick={() => {
                handlePendingCheckout(attendance.id);
              }}
            >
              {t("checkOut")}
            </Button>
          );
        }

        if (myAttendanceHistory) {
          return (
            <Button
              disabled
              style={{
                backgroundColor: "red",
                color: "white",
                textTransform: "none",
              }}
              variant="contained"
            >
              {t("pending")}
            </Button>
          );
        }
      } else {
        return moment(getCorrectTimezone(attendance.checkOut)).format("LLL");
      }
    };

    return (
      <>
        <CustomTableRow key={(attendance.id, +i)}>
          <CustomTableCell align={isEnglish ? "left" : "right"}>
            {moment(getCorrectTimezone(attendance.checkIn)).format("LLL")}
          </CustomTableCell>

          <CustomTableCell align={isEnglish ? "left" : "right"}>
            {getAttendanceCheckoutCell()}
          </CustomTableCell>

          <CustomTableCell>
            <Typography>
              {attendance.location
                ? isEnglish
                  ? attendance.location?.name
                  : attendance.location?.nameAr
                : "-"}
            </Typography>
          </CustomTableCell>

          {!companyOwner && (
            <>
              <CustomTableCell align={isEnglish ? "left" : "right"}>
                <Typography>{checkInDistance()}</Typography>
              </CustomTableCell>
              <CustomTableCell align={isEnglish ? "left" : "right"}>
                <Typography>{checkOutDistance()}</Typography>
              </CustomTableCell>
            </>
          )}

          {companyOwner && (
            <>
              <CustomTableCell align={isEnglish ? "left" : "right"}>
                <Typography>{companyOwnerCheckInDistance()}</Typography>
              </CustomTableCell>
              <CustomTableCell align={isEnglish ? "left" : "right"}>
                <Typography>{companyOwnerCheckOutDistance()}</Typography>
              </CustomTableCell>
            </>
          )}
          <CustomTableCell>{calculateDuration()}</CustomTableCell>
          {companyOwner && (
            <>
              <CustomTableCell>
                {attendance.image ? (
                  <Box clone height={"40px"} width={"40px"}>
                    <Avatar alt="employee-checkin" src={attendance.image} />
                  </Box>
                ) : (
                  <Typography>-</Typography>
                )}
              </CustomTableCell>
              <CustomTableCell>
                {attendance.imageCheckout ? (
                  <Box clone height={"40px"} width={"40px"}>
                    <Avatar
                      alt="employee-checkout"
                      src={attendance.imageCheckout}
                    />
                  </Box>
                ) : (
                  <Typography>-</Typography>
                )}
              </CustomTableCell>
            </>
          )}
        </CustomTableRow>
      </>
    );
  });

  const handleUpdateTimes = () => {
    const checkIn = revertTimeZone(attendanceForModal.checkIn);
    const checkOut = revertTimeZone(attendanceForModal.checkOut);
    updateEmployeeAttendance({
      ...attendanceForModal,
      id: attendanceForModal.id,
      employeeId: attendanceForModal.employee.id,
      checkIn: checkIn,
      checkOut: checkOut,
    });

    setOpenModal(false);
  };

  return (
    <>
      <Box width={"100%"} borderRadius={15} bgcolor="white">
        {companyOwner && (
          <Box
            display="flex"
            flexDirection="row"
            width={"100%"}
            alignItems="center"
            justifyContent="flex-start"
          >
            {icon}
            <Typography variant="h5" color="primary">
              {title}
            </Typography>
          </Box>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableRow>{headerTitles}</CustomTableRow>
            </TableHead>
            <TableBody>{employeeAttendancesList}</TableBody>
          </Table>
          {attendances.length === 0 && (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography color="primary" align="center">
                {t("noRecords")}
              </Typography>
            </Box>
          )}
        </TableContainer>

        {companyOwner && (
          <TablePagination
            count={attendances.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Box>
      <CompanyStructureModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        fadeIn={openModal}
        title={t("modifyCheckin/CheckoutTime")}
      >
        <Box height="auto" maxHeight="90vh" margin={4}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TimePickerField
                label={t("checkInTime")}
                name="checkIn"
                onChange={(e) =>
                  setAttendanceForModal({
                    ...attendanceForModal,
                    checkIn: moment(e),
                  })
                }
                value={attendanceForModal.checkIn}
              />
            </Grid>

            <Grid item md={6}>
              <TimePickerField
                label={t("checkOutTime")}
                name="checkOut"
                onChange={(e) =>
                  setAttendanceForModal({
                    ...attendanceForModal,
                    checkOut: moment(e),
                  })
                }
                value={attendanceForModal.checkOut}
              />
            </Grid>

            <Grid item container justifyContent="flex-end" md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateTimes}
                disabled={
                  !attendanceForModal.checkIn || !attendanceForModal.checkOut
                }
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};
const mapDispatchToProps = {
  updateEmployeeAttendance,
};

export default connect(null, mapDispatchToProps)(AttendanceTable);
