//Material UI
import { Box, Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Moment
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";

import { CompanyStructureModal, FormSelectInput } from "../../../../common";

const AddRequestModal = ({
  open,
  close,
  setOpenRequestModal,
  requestType,
  setRequestType,
  isOvertimeEnabled,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const [alert, setAlert] = useState("");

  let requestTypes = [
    { value: "LEAVE", name: "Leave", nameAr: "اجازة" },
    { value: "RESIGNATION", name: "Resignation", nameAr: "استقالة" },
    // { value: "EARLY_SALARY", name: "Early Salary", nameAr: "راتب مبكر" },
    { value: "OVERTIME", name: "Overtime", nameAr: "Overtime" },
    { value: "GENERAL", name: "General", nameAr: "عام" },
  ];

  requestTypes = isOvertimeEnabled
    ? requestTypes
    : requestTypes.filter((type) => type.value !== "OVERTIME");

  return (
    open && (
      <CompanyStructureModal
        open={open}
        onClose={close}
        fadeIn={open}
        title={t("addRequest")}
      >
        <Box height={"auto"} maxHeight={"90vh"} margin={4}>
          <Grid container alignItems="center">
            <Grid item md={12} xs={12}>
              {alert && <Alert severity="error">{alert}</Alert>}
              <FormSelectInput
                label={t("requestType")}
                name={"requestType"}
                onChange={(e) => {
                  console.log("e", e);
                  setRequestType(e);
                }}
                selectOptions={requestTypes.map((type) => ({
                  label: isEnglish ? type.name : type.nameAr,
                  value: type.value,
                }))}
                value={requestType}
              />
              <Grid container direction="row" justifyContent="flex-end">
                <Box padding={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setRequestType("");
                      close();
                    }}
                  >
                    {t("cancel")}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (requestType) {
                        // open leave request modal
                        setOpenRequestModal(true);
                        close();
                      } else {
                        // open business trip request modal
                        setAlert("Please select request type");
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CompanyStructureModal>
    )
  );
};
export default AddRequestModal;
