import { Box, Grid } from "@material-ui/core";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../../../../components/common";

import CreateModificationButton from "./CreateModificationButton";
import LeaveFile from "../../../../../../../components/common/LeaveFile";
import React from "react";
import UpdateModificationButton from "./UpdateModificationButton";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const ModificationModal = ({
  open,
  setOpen,
  modification,
  employeeId,
  setModification,
  isEarning,
}) => {
  const { t } = useTranslation(["common", "employeeProfile", "employeeSalary"]);

  const handleClose = () => {
    setModification({
      amount: 0.0,
      reason: "",
      month: new Date(),
      attachment: "",
      employeeId,
    });
    setOpen(false);
  };

  const title = isEarning ? t("common:addEarning") : t("common:addDeduction");

  return (
    <CompanyStructureModal
      open={open}
      onClose={() => setOpen(false)}
      fadeIn={open}
      title={title}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid
          item
          container
          spacing={3}
          justifyContent="center"
          style={{ margin: 4 }}
        >
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("employeeSalary:description")}
              value={modification.reason}
              onChange={(e) =>
                setModification({ ...modification, reason: e.target.value })
              }
              id="reason"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("employeeSalary:amount")}
              type="number"
              value={
                isEarning ? modification.amount : -Math.abs(modification.amount)
              }
              onChange={(e) =>
                setModification({ ...modification, amount: e.target.value })
              }
              id="amount"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <DatePickerField
              label={t("employeeSalary:month")}
              value={new Date(modification.month)}
              onChange={(e) =>
                setModification({
                  ...modification,
                  month: moment(e).format("yyyy-MM-DD"),
                })
              }
              disableToolbar={false}
            />
          </Grid>
          <Grid item md={12}>
            <LeaveFile
              value={modification.attachment}
              setValue={(newFile) =>
                setModification({ ...modification, attachment: newFile })
              }
              removeFileState={() =>
                setModification({ ...modification, attachment: "" })
              }
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} style={{ margin: 10 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width={"100%"}
          >
            {modification.id ? (
              <UpdateModificationButton
                modification={modification}
                isEarning={isEarning}
                handleClose={handleClose}
              />
            ) : (
              <CreateModificationButton
                modification={modification}
                isEarning={isEarning}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};

export default ModificationModal;
