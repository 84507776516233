import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { CancelOutlined, InsertDriveFile } from "@material-ui/icons";

import React from "react";
import { useTranslation } from "react-i18next";
import DetailsItem from "./DetailsItem";

const FilesList = ({ files, setFiles, editable }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const getPlaceHolder = (file) => {
    if (file.file) {
      if (isEnglish && typeof file.file === "string") {
        return file.file.split("/").pop();
      } else if (file.file.name) {
        return file.file.name;
      } else {
        return " ";
      }
    }
  };

  const data =
    files.length > 0
      ? files
          .filter((_file) => _file.file)
          .map((file, idx) => {
            return (
              <ListItem style={{ display: "list-item" }} key={idx}>
                <ListItemText
                  primary={
                    <a
                      style={{ display: "hidden" }}
                      href={file.file}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <Typography
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        color="primary"
                        variant="subtitle2"
                        align={isEnglish ? "left" : "right"}
                      >
                        {getPlaceHolder(file)}
                      </Typography>
                    </a>
                  }
                />
                {editable && (
                  <ListItemSecondaryAction
                    onClick={() => {
                      let fileId = file.id ? "id" : "uuid";
                      let modifiedFiles = files.filter(
                        (prevState) => prevState[fileId] !== file[fileId],
                      );
                      setFiles(modifiedFiles);
                    }}
                  >
                    <CancelOutlined color="primary" />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })
      : null;
  return (
    <>
      <DetailsItem label={t("common:files")} icon={InsertDriveFile} />
      <List
        disablepadding
        style={{
          listStyleType: "disc",
          paddingLeft: isEnglish && 80,
          paddingRight: !isEnglish && 80,
        }}
      >
        {data}
      </List>
    </>
  );
};

export default FilesList;
