import { DatePickerField } from "../../../../../components/common";
import { Grid } from "@material-ui/core";
import OvertimeCredits from "./OvertimeCredits";
import OvertimeRequests from "./OvertimeRequests";
import React from "react";
import moment from "moment/moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Overtime = ({ employee }) => {
  const { t } = useTranslation("common");

  const [month, setMonth] = useState(moment(new Date()).format("yyyy-MM"));

  const handleMonthChange = (e) => {
    setMonth(moment(e).format("yyyy-MM"));
  };

  return (
    <Grid container spacing={4}>
      <Grid item container justifyContent="flex-end" lg={12} xs={12}>
        <Grid item md={4} xs={12}>
          <DatePickerField
            disableToolbar={false}
            yearMonthView
            openTo="month"
            onChange={(e) => handleMonthChange(e)}
            value={month}
            label={t("common:month")}
          />
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <OvertimeRequests employee={employee} month={month} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <OvertimeCredits employee={employee} month={month} />
      </Grid>
    </Grid>
  );
};

export default Overtime;
