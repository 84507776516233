import {
  TableCell,
  TableHead,
  TableSortLabel,
  makeStyles,
} from "@material-ui/core";

import { CustomTableRow } from "../../../../components/common";
import { useTranslation } from "react-i18next";

const EnhancedTableCell = ({
  headCell,
  orderBy,
  setOrderBy,
  order,
  createSortHandler,
  classes,
}) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  return (
    <TableCell
      key={headCell.id}
      sortDirection={orderBy === headCell.id ? order : false}
      style={{
        whiteSpace: "nowrap",
        borderBottom: "none",
      }}
      align={isEnglish ? "left" : "right"}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : "asc"}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export const EnhancedTableHead = ({
  labels,
  order,
  orderBy,
  onRequestSort,
  setOrderBy,
}) => {
  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    onRequestSort(event, property);
  };

  const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();

  return (
    <TableHead>
      <CustomTableRow>
        {labels.map((headCell, i) => (
          <EnhancedTableCell
            headCell={headCell}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onRequestSort={onRequestSort}
            order={order}
            createSortHandler={createSortHandler}
            classes={classes}
            key={i}
          />
        ))}
      </CustomTableRow>
    </TableHead>
  );
};
