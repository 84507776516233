//Material UI
import { Box, Button, Grid, Modal, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Send } from "@material-ui/icons/";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

//Redux
import { createAnnouncement } from "../../redux/actions";
//Styles
import useStyles from "../pages/NewAnnouncements/styles";
//Components
//Data & Utils
import { filterEmployees } from "../pages/NewAnnouncements/utils";
import AnnouncementInput from "./AnnouncementInput";
import AutocompleteInput from "./AutocompleteInput";
import MultilineInput from "./MultilineInput";

//Components

//History

const ExpiringDocumentsModal = ({
  departments,
  positions,
  employees,
  resources,
  request,
  openModal,
  setOpenModal,
  isEnglish,
  createAnnouncement,
  t,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const [position, setPosition] = useState([]);
  const [_resources, setResources] = useState([]);

  const [title, setTitle] = useState(request ? request.documentName : "");
  const [message, setMessage] = useState("");
  const [_employees, setEmployees] = useState([]);

  const [department, setDepartment] = useState([]);
  useEffect(() => {
    if (request) {
      setTitle(request.documentName);
      let foundDepartment = departments.find((dep) =>
        isEnglish
          ? dep.name === request.department
          : dep.nameAr === request.department
      );
      if (foundDepartment) {
        setDepartment([
          {
            label: isEnglish ? foundDepartment.name : foundDepartment.nameAr,
            value: foundDepartment.id,
          },
        ]);
      }
      let foundPosition = positions.find((pos) =>
        isEnglish
          ? pos.name === request.position
          : pos.nameAr === request.position
      );
      if (foundPosition) {
        setPosition([
          {
            label: isEnglish ? foundPosition.name : foundPosition.nameAr,
            value: foundPosition.id,
          },
        ]);
      }
      let foundEmployee = employees.find(
        (emp) => emp.id === request.employeeId
      );
      if (foundEmployee) {
        setEmployees([
          {
            label: foundEmployee.user
              ? foundEmployee.user?.fullName
              : foundEmployee?.fullName,
            value: foundEmployee.id,
          },
        ]);
      }
    }
  }, [departments, positions, employees, request, isEnglish]);

  if (!request) return <></>;

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id)
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ])
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const employeesList = filterEmployees(employees, department, position);

  const handleSubmit = () => {
    if (title !== "" && message !== "") {
      let announcement = {
        title: title,
        titleAr: title,
        postDate: new Date(),
        body: message,
        bodyAr: message,
        resources: _resources.map((res) => res.value),
      };
      if (_employees.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            employees: _employees.map((emp) => emp.value),
          },
          () => {
            setOpenModal(false);
            history.push("/announcements");
          }
        );
      } else if (position.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            positions: position.map((pos) => pos.value),
          },
          () => {
            setOpenModal(false);
            history.push("/announcements");
          }
        );
      } else if (department.length !== 0) {
        createAnnouncement(
          {
            ...announcement,
            departments: department.map((dep) => dep.value),
          },
          () => {
            setOpenModal(false);
            history.push("/announcements");
          }
        );
      }
    }
  };
  return (
    <Box>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Grid container>
          <Box
            width={matchesXS ? "85%" : "60%"}
            height={matchesXS ? "85%" : "80%"}
            top={matchesXS ? "5%" : "10%"}
            left={matchesXS ? "5%" : "20%"}
            bgcolor="white"
            position="absolute"
            borderRadius={10}
            overflow="scroll"
          >
            <Box clone padding={6}>
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <AnnouncementInput
                    disabled
                    label={t("common:documentExpiring")}
                    value={title}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setTitle(e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <MultilineInput
                    label={t("common:announcement")}
                    placeholder={t("common:enterAnnouncement")}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={department}
                    options={departments.map((dep) => ({
                      label: isEnglish ? dep.name : dep.nameAr,
                      value: dep.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setDepartment(e)}
                    label={t("common:department")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    multiple={true}
                    value={position}
                    options={_positions().map((pos) => ({
                      label: isEnglish ? pos.name : pos.nameAr,
                      value: pos.id,
                    }))}
                    onChange={(e) => setPosition(e)}
                    label={t("common:position")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={_employees}
                    options={employeesList.map((emp) => ({
                      label: emp.user ? emp.user?.fullName : emp?.fullName,
                      value: emp.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setEmployees(e)}
                    label={t("common:employees")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={_resources}
                    options={resources.map((res) => ({
                      label: isEnglish ? res.name : res.nameAr,
                      value: res.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setResources(e)}
                    label={t("common:resources")}
                  />
                </Grid>

                <Grid item md={12} sm={12}>
                  <Box
                    clone
                    paddingTop={
                      matchesXS
                        ? 10
                        : matchesSM
                        ? 8
                        : matchesMD
                        ? 0
                        : matchesLG
                        ? 0
                        : 15
                    }
                    paddingLeft={matchesXS ? 5 : undefined}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isEnglish ? "flex-end" : "flex-start"}
                      spacing={2}
                    >
                      {isEnglish && (
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={() => setOpenModal(false)}
                          >
                            {t("common:cancel")}
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          className={classes.sendButton}
                          startIcon={
                            !isEnglish && (
                              <Send
                                style={{
                                  transform: "scaleX(-1)",
                                }}
                              />
                            )
                          }
                          endIcon={isEnglish && <Send />}
                        >
                          {t("common:send")}
                        </Button>
                      </Grid>
                      {!isEnglish && (
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={() => setOpenModal(false)}
                          >
                            {t("common:cancel")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({ company, employees }) => ({
  resources: company.resources,
  departments: company.departments,
  employees: employees.employees,
  positions: company.positions,
});

const mapDispatchToProps = {
  createAnnouncement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpiringDocumentsModal);
