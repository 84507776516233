import { Button, Grid } from "@material-ui/core";
import {
  createEmployeeDocumentFiles,
  createEmployeeDocuments,
  deleteEmployeeDocumentFile,
  updateEmployeeDocument,
  updateEmployeeDocumentFile,
} from "../../../../../../redux/actions";

import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const EmployeeProfileDocumentsButtons = ({
  createEmployeeDocumentFiles,
  createEmployeeDocuments,
  deleteEmployeeDocumentFile,
  updateEmployeeDocument,
  updateEmployeeDocumentFile,

  documentState,
  setDocumentState,
  setOpen,
  modifiedDocument,
  setModifiedDocument,
  modifiedFiles,
  setModifiedFiles,
}) => {
  const { t } = useTranslation("common");

  const { employeeId } = useParams();

  const handleCloseModal = () => {
    if (documentState !== null) {
      setOpen(false);
      setDocumentState(null);
    } else {
      setOpen(false);
      setModifiedDocument({
        name: "",
        referenceNumber: "",
        issueDate: null,
        exp: null,
        type: {
          id: "",
        },
      });
      setModifiedFiles([]);
    }
  };

  const onSubmit = () => {
    if (documentState !== null) {
      const { type, employee, __typename, ...rest } = modifiedDocument;

      updateEmployeeDocument({
        ...rest,
        typeId: Number(type?.id),
        employeeId: employeeId,
      });

      const modifiedFilesIds = modifiedFiles
        .filter((file) => file.id)
        .map((file) => file.id);

      const filesToRemove = documentState.files.filter(
        (file) => !modifiedFilesIds.includes(file.id)
      );

      for (const existingFile of filesToRemove) {
        const { id } = existingFile;
        deleteEmployeeDocumentFile({
          id,
          employeeId,
        });
      }

      const filesToAdd = modifiedFiles.filter((file) => file.uuid);

      for (const newFile of filesToAdd) {
        const { file, documentId } = newFile;
        createEmployeeDocumentFiles([{ file, documentId, employeeId }]);
      }
    } else {
      const { name, exp, referenceNumber, issueDate, type } = modifiedDocument;
      const filesToAdd = modifiedFiles
        .filter((file) => file.uuid)
        .map((file) => file.file);

      createEmployeeDocuments([
        {
          name,
          exp,
          referenceNumber,
          issueDate,
          typeId: +type.id,
          employeeId,
          filesToAdd,
        },
      ]);
    }

    handleCloseModal();
  };

  return (
    <Grid item container direction="row-reverse" xs={12} lg={12}>
      <Grid item container justifyContent="flex-end" xs={6} lg={2}>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("common:confirm")}
        </Button>
      </Grid>
      <Grid item container alignItems="flex-end" xs={6} lg={2}>
        <Button variant="outlined" onClick={handleCloseModal}>
          {t("common:cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  updateEmployeeDocument,
  updateEmployeeDocumentFile,
  deleteEmployeeDocumentFile,
  createEmployeeDocumentFiles,
  createEmployeeDocuments,
};

export default connect(
  null,
  mapDispatchToProps
)(EmployeeProfileDocumentsButtons);
