import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  CompanyStructureModal,
  FormInputField,
} from "../../../../../../components/common";
import {
  createLocation,
  updateLocation,
} from "../../../../../../redux/actions";

const LocationsModal = ({
  location,
  updateLocation,
  createLocation,
  open,
  setOpen,
  modifiedLocation,
  setModifiedLocation,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";

  return (
    <CompanyStructureModal
      open={open}
      onClose={() => {
        setOpen(false);
        setModifiedLocation({
          name: "",
          nameAr: "",
          latitude: "",
          longitude: "",
        });
      }}
      fadeIn={open}
      title={t("companyProfile:addLocation")}
    >
      <Grid container spacing={3}>
        <Grid item container md={12} spacing={3} style={{ margin: 10 }}>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={isEnglish}
              label={t("common:name")}
              value={modifiedLocation.name}
              onChange={(e) =>
                setModifiedLocation({
                  ...modifiedLocation,
                  name: e.target.value,
                })
              }
              id="name"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={!isEnglish}
              label={t("common:arabicName")}
              value={modifiedLocation.nameAr}
              onChange={(e) =>
                setModifiedLocation({
                  ...modifiedLocation,
                  nameAr: e.target.value,
                })
              }
              id="arabicName"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={!isEnglish}
              label={t("common:latitude")}
              value={modifiedLocation.latitude}
              onChange={(e) =>
                setModifiedLocation({
                  ...modifiedLocation,
                  latitude: e.target.value,
                })
              }
              id="arabicName"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <FormInputField
              isRequired={!isEnglish}
              label={t("common:longitude")}
              value={modifiedLocation.longitude}
              onChange={(e) =>
                setModifiedLocation({
                  ...modifiedLocation,
                  longitude: e.target.value,
                })
              }
              id="arabicName"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>

        <Box
          clone
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          paddingX={2}
          paddingY={2}
        >
          <Grid item md={12} sm={12} xs={11} style={{ margin: 10 }}>
            <Button
              disableRipple
              variant="contained"
              onClick={
                location?.id
                  ? () => {
                      updateLocation(
                        {
                          ...modifiedLocation,
                          locationId: location.id,
                        },
                        setOpen
                      );
                      setOpen(false);
                    }
                  : () => {
                      createLocation(modifiedLocation, setOpen);
                      setOpen(false);
                    }
              }
              endIcon={location ? <Edit /> : <Add />}
            >
              <Typography>
                {location ? t("common:save") : t("common:add")}
              </Typography>
            </Button>
          </Grid>
        </Box>
      </Grid>
    </CompanyStructureModal>
  );
};

const mapDispatchToProps = {
  createLocation,
  updateLocation,
};

export default connect(null, mapDispatchToProps)(LocationsModal);
