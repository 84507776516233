import { Box, Grid, Typography } from "@material-ui/core";

import { Link } from "react-router-dom";
import React from "react";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";

const Position = ({
  position,
  departmentId,
  employeeAvatars,
  description,
  action,
}) => {
  const classes = useStyles();
  const {
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  return (
    <Box width={"100%"} paddingY={1}>
      <Grid container alignItems="space-between">
        <Grid item lg={1} xs={1}></Grid>
        <Grid item lg={4} xs={4} style={{ paddingLeft: 20 }}>
          <Typography
            component={Link}
            color="primary"
            variant="subtitle2"
            to={`/employeesList?department=${departmentId}&location=&status=&order=null&orderBy=null&position=${position.id}`}
            className={classes.menuButton}
          >
            {isEnglish ? position.name : position.nameAr}
          </Typography>
        </Grid>

        <Grid item lg={5} xs={5}>
          <Typography variant="subtitle2" color="primary">
            {description}
          </Typography>
        </Grid>
        <Grid item container direction="row-reverse" lg={2} xs={2}>
          {action}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Position;
