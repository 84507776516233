import { Grid, List, ListItem, Typography } from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const ResourceDetail = ({ resource }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("announcement");
  const isEnglish = language === "en";

  let description = isEnglish ? resource.description : resource.descriptionAr;
  description = description
    .split("/n")
    .map((line) => <Typography key={line}>{line}</Typography>);

  const links = resource.links.map((link) => (
    <ListItem key={link.link}>
      <a href={link.link} target="_blank" rel="noreferrer">
        {isEnglish
          ? link.description
            ? link.description
            : link.link
          : link.descriptionAr
          ? link.descriptionAr
          : link.link}
      </a>
    </ListItem>
  ));

  const files = resource.files.map((file) => (
    <ListItem key={file.file}>
      <a href={file.file} download target="_blank" rel="noreferrer">
        {isEnglish
          ? file.description
            ? file.description
            : file.file
          : file.descriptionAr
          ? file.descriptionAr
          : file.file}
      </a>
    </ListItem>
  ));

  return (
    <>
      <Grid item container spacing={2} lg={10} justifyContent="center">
        <Grid item lg={10} xs={10}>
          {description}
        </Grid>
        {files.length !== 0 ||
          (links.length !== 0 && (
            <Grid item lg={10} xs={10}>
              <Typography variant="h3" color="primary">
                {t("attachments")}
              </Typography>
            </Grid>
          ))}
        {files.length !== 0 && (
          <>
            <Grid item lg={8} xs={10}>
              <Typography variant="h4" color="secondary">
                {t("files")}
              </Typography>
            </Grid>
            <Grid item lg={7} xs={10}>
              <List>{files}</List>
            </Grid>
          </>
        )}
        {links.length !== 0 && (
          <>
            <Grid item lg={8} xs={10}>
              <Typography variant="h4" color="secondary">
                {t("links")}
              </Typography>
            </Grid>
            <Grid item lg={7} xs={10}>
              <List>{links}</List>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ResourceDetail;
