import moment from "moment";

export const normalizeProfileRequest = (request) => {
  return {
    id: request.id,
    name: request.employee.user
      ? request.employee.user?.fullName
      : request.employee?.fullName,
    lastModified: moment(new Date(request.lastModified)).format(
      "DD-MM-yyyy hh:mm a"
    ),
    lastModifiedBy: request.lastModifiedBy?.fullName,
  };
};
