import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

import AutoCheckout from "./AutoCheckout";
import CameraCheckIn from "./CameraCheckIn";
import CheckinDetails from "./CheckinDetails";
import CheckinLimits from "./CheckinLimits";
import CheckinDistanceLimit from "./CheckinDistanceLimit";
import React from "react";
import { useTranslation } from "react-i18next";

const CheckinSettings = ({ edit, company }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Card>
        <CardHeader title={t("checkInSettings")} />
        <CardContent>
          <Grid container spacing={3}>
            <CameraCheckIn company={company} />
            <CheckinDetails company={company} />
            <CheckinLimits company={company} />
            <AutoCheckout company={company} />
            <CheckinDistanceLimit company={company} />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CheckinSettings;
