// Action Types
import {
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_DOCUMENT,
  ADD_EMPLOYEE_DOCUMENT_FILE,
  ADD_EMPLOYEE_LOADING,
  ADD_REQUEST,
  ADD_REQUEST_LOADING,
  CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN,
  DELETE_EMPLOYEE_DOCUMENT,
  DELETE_EMPLOYEE_DOCUMENT_FILE,
  EDIT_ANNOUNCEMENT,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_ATTENDANCES,
  EDIT_EMPLOYEE_DOCUMENT,
  EDIT_EMPLOYEE_DOCUMENT_FILE,
  EMPLOYEE_ACTIVE_ATTENDANCE,
  EMPLOYEE_ADD_EMPLOYEE_DOCUMENT,
  EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT,
  EMPLOYEE_LEAVE_SALARY,
  EMPLOYEE_PROFILE,
  GET_ERRORS,
  MY_ATTENDANCES,
  UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE,
} from "./types";
//Mutations
import {
  CREATE_ANONYMOUS_EMPLOYEE_ATTENDANCE,
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_ATTENDANCE,
  CREATE_LEAVE_REQUEST,
  CREATE_OVERTIME_REQUEST,
  CREATE_PROFILE_UPDATE_REQUEST,
  CREATE_RECURRING_SALARY_MODIFICATIONS,
  CREATE_RESIGNATION_REQUEST,
  EMPLOYEE_DOCUMENT_DELETE,
  EMPLOYEE_DOCUMENT_FILE_DELETE,
  EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT,
  EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANONYMOUS_EMPLOYEE_ATTENDANCE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_ATTENDANCE,
  CREATE_GENERAL_REQUEST,
} from "./Mutations";
//Queries
import {
  EMPLOYEE_ACTIVE_ATTENDANCE_QUERY,
  EMPLOYEE_LEAVE_SALARY_QUERY,
  EMPLOYEE_PROFILE_QUERY,
  MY_ATTENDANCES_QUERY,
} from "./Queries";

// Graphql
import client from "./client";
import { fetchEmployees } from "./employees";

export const fetchEmployeeProfile = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEE_PROFILE_QUERY,
    });

    const employee = res.data.employee;
    dispatch({ type: EMPLOYEE_PROFILE, payload: employee });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createEmployeeLoading = (status) => async (dispatch) => {
  dispatch({ type: ADD_EMPLOYEE_LOADING, payload: status });
};

export const createEmployee =
  (
    {
      civilId,
      employee,
      passport,
      files: { civilId: civilIdFile, passport: passportFile },
      recurringSalaryModifications,
    },
    history,
  ) =>
  async (dispatch) => {
    dispatch(createEmployeeLoading(true));
    try {
      let res = await client.mutate({
        mutation: CREATE_EMPLOYEE,
        variables: employee,
      });
      const newEmployee = res.data.createEmployee.employee;
      const employeeId = newEmployee.id;

      if (recurringSalaryModifications.length) {
        await client.mutate({
          mutation: CREATE_RECURRING_SALARY_MODIFICATIONS,
          variables: { recurringSalaryModifications, employeeId },
        });
      }

      res = await client.mutate({
        mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT,
        variables: {
          ...civilId,
          name: "Civil ID",
          typeId: Number(civilId.documentType),
          employeeId,
        },
      });
      if (civilIdFile) {
        const documentId = res.data.employeeUpdateOrCreateDocument.document.id;
        await client.mutate({
          mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE,
          variables: { file: civilIdFile, employeeId, documentId },
        });
      }

      res = await client.mutate({
        mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT,
        variables: {
          ...passport,
          name: "Passport",
          typeId: Number(passport.documentType),
          employeeId,
        },
      });
      if (passportFile) {
        const documentId = res.data.employeeUpdateOrCreateDocument.document.id;
        await client.mutate({
          mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE,
          variables: { file: passportFile, employeeId, documentId },
        });
      }

      dispatch({ type: ADD_EMPLOYEE, payload: newEmployee });
      history.push(`/employees/${employeeId}`);
    } catch (error) {
      dispatch(createEmployeeLoading(false));
      if (error.graphQLErrors.length) {
        let object = {};
        error.graphQLErrors.forEach(
          (error, idx) => (object = { ...object, [idx]: error.message }),
        );
        dispatch({
          type: GET_ERRORS,
          errors: object,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateEmployee = (employee, history) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: UPDATE_EMPLOYEE,
      variables: employee,
    });
    const updatedEmployee = res.data.updateEmployee.employee;

    dispatch({ type: EDIT_EMPLOYEE, payload: updatedEmployee });

    dispatch(fetchEmployees());

    if (history) history.push(`/employees/${employee.id}`);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateAnnouncement = (announcement) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: UPDATE_ANNOUNCEMENT,
      variables: announcement,
    });
    const updatedAnnouncement =
      res.data.updateAnnouncementReadReceipt.announcement;

    dispatch({ type: EDIT_ANNOUNCEMENT, payload: updatedAnnouncement });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createEmployeeDocuments =
  (documents, employee = false) =>
  (dispatch) => {
    documents.forEach(async (document) => {
      const { filesToAdd, ...rest } = document;
      const data = { ...rest };

      try {
        const res = await client.mutate({
          mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT,
          variables: data,
        });
        const resDocument = res.data.employeeUpdateOrCreateDocument.document;
        for (const file of filesToAdd) {
          dispatch(
            createEmployeeDocumentFiles([
              {
                file,
                documentId: +resDocument.id,
                employeeId: data.employeeId,
              },
            ]),
          );
        }
        if (employee)
          dispatch({
            type: EMPLOYEE_ADD_EMPLOYEE_DOCUMENT,
            payload: resDocument,
          });
        else dispatch({ type: ADD_EMPLOYEE_DOCUMENT, payload: resDocument });
      } catch (error) {
        if (error.response) {
          dispatch({
            type: GET_ERRORS,
            errors: error.response.data,
          });
        } else {
          console.error(error);
        }
      }
    });
  };

export const updateEmployeeDocument =
  (document, employee = false) =>
  async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT,
        variables: document,
      });

      const resDocument = res.data.employeeUpdateOrCreateDocument.document;
      if (employee)
        dispatch({
          type: EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT,
          payload: resDocument,
        });
      else dispatch({ type: EDIT_EMPLOYEE_DOCUMENT, payload: resDocument });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteEmployeeDocument = (document) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: EMPLOYEE_DOCUMENT_DELETE,
      variables: document,
    });
    const status = res.data.employeeDeleteDocument.status;
    if (status) dispatch({ type: DELETE_EMPLOYEE_DOCUMENT, payload: document });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createEmployeeDocumentFiles = (files) => (dispatch) => {
  files.forEach(async (file) => {
    try {
      const res = await client.mutate({
        mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE,
        variables: file,
      });

      const documentFile =
        res.data.employeeUpdateOrCreateDocumentFile.documentFile;
      dispatch({ type: ADD_EMPLOYEE_DOCUMENT_FILE, payload: documentFile });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  });
};

export const updateEmployeeDocumentFile = (file) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: EMPLOYEE_UPDATE_OR_CREATE_DOCUMENT_FILE,
      variables: file,
    });

    const documentFile =
      res.data.employeeUpdateOrCreateDocumentFile.documentFile;
    dispatch({ type: EDIT_EMPLOYEE_DOCUMENT_FILE, payload: documentFile });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteEmployeeDocumentFile = (file) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: EMPLOYEE_DOCUMENT_FILE_DELETE,
      variables: file,
    });

    const status = res.data.employeeDeleteDocumentFile.status;
    if (status)
      dispatch({
        type: DELETE_EMPLOYEE_DOCUMENT_FILE,
        payload: file,
      });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const addRequestLoading = (status) => (dispatch) => {
  dispatch({ type: ADD_REQUEST_LOADING, payload: status });
};

export const createLeaveRequest =
  (leaveData, closeModal) => async (dispatch) => {
    dispatch(addRequestLoading(true));
    try {
      let res = await client.mutate({
        mutation: CREATE_LEAVE_REQUEST,
        variables: leaveData,
      });
      const leaveRequest = res.data.createLeaveRequest.leaveRequest;

      dispatch({ type: ADD_REQUEST, payload: leaveRequest });
      if (closeModal) closeModal();
    } catch (error) {
      dispatch(addRequestLoading(false));
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const createProfileUpdateRequest =
  (updatedData, history) => async (dispatch) => {
    try {
      let res = await client.mutate({
        mutation: CREATE_PROFILE_UPDATE_REQUEST,
        variables: updatedData,
      });
      const updateRequest =
        res.data.createProfileUpdateRequest.updateProfileRequest;

      dispatch({ type: ADD_REQUEST, payload: updateRequest });
      if (history) history.push(`/request/${updateRequest.request.id}`);
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const createResignationRequest = (data, history) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: CREATE_RESIGNATION_REQUEST,
      variables: data,
    });
    const resignationRequest =
      res.data.createResignationRequest.resignationRequest;

    dispatch({ type: ADD_REQUEST, payload: resignationRequest });
    if (history) history.push(`/request/${resignationRequest.request.id}`);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createGeneralRequest = (data) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: CREATE_GENERAL_REQUEST,
      variables: data,
    });
    const generalRequest = res.data.createGeneralRequest.generalRequest;

    dispatch({ type: ADD_REQUEST, payload: generalRequest });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchMyAttendances = (data) => async (dispatch) => {
  try {
    let res = await client.query({
      query: MY_ATTENDANCES_QUERY,
      variables: data,
    });

    let myAttendance = res.data.employeeAttendances;

    dispatch({
      type: MY_ATTENDANCES,
      payload: myAttendance,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeActiveAttendance =
  (employeeId) => async (dispatch) => {
    try {
      let res = await client.query({
        query: EMPLOYEE_ACTIVE_ATTENDANCE_QUERY,
        variables: employeeId,
      });

      let employeeActiveAttendance = res.data.employeeActiveAttendance;

      dispatch({
        type: EMPLOYEE_ACTIVE_ATTENDANCE,
        payload: employeeActiveAttendance,
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchEmployeeLeaveSalary = (leaveData) => async (dispatch) => {
  try {
    let res = await client.query({
      query: EMPLOYEE_LEAVE_SALARY_QUERY,
      variables: leaveData,
    });

    const leaveSalary = res.data.employeeLeaveSalary;
    dispatch({ type: EMPLOYEE_LEAVE_SALARY, payload: leaveSalary });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateEmployeeAttendance =
  ({ id, latitude, longitude, employeeId, checkIn, checkOut }) =>
  async (dispatch) => {
    try {
      let res = await client.mutate({
        mutation: UPDATE_EMPLOYEE_ATTENDANCE,
        variables: { id, employeeId, checkIn, checkOut, latitude, longitude },
      });

      let updatedAttendance = res.data.employeeUpdateAttendance;

      dispatch({
        type: UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE,
        payload: updatedAttendance.attendance,
      });

      dispatch({
        type: EDIT_EMPLOYEE_ATTENDANCES,
        payload: updatedAttendance.attendance,
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const createEmployeeAttendance =
  ({ employeeId, latitude, longitude, locationId }) =>
  async (dispatch) => {
    try {
      let res = await client.mutate({
        mutation: CREATE_EMPLOYEE_ATTENDANCE,
        variables: { employeeId, latitude, longitude, locationId },
      });

      let checkedIn = res.data.employeeCreateAttendance;

      dispatch({
        type: CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN,
        payload: checkedIn,
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const createAnonymousEmployeeAttendance =
  (data, setSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: CREATE_ANONYMOUS_EMPLOYEE_ATTENDANCE,
        variables: data,
      });
      if (setSuccess) setSuccess();
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        errors: error.graphQLErrors.map((error) => error.message),
      });
    }
  };

export const updateAnonymousEmployeeAttendance =
  (data, setSuccess) => async (dispatch) => {
    try {
      await client.mutate({
        mutation: UPDATE_ANONYMOUS_EMPLOYEE_ATTENDANCE,
        variables: data,
      });

      if (setSuccess) setSuccess();
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        errors: error.graphQLErrors.map((error) => error.message),
      });
    }
  };

export const createOvertimeRequest = (data, closeModal) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: CREATE_OVERTIME_REQUEST,
      variables: data,
    });
    const overtimeRequest = res.data.createOvertimeRequest.overtimeRequest;

    dispatch({ type: ADD_REQUEST, payload: overtimeRequest });
    if (closeModal) closeModal();
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
