import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const SalaryLabels = ({
  employee,
  totalEarnings,
  totalDeductions,
  nextDeposit,
}) => {
  const { t } = useTranslation(["employeeSalary"]);
  return (
    <>
      <Grid item lg={6} xs={6}>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          color="textSecondary"
        >
          {t("employeeSalary:basicSalary")}
        </Typography>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold" }}
          color="textSecondary"
        >
          {t("common:netSalary")}
        </Typography>
      </Grid>
      <Grid item lg={6} xs={6}>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", paddingLeft: 10 }}
          color="textPrimary"
        >
          {employee.baseSalary}
        </Typography>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", paddingLeft: 10 }}
          color="textPrimary"
        >
          {Number(nextDeposit.totalNetSalary).toFixed(3)}
        </Typography>
      </Grid>
    </>
  );
};

export default SalaryLabels;
