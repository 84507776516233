//MaterialUI
import { Box, Grid, Typography, FormControlLabel, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";

//Components
import CompanyField from "../CompanyField";
import { FormInputField } from "../../../common";
import { connect } from "react-redux";
//Actions
import { updateCompanyVacation } from "../../../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

const LeaveDetails = ({ edit, vacation, updateCompanyVacation }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [vacationState, setVacation] = useState(vacation ? vacation : null);

  const [wholeBalance, setWholeBalance] = useState(vacation ? vacation.whole_balance : false);

  const handleSwitchChange = () => {
    setWholeBalance(!wholeBalance)
  }

  useEffect(() => {
    setVacation(vacation ? vacation : null);
  }, [vacation]);

  if (!vacation) return <></>;

  return (
    <>
      <Typography variant="h2" color="textPrimary">
        {t("companyProfile:leaveDetails")}
      </Typography>

      <Box clone paddingX={2} paddingTop={6}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <CompanyField>
              <FormInputField
                isRequired
                label={t("companyProfile:annualLeave")}
                name="annualLeave"
                disabled={!edit}
                onChange={(e) => {
                  setVacation({
                    ...vacationState,
                    annualLeave: e.target.value,
                  });
                  updateCompanyVacation({
                    ...vacationState,
                    annualLeave: e.target.value,
                  });
                }}
                value={vacationState?.annualLeave}
                helperText={t("companyProfile:annualLeaveHelperText")}
                id="annualLeave"
                autoComplete="new-password"
              />
            </CompanyField>
            <CompanyField>
              <FormInputField
                isRequired
                label={t("companyProfile:accumulatedFor")}
                name="accumulatedFor"
                disabled={!edit}
                onChange={(e) => {
                  setVacation({
                    ...vacationState,
                    accumulatedFor: e.target.value,
                  });
                  updateCompanyVacation({
                    ...vacationState,
                    accumulatedFor: e.target.value,
                  });
                }}
                value={vacationState?.accumulatedFor}
                helperText={t("companyProfile:accumulatedForHelperText")}
                id="accumulatedFor"
                autoComplete="new-password"
              />
            </CompanyField>
            <CompanyField>
              <FormInputField
                isRequired
                label={t("companyProfile:sickLeave")}
                name="sickLeave"
                disabled={!edit}
                onChange={(e) => {
                  setVacation({
                    ...vacationState,
                    sickLeave: e.target.value,
                  });
                  updateCompanyVacation({
                    ...vacationState,
                    sickLeave: e.target.value,
                  });
                }}
                value={vacationState?.sickLeave}
                helperText={t("companyProfile:sickLeaveHelperText")}
                id="sickLeave"
                autoComplete="new-password"
              />
            </CompanyField>
            <CompanyField>
              <FormInputField
                isRequired
                label={t("companyProfile:casualLeave")}
                name="casualLeave"
                disabled={!edit}
                onChange={(e) => {
                  setVacation({
                    ...vacationState,
                    casualLeave: e.target.value,
                  });
                  updateCompanyVacation({
                    ...vacationState,
                    casualLeave: e.target.value,
                  });
                }}
                value={vacationState?.casualLeave}
                helperText={t("companyProfile:casualLeaveHelperText")}
                id="casualLeave"
                autoComplete="new-password"
              />
            </CompanyField>
            <CompanyField>
              <FormInputField
                isRequired
                label={t("companyProfile:shortLeave")}
                name="shortLeave"
                disabled={!edit}
                onChange={(e) => {
                  setVacation({
                    ...vacationState,
                    shortLeave: e.target.value,
                  });
                  updateCompanyVacation({
                    ...vacationState,
                    shortLeave: e.target.value,
                  });
                }}
                value={vacationState.shortLeave}
                helperText={t("companyProfile:shortLeaveHelperText")}
                id="shortLeave"
                autoComplete="shortLeave"
              />
            </CompanyField>
            <Grid item md={12} xs={12}>
            <Grid container spacing={3}>
              <FormControlLabel
                disabled={!edit}
                control={
                  <Switch
                    color="primary"
                    checked={vacation.whole_balance}
                    onChange={() => handleSwitchChange()}
                  />
                }
                labelPlacement="start"
                label={
                  <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
                    {'Whole Balance'}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  vacation: company.vacation,
});

const mapDispatchToProps = {
  updateCompanyVacation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveDetails);
