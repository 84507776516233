import ModificationTable from "./ModificationTable";
import React from "react";
import { useTranslation } from "react-i18next";

const Deductions = ({ employee, deductions, enableActions }) => {
  const { t } = useTranslation("common");

  const headerLabels = [
    t("common:reason"),
    t("common:amount"),
    t("common:month"),
    t("common:file"),
    t("common:edit"),
    t("common:delete"),
  ];

  if (!enableActions) {
    let editLabelIdx = headerLabels.indexOf(t("common:edit"));
    headerLabels.splice(editLabelIdx, 1);
  }

  return (
    <ModificationTable
      employee={employee}
      label={t("deductions")}
      actionLabel={t("addDeduction")}
      headerLabels={headerLabels}
      data={deductions}
      enableActions={enableActions}
    />
  );
};

export default Deductions;
