import { Add, NotesSharp } from "@material-ui/icons";
//Material UI
import { Box, Button, Grid, Typography } from "@material-ui/core";
//Components
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
  SectionTitle,
} from "../../../common";
import React, { useState } from "react";

import { Alert } from "@material-ui/lab";
import ReactGA from "react-ga";
import RequestsTable from "../../../common/RequestsTable";
import { connect } from "react-redux";
import { createOvertimeRequest } from "../../../../redux/actions";
//Moment
import moment from "moment";
import { requestsStatus } from "../../../common/DropDownsData";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const EmployeeOvertime = ({ profile, user, createOvertimeRequest }) => {
  ReactGA.pageview(window.location.pathname);
  const [open, setOpen] = useState(false);
  const [overtimeRequest, setOvertimeRequest] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
    amount: "",
  });

  const [error, setError] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  if (!profile) return <></>;

  const overtimeRequestsData = profile.requests.filter(
    (request) => request.kind === "OVERTIME" && request.overtimeRequest
  );

  const headerLabels = [
    t("common:id"),
    t("common:date"),
    t("common:amount"),
    t("common:status"),
  ];

  let overtimeRequests = overtimeRequestsData.map((request) => {
    const status = () => {
      let statusObj = requestsStatus.find(
        (status) => status.value === request.status
      );
      if (isEnglish) return statusObj.name;
      return statusObj.nameAr;
    };
    return {
      // startDate: moment(new Date(request.createdOn)).format(
      //   "DD-MM-yyyy hh:mm a"
      // ),
      id: request.id,
      date: moment(new Date(request.overtimeRequest.date)).format("DD-MM-yyyy"),
      amount: request.overtimeRequest.amount,
      status: status(),
    };
  });

  return (
    <>
      <Grid item md={11} xs={12}>
        <Box
          clone
          bgcolor="white"
          minHeight={"500px"}
          paddingX={matchesMD ? 2 : 6}
          marginBottom={3}
          paddingY={3}
          borderRadius={10}
        >
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <SectionTitle
                  title={t("overtimeRequests")}
                  icon={<NotesSharp color="primary" />}
                />
                {user.isEmployee && (
                  <Button
                    style={{
                      width: "210px",
                      whiteSpace: "nowrap",
                    }}
                    variant="text"
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => setOpen(true)}
                  >
                    {t("overtimeRequest")}
                  </Button>
                )}
              </Box>
            </Grid>

            <Grid item md={12} xs={12}>
              <RequestsTable
                data={overtimeRequests}
                labels={headerLabels}
                toRow={"/request"}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <CompanyStructureModal
        open={open}
        onClose={() => setOpen(false)}
        fadeIn={open}
        title={t("overtimeRequest")}
        height="300px"
      >
        <Grid container justifyContent="center">
          <Grid item container spacing={3} style={{ margin: 4 }}>
            {error && (
              <Grid item md={8} xs={12}>
                <Alert onClick={() => setError("")} severity="error">
                  {error}
                </Alert>
              </Grid>
            )}
            <Grid item md={6} xs={6}>
              <DatePickerField
                isRequired
                label={t("startDate")}
                name="startDate"
                onChange={(e) =>
                  setOvertimeRequest({
                    ...overtimeRequest,
                    date: moment(e).format("yyyy-MM-DD"),
                  })
                }
                value={overtimeRequest.date}
                disableToolbar={false}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <FormInputField
                isRequired
                label={t("amount")}
                name="amount"
                type="number"
                onChange={(e) => {
                  setOvertimeRequest({
                    ...overtimeRequest,
                    amount: e.target.value,
                  });
                }}
                value={overtimeRequest.amount}
                id="amount"
                helperText={t("maximumOvertime")}
              />
            </Grid>
          </Grid>
          {/* <Grid item md={6} xs={6}>
            <TimePickerField
              isRequired
              label={t('startTime')}
              name="startTime"
              onChange={e =>
                setOvertimeRequest({
                  ...overtimeRequest,
                  startTime: moment(e),
                })
              }
              value={overtimeRequest.startTime}
            />
          </Grid> */}

          <Grid item md={12} xs={12} style={{ margin: 10 }}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createOvertimeRequest(overtimeRequest, () =>
                      setOpen(false)
                    );
                  }}
                >
                  <Typography>{t("confirm")}</Typography>
                </Button>
              </Box>
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(false)}
                >
                  <Typography>{t("cancel")}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ employee, auth }) => ({
  profile: employee.profile,
  user: auth.user,
});

const mapDispatchToProps = {
  createOvertimeRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeOvertime);
