import { Box, Button, Typography } from "@material-ui/core";

import React from "react";
import { createPDF } from "./createPDF";
import { getPdfFields } from "./getPdfFields";
import { useTranslation } from "react-i18next";

const GeneratePdfButton = ({
  employeesForPdf,
  pdfFields,
  setPdfFields,
  setOpenModal,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  return (
    <Box clone marginX={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          createPDF(
            employeesForPdf,
            setPdfFields,
            getPdfFields,
            setOpenModal,
            t,
            isEnglish
          )
        }
      >
        <Typography>{t("generatePdf")}</Typography>
      </Button>
    </Box>
  );
};

export default GeneratePdfButton;
