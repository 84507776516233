import {
  Box,
  Button,
  Grid,
  Modal,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Send } from "@material-ui/icons/";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import AnnouncementInput from "../../../../../components/common/AnnouncementInput";
import AutocompleteInput from "../../../../../components/common/AutocompleteInput";
import MultilineInput from "../../../../../components/common/MultilineInput";
//Styles
import useStyles from "../../../../../components/pages/NewAnnouncements/styles";
//Data & Utils
import { filterEmployees } from "../../../../../components/pages/NewAnnouncements/utils";
import { createAnnouncement } from "../../../../../redux/actions";

const ResourceModal = ({
  resource,
  setResource,
  openModal,
  setOpenModal,
  t,
  departments,
  employees,
  isEnglish,
  positions,
  createAnnouncement,
}) => {
  console.log("🚀 ~ file: ResourceModal.js:34 ~ resource", resource);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [position, setPosition] = useState([]);

  const [message, setMessage] = useState("");
  const [_employees, setEmployees] = useState([]);
  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id)
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ])
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const employeesList = filterEmployees(employees, department, position);

  const handleSubmit = () => {
    let announcement = {
      title: resource?.name,
      titleAr: resource?.nameAr,
      postDate: new Date(),
      body: message,
      bodyAr: message,
      resources: [resource?.id],
    };
    if (_employees.length !== 0) {
      createAnnouncement(
        {
          ...announcement,
          employees: _employees.map((employee) => employee.value),
        },
        () => {
          setOpenModal(false);
          history.push("/announcements");
        }
      );
    } else if (position.length !== 0) {
      createAnnouncement(
        {
          ...announcement,
          positions: position.map((pos) => pos.value),
        },
        () => {
          setOpenModal(false);
          history.push("/announcements");
        }
      );
    } else if (department.length !== 0) {
      createAnnouncement(
        {
          ...announcement,
          departments: department.map((dep) => dep.value),
        },
        () => {
          setOpenModal(false);
          history.push("/announcements");
        }
      );
    }
  };

  return (
    <Box>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Grid container>
          <Box
            width={matchesXS ? "85%" : "60%"}
            height={matchesXS ? "85%" : "80%"}
            top={matchesXS ? "5%" : "10%"}
            left={matchesXS ? "5%" : "20%"}
            bgcolor="white"
            position="absolute"
            borderRadius={10}
            overflow="scroll"
          >
            <Box clone padding={6}>
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <AnnouncementInput
                    disabled
                    label="Resource"
                    value={isEnglish ? resource?.name : resource?.nameAr}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        isEnglish
                          ? setResource({ ...resource, title: e.target.value })
                          : setResource({
                              ...resource,
                              titleAr: e.target.value,
                            });
                      }
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <MultilineInput
                    label={t("announcement")}
                    placeholder={t("enterAnnouncement")}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={department}
                    options={departments.map((dep) => ({
                      label: isEnglish ? dep.name : dep.nameAr,
                      value: dep.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setDepartment(e)}
                    label={t("department")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    multiple={true}
                    value={position}
                    options={_positions().map((pos) => ({
                      label: isEnglish ? pos.name : pos.nameAr,
                      value: pos.id,
                    }))}
                    onChange={(e) => setPosition(e)}
                    label={t("position")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutocompleteInput
                    value={_employees}
                    options={employeesList.map((emp) => ({
                      label: emp.user ? emp.user?.fullName : emp?.fullName,
                      value: emp.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setEmployees(e)}
                    label={t("employees")}
                  />
                </Grid>

                <Grid item md={12} sm={12}>
                  <Box
                    clone
                    paddingTop={
                      matchesXS
                        ? 10
                        : matchesSM
                        ? 8
                        : matchesMD
                        ? 0
                        : matchesLG
                        ? 0
                        : 15
                    }
                    paddingLeft={matchesXS ? 5 : undefined}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={isEnglish ? "flex-end" : "flex-start"}
                      spacing={2}
                    >
                      {isEnglish && (
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={() => setOpenModal(false)}
                          >
                            {t("cancel")}
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          className={classes.sendButton}
                          startIcon={
                            !isEnglish && (
                              <Send
                                style={{
                                  transform: "scaleX(-1)",
                                }}
                              />
                            )
                          }
                          endIcon={isEnglish && <Send />}
                        >
                          {t("send")}
                        </Button>
                      </Grid>
                      {!isEnglish && (
                        <Grid item>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={() => setOpenModal(false)}
                          >
                            {t("cancel")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({ company, employees }) => ({
  departments: company.departments,
  employees: employees.employees,
  positions: company.positions,
});

const mapDispatchToProps = {
  createAnnouncement,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceModal);
