import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";

import { Add } from "@material-ui/icons";
import ApproversForm from "./ApproversForm";
import { CompanyStructureModal } from "../../../common";
import Rules from "./Rules";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

//Redux

const RequestApprovals = ({ approverRules }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box clone minHeight={"400px"} paddingY={5} paddingX={matchesMD ? 4 : 10}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h2" color="textPrimary">
                {t("requestApprovals")}
              </Typography>
              <Button
                disableRipple
                variant="contained"
                color="primary"
                startIcon={isEnglish ? <Add /> : null}
                endIcon={isEnglish ? null : <Add />}
                onClick={() => setOpenModal(true)}
              >
                <Typography>{t("common:Add")}</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item container md={12} xs={12}>
            <Rules openModal={openModal} setOpenModal={setOpenModal} />
          </Grid>
        </Grid>
      </Box>
      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("addRule")}
      >
        <ApproversForm setOpen={setOpenModal} />
      </CompanyStructureModal>
    </>
  );
};

export default RequestApprovals;
