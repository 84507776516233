import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { BorderColorOutlined } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const LocationItem = ({
  location,
  setModifiedLocation,
  setOpen,
  setSelectedLocation,
  isLast,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  return (
    <Grid
      item
      container
      lg={12}
      justifyContent="space-between"
      alignItems="center"
      minWidth={"100%"}
      spacing={1}
      style={{ marginTop: 5 }}
    >
      <Grid item lg={3}>
        <Typography color="primary" variant="subtitle2">
          {isEnglish ? location?.name : location?.nameAr}
        </Typography>
      </Grid>

      <Grid item lg={3}>
        <Typography variant="subtitle2" color="primary">
          {location?.employees?.length ? location?.employees?.length : 0}{" "}
          {t("common:employees")}
        </Typography>
      </Grid>

      <Grid item lg={3}>
        <Typography variant="subtitle2" color="primary">
          {`${t("common:latitude")}:${location.latitude ?? " - "}`}
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {`${t("common:longitude")}:${location.longitude ?? " - "}`}
        </Typography>
      </Grid>

      <Grid item container direction="row-reverse" lg={3}>
        <IconButton
          disableRipple
          size="small"
          onClick={() => {
            setSelectedLocation(location);
            setModifiedLocation({
              id: location.id,
              name: location.name,
              nameAr: location.nameAr,
              latitude: location.latitude,
              longitude: location.longitude,
            });
            setOpen(true);
          }}
        >
          <BorderColorOutlined color="primary" />
        </IconButton>
      </Grid>
      {!isLast && (
        <Grid item lg={12}>
          <Divider variant="fullWidth" />
        </Grid>
      )}
    </Grid>
  );
};

export default LocationItem;
