import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  gender: yup.string().required(),
  email: yup.string().email().required(),
  maritalStatus: yup.string(),
  dob: yup.date().required(),
  country: yup.string().required(),
  address1: yup.string().required(),
  address2: yup.string(),
  cityOrTown: yup.string().required(),
  postcode: yup.string(),
  zipCode: yup.string(),
  stateOrProvince: yup.string().required(),
  paciNumber: yup.string(),
});
export default schema;
