import React from "react";

// Componenets
import Navbar from "../../Navbar";
import Form from "./form";

// Material UI
import { Box, Grid } from "@material-ui/core";

const NuAttendance = () => {
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Box clone height={"100%"}>
        <Grid item md={6} xs={12}>
          <Navbar />
          <Box clone minHeight={"70vh"}>
            <Grid container justifyContent="center" alignContent="center">
              <Grid item md={5} xs={10}>
                <Form />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default NuAttendance;
