import { calculateDistance } from "./calculateDistance";

// latitude:
//  if calculating checkin distance -> attendance.checkInLatitude
//  if calculating checkout distance -> attendance.checkOutLatitude

// longitude:
//  if calculating checkin distance -> attendance.checkInLongitude
//  if calculating checkout distance -> attendance.checkOutLongitude

// locationLatitude:
// attendance.location.latitude

// locationLongitude
// attendance.location.longitude

// profileLocation
// profile.location

// prettier-ignore
export const distanceToDisplay = (latitude, longitude,locationLatitude,locationLongitude,profileLocation, t ) => {

  // prettier-ignore
  const isMissingValue = !latitude || !longitude || !locationLatitude || !locationLongitude

  // prettier-ignore
  const isMissingValueAndProfileLocation = isMissingValue || !profileLocation;

  // prettier-ignore
  const missingData = profileLocation ? isMissingValueAndProfileLocation : isMissingValue;


  if (missingData) {
    return "-";
  }

  return `${calculateDistance(
    latitude,
    locationLatitude,
    longitude,
    locationLongitude
  )} ${t("kilometers")}`;


};
