import { Card, CardActions, CardHeader, Grid, List } from "@material-ui/core";

import { DetailsItem } from "../../../common";
import React from "react";
import { useTranslation } from "react-i18next";

const CompanyBasicDetails = ({ company, address }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const sector = isEnglish ? company?.sector?.name : company?.sector?.nameAr;

  const companyAddressDetails = [
    { label: t("address1"), value: company?.address?.address1 },
    { label: t("address2"), value: company?.address?.address2 },
    { label: t("zipcode"), value: company?.address?.zipCode },
    { label: t("country"), value: company?.address?.country?.name },
    { label: t("cityOrTown"), value: company?.address?.cityOrTown },
    { label: t("stateOrProvince"), value: company?.address?.stateOrProvince },
    { label: t("paciNumber"), value: company?.address?.paciNumber },
  ];

  return (
    <Card>
      <CardHeader title={t("basicDetails")} />

      <List>
        <DetailsItem label={t("name")} value={company?.name} />
        <DetailsItem label={t("arabicName")} value={company?.nameAr} />
        <DetailsItem label={t("companyProfile:sector")} value={sector} />
      </List>

      <CardHeader title={t("companyAddress")} />

      <List disablepadding>
        <Grid container>
          {companyAddressDetails.map((detail, idx) => {
            return (
              <Grid item md={6} key={idx}>
                <DetailsItem label={detail.label} value={detail.value} />
              </Grid>
            );
          })}
        </Grid>
      </List>

      <CardActions></CardActions>
    </Card>
  );
};

export default CompanyBasicDetails;
