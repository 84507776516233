import { Box, ListItem, ListItemText, Typography } from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const CompanyDocumentItem = ({ label, value }) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const getValueForField = () => {
    if (typeof value === "object") {
      if (isEnglish) {
        return value?.name ?? "N/A";
      } else {
        return value?.nameAr;
      }
    } else {
      return value ?? "N/A";
    }
  };
  return (
    <ListItem disablepadding={true.toString()}>
      <ListItemText
        disableTypography
        primary={
          <Typography
            style={{ fontWeight: "bold" }}
            variant="subtitle2"
            align={isEnglish ? "left" : "right"}
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            style={{
              flex: 1,
              marginTop: 0,
            }}
          >
            <Typography
              color="textSecondary"
              variant="body2"
              align={isEnglish ? "left" : "right"}
            >
              {getValueForField()}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};

export default CompanyDocumentItem;
