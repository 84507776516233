import "jspdf-autotable";

import "../../../../../helpers/Amiri-Regular-normal";

import jsPDF from "jspdf";

import { getBody, getHeaders } from "./data";
import moment from "moment";

export const filterEmployees = (employee) => {
  return employee;
};

// const getTotalSum = (employees, idx) => {
//   let sum = 0;

//   for (const employee of employees) {
//     sum += Number(employee[idx]);
//   }

//   return sum.toFixed(3);
// };

export const createPDF = (employeesForPdf, closeModal, t, isEnglish, month) => {
  const pdf = new jsPDF("portrait");

  // Styling
  const titleStyle = {
    fontSize: 18,
    fontStyle: "bold",
    textColor: "#002553",
    align: "center",
  };

  const cellPadding = 5;
  // const borderWidth = 0.5; // Adjust the border width as needed

  // Pdf Headers
  const headers = getHeaders(employeesForPdf[0], t, isEnglish);
  const body = getBody(employeesForPdf, isEnglish);

  let _month = moment(month).format("MMM-YYYY");

  const title = `${employeesForPdf[0].name} ${t("indemnitySlip")} ${_month}`;

  pdf.setTextColor("#002553");

  if (!isEnglish) {
    pdf.setFont("Amiri-Regular");
  }

  // Add title
  pdf.text(title, pdf.internal.pageSize.width / 2, 15, titleStyle);

  // Transpose the data array for the vertical table
  const verticalTableData = headers.map((header, index) =>
    isEnglish ? [header, body[0][index]] : [body[0][index], header],
  );

  // Calculate the width of each column
  // const columnWidth = pdf.internal.pageSize.width / 2;
  let headStyles = isEnglish
    ? { halign: "left" }
    : { font: "Amiri-Regular", halign: "right" };
  let bodyStyles = isEnglish
    ? { halign: "left" }
    : { font: "Amiri-Regular", halign: "right" };
  // Use autoTable for creating the vertical table
  pdf.autoTable({
    startY: 30, // Adjust as needed
    body: verticalTableData,
    theme: "grid",
    tableWidth: "auto",
    margin: { top: 40 },
    styles: {
      cellPadding: cellPadding,
      fontSize: 12,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      overflow: "linebreak",
      halign: isEnglish ? "center" : "right", // Center align cells horizontally for English, right-align for Arabic
      valign: "middle", // Center align cells vertically
      fontStyle: "normal", // Default font style for body cells
    },
    headStyles: { ...headStyles, fillColor: [230, 230, 230] },
    bodyStyles: bodyStyles,
    columnStyles: {
      0: { halign: isEnglish ? "left" : "right" }, // Align the first column to the left for English, right-align for Arabic
    },
  });

  pdf.save(`Indemnity-slip-${_month}.pdf`);
  closeModal();
};

export const getCsvData = (
  employeesForCsv,
  setPdfFields,
  indemnityPdfFields,
  setOpenModal,
  t,
  isEnglish,
) => {
  const headers = getHeaders(employeesForCsv[0], t, isEnglish);
  const body = getBody(employeesForCsv, isEnglish);

  const data = [headers, ...body];

  return data;
};

export const getXlsxData = (employeesForCsv, closeModal, t, isEnglish) => {
  const headers = getHeaders(employeesForCsv[0], t, isEnglish);
  const body = getBody(employeesForCsv, isEnglish);

  const data = [headers, ...body];

  closeModal();
  return data;
};
