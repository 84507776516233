import React, { Fragment } from "react";

import { Box, Button, Grid } from "@material-ui/core";
import { DatePickerField } from "../../../common";

/**
 * @description Payroll page header component
 * @category Components - Web
 * @namespace PayrollPageHeaderComponent
 * @param {import('./types').PayrollPageHeaderComponentProps} PayrollPageHeaderComponentProps - props for component
 * @returns {React.JSX.Element}
 */

export const PayrollPageHeaderComponent = ({
  handleMonthChange,
  displayMonth,
  onClick,
  t,
}) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Box
          paddingX={4}
          paddingY={2}
          display={"flex"}
          flexDirection={"row-reverse"}
          justifyContent={"flex-start"}
        >
          <DatePickerField
            fullWidth={false}
            disableToolbar={false}
            yearMonthView
            openTo="month"
            onChange={handleMonthChange}
            value={displayMonth}
            label={t("common:month")}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box paddingX={4} display={"flex"} flexDirection={"row-reverse"}>
          <Button variant="contained" color="primary" onClick={onClick}>
            {t("payrollReport")}
          </Button>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default PayrollPageHeaderComponent;
