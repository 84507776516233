import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
// Translations
import { useTranslation } from "react-i18next";

const UserLocationDenied = ({ onClickCancel, onClickCheck, onCheckOut }) => {
  const { t } = useTranslation("common");
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box paddingBottom={2}>
          <Typography align="center">
            {t("userLocationDeniedDescription")}
          </Typography>
        </Box>
      </Grid>

      <Grid item container justifyContent="flex-end" md={6} sm={6} xs={6}>
        <Button variant="contained" color="primary" onClick={onClickCancel}>
          {t("cancel")}
        </Button>
      </Grid>
      <Grid item md={6} sm={6} xs={6}>
        <Button variant="contained" color="primary" onClick={onClickCheck}>
          {onCheckOut ? t("checkOut") : t("checkIn")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserLocationDenied;
