import { Box, IconButton } from "@material-ui/core";
import { DeleteOutlined, EditOutlined, Save } from "@material-ui/icons";
import React, { useState } from "react";

import { CompanyStructureModal } from "../../../../../../../components/common";
import Position from "./Position";
import PositionForm from "../Forms/PositionForm";
import { connect } from "react-redux";
import { deletePosition } from "../../../../../../../redux/actions";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";

const Positions = ({ department, deletePosition }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const positionList = department.positions.map((position) => (
    <Position
      position={position}
      action={
        <>
          <Box flexDirection="row">
            <IconButton
              disableRipple
              className={classes.menuButton}
              size="small"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <EditOutlined color="primary" />
            </IconButton>
            <IconButton
              disableRipple
              className={classes.menuButton}
              size="small"
              disabled={position?.employees?.length}
              onClick={() =>
                deletePosition({ id: position.id }, department?.id)
              }
            >
              <DeleteOutlined
                style={{
                  color: position.employees.length > 0 ? "inherit" : "gray",
                }}
              />
            </IconButton>
          </Box>
          <CompanyStructureModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            fadeIn={openModal}
            title={t("companyProfile:editPosition")}
            icon={<Save />}
            iconText={t("common:save")}
          >
            <PositionForm
              setOpen={setOpenModal}
              departmentId={department.id}
              position={position}
            />
          </CompanyStructureModal>
        </>
      }
      description={`${position.employees.length} ${t("common:employees")}`}
      departmentId={department.id}
      key={position.id}
    />
  ));
  return positionList;
};

const mapDispatchToProps = {
  deletePosition,
};

export default connect(null, mapDispatchToProps)(Positions);
