import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../../components/common";
import React, { useState } from "react";

import { Alert } from "@material-ui/lab";
// import DateRangeInput from "../../../../../components/common/DateRangePicker";
import { connect } from "react-redux";
import moment from "moment/moment";
import { updateOrCreateOfficialHoliday } from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const HolidayModal = ({
  open,
  setOpen,
  holiday,
  setHoliday,
  updateOrCreateOfficialHoliday,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const [error, setError] = useState("");
  return (
    <CompanyStructureModal
      open={open}
      onClose={() => {
        setHoliday({
          name: "",
          nameAr: "",
          start: moment(new Date()).format("yyyy-MM-DD"),
          end: moment(new Date()).format("yyyy-MM-DD"),
        });
        setOpen(false);
      }}
      fadeIn={open}
      title={t("companyProfile:addHoliday")}
    >
      <Grid container justifyContent="center">
        <Grid item container md={12} style={{ margin: 10 }} spacing={3}>
          {error && (
            <Grid item md={8} xs={12}>
              <Alert onClick={() => setError("")} severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item md={6} xs={6}>
            <FormInputField
              isRequired
              label={t("common:name")}
              name="name"
              type="text"
              onChange={(e) => setHoliday({ ...holiday, name: e.target.value })}
              value={holiday.name}
              id="name"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormInputField
              isRequired
              label={t("common:arabicName")}
              name="nameAr"
              type="text"
              onChange={(e) =>
                setHoliday({ ...holiday, nameAr: e.target.value })
              }
              value={holiday.nameAr}
              id="nameAr"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <DatePickerField
              openTo="date"
              onChange={(date) => {
                setHoliday({
                  ...holiday,
                  start: moment(date).format("yyyy-MM-DD"),
                });
              }}
              value={holiday.start}
              label={t("common:startDate")}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <DatePickerField
              min={holiday.start}
              openTo="date"
              onChange={(date) => {
                setHoliday({
                  ...holiday,
                  end: moment(date).format("yyyy-MM-DD"),
                });
              }}
              value={holiday.end}
              label={t("common:endDate")}
            />
          </Grid>
          {/* <Grid item md={12} xs={6}>
            <DateRangeInput
              onChange={(selectedDates) =>
                setHoliday({
                  ...holiday,
                  start: moment(selectedDates[0]).format("yyyy-MM-DD"),
                  end: moment(selectedDates[1]).format("yyyy-MM-DD"),
                })
              }
            />
          </Grid> */}
        </Grid>

        <Grid item md={12} xs={12} style={{ margin: 10 }}>
          <Box
            display="flex"
            flexDirection={isEnglish ? "row" : "row-reverse"}
            justifyContent="center"
          >
            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateOrCreateOfficialHoliday(holiday, () => setOpen(false))
                }
              >
                <Typography>
                  {/* {officialHoliday ? t("common:update") : t("common:confirm")} */}
                  {t("common:confirm")}
                </Typography>
              </Button>
            </Box>

            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setHoliday({
                    name: "",
                    nameAr: "",
                    start: moment(new Date()).format("yyyy-MM-DD"),
                    end: moment(new Date()).format("yyyy-MM-DD"),
                  });
                  setOpen(false);
                }}
              >
                <Typography>{t("common:cancel")}</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};

const mapDispatchToProps = {
  updateOrCreateOfficialHoliday,
};

export default connect(null, mapDispatchToProps)(HolidayModal);
