//MaterialUI
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";

import AutocompleteInput from "../../AutocompleteInput";
import { CompanyStructureModal } from "../..";
import { Edit } from "@material-ui/icons";
import React from "react";
import ShiftsForm from "./ShiftsForm";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

const WorkingHours = ({
  edit,
  onSubmit,
  onClose,
  openShiftsModal,
  setOpenShiftsModal,
  labels,
  setLabels,
  schedulesState,
  setSchedulesState,
  forEmployee,
  alert,
  setAlert,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  return (
    <>
      {!forEmployee && (
        <Typography variant="h2" color="textPrimary">
          {t("companyProfile:workingHours")}
        </Typography>
      )}

      <Box
        clone
        paddingX={forEmployee ? 0 : 2}
        paddingTop={forEmployee ? 0 : 6}
      >
        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={forEmployee ? 11 : 6} xs={11}>
              <AutocompleteInput
                forcePopupIcon={false}
                disabled
                value={labels.map(({ label, value }) => ({
                  label,
                  value,
                }))}
                options={[]}
                multiple={true}
                label={t("companyProfile:defaultWorkingHours")}
                name="workingHours"
                helperText={t("companyProfile:defaultWorkingHoursHelperText")}
                id="workingHours"
              />
            </Grid>
            <Grid item md={1} xs={1}>
              <IconButton
                disabled={!edit}
                color="primary"
                onClick={() => setOpenShiftsModal(true)}
              >
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CompanyStructureModal
        open={openShiftsModal}
        onClose={onClose}
        fadeIn={openShiftsModal}
        title={t("common:shifts")}
      >
        <Box height={"auto"} maxHeight="90vh" margin={4}>
          <form>
            {alert && (
              <Alert severity="error">Please Select Schedule days !</Alert>
            )}
            <ShiftsForm
              schedulesState={schedulesState}
              setSchedulesState={setSchedulesState}
            />
            <Box
              display="flex"
              flexDirection="flex-end"
              justifyContent="flex-end"
              width="100%"
            >
              <Button variant="contained" color="primary" onClick={onSubmit}>
                {t("save")}
              </Button>
            </Box>
          </form>
        </Box>
      </CompanyStructureModal>
    </>
  );
};

export default WorkingHours;
