import React from "react";

import { CompanyStructureModal } from "../../../../../components/common";
import DepositForm from "./DepositForm";

const DepositSalaryModal = ({
  open,
  onClose,
  title,
  deposited,
  selected,
  total,
}) => {
  return (
    <CompanyStructureModal
      open={open}
      onClose={onClose}
      fadeIn={open}
      title={title}
    >
      <DepositForm
        total={Number(total)}
        closeModal={onClose}
        deposited={deposited}
        selected={selected}
      />
    </CompanyStructureModal>
  );
};

export default DepositSalaryModal;
