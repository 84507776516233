import ModificationTable from "./ModificationTable";
import React from "react";
import { useTranslation } from "react-i18next";

const RecurringDeductions = ({
  employee,
  recurringDeductions,
  enableActions,
}) => {
  const { t } = useTranslation(["common", "employeeSalary", "employeeProfile"]);

  const headerLabels = [
    t("common:reason"),
    t("common:amount"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:file"),
    t("common:edit"),
    t("common:delete"),
  ];

  return (
    <ModificationTable
      employee={employee}
      label={t("employeeProfile:recurringDeductions")}
      actionLabel={t("employeeProfile:addRecurringDeduction")}
      headerLabels={headerLabels}
      data={recurringDeductions}
      enableActions={enableActions}
      isRecurring
    />
  );
};

export default RecurringDeductions;
