import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const DetailsItem = (props) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const { label, value, icon: Icon, endIcon: EndIcon, ...other } = props;
  return (
    <ListItem disablepadding={true.toString()} {...other}>
      {Icon && (
        <ListItemIcon>
          <Icon color="primary" />
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={
          <Typography
            style={{ fontWeight: "bold" }}
            variant="subtitle2"
            align={isEnglish ? "left" : "right"}
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            style={{
              flex: 1,
              marginTop: 0,
            }}
          >
            <Typography
              color="secondary"
              variant="body2"
              align={isEnglish ? "left" : "right"}
            >
              {value}
            </Typography>
          </Box>
        }
      />
      {EndIcon && (
        <ListItemIcon>
          <EndIcon color="primary" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default DetailsItem;
