import {FormControl, FormHelperText, TextField} from '@material-ui/core'

import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
//Translation
import {useTranslation} from 'react-i18next'

const AnnouncementInput = ({
  label,
  name,
  onChange = () => {},

  errors,
  helperText,
  type = 'text',
  isRequired,
  children,
  select = false,
  disabled = false,
  strict = false,
  ...inputProps
}) => {
  const {
    i18n: {language},
  } = useTranslation()
  const isEnglish = language === 'en'
  const useStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.primary.main,
      '& .MuiFormLabel-root': {
        color: theme.palette.primary.main,
        fontSize: '1rem',
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid #3D5E77',
      },
      '& .MuiInputLabel-formControl': {
        transformOrigin: isEnglish ? 'top left' : 'top right',
        display: 'flex',
        flexDirection: isEnglish ? 'row' : 'row-reverse',
        top: 0,
        paddingRight: isEnglish ? 0 : 4,
        left: undefined,
        right: isEnglish ? null : 0,
      },
    },
  }))

  const classes = useStyles()

  return (
    <FormControl fullWidth className={classes.root}>
      <TextField
        error={!!errors}
        inputProps={{
          style: {textAlign: isEnglish ? 'left' : 'right'},
        }}
        required={isRequired}
        label={label}
        type={type}
        onChange={onChange}
        name={name}
        select={select}
        disabled={disabled}
        {...inputProps}
      >
        {children}
      </TextField>
      {helperText && (
        <FormHelperText className={classes.error} children={helperText} />
      )}
      <FormHelperText children={errors} error={!!errors} />
    </FormControl>
  )
}

export default AnnouncementInput
