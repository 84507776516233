//Material UI
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Add, NotesSharp } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
//Moment

import React, { useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//Components
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
  SearchInput,
} from "../../../common";
import { requestsKind, requestsStatus } from "../../../common/DropDownsData";

import { TablePagination } from "../../../../redesign/components/common";
import RequestItem from "./RequestItem";
import AddRequestModal from "./RequestModals/addRequstModal";
import GeneralRequestModal from "./RequestModals/generalRequestModal";
import LeaveRequestModal from "./RequestModals/leaveRequestModal";
import OvertimeRequestModal from "./RequestModals/overtimeRequestModal";
import ResignationRequestModal from "./RequestModals/resignationRequsetModal";
import { filterRequests } from "./utils";

const EmployeeRequests = () => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "requests"]);
  const isEnglish = language === "en";
  const theme = useTheme();
  //   const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestType, setRequestType] = useState("");

  const profile = useSelector((state) => state.employee.profile);
  const isOvertimeEnabled = (employee) => {
    if (employee) {
      return employee.company?.overtimeEnabled;
    }
    return false;
  };

  // const headerLabels = [
  //   t("common:id"),
  //   t("common:reason"),
  //   t("common:kind"),
  //   t("common:amount"),
  //   t("common:startDate"),
  //   t("common:endDate"),
  //   t("common:createdOn"),
  //   t("common:status"),
  //   t("common:lastModified"),
  //   t("common:modifiedBy"),
  // ];
  // let employeeRequests = profile.requests
  //   .filter((request) => request.leaveRequest)
  //   .map((request) => {
  //     const kind = () => {
  //       let kindObj = leavesKind.find(
  //         (kind) => kind.value === request.leaveRequest.kind,
  //       );
  //       if (kindObj) {
  //         if (isEnglish) return kindObj.name;
  //         return kindObj.nameAr;
  //       }
  //       return "";
  //     };
  //     const status = () => {
  //       let statusObj = requestsStatus.find(
  //         (status) => status.value === request.status,
  //       );
  //       if (isEnglish) return statusObj.name;
  //       return statusObj.nameAr;
  //     };
  //     return {
  //       id: request.id,
  //       reason: request.leaveRequest.reason,
  //       kind: kind(),
  //       amount: request.leaveRequest.amount,
  //       startDate: moment(new Date(request.leaveRequest.date)).format(
  //         "DD-MM-yyyy",
  //       ),
  //       endDate: moment(new Date(request.leaveRequest.endDate)).format(
  //         "DD-MM-yyyy",
  //       ),
  //       createdOn: moment(new Date(request.createdOn)).format(
  //         "DD-MM-yyyy hh:mm a",
  //       ),
  //       status: status(),
  //       lastModified: moment(new Date(request.lastModified)).format(
  //         "DD-MM-yyyy hh:mm a",
  //       ),
  //       lastModifiedBy: request.lastModifiedBy?.fullName,
  //     };
  //   });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(["requestId"]);
  const [query, setQuery] = useState("");

  const pendingRequests = (requests = []) => {
    return requests.filter((request) => request.status === "PENDING").length;
  };

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "requestId":
        if (b.id < a.id) return -1;
        if (b.id > a.id) return 1;
        return 0;
      case "requestType":
        if (b.kind < a.kind) return -1;
        if (b.kind > a.kind) return 1;
        return 0;
      case "requestStatus":
        if (b.status < a.status) return -1;
        if (b.status > a.status) return 1;
        return 0;
      case "requestDate":
        if (b.createdOn < a.createdOn) return -1;
        if (b.createdOn > a.createdOn) return 1;
        return 0;

      default:
        return orderBy;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "requestId", label: t("common:requestID") },
    { id: "requestType", label: t("common:requestType") },
    { id: "requestStatus", label: t("common:requestStatus") },
    { id: "requestDate", label: t("common:requestDate") },
  ];
  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,

      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      setOrderBy(property);
      onRequestSort(event, property);
    };

    const useStyles = makeStyles(() => ({
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }));

    const classes = useStyles();

    return (
      <TableHead>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <CustomTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const listOfRequests = (requests = []) => {
    let requestsList = filterRequests(requests, query);
    return stableSort(requestsList, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((request) => (
        <RequestItem
          isEnglish={isEnglish}
          request={request}
          requestsStatus={requestsStatus}
          requestsKind={requestsKind}
          key={`${request.id}-${request.kind}`}
        />
      ));
  };

  return (
    profile && (
      <Grid container item md={11} xs={12} justifyContent="center">
        <Box
          clone
          bgcolor="white"
          minHeight={"500px"}
          paddingX={matchesMD ? 2 : 6}
          marginBottom={3}
          paddingY={3}
          marginTop={1}
          borderRadius={10}
        >
          <Grid container direction="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <NotesSharp color="primary" />
                <Typography variant="h3" color="primary">
                  {"   "}
                  {t("employeeRequests")}
                </Typography>
              </Box>
              <Button
                style={{ width: "33%", height: "40px" }}
                variant="outlined"
                color="primary"
                endIcon={<Add />}
                onClick={() => setOpen(true)}
              >
                {t("addRequest")}
              </Button>
            </Box>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item md={4} xs={4}>
                <SearchInput
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={t("common:search")}
                  position="start"
                />
              </Grid>
            </Grid>
            <Box marginTop={1}>
              <TableContainer>
                <Box
                  display="flex"
                  flexDirection="row"
                  paddingX={2}
                  paddingY={1}
                >
                  <Typography variant="subtitle2" color="primary">
                    {`${t("requests:pending")}: ${pendingRequests(
                      profile.requests,
                    )}`}
                  </Typography>
                </Box>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>{listOfRequests(profile.requests)}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                count={profile.requests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Box>

        {open && (
          <AddRequestModal
            open={open}
            close={() => setOpen(false)}
            setOpenRequestModal={setOpenModal}
            requestType={requestType}
            setRequestType={setRequestType}
            isOvertimeEnabled={isOvertimeEnabled(profile)}
          />
        )}
        {openModal && requestType === "LEAVE" && (
          <LeaveRequestModal open={openModal} setOpen={setOpenModal} />
        )}
        {openModal && requestType === "RESIGNATION" && (
          <ResignationRequestModal open={openModal} setOpen={setOpenModal} />
        )}
        {openModal &&
          requestType === "OVERTIME" &&
          !isOvertimeEnabled(profile) && (
            <CompanyStructureModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              fadeIn={openModal}
              title={t("OVERTIME")}
            >
              <Box height={"auto"} maxHeight={"90vh"} margin={4}>
                <Grid container alignItems="center">
                  <Grid item md={12} xs={12}>
                    <Box>
                      <Typography variant="h5">
                        {t("overtimeNotEnabled")}
                      </Typography>
                    </Box>
                    <Box padding={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                      >
                        {t("cancel")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CompanyStructureModal>
          )}
        {openModal &&
          requestType === "OVERTIME" &&
          isOvertimeEnabled(profile) && (
            <OvertimeRequestModal open={openModal} setOpen={setOpenModal} />
          )}
        {openModal && requestType === "GENERAL" && (
          <GeneralRequestModal open={openModal} setOpen={setOpenModal} />
        )}
      </Grid>
    )
  );
};

export default EmployeeRequests;
