import moment from "moment/moment";

export let payrollPdfFields = (t) => [
  {
    value: "name",
    label: t("name"),
    active: true,
  },
  {
    value: "id",
    label: t("id"),
    active: true,
  },
  {
    value: "department",
    label: t("department"),
    active: true,
  },
  {
    value: "position",
    label: t("position"),
    active: true,
  },
  {
    value: "month",
    label: t("month"),
    active: true,
  },
  {
    value: "baseSalary",
    label: t("baseSalary"),
    active: true,
  },
  {
    value: "earnings",
    label: t("earnings"),
    active: true,
  },
  {
    value: "grossSalary",
    label: t("grossSalary"),
    active: true,
  },
  {
    value: "deductions",
    label: t("deductions"),
    active: true,
  },
  {
    value: "overTimeHours",
    label: t("overTimeHours"),
    active: true,
  },
  {
    value: "overTimeCost",
    label: t("overTimeCost"),
    active: true,
  },
  {
    value: "totalSalary",
    label: t("totalSalary"),
    active: true,
  },
  {
    value: "netSalary",
    label: t("netSalary"),
    active: true,
  },
  {
    value: "workingDays",
    label: t("workingDays"),
    active: true,
  },
  {
    value: "daysOff",
    label: t("daysOff"),
    active: true,
  },
  {
    value: "unpaidLeaveDays",
    label: t("unpaidLeaveDays"),
    active: true,
  },
  {
    value: "netWorkingDays",
    label: t("netWorkingDays"),
    active: true,
  },
  {
    value: "lateHours",
    label: t("lateHours"),
    active: true,
  },
  {
    value: "bankName",
    label: t("bankName"),
    active: true,
  },
  {
    value: "iban",
    label: t("iban"),
    active: true,
  },

  {
    value: "civilId",
    label: t("civilId"),
    active: true,
  },

  {
    value: "locations",
    label: t("locations"),
    active: true,
  },
  {
    value: "phoneNumber",
    label: t("phoneNumber"),
    active: true,
  },
  {
    value: "annualBalance",
    label: t("annualBalance"),
    active: true,
  },
  {
    value: "offsetBalance",
    label: t("offsetBalance"),
    active: true,
  },
  {
    value: "totalWorkingHours",
    label: t("totalWorkingHours"),
    active: true,
  },
  {
    value: "expectedWorkingHours",
    label: t("expectedWorkingHours"),
    active: true,
  },
  {
    value: "earningsDetails",
    label: t("earningsDetails"),
    active: true,
  },
  {
    value: "deductionsDetails",
    label: t("deductionsDetails"),
    active: true,
  },
  {
    value: "resignation",
    label: t("resignation"),
    active: true,
  },
  {
    value: "termination",
    label: t("termination"),
    active: true,
  },
  {
    value: "vacationAccrual",
    label: t("vacationAccrual"),
    active: true,
  },
];

export const normalizeData = (
  employees,
  myArray,
  isEnglish,
  month,
  multipleMonths = false,
) => {
  const normalizedEmployees = {};

  const fields = myArray
    .filter((field) => field.active)
    .map((field) => field.value);

  for (const employee of employees) {
    const employeeId = employee.employee.id;

    if (!normalizedEmployees[employeeId]) {
      normalizedEmployees[employeeId] = {
        name: "",
        id: "",
        department: "",
        position: "",
        month: "",
        baseSalary: "",
        earnings: 0,
        grossSalary: 0,
        deductions: 0,
        overTimeHours: 0,
        overTimeCost: 0,
        totalSalary: 0,
        netSalary: 0,
        workingDays: 0,
        daysOff: 0,
        netWorkingDays: 0,
        lateHours: 0,
        bankName: "",
        iban: "",
        civilId: "",
        locations: "",
        phoneNumber: "",
        annualBalance: "",
        offsetBalance: "",
        totalWorkingHours: 0,
        expectedWorkingHours: 0,
        earningsDetails: "",
        deductionsDetails: "",
        resignation: 0,
        termination: 0,
        vacationAccrual: 0,
      };
    }

    let modifiedEmployee = normalizedEmployees[employeeId];

    if (fields.includes("name")) {
      modifiedEmployee["name"] = employee.employee?.fullName;
    }

    if (fields.includes("id")) {
      modifiedEmployee["id"] = employee.employee.referenceNumber
        ? employee.employee.referenceNumber
        : employee.employee.id;
    }

    if (fields.includes("department")) {
      modifiedEmployee["department"] = isEnglish
        ? employee.employee.department.name
        : employee.employee.department.nameAr;
    }

    if (fields.includes("position")) {
      modifiedEmployee["position"] = isEnglish
        ? employee.employee.position.name
        : employee.employee.position.nameAr;
    }

    if (fields.includes("baseSalary")) {
      modifiedEmployee["baseSalary"] = Number(
        employee.employee.baseSalary,
      ).toFixed(3);
    }

    if (fields.includes("month")) {
      if (multipleMonths) {
        modifiedEmployee["month"] += `${month}\n to \n ${multipleMonths} `;
      } else {
        modifiedEmployee["month"] = `${moment(month).format("MM-YYYY")}`;
      }
    }

    if (fields.includes("earnings")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // const earnings = getEarnings(
      //   employee.employee.salaryModifications,
      //   employee.employee.recurringSalaryModifications,
      //   monthToUse,
      // ).toFixed(3);
      // if (multipleMonths) {
      //   modifiedEmployee["earnings"] += +Number(earnings);
      // } else {
      //   modifiedEmployee["earnings"] = earnings;
      // }

      let earnings = getEarnings(
        employee.modifications,
        employee.recurringModifications,
      ).toFixed(3);

      modifiedEmployee["earnings"] = earnings;
    }

    if (fields.includes("grossSalary")) {
      modifiedEmployee["grossSalary"] = Number(
        employee.salary.grossSalary,
      ).toFixed(3);
    }

    if (fields.includes("deductions")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // let deductions = getDeductions(
      //   employee.employee.salaryModifications,
      //   employee.employee.recurringSalaryModifications,
      //   // monthToUse,
      // ).toFixed(3);
      // if (multipleMonths) {
      //   modifiedEmployee["deductions"] += +Number(deductions);
      // } else {
      //   modifiedEmployee["deductions"] = deductions;
      // }

      let deductions = getDeductions(
        employee.modifications,
        employee.recurringModifications,
      ).toFixed(3);

      modifiedEmployee["deductions"] = deductions;
    }

    if (fields.includes("overTimeHours")) {
      if (multipleMonths) {
        modifiedEmployee["overTimeHours"] += employee.overtimeHours;
      } else {
        modifiedEmployee["overTimeHours"] = employee.overtimeHours;
      }
    }

    if (fields.includes("overTimeCost")) {
      if (multipleMonths) {
        modifiedEmployee["overTimeCost"] += Number(employee.overtimeCost);
      } else {
        modifiedEmployee["overTimeCost"] = employee.overtimeCost;
      }
    }

    if (fields.includes("totalSalary")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // modifiedEmployee["grossSalary"] = Number(employee.totalSalary).toFixed(3);
      // let grossSalary = getGrossSalary(
      //   employee.employee.salaryModifications,
      //   employee.employee.recurringSalaryModifications,
      //   monthToUse,
      //   employee.employee.baseSalary,
      // ).toFixed(3);
      // if (multipleMonths) {
      //   modifiedEmployee["grossSalary"] += +Number(grossSalary);
      // } else {
      //   modifiedEmployee["grossSalary"] = grossSalary;
      // }

      modifiedEmployee["totalSalary"] = Number(
        employee.salary.totalSalary,
      ).toFixed(3);
    }

    if (fields.includes("netSalary")) {
      modifiedEmployee["netSalary"] = Number(employee.salary.netSalary).toFixed(
        3,
      );
    }

    if (fields.includes("workingDays")) {
      if (multipleMonths) {
        modifiedEmployee["workingDays"] += Number(employee.workingDays);
      } else {
        modifiedEmployee["workingDays"] = Number(employee.workingDays);
      }
    }

    if (fields.includes("daysOff")) {
      // if (multipleMonths) {
      //   modifiedEmployee["daysOff"] += employee.daysOff;
      // } else {
      //   modifiedEmployee["daysOff"] = employee.daysOff;
      // }
      modifiedEmployee["daysOff"] = Number(employee.daysOff);
    }
    if (fields.includes("unpaidLeaveDays")) {
      modifiedEmployee["unpaidLeaveDays"] = Number(employee.unpaid.days);
    }

    if (fields.includes("netWorkingDays")) {
      modifiedEmployee["netWorkingDays"] =
        Number(employee.workingDays) - Number(employee.daysOff);
    }

    if (fields.includes("lateHours")) {
      if (multipleMonths) {
        modifiedEmployee["lateHours"] += employee.lateHours;
      } else {
        modifiedEmployee["lateHours"] = employee.lateHours;
      }
    }

    if (fields.includes("bankName")) {
      modifiedEmployee["bankName"] = employee.employee.bankName;
    }

    if (fields.includes("iban")) {
      modifiedEmployee["iban"] = employee.employee.iban;
    }

    if (fields.includes("annualBalance")) {
      modifiedEmployee["annualBalance"] =
        employee.employee.leaveBalance.annualBalance;
    }

    if (fields.includes("offsetBalance")) {
      modifiedEmployee["offsetBalance"] =
        employee.employee.leaveBalance.offsetBalance;
    }

    if (fields.includes("civilId")) {
      const documentData = employee.civilId;

      if (documentData) {
        modifiedEmployee["civilId"] = documentData;
      } else {
        modifiedEmployee["civilId"] = "-";
      }
    }

    if (fields.includes("locations")) {
      let locationsData = [];
      const employeeLocations = employee.employee.locations;
      if (employeeLocations.length > 0) {
        for (const location of employeeLocations) {
          const locationName = isEnglish ? location.name : location.nameAr;
          locationsData.push(locationName);
        }
      } else {
        locationsData.push("-");
      }

      modifiedEmployee["locations"] = locationsData.toString();
    }

    if (fields.includes("phoneNumber")) {
      modifiedEmployee["phoneNumber"] = employee.employee.phoneNumber ?? "-";
    }

    if (fields.includes("totalWorkingHours")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // let employeeAttendances = employee.employee.attendances.filter(
      //   (attendance) => {
      //     return (
      //       new Date(attendance.checkIn).getMonth() ===
      //         new Date(monthToUse).getMonth() &&
      //       new Date(attendance.checkIn).getFullYear() ===
      //         new Date(monthToUse).getFullYear()
      //     );
      //   },
      // );
      // let totalMins = 0;
      // let totalHours = 0;
      // employeeAttendances.forEach((el) => {
      //   if (el.checkIn && el.checkOut) {
      //     let diffInMins =
      //       (new Date(el.checkOut).getTime() - new Date(el.checkIn).getTime()) /
      //       1000 /
      //       60;
      //     if (diffInMins < 0) {
      //       diffInMins += 1440;
      //     }
      //     modifiedEmployee["employeeWorkingHours"] += Number(diffInMins);
      //   }
      // });
      // totalMins += Number(modifiedEmployee["employeeWorkingHours"]);
      // // Convert totalMins to hours and minutes
      // totalHours += Math.floor(totalMins / 60);
      // totalMins %= 60;
      // modifiedEmployee["totalWorkingHours"] = `${totalHours}:${
      //   Math.round(totalMins) < 10
      //     ? "0" + Math.round(totalMins)
      //     : Math.round(totalMins)
      // }`;

      let totalMins = employee.totalWorkingTime;
      let totalHours = Math.floor(totalMins / 60);
      totalMins %= 60;

      modifiedEmployee["totalWorkingHours"] = `${totalHours}:${
        Math.round(totalMins) < 10
          ? "0" + Math.round(totalMins)
          : Math.round(totalMins)
      }`;
    }

    if (fields.includes("expectedWorkingHours")) {
      // let employeeExpectedWorkingHours = 0;
      // modifiedEmployee["employeeExpectedWorkingHours"] +=
      //   employee.workingDays * employee.employee.company.workHoursPerDay;
      // employeeExpectedWorkingHours =
      //   modifiedEmployee["employeeExpectedWorkingHours"];
      // modifiedEmployee[
      //   "expectedWorkingHours"
      // ] = `${employeeExpectedWorkingHours}:00`;

      let expectedWorkingHours =
        (employee.workingDays - employee.daysOff) *
        employee.employee.company.workHoursPerDay;
      modifiedEmployee["expectedWorkingHours"] = `${expectedWorkingHours}:00`;
    }

    if (fields.includes("earningsDetails")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // let earningsDetails = getEarningsList(
      //   employee.employee.salaryModifications,
      //   employee.employee.recurringSalaryModifications,
      //   monthToUse,
      // );
      // if (multipleMonths) {
      //   modifiedEmployee["earningsDetails"] += `${earningsDetails}`;
      // } else {
      //   modifiedEmployee["earningsDetails"] = `${earningsDetails}`;
      // }
      let earningsDetails = getEarningsList(
        employee.modifications,
        employee.recurringModifications,
      );

      modifiedEmployee["earningsDetails"] = `${earningsDetails}`;
    }

    if (fields.includes("deductionsDetails")) {
      // let monthToUse = multipleMonths
      //   ? moment(employee.month, "yyyy-MM")
      //   : month;
      // let deductionsDetails = getDeductionsList(
      //   employee.employee.salaryModifications,
      //   employee.employee.recurringSalaryModifications,
      //   monthToUse,
      // );
      // if (multipleMonths) {
      //   modifiedEmployee["deductionsDetails"] += `${deductionsDetails}`;
      // } else {
      //   modifiedEmployee["deductionsDetails"] = `${deductionsDetails}`;
      // }
      let deductionsDetails = getDeductionsList(
        employee.modifications,
        employee.recurringModifications,
      );

      if (employee.unpaid.days > 0) {
        deductionsDetails += `** - (${employee.unpaid.days}) days - Unpaid Leave: ${Number(employee.unpaid.deduction).toFixed(3)} \n`;
      }

      modifiedEmployee["deductionsDetails"] = `${deductionsDetails}`;
    }

    if (fields.includes("resignation")) {
      modifiedEmployee["resignation"] = Number(
        employee.hypotheticalIndemnities.resignation,
      );
    }

    if (fields.includes("termination")) {
      modifiedEmployee["termination"] = Number(
        employee.hypotheticalIndemnities.termination,
      );
    }

    if (fields.includes("vacationAccrual")) {
      modifiedEmployee["vacationAccrual"] = Number(
        employee.hypotheticalIndemnities.accrual.vacation,
      );
    }

    normalizedEmployees[employeeId] = modifiedEmployee;
  }
  for (let emp in normalizedEmployees) {
    let employee = normalizedEmployees[emp];
    for (let key in employee) {
      if (!fields.includes(key)) {
        delete employee[key];
      }
    }
  }

  return Object.values(normalizedEmployees);
};

export const getHeaders = (employeeObj, t, isEnglish) => {
  if (employeeObj) {
    const headers = Object.keys(employeeObj);

    const translatedHeaders = headers.map((head) => t(head));

    if (isEnglish) {
      return translatedHeaders;
    } else {
      return translatedHeaders.reverse();
    }
  }
};

export const getBody = (employees, isEnglish) => {
  const employeeValues = employees.map((employee) => Object.values(employee));

  if (isEnglish) {
    return employeeValues;
  } else {
    let reversedValues = employeeValues.map((arrayValue) => {
      arrayValue.reverse();
      return arrayValue;
    });
    return reversedValues;
  }
};

export const getEarnings = (modifications, recurringModifications) => {
  let earnings = 0;
  modifications.forEach((modification) => {
    if (Number(modification.amount) >= 0) {
      earnings += +Number(modification.amount);
    }
  });
  recurringModifications.forEach((modification) => {
    if (Number(modification.amount) >= 0) {
      earnings += +Number(modification.amount);
    }
  });
  // modifications.forEach((modification) => {
  //   let modificationMonth = moment(modification.month, "yyyy-MM");
  //   if (
  //     Number(modification.amount) >= 0 &&
  //     modificationMonth.isSame(month, "month")
  //   ) {
  //     earnings += +Number(modification.amount);
  //   }
  // });
  // recurringModifications.forEach((modification) => {
  //   let modificationStartMonth = moment(modification.startDate, "yyyy-MM");
  //   let modificationEndMonth = modification.endDate
  //     ? moment(modification.endDate, "yyyy-MM")
  //     : null;
  //   if (
  //     Number(modification.amount) >= 0 &&
  //     modificationEndMonth === null &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     earnings += +Number(modification.amount);
  //   } else if (
  //     Number(modification.amount) >= 0 &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     earnings += +Number(modification.amount);
  //   } else if (
  //     Number(modification.amount) >= 0 &&
  //     modificationStartMonth.isBefore(month, "month") &&
  //     (modificationEndMonth === null ||
  //       modificationEndMonth.isAfter(month, "month"))
  //   ) {
  //     earnings += +Number(modification.amount);
  //   }
  // });
  return Number(earnings);
};

export const getEarningsList = (modifications, recurringModifications) => {
  let earningsList = "";
  let i = 1;
  modifications.forEach((modification) => {
    if (Number(modification.amount) >= 0) {
      let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
      earningsList += earning;
      i++;
    }
  });
  recurringModifications.forEach((modification) => {
    if (Number(modification.amount) >= 0) {
      let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
      earningsList += earning;
      i++;
    }
  });
  // modifications.forEach((modification) => {
  //   let modificationMonth = moment(modification.month, "yyyy-MM");
  //   if (
  //     Number(modification.amount) >= 0 &&
  //     modificationMonth.isSame(month, "month")
  //   ) {
  //     let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     earningsList += earning;
  //     i++;
  //   }
  // });
  // recurringModifications.forEach((modification) => {
  //   let modificationStartMonth = moment(modification.startDate, "yyyy-MM");
  //   let modificationEndMonth = modification.endDate
  //     ? moment(modification.endDate, "yyyy-MM")
  //     : null;
  //   if (
  //     Number(modification.amount) >= 0 &&
  //     modificationEndMonth === null &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     earningsList += earning;
  //     i++;
  //   } else if (
  //     Number(modification.amount) >= 0 &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     earningsList += earning;
  //     i++;
  //   } else if (
  //     Number(modification.amount) >= 0 &&
  //     modificationStartMonth.isBefore(month, "month") &&
  //     (modificationEndMonth === null ||
  //       modificationEndMonth.isAfter(month, "month"))
  //   ) {
  //     let earning = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     earningsList += earning;
  //     i++;
  //   }
  // });
  return earningsList;
};

export const getDeductions = (modifications, recurringModifications) => {
  let deductions = 0;
  modifications.forEach((modification) => {
    if (Number(modification.amount) < 0) {
      deductions += +Number(modification.amount);
    }
  });
  recurringModifications.forEach((modification) => {
    if (Number(modification.amount) < 0) {
      deductions += +Number(modification.amount);
    }
  });

  // modifications.forEach((modification) => {
  //   let modificationMonth = moment(modification.month, "yyyy-MM");
  //   if (
  //     Number(modification.amount) < 0 &&
  //     modificationMonth.isSame(month, "month")
  //   ) {
  //     deductions += +Number(modification.amount);
  //   }
  // });
  // recurringModifications.forEach((modification) => {
  //   let modificationStartMonth = moment(modification.startDate, "yyyy-MM");
  //   let modificationEndMonth = modification.endDate
  //     ? moment(modification.endDate, "yyyy-MM")
  //     : null;
  //   if (
  //     Number(modification.amount) < 0 &&
  //     modificationEndMonth === null &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     deductions += +Number(modification.amount);
  //   } else if (
  //     Number(modification.amount) < 0 &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     deductions += +Number(modification.amount);
  //   } else if (
  //     Number(modification.amount) < 0 &&
  //     modificationStartMonth.isBefore(month, "month") &&
  //     (modificationEndMonth === null ||
  //       modificationEndMonth.isAfter(month, "month"))
  //   ) {
  //     deductions += +Number(modification.amount);
  //   }
  // });

  return Number(deductions);
};

export const getDeductionsList = (modifications, recurringModifications) => {
  let deductionsList = "";
  let i = 1;
  modifications.forEach((modification) => {
    if (Number(modification.amount) < 0) {
      let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
      deductionsList += deduction;
      i++;
    }
  });
  recurringModifications.forEach((modification) => {
    if (Number(modification.amount) < 0) {
      let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
      deductionsList += deduction;
      i++;
    }
  });
  // modifications.forEach((modification) => {
  //   let modificationMonth = moment(modification.month, "yyyy-MM");
  //   if (
  //     Number(modification.amount) < 0 &&
  //     modificationMonth.isSame(month, "month")
  //   ) {
  //     let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     deductionsList += deduction;
  //     i++;
  //   }
  // });
  // recurringModifications.forEach((modification) => {
  //   let modificationStartMonth = moment(modification.startDate, "yyyy-MM");
  //   let modificationEndMonth = modification.endDate
  //     ? moment(modification.endDate, "yyyy-MM")
  //     : null;
  //   if (
  //     Number(modification.amount) < 0 &&
  //     modificationEndMonth === null &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     deductionsList += deduction;
  //     i++;
  //   } else if (
  //     Number(modification.amount) < 0 &&
  //     modificationStartMonth.isSame(month, "month")
  //   ) {
  //     let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     deductionsList += deduction;
  //     i++;
  //   } else if (
  //     Number(modification.amount) < 0 &&
  //     modificationStartMonth.isBefore(month, "month") &&
  //     (modificationEndMonth === null ||
  //       modificationEndMonth.isAfter(month, "month"))
  //   ) {
  //     let deduction = `${i} - ${modification.reason}: ${modification.amount} \n`;
  //     deductionsList += deduction;
  //     i++;
  //   }
  // });
  return deductionsList;
};

export const getGrossSalary = (
  modifications = [],
  recurringModifications = [],
  month,
  baseSalary = 0,
) => {
  let earnings = getEarnings(modifications, recurringModifications);
  let deductions = getDeductions(modifications, recurringModifications);
  return Number(earnings) + Number(baseSalary) + Number(deductions);
};
