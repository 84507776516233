//Material UI
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import { CustomTableCell, CustomTableRow } from "../common";
import React, { useState } from "react";
import { deleteLeaveCredit, deleteLeaveDebit } from "../../redux/actions";

import { CompanyStructureModal } from "../../components/common";
import { DeleteOutline } from "@material-ui/icons";
import { TablePagination } from "../../redesign/components/common";
import { connect } from "react-redux";
import { getFileName } from "../../helpers/getFileName";
import { useTranslation } from "react-i18next";

const RequestsTable = ({
  labels,
  data,
  toRow,
  type = "vacation",
  icon,
  title,
  canDeleteRecord = false,
  forLeaveCredit = false,
  forLeaveDebit = false,
  deleteLeaveCredit,
  deleteLeaveDebit,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [requestId, setRequestId] = useState();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setRequestId(id);
  };

  const handleDeleteLeaveRecord = (leaveId) => {
    if (forLeaveCredit) {
      deleteLeaveCredit({ id: leaveId }, () => setOpenDeleteModal(false));
    } else {
      deleteLeaveDebit({ id: leaveId }, () => setOpenDeleteModal(false));
    }
  };

  const headerTitles = labels.map((label, i) => (
    <CustomTableCell key={(label, +i)}>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align={isEnglish ? "left" : "right"}
      >
        {label}
      </Typography>
    </CustomTableCell>
  ));

  let rows = [];
  if (data.length !== 0) {
    const fields = Object.keys(data[0]);

    rows = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((request, i) => {
        return (
          <CustomTableRow
            to={toRow ? `${toRow}/${request.id}` : null}
            key={(request.id, +i)}
          >
            {fields.map((field) => {
              const getDocumentElement = () => {
                if (field === "document" && request[field] !== null) {
                  const documentName = getFileName(request[field]);
                  return (
                    <a
                      href={request[field]}
                      target="_blank"
                      download
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {documentName}
                    </a>
                  );
                } else if (field === "document" && request[field] === null) {
                  return <Typography>-</Typography>;
                } else {
                  return request[field];
                }
              };

              return (
                <CustomTableCell align={isEnglish ? "left" : "right"}>
                  {getDocumentElement()}
                </CustomTableCell>
              );
            })}
            {canDeleteRecord && (
              <CustomTableCell>
                <IconButton onClick={() => handleOpenDeleteModal(request.id)}>
                  <DeleteOutline color="error" />
                </IconButton>
              </CustomTableCell>
            )}
          </CustomTableRow>
        );
      });
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 5);
    setPage(0);
  };

  return (
    <>
      <Box width={"100%"} borderRadius={15} bgcolor="white">
        <Box
          display="flex"
          flexDirection="row"
          width={"100%"}
          alignItems="center"
          justifyContent="flex-start"
          paddingTop={3}
        >
          {icon}
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <CustomTableRow>{headerTitles}</CustomTableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
          {data.length === 0 && (
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography color="primary" align="center">
                {t("noRecords")}
              </Typography>
            </Box>
          )}
        </TableContainer>
        <TablePagination
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <CompanyStructureModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        fadeIn={openDeleteModal}
        title={t("deleteLeaveRecord")}
      >
        <Grid container spacing={3} style={{ margin: 4 }}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("common:leaveRecordDeleteConfirm")}
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            paddingX={2}
            paddingY={2}
          >
            <Grid item container md={12} sm={12} xs={11}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeleteLeaveRecord(requestId)}
                >
                  {t("common:confirm")}
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </CompanyStructureModal>
    </>
  );
};

const mapDispatchToProps = {
  deleteLeaveCredit,
  deleteLeaveDebit,
};

export default connect(null, mapDispatchToProps)(RequestsTable);
