//MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";
import {
  FormInputField,
  FormSelectInput,
} from "../../../../../components/common";
import React, { useEffect, useState } from "react";

import CompanyField from "../../../../../components/pages/CompanyProfile/CompanyField";
import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CompanyInformation = ({
  company,
  updateCompanyProfile,
  sectors,
  edit,
}) => {
  const [companyState, setCompany] = useState({
    ...company,
    sectorId: company?.sector?.id,
  });
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  useEffect(() => {
    setCompany({ ...company, sectorId: company?.sector?.id });
  }, [company]);

  if (!company)
    return (
      <Typography variant="h3" color="textPrimary" align="center">
        Loading
      </Typography>
    );

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" color="textPrimary">
              {t("companyProfile:companyInformation")}
            </Typography>
          </Grid>
          <CompanyField>
            <FormInputField
              isRequired
              label={t("common:name")}
              id="name"
              name="name"
              onChange={(e) => {
                setCompany({ ...companyState, name: e.target.value });
              }}
              onBlur={(e) =>
                updateCompanyProfile({
                  ...companyState,
                  name: e.target.value,
                })
              }
              value={companyState.name}
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              label={t("common:arabicName")}
              id="nameAr"
              name="nameAr"
              onChange={(e) => {
                setCompany({ ...companyState, nameAr: e.target.value });
              }}
              onBlur={(e) =>
                updateCompanyProfile({
                  ...companyState,
                  nameAr: e.target.value,
                })
              }
              value={companyState.nameAr}
              autoComplete="new-password"
            />
          </CompanyField>

          <CompanyField>
            <FormSelectInput
              name="sector"
              isRequired={true}
              value={companyState.sectorId}
              selectOptions={sectors.map((sector) => ({
                label: isEnglish ? sector.name : sector.nameAr,
                value: sector.id,
              }))}
              onChange={(e) => {
                setCompany({ ...companyState, sectorId: e });
                updateCompanyProfile({
                  ...companyState,
                  sectorId: e,
                });
              }}
              label={t("companyProfile:sector")}
            />
          </CompanyField>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
  sectors: company.sectors,
});

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
