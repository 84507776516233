import { Chip, MenuItem, Typography } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import FormInputField from "./FormInputField";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

export default function AutoCompleteCategories({
  array,
  onChange = () => {},
  errors,
  helperText,
  value,
  disabled,
  label,
  excludeFlags = false,
  ...inputProps
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const options = array.map((option) => {
    return {
      sorting:
        option.sort === "gcc"
          ? t("common:gcc")
          : option.sort === "common"
          ? t("common:common")
          : excludeFlags
          ? ""
          : t("common:other"),
      ...option,
    };
  });

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiIconButton-root": {
        color: theme.palette.primary.main,
      },
    },
    select: {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      justifyContent: isEnglish ? "flex-start" : "flex-end",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    groupLabel: {
      textAlign: isEnglish ? "left" : "right",
    },
  }));

  const classes = useStyles();

  return (
    <Autocomplete
      className={classes.root}
      onChange={(_e, v) => {
        if (v) {
          onChange(v.value ? v.value : v);
        } else {
          onChange(v);
        }
      }}
      id="grouped-demo"
      classes={{
        option: classes.select,
        groupLabel: classes.groupLabel,
      }}
      disabled={disabled}
      options={options}
      groupBy={(option) => option.sorting}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option) => option.value}
      value={options.find((option) => option.value === value)}
      {...inputProps}
      renderOption={(option) => (
        <MenuItem className={classes.select}>
          {!excludeFlags && <span>{countryToFlag(option.value)}</span>}
          <Typography>{option.label}</Typography>
          {option.hrAccess && (
            <Chip
              label={t("hasHrAccess")}
              style={{
                textTransform: "none",
                marginLeft: 5,
                marginRight: 5,
                fontSize: "12px",
              }}
            />
          )}
        </MenuItem>
      )}
      renderInput={(params) => {
        params.inputProps.autoComplete = "new-password";
        return (
          <FormInputField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            helperText={helperText}
            errors={errors}
            label={label}
            {...params}
          />
        );
      }}
    />
  );
}
