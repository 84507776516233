import { Box } from "@material-ui/core";
import { CompanyStructureModal } from "../../../../common";
import LocationLoaded from "../LocationLoaded";
import LocationNotLoaded from "../LocationNotLoaded";
import React from "react";
import UserLocationDenied from "../UserLocationDenied";

const CheckInModal = ({
  t,
  location,
  openCheckInModal,
  onClickCheckIn,
  onClickCancel,
  onClickLoaded,
  onClickNotLoaded,
  onCloseModal,
}) => {
  return (
    <CompanyStructureModal
      open={openCheckInModal}
      onClose={onCloseModal}
      fadeIn={openCheckInModal}
      title={
        !("allowed" in location)
          ? t("userLocationDenied")
          : t("locationLoading")
      }
    >
      <Box margin={4}>
        {!("allowed" in location) && (
          <UserLocationDenied
            onClickCancel={onClickCancel}
            onClickCheck={onClickCheckIn}
          />
        )}
        {location.loaded && "allowed" in location && (
          <LocationLoaded onClick={onClickLoaded} />
        )}
        {!location.loaded && <LocationNotLoaded onClick={onClickNotLoaded} />}
      </Box>
    </CompanyStructureModal>
  );
};

export default CheckInModal;
