import { Button, Grid } from "@material-ui/core";
import {
  createDocumentFiles,
  createDocuments,
  deleteDocumentFile,
  updateDocument,
} from "../../../../../../redux/actions";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const CompanyDocumentsButtons = ({
  documentState,
  setDocumentState,
  setOpen,
  modifiedDocument,
  setModifiedDocument,
  modifiedFiles,
  setModifiedFiles,
  employeeId,
  setAlert,
}) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    if (documentState !== null) {
      setOpen(false);
      setDocumentState(null);
    } else {
      setOpen(false);
      setModifiedDocument({
        name: "",
        exp: null,
        location: "",
      });
      setModifiedFiles([]);
    }
  };

  const onSubmit = async () => {
    if (documentState !== null) {
      const { id, name, exp, location } = modifiedDocument;

      const locationValue = location?.id ?? location;

      dispatch(
        updateDocument({
          id,
          name,
          exp,
          locationId: locationValue,
        }),
      );

      const modifiedFilesIds = modifiedFiles
        .filter((file) => file.id)
        .map((file) => file.id);

      const filesToRemove = documentState.files.filter(
        (file) => !modifiedFilesIds.includes(file.id),
      );

      if (filesToRemove.length > 0) {
        for (const existingFile of filesToRemove) {
          const { id } = existingFile;
          dispatch(
            deleteDocumentFile({
              id,
              employeeId,
            }),
          );
        }
      }

      const filesToAdd = modifiedFiles.filter((file) => file.uuid);

      if (filesToAdd.length > 0) {
        for (const newFile of filesToAdd) {
          const { file } = newFile;
          dispatch(
            createDocumentFiles([{ file, documentId: modifiedDocument.id }]),
          );
        }
      }
    } else {
      const { name, exp, location } = modifiedDocument;

      const filesToAdd = modifiedFiles.filter((file) => file.uuid);
      const locationValue = location?.id ?? location;
      console.log(locationValue);
      dispatch(
        createDocuments([
          {
            name,
            exp,
            locationId: locationValue === "" ? null : locationValue,
          },
        ]),
      ).then((documents) => {
        for (const newFile of filesToAdd) {
          const { file } = newFile;

          dispatch(
            createDocumentFiles([{ file, documentId: documents[0].id }]),
          );
        }
      });
    }
    if (modifiedDocument.exp === null) {
      setAlert(true);
    } else {
      setAlert(false);
      handleCloseModal();
    }
  };

  return (
    <Grid item container direction="row-reverse" xs={12} lg={12}>
      <Grid item container justifyContent="flex-end" xs={6} lg={2}>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("common:confirm")}
        </Button>
      </Grid>
      <Grid item container alignItems="flex-end" xs={6} lg={2}>
        <Button variant="outlined" onClick={handleCloseModal}>
          {t("common:cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};

// const mapStateToProps = ({ company }) => ({
//   documents: company.documents,
// });

// const mapDispatchToProps = {
//   updateDocument,
//   createDocumentFiles,
//   updateDocumentFile,
//   deleteDocumentFile,
//   deleteDocument,
//   createDocuments,
// };

export default CompanyDocumentsButtons;
