import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Actions
import { resetChangePassword } from "../../../redux/actions";

// Layout
import { Grid, Button, Typography, Box } from "@material-ui/core";

// Icons
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router";
import AuthenticationInput from "../../common/AuthenticationInput";

const schema = yup.object().shape({
  newPassword1: yup.string().required(),
  newPassword2: yup.string().required(),
});

const Form = ({ resetChangePassword, errorFields }) => {
  const [success, setSuccess] = useState("");
  const [visible, setVisible] = useState(false);
  const { token } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation(["login", "companyProfile"]);

  const isEnglish = language === "en";

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ newPassword1, newPassword2 }) => {
    resetChangePassword({ newPassword1, newPassword2, token }, () =>
      setSuccess(t("companyProfile:passwordChanged"))
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} data-test="login-form">
        <Grid container justifyContent="space-between" spacing={2}>
          <Box clone pb={2}>
            <Grid item md={12}>
              <Typography
                variant="h2"
                color="primary"
                align={isEnglish ? "left" : "right"}
              >
                {t("resetPassword")}
              </Typography>
            </Grid>
          </Box>
          {success && (
            <Grid item md={12} xs={11}>
              <Alert severity="success" onClick={() => setSuccess("")}>
                {success}
              </Alert>
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <AuthenticationInput
                name="newPassword1"
                error={!!errors.newPassword1}
                helperText={errors.newPassword?.message}
                inputRef={register}
                label={t("companyProfile:newPassword")}
                type={visible ? "text" : "password"}
                icon={
                  visible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )
                }
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(!visible)}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <AuthenticationInput
                name="newPassword2"
                error={!!errors.newPassword2}
                helperText={errors.newPassword2?.message}
                inputRef={register}
                label={t("companyProfile:confirmNewPassword")}
                margin="normal"
                type={visible ? "text" : "password"}
                icon={
                  visible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )
                }
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(!visible)}
              />
            </Box>
          </Grid>
          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}
          <Grid item md={6} xs={5}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ExitToAppRoundedIcon />}
              style={{
                color: "#FFF",
                backgroundColor: "#3D5E77",
              }}
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = ({ errors }) => ({
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = { resetChangePassword };

export default connect(mapStateToProps, mapDispatchToProps)(Form);
