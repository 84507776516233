import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { CompanyStructureModal, Section } from "../../common";
import React, { useState } from "react";

import Field from "./Field";
import MultilineInput from "../../common/MultilineInput";
import { Redirect } from "react-router";
import { connect } from "react-redux";
//Actions
import { updateProfileUpdateRequest } from "../../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

const UpdateProfile = ({
  update,
  request,
  updateProfileUpdateRequest,
  user,
  employeeProfile,
}) => {
  const { t } = useTranslation("requests");
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [notes, setNotes] = useState("");
  if (update.old === null || update.new === null)
    return <Redirect to="/requests" />;
  const hasHrAccess =
    user.isEmployee && employeeProfile && employeeProfile.hrAccess;
  let keys = Object.keys(update.old);

  let oldFields = [];

  keys.forEach((field) => {
    if (field === "image") {
      oldFields.push(
        <>
          <Box clone minHeight={"100px"}>
            <Grid item md={6} xs={12}>
              <Typography color="primary">{t(`common:${field}`)}</Typography>
            </Grid>
          </Box>
          <Box clone height={"100%"}>
            <Grid item md={6} xs={12}>
              {update.old.image ? (
                <img
                  src={update.old.image}
                  alt="old"
                  height={"80px"}
                  width={"80px"}
                  // eslint-disable-next-line react/style-prop-object
                  style={{ objectFit: "contain" }}
                />
              ) : (
                "-"
              )}
            </Grid>
          </Box>
        </>
      );
    } else if (field !== "poc" && field !== "address") {
      oldFields.push(
        <Field
          key={`address-${field}`}
          md={6}
          xs={12}
          label={t(`common:${field}`)}
          value={update.old[field]}
        />
      );
    } else if (field === "poc") {
      oldFields.push(
        <Grid item key={"old poc"} md={12} xs={12}>
          <Typography varient="h4" color="secondary">
            {t("common:poc")}
          </Typography>
        </Grid>
      );

      Object.keys(update.old.poc).forEach((pocField) => {
        if (pocField !== "address") {
          oldFields.push(
            <Field
              key={`poc-${pocField}`}
              md={6}
              xs={12}
              label={t(`common:${pocField}`)}
              value={update.old.poc[pocField]}
            />
          );
        } else {
          Object.keys(update.old.poc.address).forEach((pocAddressField) =>
            oldFields.push(
              <Field
                key={`poc-address-${pocAddressField}`}
                md={6}
                xs={12}
                label={t(`common:${pocAddressField}`)}
                value={update.old.poc.address[pocAddressField]}
              />
            )
          );
        }
      });
    } else if (field === "address") {
      oldFields.push(
        <Grid key={"old address"} item md={12} xs={12}>
          <Typography varient="h4" color="secondary">
            {t("common:address")}
          </Typography>
        </Grid>,
        ...Object.keys(update.old.address).map((addressField) => (
          <Field
            key={`old-address-${addressField}`}
            md={6}
            xs={12}
            label={t(`common:${addressField}`)}
            value={update.old.address[addressField]}
          />
        ))
      );
    }
  });

  let newFields = [];

  keys.forEach((field) => {
    if (field === "image") {
      newFields.push(
        <>
          <Box clone minHeight={"100px"}>
            <Grid item md={6} xs={12}>
              <Typography color="primary">{t(`common:${field}`)}</Typography>
            </Grid>
          </Box>
          <Box clone height={"100%"}>
            <Grid item md={6} xs={12}>
              {update.new.image ? (
                <img
                  src={update.new.image}
                  alt="new"
                  height={"80px"}
                  width={"80px"}
                  // eslint-disable-next-line react/style-prop-object
                  style={{ objectFit: "contain" }}
                />
              ) : (
                "-"
              )}
            </Grid>
          </Box>
        </>
      );
    } else if (field !== "poc" && field !== "address") {
      newFields.push(
        <Field
          key={`${field}-new`}
          md={6}
          xs={12}
          label={t(`common:${field}`)}
          value={update.new[field]}
        />
      );
    } else if (field === "poc") {
      newFields.push(
        <Grid key={"new poc"} item md={12} xs={12}>
          <Typography varient="h4" color="secondary">
            {t("common:poc")}
          </Typography>
        </Grid>
      );

      Object.keys(update.new.poc).forEach((pocField, idx) => {
        if (pocField !== "address") {
          newFields.push(
            <Field
              key={`${pocField}-poc-new-${idx}`}
              md={6}
              xs={12}
              label={t(`common:${pocField}`)}
              value={update.new.poc[pocField]}
            />
          );
        } else {
          Object.keys(update.new.poc.address).forEach((pocAddressField) =>
            newFields.push(
              <Field
                key={`${pocAddressField}-poc-address-new`}
                md={6}
                xs={12}
                label={t(`common:${pocAddressField}`)}
                value={update.new.poc.address[pocAddressField]}
              />
            )
          );
        }
      });
    } else if (field === "address") {
      newFields.push(
        <Grid key={"new address"} item md={12} xs={12}>
          <Typography varient="h4" color="secondary">
            {t("common:address")}
          </Typography>
        </Grid>,
        ...Object.keys(update.new.address).map((addressField) => (
          <Field
            key={`${addressField}-new-address`}
            md={6}
            xs={12}
            label={t(`common:${addressField}`)}
            value={update.new.address[addressField]}
          />
        ))
      );
    }
  });

  return (
    <Section>
      <Box clone bgcolor="white" borderRadius={10} marginY={10}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={12}>
            <Typography variant="h3" color="primary">
              {t("updateDetails")}
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography color="secondary" variant="h4">
                  {t("currentValue")}
                </Typography>
              </Grid>
              {oldFields}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Box clone display="flex" flexDirection="row" alignItems="center">
              <Grid item md={1}>
                <Box height={"90%"} borderLeft={1}></Box>
              </Grid>
            </Box>
          </Hidden>
          <Grid item md={5} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography color="secondary" variant="h4">
                  {t("updatedValue")}
                </Typography>
              </Grid>
              {newFields}
            </Grid>
          </Grid>
          {(user.isCompanyOwner || hasHrAccess) && (
            <>
              {request.status === "PENDING" && (
                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box clone marginRight={1}>
                      <Button
                        onClick={() => setOpen(true)}
                        variant="contained"
                        color="primary"
                      >
                        <Typography>{t("approve")} </Typography>
                      </Button>
                    </Box>
                    <Box clone marginRight={1}>
                      <Button
                        onClick={() => setOpenDecline(true)}
                        variant="contained"
                        color="secondary"
                      >
                        <Typography>{t("decline")} </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <CompanyStructureModal
        open={open}
        onClose={() => setOpen(false)}
        fadeIn={open}
        title={t("approveUpdate")}
      >
        <Grid container direction="column">
          <Grid item md={12} style={{ margin: 10 }}>
            <MultilineInput
              fullWidth
              multiline
              rows={2}
              label={t("notes")}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              placeholder="optional"
            />
          </Grid>
          <Grid item md={12} style={{ margin: 10 }}>
            <Box
              paddingY={4}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateProfileUpdateRequest(
                    {
                      employeeId: request.employee.id,
                      id: request.id,
                      status: "APPROVED",
                      notes,
                    },
                    () => setOpen(false)
                  )
                }
              >
                <Typography>{t("yes")} </Typography>
              </Button>

              <Button
                onClick={() => setOpen(false)}
                variant="contained"
                color="secondary"
              >
                <Typography>{t("no")} </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
      <CompanyStructureModal
        open={openDecline}
        onClose={() => setOpenDecline(false)}
        fadeIn={openDecline}
        title={t("approveUpdate")}
      >
        <Grid container direction="column">
          <Grid item md={12} style={{ margin: 10 }}>
            <MultilineInput
              fullWidth
              multiline
              rows={2}
              label={t("notes")}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              placeholder="optional"
            />
          </Grid>
          <Grid item md={12} style={{ margin: 10 }}>
            <Box
              paddingY={4}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  updateProfileUpdateRequest(
                    {
                      employeeId: request.employee.id,
                      id: request.id,
                      status: "DECLINED",
                      notes,
                    },
                    () => setOpenDecline(false)
                  );
                }}
              >
                <Typography>{t("yes")} </Typography>
              </Button>

              <Button
                onClick={() => setOpenDecline(false)}
                variant="contained"
                color="secondary"
              >
                <Typography>{t("no")} </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
    </Section>
  );
};

const mapStateToProps = ({ auth, employee }) => ({
  user: auth.user,
  employeeProfile: employee.profile,
});

const mapDispatchToProps = {
  updateProfileUpdateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);
