import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { changePassword, resetErrors } from "../../../redux/actions";

import { Alert } from "@material-ui/lab";
import { FormInputField } from "../../../components/common";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Password = ({ user, changePassword, resetErrors, errorFields }) => {
  const { t } = useTranslation(["common", "employeeProfile", "breadcrumbs"]);
  const [alert, setAlert] = useState("");
  const [data, setData] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  return (
    <Card>
      <CardHeader title={t("changePassword")} />
      <CardContent>
        {alert && (
          <Grid item md={12} xs={12}>
            <Alert severity="success" onClick={() => setAlert("")}>
              {alert}
            </Alert>
          </Grid>
        )}

        <Grid container>
          <Grid
            item
            container
            md={12}
            xs={11}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item md={6} xs={11}>
              <FormInputField
                disabled={!user.verified}
                isRequired
                type="password"
                label={t("oldPassword")}
                name="oldPassword"
                onChange={(e) =>
                  setData({ ...data, oldPassword: e.target.value })
                }
                value={data.oldPassword}
                error={data.oldPassword === ""}
                id="oldPassword"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={11} style={{ marginTop: 10, marginBottom: 10 }}>
            <FormInputField
              disabled={!user.verified}
              isRequired
              type="password"
              label={t("newPassword")}
              name="newPassword1"
              onChange={(e) =>
                setData({ ...data, newPassword1: e.target.value })
              }
              value={data.newPassword1}
              error={data.newPassword1 === ""}
              id="newPassword1"
              autoComplete="new-password"
            />
          </Grid>

          <Grid item md={6} xs={11} style={{ marginTop: 10, marginBottom: 10 }}>
            <FormInputField
              disabled={!user.verified}
              isRequired
              type="password"
              label={t("confirmNewPassword")}
              name="newPassword2"
              onChange={(e) =>
                setData({ ...data, newPassword2: e.target.value })
              }
              value={data.newPassword2}
              error={data.newPassword2 === ""}
              id="newPassword2"
              autoComplete="new-password"
            />
          </Grid>

          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography align="center" style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}

          <Grid
            item
            md={12}
            xs={12}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    data.oldPassword !== "" &&
                    data.newPassword1 !== "" &&
                    data.newPassword2 !== ""
                  ) {
                    resetErrors();
                    changePassword(data, user, () =>
                      setAlert(t("passwordChanged"))
                    );
                  }
                }}
              >
                {t("changePassword")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ auth, errors }) => ({
  user: auth.user,
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = {
  resetErrors,
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
