import "jspdf-autotable";

import jsPDF from "jspdf";
import moment from "moment";

import { getPdfTemplate } from "../../../../../helpers/getPdfTemplate";

export const createPayslip = (
  t,
  isEnglish,
  profile,
  earnings,
  deductions,
  recurringEarnings,
  recurringDeductions,
  totalEarnings,
  totalDeductions,
  month,
) => {
  const pdf = new jsPDF();
  var pdfWidth = pdf.internal.pageSize.width;
  const pageWidth = pdfWidth || pdf.internal.pageSize.getWidth();
  const currentHeight = 15;
  const selectedMonth = moment(month);
  console.log("month:", month);
  const hasSubtitle = true;

  getPdfTemplate(
    pdf,
    pageWidth,
    pdfWidth,
    currentHeight,
    t,
    isEnglish,
    profile.company.name,
    profile.company.logo,
    hasSubtitle,
  );

  const hiringDateTitle = t("common:hiringDate");
  const hiringDate = profile.employmentDate;
  pdf.setFontSize(9);
  pdf.text(pdfWidth - 50, currentHeight + 43, hiringDateTitle, "left");
  pdf.text(pdfWidth - 30, currentHeight + 43, hiringDate, "left");

  const locationTitle = t("common:locationField");
  const location = `${
    profile.location
      ? profile.location.name
      : profile.locations.length > 0
      ? profile.locations[0].name
      : "-"
  } / ${profile.department.name}`;
  pdf.setFontSize(9);
  pdf.text(15, currentHeight + 43, locationTitle, "left");
  pdf.text(35, currentHeight + 43, location, "left");

  const positionTitle = t("common:positionField");
  const position = profile.position.name;
  pdf.setFontSize(9);
  pdf.text(15, currentHeight + 50, positionTitle, "left");
  pdf.text(35, currentHeight + 50, position, "left");

  const monthTitle = t("common:monthField");
  const selectedMonthValue = selectedMonth.format("MMM-YYYY");
  pdf.setFontSize(9);
  pdf.text(pdfWidth - 50, currentHeight + 50, monthTitle, "left");
  pdf.text(pdfWidth - 30, currentHeight + 50, selectedMonthValue, "left");

  const basicSalaryTitle = t("common:basicSalaryField");
  const basicSalary = `${profile.baseSalary}`;
  pdf.setFontSize(9);
  pdf.text(pdfWidth - 50, currentHeight + 57, basicSalaryTitle, "left");
  pdf.text(pdfWidth - 30, currentHeight + 57, basicSalary, "left");

  const bankName = profile.bankName ?? "";
  const employeeName = profile.user
    ? profile.user?.fullName
    : profile?.fullName;

  const getModificationCell = (title) => {
    return { content: title };
  };
  const getValueCell = (value) => {
    return { content: value, styles: { halign: "right" } };
  };
  const emptyModificationCell = {
    content: " ".repeat(33),
  };

  const emptyValueCell = {
    content: " ".repeat(4),
    styles: { halign: "right" },
  };

  let earningsData = [
    [getModificationCell(t("baseSalary")), getValueCell(profile.baseSalary)],
  ];

  earnings.map((earning) =>
    earningsData.push([
      getModificationCell(earning.reason),
      getValueCell(earning.amount),
    ]),
  );

  recurringEarnings.map((earning) =>
    earningsData.push([
      getModificationCell(earning.reason),
      getValueCell(earning.amount),
    ]),
  );

  let deductionsData = [];

  deductions.map((deduction) =>
    deductionsData.push([
      getModificationCell(deduction.reason),
      getValueCell(Math.abs(deduction.amount)),
    ]),
  );

  recurringDeductions.map((deduction) =>
    deductionsData.push([
      getModificationCell(deduction.reason),
      getValueCell(Math.abs(deduction.amount)),
    ]),
  );

  const getBody = () => {
    let body = [
      [
        {
          content: t("common:income"),
          colSpan: 2,
          styles: { halign: "center" },
        },
        {
          content: t("common:deductions"),
          colSpan: 2,
          styles: { halign: "center" },
        },
      ],
    ];

    const emptyRow = [
      emptyModificationCell,
      emptyValueCell,
      emptyModificationCell,
      emptyValueCell,
    ];

    let totalIncome = Number(profile.baseSalary) + Number(totalEarnings);
    const totalsRow = [
      getModificationCell(t("common:totalIncome")),
      getValueCell(totalIncome.toFixed(3)),
      getModificationCell(t("common:totalDeductions")),
      getValueCell(Math.abs(totalDeductions)),
    ];
    let netSalary =
      Number(profile.baseSalary) +
      Number(totalEarnings) +
      Number(totalDeductions);
    const depositDetails = [
      {
        content: isEnglish
          ? `${t("common:netSalaryInBank")} (${selectedMonth.format(
              "MMM",
            )}) : ${netSalary.toFixed(3)} KWD ${bankName}`
          : `${t("common:netSalaryInBank")} لشهر ${selectedMonth.format(
              "MMM",
            )} : ${netSalary.toFixed(3)} دينار كويتي `,
        colSpan: 4,
        styles: { halign: "center" },
      },
    ];

    const earningsLength = earningsData.length;
    const deductionsLength = deductionsData.length;

    if (earningsLength > deductionsLength) {
      for (let i = 0; i < earningsData.length; i++) {
        if (!deductionsData[i]) {
          body.push([
            ...earningsData[i],
            emptyModificationCell,
            emptyValueCell,
          ]);
        } else {
          body.push([...earningsData[i], ...deductionsData[i]]);
        }
      }
    }

    if (deductionsLength > earningsLength) {
      for (let j = 0; j < deductionsData.length; j++) {
        if (!earningsData[j]) {
          body.push([
            emptyModificationCell,
            emptyValueCell,
            ...deductionsData[j],
          ]);
        } else {
          body.push([...earningsData[j], ...deductionsData[j]]);
        }
      }
    }

    if (earningsLength === deductionsLength) {
      for (let i = 0; i < earningsData.length; i++) {
        body.push([...earningsData[i], ...deductionsData[i]]);
      }
    }

    body.push(emptyRow);
    body.push(emptyRow);
    body.push(emptyRow);
    body.push(totalsRow);
    body.push(depositDetails);

    return body;
  };
  if (!isEnglish) {
  }

  const headStyles = isEnglish
    ? { halign: "left" }
    : { font: "Amiri-Regular", halign: "right" };

  const bodyStyles = isEnglish
    ? { halign: "left" }
    : { font: "Amiri-Regular", halign: "right" };

  pdf.autoTable({
    startY: currentHeight + 70,
    styles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
    },
    theme: "grid",
    body: [...getBody()],
    headStyles: headStyles,
    bodyStyles: bodyStyles,
  });

  pdf.save(`${employeeName}-payslip.pdf`);
};
