//Material UI
import { Box, Menu, MenuItem } from "@material-ui/core";

import { Link } from "react-router-dom";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const ViewMoreAction = ({
  idEmployees,
  classes,
  openEmployees,
  anchorEl,
  department,
  closeViewMore,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  return (
    <Menu
      id={idEmployees}
      open={openEmployees}
      anchorEl={anchorEl.employees}
      onClose={closeViewMore}
      style={{ zIndex: 12 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isEnglish ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: isEnglish ? "left" : "right",
      }}
    >
      <Box
        clone
        width="100px"
        height="10px"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <MenuItem
          component={Link}
          to={`/employeesList?department=${department.id}&location=&status=&order=null&orderBy=null&position=`}
          variant="subtitle2"
          color="primary"
          style={{ textDecoration: "none" }}
          className={classes.menuItem}
        >
          {t("common:viewMore")}
        </MenuItem>
      </Box>
    </Menu>
  );
};

export default ViewMoreAction;
