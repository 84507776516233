import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

import AttendanceSystem from "./AttendanceSystem";
import AutoDeduct from "./AutoDeduct";
import Overtime from "./Overtime";
import React from "react";
import { useTranslation } from "react-i18next";

const AttendanceAndOvertimeSettings = ({ company }) => {
  const { t } = useTranslation("companyProfile");
  return (
    <Card>
      <CardHeader title={t("overtimeAndAttendanceSettings")} />
      <CardContent>
        <Grid container spacing={3}>
          <Overtime company={company} />
          <AutoDeduct company={company} />
          <AttendanceSystem company={company} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AttendanceAndOvertimeSettings;
