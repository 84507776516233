import {
  Button,
  Card,
  CardHeader,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import { CompanyStructureModal } from "../../../../../../components/common";
import Department from "./Department";
import DepartmentForm from "./Forms/DepartmentForm";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Departments = ({ departments }) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(["common", "companyProfile"]);
  const departmentsList = departments.map((department) => (
    <Department department={department} key={department.id} />
  ));

  return (
    <>
      <Card>
        <CardHeader
          title={t("departments")}
          action={
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              {t("companyProfile:addDepartment")}
            </Button>
          }
        />
        {/*       
        <CardContent> */}
        {departmentsList}
        {/* </CardContent> */}
      </Card>

      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("companyProfile:addDepartment")}
        height={matchesSM ? "350px" : "300px"}
      >
        <DepartmentForm setOpen={setOpenModal} />
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  departments: company.departments,
});

export default connect(mapStateToProps)(Departments);
