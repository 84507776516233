import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";

import React from "react";
import SearchEmployees from "./SearchEmployees";
import { useTranslation } from "react-i18next";

const RequestsAccess = () => {
  const { t } = useTranslation(["companyProfile", "common"]);
  return (
    <Card>
      <CardHeader
        title={t("companyProfile:requestAccess")}
        subheader={t("companyProfile:requestAccessDescription")}
      />
      <CardContent>
        <Typography variant="h5" color="primary">
          {t("common:employeeName")}
        </Typography>

        <SearchEmployees />
      </CardContent>
    </Card>
  );
};

export default RequestsAccess;
