import {
  Avatar,
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles'
import { useEffect, useState } from "react";
//Translation
import {useTranslation} from 'react-i18next'

const FormImageField = ({
  label,
  name,
  onChange,
  imageId,
  image,
  isRequired = false,
  disabled = false,
}) => {
  const {
    i18n: {language},
  } = useTranslation('common')
  const isEnglish = language === 'en'

  const useStyles = makeStyles(
    theme => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },

      formControlRoot: {
        padding: 3,
        '& .MuiFormLabel-root': {
          color: theme.palette.text.secondary,
          fontSize: '1rem',
          fontWeight: 500,
        },
        '& .MuiInputLabel-formControl': {
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
      },
    }),
    {index: 1},
  )

  const [file, setFile] = useState({src: '', name: ''})

  const processImage = newImage => {
    const reader = new FileReader()
    reader.onload = ({target: {result}}) =>
      setFile({src: result, name: newImage.name})
    reader.readAsDataURL(newImage)
  }

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setFile({src: image, name: image.split('/').pop()})
      } else {
        processImage(image)
      }
    }
  }, [image])

  const handleChange = e => {
    const {files} = e.target
    if (files.length) processImage(files[0])
    onChange(e)
  }

  const classes = useStyles()

  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      <Input
        id={imageId}
        label={label}
        type="file"
        inputProps={{ accept: "image/*" }}
        onChange={handleChange}
        name={name}
        style={{ display: "none" }}
        required={isRequired}
        disabled={disabled}
      />

      <FormLabel
        htmlFor={imageId}
        style={disabled ? {} : { cursor: "pointer" }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item md={2}>
            <Box
              clone
              width="100%"
              height="100%"
              borderRadius={file.src ? 0 : 100}
            >
              <Avatar
                src={file.src || "/images/profile_pic_default.pn"}
                alt="Logo"
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            {file.name && (
              <Typography variant="h4" color="textPrimary">
                {file.name}
              </Typography>
            )}
            <Typography
              style={{ textDecoration: "underline" }}
              variant="subtitle2"
              color="textSecondary"
            >
              {label}
            </Typography>
          </Grid>
        </Grid>
      </FormLabel>
    </FormControl>
  );
}

export default FormImageField
