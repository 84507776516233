import AutoCheckout from "./AutoCheckout";
import CameraCheckIn from "./CameraCheckIn";
import CheckinDetails from "./CheckinDetails";
import CheckinLimits from "./CheckinLimits";
import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CheckinSettings = ({ edit }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Typography variant="h2" color="textPrimary">
        {t("checkInSettings")}
      </Typography>
      <CameraCheckIn edit={edit} />
      <CheckinDetails edit={edit} />
      <CheckinLimits edit={edit} />
      <AutoCheckout edit={edit} />
    </>
  );
};

export default CheckinSettings;
