import Departments from "./Departments/index";
import { Grid } from "@material-ui/core";
import Locations from "./Locations";
import React from "react";

const LocationsAndDepartments = () => {
  return (
    <Grid container spacing={8}>
      <Grid item lg={6} xs={12}>
        <Locations />
      </Grid>
      <Grid item lg={6} xs={12}>
        <Departments />
      </Grid>
    </Grid>
  );
};

export default LocationsAndDepartments;
