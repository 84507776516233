export const calculateAttendanceDuration = (attendance) => {
  if (attendance.checkIn && attendance.checkOut) {
    let diffInMins =
      (new Date(attendance.checkOut).getTime() -
        new Date(attendance.checkIn).getTime()) /
      1000 /
      60;
    let hours = diffInMins / 60;
    let fullHours = Math.floor(hours).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let mins = (hours - fullHours) * 60;
    let fullMins = Math.round(mins).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${fullHours}:${fullMins}`;
  } else {
    return "-";
  }
};
