import {FormControl, InputAdornment, TextField} from '@material-ui/core'

import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'

const AuthenticationInput = ({
  error,
  inputRef,
  helperText,
  name,
  label,
  icon,
  type,
  style,
  onClick,
}) => {
  const {
    i18n: {language},
  } = useTranslation()

  const isEnglish = language === 'en'
  const useStyles = makeStyles(
    theme => ({
      error: {
        '& ..MuiFormHelperText-root': {
          color: 'red',
        },

        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,
      },
      formControlRoot: {
        justifyContent: 'flex-start',
        padding: 4,
        '& .MuiFormLabel-root': {
          color: theme.palette.primary.main,
          fontSize: '1rem',
          fontWeight: 500,
        },
        '& .MuiInputLabel-shrink': {
          transformOrigin: isEnglish ? 'top left' : 'top right',
        },
        '& .MuiInputLabel-formControl': {
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish && 0,
          right: isEnglish ? null : 0,
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid #002A6E',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
        '& .MuiInputBase-input MuiInput-input Mui-disabled Mui-disabled': {
          backgroundColor: 'transparent',
        },

        '& .MuiInputBase-root.Mui-disabled': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },

        '& .MuiInputBase-root.Mui-disabled:after': {
          backgroundColor: 'transparent',
        },

        '& .MuiFormControl-root MuiTextField-root': {
          backgroundColor: 'transparent',
        },
        '& ..MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        '& .MuiFormHelperText-root': {
          textAlign: isEnglish ? 'left' : 'right',
        },
      },
    }),
    {index: 1},
  )

  const classes = useStyles()
  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      <TextField
        error={error}
        inputRef={inputRef}
        helperText={helperText}
        name={name}
        label={label}
        type={type}
        margin="normal"
        InputProps={{
          className: classes.input,
          endAdornment: (
            <InputAdornment style={style} onClick={onClick} position="start">
              {icon}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  )
}

export default AuthenticationInput
