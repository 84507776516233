import { Box } from "@material-ui/core";
import Breadcrumb from "./Breadcrumb";
import PageTitle from "./PageTitle";
import React from "react";

const PageHeader = ({ paths, title, paddingTop, paddingBottom }) => {
  return (
    <Box
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      // paddingX={!lgUp && 3}
    >
      {paths && <Breadcrumb paths={paths} />}
      {title && <PageTitle title={title} />}
    </Box>
  );
};

export default PageHeader;
