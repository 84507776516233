import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  deleteDocument,
  deleteEmployeeDocument,
} from "../../../../../redux/actions";

import { CompanyStructureModal } from "../../../../../components/common";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const DeleteDocumentModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  documentState,
  setDocumentState,
  deleteDocument,
  deleteEmployeeDocument,
  employeeId,
}) => {
  const { t } = useTranslation("common");
  console.log(documentState);
  console.log(employeeId);
  const handleDelete = () => {
    console.log("Deleting document...");
    if (!employeeId) {
      console.log("Deleting document without employeeId");
      deleteDocument({ id: documentState?.id });
    } else {
      console.log("Deleting document with employeeId", employeeId);
      deleteEmployeeDocument({ id: documentState?.id, employeeId: employeeId });
    }
  };

  return (
    <CompanyStructureModal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      fadeIn={openDeleteModal}
      title={t("common:documentDeleteConfirm").replace("{document}", "")}
    >
      <Box height={"auto"} maxHeight="90vh" margin={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("common:documentDeleteConfirm").replace(
                "this document {document}",
                documentState?.name,
              )}
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            paddingX={2}
            paddingY={2}
          >
            <Grid item container md={12} sm={12} xs={11}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleDelete();
                    setOpenDeleteModal(false);
                  }}
                >
                  {t("common:confirm")}
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CompanyStructureModal>
  );
};

const mapDispatchToPros = {
  deleteDocument,
  deleteEmployeeDocument,
};

export default connect(null, mapDispatchToPros)(DeleteDocumentModal);
