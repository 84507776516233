export const filterEmployees = (
  employees,
  department,
  position,
  status,
  query,
  location,
) => {
  let departmentEmployees = employees.filter((employee) => {
    if (department === "All") {
      return employee;
    }
    return employee.department.id === department;
  });
  let positionEmployees = departmentEmployees.filter((employee) => {
    if (position === "All") {
      return employee;
    }
    return employee.position.id === position;
  });
  let locationEmployees = positionEmployees.filter((employee) => {
    if (location === "All") {
      return employee;
    }
    const hasEmployees =
      employee.locations.filter((loc) => loc.id === location).length > 0;
    return hasEmployees;
  });
  let statusEmployee = locationEmployees.filter((employee) => {
    if (status === "All") {
      return employee;
    }
    return employee.employmentStatus === status;
  });

  return statusEmployee.filter((employee) => {
    const name = employee.user ? employee.user?.fullName : employee?.fullName;
    return (
      name.toLowerCase().includes(query.toLowerCase()) ||
      employee.id.includes(query)
    );
  });
};

export const getTotalToBeDeposited = (
  total,
  selectedAllowances,
  earnings,
  deductions,
) => {
  let totalAllowances = selectedAllowances
    .filter((allowance) => !allowance.receiveDuringVacation)
    .map((allowance) => allowance.amount)
    .reduce((partialSum, a) => partialSum + +a, 0);

  // let totalEarnings = earnings
  //   .map((earning) => earning.amount)
  //   .reduce((partialSum, a) => partialSum + +a, 0);

  // let totalDeductions = deductions
  //   .map((deduction) => deduction.amount)
  //   .reduce((partialSum, a) => partialSum + +a, 0);

  return Number(total) + Number(totalAllowances);
};
