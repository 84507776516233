import React from "react";
import { Link } from "@material-ui/core";

// Translation
import { useTranslation } from "react-i18next";
import { StyledNavLink } from "./stlyes";

const LanguageButton = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <StyledNavLink
      component={Link}
      underline="none"
      onClick={() => {
        i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
      }}
    >
      {t("language")}
    </StyledNavLink>
  );
};

export default LanguageButton;
