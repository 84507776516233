import { Button, SvgIcon } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";

import { ActionsPopover } from "./ActionsPopover";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export const ActionsButton = (props) => {
  const { t } = useTranslation("common");
  const { actions } = props;
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopoverOpen = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpenPopover(false);
  }, []);

  return (
    <>
      <Button
        size="small"
        onClick={handlePopoverOpen}
        ref={anchorRef}
        endIcon={
          <SvgIcon>
            <KeyboardArrowDown />
          </SvgIcon>
        }
        variant="contained"
        color="primary"
        style={{ textTransform: "none" }}
      >
        {t("actions")}
      </Button>
      <ActionsPopover
        t={t}
        actions={actions}
        anchorEl={anchorRef.current}
        onClose={handlePopoverClose}
        open={openPopover}
      />
    </>
  );
};
