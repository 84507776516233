import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";

import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const StyledHomeTable = ({ header, body, data, requestsLoading }) => {
  const { t } = useTranslation(["common"]);
  return (
    <StyledTableContainer>
      {requestsLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ paddingLeft: 0 }}>{header}</TableRow>
            </TableHead>
            <TableBody style={{ minHeight: "100%" }}>{body}</TableBody>
          </Table>
          {data.length === 0 && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              minHeight={"100%"}
            >
              <Typography color="primary" align="center">
                {t("common:noRecords")}
              </Typography>
            </Box>
          )}
        </>
      )}
    </StyledTableContainer>
  );
};

const mapStateToProps = ({ requests }) => ({
  requestsLoading: requests.requestsLoading,
});

export default connect(mapStateToProps)(StyledHomeTable);

export const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: "25vh",
  maxWidth: "100%",
}));
