import { Box, Button, Grid, Typography } from "@material-ui/core";
//Components
import { FormInputField, Section } from "../../../common";

import { Add } from "@material-ui/icons/";
import React from "react";
import RecurringSalaryModification from "./RecurringSalaryModification";
//Date
import moment from "moment";
//Translation
import { useTranslation } from "react-i18next";

let COUNTER = 0;

const Salary = ({ state, setState, handleChange, validation }) => {
  const { t } = useTranslation(["common", "employeeAdd"]);

  const onChange = (id) => (eventOrValue) => {
    const name = eventOrValue.target?.name ?? "month";
    const value =
      eventOrValue.target?.value ?? moment(eventOrValue).format("yyyy-MM-DD");

    setState((state) => ({
      ...state,
      recurringSalaryModifications: state.recurringSalaryModifications.map(
        (salaryModification) =>
          salaryModification.id !== id
            ? salaryModification
            : { ...salaryModification, [name]: value }
      ),
    }));
  };

  const handleAdd = () =>
    setState((state) => ({
      ...state,
      recurringSalaryModifications: state.recurringSalaryModifications.concat({
        id: ++COUNTER,
        amount: null,
        reason: "",
        month: null,
      }),
    }));

  const handleRemove = (id) => () =>
    setState((state) => ({
      ...state,
      recurringSalaryModifications: state.recurringSalaryModifications.filter(
        (allowance) => allowance.id !== id
      ),
    }));

  const salaryModifications = state.recurringSalaryModifications.map(
    (salaryModification) => (
      <RecurringSalaryModification
        key={salaryModification.id}
        state={salaryModification}
        onChange={onChange(salaryModification.id)}
        handleRemove={handleRemove(salaryModification.id)}
      />
    )
  );

  return (
    <Section bgcolor="white">
      <Grid container>
        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              type="text"
              label={t("common:bankName")}
              name="bankName"
              onChange={handleChange}
              value={state.bankName}
              id="bankName"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              type="number"
              label={t("common:iban")}
              name="iban"
              onChange={handleChange}
              value={state.iban}
              id="iban"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={1}>
          <Grid item md={6}>
            <FormInputField
              helperText={t("common:salaryHelper")}
              errors={validation && validation[t("common:baseSalary")]}
              type="number"
              label={t("common:salary")}
              name="baseSalary"
              onChange={handleChange}
              value={state.baseSalary}
              id="baseSalary"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item md={10}>
            <Typography>
              {t("employeeAdd:recurringSalaryModifications")}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Button variant="text" onClick={handleAdd}>
              <Add />
            </Button>
          </Grid>
        </Grid>
        <Grid container>{salaryModifications}</Grid>
      </Grid>
    </Section>
  );
};

export default Salary;
