import { Box, Grid, useMediaQuery } from "@material-ui/core";
//Components
import { FormInputField, Section } from "../../../common";

import AutocompleteCategoriesInput from "../../../common/AutocompleteCategoriesInput";
import React from "react";
//Data
import { countries } from "../../../common/DropDownsData";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const ContactInformation = ({
  state,
  handleChange,
  handleValueChange,
  extraFields,
}) => {
  const { t } = useTranslation("common");

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const paddingY = matchesMD ? 0 : 3;

  const filteredCountries = (t) => {
    return countries(t).slice(1);
  };

  return (
    <Section bgcolor="white">
      <Grid container spacing={3}>
        {extraFields}
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <AutocompleteCategoriesInput
              label={t("country")}
              name="country"
              onChange={handleValueChange("country")}
              array={filteredCountries(t)}
              value={state.country}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("phoneNumber")}
              name="phoneNumber"
              onChange={handleChange}
              value={state.phoneNumber}
              id="phoneNumber"
              autoComplete="new-password"
              inputProps={{ maxLength: 11 }}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("address1")}
              name="address1"
              onChange={handleChange}
              value={state.address1}
              id="address1"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("address2")}
              name="address2"
              onChange={handleChange}
              value={state.address2}
              id="address2"
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("cityOrTown")}
              name="cityOrTown"
              onChange={handleChange}
              value={state.cityOrTown}
              id="cityOrTown"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("paciNumber")}
              name="paciNumber"
              onChange={handleChange}
              value={state.paciNumber}
              id="paciNumber"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
      </Grid>
    </Section>
  );
};

export default ContactInformation;
