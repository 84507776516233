import React from "react";

//MaterialUI
import { Grid } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";
import { FormImageField } from "../../../../common";

const ProfileImage = ({ profile, edit, updatedProfile, setUpdatedProfile }) => {
  const { t } = useTranslation("common");

  return (
    <Grid item>
      <FormImageField
        disabled={!edit}
        label={t("uploadEmployeeImage")}
        name="image"
        imageId={"one"}
        image={updatedProfile.image ? updatedProfile.image : profile.image}
        onChange={(e) =>
          setUpdatedProfile({ ...updatedProfile, image: e.target.files[0] })
        }
      />
    </Grid>
  );
};

export default ProfileImage;
