import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

/**
 * @description Loading component for ReportsModal
 * @returns {React.JSX.Element}
 * @constructor
 * @see ReportsModal
 */

const LoadingComponent = () => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <CircularProgress size={40} />
  </Box>
);

export default LoadingComponent;