import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { days as availableDays } from "../../../../DropDownsData";

const SelectDays = ({
  schedulesState,
  setSchedulesState,
  schedule,
  selectedDays,
  setSelectedDays,
  scheduleIdx,
}) => {
  const [currentScheduleDays, setCurrentScheduleDays] = useState(
    schedule?.days
  );

  const allDaysThatAreSelected = selectedDays.filter((day) => day.selected);

  const daysSelectedInCurrentSchedule = allDaysThatAreSelected
    .filter((day) => schedule?.days?.includes(day.value))
    .map((day) => day.value);

  const daysSelectedInOtherSchedules = allDaysThatAreSelected
    .filter((day) => !schedule?.days?.includes(day.value))
    .map((day) => day.value);

  const checkIfDayInOtherSchedule = (day) => {
    if (daysSelectedInOtherSchedules.includes(day)) return true;
    return false;
  };

  const checkIfDayInCurrentSchedule = (day) => {
    if (daysSelectedInCurrentSchedule.includes(day)) return true;
    return false;
  };

  const handleChange = (e) => {
    if (currentScheduleDays.includes(e.target.value)) {
      setCurrentScheduleDays((prevState) =>
        prevState.filter((day) => day !== e.target.value)
      );
    } else {
      setCurrentScheduleDays((prevState) => [...prevState, e.target.value]);
    }

    const newSelectedDays = selectedDays.map((day) => {
      if (day.value === e.target.value) {
        day.selected = !day.selected;
      }
      return day;
    });

    setSelectedDays(newSelectedDays);
  };

  useEffect(() => {
    let newCompanyScheduleState = schedulesState.map((sched, idx) => {
      if (idx === scheduleIdx) {
        return { ...schedule, days: currentScheduleDays };
      } else {
        return sched;
      }
    });

    setSchedulesState(newCompanyScheduleState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScheduleDays]);

  return (
    <Grid item md={4}>
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-checkbox-label">Days</InputLabel>
        <Select
          style={{ marginTop: 21 }}
          value={currentScheduleDays}
          onChange={handleChange}
          input={<Input />}
          renderValue={(values) => {
            const dayLabels = values
              .sort((a, b) => {
                return a - b;
              })
              .map((value) => {
                const day = availableDays?.find((day) => day.value === value);
                return day?.name.slice(0, 3);
              });
            return dayLabels.join(", ");
          }}
        >
          {selectedDays.map((day) => {
            return (
              <MenuItem
                disabled={checkIfDayInOtherSchedule(day.value)}
                key={day}
                value={day.value}
              >
                <Checkbox checked={checkIfDayInCurrentSchedule(day.value)} />
                <ListItemText primary={day.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SelectDays;
