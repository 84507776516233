import {
  ADD_EMPLOYEE,
  ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES,
  ADD_EMPLOYEES_MUTLIPLE_PAYROLL,
  ADD_EMPLOYEE_DOCUMENT,
  ADD_EMPLOYEE_DOCUMENT_FILE,
  ADD_EMPLOYEE_LOADING,
  ADD_LEAVE_CREDIT,
  ADD_LEAVE_DEBIT,
  ADD_PERMISSION_EMPLOYEE,
  ADD_RECURRING_SALARY_MODIFICATION,
  ADD_SALARY_MODIFICATION,
  DELETE_EMPLOYEE_DOCUMENT,
  DELETE_EMPLOYEE_DOCUMENT_FILE,
  DELETE_PERMISSION_EMPLOYEE,
  DELETE_RECURRING_SALARY_MODIFICATION,
  DELETE_SALARY_MODIFICATION,
  DEPOSITS,
  DOCUMENT_TYPES,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_ATTENDANCES,
  EDIT_EMPLOYEE_DOCUMENT,
  EDIT_EMPLOYEE_DOCUMENT_FILE,
  EDIT_EMPLOYEE_HR_ACCESS,
  EDIT_PERMISSION_EMPLOYEE,
  EDIT_RECURRING_SALARY_MODIFICATION,
  EDIT_SALARY_MODIFICATION,
  EMPLOYEE,
  EMPLOYEES,
  EMPLOYEES_PAYROLL,
  EMPLOYEES_WITH_HR_ACCESS,
  EMPLOYEE_ATTENDANCES,
  EMPLOYEE_LOADING,
  FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING,
  FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING,
  HYPOTHETICAL_INDEMNITIES,
  INDEMNITY,
  LEAVE_CREDIT_DEBIT_LOADING,
  NEXT_DEPOSIT,
  PERMISSION_EMPLOYEES,
  REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES,
  REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL,
  REMOVE_LEAVE_CREDIT,
  REMOVE_LEAVE_DEBIT,
  EMPLOYEES_LEAVE_BALANCE,
} from "../actions/types";

const initialState = {
  employees: [],
  addEmployeeLoading: false,

  employeesWithHrAccess: [],
  indemnity: null,
  deposits: [],
  nextDeposit: null,

  employeesDetails: [],
  leaveCreditDebitLoading: false,

  hypotheticalIndemnities: [],
  hypotheticalIndemnitiesLoading: true,
  employeeLoading: true,
  employeesLoading: true,
  documentTypes: [],

  attendances: [],
  attendancesLoading: true,
  multipleMonthsAttendances: [],
  multipleMonthsAttendancesLoading: false,

  payroll: [],
  payrollLoading: true,
  multipleMonthsPayroll: [],
  multipleMonthsPayrollLoading: false,

  permissionEmployees: [],

  employeesLeaveBalance: [],
};

export const employeesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EMPLOYEES:
      return {
        ...state,
        employees: payload,
        employeesLoading: false,
      };

    case PERMISSION_EMPLOYEES:
      return {
        ...state,
        permissionEmployees: payload,
      };
    case ADD_PERMISSION_EMPLOYEE:
      return {
        ...state,
        permissionEmployees: [...state.permissionEmployees, payload],
      };

    case EDIT_PERMISSION_EMPLOYEE:
      return {
        ...state,
        permissionEmployees: state.permissionEmployees.map((employee) => {
          if (employee.id === payload.id) {
            return payload;
          }
          return employee;
        }),
      };

    case DELETE_PERMISSION_EMPLOYEE:
      let newPermissionEmployees = state.permissionEmployees.filter(
        (employee) => employee.id !== payload,
      );
      return {
        ...state,
        permissionEmployees: newPermissionEmployees,
      };

    case EMPLOYEES_PAYROLL:
      return {
        ...state,
        payroll: payload,
        payrollLoading: false,
      };
    case EMPLOYEES_WITH_HR_ACCESS:
      return {
        ...state,
        employeesWithHrAccess: payload,
      };

    case EMPLOYEES_LEAVE_BALANCE:
      return {
        ...state,
        employeesLeaveBalance: payload,
      };

    case EDIT_EMPLOYEE_HR_ACCESS:
      return {
        ...state,
        employees: state.employees.map((employee) => {
          if (employee.id === payload.employeeId) {
            return { ...employee, hrAccess: payload.status };
          }
          return employee;
        }),
      };

    case ADD_EMPLOYEE:
      return {
        ...state,
        employees: [...state.employees, payload],
        addEmployeeLoading: false,
      };

    case ADD_EMPLOYEE_LOADING:
      return {
        ...state,
        addEmployeeLoading: payload,
      };

    case EDIT_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.map((employee) => {
          if (employee.id === payload.id) {
            return payload;
          }
          return employee;
        }),
        employeesDetails: state.employeesDetails.map((employee) => {
          if (employee.id === payload.id) {
            return payload;
          }
          return employee;
        }),
      };
    case ADD_EMPLOYEE_DOCUMENT:
      let employee = {
        ...state.employeesDetails.find((emp) => emp.id === payload.employee.id),
      };
      employee.documents = [...employee.documents, payload];

      return {
        ...state,
        employeesDetails: [
          ...state.employeesDetails.map((emp) => {
            if (emp.id === employee.id) {
              return employee;
            }
            return emp;
          }),
        ],
      };
    case EDIT_EMPLOYEE_DOCUMENT:
      let foundEmployee = {
        ...state.employeesDetails.find((emp) => emp.id === payload.employee.id),
      };
      foundEmployee.documents = foundEmployee.documents.map((document) => {
        if (document.id === payload.id) {
          return payload;
        }
        return document;
      });

      return {
        ...state,
        employeesDetails: [
          ...state.employeesDetails.map((emp) => {
            if (emp.id === foundEmployee.id) {
              return foundEmployee;
            }
            return emp;
          }),
        ],
      };

    case DELETE_EMPLOYEE_DOCUMENT:
      let ddEmployee = {
        ...state.employeesDetails.find((emp) => emp.id === payload.employeeId),
      };
      ddEmployee.documents = ddEmployee.documents.filter(
        (document) => document.id !== payload.id,
      );

      return {
        ...state,
        employeesDetails: [
          ...state.employeesDetails.map((emp) => {
            if (emp.id === ddEmployee.id) {
              return ddEmployee;
            }
            return emp;
          }),
        ],
      };

    case ADD_EMPLOYEE_DOCUMENT_FILE:
      let dfEmployee = {
        ...state.employeesDetails.find(
          (emp) => emp.id === payload.document.employee.id,
        ),
      };
      let document = {
        ...dfEmployee.documents.find((doc) => doc.id === payload.document.id),
      };
      document.files = [...document.files, payload];
      dfEmployee.documents = dfEmployee.documents.map((doc) => {
        if (doc.id === document.id) {
          return document;
        }
        return doc;
      });

      return {
        ...state,
        employeesDetails: [
          ...state.employeesDetails.map((emp) => {
            if (emp.id === dfEmployee.id) {
              return dfEmployee;
            }
            return emp;
          }),
        ],
      };
    case EDIT_EMPLOYEE_DOCUMENT_FILE:
      let dfeEmployee = {
        ...state.employeesDetails.find(
          (emp) => emp.id === payload.document.employee.id,
        ),
      };
      let fedocument = {
        ...dfeEmployee.documents.find((doc) => doc.id === payload.document.id),
      };
      fedocument.files = fedocument.files.map((file) => {
        if (file.id === payload.id) {
          return payload;
        }
        return file;
      });
      dfeEmployee.documents = dfeEmployee.documents.map((doc) => {
        if (doc.id === fedocument.id) {
          return fedocument;
        }
        return doc;
      });
      break;
    case DELETE_EMPLOYEE_DOCUMENT_FILE:
      let dfdEmployee = {
        ...state.employeesDetails.find((emp) => emp.id === payload.employeeId),
      };

      let fddocument = {
        ...dfdEmployee.documents.find((doc) => doc.id === payload.document.id),
      };

      fddocument.files = fddocument.files.filter(
        (file) => file.id !== payload.id,
      );
      dfdEmployee.documents = dfdEmployee.documents.map((doc) => {
        if (doc.id === fddocument.id) {
          return fddocument;
        }
        return doc;
      });

      return {
        ...state,
        employeesDetails: [
          ...state.employeesDetails.map((emp) => {
            if (emp.id === dfdEmployee.id) {
              return dfdEmployee;
            }
            return emp;
          }),
        ],
      };
    case EMPLOYEE:
      return {
        ...state,
        employeesDetails: [...state.employeesDetails, payload],
        employeeLoading: false,
      };
    case DEPOSITS:
      return {
        ...state,
        deposits: payload,
      };
    case NEXT_DEPOSIT:
      return {
        ...state,
        nextDeposit: payload,
      };
    case ADD_SALARY_MODIFICATION:
      return {
        ...state,
        nextDeposit: {
          ...state.nextDeposit,
          salaryModifications: [
            ...state.nextDeposit.salaryModifications,
            payload,
          ],
        },
      };
    case EDIT_SALARY_MODIFICATION:
      let salaryModifications = state.nextDeposit.salaryModifications.map(
        (modification) => {
          if (modification.id === payload.id) {
            return payload;
          }
          return modification;
        },
      );
      return {
        ...state,
        nextDeposit: { ...state.nextDeposit, salaryModifications },
      };

    case DELETE_SALARY_MODIFICATION:
      let newSalaryModifications = state.nextDeposit.salaryModifications.filter(
        (modification) => modification.id !== payload.id,
      );

      return {
        ...state,
        nextDeposit: {
          ...state.nextDeposit,
          salaryModifications: newSalaryModifications,
        },
      };
    case ADD_RECURRING_SALARY_MODIFICATION:
      return {
        ...state,
        nextDeposit: {
          ...state.nextDeposit,
          recurringSalaryModifications: [
            ...state.nextDeposit.recurringSalaryModifications,
            payload,
          ],
        },
      };
    case EDIT_RECURRING_SALARY_MODIFICATION:
      let recurringSalaryModifications =
        state.nextDeposit.recurringSalaryModifications.map((modification) => {
          if (modification.id === payload.oldId) {
            return payload;
          }
          return modification;
        });
      return {
        ...state,
        nextDeposit: { ...state.nextDeposit, recurringSalaryModifications },
      };

    case DELETE_RECURRING_SALARY_MODIFICATION:
      let newRecurringSalaryModifications =
        state.nextDeposit.recurringSalaryModifications.filter(
          (modification) => modification.id !== payload.id,
        );
      return {
        ...state,
        nextDeposit: {
          ...state.nextDeposit,
          recurringSalaryModifications: newRecurringSalaryModifications,
        },
      };
    case HYPOTHETICAL_INDEMNITIES:
      return {
        ...state,
        hypotheticalIndemnities: payload,
        hypotheticalIndemnitiesLoading: false,
      };
    case EMPLOYEE_LOADING:
      return {
        ...state,
        employeeLoading: true,
      };
    case INDEMNITY:
      return {
        ...state,
        indemnity: payload,
      };
    case DOCUMENT_TYPES:
      return { ...state, documentTypes: payload };
    case EMPLOYEE_ATTENDANCES:
      return {
        ...state,
        attendances: payload,
        attendancesLoading: false,
      };

    case EDIT_EMPLOYEE_ATTENDANCES:
      let newAttendances = state.attendances.map((attendace) => {
        if (attendace.id === payload.id) {
          return payload;
        }
        return attendace;
      });
      return {
        ...state,
        attendances: newAttendances,
      };
    case FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING:
      return {
        ...state,
        multipleMonthsPayrollLoading: payload,
      };
    case ADD_EMPLOYEES_MUTLIPLE_PAYROLL:
      return {
        ...state,
        multipleMonthsPayroll: [...state.multipleMonthsPayroll, payload],
      };

    case REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL:
      return {
        ...state,
        multipleMonthsPayroll: [],
      };

    case FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING:
      return {
        ...state,
        multipleMonthsAttendancesLoading: payload,
      };

    case ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES:
      return {
        ...state,
        multipleMonthsAttendances: [
          ...state.multipleMonthsAttendances,
          payload,
        ],
      };

    case REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES:
      return {
        ...state,
        multipleMonthsAttendances: [],
      };
    case ADD_LEAVE_CREDIT:
      const newLeavecredits = [
        ...state.employeesDetails[0].leaveBalance.leavecredits,
        payload,
      ];

      const newLeaveBalanceWithNewCredit = {
        ...state.employeesDetails[0].leaveBalance,
        leavecredits: newLeavecredits,
      };

      const employeDetailsWithNewCredit = [
        {
          ...state.employeesDetails[0],
          leaveBalance: newLeaveBalanceWithNewCredit,
        },
      ];

      return {
        ...state,
        employeesDetails: employeDetailsWithNewCredit,
        leaveCreditDebitLoading: false,
      };
    case REMOVE_LEAVE_CREDIT:
      const modifiedLeavecredits =
        state.employeesDetails[0].leaveBalance.leavecredits.filter(
          (leave) => leave.id !== payload.id,
        );

      const leaveBalance = {
        ...state.employeesDetails[0].leaveBalance,
        leavecredits: modifiedLeavecredits,
      };

      const newEmployeeDetails = [
        { ...state.employeesDetails[0], leaveBalance },
      ];

      return {
        ...state,
        employeesDetails: newEmployeeDetails,
        leaveCreditDebitLoading: false,
      };

    case ADD_LEAVE_DEBIT:
      const newLeavedebits = [
        ...state.employeesDetails[0].leaveBalance.leavedebits,
        payload,
      ];

      const newLeaveBalanceWithNewDebit = {
        ...state.employeesDetails[0].leaveBalance,
        leavedebits: newLeavedebits,
      };

      const employeDetailsWithNewDebit = [
        {
          ...state.employeesDetails[0],
          leaveBalance: newLeaveBalanceWithNewDebit,
        },
      ];

      return {
        ...state,
        employeesDetails: employeDetailsWithNewDebit,
        leaveCreditDebitLoading: false,
      };

    case REMOVE_LEAVE_DEBIT:
      const modifiedLeavedebits =
        state.employeesDetails[0].leaveBalance.leavedebits.filter(
          (leave) => leave.id !== payload.id,
        );

      const modifiedLeaveBalance = {
        ...state.employeesDetails[0].leaveBalance,
        leavedebits: modifiedLeavedebits,
      };

      const modifiedEmployeeDetails = [
        { ...state.employeesDetails[0], leaveBalance: modifiedLeaveBalance },
      ];

      return {
        ...state,
        employeesDetails: modifiedEmployeeDetails,
        leaveCreditDebitLoading: false,
      };

    case LEAVE_CREDIT_DEBIT_LOADING:
      return {
        ...state,
        leaveCreditDebitLoading: payload,
      };
    default:
      return state;
  }
};
