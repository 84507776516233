import {
  CartesianGrid,
  Line,
  LineChart as RechartLineChart,
  Tooltip,
  XAxis,
} from "recharts";

import React from "react";
import moment from "moment";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const LineChart = ({ deposits }) => {
  let depositsData = deposits
    .map((deposit) => ({
      name: moment(new Date(deposit.month)).format("DD-MMM-YYYY"),
      total: deposit.total,
    }))
    .reverse();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const width = lgUp ? 650 : 400;
  return (
    <RechartLineChart
      width={width}
      height={200}
      data={depositsData}
      margin={{
        top: 20,
        right: 20,
        left: 50,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis dataKey="name" tickLine={false} />
      <Tooltip />
      <Line
        type="natural"
        dataKey="total"
        strokeWidth="4"
        stroke="#0B2B5A"
        fill="#0B2B5A"
      />
    </RechartLineChart>
  );
};

export default LineChart;
