import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { deleteEmployeePortalPermission } from "../../../../../redux/actions";
import EditPermission from "./EditPermission";

const PermissionEmployeeItem = ({
  // openModal,
  // setOpenModal,
  employee,
  deleteEmployeePortalPermission,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const [open, setOpen] = useState(false);

  const deleteEmployeePermission = () => {
    deleteEmployeePortalPermission({
      employeeId: employee?.id,
      kinds: [],
      locations: [],
    });
  };

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item md={8}>
          <Typography
            variant="h3"
            style={{ fontWeight: "bold", color: "#E1E3E4" }}
          >
            {employee?.fullName}
          </Typography>
        </Grid>
        <Grid item container md={4}>
          <Grid item md={5}>
            <Button
              style={{ color: "red" }}
              variant="text"
              onClick={() => deleteEmployeePermission()}
            >
              {t("deleteEmployee")}
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              {t("edit")}
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2} md={6}>
          {employee?.hasAttendanceAccess && (
            <Grid item md={3}>
              <Button
                style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                variant="outlined"
                disabled
              >
                {t("attendanceAccess")}
              </Button>
            </Grid>
          )}

          {employee?.hasIndemnityAccess && (
            <Grid item md={3}>
              <Button
                style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                variant="outlined"
                disabled
              >
                {t("indemnityAccess")}
              </Button>
            </Grid>
          )}

          {employee?.hasDocumentAccess && (
            <Grid item md={3}>
              <Button
                style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                variant="outlined"
                disabled
              >
                {t("documentsAccess")}
              </Button>
            </Grid>
          )}

          {employee?.hasVacationsAccess && (
            <Grid item md={3}>
              <Button
                style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                variant="outlined"
                disabled
              >
                {t("vacationsAccess")}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={1} md={12}>
          {employee?.locations?.map((location) => {
            return (
              <Grid item>
                <Button
                  style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                  variant="outlined"
                  disabled
                >
                  {isEnglish ? location.name : location.nameAr}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid item md={12} style={{ marginTop: 5 }}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
      <EditPermission
        openModal={open}
        setOpenModal={setOpen}
        selectedEmployee={employee}
      />
    </>
  );
};

const mapDispatchToProps = {
  deleteEmployeePortalPermission,
};

export default connect(null, mapDispatchToProps)(PermissionEmployeeItem);
