//Icons
import { Add, Edit } from "@material-ui/icons";
//Material UI
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
//Redux
import { createLocation, updateLocation } from "../../../../../redux/actions";

//Components
import { FormInputField } from "../../../../common";
import { connect } from "react-redux";
//Styles
import useStyles from "../../styles";
//Translation
import { useTranslation } from "react-i18next";

const LocationForm = ({
  location,
  updateLocation,
  createLocation,
  setOpen,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);

  const isEnglish = language === "en";
  const [locationState, setLocation] = useState(
    location ? location : { name: "", nameAr: "", latitude: "", longitude: "" }
  );
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item container md={12} spacing={3} style={{ margin: 10 }}>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired={isEnglish}
            label={t("common:name")}
            value={locationState.name}
            onChange={(e) =>
              setLocation({ ...locationState, name: e.target.value })
            }
            id="name"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired={!isEnglish}
            label={t("common:arabicName")}
            value={locationState.nameAr}
            onChange={(e) =>
              setLocation({ ...locationState, nameAr: e.target.value })
            }
            id="arabicName"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired={!isEnglish}
            label={t("common:latitude")}
            value={locationState.latitude}
            onChange={(e) =>
              setLocation({ ...locationState, latitude: e.target.value })
            }
            id="arabicName"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired={!isEnglish}
            label={t("common:longitude")}
            value={locationState.longitude}
            onChange={(e) =>
              setLocation({ ...locationState, longitude: e.target.value })
            }
            id="arabicName"
            autoComplete="new-password"
          />
        </Grid>
      </Grid>

      <Box
        clone
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        paddingX={2}
        paddingY={2}
      >
        <Grid item md={12} sm={12} xs={11} style={{ margin: 10 }}>
          <Button
            disableRipple
            className={classes.actionButton}
            variant="contained"
            onClick={
              location
                ? () => {
                    updateLocation(
                      {
                        ...locationState,
                        locationId: location.id,
                      },
                      setOpen
                    );
                    setOpen(false);
                  }
                : () => {
                    createLocation(locationState, setOpen);
                    setOpen(false);
                  }
            }
            endIcon={location ? <Edit /> : <Add />}
          >
            <Typography>
              {location ? t("common:save") : t("common:add")}
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

const mapDispatchToProps = {
  createLocation,
  updateLocation,
};

export default connect(null, mapDispatchToProps)(LocationForm);
