import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";

import React from "react";
import SelectDays from "./SelectDays";
import Shifts from "./Shifts";
import { useTranslation } from "react-i18next";

const ScheduleItem = ({
  schedulesState,
  setSchedulesState,
  schedule,
  scheduleIdx,

  selectedDays,
  setSelectedDays,
}) => {
  const { t } = useTranslation("common");

  const handleRemoveWithState = (currentSchedule) => {
    setSchedulesState((prevState) =>
      prevState.filter((sched) => sched !== currentSchedule)
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>{t("schedule")}</Typography>
        </Box>
        {scheduleIdx !== 0 && (
          <Box>
            <Button
              variant="text"
              onClick={() => handleRemoveWithState(schedule)}
              style={{
                textTransform: "none",
              }}
            >
              {t("removeSchedule")}
            </Button>
          </Box>
        )}
      </Box>

      <Grid
        container
        justifyContent={"space-between"}
        alignItems="baseline"
        spacing={2}
      >
        <SelectDays
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          schedulesState={schedulesState}
          setSchedulesState={setSchedulesState}
          schedule={schedule}
          scheduleIdx={scheduleIdx}
        />

        <Shifts
          schedule={schedule}
          scheduleIdx={scheduleIdx}
          schedulesState={schedulesState}
          setSchedulesState={setSchedulesState}
        />
      </Grid>

      <Box paddingY={2}>
        <Divider variant="fullWidth" />
      </Box>
    </>
  );
};

export default ScheduleItem;
