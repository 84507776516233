import {
  ADDRESS,
  ADD_ANNOUNCEMENT,
  ADD_APPROVER_RULE,
  ADD_DEPARTMENT,
  ADD_DOCUMENT,
  ADD_DOCUMENT_FILE,
  ADD_EXPIRING_COMPANY_DOCUMENTS,
  ADD_HOLIDAY,
  ADD_LOCATION,
  ADD_POSITION,
  ADD_SCHEDULES,
  ALL_EMPLOYEE_DOCUMENTS,
  ANNOUNCEMENTS,
  APPROVER_RULES,
  COMPANY,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FILE,
  DEPARTMENTS,
  DOCUMENTS,
  DOCUMENT_TYPES,
  EDIT_APPROVER_RULE,
  EDIT_DEPARTMENT,
  EDIT_HOLIDAY,
  EDIT_LOCATION,
  EDIT_POSITION,
  EDIT_VACATION,
  EMPLOYEE_DOCUMENTS,
  EXPIRING_COMPANY_DOCUMENTS,
  LOCATIONS,
  POC,
  POC_ADDRESS,
  POSITIONS,
  REMOVE_APPROVER_RULE,
  REMOVE_DEPARTMENT,
  REMOVE_POSITION,
  RESOURCES,
  SECTORS,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_FILE,
} from "../actions/types";

const initialState = {
  profile: null,
  vacation: null,
  vacationLoading: true,
  schedules: [],
  documents: [],
  employeeDocuments: [],
  employeeDocumentsLoading: true,
  allEmployeeDocuments: [],
  allEmployeeDocumentsLoading: true,
  address: null,
  poc: { name: "", email: "", number: "" },
  pocAddress: null,
  resources: [],
  sectors: [],
  departments: [],
  locations: [],
  employees: [],
  positions: [],
  profileLoading: true,
  documentsLoading: true,
  addressLoading: true,
  pocLoading: true,
  pocAddressLoading: true,
  resourcesLoading: true,
  sectorsLoading: true,
  departmentsLoading: true,
  positionsLoading: true,
  announcements: [],
  expiringCompanyDocuments: [],
  documentTypes: [],
  approverRules: [],
};

export const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY:
      return {
        ...state,
        profile: payload,
        vacation: payload.vacation,
        schedules: payload.schedules,
        profileLoading: false,
        vacationLoading: false,
      };
    case ADD_SCHEDULES:
      return {
        ...state,
        schedules: payload,
      };
    case EXPIRING_COMPANY_DOCUMENTS:
      return {
        ...state,
        expiringCompanyDocuments: payload,
      };

    case ADD_EXPIRING_COMPANY_DOCUMENTS:
      return {
        ...state,
        expiringCompanyDocuments: [
          ...state.expiringCompanyDocuments,
          ...payload,
        ],
      };
    case DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: payload,
      };
    case ADDRESS:
      return { ...state, address: payload, addressLoading: false };
    case DEPARTMENTS:
      return {
        ...state,
        departments: payload,
        departmentsLoading: false,
      };
    case LOCATIONS:
      return {
        ...state,
        locations: payload,
      };
    case ADD_LOCATION:
      return { ...state, locations: [...state.locations, payload] };
    case EDIT_LOCATION:
      let editedLocations = state.locations.map((location) => {
        if (location.id === payload.id) {
          return payload;
        }
        return location;
      });
      return { ...state, locations: editedLocations };
    case POSITIONS:
      return { ...state, positions: payload, positionsLoading: false };
    case POC:
      return { ...state, poc: payload, pocLoading: false };
    case POC_ADDRESS:
      return { ...state, pocAddress: payload, pocAddressLoading: false };
    case SECTORS:
      return { ...state, sectors: payload, sectorsLoading: false };
    case DOCUMENTS:
      return { ...state, documents: payload, documentsLoading: false };

    case ADD_DOCUMENT:
      return { ...state, documents: [...state.documents, payload] };

    case ADD_DOCUMENT_FILE:
      let addFileToDocument = state.documents.map((doc) => {
        if (doc.id === payload.document.id) {
          return {
            ...doc,
            files: [...doc.files, payload],
          };
        }
        return doc;
      });
      return { ...state, documents: [...addFileToDocument] };
    case ADD_DEPARTMENT:
      return { ...state, departments: [...state.departments, payload] };

    case EDIT_DEPARTMENT:
      let editedDepartments = state.departments.map((department) => {
        if (department.id === payload.id) {
          return payload;
        }
        return department;
      });
      return { ...state, departments: editedDepartments };

    case REMOVE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter((dep) => dep.id !== payload.id),
      };
    case ADD_POSITION:
      let departments = state.departments.map((department) => {
        if (department.id === payload.department.id) {
          return {
            ...department,
            positions: [...department.positions, payload],
          };
        }
        return department;
      });
      return {
        ...state,
        positions: [...state.positions, payload],
        departments,
      };
    case EDIT_POSITION:
      let editedPositions = state.positions.map((position) => {
        if (position.id === payload.id) {
          return payload;
        }
        return position;
      });
      let updatedDepartments = state.departments.map((department) => {
        if (department.id === payload.department.id) {
          return {
            ...department,
            positions: department.positions.map((position) => {
              if (position.id === payload.id) {
                return payload;
              }
              return position;
            }),
          };
        }
        return department;
      });
      return {
        ...state,
        positions: editedPositions,
        departments: updatedDepartments,
      };

    case REMOVE_POSITION:
      return {
        ...state,
        positions: state.positions.filter((pos) => pos.id !== payload.id),
        departments: state.departments.map((dep) => {
          if (dep.id === payload.department.id) {
            return {
              ...dep,
              positions: dep.positions.filter((pos) => pos.id !== payload.id),
            };
          }
          return dep;
        }),
      };
    case EDIT_VACATION:
      return {
        ...state,
        vacation: payload,
        profile: { ...state.profile, vacation: payload },
      };
    case UPDATE_DOCUMENT:
      let array = [...state.documents];
      let index = array.findIndex((item) => item.id === payload.id);
      array[index] = payload;
      return { ...state, documents: [...array] };
    case UPDATE_DOCUMENT_FILE:
      let updateFileFromDocument = state.documents.map((doc) => {
        if (doc.id === payload.document.id) {
          return {
            ...doc,
            files: doc.files.map((file) => {
              if (file.id === payload.id) {
                return payload;
              }
              return file;
            }),
          };
        }
        return doc;
      });
      return { ...state, documents: [...updateFileFromDocument] };
    case DELETE_DOCUMENT:
      let filteredDocuments = state.documents.filter(
        (document) => document.id !== payload.id
      );
      return { ...state, documents: [...filteredDocuments] };
    case DELETE_DOCUMENT_FILE:
      let deleteFileFromDocument = state.documents.map((doc) => {
        if (doc.id === payload.documentId) {
          return {
            ...doc,
            files: doc.files.filter((file) => file.id !== payload.id),
          };
        }
        return doc;
      });
      return { ...state, documents: [...deleteFileFromDocument] };
    case RESOURCES:
      return {
        ...state,
        resources: payload,
        resourcesLoading: false,
      };
    case EMPLOYEE_DOCUMENTS:
      return {
        ...state,
        employeeDocuments: payload,
        employeeDocumentsLoading: false,
      };
    case ALL_EMPLOYEE_DOCUMENTS:
      return {
        ...state,
        allEmployeeDocuments: payload,
        allEmployeeDocumentsLoading: false,
      };
    case ANNOUNCEMENTS:
      return {
        ...state,
        announcements: payload,
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [payload, ...state.announcements],
      };
    case ADD_HOLIDAY:
      return {
        ...state,
        vacation: {
          ...state.vacation,
          officialHolidays: [payload, ...state.vacation.officialHolidays],
        },
        profile: {
          ...state.profile,
          vacation: {
            ...state.vacation,
            officialHolidays: [payload, ...state.vacation.officialHolidays],
          },
        },
      };
    case EDIT_HOLIDAY:
      return {
        ...state,
        vacation: {
          ...state.vacation,
          officialHolidays: [
            ...state.vacation.officialHolidays.map((holiday) => {
              if (holiday.id === payload.id) {
                return payload;
              }
              return holiday;
            }),
          ],
        },
        profile: {
          ...state.profile,
          vacation: {
            ...state.vacation,
            officialHolidays: [
              ...state.vacation.officialHolidays.map((holiday) => {
                if (holiday.id === payload.id) {
                  return payload;
                }
                return holiday;
              }),
            ],
          },
        },
      };

    case APPROVER_RULES:
      return {
        ...state,
        approverRules: payload,
      };

    case ADD_APPROVER_RULE:
      return {
        ...state,
        approverRules: [...state.approverRules, payload],
      };

    case REMOVE_APPROVER_RULE:
      let newApproverRules = state.approverRules.filter(
        (rule) => rule.id !== payload.approverRuleId
      );

      return { ...state, approverRules: newApproverRules };

    case EDIT_APPROVER_RULE:
      let updatedApproverRules = state.approverRules.map((rule) => {
        if (rule.id === payload.id) {
          return payload;
        } else {
          return rule;
        }
      });

      return { ...state, approverRules: updatedApproverRules };

    default:
      return state;
  }
};
