import { Box, Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import AttendanceTable from "../../../../common/AttendanceTable";
import { DatePickerField } from "../../../../common";
import { NotesSharp } from "@material-ui/icons";
import { fetchMyAttendances } from "../../../../../redux/actions/employee";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Attendances = ({ user, profile, myAttendances }) => {
  const { t } = useTranslation("common");
  const profileId = user.profile.id;
  const dispatch = useDispatch();
  const [month, setMonth] = useState(moment(new Date()).format("yyyy-MM"));

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(
      fetchMyAttendances({
        dateMonth: month,
        employeeId: profileId,
      })
    );
  }, [dispatch, month, profileId]);

  const handleMonthChange = (e) => {
    setMonth(moment(e).format("yyyy-MM"));
  };

  const sortedAttendaces = [...myAttendances].sort(
    (a, b) => new Date(b.checkIn) - new Date(a.checkIn)
  );

  return (
    <Box clone minHeight={"400px"} paddingX={matchesMD ? 2 : 6}>
      <Grid container spacing={3}>
        <Grid item container justifyContent="flex-end" md={12} xs={12}>
          <Grid item md={4} xs={12}>
            <DatePickerField
              disableToolbar="false"
              yearMonthView
              openTo="month"
              onChange={(e) => handleMonthChange(e)}
              value={month}
              label={t("common:month")}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid item xs={12}>
            <AttendanceTable
              myAttendanceHistory={true}
              title={t("attendances")}
              icon={<NotesSharp color="primary" />}
              attendances={sortedAttendaces}
              month={month}
              hasLocation={profile.location}
              profile={profile}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ auth, employee }) => ({
  user: auth.user,
  myAttendances: employee.attendances,
  profile: employee.profile,
});
export default connect(mapStateToProps)(Attendances);
