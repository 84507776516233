import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../../components/common";
import {
  updateOrCreateLeaveCredit,
  updateOrCreateLeaveDebit,
  fetchEmployeeLeaveSalary,
} from "../../../../../redux/actions";

import { Alert } from "@material-ui/lab";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { leavesKind } from "../../../../../components/common/DropDownsData";
import LeaveFile from "../../../../../components/common/LeaveFile";
import TimePickerField from "../../../../../components/common/TimePickerField";
import { isVacationsError } from "./utils";

const EditLeave = ({
  employee,
  open,
  setOpen,
  type = "debit",
  updateOrCreateLeaveCredit,
  updateOrCreateLeaveDebit,
  leaveCreditDebitLoading,
  employeeLeaveSalary,
  leave: _leave,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const dispatch = useDispatch();

  const [leave, setLeave] = useState({
    id: "",
    reason: "",
    kind: "",
    amount: 0,
    startTime: moment(),
    endTime: moment(),
    date: "",
    endDate: "",
    document: null,
    balanceAdjustment: false,
  });

  useEffect(() => {
    // Check if _leave is available and has properties
    if (_leave && Object.keys(_leave).length > 0) {
      setLeave({
        employeeId: employee.id,
        id: _leave.id,
        reason: _leave.reason,
        kind: _leave.kind,
        startTime: moment(_leave.startTime).format("HH:mm:ss"),
        endTime: moment(_leave.endTime).format("HH:mm:ss"),
        date: _leave.date,
        endDate: _leave.endDate,
        document: _leave.document,
        balanceAdjustment: _leave.balanceAdjustment,
      });
      setDateRange({
        date: _leave.date,
        endDate: _leave.endDate,
      });
      if (
        _leave.amount > 1 &&
        !moment(_leave.date, "YYYY-MM-DD").isSame(
          moment(_leave.endDate, "YYYY-MM-DD"),
        )
      ) {
        setAmount(0);
        leave.amount = 0;
      } else {
        setAmount(_leave.amount);
        leave.amount = _leave.amount;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_leave]);
  const [amount, setAmount] = useState(0);
  const [dateRange, setDateRange] = useState({
    date: null,
    endDate: null,
  });

  const [error, setError] = useState("");
  const isEnglish = language === "en";

  const modifiedLeaveTypes = () => {
    if (type === "credit")
      return leavesKind.filter((leave) => leave.value !== "SHORT");

    return leavesKind;
  };

  useEffect(() => {
    dispatch(
      fetchEmployeeLeaveSalary({
        employeeId: employee.id,
        startDate: leave.date,
        endDate: leave.endDate,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, leave.kind, leave.date, leave.endDate]);

  const getAvailableBalance = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return employee.leaveBalance.annualBalance;

      case "CASUAL":
        return employee.leaveBalance.casualBalance;

      case "SICK":
        return employee.leaveBalance.sickBalance;
      default:
        return "-";
    }
  };

  const getLeaveSalary = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return employeeLeaveSalary?.annualBalance;

      case "CASUAL":
        return employeeLeaveSalary?.casualBalance;

      case "SICK":
        return employeeLeaveSalary?.sickBalance;

      case "SHORT":
        return employeeLeaveSalary?.shortLeaveBalance;

      case "UNPAID":
        return 0;

      default:
        break;
    }
  };

  const closeModal = () => {
    setError("");
    setAmount(0);
    setLeave({
      kind: "ANNUAL",
      reason: "",
      date: null,
      endDate: null,
      startTime: null,
      endTime: null,
      employeeId: employee.id,
    });
    setDateRange({
      date: null,
      endDate: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    setError("");

    if (leave.reason === "") {
      setError(t("reasonError"));
    }
    if (type === "credit" && amount === 0) {
      setError(t("amountError"));
    }
    if (leave.kind === "SHORT") {
      if (moment(leave.endTime).isBefore(moment(leave.startTime))) {
        setError(t("timeError"));
      }
      if (!(leave.date && leave.endTime && leave.startTime)) {
        setError(t("timeAmountError"));
      }
    }
    if (type === "debit") {
      if (
        !leave.balanceAdjustment &&
        isVacationsError(
          employee,
          dateRange.date,
          amount,
          dateRange.endDate,
          _leave?.id,
        )
      ) {
        setError(t("vacationError"));
      }
      if (
        (dateRange.date === null && dateRange.endDate === null) ||
        (dateRange.date === null && amount === 0)
      ) {
        setError(t("amountError"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leave, type, amount, employee, leave.reason, leave.kind, dateRange]);

  const onSubmit = () => {
    if (type === "credit") {
      const { startTime, endTime, date, endDate, ...rest } = leave;
      updateOrCreateLeaveCredit({ ...rest, date, amount }, () => closeModal());
    } else {
      const { startTime, endTime, date, endDate, ...rest } = leave;

      if (leave.kind === "SHORT") {
        updateOrCreateLeaveDebit(
          {
            ...rest,
            date,
            endDate,
            amount: 0,
            startTime: moment(leave.startTime).format("HH:mm"),
            endTime: moment(leave.endTime).format("HH:mm"),
          },
          () => closeModal(),
        );
      } else {
        if (amount > 0) {
          updateOrCreateLeaveDebit({ ...rest, date, amount }, () =>
            closeModal(),
          );
        } else {
          updateOrCreateLeaveDebit({ ...rest, date, endDate }, () =>
            closeModal(),
          );
        }
      }
    }
  };

  return (
    <CompanyStructureModal
      open={open}
      onClose={closeModal}
      fadeIn={open}
      title={type === "credit" ? t("addCredit") : t("addDebit")}
    >
      <Box minHeight={"60vh"}>
        {leaveCreditDebitLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <>
            <Grid container justifyContent="center">
              <Grid
                item
                container
                spacing={3}
                style={{ margin: 4 }}
                md={12}
                xs={12}
              >
                {error && (
                  <Grid item md={8} xs={12}>
                    <Alert onClick={() => setError("")} severity="error">
                      {error}
                    </Alert>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <FormInputField
                    isRequired
                    label={t("reason")}
                    name="reason"
                    type="text"
                    onChange={(e) => {
                      setLeave({ ...leave, reason: e.target.value });
                    }}
                    value={leave.reason}
                    id="reason"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormSelectInput
                    isRequired
                    label={t("leaveKind")}
                    name="kind"
                    selectOptions={modifiedLeaveTypes().map((kind) => ({
                      label: isEnglish ? kind.name : kind.nameAr,
                      value: kind.value,
                    }))}
                    onChange={(e) => setLeave({ ...leave, kind: e })}
                    value={leave.kind}
                  />
                </Grid>
                {leave.kind !== "SHORT" ? (
                  type !== "credit" ? (
                    // if type is debit and kind is not short
                    <>
                      <Grid item md={6} xs={12}>
                        <FormInputField
                          isRequired
                          label={t("amount")}
                          name="amount"
                          type="number"
                          onChange={(e) => {
                            setAmount(+e.target.value);
                            setLeave({ ...leave, amount: e.target.value });
                          }}
                          value={amount}
                          id="amount"
                          autoComplete="off"
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          disabled={amount === 0}
                          disableToolbar={false}
                          isRequired
                          label={t("date")}
                          name="date"
                          value={amount === 0 ? null : dateRange.date}
                          onChange={(e) => {
                            setLeave({
                              ...leave,
                              date: moment(e).format("yyyy-MM-DD"),
                            });
                            setDateRange({
                              ...dateRange,
                              date: moment(e).format("yyyy-MM-DD"),
                            });
                          }}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Typography align="center">{t("or")}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          disabled={amount > 0}
                          disableToolbar={false}
                          openTo="date"
                          onChange={(e) => {
                            setAmount(0);
                            setDateRange({
                              ...dateRange,
                              date: moment(e).format("yyyy-MM-DD"),
                            });

                            setLeave({
                              ...leave,
                              date: moment(e).format("yyyy-MM-DD"),
                            });
                          }}
                          value={amount > 0 ? null : dateRange.date}
                          label={t("common:startDate")}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <DatePickerField
                          disabled={amount > 0}
                          min={moment(dateRange.date)}
                          disableToolbar={false}
                          openTo="date"
                          onChange={(e) => {
                            if (amount !== 0) {
                              setAmount(0);
                            }

                            setDateRange({
                              ...dateRange,
                              endDate: moment(e).format("yyyy-MM-DD"),
                            });

                            setLeave({
                              ...leave,
                              endDate: moment(e).format("yyyy-MM-DD"),
                            });
                          }}
                          value={amount > 0 ? null : dateRange.endDate}
                          label={t("common:endDate")}
                        />
                      </Grid>
                    </>
                  ) : (
                    // if type is credit and kind is not short
                    <>
                      <Grid item xs={12}>
                        <DatePickerField
                          disableToolbar={false}
                          isRequired
                          label={t("date")}
                          name="date"
                          value={leave.date}
                          onChange={(e) => {
                            setLeave({
                              ...leave,
                              date: moment(e).format("yyyy-MM-DD"),
                            });
                            setDateRange({
                              ...dateRange,
                              date: moment(e).format("yyyy-MM-DD"),
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputField
                          isRequired
                          label={t("amount")}
                          name="amount"
                          type="number"
                          onChange={(e) => {
                            setAmount(+e.target.value);
                            setLeave({ ...leave, amount: e.target.value });
                          }}
                          value={amount}
                          id="amount"
                          autoComplete="off"
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                    </>
                  )
                ) : (
                  // if kind is short
                  <>
                    <Grid item xs={12}>
                      <DatePickerField
                        disableToolbar={false}
                        isRequired
                        label={t("date")}
                        name="date"
                        value={leave.date}
                        onChange={(e) => {
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                            endDate: moment(e).format("yyyy-MM-DD"),
                          });
                          setDateRange({
                            ...dateRange,
                            date: moment(e).format("yyyy-MM-DD"),
                            endDate: moment(e).format("yyyy-MM-DD"),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TimePickerField
                        isRequired
                        label={t("startTime")}
                        name="startTime"
                        value={leave.startTime}
                        onChange={(e) => setLeave({ ...leave, startTime: e })}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TimePickerField
                        isRequired
                        label={t("endTime")}
                        name="endTime"
                        value={leave.endTime}
                        onChange={(e) => setLeave({ ...leave, endTime: e })}
                      />
                    </Grid>
                  </>
                )}

                {type !== "credit" && leave.kind !== "SHORT" && (
                  <Grid item md={12} xs={12}>
                    <Grid item container direction="row" alignItems="center">
                      <Typography variant="body1" color="primary">
                        {t("common:balanceAdjustment")}
                      </Typography>

                      <Checkbox
                        checked={leave.balanceAdjustment}
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            balanceAdjustment: e.target.checked,
                          })
                        }
                        style={{ marginInline: "20px" }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("common:balanceAdjustmentDescription")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12} style={{ margin: 20 }}>
                <LeaveFile
                  value={leave.document}
                  setValue={(newFile) =>
                    setLeave({ ...leave, document: newFile })
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              style={{ marginInline: 20, marginBottom: "20px" }}
            >
              <Grid item md={12} style={{ paddingTop: 1 }}>
                <Typography variant="subtitle2" color="textSecondary">{`${t(
                  "common:totalCountRequested",
                )}: ${
                  moment(leave.endDate, "yyyy-MM-DD")?.diff(
                    moment(leave.date, "yyyy-MM-DD"),
                    "days",
                  ) + 1
                }`}</Typography>
              </Grid>

              <Grid item md={12} style={{ paddingTop: 1 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  {" "}
                  {`${t("common:estimatedSalaryAfterLeave")}: ${parseFloat(
                    getLeaveSalary(),
                  ).toFixed(3)} KWD`}
                </Typography>
              </Grid>

              <Grid item md={12} style={{ paddingTop: 1 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  {" "}
                  {`${t(
                    "common:totalNumberInBalance",
                  )}:  ${getAvailableBalance()}`}
                </Typography>
              </Grid>
              {/* <Grid item md={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="salaryRequest"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={"before"}
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckBoxOutlined />}
                          icon={<CheckBoxOutlineBlankOutlined />}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {t("common:requestSalaryBeforeLeave")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"after"}
                      control={
                        <Radio
                          size="small"
                          checkedIcon={<CheckBoxOutlined />}
                          icon={<CheckBoxOutlineBlankOutlined />}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {t("common:requestSalaryAfterLeave")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
            </Grid>

            <Grid item md={12} xs={12} style={{ margin: 10 }}>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Box clone marginX={2}>
                  <Button
                    disabled={error !== "" ? true : false}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    <Typography>{t("confirm")}</Typography>
                  </Button>
                </Box>
                <Box clone marginX={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => closeModal()}
                  >
                    <Typography>{t("cancel")}</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </CompanyStructureModal>
  );
};

const mapStateToProps = ({ employees, employee }) => ({
  leaveCreditDebitLoading: employees.leaveCreditDebitLoading,
  employeeLeaveSalary: employee.leaveSalary,
});

const mapDispatchToProps = {
  updateOrCreateLeaveCredit,
  updateOrCreateLeaveDebit,
  fetchEmployeeLeaveSalary,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLeave);
