import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";

import PayrollDay from "./PayrollDay";
import React from "react";
import { useTranslation } from "react-i18next";

const PayrollSettings = ({ edit, company }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Card>
        <CardHeader title={t("payrollSettings")} />
        <CardContent>
          <Grid container spacing={3}>
            <PayrollDay company={company} />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default PayrollSettings;
