import {
  AppBar,
  Box,
  Container,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
//Icons
import { Close, Menu } from "@material-ui/icons";
import { ElevationScroll, Logo } from "../common";
//Components
import { Link, NavLink } from "react-router-dom";

import LanguageButton from "./LanguageButton";
import React from "react";

const Topbar = ({ navItems, onMobileNavOpen, isMobileNavOpen }) => {
  const navLinks = navItems.map((item) => (
    <Grid item key={item.title}>
      <NavLink style={{ textDecoration: "none", color: "#246ea0" }} {...item}>
        {item.title}
      </NavLink>
    </Grid>
  ));

  return (
    <ElevationScroll>
      <AppBar position="sticky">
        <Box pt={5} pb={2} bgcolor="background.default">
          <Container>
            <Toolbar disableGutters>
              <Link to="/">
                <Logo />
              </Link>
              <Box flexGrow={1} />
              <Hidden smDown>
                <Box clone justifyContent="flex-end">
                  <Grid container spacing={3} alignItems="center">
                    {navLinks}
                    <Grid item>
                      <LanguageButton />
                    </Grid>
                  </Grid>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box justifyContent="flex-end">
                  <Grid container justifyContent="space-evenly">
                    <Grid item sm={6} xs={6}>
                      <IconButton color="default" onClick={onMobileNavOpen}>
                        {isMobileNavOpen ? <Close /> : <Menu />}
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Hidden>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
    </ElevationScroll>
  );
};

export default Topbar;
