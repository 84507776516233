import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import EditPermission from "./EditPermission";
import { connect } from "react-redux";

import { deleteApproverRule } from "../../../../redux/actions";
import { CompanyStructureModal } from "../../../common";
import ApproversForm from "./ApproversForm";

const RequestApproverItem = ({ rule, deleteApproverRule }) => {
  const { t } = useTranslation("common");

  const [openModal, setOpenModal] = useState(false);

  const handleDeleteRule = (ruleId) => {
    deleteApproverRule({ approverRuleId: ruleId });
  };

  const getDepartmentNames = () => {
    const names = rule?.departments?.map((department) => department.name);
    return names.join(", ");
  };

  const getLocationsNames = () => {
    const names = rule?.locations?.map((location) => location.name);
    return names.join(", ");
  };

  return (
    <>
      <Grid item container spacing={2} key={rule.id}>
        <Grid item container md={8}>
          <Grid item md={12}>
            <Typography variant="subtitle2">
              {`Departments: ${getDepartmentNames()}`}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle2">
              {`Locations: ${getLocationsNames()}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container md={4}>
          <Grid item md={5}>
            <Button
              style={{ color: "red" }}
              variant="text"
              onClick={() => handleDeleteRule(rule.id)}
            >
              {t("deleteRule")}
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              {t("edit")}
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2} md={6}>
          {rule?.users?.map((user) => {
            return (
              <Grid item>
                <Button
                  style={{ backgroundColor: "#E1E3E4", textTransform: "none" }}
                  variant="outlined"
                  disabled
                >
                  {user.employee?.fullName}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid item md={12} style={{ marginTop: 5 }}>
          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("editRule")}
      >
        <ApproversForm setOpen={setOpenModal} rule={rule} />
      </CompanyStructureModal>
    </>
  );
};

const mapDispatchToProps = {
  deleteApproverRule,
};

export default connect(null, mapDispatchToProps)(RequestApproverItem);
