import moment from "moment";

export const normalizeEmployeeDocument = (document, isEnglish) => {
  return {
    documentName: document.name,
    documentType: isEnglish ? document.type?.name : document.type?.nameAr,
    exp: moment(new Date(document.exp)).format("DD-MM-yyyy"),
    employeeId: document.employee.id,
    employeeName: document.employee.user
      ? document.employee.user?.fullName
      : document.employee?.fullName,
    department: isEnglish
      ? document.employee.department.name
      : document.employee.department.nameAr,
    position: isEnglish
      ? document.employee.position.name
      : document.employee.position.nameAr,
  };
};
