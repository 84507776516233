import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalPaper: {
      backgroundColor: "white",
      borderRadius: 10,
      outline: "none",
    },
  },
  { index: 1 },
);

const CompanyStructureModal = ({
  open,
  onClose,
  fadeIn,
  title,
  actions,
  width = "800px",
  children,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const classes = useStyles();

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    open && (
      <Modal
        aria-labelledby={title}
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={fadeIn}>
          <Box
            height="auto"
            maxHeight={lgUp ? "90vh" : "100vh"}
            width={width}
            className={classes.modalPaper}
          >
            <Box display="flex" flexDirection="column">
              <Box paddingX={2} paddingY={2}>
                <Typography
                  variant="h2"
                  color="primary"
                  align={isEnglish ? "left" : "right"}
                >
                  {title}
                </Typography>
              </Box>
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    )
  );
};

export default CompanyStructureModal;
