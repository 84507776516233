import { Button, CircularProgress, Typography } from "@material-ui/core";

import React from "react";

const LoadingButton = ({
  loading,
  onClick,
  variant,
  color,
  label,
  ...rest
}) => {
  return (
    <Button onClick={onClick} variant={variant} color={color} {...rest}>
      {loading && <CircularProgress size={14} />}
      {!loading && <Typography>{label}</Typography>}
    </Button>
  );
};

export default LoadingButton;
