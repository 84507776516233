import { Chip, IconButton, Typography } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  CustomTableCell,
  CustomTableRow,
} from "../../../../../../components/common";
import { deleteEmployeePortalPermission } from "../../../../../../redux/actions";
import EditPermission from "./EditPermission";

const Row = ({ employee, deleteEmployeePortalPermission }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const [openEditModal, setOpenEditModal] = useState(false);
  console.log("employee", employee);
  const getPermissions = () => {
    let permissions = [];
    if (employee?.hasAttendanceAccess) permissions.push(t("attendanceAccess"));
    if (employee?.hasIndemnityAccess) permissions.push(t("indemnityAccess"));
    if (employee?.hasDocumentAccess) permissions.push(t("documentsAccess"));
    if (employee?.hasVacationsAccess) permissions.push(t("vacationsAccess"));

    return permissions;
  };

  const deleteEmployeePermission = () => {
    deleteEmployeePortalPermission({
      employeeId: employee?.id,
      kinds: [],
      locations: [],
      departments: [],
    });
  };

  return (
    <>
      <CustomTableRow hover role="checkbox">
        <CustomTableCell>
          {getPermissions().map((permission, i) => {
            return (
              <Chip
                color="primary"
                variant="outlined"
                key={i}
                label={permission}
                style={{ marginRight: 2 }}
              />
            );
          })}
        </CustomTableCell>

        <CustomTableCell>
          {employee?.locations
            ? employee?.locations?.map((location, i) => {
                return (
                  <Chip
                    color="primary"
                    variant="outlined"
                    key={i}
                    label={isEnglish ? location.name : location.nameAr}
                    style={{ marginRight: 2 }}
                  />
                );
              })
            : "-"}
        </CustomTableCell>
        <CustomTableCell>
          {employee?.departments?.map((location, i) => {
            return (
              <Chip
                color="primary"
                variant="outlined"
                key={i}
                label={isEnglish ? location.name : location.nameAr}
                style={{ marginRight: 2 }}
              />
            );
          })}
        </CustomTableCell>

        <CustomTableCell>
          <Typography variant="subtitle2" color="textPrimary">
            {employee?.fullName}
          </Typography>
        </CustomTableCell>

        <CustomTableCell forActionLabel>
          <IconButton
            color="primary"
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => deleteEmployeePermission()}
          >
            <DeleteOutline />
          </IconButton>
        </CustomTableCell>
      </CustomTableRow>
      <EditPermission
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        selectedEmployee={employee}
      />
    </>
  );
};

const mapDispatchToProps = {
  deleteEmployeePortalPermission,
};

export default connect(null, mapDispatchToProps)(Row);
