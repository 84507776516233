import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    sendButton: {
      backgroundColor: theme.palette.common.lightBlue,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.common.green,
        color: "white",
      },
    },
    depositButton: {
      backgroundColor: theme.palette.common.blue,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.common.green,
        color: "white",
      },
    },
    cancelButton: {
      backgroundColor: "transparent",
      color: theme.palette.common.black,
      boxShadow: "none",

      "&:hover": {
        backgroundColor: theme.palette.common.red,
        color: "white",
      },
    },
    sendIcon: {
      "& .MuiSvgIcon-root": {
        transform: "scaleX(-1)",
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
