import React, { useState } from "react";

import { Box, FormHelperText, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/ar";

// Date Picker
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

//Translation
import { useTranslation } from "react-i18next";
import "../../styles/react_dates_overrides.css";

const DateRangeInput = ({ onChange, disabled = false, maxEndDate = null }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  const isDateOutsideRange = (date) => {
    if (maxEndDate) {
      return date.isAfter(moment(maxEndDate).add(1, "days"));
    }
    return false;
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      onChange([startDate.toDate(), endDate.toDate()]);
    }
  };
  const useStyles = makeStyles(
    (theme) => ({
      formControlRoot: {
        justifyContent: "flex-start",
        padding: 4,
        "& .MuiButtonBase-root MuiIconButton-root": {
          color: theme.palette.common.staffiBlue,
        },

        "& .MuiFormLabel-root": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInputLabel-formControl": {
          color: theme.palette.primary.main,
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
        "& .MuiInputLabel-shrink": {
          transformOrigin: isEnglish ? "top left" : "top right",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid #002A6E",
        },
        "& .MuiInput-underline.Mui-disabled:before": {
          borderBottomStyle: "solid",
        },
        "& .Mui-disabled": {
          color: "black",
        },
        "& .MuiFormHelperText-root": {
          textAlign: isEnglish ? "left" : "right",
        },
      },
    }),
    { index: 1 },
  );

  const classes = useStyles();
  return (
    <Box width="100%">
      <FormLabel style={{ color: "#325e77" }}>{t("startAndEndDate")}</FormLabel>
      <br />
      <Box clone width="100%" margin={0} className={classes.formControlRoot}>
        <DateRangePicker
          startDate={startDate}
          startDateId="start-date-input"
          endDate={endDate}
          endDateId="end-date-input"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          showClearDates={true}
          numberOfMonths={1}
          isOutsideRange={isDateOutsideRange}
          disabled={disabled}
        />
      </Box>
      <Box margin={0} className={classes.formControlRoot}>
        <FormHelperText>{t("startAndEndDate")}</FormHelperText>
      </Box>
    </Box>
  );
};

export default DateRangeInput;
