import { Avatar, Tooltip } from "@material-ui/core";
import React from "react";

const EmployeeAvatar = ({ employee }) => {
  return (
    <Tooltip
      title={employee.user ? employee.user?.fullName : employee?.fullName}
    >
      <Avatar src={employee.image} />
    </Tooltip>
  );
};

export default EmployeeAvatar;
