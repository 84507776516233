import { Button, styled } from "@material-ui/core";

export const StyledNavLink = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: "center",
  letterSpacing: 0,
  padding: "10px 8px",
  textTransform: "none",
  width: "100%",

  "&.active": {
    color: theme.palette.primary.main,
  },
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));
