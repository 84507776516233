import React from "react";

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { paths } from "../../../utils";
import { PageHeader } from "../../common";
import UploadComponent from "./uploadComponent";

function Upload() {
  const { t } = useTranslation(["breadcrumbs", "common"]);
  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.employees.name}`),
      link: paths.employees.link,
    },
    {
      name: t(`breadcrumbs:${paths.upload.name}`),
      isLast: true,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader
        title={t(`breadcrumbs:${paths.upload.header}`)}
        paths={navigationPaths}
        paddingBottom={5}
      />
      <UploadComponent />
    </Box>
  );
}

export default Upload;
