import { Card, CardHeader, Grid, List } from "@material-ui/core";

import { DetailsItem } from "../../common";
import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeContactInfo = (props) => {
  const {
    address1,
    address2,
    cityOrTown,
    country,
    postCode,
    paciNumber,
    stateOrProvince,
    zipCode,
    pocName,
    pocEmail,
    pocNumber,
    pocAddress1,
    pocAddress2,
    pocCityOrTown,
    pocCountryName,
    pocPostCode,
    pocPaciNumber,
    pocStateOrProvince,
    pocZipCode,
    ...other
  } = props;

  const { t } = useTranslation("common");

  const contactDetails = [
    { label: t("country"), value: country },
    { label: t("cityOrTown"), value: cityOrTown },
    { label: t("address1"), value: address1 },
    { label: t("address2"), value: address2 },
    { label: t("postcode"), value: postCode },
    { label: t("zipcode"), value: zipCode },
    { label: t("stateOrProvince"), value: stateOrProvince },
    { label: t("paciNumber"), value: paciNumber },
  ];

  const pointOfContactDetails = [
    { label: t("name"), value: pocName },
    { label: t("email"), value: pocEmail },
    { label: t("phoneNumber"), value: pocNumber },
    { label: t("address1"), value: pocAddress1 },
    { label: t("address2"), value: pocAddress2 },

    { label: t("zipcode"), value: pocZipCode },
    { label: t("country"), value: pocCountryName },
    { label: t("cityOrTown"), value: pocCityOrTown },
    { label: t("stateOrProvince"), value: pocStateOrProvince },
    { label: t("paciNumber"), value: pocPaciNumber },
  ];
  return (
    <Card {...other}>
      <CardHeader title={t("contactDetails")} />

      <List disablepadding={true.toString()}>
        <Grid container>
          {contactDetails.map((detail, idx) => {
            return (
              <Grid item md={6} key={idx}>
                <DetailsItem label={detail.label} value={detail.value} />
              </Grid>
            );
          })}
        </Grid>
      </List>

      <CardHeader title="Point of Contact Details" />

      <List disablepadding={true.toString()}>
        <Grid container>
          {pointOfContactDetails.map((detail, idx) => {
            return (
              <Grid item md={6} key={idx}>
                <DetailsItem label={detail.label} value={detail.value} />
              </Grid>
            );
          })}
        </Grid>
      </List>
    </Card>
  );
};

export default EmployeeContactInfo;
