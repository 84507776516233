//MaterialUI
import {
  Grid,
  Button,
  Box,
  Typography,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import {
  CompanyStructureModal,
  FormInputField,
} from "../../../../../../components/common";
import React from "react";

import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const Form = ({ updateCompanyProfile, company }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [companyState, setCompany] = React.useState({
    ...company,
  });
  const [payDay, setPayDay] = React.useState(null);

  const handleUpdate = () => {
    const payrollDayNumber = Number(payDay);
    console.log("payrollDayNumber", payrollDayNumber);
    if (
      payrollDayNumber === 0 ||
      payrollDayNumber === 1 ||
      (payrollDayNumber > 28 && payrollDayNumber < 32)
    ) {
      // Valid range, update payroll day cycle will be 1st of every month
      setAlert(false);
      console.log("Inside if");
      setCompany((...prevCompanyState) => ({
        ...prevCompanyState,
        payrollDay: 0,
      }));
      companyState.payrollDay = 0;
      console.log("company state inside if", companyState);
      updateCompanyProfile(companyState);
      handleCancel();
    } else if (payrollDayNumber < 0 || payrollDayNumber > 31) {
      // Invalid range, set alert
      setAlert(true);
    } else {
      // Valid range, update payroll day
      setAlert(false);
      console.log("Inside else");
      setCompany((...prevCompanyState) => ({
        ...prevCompanyState,
        payrollDay: payrollDayNumber,
      }));
      companyState.payrollDay = payrollDayNumber;
      console.log("company state inside else", companyState);
      updateCompanyProfile(companyState);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Grid item md={12} xs={12}>
        <FormControlLabel
          control={
            <>
              <IconButton color="primary" onClick={() => setIsModalOpen(true)}>
                <Edit />
              </IconButton>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#3D5E77",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {companyState?.payrollDay
                  ? companyState?.payrollDay
                  : t("notSet")}
              </Typography>
            </>
          }
          labelPlacement="start"
          label={
            <Typography
              variant="subtitle2"
              style={{
                marginInlineEnd: 8,
                color: "#3D5E77",
              }}
            >
              {t("payrollDay")}
            </Typography>
          }
        />
      </Grid>

      <CompanyStructureModal
        open={isModalOpen}
        fadeIn={true}
        title={t("updatePayrollday")}
      >
        <Box height="auto" maxHeight="90vh" margin={4}>
          <Grid item lg={12} xs={12}>
            {alert && (
              <Alert severity="error">
                Payroll Day must be between 1 and 31
              </Alert>
            )}
            <FormInputField
              label={t("payrollDay")}
              id="payrollDay"
              name="payrollDay"
              type="number"
              onChange={(e) => {
                setPayDay(e.target.value);
              }}
              value={payDay}
              helperText={
                <div style={{ whiteSpace: "pre-line" }}>
                  {t("payrollDayHelperText")}
                </div>
              }
              autoComplete="payrollDay"
            />
            <Grid
              item
              container
              direction="row-reverse"
              lg={12}
              xs={12}
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                >
                  {t("save")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(Form);
