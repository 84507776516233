//MaterialUI
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";

import Access from "./Acces";
import { Alert } from "@material-ui/lab";
import CompanySettings from "./CompanySettings";
import CompanyStructure from "./CompanyStructure";
import { CustomTabPanel } from "../../common";
//Components
import Documents from "./Documents";
import Holidays from "./Holidays";
import { PageHeader } from "../../../redesign/components/common";
import Password from "./Password";
import Profile from "./Profile";
import ReactGA from "react-ga";
import RequestApprovals from "./RequestApprovals";
import Resources from "./Resources";
//Redux
import { connect } from "react-redux";
import { paths } from "../../../redesign/utils";
import { useHistory } from "react-router";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";
import { verifyAccount } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  scrollButtons: {
    "& .MuiTabs-root": {
      direction: "rtl",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      opacity: 1,
    },
  },
}));

const CompanyProfile = ({ user, verifyAccount }) => {
  ReactGA.pageview(window.location.pathname);
  const [alert, setAlert] = useState("");

  const history = useHistory();
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation(["companyProfile", "breadcrumbs"]);

  const isEnglish = language === "en";
  const [value, setValue] = useState(0);
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const tabsLabel = [
    t("file"),
    t("companyStructure"),
    t("documents"),
    t("companySettings"),
    t("officialHolidays"),
    t("resources"),
    t("password"),
    t("access"),
    t("requestApprovals"),
  ];

  const tabs = tabsLabel.map((label, idx) => (
    <Tab
      disableRipple
      key={label}
      label={label}
      style={{
        color:
          value === idx
            ? theme.palette.common.lightBlue
            : theme.palette.common.grey,
        fontWeight: value === idx ? "bold" : "normal",
        width: "100px",
      }}
    />
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    return event;
  };

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.companyProfile.name}`),
      isLast: true,
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent={matchesMD ? "center" : "flex-start"}
        style={{ marginTop: 20 }}
      >
        <Grid item md={11} xs={12}>
          <PageHeader
            paths={navigationPaths}
            title={t(`breadcrumbs:${paths.companyProfile.header}`)}
            paddingBottom={5}
          />
        </Grid>
        <Box
          clone
          display="flex"
          flexDirection={isEnglish ? "row" : "row-reverse"}
          justifyContent="center"
        >
          <Tabs
            variant="scrollable"
            scrollButtons={matchesSM ? "on" : "auto"}
            value={value}
            onChange={handleChange}
            className={classes.scrollButtons}
            TabScrollButtonProps={{ disabled: false }}
          >
            {tabs}
          </Tabs>
        </Box>

        <Box clone bgcolor="white" borderRadius={10}>
          <Grid item md={11} xs={12}>
            {alert.length > 0 && (
              <Grid item md={12} xs={12}>
                <Alert severity="success" onClick={() => setAlert("")}>
                  {alert}
                </Alert>
              </Grid>
            )}
            {!user.verified && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                paddingX={3}
                paddingTop={3}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    verifyAccount({ email: user.email }, history, () =>
                      setAlert(t("verifyAccountEmail"))
                    )
                  }
                >
                  {`${t("verifyAccount")} : ${user.email}`}
                </Button>
              </Box>
            )}
            <CustomTabPanel value={value} index={0}>
              <Profile />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CompanyStructure />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Documents />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <CompanySettings />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Holidays />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <Resources />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <Password />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
              <Access />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={8}>
              <RequestApprovals />
            </CustomTabPanel>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});
const mapDispatchToProps = {
  verifyAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
