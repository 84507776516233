import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";

// Components
import { FormInputField, FormSelectInput } from "../../../common";

// Data
import { countries } from "../../../common/DropDownsData";

// MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";

const CompanyInformation = ({
  register,
  errors,
  control,
  errorFields,
  sectors,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyCreate"]);
  const isEnglish = language === "en";

  return (
    <Box clone width="100%">
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <FormInputField
            required
            label={t("companyCreate:companyName")}
            name="name"
            errors={errors.name?.message}
            inputRef={register}
            id="name"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            isRequired
            label={t("companyCreate:companyArabicName")}
            name="nameAr"
            errors={errors.nameAr?.message}
            inputRef={register}
            id="nameAr"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="sectorId"
            control={control}
            render={({ ref, ...props }) => (
              <FormSelectInput
                isRequired
                selectOptions={sectors.map((sector) => ({
                  label: isEnglish ? sector.name : sector.nameAr,
                  value: sector.id,
                }))}
                label={t("companyCreate:sector")}
                error={errors.sectorId?.message}
                inputRef={ref}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>{t("common:address")}</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="address.country"
            control={control}
            render={({ ref, ...props }) => (
              <FormSelectInput
                isRequired
                selectOptions={countries(t)}
                label={t("common:country")}
                error={errors.address?.country?.message}
                inputRef={ref}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            isRequired
            label={t("common:address1")}
            name="address.address1"
            errors={errors.address?.address1?.message}
            inputRef={register}
            id="address.address1"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:address2")}
            name="address.address2"
            errors={errors.address?.address2?.message}
            inputRef={register}
            id="address.address2"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            isRequired
            label={t("common:cityOrTown")}
            name="address.cityOrTown"
            errors={errors.address?.cityOrTown?.message}
            inputRef={register}
            id="address.cityOrTown"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:postcode")}
            name="address.postcode"
            errors={errors.address?.postcode?.message}
            inputRef={register}
            id="address.postcode"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:zipcode")}
            name="address.zipCode"
            errors={errors.address?.zipCode?.message}
            inputRef={register}
            id="address.zipCode"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            isRequired
            label={t("common:stateOrProvince")}
            name="address.stateOrProvince"
            errors={errors.address?.stateOrProvince?.message}
            inputRef={register}
            id="address.stateOrProvince"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:paciNumber")}
            name="address.paciNumber"
            errors={errors.address?.paciNumber?.message}
            inputRef={register}
            id="address.paciNumber"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company: { sectors } }) => ({ sectors });

export default connect(mapStateToProps)(CompanyInformation);
