import {
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { DatePickerField } from "../../../../../components/common";
import { connect } from "react-redux";
import { fetchEmployeeIndemnity } from "../../../../../redux/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";

import ReportsModal from "./ReportsModal.js";
import { indemnityPdfFields } from "./data";
import { IndemnityPageHeaderComponent } from "./components";

const Indemnity = ({
  employee,
  deposits,
  nextDeposit,
  indemnity,
  fetchEmployeeIndemnity,
  user,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeSalary"]);
  const isEnglish = language === "en";

  const [indemnityDate, setIndemnity] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [pdfFields, setPdfFields] = useState(
    isEnglish ? indemnityPdfFields(t) : indemnityPdfFields(t),
  );

  console.log("pdfFields", pdfFields);
  console.log("indemnity", indemnity);
  console.log("employee", employee);
  // const employeesForPdf = normalizeData(reportEmployees, pdfFields);
  useEffect(() => {
    setPdfFields(indemnityPdfFields(t));
  }, [isEnglish, t]);

  useEffect(() => {
    if (employee.employmentDate) {
      fetchEmployeeIndemnity({
        id: employee.id,
        date: moment(indemnityDate).format("yyyy-MM-DD"),
      });
    }
  }, [indemnityDate, employee, fetchEmployeeIndemnity]);

  return (
    <Grid container spacing={4}>
      <Grid item container justifyContent="flex-end" lg={12} xs={12}>
        <Grid item lg={4} xs={12}>
          <DatePickerField
            min={new Date(employee.employmentDate)}
            value={indemnityDate}
            // openTo="month"
            onChange={(e) => setIndemnity(e)}
            // yearMonthView
            disableToolbar={false}
            label={t("common:month")}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" lg={12} xs={12}>
        <Grid>
          <IndemnityPageHeaderComponent
            // handleMonthChange={handleMonthChange}
            // displayMonth={_month}
            onClick={() => {
              setOpenModal(true);
            }}
            t={t}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Card>
          <CardHeader title={t("employeeSalary:hypotheticalIndemnity")} />
          {employee.employmentDate && indemnity && (
            <>
              <List>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <ListItem disablepadding={true.toString()}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="h4"
                            style={{ fontWeight: "bold" }}
                            color="textSecondary"
                            align={isEnglish ? "left" : "right"}
                          >
                            {t("employeeSalary:resignation")}{" "}
                          </Typography>
                        }
                        secondary={
                          <Box
                            style={{
                              flex: 1,
                              marginTop: 0,
                            }}
                          >
                            <Typography
                              variant="h4"
                              style={{ fontWeight: "bold" }}
                              color="primary"
                              align={isEnglish ? "left" : "right"}
                            >
                              {indemnity?.resignation} {t("currency")}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <ListItem disablepadding={true.toString()}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="h4"
                            style={{ fontWeight: "bold" }}
                            color="textSecondary"
                            align={isEnglish ? "left" : "right"}
                          >
                            {t("employeeSalary:termination")}
                          </Typography>
                        }
                        secondary={
                          <Box
                            style={{
                              flex: 1,
                              marginTop: 0,
                            }}
                          >
                            <Typography
                              variant="h4"
                              style={{ fontWeight: "bold" }}
                              color="primary"
                              align={isEnglish ? "left" : "right"}
                            >
                              {indemnity?.termination} {t("currency")}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <ListItem disablepadding={true.toString()}>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="h4"
                            style={{ fontWeight: "bold" }}
                            color="textSecondary"
                            align={isEnglish ? "left" : "right"}
                          >
                            {t("employeeSalary:accrual")}
                          </Typography>
                        }
                        // secondary={
                        //   <Box
                        //     style={{
                        //       flex: 1,
                        //       marginTop: 0,
                        //     }}
                        //   >
                        //     <Typography
                        //       variant="h4"
                        //       style={{ fontWeight: "bold" }}
                        //       color="primary"
                        //       align={isEnglish ? "left" : "right"}
                        //     >
                        //       {indemnity.accrual.total} {t("currency")}
                        //     </Typography>
                        //   </Box>
                        // }
                      />
                    </ListItem>
                  </Grid>

                  <Grid item container direction="row-reverse" lg={12} xs={12}>
                    <Grid item md={4} xs={12}>
                      <ListItem disablepadding={true.toString()}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ fontWeight: "bold" }}
                              color="textSecondary"
                              align={isEnglish ? "left" : "right"}
                            >
                              {t("employeeSalary:vacationAccrual")}
                            </Typography>
                          }
                          secondary={
                            <Box
                              style={{
                                flex: 1,
                                marginTop: 0,
                              }}
                            >
                              <Typography
                                variant="h4"
                                style={{ fontWeight: "bold" }}
                                color="primary"
                                align={isEnglish ? "left" : "right"}
                              >
                                {indemnity.accrual.vacation} {t("currency")}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row-reverse" lg={12} xs={12}>
                    <Grid item md={4} xs={12}>
                      <ListItem disablepadding={true.toString()}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ fontWeight: "bold" }}
                              color="textSecondary"
                              align={isEnglish ? "left" : "right"}
                            >
                              {t("employeeSalary:depositAccrual")}
                            </Typography>
                          }
                          secondary={
                            <Box
                              style={{
                                flex: 1,
                                marginTop: 0,
                              }}
                            >
                              <Typography
                                variant="h4"
                                style={{ fontWeight: "bold" }}
                                color="primary"
                                align={isEnglish ? "left" : "right"}
                              >
                                {indemnity.accrual.lateDeposit.total &&
                                Number(indemnity.accrual.lateDeposit.total) > 0
                                  ? 3 * Number(employee.baseSalary)
                                  : 0}{" "}
                                {t("currency")}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </List>
            </>
          )}
        </Card>
      </Grid>
      {openModal && (
        <ReportsModal
          isVisible={openModal}
          documentHeaders={pdfFields}
          closeModal={() => setOpenModal(false)}
          month={indemnityDate}
          employee={employee}
          indemnity={indemnity}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = ({ employees, auth }) => ({
  deposits: employees.deposits,
  nextDeposit: employees.nextDeposit,
  indemnity: employees.indemnity,
  user: auth.user,
});

const mapDispatchToPros = {
  fetchEmployeeIndemnity,
};

export default connect(mapStateToProps, mapDispatchToPros)(Indemnity);
