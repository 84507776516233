// import React, { useState } from "react";

// import ExpiringDocumentsModal from "../../common/ExpiringDocumentsModal";
import HomeCard from "../../common/HomeCard";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const ExpiringContracts = ({ employees }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);
  const isEnglish = language === "en";

  // const [openModal, setOpenModal] = useState(false);
  // const [modalData, setModalData] = useState(null);

  const labels = [
    t("common:id"),
    t("common:employeeName"),
    t("common:department"),
    t("common:position"),
    t("common:expirationDate"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={(label, +i)} colSpan={2} />
  ));

  const today = new Date();
  const isContractExpiring = (employee) => {
    const leaveDate = moment(employee.leaveDate);
    const sixMonthsFromNow = moment(today).add(6, "months");
    return leaveDate.isSameOrBefore(sixMonthsFromNow);
  };

  const data = employees
    .filter((employee) => {
      return (
        employee.employmentStatus === "CONTRACTED" &&
        isContractExpiring(employee)
      );
    })
    .map((employee) => {
      return {
        id: employee.id,
        employeeName: employee.fullName,
        department: isEnglish
          ? employee.department.name
          : employee.department.nameAr,
        position: isEnglish ? employee.position.name : employee.position.nameAr,
        exp: employee.leaveDate,
      };
    });
  const rows = data.map((entry, i) => {
    return (
      <TableRow
        // onClick={() => {
        //   setModalData(entry);
        //   setOpenModal(true);
        // }}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.id}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.employeeName}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.department}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.position}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.exp}
        </RequestTableCell>
      </TableRow>
    );
  });

  return (
    <HomeCard title={t("home:expiringContract")}>
      <StyledHomeTable header={header} body={rows} data={data} />
      {/* <ExpiringDocumentsModal
        isEnglish={isEnglish}
        openModal={openModal}
        setOpenModal={setOpenModal}
        request={modalData}
        t={t}
      /> */}
    </HomeCard>
  );
};

const mapStateToProps = ({ employees }) => ({
  employees: employees.employees,
});

export default connect(mapStateToProps)(ExpiringContracts);
