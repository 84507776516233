import React from "react";

import { Grid, Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

//Components
import { FormInputField } from "../../../common";
import ContactInformationForm from "./ContactInformation";

//Translation
import { useTranslation } from "react-i18next";

const PointOfContact = ({ state, handleChange, handleValueChange }) => {
  const { t } = useTranslation("common");

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const paddingY = matchesMD ? 0 : 3;
  const extraFields = (
    <>
      <Box clone paddingY={paddingY}>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:name")}
            name="name"
            onChange={handleChange}
            value={state.name}
            id="name"
            autoComplete="new-password"
          />
        </Grid>
      </Box>

      <Box clone paddingY={paddingY}>
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("common:email")}
            name="email"
            type="email"
            onChange={handleChange}
            value={state.email}
            id="email"
            autoComplete="new-password"
          />
        </Grid>
      </Box>
    </>
  );
  return (
    <ContactInformationForm
      state={state}
      handleChange={handleChange}
      handleValueChange={handleValueChange}
      extraFields={extraFields}
    />
  );
};

export default PointOfContact;
