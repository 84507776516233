import React from "react";

//Components
import CustomTableCell from "../../../common/CustomTableCell";
import ButtonTableRow from "../../../common/ButtonTableRow";

//Material UI
import { Box } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";

const HolidayItem = ({ holiday, setOpen, setHoliday }) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <Box clone whiteSpace="nowrap">
      <ButtonTableRow
        onClick={() => {
          setHoliday(holiday);
          setOpen(true);
        }}
      >
        <CustomTableCell>
          {isEnglish ? holiday.name : holiday.nameAr}
        </CustomTableCell>
        <CustomTableCell>{holiday.start}</CustomTableCell>
        <CustomTableCell>{holiday.end}</CustomTableCell>
      </ButtonTableRow>
    </Box>
  );
};

export default HolidayItem;
