export const filterEmployees = (
  employees,
  department,
  position,
  status,
  query
) => {
  let departmentEmployees = employees.filter((employee) => {
    if (department.length === 0) {
      return employee;
    }

    return department.some((dep) => dep.value === employee.department.id);
  });

  let positionEmployees = departmentEmployees.filter((employee) => {
    if (position.length === 0) {
      return employee;
    }
    return position.some((pos) => pos.value === employee.position.id);
  });

  let statusEmployee = positionEmployees.filter((employee) => {
    if (status.length === 0) {
      return employee;
    }
    return status.some((stat) => stat.value === employee.employmentStatus);
  });

  return statusEmployee.filter((employee) => {
    const name = employee.user ? employee.user?.fullName : employee?.fullName;
    return (
      name.toLowerCase().includes(query.toLowerCase()) ||
      employee.id.includes(query)
    );
  });
};
