import {Box, Button, Grid, Typography} from '@material-ui/core'

import {InsertDriveFile} from '@material-ui/icons'
import React from 'react'
import {getFileName} from '../../../helpers/getFileName'
import {useTranslation} from 'react-i18next'

const LeaveFileField = ({file}) => {
  const {t} = useTranslation('requests')

  return (
    <Box clone minHeight={'50px'}>
      <Grid item container direction="row" alignItems="center" md={6} xs={12}>
        <Grid item md={1} xs={1}>
          <InsertDriveFile />
        </Grid>
        <Grid item md={5} xs={5}>
          <Typography>{getFileName(file)}</Typography>
        </Grid>
        <Grid item md={3} xs={3}>
          <Button
            component={'a'}
            color="primary"
            variant="contained"
            href={file}
            target="_blank"
            download
            rel="noreferrer"
          >
            {t('download')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LeaveFileField
