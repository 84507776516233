/* eslint-disable no-useless-escape */
const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const checkRequiredFields = (object, required) => {
  const keys = Object.keys(object);

  let errors = null;
  keys.forEach((key) => {
    if (
      key === "locations" &&
      object[key].length === 0 &&
      required[key]?.required
    ) {
      errors = {
        ...errors,
        [required[key].name]: `${required[key].name} ${required[key].message}`,
      };
    }

    if (!object[key] && required[key]?.required) {
      errors = {
        ...errors,
        [required[key].name]: `${required[key].name} ${required[key].message}`,
      };
    }

    if (object.email) {
      if (!validateEmail(object.email)) {
        errors = {
          ...errors,
          [required.email.name]: `${required["email"].validationMessage}`,
        };
      }
    }
  });
  return errors;
};

function validateEmail(emailValue) {
  const emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
  if (emailRegex.test(emailValue) || emailValue === "") {
    return true;
  } else {
    return false;
  }
}

function calcDistance(lat1, lat2, lon1, lon2) {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result
  let kilometers = (c * r).toFixed(2);
  return kilometers;
}

export { validURL, checkRequiredFields, calcDistance };
