import React from "react";

//Material UI
import { Box, Grid } from "@material-ui/core";

const CompanyField = ({ children }) => {
  return (
    <Box clone paddingY={2}>
      <Grid item md={6} xs={12}>
        {children}
      </Grid>
    </Box>
  );
};
export default CompanyField;
