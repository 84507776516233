import React, { useState, useEffect } from "react";

//Components
import CompanyField from "../CompanyField";
import { FormInputField } from "../../../common";

import AutocompleteCategoriesInput from "../../../common/AutocompleteCategoriesInput";

// Data
import { countries } from "../../../common/DropDownsData";

//MaterialUI
import { Grid, Typography, Box } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

//Actions
import { updatePocAddress } from "../../../../redux/actions";

const PocAddress = ({
  poc,
  address,
  updatePocAddress,
  edit,
  zipCodeHelperPoc,
  setZipCodeHelperPoc,
  postcodeHelperPoc,
  setPostcodeHelperPoc,
}) => {
  const { t } = useTranslation(["companyProfile", "common"]);
  const [addressState, setAddress] = useState({
    ...address,
    country: address?.country?.code,
    pocAddress: true,
  });

  useEffect(() => {
    setAddress({
      ...address,
      country: address?.country?.code,
      pocAddress: true,
    });
  }, [address]);

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" color="textPrimary">
              {t("companyProfile:pointOfContactAddress")}
            </Typography>
          </Grid>
          <CompanyField>
            <AutocompleteCategoriesInput
              isRequired={true}
              disabled={!edit}
              name="country"
              onChange={(e) => {
                setAddress({ ...addressState, country: e });
                updatePocAddress({
                  ...addressState,
                  country: e === "NK" ? null : e,
                });
              }}
              label={t("common:country")}
              value={addressState.country || "KW"}
              array={countries(t)}
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              label={t("common:address1")}
              name="address1"
              onChange={(e) => {
                setAddress({ ...addressState, address1: e.target.value });
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  address1: e.target.value,
                })
              }
              value={addressState.address1 || "-"}
              id="address1"
              autoComplete="new-password"
            />
          </CompanyField>

          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              label={t("common:address2")}
              name="address2"
              onChange={(e) => {
                setAddress({ ...addressState, address2: e.target.value });
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  address2: e.target.value,
                })
              }
              value={addressState.address2 || "-"}
              id="address2"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              label={t("common:stateOrProvince")}
              name="stateOrProvince"
              onChange={(e) => {
                setAddress({
                  ...addressState,
                  stateOrProvince: e.target.value,
                });
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  stateOrProvince: e.target.value,
                })
              }
              value={addressState.stateOrProvince || "-"}
              id="stateOrProvince"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              disabled={!edit}
              label={t("common:paciNumber")}
              name="paciNumber"
              onChange={(e) => {
                setAddress({
                  ...addressState,
                  paciNumber: e.target.value,
                });
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  paciNumber: e.target.value,
                })
              }
              value={addressState.paciNumber || "-"}
              id="paciNumber"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              label={t("common:cityOrTown")}
              name="cityOrTown"
              onChange={(e) => {
                setAddress({ ...addressState, cityOrTown: e.target.value });
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  cityOrTown: e.target.value,
                })
              }
              value={addressState.cityOrTown || "-"}
              id="cityOrTown"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              errors={postcodeHelperPoc.length !== 0}
              helperText={postcodeHelperPoc}
              label={t("common:postcode")}
              name="postcode"
              onChange={(e) => {
                setAddress({ ...addressState, postcode: e.target.value });

                const valid = /^(\d{5})?$/.test(e.target.value);

                if (!valid) {
                  setPostcodeHelperPoc("Invalid Postcode format. Ex. 45450");
                } else {
                  setPostcodeHelperPoc("");
                }
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  postcode: e.target.value,
                })
              }
              value={addressState.postcode || "-"}
              id="postcode"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              disabled={!edit}
              label={t("common:zipcode")}
              name="zipCode"
              errors={zipCodeHelperPoc.length !== 0}
              helperText={zipCodeHelperPoc}
              onChange={(e) => {
                setAddress({ ...addressState, zipCode: e.target.value });

                const valid = /^(\d{5})?$/.test(e.target.value);

                if (!valid) {
                  setZipCodeHelperPoc("Invalid Zipcode format. Ex. 45450");
                } else {
                  setZipCodeHelperPoc("");
                }
              }}
              onBlur={(e) =>
                updatePocAddress({
                  ...addressState,
                  zipCode: e.target.value,
                })
              }
              value={addressState.zipCode || "-"}
              id="zipCode"
              autoComplete="new-password"
            />
          </CompanyField>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  poc: company.poc,
  address: company.pocAddress,
});

const mapDispatchToProps = {
  updatePocAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(PocAddress);
