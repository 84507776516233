import {
  Avatar,
  Box,
  Button,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { Delete, EditOutlined, Print } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { employeesStatus } from "../../../../components/common/DropDownsData";
import { createEmployeeProfilePdf } from "../../../../components/pages/EmployeeProfile/utils";
import { ActionsButton } from "../../common";
import EmployeeDeleteModal from "./EmployeeDeleteModal";

const EmployeeHeader = (props) => {
  const { employeeId } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const {
    employee: { employee },
  } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const employeeStatus = employeesStatus.find(
    (status) => status.value === employee?.employmentStatus
  );

  let actions = [
    {
      icon: <Print color="primary" />,
      label: "Print",
      onClick: () =>
        createEmployeeProfilePdf(t, isEnglish, employee, employeeStatus),
    },
  ];

  if (!employee.archived) {
    actions.push({
      icon: <Delete color="error" />,
      label: "Delete",
      onClick: () => setOpenDeleteModal(true),
    });
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginY={5}
      >
        <Grid container alignItems="center" direction="row" spacing={2}>
          <Avatar
            src={employee.image ?? ""}
            style={{
              height: 64,
              width: 64,
            }}
          />
          <Grid item spacing={1} container>
            <Typography variant="h3">{employee?.fullName}</Typography>
            <Grid container alignItems="center" direction="row">
              <Typography variant="subtitle2">
                {`${employee.position.name} - ${employee.department.name}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" direction="row-reverse">
          <ActionsButton actions={actions} />
          <Button
            style={{ marginRight: 20, textTransform: "none" }}
            color="inherit"
            component={Link}
            to={`/editEmployee/${employeeId}`}
            endIcon={
              <SvgIcon>
                <EditOutlined />
              </SvgIcon>
            }
          >
            {t("edit")}
          </Button>
        </Grid>
      </Box>
      <EmployeeDeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        employeeId={employeeId}
      />
    </>
  );
};

export default EmployeeHeader;
