import { Breadcrumbs, Typography } from "@material-ui/core";

import { Link } from "react-router-dom";
import { NavigateNext } from "@material-ui/icons";
import React from "react";

const Breadcrumb = ({ paths }) => {
  const breadcrumbs = paths.map((path, i) => {
    if (path.isLast) {
      return (
        <Typography key={i} color="primary" style={{ fontStyle: "italic" }}>
          {path.name}
        </Typography>
      );
    } else {
      return (
        <Link
          to={path.link && path.link}
          key={i}
          style={{
            textTransform: "none",
            textDecoration: "none",
            fontStyle: "italic",
            backgroundColor: "transparent",
            color: "#3D5E77",
            // fontWeight: "bold",
          }}
        >
          {path.name}
        </Link>
      );
    }
  });
  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
