import React from "react";

//MaterialUI
import { Box, Typography } from "@material-ui/core";

const SectionTitle = ({ title, icon }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width={"100%"}
      alignItems="center"
      justifyContent="flex-start"
      paddingY={3}
    >
      {icon}
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
    </Box>
  );
};

export default SectionTitle;
