import { Grid, Typography } from "@material-ui/core";
import {
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../../components/common";
import {
  countries,
  employeesStatus,
  genders,
  maritalStatus,
} from "../../../../../components/common/DropDownsData";
import LeaveFile from "../../../../../components/common/LeaveFile";
import { useTranslation } from "react-i18next";
import AutocompleteCategoriesInput from "../../../../../components/common/AutocompleteCategoriesInput";
import { useEffect, useState } from "react";
import moment from "moment";

// full_name	phone_number	gender	department	position	nationality	employment_status	marital_status	date_of_birth	base_salary	employment_date	reference_number	paci_number	bank_name	iban	locations	civil_id	civil_id_issue_date	civil_id_expiry_date	passport_number	passport_issue_date	passport_expiry_date
// those are the columns in the excel sheet
export const EmployeesForm = ({
  data,
  idx,
  uploadData,
  setData,
  setDisabled,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common"]);
  const isEnglish = language === "en";
  const [employee, setEmployee] = useState(data);
  const [error, setError] = useState("");
  console.log("data", data);
  console.log("employee", employee);

  const handleChange = (field, value) => {
    setEmployee({ ...employee, [field]: value });
  };

  useEffect(() => {
    let errors = {};

    if (!employee.full_name) {
      errors.full_name = t("upload:fullNameError");
    }
    if (!employee.id) {
      errors.id = t("upload:idError");
    }
    if (!employee.department) {
      errors.department = t("upload:departmentError");
    }
    if (!employee.position) {
      errors.position = t("upload:positionError");
    }
    if (!employee.employment_status) {
      errors.employment_status = t("upload:employmentStatusError");
    }
    if (!employee.base_salary) {
      errors.base_salary = t("upload:baseSalaryError");
    }
    if (!employee.employment_date) {
      errors.employment_date = t("upload:employmentDateError");
    }
    if (!employee.locations) {
      errors.locations = t("upload:locationsError");
    }
    if (
      employee.employment_status in ["RESIGNED", "LAID_OFF", "CONTRACTED"] &&
      !employee.leave_date
    ) {
      errors.leave_date = t("upload:leaveDateError");
    }
    if (employee.civil_id && !employee.civil_id_expiry_date) {
      errors.civil_id_expiry_date = t("upload:civilIdExpiryDateError");
    }
    if (employee.passport_number && !employee.passport_expiry_date) {
      errors.passport_expiry_date = t("upload:passportExpiryDateError");
    }

    setError(errors);

    let temp = uploadData;
    temp[idx] = employee;
    setData(temp);
    console.log("tempData", temp);
    //eslint-disable-next-line
  }, [data, employee, idx, setData, uploadData]);

  setDisabled(Object.keys(error).length > 0);

  return (
    <Grid container spacing={1}>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired
            errors={error && error.full_name}
            label={t("common:fullName")}
            name="fullName"
            value={employee.full_name ? employee.full_name : ""}
            onChange={(e) => {
              handleChange("full_name", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired={true}
            errors={error && error.id}
            label={t("common:employeeId")}
            name="employeeId"
            value={employee.id ? employee.id : ""}
            onChange={(e) => {
              handleChange("id", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired
            label={t("common:phoneNumber")}
            name="phoneNumber"
            value={employee.phone_number ? employee.phone_number : ""}
            onChange={(e) => {
              handleChange("phone_number", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            label={t("common:dateOfBirth")}
            name="dateOfBirth"
            value={employee.date_of_birth ? employee.date_of_birth : ""}
            onChange={(e) => {
              handleChange("date_of_birth", moment(e).format("YYYY-MM-DD"));
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormSelectInput
            label={t("common:gender")}
            name="gender"
            value={employee.gender ? employee.gender : ""}
            selectOptions={genders.map((gender) => ({
              value: gender.value,
              label: isEnglish ? gender.name : gender.nameAr,
            }))}
            onChange={(e) => {
              handleChange("gender", e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormSelectInput
            label={t("common:maritalStatus")}
            name="maritalStatus"
            value={employee.marital_status ? employee.marital_status : ""}
            selectOptions={maritalStatus.map((status) => ({
              value: status.value,
              label: isEnglish ? status.name : status.nameAr,
            }))}
            onChange={(e) => {
              handleChange("marital_status", e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <AutocompleteCategoriesInput
            array={countries(t)}
            label={t("common:nationality")}
            name="nationality"
            value={employee.nationality ? employee.nationality : ""}
            onChange={(e) => {
              handleChange("nationality", e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("common:paciNumber")}
            name="paciNumber"
            value={employee.paci_number ? employee.paci_number : ""}
            onChange={(e) => {
              handleChange("paci_number", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired
            errors={error && error.department}
            label={t("common:department")}
            name="department"
            value={employee.department ? employee.department : ""}
            onChange={(e) => {
              handleChange("department", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired
            errors={error && error.position}
            label={t("common:position")}
            name="position"
            value={employee.position ? employee.position : ""}
            onChange={(e) => {
              handleChange("position", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired={true}
            errors={error && error.locations}
            label={t("common:location")}
            name="location"
            value={employee.locations ? employee.locations : ""}
            onChange={(e) => {
              setEmployee({ ...employee, locations: e.target.value });
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormSelectInput
            isRequired={true}
            errors={error && error.employment_status}
            label={t("common:employmentStatus")}
            name="employmentStatus"
            value={
              employeesStatus.find(
                (status) =>
                  status.value
                    .toLowerCase()
                    .slice(0, 3)
                    .includes(
                      employee.employment_status.slice(0, 3).toLowerCase(),
                    ),
                console.log(
                  "employee.employment_status",
                  employee.employment_status.slice(0, 3).toLowerCase(),
                ),
              ).value ?? null
            }
            selectOptions={employeesStatus.map((status) => ({
              value: status.value,
              label: isEnglish ? status.name : status.nameAr,
            }))}
            onChange={(e) => {
              handleChange("employment_status", e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            isRequired={true}
            errors={error && error.employment_date}
            label={t("common:employmentDate")}
            name="employmentDate"
            value={employee.employment_date ? employee.employment_date : ""}
            onChange={(e) => {
              handleChange("employment_date", moment(e).format("YYYY-MM-DD"));
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            errors={error && error.leave_date}
            label={t("common:leaveDate")}
            name="leaveDate"
            min={employee.employment_date ? employee.employment_date : null}
            value={employee.leave_date ? employee.leave_date : ""}
            onChange={(e) => {
              handleChange("leave_date", moment(e).format("YYYY-MM-DD"));
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("common:bankName")}
            name="bankName"
            value={employee.bank_name ? employee.bank_name : ""}
            onChange={(e) => {
              handleChange("bank_name", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label="IBAN"
            name="iban"
            type="number"
            value={employee.iban ? employee.iban : ""}
            onChange={(e) => {
              handleChange("iban", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={12} md={12}>
        <Grid item xs={12} lg={12} md={12} style={{ marginInline: "20px" }}>
          <FormInputField
            isRequired
            errors={error && error.base_salary}
            type="number"
            label={t("common:baseSalary")}
            name="baseSalary"
            value={employee.base_salary ? employee.base_salary : ""}
            onChange={(e) => {
              handleChange("base_salary", e.target.value);
            }}
            inputProps={{ min: 0 }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("common:civilId")}
            name="civilId"
            value={employee.civil_id ? employee.civil_id : ""}
            onChange={(e) => {
              handleChange("civil_id", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            label={t("common:documentIssueDate")}
            name="civilIdIssueDate"
            value={
              employee.civil_id_issue_date ? employee.civil_id_issue_date : ""
            }
            onChange={(e) => {
              handleChange(
                "civil_id_issue_date",
                moment(e).format("YYYY-MM-DD"),
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            errors={error && error.civil_id_expiry_date}
            label={t("common:documentExpiration")}
            name="civilIdExpiryDate"
            value={
              employee.civil_id_expiry_date ? employee.civil_id_expiry_date : ""
            }
            onChange={(e) => {
              handleChange(
                "civil_id_expiry_date",
                moment(e).format("YYYY-MM-DD"),
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <Typography color="primary">{t("common:civilId")}</Typography>
          <LeaveFile
            value={employee.civilId_document ? employee.civilId_document : null}
            setValue={(e) => {
              handleChange("civilId_document", e);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("common:passport")}
            name="passportNumber"
            value={employee.passport_number ? employee.passport_number : ""}
            onChange={(e) => {
              handleChange("passport_number", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            label={t("common:documentIssueDate")}
            name="passportIssueDate"
            value={
              employee.passport_issue_date ? employee.passport_issue_date : ""
            }
            onChange={(e) => {
              handleChange(
                "passport_issue_date",
                moment(e).format("YYYY-MM-DD"),
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <DatePickerField
            errors={error && error.passport_expiry_date}
            label={t("common:documentExpiration")}
            name="passportExpiryDate"
            value={
              employee.passport_expiry_date ? employee.passport_expiry_date : ""
            }
            onChange={(e) => {
              handleChange(
                "passport_expiry_date",
                moment(e).format("YYYY-MM-DD"),
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <Typography color="primary">{t("common:passport")}</Typography>
          <LeaveFile
            value={
              employee.passport_document ? employee.passport_document : null
            }
            setValue={(e) => {
              handleChange("passport_document", e);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
