//MaterialUI
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CheckinDetails = ({ company, updateCompanyProfile }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [enableCheckinOutsideLocation, setEnableCheckinOutsideLocation] =
    useState(company?.checkInOutsideLocation);

  const handleSwitchChange = () => {
    setEnableCheckinOutsideLocation(!enableCheckinOutsideLocation);

    updateCompanyProfile({
      ...company,
      checkInOutsideLocation: !enableCheckinOutsideLocation,
    });
  };

  return (
    <Grid item md={12} xs={12}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={enableCheckinOutsideLocation}
            onChange={() => handleSwitchChange()}
          />
        }
        labelPlacement="start"
        label={
          <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
            {t("companyProfile:enableCheckinOutsideLocation")}
          </Typography>
        }
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(CheckinDetails);
