import {
  Button,
  Card,
  CardHeader,
  Grid,
  List as MuiList,
} from "@material-ui/core";

import CompanyDocumentItem from "./CompanyDocumentItem";
import DetailsItem from "../DetailsItem";
import { Edit, Delete } from "@material-ui/icons";
import FilesList from "../FilesList";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Item = ({
  user,
  doc,
  setOpen,
  setOpenDelete,
  setDocumentState,
  forCompanyPage,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeProfile"]);
  const isEnglish = language === "en";

  const getDocumentTypeName = () => {
    if (forCompanyPage) {
      return doc.name;
    } else {
      if (isEnglish) {
        return doc.type?.name;
      } else {
        return doc.type?.nameAr;
      }
    }
  };

  let fields = [
    {
      label: t("common:name"),
      value: doc.name,
    },
    {
      label: t("employeeProfile:documentNumber"),
      value: doc.referenceNumber,
    },
    {
      label: t("employeeProfile:documentIssue"),
      value: doc.issueDate,
    },
    {
      label: t("employeeProfile:documentExpirationDate"),
      value: doc.exp,
    },
  ];

  if (forCompanyPage) {
    fields = [
      {
        label: t("employeeProfile:documentExpirationDate"),
        value: doc.exp,
      },
      {
        label: t("common:location"),
        value: doc.location,
      },
    ];
  }
  console.log("=====user======", user);
  const handleOpenEditModal = () => {
    setDocumentState(doc);
    setOpen(true);
  };
  const handleOpenDeleteModal = () => {
    setDocumentState(doc);
    setOpenDelete(true);
  };
  const FieldItem = forCompanyPage ? CompanyDocumentItem : DetailsItem;

  return (
    <Card>
      <CardHeader
        title={getDocumentTypeName()}
        action={
          user &&
          (user.isCompanyOwner || user.hasDocumentAccess) && (
            <Grid>
              <Grid>
                <Button
                  size="small"
                  endIcon={<Edit color="white" />}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenEditModal}
                >
                  {t("edit")}
                </Button>
              </Grid>
              <Grid>
                <Button
                  size="small"
                  endIcon={<Delete color="error" />}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDeleteModal}
                >
                  {t("delete")}
                </Button>
              </Grid>
            </Grid>
          )
        }
      />

      <MuiList disablepadding>
        <Grid container>
          {fields?.map(({ label, value }, idx) => {
            return (
              <Grid item xs={6} lg={6}>
                <FieldItem label={label} value={value} />
              </Grid>
            );
          })}
          <Grid item xs={12} lg={12}>
            <FilesList files={doc.files} />
          </Grid>
        </Grid>
      </MuiList>
    </Card>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Item);
