export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const COMPANY_CREATE = "COMPANY_CREATE";

export const COMPANY = "COMPANY";
export const EXPIRING_COMPANY_DOCUMENTS = "EXPIRING_COMPANY_DOCUMENTS";
export const ADD_EXPIRING_COMPANY_DOCUMENTS = "ADD_EXPIRING_COMPANY_DOCUMENTS";
export const EDIT_VACATION = "EDIT_VACATION";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const ADDRESS = "ADDRESS";
export const POC = "POC";
export const POC_ADDRESS = "POC_ADDRESS";
export const EMPLOYEE_DOCUMENTS = "EMPLOYEE_DOCUMENTS";
export const ALL_EMPLOYEE_DOCUMENTS = "ALL_EMPLOYEE_DOCUMENTS";
export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const ANNOUNCEMENTS = "ANNOUNCEMENTS";
export const SECTORS = "SECTORS";
export const RESOURCES = "RESOURCES";
export const ADD_SCHEDULES = "ADD_SCHEDULES";
export const APPROVER_RULES = "APPROVER_RULES";
export const ADD_APPROVER_RULE = "ADD_APPROVER_RULE";
export const REMOVE_APPROVER_RULE = "REMOVE_APPROVER_RULE";
export const EDIT_APPROVER_RULE = "EDIT_APPROVER_RULE";

//Documents
export const DOCUMENTS = "DOCUMENTS";
export const DOCUMENT_TYPES = "DOCUMENT_TYPES";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const ADD_DOCUMENT_FILE = "ADD_DOCUMENT_FILE";
export const UPDATE_DOCUMENT_FILE = "UPDATE_DOCUMENT_FILE";
export const DELETE_DOCUMENT_FILE = "DELETE_DOCUMENT_FILE";

//Structure
export const DEPARTMENTS = "DEPARTMENTS";
export const LOCATIONS = "LOCATIONS";
export const POSITIONS = "POSITIONS";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const ADD_POSITION = "ADD_POSITION";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const EDIT_POSITION = "EDIT_POSITION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT";
export const REMOVE_POSITION = "REMOVE_POSITION";

//Employees
export const EMPLOYEES = "EMPLOYEES";
export const EMPLOYEES_WITH_HR_ACCESS = "EMPLOYEES_WITH_HR_ACCESS";
export const EMPLOYEE = "EMPLOYEE";
export const EMPLOYEE_OVERTIME_CREDITS = "EMPLOYEE_OVERTIME_CREDITS";
export const EMPLOYEE_LOADING = "EMPLOYEE_LOADING";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_LOADING = "ADD_EMPLOYEE_LOADING";
export const EMPLOYEES_LEAVE_BALANCE = "EMPLOYEES_LEAVE_BALANCE";

export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const EDIT_EMPLOYEE_HR_ACCESS = "EDIT_EMPLOYEE_HR_ACCESS";
export const DEPOSITS = "DEPOSITS";
export const NEXT_DEPOSIT = "NEXT_DEPOSIT";
export const ADD_SALARY_MODIFICATION = "ADD_SALARY_MODIFICATION";
export const DELETE_SALARY_MODIFICATION = "DELETE_SALARY_MODIFICATION";
export const EDIT_SALARY_MODIFICATION = "EDIT_SALARY_MODIFICATION";
export const ADD_RECURRING_SALARY_MODIFICATION =
  "ADD_RECURRING_SALARY_MODIFICATION";
export const PERMISSION_EMPLOYEES = "PERMISSION_EMPLOYEES";
export const ADD_PERMISSION_EMPLOYEE = "ADD_PERMISSION_EMPLOYEE";
export const DELETE_PERMISSION_EMPLOYEE = "DELETE_PERMISSION_EMPLOYEE";
export const EDIT_PERMISSION_EMPLOYEE = "EDIT_PERMISSION_EMPLOYEE";

export const EDIT_RECURRING_SALARY_MODIFICATION =
  "EDIT_RECURRING_SALARY_MODIFICATION";
export const DELETE_RECURRING_SALARY_MODIFICATION =
  "DELETE_RECURRING_SALARY_MODIFICATION";
export const HYPOTHETICAL_INDEMNITIES = "HYPOTHETICAL_INDEMNITIES";
export const INDEMNITY = "INDEMNITY";
export const EDIT_ANNOUNCEMENT = "EDIT_ANNOUNCEMENT";
export const EMPLOYEES_PAYROLL = "EMPLOYEES_PAYROLL";
export const ADD_EMPLOYEES_MUTLIPLE_PAYROLL = "ADD_EMPLOYEES_MUTLIPLE_PAYROLL";
export const REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL =
  "REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL";
export const FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING =
  "FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING";

export const ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES =
  "ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES";

export const REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES =
  "REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES";

export const FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING =
  "FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING";

//updateEmployeePortalPermission
export const EMPLOYEE_PROFILE = "EMPLOYEE_PROFILE";
export const EMPLOYEE_DEPOSITS = "EMPLOYEE_DEPOSITS";
export const EMPLOYEE_NEXT_DEPOSIT = "EMPLOYEE_NEXT_DEPOSIT";
export const ADD_EMPLOYEE_DOCUMENT = "ADD_EMPLOYEE_DOCUMENT";
export const EDIT_EMPLOYEE_DOCUMENT = "EDIT_EMPLOYEE_DOCUMENT";
export const EMPLOYEE_ADD_EMPLOYEE_DOCUMENT = "EMPLOYEE_ADD_EMPLOYEE_DOCUMENT";
export const EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT =
  "EMPLOYEE_EDIT_EMPLOYEE_DOCUMENT";
export const ADD_EMPLOYEE_DOCUMENT_FILE = "ADD_EMPLOYEE_DOCUMENT_FILE";
export const EDIT_EMPLOYEE_DOCUMENT_FILE = "EDIT_EMPLOYEE_DOCUMENT_FILE";
export const DELETE_EMPLOYEE_DOCUMENT = "DELETE_EMPLOYEE_DOCUMENT";
export const DELETE_EMPLOYEE_DOCUMENT_FILE = "DELETE_EMPLOYEE_DOCUMENT_FILE";
export const EMPLOYEE_LEAVE_SALARY = "EMPLOYEE_LEAVE_SALARY";

//Requests
export const REQUESTS = "REQUESTS";
export const REQUEST_LOADING = "REQUEST_LOADING";
export const REQUEST = "REQUEST";
export const EDIT_LEAVE_REQUEST = "EDIT_LEAVE_REQUEST";
export const ADD_REQUEST = "ADD_REQUEST";
export const ADD_REQUEST_LOADING = "ADD_REQUEST_LOADING";

export const EDIT_UPDATE_REQUEST = "EDIT_UPDATE_REQUEST";
export const EDIT_RESIGNATION_REQUEST = "EDIT_RESIGNATION_REQUEST";
export const EDIT_OVERTIME_REQUEST = "EDIT_OVERTIME_REQUEST";
export const REQUEST_APPROVERS_STATUS = "REQUEST_APPROVERS_STATUS";
export const EDIT_LEAVE_REQUEST_LOADING = "EDIT_LEAVE_REQUEST_LOADING";
export const EDIT_GENERAL_REQUEST = "EDIT_GENERAL_REQUEST";

export const ADD_LEAVE_CREDIT = "ADD_LEAVE_CREDIT";
export const REMOVE_LEAVE_CREDIT = "REMOVE_LEAVE_CREDIT";
export const ADD_LEAVE_DEBIT = "ADD_LEAVE_DEBIT";
export const REMOVE_LEAVE_DEBIT = "REMOVE_LEAVE_DEBIT";
export const LEAVE_CREDIT_DEBIT_LOADING = "LEAVE_CREDIT_DEBIT_LOADING";

export const ADD_OVERTIME_CREDIT = "ADD_OVERTIME_CREDIT";

// Attendance
export const EMPLOYEE_ATTENDANCES = "EMPLOYEE_ATTENDANCES";
export const EDIT_EMPLOYEE_ATTENDANCES = "EDIT_EMPLOYEE_ATTENDANCES";

export const EMPLOYEE_ACTIVE_ATTENDANCE = "EMPLOYEE_ACTIVE_ATTENDANCE";
export const CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN =
  "CREATE_EMPLOYEE_ATTENDANCE_CHECK_IN";
export const UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE =
  "UPDATE_EMPLOYEE_ACTIVE_ATTENDANCE";
export const MY_ATTENDANCES = "MY_ATTENDANCES";
export const CREATE_ANONYMOUS_EMPLOYEE_ATTENDANCE_CHECK_IN =
  "CREATE_ANONYMOUS_EMPLOYEE_ATTENDANCE_CHECK_IN";
export const UPDATE_ANONYMOUS_EMPLOYEE_ATTENDANCE =
  "UPDATE_ANONYMOUS_EMPLOYEE_ATTENDANCE";
