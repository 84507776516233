export const getTotalToBeDeposited = (
  total,
  selectedAllowances,
  // earnings,
  // deductions,
) => {
  let totalAllowances = selectedAllowances
    .filter((allowance) => !allowance.receiveDuringVacation)
    .map((allowance) => allowance.amount)
    .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
  // let totalEarnings = earnings
  //   .map((earning) => earning.amount)
  //   .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
  // let totalDeductions = deductions
  //   .map((deduction) => deduction.amount)
  //   .reduce((partialSum, a) => Number(partialSum) + Number(a), 0);

  return Number(total) + Number(totalAllowances);
};
