// Graphql
import client from "./client";

// Action Types
import {
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  ADD_DOCUMENT_FILE,
  UPDATE_DOCUMENT_FILE,
  DELETE_DOCUMENT_FILE,
  DOCUMENTS,
  GET_ERRORS,
} from "./types";

//Queries
import { COMPANY_DOCUMENTS_QUERY } from "./Queries";

//Mutations
import {
  DOCUMENT_CREATE,
  DOCUMENT_DELETE,
  DOCUMENT_FILE_DELETE,
  DOCUMENT_FILE_CREATE,
  DOCUMENT_UPDATE,
  DOCUMENT_FILE_UPDATE,
} from "./Mutations";

export const fetchDocuments = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: COMPANY_DOCUMENTS_QUERY,
      variables: null,
    });

    const documents = res.data.companyDocuments;
    dispatch({ type: DOCUMENTS, payload: documents });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
    }
  }
};

export const createDocuments = (documents) => async (dispatch) => {
  // Using Promise.all to wait for all documents to be created and gather results
  const results = await Promise.all(
    documents.map(async (document) => {
      try {
        const res = await client.mutate({
          mutation: DOCUMENT_CREATE,
          variables: document,
        });
        const resDocument = res.data.updateOrCreateDocument.document;
        dispatch({ type: ADD_DOCUMENT, payload: resDocument });
        return resDocument;
      } catch (error) {
        if (error.response) {
          dispatch({
            type: GET_ERRORS,
            errors: error.response.data,
          });
        }
        // Return null if document creation failed for this document
        return null;
      }
    })
  );
  // Return the results array which contains created documents or nulls
  return results;
};

export const updateDocument = (document) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DOCUMENT_UPDATE,
      variables: document,
    });

    const resDocument = res.data.updateOrCreateDocument.document;
    dispatch({ type: UPDATE_DOCUMENT, payload: resDocument });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
    }
  }
};

export const deleteDocument = (document) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DOCUMENT_DELETE,
      variables: document,
    });

    const status = res.data.deleteDocument.status;
    if (status) dispatch({ type: DELETE_DOCUMENT, payload: document });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
    }
  }
};

export const createDocumentFiles = (files) => (dispatch) => {
  files.forEach(async (file) => {
    try {
      const res = await client.mutate({
        mutation: DOCUMENT_FILE_CREATE,
        variables: file,
      });

      const documentFile = res.data.updateOrCreateDocumentFile.documentFile;
      dispatch({ type: ADD_DOCUMENT_FILE, payload: documentFile });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
      }
    }
  });
};

export const updateDocumentFile = (file, documentId) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DOCUMENT_FILE_UPDATE,
      variables: { ...documentId, ...file },
    });

    const documentFile = res.data.updateOrCreateDocumentFile.documentFile;
    dispatch({ type: UPDATE_DOCUMENT_FILE, payload: documentFile });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
    }
  }
};

export const deleteDocumentFile = (file, documentId) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DOCUMENT_FILE_DELETE,
      variables: { ...documentId, ...file },
    });

    const status = res.data.deleteDocumentFile.status;
    if (status)
      dispatch({
        type: DELETE_DOCUMENT_FILE,
        payload: { ...documentId, ...file },
      });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
    }
  }
};
