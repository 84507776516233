import moment from "moment";

export const normalizeOvertimeRequest = (request) => {
  return {
    id: request.id,
    name: request.employee.user
      ? request.employee.user?.fullName
      : request.employee?.fullName,
    date: moment(request.overtimeRequest.date).format("DD-MM-yyyy"),
    amount: request.overtimeRequest.amount,
  };
};
