import { Box, Button, Grid, Typography } from "@material-ui/core";
//moment
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";

import { PageHeader } from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils/index";
//Actions
import {
  fetchEmployeeRequestDetails,
  fetchRequestApproversStatus,
  fetchRequestDetails,
  fetchRequests,
} from "../../../redux/actions";
import { Section } from "../../common";
//Data
import { requestsKind, requestsStatus } from "../../common/DropDownsData";
import Field from "./Field";
import Leave from "./Leave";
import LeaveFileField from "./LeaveFileField";
import Overtime from "./Overtime";
import Resignation from "./Resignation";
import StatusField from "./StatusField";
import UpdateProfile from "./UpdateProfile";
import { createRequestPdf } from "./utils";
import General from "./General";

const Request = ({
  approversStatus,
  fetchRequestApproversStatus,
  requests,
  request: _request,
  loading,
  requestsLoading,
  fetchRequests,
  fetchRequestDetails,
  fetchEmployeeRequestDetails,
  user,
  employeeProfile,
}) => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation("requests");
  const isEnglish = language === "en";
  const { requestId } = useParams();
  const [request, setRequest] = useState(_request);
  const hasHrAccess =
    user.isEmployee && employeeProfile && employeeProfile.hrAccess;
  useEffect(() => {
    if (requestsLoading) {
      fetchRequests();
    }
  }, [requestsLoading, fetchRequests]);

  useEffect(() => {
    let foundRequest = requests.find((request) => request.id === requestId);
    if (foundRequest) {
      if (
        user.isCompanyOwner ||
        hasHrAccess ||
        employeeProfile?.user.hasVacationsAccess
      ) {
        fetchRequestDetails({
          id: requestId,
          employeeId: foundRequest.employee.id,
        });
        fetchRequestApproversStatus({
          id: requestId,
        });
      } else {
        fetchEmployeeRequestDetails({
          id: requestId,
        });
        fetchRequestApproversStatus({
          id: requestId,
        });
      }
    } else if (!foundRequest && employeeProfile?.user.hasVacationsAccess) {
      fetchEmployeeRequestDetails({
        id: requestId,
      });
    }
  }, [
    fetchEmployeeRequestDetails,
    fetchRequestDetails,
    requestId,
    requests,
    requestsLoading,
    user,
    employeeProfile,
    hasHrAccess,
    fetchRequestApproversStatus,
  ]);

  useEffect(() => {
    if (_request && !approversStatus) {
      fetchRequestApproversStatus({ id: _request.id });
    }
  }, [_request, approversStatus, fetchRequestApproversStatus]);

  useEffect(() => {
    if (_request) setRequest(_request);
  }, [_request]);

  if (loading || !request)
    return (
      <Typography variant="h3" color="primary" align="center">
        {t("loading")}
      </Typography>
    );
  const kind = () => {
    let kindObj = requestsKind.find((kind) => kind.value === request.kind);
    if (isEnglish) return kindObj.name;
    return kindObj.nameAr;
  };
  const status = () => {
    let statusObj = requestsStatus.find(
      (status) => status.value === request.status,
    );
    if (isEnglish) return statusObj.name;
    return statusObj.nameAr;
  };

  let getBalance = () => {
    switch (request.leaveRequest.kind) {
      case "CASUAL":
        return request.employee.leaveBalance.casualBalance;
      case "SICK":
        return request.employee.leaveBalance.sickBalance;
      case "ANNUAL":
        return request.employee.leaveBalance.annualBalance;
      case "SHORT":
        return request.employee.leaveBalance.shortLeaveBalance ?? 0;
      default:
        return 0;
    }
  };

  const requestType = () => {
    switch (request.kind) {
      case "LEAVE":
        return (
          <Leave
            leave={request.leaveRequest}
            request={request}
            getBalance={getBalance}
            requests={requests}
            approversStatus={approversStatus}
          />
        );
      case "OVERTIME":
        return (
          <Overtime
            leave={request.overtimeRequest}
            request={request}
            getBalance={getBalance}
            requests={requests}
          />
        );
      case "UPDATE_PROFILE":
        return (
          <UpdateProfile
            update={request.updateProfileRequest}
            request={request}
          />
        );
      case "RESIGNATION":
        return (
          <Resignation
            resignation={request.resignationRequest}
            request={request}
          />
        );
      case "GENERAL":
        return <General general={request.generalRequest} request={request} />;
      default:
        break;
    }
  };

  const createdOn = moment(new Date(request.createdOn)).format(
    "DD-MM-yyyy HH:mm a",
  );

  const lastModifiedOn = moment(new Date(request.lastModified)).format(
    "DD-MM-yyyy HH:mm a",
  );

  const lastModifiedBy =
    request.lastModifiedBy?.fullName || request.employee?.fullName;

  const getApprovers = () => {
    let approversList = [];
    approversStatus.map((approver) =>
      approversList.push({
        fullName: approver.approvedBy?.fullName,
        status: approver.status,
      }),
    );
    return approversList;
  };

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.requests.name}`),
      link: paths.requests.link,
    },
    {
      name: request.employee?.fullName,
      isLast: true,
      // link: `${paths.employees.detail.link}/${request.employee.id}`,
    },
    {
      name: `${t("request")} #${request.id}-${kind()}`,
      isLast: true,
    },
  ];

  return (
    <>
      <Section>
        <PageHeader paths={navigationPaths} paddingBottom={1} />
        <Box display="flex" justifyContent="flex-end" paddingY={3}>
          {request.leaveRequest && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                createRequestPdf(
                  t,
                  isEnglish,
                  request.employee,
                  kind(),
                  status(),
                  createdOn,
                  lastModifiedOn,
                  lastModifiedBy,
                  request.leaveRequest,
                  getBalance(),
                )
              }
            >
              {t("print")}
            </Button>
          )}
        </Box>

        <Box clone bgcolor="white" borderRadius={10}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography variant="h3" color="primary">
                {t("requestDetails")}
              </Typography>
            </Grid>

            <Field label={t("request")} value={`#${request.id}-${kind()}`} />
            {/* <Field label={t("requestStatus")} value={status()} /> */}

            <StatusField approvers={getApprovers()} status={status()} />

            <Field
              label={t("employeeName")}
              value={
                request.employee.user
                  ? request.employee.user?.fullName
                  : request.employee?.fullName
              }
            />
            <Field
              label={t("employeeDepartment")}
              value={
                isEnglish
                  ? request.employee.department.name
                  : request.employee.department.nameAr
              }
            />
            <Field
              label={t("employeePosition")}
              value={
                isEnglish
                  ? request.employee.position.name
                  : request.employee.position.nameAr
              }
            />

            <Field label={t("createdOn")} value={createdOn} />
            <Field label={t("lastModifiedOn")} value={lastModifiedOn} />
            <Field label={t("lastModifiedBy")} value={lastModifiedBy} />
            {request.notes && (
              <Field label={t("notes")} value={request.notes} />
            )}
            {request.leaveRequest && request.leaveRequest.document && (
              <LeaveFileField file={request.leaveRequest.document} />
            )}
          </Grid>
        </Box>
      </Section>
      {requestType()}
    </>
  );
};

const mapStateToPorps = ({ requests, auth, employee }) => ({
  requests: requests.requests,
  request: requests.request,
  loading: requests.requestLoaidng,
  requestsLoading: requests.requestsLoading,
  user: auth.user,
  employeeProfile: employee.profile,
  approversStatus: requests.approversStatus,
});

const mapDispatchToProps = {
  fetchRequests,
  fetchRequestDetails,
  fetchEmployeeRequestDetails,
  fetchRequestApproversStatus,
};

export default connect(mapStateToPorps, mapDispatchToProps)(Request);
