import React from "react";

//Components
import { FormInputField } from "../../../../common";
import AutocompleteCategoriesInput from "../../../../common/AutocompleteCategoriesInput";

//MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";
import { countries } from "../../../../common/DropDownsData";

const ContactInformation = ({
  values,
  edit,
  updatedProfile,
  setUpdatedProfile,
  onBlur,
  errors,
  touched,
  onChange,
  profile,
}) => {
  const { t } = useTranslation("common");

  const handleChange = (field, value) => {
    if (value !== profile.poc[field]) {
      updatedProfile.poc
        ? setUpdatedProfile({
            ...updatedProfile,
            poc: {
              ...updatedProfile.poc,
              [field]: value,
            },
          })
        : setUpdatedProfile({
            ...updatedProfile,
            poc: {
              [field]: value,
            },
          });
    } else {
      let updatedFields = Object.keys(updatedProfile.poc);
      let newObj = {};
      if (updatedFields.length > 1) {
        updatedFields.forEach((key) => {
          if (key !== field)
            newObj = { ...newObj, [key]: updatedProfile.poc[key] };
        });
        setUpdatedProfile({ ...updatedProfile, poc: newObj });
      } else {
        Object.keys(updatedProfile).forEach((key) => {
          if (key !== "poc") newObj = { ...newObj, [key]: updatedProfile[key] };
        });
        setUpdatedProfile(newObj);
      }
    }
  };

  const handlePocAddressChange = (field, value) => {
    if (value !== profile.poc.address[field]) {
      updatedProfile.poc
        ? updatedProfile.poc.address
          ? setUpdatedProfile({
              ...updatedProfile,
              poc: {
                ...updatedProfile.poc,
                address: {
                  ...updatedProfile.poc.address,
                  [field]: value,
                },
              },
            })
          : setUpdatedProfile({
              ...updatedProfile,
              poc: {
                ...updatedProfile.poc,
                address: {
                  [field]: value,
                },
              },
            })
        : setUpdatedProfile({
            ...updatedProfile,
            poc: {
              address: {
                [field]: value,
              },
            },
          });
    } else {
      let updatedFields = Object.keys(updatedProfile.poc.address);
      let newObj = {};
      if (updatedFields.length > 1) {
        updatedFields.forEach((key) => {
          if (key !== field)
            newObj = { ...newObj, [key]: updatedProfile.poc.address[key] };
        });
        setUpdatedProfile({
          ...updatedProfile,
          poc: { ...updatedProfile.poc, address: newObj },
        });
      } else {
        Object.keys(updatedProfile.poc).forEach((key) => {
          if (key !== "address")
            newObj = { ...newObj, [key]: updatedProfile.poc[key] };
        });
        setUpdatedProfile({ ...updatedProfile, poc: { ...newObj } });
      }
    }
  };

  return (
    <Grid item md={12} xs={12}>
      <Typography variant="h4" color="textPrimary">
        {t("employeeDashboard:contactInformation")}
      </Typography>

      <Box clone paddingY={2}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:name")}
              name="pocName"
              onChange={(e) => {
                onChange(e);
                handleChange("name", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocName}
              errors={touched.pocName && errors.pocName}
              disabled={!edit}
              id="pocName"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:email")}
              name="pocEmail"
              onChange={(e) => {
                onChange(e);
                handleChange("email", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocEmail}
              errors={touched.pocEmail && errors.pocEmail}
              disabled={!edit}
              id="pocEmail"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:phoneNumber")}
              name="pocNumber"
              onChange={(e) => {
                onChange(e);
                handleChange("number", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocNumber}
              errors={touched.pocNumber && errors.pocNumber}
              disabled={!edit}
              id="pocNumber"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <AutocompleteCategoriesInput
              disabled={edit ? false : true}
              name="pocCountry"
              isRequired
              value={
                updatedProfile["poc"]
                  ? updatedProfile.poc["address"]
                    ? updatedProfile.poc.address["country"]
                      ? updatedProfile.poc.address.country
                      : values.pocCountry
                    : values.pocCountry
                  : values.pocCountry
              }
              array={countries(t)}
              label={t("common:country")}
              onChange={(e) => {
                handlePocAddressChange("country", e);
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:address1")}
              name="pocAddress1"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("address1", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocAddress1}
              errors={touched.pocAddress1 && errors.pocAddress1}
              disabled={!edit}
              id="pocAddress1"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:address2")}
              name="pocAddress2"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("address2", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocAddress2}
              errors={touched.pocAddress2 && errors.pocAddress2}
              disabled={!edit}
              id="pocAddress2"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:cityOrTown")}
              name="pocCityOrTown"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("cityOrTown", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocCityOrTown}
              errors={touched.pocCityOrTown && errors.pocCityOrTown}
              disabled={!edit}
              id="pocCityOrTown"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:postcode")}
              name="pocPostcode"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("postcode", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocPostcode}
              errors={touched.pocPostcode && errors.pocPostcode}
              disabled={!edit}
              id="pocPostcode"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:zipcode")}
              name="pocZipCode"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("zipCode", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocZipCode}
              errors={touched.pocZipCode && errors.pocZipCode}
              disabled={!edit}
              id="pocZipCode"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:stateOrProvince")}
              name="pocStateOrProvince"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("stateOrProvince", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocStateOrProvince}
              errors={touched.pocStateOrProvince && errors.pocStateOrProvince}
              disabled={!edit}
              id="pocStateOrProvince"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:paciNumber")}
              name="pocPaciNumber"
              onChange={(e) => {
                onChange(e);
                handlePocAddressChange("paciNumber", e.target.value);
              }}
              onBlur={onBlur}
              value={values.pocPaciNumber}
              errors={touched.pocPaciNumber && errors.pocPaciNumber}
              disabled={!edit}
              id="pocPaciNumber"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ContactInformation;
