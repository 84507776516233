import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../../../../components/common";

import CreateRecurringModificationButton from "./CreateRecurringModificationButton";
import LeaveFile from "../../../../../../../components/common/LeaveFile";
import React from "react";
import UpdateRecurringModificationButton from "./UpdateRecurringModificationButton";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const RecurringModificationModal = ({
  open,
  setOpen,
  modification,
  employeeId,
  setModification,
  isEarning,
}) => {
  const { t } = useTranslation(["employeeProfile", "employeeSalary"]);

  const handleClose = () => {
    setModification({
      amount: 0.0,
      reason: "",
      startDate: new Date(),
      endDate: null,
      receiveDuringVacation: true,
      attachment: "",
      employeeId: employeeId,
    });
    setOpen(false);
  };

  const handleSwitchChange = () => {
    setModification({
      ...modification,
      receiveDuringVacation: !modification.receiveDuringVacation,
    });
  };

  const title = isEarning
    ? t("employeeProfile:addRecurringEarning")
    : t("employeeProfile:addRecurringDeduction");

  return (
    <CompanyStructureModal
      open={open}
      onClose={() => setOpen(false)}
      fadeIn={open}
      title={title}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid
          item
          container
          spacing={3}
          justifyContent="center"
          style={{ margin: 4 }}
        >
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("employeeSalary:description")}
              value={modification.reason}
              onChange={(e) =>
                setModification({ ...modification, reason: e.target.value })
              }
              type="text"
              id="description"
              autoComplete="off"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("employeeSalary:amount")}
              type="number"
              value={
                isEarning ? modification.amount : -Math.abs(modification.amount)
              }
              onChange={(e) =>
                setModification({
                  ...modification,
                  amount: e.target.value,
                })
              }
              id="amount"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePickerField
              label={t("employeeSalary:startDate")}
              value={
                new Date(modification.startDate) < new Date()
                  ? new Date()
                  : new Date(modification.startDate)
              }
              helperText={t("employeeSalary:startNote")}
              minDate={new Date()}
              onChange={(e) =>
                setModification({
                  ...modification,
                  startDate: moment(e).format("yyyy-MM-DD"),
                })
              }
              disableToolbar={false}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePickerField
              label={t("employeeSalary:endDate")}
              value={
                modification.endDate ? new Date(modification.endDate) : null
              }
              helperText={t("employeeSalary:noEnd")}
              minDate={
                modification.startDate &&
                new Date(modification.startDate) >= new Date()
                  ? new Date(modification.startDate)
                  : new Date()
              }
              onChange={(e) =>
                setModification({
                  ...modification,
                  endDate: moment(e).format("yyyy-MM-DD"),
                })
              }
              disableToolbar={false}
            />
          </Grid>

          <Grid item md={6}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={modification.receiveDuringVacation}
                  onChange={() => handleSwitchChange()}
                />
              }
              labelPlacement="start"
              label={
                <Typography variant="subtitle2" color="textPrimary">
                  {t("employeeSalary:includeOnVacation")}
                </Typography>
              }
            />
          </Grid>

          <Grid item md={6}>
            <LeaveFile
              value={modification.attachment}
              setValue={(newFile) =>
                setModification({ ...modification, attachment: newFile })
              }
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} style={{ margin: 10 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width={"100%"}
          >
            {modification.id ? (
              <UpdateRecurringModificationButton
                modification={modification}
                isEarning={isEarning}
                handleClose={handleClose}
              />
            ) : (
              <CreateRecurringModificationButton
                modification={modification}
                isEarning={isEarning}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};

export default RecurringModificationModal;
