import { Button, Card, CardHeader } from "@material-ui/core";
import React, { useState } from "react";

import ApproversForm from "./ApproversForm";
import { CompanyStructureModal } from "../../../../../../components/common";
import Row from "./Row";
import Table from "../../../../common/Table";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const RequestApprovals = ({ approverRules }) => {
  const { t } = useTranslation("common");
  const [openModal, setOpenModal] = useState(false);

  const labels = [
    t("common:departments"),
    t("common:locations"),
    t("common:user"),
    t("common:action"),
  ];

  const sortedRequestsApprovers = approverRules?.map((obj) => {
    const sortedUsers = obj?.users?.slice()?.sort((a, b) => {
      if (a.employee === null && b.employee !== null) {
        return 1; // a comes after b
      }
      if (a.employee !== null && b.employee === null) {
        return -1; // a comes before b
      }
      return 0; // no change to order
    });
    return { ...obj, users: sortedUsers };
  });

  const tableBody = sortedRequestsApprovers?.map((rule) => {
    return <Row key={rule?.id} rule={rule} />;
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t("requestApprovals")}
          // subheader={t("descriptions")}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              {t("add")}
            </Button>
          }
        />

        <Table
          data={approverRules}
          labels={labels}
          body={tableBody}
          hasActionLabel
        />
      </Card>
      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("addRule")}
      >
        <ApproversForm setOpen={setOpenModal} />
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  approverRules: company.approverRules,
});

export default connect(mapStateToProps)(RequestApprovals);
