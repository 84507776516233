import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";

import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { CompanyStructureModal } from "../../../common";
import DateRangeInput from "../../../common/DateRangePicker";
import { FormInputField } from "../../../common";
import HolidayTable from "./HolidayTable";
import { connect } from "react-redux";
import moment from "moment";
import { updateOrCreateOfficialHoliday } from "../../../../redux/actions";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const Holidays = ({ updateOrCreateOfficialHoliday }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const [holiday, setHoliday] = useState({
    name: "",
    nameAr: "",
    start: moment(new Date()).format("yyyy-MM-DD"),
    end: moment(new Date()).format("yyyy-MM-DD"),
  });
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation(["companyProfile", "common"]);

  const isEnglish = language === "en";

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Box minHeight={"400px"} paddingY={5} paddingX={matchesXS ? 4 : 10}>
        <Box clone display="flex" flexDirection="row-reverse" paddingTop={2}>
          <Grid item md={12} xs={10}>
            <Button
              endIcon={<Add />}
              onClick={() => setOpen(true)}
              color="primary"
              variant="contained"
            >
              {t("companyProfile:addHoliday")}
            </Button>
          </Grid>
        </Box>

        <HolidayTable setHoliday={setHoliday} open={open} setOpen={setOpen} />
      </Box>
      <CompanyStructureModal
        open={open}
        onClose={() => setOpen(false)}
        fadeIn={open}
        title={t("companyProfile:addHoliday")}
      >
        <Grid container justifyContent="center">
          <Grid item container md={12} style={{ margin: 10 }} spacing={3}>
            {error && (
              <Grid item md={8} xs={12}>
                <Alert onClick={() => setError("")} severity="error">
                  {error}
                </Alert>
              </Grid>
            )}
            <Grid item md={6} xs={6}>
              <FormInputField
                isRequired
                label={t("common:name")}
                name="name"
                type="text"
                onChange={(e) =>
                  setHoliday({ ...holiday, name: e.target.value })
                }
                value={holiday.name}
                id="name"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <FormInputField
                isRequired
                label={t("common:arabicName")}
                name="nameAr"
                type="text"
                onChange={(e) =>
                  setHoliday({ ...holiday, nameAr: e.target.value })
                }
                value={holiday.nameAr}
                id="nameAr"
                autoComplete="new-password"
              />
            </Grid>

            <Grid item md={12} xs={6}>
              <DateRangeInput
                onChange={(selectedDates) =>
                  setHoliday({
                    ...holiday,
                    start: moment(selectedDates[0]).format("yyyy-MM-DD"),
                    end: moment(selectedDates[1]).format("yyyy-MM-DD"),
                  })
                }
              />
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} style={{ margin: 10 }}>
            <Box
              display="flex"
              flexDirection={isEnglish ? "row" : "row-reverse"}
              justifyContent="center"
            >
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    updateOrCreateOfficialHoliday(holiday, () => setOpen(false))
                  }
                >
                  <Typography>
                    {/* {officialHoliday ? t("common:update") : t("common:confirm")} */}
                    {t("common:confirm")}
                  </Typography>
                </Button>
              </Box>

              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setHoliday({
                      name: "",
                      nameAr: "",
                      start: moment(new Date()).format("yyyy-MM-DD"),
                      end: moment(new Date()).format("yyyy-MM-DD"),
                    });
                    setOpen(false);
                  }}
                >
                  <Typography>{t("common:cancel")}</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  vacations: company.vacations,
});

const mapDispatchToProps = {
  updateOrCreateOfficialHoliday,
};

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
