import { Box, Button, Divider, Popover, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { logout } from "../../../../../../redux/actions";

const AccountPopover = ({ user, logout, anchorEl, onClose, open }) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ style: { width: 200 } }}
    >
      <Box padding={2}>
        <Typography variant="body1">{user?.fullName}</Typography>
        <Typography color="textSecondary" variant="body2">
          {user?.email}
        </Typography>
      </Box>
      {!user.isEmployee && (
        <>
          <Divider />
          <Box style={{ padding: 1 }}>
            <Button
              style={{
                borderRadius: 5,
                margin: 4,
                width: "100%",
              }}
              startIcon={<Settings />}
              onClick={() => {
                history.push("/settings");
                onClose();
              }}
            >
              {t("settings")}
            </Button>
          </Box>
        </>
      )}

      <Divider
        style={{ marginTop: "0 !important", marginBottom: "0 !important" }}
      />
      <Box display="flex" justifyContent="center" padding={1}>
        <Button onClick={() => logout(history)} color="inherit" size="small">
          {t("logout")}
        </Button>
      </Box>
    </Popover>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPopover);
