import React from "react";
import { TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CustomTableCell = ({
  children,
  colSpan,
  onClick,
  paddingBottom,
  paddingTop,
  forActionLabel,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  return (
    <TableCell
      onClick={onClick}
      colSpan={colSpan}
      style={{
        whiteSpace: "nowrap",
        borderBottom: "none",
        paddingBottom: { paddingBottom },
        paddingTop: { paddingTop },
        zIndex: 1302,
      }}
      align={
        forActionLabel ? "center" : isEnglish ? "left" : !isEnglish && "right"
      }
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
