import { Box, Hidden } from "@material-ui/core";
import React from "react";

import EmployeeAvatar from "../../EmployeeAvatar";

const PositionEmployees = ({ position }) => {
  return (
    <Hidden mdDown>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {position.employees.slice(0, 6).map((employee) => (
          <EmployeeAvatar
            employee={employee}
            key={employee.user ? employee.user?.fullName : employee?.fullName}
          />
        ))}
      </Box>
    </Hidden>
  );
};

export default PositionEmployees;
