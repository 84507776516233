import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Save } from "@material-ui/icons/";
//MaterialUI
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";
//Router
import { Link } from "react-router-dom";
import { Redirect, useHistory, useParams } from "react-router";

//Actions
import { updateEmployee } from "../../../../redux/actions";
import ContactInformation from "./Forms/ContactInformation";
//Components
import PersonalInformation from "./Forms/PersonalInformation";
import PointOfContact from "./Forms/PointOfContact";
import Salary from "./Forms/Salary";

const useStyles = makeStyles(
  () => ({
    button: {
      color: "white",
      backgroundColor: "#7cc777",
      "&:hover": {
        backgroundColor: "#7cc777",
      },
    },
  }),
  { index: 1 },
);

const EditEmployee = ({ employees, updateEmployee }) => {
  ReactGA.pageview(window.location.pathname);
  //Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeAdd"]);
  const isEnglish = language === "en";
  const hasSetEmployeeData = useRef(false);
  const { employeeId } = useParams();
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();

  //States
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [employee, setEmployee] = useState(
    employees.find((emp) => {
      return emp.id === employeeId;
    }),
  );

  const normalizeSchedules = () => {
    if (employee && employee.schedules) {
      return employee.schedules.map((schedule) => {
        return {
          days: schedule.days.map((day) => day.number),
          shifts: schedule.shifts.map((shift) => ({
            startTime: shift.startTime,
            endTime: shift.endTime,
          })),
        };
      });
    } else {
      return [];
    }
  };
  console.log(normalizeSchedules());
  const [personalInfo, setPersonalInfo] = useState({
    fullName: employee
      ? employee.user
        ? employee.user?.fullName
        : employee?.fullName
      : "",

    email: employee ? employee.user?.email : "",
    image: employee ? employee.image : null,
    nationality: employee ? employee.nationality.code : "",
    locations: employee
      ? employee.locations.map((location) => ({
          value: location.id,
          label: isEnglish ? location.name : location.nameAr,
        }))
      : [],
    departmentId: employee ? employee.department.id : "",
    positionId: employee ? employee.position.id : "",
    employmentStatus: employee ? employee.employmentStatus : "",
    gender: employee ? employee.gender : "",
    maritalStatus: employee ? employee.maritalStatus : "",
    dateOfBirth: employee ? employee.dateOfBirth : null,
    employmentDate: employee ? employee.employmentDate : null,
    referenceNumber: employee ? employee?.referenceNumber : employee?.id,
    schedules: employee ? employee?.schedules : [],
  });

  const [contactInfo, setContactInfo] = useState({
    country: employee ? employee.address?.country?.code : "",
    phoneNumber: employee ? employee.phoneNumber : "",
    address1: employee ? employee.address?.address1 : "",
    address2: employee ? employee.address?.address2 : "",
    cityOrTown: employee ? employee.address?.cityOrTown : "",
    postcode: employee ? employee.address?.postcode : "",
    zipCode: employee ? employee.address?.zipCode : "",
    stateOrProvince: employee ? employee.address?.stateOrProvince : "",
    paciNumber: employee ? employee.address?.paciNumber : "",
  });
  const [poc, setPoc] = useState({
    name: employee ? employee.poc?.name : "",
    email: employee ? employee.poc?.email : "",
    phoneNumber: employee ? employee.poc?.number : "",
    country: employee ? employee.poc?.address?.country?.code : "",
    address1: employee ? employee.poc?.address?.address1 : "",
    address2: employee ? employee.poc?.address?.address2 : "",
    cityOrTown: employee ? employee.poc?.address?.cityOrTown : "",
    postcode: employee ? employee.poc?.address?.postcode : "",
    zipCode: employee ? employee.poc?.address?.zipCode : "",
    stateOrProvince: employee ? employee.poc?.address?.stateOrProvince : "",
    paciNumber: employee ? employee.poc?.address?.paciNumber : "",
  });
  const [salary, setSalary] = useState({
    bankName: employee ? employee.bankName : null,
    iban: employee ? employee.iban : null,
    baseSalary: employee ? employee.baseSalary : null,
  });

  useEffect(() => {
    let foundEmployee = employees.find((emp) => emp.id === employeeId);

    if (foundEmployee && !hasSetEmployeeData.current) {
      const normalizedLocations = foundEmployee.locations.map((location) => ({
        value: location.id,
        label: isEnglish ? location.name : location.nameAr,
      }));

      setEmployee(foundEmployee);
      setPersonalInfo({
        id: foundEmployee.id,
        fullName: foundEmployee.user
          ? foundEmployee.user?.fullName
          : foundEmployee?.fullName,
        email: foundEmployee.user?.email,
        image: foundEmployee.image,
        nationality: foundEmployee.nationality.code,
        locationId: foundEmployee.location?.id,
        locations: normalizedLocations,
        departmentId: foundEmployee.department.id,
        positionId: foundEmployee.position.id,
        employmentStatus: foundEmployee.employmentStatus,
        note: foundEmployee.note,
        gender: foundEmployee.gender,
        maritalStatus: foundEmployee.maritalStatus,
        dateOfBirth: foundEmployee.dateOfBirth,
        employmentDate: foundEmployee.employmentDate,
        leaveDate: foundEmployee.leaveDate,
        referenceNumber: foundEmployee.referenceNumber
          ? foundEmployee.referenceNumber
          : foundEmployee.id,
        schedules: foundEmployee.schedules,
      });
      setContactInfo({
        country: foundEmployee.address?.country?.code,
        phoneNumber: foundEmployee.phoneNumber,
        address1: foundEmployee.address?.address1,
        address2: foundEmployee.address?.address2,
        cityOrTown: foundEmployee.address?.cityOrTown,
        postcode: foundEmployee.address?.postcode,
        zipCode: foundEmployee.address?.zipCode,
        stateOrProvince: foundEmployee.address?.stateOrProvince,
        paciNumber: foundEmployee.address?.paciNumber,
      });
      setPoc({
        name: foundEmployee.poc?.name,
        email: foundEmployee.poc?.email,
        phoneNumber: foundEmployee.poc?.number,
        country: foundEmployee.poc?.address?.country?.code,
        address1: foundEmployee.poc?.address?.address1,
        address2: foundEmployee.poc?.address?.address2,
        cityOrTown: foundEmployee.poc?.address?.cityOrTown,
        postcode: foundEmployee.poc?.address?.postcode,
        zipCode: foundEmployee.poc?.address?.zipCode,
        stateOrProvince: foundEmployee.poc?.address?.stateOrProvince,
        paciNumber: foundEmployee.poc?.address?.paciNumber,
      });
      setSalary({
        bankName: foundEmployee.bankName,
        iban: foundEmployee.iban,
        baseSalary: foundEmployee.baseSalary,
      });
      hasSetEmployeeData.current = true;
    }
  }, [employees, employeeId, isEnglish]);

  const handleChange =
    (setState) =>
    ({ target: { name, value } }) =>
      setState((state) => ({ ...state, [name]: value }));

  const handleValueChange = (setState) => (name) => (value) =>
    setState((state) => ({ ...state, [name]: value }));

  const handleSubmit = async () => {
    const { phoneNumber, ...address } = contactInfo;
    const { baseSalary, bankName, iban } = salary;
    const { name, email, phoneNumber: number, ...pocAddress } = poc;

    let pocCountry = pocAddress.country === "NK" ? null : pocAddress.country;

    let country = address.country === "NK" ? null : address.country;
    let addressTemp = {
      ...address,
      country,
    };

    const normalizedLocations = personalInfo.locations.map((loc) => +loc.value);

    const normalizedPersonalInfo = {
      ...personalInfo,
      locations: normalizedLocations,
      schedules: normalizeSchedules(),
    };

    const data = {
      id: employee.id,
      ...normalizedPersonalInfo,
      phoneNumber,
      address: addressTemp,
      baseSalary,
      bankName,
      iban,
      poc: {
        name,
        email,
        number,
        address: { ...pocAddress, country: pocCountry },
      },
    };

    updateEmployee(data, history);
  };

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const steps = [
    t("employeeAdd:personalInformation"),
    t("employeeAdd:contactInformation"),
    t("employeeAdd:pointOfContact"),
    t("employeeAdd:salary"),
  ];

  const getStepForm = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalInformation
            state={personalInfo}
            handleChange={handleChange(setPersonalInfo)}
            handleValueChange={handleValueChange(setPersonalInfo)}
          />
        );
      case 1:
        return (
          <ContactInformation
            state={contactInfo}
            handleChange={handleChange(setContactInfo)}
            handleValueChange={handleValueChange(setContactInfo)}
          />
        );

      case 2:
        return (
          <PointOfContact
            state={poc}
            handleChange={handleChange(setPoc)}
            handleValueChange={handleValueChange(setPoc)}
          />
        );
      case 3:
        return (
          <Salary
            state={salary}
            setState={setSalary}
            handleChange={handleChange(setSalary)}
          />
        );
      default:
        break;
    }
  };

  const isOptionalStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return false;
      case 1:
        return true;
      case 2:
        return true;
      case 3:
        return false;
      default:
        break;
    }
  };

  const hasSkippedStep = (step) => skipped.has(step);

  const handleNext = () => {
    if (hasSkippedStep(activeStep)) {
      setSkipped((prev) => {
        const newSkipped = new Set(prev.values());
        newSkipped.delete(activeStep);
        return newSkipped;
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const stepsList = steps.map((label, index) => {
    const stepProps = {};
    const labelProps = {};
    if (isOptionalStep(index)) {
      labelProps.optional = (
        <Typography
          key={label}
          variant="subtitle2"
          color="textSecondary"
          align={matchesMD ? "flex-start" : "center"}
        >
          {t("common:optional")}
        </Typography>
      );
    }
    if (hasSkippedStep(index)) stepProps.completed = false;
    return (
      <Step key={label} {...stepProps}>
        <StepLabel {...labelProps}>
          <Typography
            variant="h3"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
          >
            {label}
          </Typography>
        </StepLabel>
      </Step>
    );
  });

  if (!employee) return <Redirect to={"/employeesList"} />;

  return (
    <Grid container justifyContent={matchesMD ? "center" : "flex-start"}>
      <Grid item md={11} xs={12}>
        <Box clone bgcolor="white" borderRadius={20} padding={5} marginY={5}>
          <Grid container justifyContent="center">
            <form noValidate onSubmit={handleSubmit}>
              <Stepper
                orientation={matchesMD ? "vertical" : "horizontal"}
                activeStep={activeStep}
              >
                {stepsList}
              </Stepper>

              <Grid item md={12} xs={9}>
                {getStepForm(activeStep)}
              </Grid>

              <Grid item md={12} xs={9}>
                <Box paddingTop={4}>
                  <Grid
                    container
                    justifyContent={
                      activeStep === steps.length - 1 ? "flex-end" : "center"
                    }
                  >
                    <Grid item md={12} xs={9}>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <Grid item md={4} xs={6}>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            {t("common:back")}
                          </Button>
                          <Button
                            disabled={activeStep === steps.length - 1}
                            variant="text"
                            color="primary"
                            onClick={handleNext}
                          >
                            {t("common:next")}
                          </Button>
                        </Grid>
                        <Grid item md={4} xs={6}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                          >
                            <Button
                              component={Link}
                              to={`/employees/${employee.id}`}
                            >
                              {t("common:cancel")}
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.button}
                              size="large"
                              onClick={handleSubmit}
                              startIcon={<Save />}
                            >
                              {t("common:save")}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ employees }) => ({
  employees: employees.employeesDetails,
});

const mapDispatchToProps = {
  updateEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee);
