import * as yup from "yup";

export const companySchema = yup.object().shape({
  name: yup.string().required(),
  nameAr: yup.string().required(),
  logo: yup.string(),
  sectorId: yup.number().required(),
  address: yup.object().shape({
    country: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    cityOrTown: yup.string().required(),
    postcode: yup.string(),
    zipCode: yup.string(),
    stateOrProvince: yup.string().required(),
    paciNumber: yup.string(),
  }),
});

export const pocSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required().email(),
  number: yup.number().required(),
  address: yup.object().shape({
    country: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    cityOrTown: yup.string().required(),
    postcode: yup.string(),
    zipCode: yup.string(),
    stateOrProvince: yup.string().required(),
    paciNumber: yup.string(),
  }),
});
