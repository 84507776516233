import {ArrowBackIos, ArrowForwardIos} from '@material-ui/icons'
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core'

import {Link} from 'react-router-dom'
import React from 'react'
import {useTranslation} from 'react-i18next'

const MoreButton = ({to}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation(['common'])
  const isEnglish = language === 'en'
  return (
    <NavigationButton
      disableRipple
      component={Link}
      to={to}
      variant="text"
      color="secondary"
      endIcon={isEnglish ? <ArrowForwardIos /> : <ArrowBackIos />}
    >
      {t('common:more')}
    </NavigationButton>
  )
}

const HomeCard = ({title, navigationAction, secondaryAction, children}) => {
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid item md={5} xs={9} style={{margin: 20}}>
      <Box
        paddingBottom={matchesMD ? 6 : 0}
        height={'35vh'}
        style={{backgroundColor: 'white', borderRadius: 15, padding: 20}}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Box flexGrow={1}>
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
          </Box>
          <Box flexGrow={0}>
            {navigationAction && <MoreButton to={navigationAction} />}
            {secondaryAction}
          </Box>
        </Box>
        {children}
      </Box>
    </Grid>
  )
}

export default HomeCard

const NavigationButton = withStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
  text: {
    fontSize: theme.typography.subtitle2,
    opacity: 0.8,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
}))(Button)
