//MaterialUI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
//Components
import { CustomTableCell, CustomTableRow, DonutChart } from "../../../common";

import ExpirationDocuments from "./ExpDocuments";
import { NotesSharp } from "@material-ui/icons";
import React from "react";
import SectionTitle from "./SectionTitle";
import VacationRequest from "./VacationReq";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const Overview = ({ profile }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["employeeHome", "common"]);
  const isEnglish = language === "en";

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  if (!profile) return <Typography> Loading</Typography>;

  let availableBalance = profile.leaveBalance.annualBalance;
  let casualBalance = profile.leaveBalance.casualBalance;
  let sickBalance = profile.leaveBalance.sickBalance;
  // let burned = profile.leaveBalance.annualBalanceUncapped

  let usedBalance = 0;

  profile.leaveBalance.leavedebits.forEach((debit) => {
    if (debit.isCounted) usedBalance += debit.amount;
  });

  let vacationRequests = profile.requests.filter(
    (request) => request.leaveRequest
  );

  return (
    <Box minHeight={"500px"} paddingX={matchesMD ? 2 : 6}>
      <SectionTitle
        title={t("employeeHome:leave/leaves")}
        icon={<NotesSharp color="primary" />}
      />

      <Box
        display={matchesXS ? undefined : "flex"}
        flexDirection={matchesXS ? undefined : "row"}
        justifyContent="center"
      >
        <Grid item md={12} xs={12}>
          <DonutChart
            radius={20}
            label={({ dataEntry }) =>
              `${dataEntry.title}  (${dataEntry.value} ${t("common:days")})`
            }
            labels={[
              {
                label: t("common:availableCredit"),
                value: availableBalance,
              },
              {
                label: t("common:usedCredit"),
                value: usedBalance,
              },
            ]}
          />
        </Grid>

        <Box
          clone
          paddingLeft={matchesXS ? undefined : isEnglish ? 5 : 0}
          paddingRight={matchesXS ? undefined : isEnglish ? 0 : 5}
          paddingY={matchesXS ? undefined : 10}
          maxWidth={"100%"}
        >
          <Grid item md={12} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {t("common:annual")}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {availableBalance}
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                  <CustomTableRow>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {t("common:casual")}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {casualBalance}
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                  <CustomTableRow>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {t("common:sick")}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? "left" : "right"}
                      >
                        {sickBalance}
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                  {/* Vacation days burned */}
                  {/* <CustomTableRow>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? 'left' : 'right'}
                      >
                        {t('common:burned')}
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align={isEnglish ? 'left' : 'right'}
                      >
                        {burned}
                      </Typography>
                    </CustomTableCell>
                  </CustomTableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Box>

      <Grid item md={12}>
        <VacationRequest
          title={t("common:vacationRequest")}
          icon={<NotesSharp color="primary" />}
          requests={vacationRequests}
        />
      </Grid>

      <Grid container>
        <Grid item md={12}>
          <SectionTitle
            title={t("common:documentsExpiration")}
            icon={<NotesSharp color="primary" />}
          />
          <ExpirationDocuments />
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ employee }) => ({
  profile: employee.profile,
});
export default connect(mapStateToProps)(Overview);
