import { filterEmployeesByDepartment } from "../../../helpers";
import { filterEmployeesByLocation } from "../../../helpers";
import { filterEmployeesByPosition } from "../../../helpers";
import { filterEmployeesByQuery } from "../../../helpers";
import { filterEmployeesByStatus } from "../../../helpers";

export const filterEmployees = (
  employees,
  selectedDepartments,
  selectedPositions,
  selectedStatus,
  selectedLocations,
  query
) => {
  let departmentEmployees = filterEmployeesByDepartment(
    employees,
    selectedDepartments
  );
  let positionEmployees = filterEmployeesByPosition(
    departmentEmployees,
    selectedPositions
  );
  let statusEmployees = filterEmployeesByStatus(
    positionEmployees,
    selectedStatus
  );
  let locationEmployees = filterEmployeesByLocation(
    statusEmployees,
    selectedLocations
  );

  return filterEmployeesByQuery(locationEmployees, query);
};
