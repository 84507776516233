// MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

import AutocompleteCategoriesInput from "../../../../../components/common/AutocompleteCategoriesInput";
import CompanyField from "../../../../../components/pages/CompanyProfile/CompanyField";
import { FormInputField } from "../../../../../components/common";
import { connect } from "react-redux";
import { countries } from "../../../../../components/common/DropDownsData";
import { updateCompanyAddress } from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CompanyAddress = ({
  address,
  addressState,
  setAddress,
  updateCompanyAddress,
  edit,
  zipCodeHelper,
  setZipCodeHelper,
  postcodeHelper,
  setPostcodeHelper,
}) => {
  const { t } = useTranslation(["companyProfile", "common"]);

  useEffect(() => {
    setAddress({ ...address, country: address?.country?.code });
  }, [address, setAddress]);

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" color="textPrimary">
              {t("companyProfile:companyAddress")}
            </Typography>
          </Grid>
          <CompanyField>
            <AutocompleteCategoriesInput
              isRequired={true}
              label={t("common:country")}
              name="country"
              onChange={(e) => {
                setAddress({ ...addressState, country: e });
                updateCompanyAddress({
                  ...addressState,
                  country: e === "NK" ? null : e,
                });
              }}
              value={addressState.country || "KW"}
              array={countries(t)}
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              label={t("common:address1")}
              id="address1"
              name="address1"
              onChange={(e) => {
                setAddress({ ...addressState, address1: e.target.value });
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  address1: e.target.value,
                })
              }
              value={addressState.address1 || "-"}
              autoComplete="new-password"
            />
          </CompanyField>

          <CompanyField>
            <FormInputField
              isRequired={true}
              label={t("common:address2")}
              id="address2"
              name="address2"
              onChange={(e) => {
                setAddress({ ...addressState, address2: e.target.value });
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  address2: e.target.value,
                })
              }
              value={addressState.address2 || "-"}
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              label={t("common:stateOrProvince")}
              id="stateOrProvince"
              name="stateOrProvince"
              onChange={(e) => {
                setAddress({
                  ...addressState,
                  stateOrProvince: e.target.value,
                });
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  stateOrProvince: e.target.value,
                })
              }
              value={addressState.stateOrProvince || "-"}
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              label={t("common:paciNumber")}
              name="paciNumber"
              onChange={(e) => {
                setAddress({
                  ...addressState,
                  paciNumber: e.target.value,
                });
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  paciNumber: e.target.value,
                })
              }
              value={addressState.paciNumber || "-"}
              id="paciNumber"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              label={t("common:cityOrTown")}
              name="cityOrTown"
              onChange={(e) => {
                setAddress({ ...addressState, cityOrTown: e.target.value });
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  cityOrTown: e.target.value,
                })
              }
              value={addressState.cityOrTown || "-"}
              id="cityOrTown"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              errors={postcodeHelper.length !== 0}
              helperText={postcodeHelper}
              label={t("common:postcode")}
              name="postcode"
              onChange={(e) => {
                setAddress({ ...addressState, postcode: e.target.value });

                const valid = /^(\d{5})?$/.test(e.target.value);

                if (!valid) {
                  setPostcodeHelper("Invalid Postcode format. Ex. 45450");
                } else {
                  setPostcodeHelper("");
                }
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  postcode: e.target.value,
                })
              }
              value={addressState.postcode}
              id="postcode"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              errors={zipCodeHelper.length !== 0}
              helperText={zipCodeHelper}
              label={t("common:zipcode")}
              name="zipCode"
              onChange={(e) => {
                setAddress({ ...addressState, zipCode: e.target.value });

                const valid = /^(\d{5})?$/.test(e.target.value);

                if (!valid) {
                  setZipCodeHelper("Invalid Zipcode format. Ex. 45405");
                } else {
                  setZipCodeHelper("");
                }
              }}
              onBlur={(e) =>
                updateCompanyAddress({
                  ...addressState,
                  zipCode: e.target.value,
                })
              }
              value={addressState.zipCode}
              id="zipCode"
              autoComplete="new-password"
            />
          </CompanyField>
        </Grid>
      </Grid>
    </Box>
  );
};

// const mapStateToProps = ({ company }) => ({
//   address: company.address,
// });

const mapDispatchToProps = {
  updateCompanyAddress,
};

export default connect(null, mapDispatchToProps)(CompanyAddress);
