import React from "react";

//MaterialUI
import { Grid, Box } from "@material-ui/core";

//Components
import { FormImageField } from "../../../common";

//Translation
import { useTranslation } from "react-i18next";

//Redux
import { connect } from "react-redux";

//Actions
import { updateCompanyProfile } from "../../../../redux/actions";

const CompanyImage = ({ updateCompanyProfile, company, edit }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} sm={12} xs={12}>
        <FormImageField
          disabled={!edit}
          label={t("uploadLogo")}
          name="logo"
          imageId={"one"}
          image={company?.logo ? company.logo : null}
          onChange={(e) =>
            updateCompanyProfile({
              ...company,
              logo: e.target.files[0],
            })
          }
        />
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyImage);
