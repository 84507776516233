//Material UI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import React from 'react'
//Translation
import {useTranslation} from 'react-i18next'

const PayrollTable = ({
  title,
  icon,
  tableRow,
  recurring = false,
  editable = false,
}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation('common')
  const isEnglish = language === 'en'

  let columns = [t('reason'), t('amount'), t('month')]
  if (recurring)
    columns = [t('reason'), t('amount'), t('startDate'), t('endDate')]

  if (editable) columns.push(t('edit'))

  const headerRow = columns.map(field => (
    <TableCell key={field} align={isEnglish ? 'left' : 'right'}>
      <Typography variant="subtitle2" color="textSecondary">
        {field}
      </Typography>
    </TableCell>
  ))

  return (
    <Grid item md={6} xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        width={'100%'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {icon}
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <Box clone bgcolor="inherit">
            <TableRow>{headerRow}</TableRow>
          </Box>
        </TableHead>
        <TableBody>{tableRow}</TableBody>
      </Table>
    </Grid>
  )
}

export default PayrollTable
