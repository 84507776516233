import { Button, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  DatePickerField,
  FormSelectInput,
} from "../../../../../components/common";
import { DepositSalaryModal } from "../../../common";
import DataVisualization from "./DataVisualization";
import Deductions from "./Deduction";
import Earnings from "./Earnings";
import { getModificationValues } from "./getModificationValues";
import RecurringDeductions from "./RecurringDeductions";
import RecurringEarnings from "./RecurringEarnings";
import { createPayslip } from "./utils";

const Payroll = ({ employee, deposits, nextDeposit }) => {
  console.log("employee======", nextDeposit);
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeSalary", "employeeProfile"]);
  const isEnglish = language === "en";

  const [deposit, setDeposit] = useState("All");
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [alert, setAlert] = useState("");

  const handleOpenDepositModal = () => {
    setOpenDepositModal(true);
  };

  const handleCloseDepositModal = () => {
    setOpenDepositModal(false);
  };

  const lastDepositMonth = deposits[0]?.month;
  const nextDepositMonth = lastDepositMonth
    ? moment(lastDepositMonth).add(1, "month").format("MMM-YYYY")
    : null;

  const [month, setMonth] = useState(moment().format("DD-MM-YYYY"));

  const depositsDropDown = [
    {
      label: `${t("employeeProfile:nextDeposit")} ${
        nextDepositMonth ? nextDepositMonth : moment().format("MMM-YYYY")
      }`,
      value: "All",
    },
    ...deposits.map((deposit) => {
      let date = new Date(deposit.month);
      return {
        label: moment(date).format("DD-MMM-YYYY"),
        value: deposit.id,
      };
    }),
    { label: t("customDates"), value: "Custom" },
  ];
  const x = deposits.find((_deposit) => _deposit.id === `${deposit}`);

  // prettier-ignore
  let recurringSalaryModifications = nextDeposit?.recurringSalaryModifications;
  let salaryModifications = nextDeposit?.salaryModifications;

  let earnings = [];
  let deductions = [];
  let recurringEarnings = [];
  let recurringDeductions = [];
  let initalTotalEarnings = 0.0;
  let initialTotalDeductions = 0.0;

  const [totalEarnings, totalDeductions] = getModificationValues(
    recurringSalaryModifications,
    salaryModifications,
    earnings,
    deductions,
    recurringEarnings,
    recurringDeductions,
    initalTotalEarnings,
    initialTotalDeductions,
    deposits,
    deposit,
    employee,
    month,
  );

  let enableActions = deposit === "All" || deposit === "Custom";

  const deposited = (status = "") => {
    if (status === "ALREADY_EXISTED") {
      setAlert(t("depositAlreadyExisted"));
    } else {
      setAlert(t("depositCreated"));
    }

    setTimeout(() => setAlert(""), 5000);
  };

  return (
    <>
      <Grid container spacing={8}>
        {alert && (
          <Grid item md={12} xs={12}>
            <Alert
              severity={
                alert === t("depositAlreadyExisted") ? "warning" : "success"
              }
            >
              {alert}
            </Alert>
          </Grid>
        )}
        <Grid item container justifyContent="flex-end" md={12}>
          <Button
            style={{ marginBottom: "10px" }}
            variant="contained"
            color="primary"
            onClick={() =>
              createPayslip(
                t,
                isEnglish,
                employee,
                earnings,
                deductions,
                recurringEarnings,
                recurringDeductions,
                totalEarnings,
                totalDeductions,
                deposit === "All" ? deposit.month : x.month,
              )
            }
          >
            {t("employeeSalary:payslipReport")}
          </Button>
          {enableActions && (
            <Grid item container justifyContent="flex-end" lg={12} xs={12}>
              <Button
                onClick={handleOpenDepositModal}
                variant="contained"
                color="primary"
              >
                {t("employeeProfile:deposit")}
              </Button>
            </Grid>
          )}
        </Grid>
        <DataVisualization
          employee={employee}
          totalEarnings={totalEarnings}
          totalDeductions={totalDeductions}
          deposits={deposits}
          nextDeposit={nextDeposit}
        />
        <Grid item container justifyContent="flex-end" lg={12} xs={12}>
          <Grid item md={4} xs={12}>
            <FormSelectInput
              label={t("employeeProfile:deposits")}
              selectOptions={depositsDropDown}
              value={deposit}
              onChange={setDeposit}
            />
          </Grid>
          {deposit === "Custom" && (
            <Grid item md={4} xs={12}>
              <Typography variant="p" color="primary">
                {t("common:month")}
              </Typography>
              <DatePickerField
                disableToolbar={false}
                yearMonthView={true}
                openTo="month"
                onChange={(e) => setMonth(e)}
                value={month}
              />
            </Grid>
          )}
        </Grid>
        <Grid item lg={6} xs={12}>
          <Earnings
            employee={employee}
            earnings={earnings}
            enableActions={enableActions}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Deductions
            employee={employee}
            deductions={deductions}
            enableActions={enableActions}
          />
        </Grid>
        {enableActions && (
          <>
            <Grid item lg={6} xs={12}>
              <RecurringEarnings
                employee={employee}
                recurringEarnings={recurringEarnings}
                enableActions={enableActions}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <RecurringDeductions
                employee={employee}
                recurringDeductions={recurringDeductions}
                enableActions={enableActions}
              />
            </Grid>
          </>
        )}
      </Grid>
      <DepositSalaryModal
        open={openDepositModal}
        onClose={handleCloseDepositModal}
        title={`${t("depositSalaryFor")} ${1} ${t("employeesObject")}:`}
        total={Number(employee.baseSalary)}
        deposited={deposited}
        selected={[employee]}
      />
    </>
  );
};

const mapStateToProps = ({ employees, auth }) => ({
  deposits: employees.deposits,
  nextDeposit: employees.nextDeposit,
  user: auth.user,
});

export default connect(mapStateToProps)(Payroll);
