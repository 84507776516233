import React from "react";
import { RequestsTable } from "../../../common";
import moment from "moment";
import { requestsStatus } from "../../../../../components/common/DropDownsData";
import { useTranslation } from "react-i18next";

const OvertimeRequests = ({ employee, month }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const headerLabels = [
    t("common:id"),
    t("common:date"),
    t("common:amount"),
    t("common:status"),
  ];

  let overtimeRequests = employee.requests
    .filter((request) => request.overtimeRequest)
    .filter(
      (request) =>
        month ===
        moment(new Date(request.overtimeRequest.date)).format("yyyy-MM")
    )
    .map((request) => {
      const status = () => {
        let statusObj = requestsStatus.find(
          (status) => status.value === request.status
        );
        if (isEnglish) return statusObj.name;
        return statusObj.nameAr;
      };
      return {
        id: request.id,
        date: moment(new Date(request.overtimeRequest.date)).format(
          "DD-MM-yyyy"
        ),
        amount: request.overtimeRequest.amount,
        status: status(),
      };
    });

  return (
    <RequestsTable
      title={t("overtimeRequest")}
      data={overtimeRequests}
      labels={headerLabels}
      toRow={"/request"}
    />
  );
};

export default OvertimeRequests;
