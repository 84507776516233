import { ExpandLess, ExpandMore, Send } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

const ResourcesAccordion = ({
  title,
  secondaryAction,
  children,
  hasAttachments,
  onClickIcon,
}) => {
  const [expandAccordion, setExpandAccordion] = useState(false);
  const handleExpand = () => {
    setExpandAccordion(!expandAccordion);
  };

  return (
    <Accordion
      expanded={expandAccordion}
      TransitionProps={{ timeout: 0, unmountOnExit: false }}
      elevation={0}
    >
      <AccordionSummary>
        <Grid container alignItems="space-between">
          <Grid item lg={1} xs={1}>
            {expandAccordion ? (
              <ExpandLess color="primary" onClick={handleExpand} />
            ) : (
              <ExpandMore
                color={hasAttachments ? "primary" : "secondary"}
                onClick={hasAttachments ? handleExpand : () => {}}
              />
            )}
          </Grid>
          <Grid item lg={10} xs={10}>
            {title}
          </Grid>
          <Grid item container direction="row-reverse" lg={1} xs={1}>
            <IconButton disableRipple size="small" onClick={onClickIcon}>
              <Send color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ResourcesAccordion;

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "none",
      transition: "none",
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: "#F6F8FA",
    display: "block",
  },
})(MuiAccordionDetails);
