//Material UI
import { Avatar, Box, Typography } from "@material-ui/core";
//Moment
import moment from "moment";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import { CustomTableCell, CustomTableRow } from "../../common";

const RequestItem = ({ request, requestsStatus, requestsKind }) => {
  const status = requestsStatus.find(
    (_status) => _status.value === request.status
  );

  const kind = requestsKind.find((_kind) => _kind.value === request.kind);

  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <Box clone whiteSpace="nowrap">
      <CustomTableRow to={`request/${request.id}`}>
        <CustomTableCell>{request.id}</CustomTableCell>
        <CustomTableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={request.employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {request.employee.user
                  ? request.employee.user?.fullName
                  : request.emplyee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish
            ? request.employee.department.name
            : request.employee.department.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish
            ? request.employee.position.name
            : request.employee.position.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? status.name : status.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {moment(new Date(request.createdOn)).format("DD-MM-yyyy")}{" "}
        </CustomTableCell>
        <CustomTableCell>
          {" "}
          {isEnglish ? kind?.name : kind?.nameAr}
        </CustomTableCell>
      </CustomTableRow>
    </Box>
  );
};

export default RequestItem;
