//MaterialUI
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import { FormInputField } from "../../../../common";
import { connect } from "react-redux";
//Actions
import { updateCompanyProfile } from "../../../../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

//Components

const AutoCheckout = ({ company, updateCompanyProfile, edit }) => {
  const { t } = useTranslation("common");

  const [enableAutomaticCheckout, setEnableAutomaticCheckout] = useState({
    autoCheckout: company?.autoCheckout,
    autoCheckoutAfter: company?.autoCheckoutAfter,
  });

  const handleSwitchChange = () => {
    setEnableAutomaticCheckout({
      ...enableAutomaticCheckout,
      autoCheckout: !enableAutomaticCheckout.autoCheckout,
    });

    updateCompanyProfile({
      ...company,
      autoCheckout: !enableAutomaticCheckout.autoCheckout,
    });
  };

  return (
    <>
      <Box clone paddingX={2} paddingTop={4}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={3}>
              <FormControlLabel
                disabled={!edit}
                control={
                  <Switch
                    color="primary"
                    checked={enableAutomaticCheckout.autoCheckout}
                    onChange={() => handleSwitchChange()}
                  />
                }
                labelPlacement="start"
                label={
                  <Typography variant="subtitle2" style={{ color: "#3D5E77" }}>
                    {t("enableAutomaticCheckout")}
                  </Typography>
                }
              />
            </Grid>
          </Grid>

          {enableAutomaticCheckout.autoCheckout && (
            <Grid item md={6}>
              <FormInputField
                isRequired
                label={t("checkOutLimits")}
                name="checkinLimits"
                disabled={!edit || !enableAutomaticCheckout.autoCheckout}
                onChange={(e) => {
                  setEnableAutomaticCheckout({
                    ...enableAutomaticCheckout,
                    autoCheckoutAfter: e.target.value,
                  });
                  updateCompanyProfile({
                    ...company,
                    autoCheckoutAfter: e.target.value,
                  });
                }}
                value={enableAutomaticCheckout.autoCheckoutAfter}
                helperText={t("enableAutomaticCheckoutAfter")}
                id="automaticCheckout"
                autoComplete="new-password"
                type="number"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoCheckout);
