import moment from "moment";

const isBetween = (date, startDate, endDate) => {
  return date.isBetween(startDate, endDate, "days", "[]") ? true : false;
};

export const isVacationsError = (
  employee,
  date,
  amount,
  endDate,
  id = null,
) => {
  const leaveDebits = employee.leaveBalance.leavedebits;

  let error = leaveDebits
    .filter((leave) =>
      id ? leave.id !== id : leave || leave.balanceAdjustment === false,
    )
    .find((leave) => {
      const leaveStartDate = moment(leave.date, "YYYY-MM-DD");
      let leaveEndDate = moment(leave.endDate, "YYYY-MM-DD");
      const leaveAmount = leave.amount;

      if (leaveAmount > 0) {
        leaveEndDate = moment(leave.date).add(leaveAmount - 1, "days");
      }

      let newLeaveDate = moment(date, "YYYY-MM-DD");
      let newLeaveEndDate = moment(endDate, "YYYY-MM-DD");

      if (amount && amount > 0) {
        newLeaveEndDate = moment(date).add(amount - 1, "days");
      }

      if (
        isBetween(newLeaveDate, leaveStartDate, leaveEndDate) ||
        isBetween(newLeaveEndDate, leaveStartDate, leaveEndDate) ||
        isBetween(leaveStartDate, newLeaveDate, newLeaveEndDate) ||
        isBetween(leaveEndDate, newLeaveDate, newLeaveEndDate)
      ) {
        return true;
      }
      return false;
    });

  return error ? true : false;
};
