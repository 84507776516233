import React from "react";
import { Box, Drawer, Hidden, List, styled, ListItem } from "@material-ui/core";

// Components
import NavLink from "./NavLink";
import LanguageButton from "./LanguageButton";

const StyledList = styled(List)({
  width: 256,
});

const MobileNav = ({ navItems, onMobileClose, openMobile }) => {
  const navLinks = navItems.map((item) => (
    <ListItem key={item.title} disableGutters>
      <NavLink
        style={{ textDecoration: "none", color: "#246ea0" }}
        {...item}
        onClick={onMobileClose}
      />
    </ListItem>
  ));

  return (
    <Hidden mdUp>
      <Drawer anchor="left" onClose={onMobileClose} open={openMobile}>
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={2}>
            <StyledList>
              {navLinks}
              <ListItem onClick={onMobileClose}>
                <LanguageButton />
              </ListItem>
            </StyledList>
          </Box>
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default MobileNav;
