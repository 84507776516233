import HomeCard from "../../common/HomeCard";
import { Link } from "react-router-dom";
import React from "react";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
import { isProfileRequestPending } from "../../../helpers/isProfileRequestPending";
import { normalizeProfileRequest } from "../../../helpers/normalizeProfileRequest";
import { sortByNewest } from "../../../helpers/sortByNewest";
import { useTranslation } from "react-i18next";

const Profile = ({ requests }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);

  const isEnglish = language === "en";

  const labels = [
    t("common:employeeName"),
    t("common:lastModified"),
    t("common:lastModifiedBy"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={(label, +i)} />
  ));

  const data = requests
    .filter((request) => {
      if (isProfileRequestPending(request)) {
        return request;
      }
      return false;
    })

    .map((request) => normalizeProfileRequest(request))
    .sort((currentReq, nextRequest) => sortByNewest(currentReq, nextRequest));

  const rows = data.map((entry, i) => {
    return (
      <TableRow
        component={Link}
        to={`request/${entry.id}`}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={4} align={isEnglish ? "left" : "right"}>
          {entry.name}
        </RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.lastModified}</RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.lastModifiedBy}</RequestTableCell>
      </TableRow>
    );
  });

  return (
    <HomeCard
      title={t("home:latestProfileUpdateRequest")}
      navigationAction={"/requests"}
    >
      <StyledHomeTable header={header} body={rows} data={data} />
    </HomeCard>
  );
};

export default Profile;
