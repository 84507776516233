import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { createAttendacePdf, getXlsxData } from "../utils";
import {
  fetchEmployeesMutlipleAttendances,
  fetchEmployeesMutlipleAttendancesLoading,
} from "../../../../../../redux/actions";

import { ArrowBack } from "@material-ui/icons";
import {
  CompanyStructureModal,
  DatePickerField,
} from "../../../../../../components/common";
// import DateRangeInput from "../../../../../../components/common/DateRangePicker";
import { REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES } from "../../../../../../redux/actions/types";
import React from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const AttendanceReportModal = ({
  openModal,
  setOpenModal,
  attendancePageMonth,
  setReportEmployees,
  reportEmployees,
  attendancePageEmployees,
  fetchEmployeesMutlipleAttendancesLoading,
  multipleMonthsAttendances,
  multipleMonthsAttendancesLoading,
  department,
  position,
  location,
  query,
  company,
  employee,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const isEnglish = language === "en";

  const dispatch = useDispatch();

  const [monthToDisplay, setMonthToDisplay] = useState(
    `${moment(attendancePageMonth).format("yyyy-MM-DD")}`,
  );
  const [displayCustomDates, setDisplayCustomDates] = useState(false);
  const [dateRange, setDateRange] = useState({
    date: moment(attendancePageMonth).format("yyyy-MM-DD"),
    endDate: null,
  });

  const handleNavigateBack = () => {
    setDateRange({
      date: moment(attendancePageMonth).format("yyyy-MM-DD"),
      endDate: null,
    });
    setMonthToDisplay(`${moment(attendancePageMonth).format("yyyy-MM-DD")}`);
    setDisplayCustomDates(false);
  };

  const closeModal = () => {
    setReportEmployees(attendancePageEmployees);
    setDateRange({
      date: moment(dateRange.date).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD"),
    });
    setMonthToDisplay(`${moment(attendancePageMonth).format("yyyy-MM-DD")}`);
    setDisplayCustomDates(false);
    setOpenModal(false);
    dispatch({ type: REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES });
  };

  const handleSubmitDateRange = () => {
    let allMonths = [];

    let startMonth = moment(dateRange.date, "yyyy-MM");
    let endMonth = dateRange.endDate
      ? moment(dateRange.endDate, "yyyy-MM")
      : startMonth;

    allMonths.push(startMonth.format("yyyy-MM"));

    while (!startMonth.isSame(endMonth)) {
      startMonth = startMonth.add(1, "months");
      allMonths.push(startMonth.format("yyyy-MM"));
    }

    for (const idx in allMonths) {
      dispatch(
        fetchEmployeesMutlipleAttendances({
          dateMonth: allMonths[idx],
          employeeId: employee.id,
        }),
      ).then((data) => {
        let attendanceMonthResponse = moment(data[0]?.date).format("yyyy-MM");

        if (
          attendanceMonthResponse === endMonth.format("yyyy-MM") ||
          (!moment(dateRange.date, "yyyy-MM").isSame(endMonth) &&
            allMonths[idx] === endMonth.format("yyyy-MM")) ||
          idx === allMonths.length - 1
        ) {
          fetchEmployeesMutlipleAttendancesLoading(false);
        }
      });
    }

    setMonthToDisplay(
      dateRange.endDate
        ? `${moment(dateRange.date).format("yyyy-MM-DD")} - ${moment(
            dateRange.endDate,
          ).format("yyyy-MM-DD")}`
        : `${moment(dateRange.date).format("yyyy-MM-DD")}`,
    );

    setDisplayCustomDates(false);
  };

  useEffect(() => {
    const simplifyArray = (arr = []) => {
      const res = [];
      multipleMonthsAttendances.forEach((attendance) => {
        res.push(...attendance);
      });
      return res;
    };
    let employees = simplifyArray(multipleMonthsAttendances);

    const customDatesemployees = dateRange.endDate
      ? employees.filter((employee) => {
          let date = moment(dateRange.date);
          let endDate = moment(dateRange.endDate);
          let employeeDate = moment(employee.checkIn, "YYYY-MM-DD");

          return employeeDate.isBetween(date, endDate, null, "[]");
        })
      : employees;

    const employeesSortedByDate = customDatesemployees.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    setReportEmployees(employeesSortedByDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    department,
    isEnglish,
    location,
    multipleMonthsAttendances,
    position,
    query,
  ]);

  const createXlsx = () => {
    let xlsxData = getXlsxData(reportEmployees, t);
    console.log(xlsxData);
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(xlsxData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      moment().format("YYYY-MM-DD"),
    );

    XLSX.writeFile(workbook, "Attendance.xlsx");
  };

  return (
    <CompanyStructureModal
      open={openModal}
      onClose={closeModal}
      fadeIn={openModal}
      title={t("attendanceReport")}
    >
      {displayCustomDates ? (
        <>
          <Box>
            <Grid item md={12}>
              <IconButton color="primary" onClick={handleNavigateBack}>
                <ArrowBack />
              </IconButton>
            </Grid>
          </Box>
          <Box margin={4}>
            <Grid container>
              <Grid item md={12} xs={6}>
                {/* <DateRangeInput
                  maxDate={moment().format("yyyy-MM-DD")}
                  onChange={(selectedDates) => {
                    setDateRange({
                      date: moment(selectedDates[0]).format("yyyy-MM-DD"),
                      endDate: moment(selectedDates[1]).format("yyyy-MM-DD"),
                    });
                  }}
                /> */}
                <DatePickerField
                  label={t("startDate")}
                  value={dateRange.date}
                  onChange={(date) =>
                    setDateRange({
                      ...dateRange,
                      date: moment(date).format("YYYY-MM-DD"),
                    })
                  }
                />
                <DatePickerField
                  label={t("endDate")}
                  value={dateRange.endDate}
                  onChange={(date) =>
                    setDateRange({
                      ...dateRange,
                      endDate: moment(date).format("YYYY-MM-DD"),
                    })
                  }
                />
              </Grid>
              <Grid item container md={12} direction="row-reverse">
                <Grid item>
                  <Button
                    onClick={handleSubmitDateRange}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          {multipleMonthsAttendancesLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={40} />
            </Box>
          ) : (
            <>
              <Box paddingX={4}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h5">{`${t(
                      "date",
                    )} : ${monthToDisplay}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      disableRipple
                      variant="text"
                      color="primary"
                      style={{
                        textDecoration: "underline",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        setDisplayCustomDates(true);
                        dispatch({
                          type: REMOVE_ALL_EMPLOYEES_MUTLIPLE_ATTENDANCES,
                        });
                      }}
                    >
                      {t("customDates")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                margin={2}
              >
                <Box clone marginX={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      createAttendacePdf(
                        t,
                        isEnglish,
                        company,
                        department,
                        location,
                        position,
                        attendancePageMonth,
                        reportEmployees,
                      );
                      setOpenModal(false);
                    }}
                  >
                    <Typography>{t("generatePdf")}</Typography>
                  </Button>
                </Box>
                <Box clone marginX={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      createXlsx();
                      setOpenModal(false);
                    }}
                  >
                    <Typography>{t("generateXlsx")}</Typography>
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </CompanyStructureModal>
  );
};

const mapStateToProps = ({ employees }) => ({
  multipleMonthsAttendances: employees.multipleMonthsAttendances,
  multipleMonthsAttendancesLoading: employees.multipleMonthsAttendancesLoading,
});

const mapDispatchToProps = {
  fetchEmployeesMutlipleAttendancesLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttendanceReportModal);
