//MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

//Components
import CompanyField from "../../../../../components/pages/CompanyProfile/CompanyField";
import { FormInputField } from "../../../../../components/common";
import { connect } from "react-redux";
import { updatePoc } from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const ContactInformation = ({
  poc,
  pocState,
  setPoc,
  updatePoc,
  edit,
  emailHelper,
  setEmailHelper,
  phoneHelper,
  setPhoneHelper,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  useEffect(() => {
    setPoc({ ...poc });
  }, [poc, setPoc]);

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Typography variant="h4" color="textPrimary">
              {t("companyProfile:pointOfContact")}
            </Typography>
          </Grid>
          <CompanyField>
            <FormInputField
              isRequired
              label={t("common:name")}
              name="name"
              onChange={(e) => {
                setPoc({ ...pocState, name: e.target.value });
              }}
              onBlur={(e) =>
                updatePoc({
                  ...pocState,
                  name: e.target.value,
                })
              }
              value={pocState.name}
              id="commonname"
              autoComplete="new-password"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired={true}
              type="email"
              label={t("common:email")}
              errors={emailHelper.length !== 0}
              helperText={emailHelper}
              name="email"
              onChange={(e) => {
                setPoc({ ...pocState, email: e.target.value });

                const valid =
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    e.target.value
                  );

                if (!valid) {
                  setEmailHelper("Invalid email format. Ex. khalid@staffi.com");
                } else {
                  setEmailHelper("");
                }
              }}
              onBlur={(e) =>
                updatePoc({
                  ...pocState,
                  email: e.target.value,
                })
              }
              value={pocState.email}
              id="email"
              autoComplete="off"
            />
          </CompanyField>
          <CompanyField>
            <FormInputField
              isRequired
              label={t("common:phoneNumber")}
              name="number"
              errors={phoneHelper.length !== 0}
              helperText={phoneHelper}
              onChange={(e) => {
                setPoc({ ...pocState, number: e.target.value });

                const valid = /^[0-9]{8}$/.test(e.target.value);

                if (!valid) {
                  setPhoneHelper("Invalid phone format. Ex. 9990-0000");
                } else {
                  setPhoneHelper("");
                }
              }}
              onBlur={(e) =>
                updatePoc({
                  ...pocState,
                  number: e.target.value,
                })
              }
              value={pocState.number}
              id="number"
              autoComplete="off"
            />
          </CompanyField>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {
  updatePoc,
};

export default connect(null, mapDispatchToProps)(ContactInformation);
