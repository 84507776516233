import {
  CustomTableCell,
  CustomTableRow,
} from "../../../../../components/common";

import { Delete, Edit } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const HolidayRow = ({ holiday, setHoliday, setOpen, setOpenDeleteModal }) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  return (
    <CustomTableRow>
      <CustomTableCell>
        {isEnglish ? holiday.name : holiday.nameAr}
      </CustomTableCell>
      <CustomTableCell>{holiday.start}</CustomTableCell>
      <CustomTableCell>{holiday.end}</CustomTableCell>
      <CustomTableCell>
        <IconButton
          onClick={() => {
            setHoliday(holiday);
            setOpen(true);
          }}
        >
          <Edit color="primary" />
        </IconButton>
      </CustomTableCell>
      <CustomTableCell>
        <IconButton
          onClick={() => {
            setHoliday(holiday);
            setOpenDeleteModal(true);
          }}
        >
          <Delete color="error" />
        </IconButton>
      </CustomTableCell>
    </CustomTableRow>
  );
};

export default HolidayRow;
