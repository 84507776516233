import React from "react";

//Components
import { FormInputField } from "../../../../common";
import AutocompleteCategoriesInput from "../../../../common/AutocompleteCategoriesInput";

//MaterialUI
import { Box, Grid, Typography } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";
import { countries } from "../../../../common/DropDownsData";

const AddressInformation = ({
  values,
  edit,
  updatedProfile,
  setUpdatedProfile,
  onBlur,
  errors,
  touched,
  onChange,
  profile,
}) => {
  const { t } = useTranslation("common");

  const handleChange = (field, value) => {
    if (value !== profile.address[field]) {
      updatedProfile.address
        ? setUpdatedProfile({
            ...updatedProfile,
            address: {
              ...updatedProfile.address,
              [field]: value,
            },
          })
        : setUpdatedProfile({
            ...updatedProfile,
            address: {
              [field]: value,
            },
          });
    } else {
      let updatedFields = Object.keys(updatedProfile.address);
      let newObj = {};
      if (updatedFields.length > 1) {
        updatedFields.forEach((key) => {
          if (key !== field)
            newObj = { ...newObj, [key]: updatedProfile.address[key] };
        });
        setUpdatedProfile({ ...updatedProfile, address: newObj });
      } else {
        Object.keys(updatedProfile).forEach((key) => {
          if (key !== "address")
            newObj = { ...newObj, [key]: updatedProfile[key] };
        });
        setUpdatedProfile(newObj);
      }
    }
  };

  return (
    <Grid item md={12} xs={12}>
      <Typography variant="h4" color="textPrimary">
        {t("employeeDashboard:addressInformation")}
      </Typography>

      <Box clone paddingY={2}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <AutocompleteCategoriesInput
              disabled={edit ? false : true}
              name="country"
              isRequired
              value={
                updatedProfile["address"]
                  ? updatedProfile.address["country"]
                    ? updatedProfile.address["country"]
                    : values.country
                  : values.country
              }
              array={countries(t)}
              label={t("common:country")}
              onChange={(e) => {
                handleChange("country", e);
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:address1")}
              name="address1"
              onChange={(e) => {
                onChange(e);
                handleChange("address1", e.target.value);
              }}
              onBlur={onBlur}
              value={values.address1}
              errors={touched.address1 && errors.address1}
              disabled={!edit}
              id="address1"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:address2")}
              name="address2"
              onChange={(e) => {
                onChange(e);
                handleChange("address2", e.target.value);
              }}
              onBlur={onBlur}
              value={values.address2}
              errors={touched.address2 && errors.address2}
              disabled={!edit}
              id="address2"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              isRequired
              label={t("common:cityOrTown")}
              name="cityOrTown"
              onChange={(e) => {
                onChange(e);
                handleChange("cityOrTown", e.target.value);
              }}
              onBlur={onBlur}
              value={values.cityOrTown}
              errors={touched.cityOrTown && errors.cityOrTown}
              disabled={!edit}
              id="cityOrTown"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:postcode")}
              name="postcode"
              onChange={(e) => {
                onChange(e);
                handleChange("postcode", e.target.value);
              }}
              onBlur={onBlur}
              value={values.postcode}
              errors={touched.postcode && errors.postcode}
              disabled={!edit}
              id="postcode"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:zipcode")}
              name="zipCode"
              onChange={(e) => {
                onChange(e);
                handleChange("zipCode", e.target.value);
              }}
              onBlur={onBlur}
              value={values.zipCode}
              errors={touched.zipCode && errors.zipCode}
              disabled={!edit}
              id="zipCode"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:stateOrProvince")}
              name="stateOrProvince"
              onChange={(e) => {
                onChange(e);
                handleChange("stateOrProvince", e.target.value);
              }}
              onBlur={onBlur}
              value={values.stateOrProvince}
              errors={touched.stateOrProvince && errors.stateOrProvince}
              disabled={!edit}
              id="stateOrProvince"
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <FormInputField
              label={t("common:paciNumber")}
              name="paciNumber"
              onChange={(e) => {
                onChange(e);
                handleChange("paciNumber", e.target.value);
              }}
              onBlur={onBlur}
              value={values.paciNumber}
              errors={touched.paciNumber && errors.paciNumber}
              disabled={!edit}
              id="paciNumber"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AddressInformation;
