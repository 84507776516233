import { Grid } from "@material-ui/core";
import LineChart from "./LineChart";
import React from "react";
import SalaryLabels from "./SalaryLabels";

const DataVisualization = ({
  employee,
  totalEarnings,
  totalDeductions,
  deposits,
  nextDeposit,
}) => {
  return (
    <>
      {!!deposits.length && (
        <Grid item lg={8} xs={12}>
          <LineChart deposits={deposits} />
        </Grid>
      )}

      <Grid item container lg={4} xs={12}>
        <SalaryLabels
          employee={employee}
          totalEarnings={totalEarnings}
          totalDeductions={totalDeductions}
          nextDeposit={nextDeposit}
        />
      </Grid>
    </>
  );
};

export default DataVisualization;
