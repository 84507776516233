import { FormControl, FormHelperText, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
//Translation
import { useTranslation } from "react-i18next";

const FormInputField = ({
  autoComplete,
  isAutocomplete,
  id,
  inputRef,
  label,
  name,
  onChange = () => {},
  onBlur = () => {},
  errors,
  helperText,
  type = "text",
  isRequired,
  children,
  select = false,
  disabled = false,
  strict = false,
  placeholder,
  inputProps,
  multiline,
  rows,
  variant,
  ...props
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const useStyles = makeStyles(
    (theme) => ({
      error: {
        "& ..MuiFormHelperText-root": {
          color: "red",
        },

        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,
      },
      formControlRoot: {
        justifyContent: "flex-start",
        padding: 4,
        "& .MuiFormLabel-root": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInputLabel-shrink": {
          transformOrigin: isEnglish ? "top left" : "top right",
        },
        "& .MuiInputLabel-formControl": {
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish && 0,
          right: isEnglish ? null : 0,
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid #002A6E",
        },
        "& .MuiInput-underline.Mui-disabled:before": {
          borderBottomStyle: "solid",
        },
        "& .MuiInputBase-input MuiInput-input Mui-disabled Mui-disabled": {
          backgroundColor: "transparent",
        },

        "& .MuiInputBase-root.Mui-disabled": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
        },

        "& .MuiInputBase-root.Mui-disabled:after": {
          backgroundColor: "transparent",
        },

        "& .MuiFormControl-root MuiTextField-root": {
          backgroundColor: "transparent",
        },
        "& ..MuiSelect-select:focus": {
          backgroundColor: "transparent",
        },
        "& .MuiFormHelperText-root": {
          textAlign: isEnglish ? "left" : "right",
        },
        "& .MuiFormControl-root": {
          direction:
            isAutocomplete && isEnglish
              ? "rtl"
              : isAutocomplete && !isEnglish && "ltr",
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();

  // Helpers to emulate strict number input field
  const toNum = (value) => (value === "" ? null : +value);
  const toText = (value) => (value ?? "") + "";

  if (strict) {
    if (props.value) props.value = toText(props.value);
    if (props.defaultValue) props.defaultValue = toText(props.defaultValue);
  }

  const handleChange = (e) => {
    if (strict) e.target.value = toNum(e.target.value);
    onChange(e);
  };

  const handleBlur = (e) => {
    if (strict) e.target.value = toNum(e.target.value);
    onBlur(e);
  };

  return (
    <FormControl fullWidth className={classes.formControlRoot}>
      <TextField
        multiline={multiline}
        rows={rows}
        id={id}
        inputRef={inputRef}
        error={!!errors}
        InputProps={{ className: classes.input }}
        required={isRequired}
        label={label}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        select={select}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        inputProps={inputProps}
        variant={variant}
        {...props}
      >
        {children}
      </TextField>
      {helperText && (
        <FormHelperText className={classes.error} children={helperText} />
      )}
      <FormHelperText children={errors} error={!!errors} />
    </FormControl>
  );
};

export default FormInputField;
