import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";

// Styles
const useStyles = makeStyles({
  dialog: {
    textAlign: "center",
    color: "#3D5E77",
  },
  datePicker: {
    margin: "20px 0",
  },
  confirmButton: {
    margin: "10px",
    backgroundColor: "#3D5E77",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#37556b",
    },
  },
});

/**
 * @description Date picker component for report modal
 * @category Components - Web
 * @namespace DatePickerComponent
 * @param {import('./types').DatePickerComponentProps} DatePickerComponentProps - props for component
 * @returns {React.JSX.Element}
 */

function DatePickerComponent({ displayCustomDates, closeModal, t, month}) {
  const classes = useStyles();
  const [date, setStartDate] = useState(moment(month).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(date).add(1,"M").format("YYYY-MM-DD"));

  const handleStartDateChange = (_, value) => {
    setStartDate(value);
  };

  const handleEndDateChange = (_, value) => {
    setEndDate(value);
  };

  const handleClose = () => {
    closeModal({
      date,
      endDate,
    });
  };

  return (
    displayCustomDates && (
      <Dialog
        onClose={handleClose}
        open={displayCustomDates}
        className={classes.dialog}
      >
        <DialogTitle>{t("selectDates")}</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              className={classes.datePicker}
              margin="normal"
              id="start-date-picker"
              label={t("startDate")}
              format="YYYY-MM-DD"
              value={date}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change start date",
              }}
            />
            <KeyboardDatePicker
              className={classes.datePicker}
              margin="normal"
              id="end-date-picker"
              label={t("endDate")}
              format="YYYY-MM-DD"
              minDate={moment(date).format("YYYY-MM-DD")}
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change end date",
              }}
            />
          </MuiPickersUtilsProvider>
          <Button onClick={handleClose} className={classes.confirmButton}>
            {t("confirm")}
          </Button>
        </DialogContent>
      </Dialog>
    )
  );
}

export default DatePickerComponent;
