import {
  Box,
  Hidden,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
//Material UI
import { MoreHoriz, MoreVert } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Components
import { CustomTableCell, CustomTableRow } from "../../../../common";
//Styles
import useStyles from "../../styles";
import EmployeeAvatar from "../EmployeeAvatar";
import LocationMenu from "./LocationMenu";
import ViewMoreActionLocation from "./ViewMoreActionLocation";

const LocationRow = ({ location }) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const [anchorEl, setAnchorEl] = useState({
    location: null,
    employees: null,
  });

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const openViewMore = (event) => {
    setAnchorEl({ ...anchorEl, employees: event.currentTarget });
  };
  const closeViewMore = () => {
    setAnchorEl({ ...anchorEl, employees: null });
  };

  const openLocationActions = (event) => {
    setAnchorEl({ ...anchorEl, location: event.currentTarget });
  };

  const closeLocationActions = () => {
    setAnchorEl({ ...anchorEl, location: null });
  };

  const openEmployees = Boolean(anchorEl.employees);
  const idEmployees = openEmployees ? "employee-popper" : undefined;

  const openLocation = Boolean(anchorEl.location);
  const idLocation = openLocation ? "location-popper" : undefined;

  return (
    <>
      <CustomTableRow backgroundColor>
        <CustomTableCell
          colSpan={matchesMD ? 4 : 5}
          onClick={() => setOpen(!open)}
        >
          <Typography
            component={Link}
            color="primary"
            variant="subtitle2"
            className={classes.menuButton}
          >
            {isEnglish ? location?.name : location?.nameAr}
          </Typography>
        </CustomTableCell>

        <Hidden mdDown>
          <CustomTableCell colSpan={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {location?.employees?.length
                ? location?.employees
                    .map((employee) => (
                      <EmployeeAvatar
                        employee={employee}
                        key={
                          employee.user
                            ? employee.user?.fullName
                            : employee?.fullName
                        }
                      />
                    ))
                    .slice(0, 10)
                : null}

              <IconButton
                disableRipple
                size="small"
                className={classes.menuButton}
                onClick={openViewMore}
              >
                <MoreHoriz />
              </IconButton>
              <ViewMoreActionLocation
                location={location}
                idEmployees={idEmployees}
                classes={classes}
                openEmployees={openEmployees}
                anchorEl={anchorEl}
                closeViewMore={closeViewMore}
              />
            </Box>
          </CustomTableCell>
        </Hidden>
        <Hidden smDown>
          <Box clone padding={0}>
            <CustomTableCell
              colSpan={matchesMD ? 3 : 3}
              onClick={() => setOpen(!open)}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={isEnglish ? "flex-start" : "flex-start"}
              >
                <Typography variant="subtitle2" color="primary">
                  {location?.employees?.length
                    ? location?.employees?.length
                    : 0}{" "}
                  {t("common:employees")}
                </Typography>
              </Box>
            </CustomTableCell>
          </Box>
        </Hidden>
        <CustomTableCell colSpan={matchesMD ? 3 : 1} size="small">
          <Box align={isEnglish ? "right" : "left"}>
            <IconButton
              disableRipple
              className={classes.menuButton}
              size="small"
              aria-describedby={idLocation}
              onClick={openLocationActions}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </CustomTableCell>
        <LocationMenu
          location={location}
          classes={classes}
          openLocation={openLocation}
          idLocation={idLocation}
          anchorEl={anchorEl}
          openModal={openModal}
          setOpenModal={setOpenModal}
          closeLocationActions={closeLocationActions}
        />
      </CustomTableRow>
    </>
  );
};

export default LocationRow;
