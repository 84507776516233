import {CartesianGrid, Line, LineChart, Tooltip, XAxis} from 'recharts'

import React from 'react'
import moment from 'moment'
//MaterialUI
import {useMediaQuery} from '@material-ui/core'
import {useTheme} from '@material-ui/styles'

const Linechart = ({deposits}) => {
  let depositsData = deposits
    .map(deposit => ({
      name: moment(new Date(deposit.month)).format('MMM-YYYY'),
      total: deposit.total,
    }))
    .reverse()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <LineChart
      width={matchesXS ? 400 : 650}
      height={200}
      data={depositsData}
      margin={{
        top: 80,
        right: 50,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis dataKey="name" tickLine={false} />
      <Tooltip />
      <Line
        type="natural"
        dataKey="total"
        strokeWidth="4"
        stroke="#0B2B5A"
        fill="#0B2B5A"
      />
    </LineChart>
  )
}

export default Linechart
