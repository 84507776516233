import { Box, Typography } from "@material-ui/core";

import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const VacationsHeader = ({ employee, user }) => {
  const { t } = useTranslation("common");

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flexGrow={1}>
        <Typography variant="h5">{t("vacationsOverview")}</Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(VacationsHeader);
