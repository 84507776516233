import { Box, Button, Grid, Typography } from "@material-ui/core";
import { deleteOfficialHoliday } from "../../../../../../redux/actions/company";

import { CompanyStructureModal } from "../../../../../../components/common";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const DeleteHolidayModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  deleteOfficialHoliday,
  holiday,
}) => {
  const { t } = useTranslation("common");
  console.log("holiday", holiday);
  const handleDelete = () => {
    deleteOfficialHoliday({ id: holiday?.id });
  };

  return (
    <CompanyStructureModal
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      fadeIn={openDeleteModal}
      title={t("common:officialHolidayDeleteConfirm").replace("{holiday}", "")}
    >
      <Box height={"auto"} maxHeight="90vh" margin={4}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h3">
              {t("common:officialHolidayDeleteConfirm").replace(
                "this holiday {holiday}",
                holiday?.name,
              )}
            </Typography>
          </Grid>
          <Box
            clone
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            paddingX={2}
            paddingY={2}
          >
            <Grid item container md={12} sm={12} xs={11}>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleDelete();
                    setOpenDeleteModal(false);
                  }}
                >
                  {t("common:confirm")}
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  {t("common:cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CompanyStructureModal>
  );
};

const mapDispatchToPros = {
  deleteOfficialHoliday,
};

export default connect(null, mapDispatchToPros)(DeleteHolidayModal);
