import {
  Box,
  Button,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import { Add } from "@material-ui/icons";
import { CompanyStructureModal } from "../../../../common";
import Department from "./Department";
import DepartmentForm from "./DepartmentForm";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Departments = ({ departments }) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(["common", "companyProfile"]);
  const departmentsList = departments.map((department) => (
    <Department department={department} key={department.id} />
  ));

  return (
    <>
      <Box
        bgcolor="#F6F8FA"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="16px"
        paddingBottom="4px"
        marginTop={5}
        overflow="scroll"
      >
        <Typography color="primary" variant="h5">
          {" "}
          {t("companyProfile:departments")}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <AddDepartmentButton
            variant="contained"
            color="primary"
            endIcon={<Add />}
            onClick={() => setOpenModal(true)}
            style={{ whiteSpace: "nowrap", fontSize: matchesSM && "10px" }}
          >
            {t("companyProfile:addDepartment")}
          </AddDepartmentButton>
        </Box>
      </Box>
      {departmentsList}
      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("companyProfile:addDepartment")}
        height={matchesSM ? "350px" : "300px"}
      >
        <DepartmentForm setOpen={setOpenModal} />
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  departments: company.departments,
});

export default connect(mapStateToProps)(Departments);

export const AddDepartmentButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.common.lightBlue,
    color: "white",
  },
}));
