import { Box, Button, Grid, useMediaQuery } from "@material-ui/core";
import { Add } from "@material-ui/icons/";
import { useTheme } from "@material-ui/styles";
//Date
import moment from "moment";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";

//Components
import {
  CompanyStructureModal,
  DatePickerField,
  FormImageField,
  FormInputField,
  FormSelectInput,
} from "../../../common";
import AutocompleteCategoriesInput from "../../../common/AutocompleteCategoriesInput";
import AutocompleteInput from "../../../common/AutocompleteInput";
//Data
import {
  countries,
  employeesStatus,
  genders,
  maritalStatus,
} from "../../../common/DropDownsData";
import DepartmentForm from "../../CompanyProfile/CompanyStructure/Departments/DepartmentForm";
import PositionForm from "../../CompanyProfile/CompanyStructure/Departments/PositionForm";
import LocationForm from "../../CompanyProfile/CompanyStructure/Location/LocationForm";
import EmployeeSchedule from "../EmployeeSchedule";

const PersonalInformation = ({
  state,
  departments,
  locations,
  handleChange,
  handleValueChange,
  validation,
  isContractEmployee,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const paddingY = matchesMD ? 0 : 3;

  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  let positions = [];

  // FOR LOCATIONS
  const handleLocationsChange = (value) =>
    handleValueChange("locations")(value);

  const handlePositionChange = (value) =>
    handleValueChange("positionId")(value);

  const handleDepartmentChange = (value) => {
    handleValueChange("departmentId")(value);

    // Clear position value when department's value has changed
    handlePositionChange("");
  };

  const handleSchedulesChange = (value) => {
    handleValueChange("schedules")(value);
  };

  const handleDateChange = (key) => (value) =>
    handleValueChange(key)(moment(value).format("YYYY-MM-DD"));

  const handleImageChange = handleValueChange("image");

  const mapDropDownData = (data) => ({
    value: data.value,
    label: isEnglish ? data.name : data.nameAr,
  });

  positions =
    departments.find((department) => department.id === state.departmentId)
      ?.positions ?? [];

  return (
    <>
      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          open={departmentOpen}
          onClose={() => setDepartmentOpen(false)}
          fadeIn={departmentOpen}
          title={t("companyProfile:addDepartment")}
        >
          <DepartmentForm setOpen={setDepartmentOpen} />
        </CompanyStructureModal>
      </Box>

      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          open={positionOpen}
          onClose={() => setPositionOpen(false)}
          fadeIn={positionOpen}
          title={t("companyProfile:addPosition")}
        >
          <PositionForm
            setOpen={setPositionOpen}
            departmentId={state.departmentId}
          />
        </CompanyStructureModal>
      </Box>

      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          height="350px"
          open={locationOpen}
          onClose={() => setLocationOpen(false)}
          fadeIn={locationOpen}
          title={t("companyProfile:addLocation")}
        >
          <LocationForm setOpen={setLocationOpen} />
        </CompanyStructureModal>
      </Box>

      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item md={12} sm={12} xs={12}>
          <Grid container justifyContent={matchesSM ? "center" : "flex-start"}>
            <Grid item md={12} sm={4} xs={5}>
              <FormImageField
                label={t("common:uploadEmployeeImage")}
                name="image"
                image={state.image}
                imageId="employeeAvatar"
                onChange={({ target: { files } }) =>
                  handleImageChange(files[0])
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              isRequired
              label={t("common:fullName")}
              errors={validation && validation[t("common:fullName")]}
              id="fullName"
              name="fullName"
              type="text"
              onChange={handleChange}
              value={state?.fullName}
              autoComplete="new-password"
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("common:email")}
              errors={validation && validation[t("common:email")]}
              name="email"
              type="email"
              onChange={handleChange}
              value={state.email}
              helperText={t("common:emailOptional")}
              autoComplete="off"
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <AutocompleteCategoriesInput
              array={countries(t)}
              label={t("common:nationality")}
              errors={validation && validation[t("common:nationality")]}
              name="nationality"
              onChange={handleValueChange("nationality")}
              value={state.nationality}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <AutocompleteInput
                  isRequired
                  errors={validation && validation[t("common:locations")]}
                  name="locations"
                  value={state.locations}
                  options={locations.map((loc) => ({
                    label: isEnglish ? loc.name : loc.nameAr,
                    value: loc.id,
                  }))}
                  multiple={true}
                  onChange={handleLocationsChange}
                  label={t("locations")}
                />
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" onClick={() => setLocationOpen(true)}>
                  <Add />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <FormSelectInput
                  isRequired
                  label={t("common:department")}
                  errors={validation && validation[t("common:department")]}
                  name="department"
                  selectOptions={departments.map((department) => ({
                    value: department.id,
                    label: isEnglish ? department.name : department.nameAr,
                  }))}
                  onChange={handleDepartmentChange}
                  value={state.departmentId}
                />
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" onClick={() => setDepartmentOpen(true)}>
                  <Add />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <FormSelectInput
                  isRequired
                  label={t("common:position")}
                  errors={validation && validation[t("common:position")]}
                  name="positionId"
                  selectOptions={positions.map((position) => ({
                    value: position.id,
                    label: isEnglish ? position.name : position.nameAr,
                  }))}
                  onChange={handlePositionChange}
                  disabled={!state.departmentId}
                  value={state.positionId}
                  helperText={t("common:selectDepartment")}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="text"
                  onClick={() => setPositionOpen(true)}
                  disabled={!state.departmentId}
                >
                  <Add />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormSelectInput
              isRequired
              label={t("common:employmentStatus")}
              errors={validation && validation[t("common:employmentStatus")]}
              name="employmentStatus"
              selectOptions={employeesStatus.map(mapDropDownData)}
              onChange={handleValueChange("employmentStatus")}
              value={state.employmentStatus}
              helperText=""
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormSelectInput
              isRequired
              label={t("common:gender")}
              errors={validation && validation[t("common:gender")]}
              name="gender"
              selectOptions={genders.map(mapDropDownData)}
              onChange={handleValueChange("gender")}
              value={state.gender}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormSelectInput
              isRequired
              label={t("common:maritalStatus")}
              errors={validation && validation[t("common:maritalStatus")]}
              name="maritalStatus"
              selectOptions={maritalStatus.map(mapDropDownData)}
              onChange={handleValueChange("maritalStatus")}
              value={state.maritalStatus}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              disableFuture={true}
              disableToolbar={false}
              isRequired
              label={t("common:dateOfBirth")}
              errors={validation && validation[t("common:dateOfBirth")]}
              name="dateOfBirth"
              onChange={handleDateChange("dateOfBirth")}
              value={state.dateOfBirth}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              isRequired
              label={t("common:employmentDate")}
              errors={validation && validation[t("common:employmentDate")]}
              name="employmentDate"
              onChange={handleDateChange("employmentDate")}
              value={state.employmentDate}
              disableToolbar={false}
            />
          </Grid>
        </Box>

        {isContractEmployee && (
          <Box clone paddingY={paddingY}>
            <Grid item md={6} xs={12}>
              <DatePickerField
                isRequired
                label={t("common:leaveDate")}
                errors={validation && validation[t("common:leaveDate")]}
                name="leaveDate"
                onChange={handleDateChange("leaveDate")}
                value={state.leaveDate}
                disableToolbar={false}
              />
            </Grid>
          </Box>
        )}

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("common:referenceNumber")}
              errors={validation && validation[t("common:referenceNumber")]}
              name="referenceNumber"
              type="text"
              onChange={handleChange}
              value={state.referenceNumber}
              autoComplete="referenceNumber"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <EmployeeSchedule
              employeeSchedule={state.schedules}
              handleSchedulesChange={handleSchedulesChange}
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6}>
            <FormInputField
              label={t("note")}
              multiline
              name="note"
              onChange={handleChange}
              value={state.note}
              placeholder={t("common:addNote")}
              fullWidth
              style={{
                backgroundColor: "#FBFBFB",
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  departments: company.departments,
  locations: company.locations,
});

export default connect(mapStateToProps)(PersonalInformation);
