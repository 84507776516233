import React from "react";
import WorkingHours from "../../common/Forms/WorkingHours";
import { days as availableDays } from "../../common/DropDownsData";
import { useState } from "react";

const EmployeeSchedule = ({
  edit,
  employeeSchedule,
  handleSchedulesChange,
}) => {
  const getEmployeeScheduleState = () => {
    let modifiedEmployeesSchedule = employeeSchedule?.map((schedule) => {
      return {
        shifts: schedule.shifts.map((shift) => ({
          startTime: shift.startTime,
          endTime: shift.endTime,
        })),
        days: schedule.days.map((day) => day?.number),
      };
    });

    return employeeSchedule && employeeSchedule.length > 0
      ? modifiedEmployeesSchedule
      : employeeSchedule;
  };

  const [employeeScheduleState, setEmployeeScheduleState] = useState(
    getEmployeeScheduleState()
  );

  const getLabels = () => {
    let items = [];
    for (let i = 0; i < employeeScheduleState?.length; i++) {
      let days = employeeScheduleState[i]?.days;
      let shifts = employeeScheduleState[i]?.shifts;

      let daysLabel = days.map((day) => {
        let _day = availableDays.find((d) => d.value === day);
        return _day?.name?.slice(0, 3);
      });

      let shiftsLabel = shifts.map((shift) => {
        return `(${shift.startTime}-${shift.endTime})`;
      });

      items.push({
        label: `${daysLabel.join("-")} ${shiftsLabel.join(" / ")}`,
        value: i,
      });
    }
    return items;
  };
  const [labels, setLabels] = useState(getLabels());

  const [openShiftsModal, setOpenShiftsModal] = useState(false);

  const createSchedule = () => {
    handleSchedulesChange(employeeScheduleState);
    setLabels(getLabels());
    setOpenShiftsModal(false);
  };

  const handleCloseModal = () => {
    setOpenShiftsModal(false);
    setEmployeeScheduleState(getEmployeeScheduleState());
  };

  // useEffect(() => {
  //   fetchCompany();
  // }, [createCompanySchedule, setOpenShiftsModal]);

  return (
    <WorkingHours
      edit={true}
      onClose={handleCloseModal}
      onSubmit={() => createSchedule()}
      openShiftsModal={openShiftsModal}
      setOpenShiftsModal={setOpenShiftsModal}
      labels={labels}
      setLabels={setLabels}
      schedulesState={employeeScheduleState}
      setSchedulesState={setEmployeeScheduleState}
      forEmployee
    />
  );
};

export default EmployeeSchedule;
