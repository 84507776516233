import { Box, CircularProgress } from "@material-ui/core";

import React from "react";

const LoadingSpinnerComponent = () => {
  return (
    <Box
      width={"100%"}
      height={"50vh"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={40} />
    </Box>
  );
};

export default LoadingSpinnerComponent;
