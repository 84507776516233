const getCorrectTimezone = (date) => {
  const dateObj = new Date(date);
  dateObj.setHours(dateObj.getHours());
  return dateObj;
};

export default getCorrectTimezone;

export const getEmployeeLocations = (employee, isEnglish) => {
  let empLocations = [];

  employee.locations.forEach((location) => {
    if (isEnglish) {
      empLocations.push(location.name);
    } else {
      empLocations.push(location.nameAr);
    }
  });

  return empLocations.join(",");
};

export const revertTimeZone = (date) => {
  const dateObj = new Date(date);
  dateObj.setHours(dateObj.getHours());
  return dateObj;
};

export const ComparatorUtil = (order, orderBy, comparator) => (a, b) => {
  const result = comparator(a, b, orderBy);
  return order === "desc" ? -result : result;
};

export const SortUtil = (array, comparator) => {
  return [...array].sort((a, b) => {
    const order = comparator(a, b);
    return order !== 0 ? order : array.indexOf(a) - array.indexOf(b);
  });
};
