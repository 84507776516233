import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Actions
import { forgotPassword } from "../../../redux/actions";

// Layout
import { Grid, Button, Typography, Box } from "@material-ui/core";

// Icons
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { Alert } from "@material-ui/lab";
import AuthenticationInput from "../../common/AuthenticationInput";

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

const Form = ({ forgotPassword, errorFields }) => {
  const [success, setSuccess] = useState("");
  const {
    t,
    i18n: { language },
  } = useTranslation("login");

  const isEnglish = language === "en";

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ email }) => {
    forgotPassword({ email }, () => setSuccess(t("requestSent")));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} data-test="login-form">
        <Grid container justifyContent="space-between" spacing={2}>
          <Box clone pb={2}>
            <Grid item md={12}>
              <Typography
                variant="h2"
                color="primary"
                align={isEnglish ? "left" : "right"}
              >
                {t("forgetPassword")}
              </Typography>
            </Grid>
          </Box>
          {success && (
            <Grid item md={12} xs={11}>
              <Alert severity="success" onClick={() => setSuccess("")}>
                {success}
              </Alert>
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <AuthenticationInput
                error={!!errors.email}
                inputRef={register}
                helperText={errors.email?.message}
                name="email"
                label={t("email")}
                icon={<PermIdentityOutlinedIcon />}
              />
            </Box>
          </Grid>
          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}
          <Grid item md={6} xs={5}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ExitToAppRoundedIcon />}
              style={{
                color: "#FFF",
                backgroundColor: "#3D5E77",
              }}
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = ({ errors }) => ({
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = { forgotPassword };

export default connect(mapStateToProps, mapDispatchToProps)(Form);
