import { connect, useDispatch } from "react-redux";

import AttendanceModal from "../../../common/AttendanceModal";
import AttendanceRow from "../../../common/AttendancesTable/AttendanceRow";
import { AttendancesTable } from "../../../common";
import { DatePickerField } from "../../../../../components/common";
import { Grid, Button } from "@material-ui/core";
import React from "react";
import { fetchEmployeeAttendances } from "../../../../../redux/actions";
import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AttendanceReportModal from "./AttendanceReportModal";

const Attendances = ({ employee, employeeAttendances, user }) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const [month, setMonth] = useState(moment(new Date()).format("yyyy-MM"));

  const [openModal, setOpenModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [reportEmployees, setReportEmployees] = useState([]);
  const [attendanceForModal, setAttendanceForModal] = useState("");
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleMonthChange = (e) => {
    setMonth(moment(e).format("yyyy-MM"));
  };

  useEffect(() => {
    dispatch(
      fetchEmployeeAttendances({
        dateMonth: month,
        employeeId: employee.id,
      }),
    );
  }, [dispatch, employee.id, month]);

  let headerLabels = [
    t("checkInTime"),
    t("checkOutTime"),
    t("location"),
    t("checkInDistance"),
    t("checkOutDistance"),
    t("duration"),
    t("image"),
    t("checkoutImage"),
    t("edit"),
  ];

  let data = [...employeeAttendances];
  console.log("data", data);
  let tableBody = data
    ?.sort((a, b) => new Date(b.checkIn) - new Date(a.checkIn))
    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    ?.map((attendance, i) => {
      return (
        <AttendanceRow
          employerDashboard
          attendance={attendance}
          setAttendanceForModal={setAttendanceForModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      );
    });
  console.log("reportEmployees", reportEmployees);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item container justifyContent="flex-end" lg={12} xs={12}>
          <Grid item md={4} xs={12}>
            <DatePickerField
              disableToolbar={false}
              yearMonthView
              openTo="month"
              onChange={(e) => handleMonthChange(e)}
              value={month}
              label={t("common:month")}
            />
            <Grid item md={4} xs={12}>
              {user && user.isCompanyOwner && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenReportModal(true);
                    setReportEmployees(data);
                  }}
                >
                  {t("common:download")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          <AttendancesTable
            data={data}
            labels={headerLabels}
            tableBody={tableBody}
            employee={employee}
            title={t("attendances")}
            month={month}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            employerDashboard
          />
        </Grid>
      </Grid>
      <AttendanceModal
        openModalState={openModal}
        setOpenModalState={setOpenModal}
        attendanceState={attendanceForModal}
        setAttendanceState={setAttendanceForModal}
      />
      <AttendanceReportModal
        company={employee.company}
        openModal={openReportModal}
        setOpenModal={setOpenReportModal}
        attendancePageMonth={month}
        reportEmployees={reportEmployees}
        setReportEmployees={setReportEmployees}
        attendancePageEmployees={data}
        month={month}
        employee={employee}
      />
    </>
  );
};

const mapStateToProps = ({ employees, auth }) => ({
  employeeAttendances: employees.attendances,
  user: auth.user,
});

export default connect(mapStateToProps)(Attendances);
