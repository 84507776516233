import React, { useEffect } from "react";
import { createCompanySchedule, fetchCompany } from "../../../../redux/actions";

import WorkingHours from "../../../common/Forms/WorkingHours";
import { days as availableDays } from "../../../common/DropDownsData";
import { connect } from "react-redux";
import { useState } from "react";

const WorkingHoursDetails = ({
  edit,
  companySchedule,
  createCompanySchedule,
  fetchCompany,
}) => {
  const getCompanyScheduleState = () => {
    let modifiedCompSched = companySchedule?.map((schedule) => {
      return {
        shifts: schedule.shifts.map((shift) => ({
          startTime: shift.startTime,
          endTime: shift.endTime,
        })),
        days: schedule.days.map((day) => day.number),
      };
    });

    return companySchedule && companySchedule.length > 0
      ? modifiedCompSched
      : companySchedule;
  };

  const [companyScheduleState, setCompanyScheduleState] = useState(
    getCompanyScheduleState()
  );

  const getLabels = () => {
    let items = [];
    for (let i = 0; i < companyScheduleState?.length; i++) {
      let days = companyScheduleState[i].days;
      let shifts = companyScheduleState[i].shifts;

      let daysLabel = days.map((day) => {
        let _day = availableDays.find((d) => d.value === day);
        return _day.name.slice(0, 3);
      });

      let shiftsLabel = shifts.map((shift) => {
        return `(${shift.startTime}-${shift.endTime})`;
      });

      items.push({
        label: `${daysLabel.join("-")} ${shiftsLabel.join(" / ")}`,
        value: i,
      });
    }
    return items;
  };
  const [labels, setLabels] = useState(getLabels());

  const [openShiftsModal, setOpenShiftsModal] = useState(false);

  const createSchedule = () => {
    createCompanySchedule({ schedules: companyScheduleState });
    setLabels(getLabels());
    setOpenShiftsModal(false);
  };

  const handleCloseModal = () => {
    setOpenShiftsModal(false);
    setCompanyScheduleState(getCompanyScheduleState());
  };

  useEffect(() => {
    fetchCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCompanySchedule, setOpenShiftsModal]);

  return (
    <WorkingHours
      edit={edit}
      onClose={handleCloseModal}
      onSubmit={() => createSchedule()}
      openShiftsModal={openShiftsModal}
      setOpenShiftsModal={setOpenShiftsModal}
      labels={labels}
      setLabels={setLabels}
      schedulesState={companyScheduleState}
      setSchedulesState={setCompanyScheduleState}
    />
  );
};

const mapStateToProps = ({ company }) => ({
  companySchedule: company.profile.schedules,
});

const mapDispatchToProps = {
  createCompanySchedule,
  fetchCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkingHoursDetails);
