import { Box, Drawer, useTheme } from "@material-ui/core";

import { Link } from "react-router-dom";
import { Logo } from "../../../../components/common";
import React from "react";
import SidebarMenu from "../SideBarMenu";
import { useTranslation } from "react-i18next";

const SIDE_NAV_WIDTH = 310;

const SideNav = ({ color, sections, user, employeeProfile }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const theme = useTheme();

  return (
    <Drawer
      anchor={isEnglish ? "left" : "right"}
      open
      PaperProps={{ style: { border: "none", width: SIDE_NAV_WIDTH } }}
      classes={{
        paper: "",
      }}
      variant="permanent"
    >
      <Box
        height="100%"
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box alignItems="center" direction="row" spacing={2} padding={3}>
          <Box
            component={Link}
            to={"/"}
            style={{
              borderRadius: 1,
              display: "flex",
              height: 40,
              padding: "4px",
              width: 40,
            }}
          >
            <Logo />
          </Box>
        </Box>
        <Box component="nav" spacing={2} flexGrow={1}>
          <SidebarMenu user={user} employeeProfile={employeeProfile} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNav;
