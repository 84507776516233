export const validation = (t, isContractEmployee) => ({
  fullName: {
    required: true,
    name: t("common:fullName"),
    message: t("common:isRequired"),
  },
  referenceNumber: {
    required: false,
    name: t("common:referenceNumber"),
    message: t("common:isRequired"),
  },
  email: {
    required: false,
    name: t("common:email"),
    message: t("common:isRequired"),
    validationMessage: t("common:emailIncorrectFormat"),
  },
  image: {
    required: false,
    name: t("common:image"),
    message: t("common:isRequired"),
  },
  nationality: {
    required: true,
    name: t("common:nationality"),
    message: t("common:isRequired"),
  },
  locations: {
    required: true,
    name: t("common:locations"),
    message: t("common:isRequired"),
  },
  departmentId: {
    required: true,
    name: t("common:department"),
    message: t("common:isRequired"),
  },
  positionId: {
    required: true,
    name: t("common:position"),
    message: t("common:isRequired"),
  },

  employmentStatus: {
    required: true,
    name: t("common:employmentStatus"),
    message: t("common:isRequired"),
  },
  gender: {
    required: true,
    name: t("common:gender"),
    message: t("common:isRequired"),
  },
  maritalStatus: {
    required: true,
    name: t("common:maritalStatus"),
    message: t("common:isRequired"),
  },
  dateOfBirth: {
    required: true,
    name: t("common:dateOfBirth"),
    message: t("common:isRequired"),
  },
  employmentDate: {
    required: true,
    name: t("common:employmentDate"),
    message: t("common:isRequired"),
  },
  leaveDate: {
    required: isContractEmployee ? true : false,
    name: t("common:leaveDate"),
    message: t("common:isRequired"),
  },
});
export const salaryValidation = (t) => ({
  baseSalary: {
    required: true,
    name: t("common:baseSalary"),
    message: "Salary is required",
  },
});
