//Material UI
import {Box, FormHelperText} from '@material-ui/core'
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

import {AccessTime} from '@material-ui/icons'
//Date
import MomentUtils from '@date-io/moment'
import {makeStyles} from '@material-ui/core/styles'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

const TimePickerField = ({
  label,
  name,
  onChange,
  onBlur = () => {},
  errors,
  isRequired,
  value = '',
  helperText = '',
  disabled = false,

  ...dateProps
}) => {
  const {
    i18n: {language},
  } = useTranslation()
  const isEnglish = language === 'en'

  const useStyles = makeStyles(
    theme => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,

        '& .MuiFormLabel-root': {
          color: theme.palette.primary.main,
          fontSize: '1rem',
          fontWeight: 500,
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid #002A6E',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
      },
      formControlRoot: {
        justifyContent: 'flex-start',
        padding: 4,
        '& .MuiButtonBase-root MuiIconButton-root': {
          color: theme.palette.common.staffiBlue,
        },

        '& .MuiFormLabel-root': {
          color: theme.palette.primary.main,
          fontSize: '1rem',
          fontWeight: 500,
        },
        '& .MuiInputLabel-formControl': {
          color: theme.palette.primary.main,
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
        '& .MuiInputLabel-shrink': {
          transformOrigin: isEnglish ? 'top left' : 'top right',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid #002A6E',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
        '& .Mui-disabled': {
          color: 'black',
        },
        '& .MuiFormHelperText-root': {
          textAlign: 'right',
        },
      },
      calendar: {
        '& .MuiIconButton-root.Mui-disabled': {
          color: '#002A6E',
        },
      },
    }),
    {index: 1},
  )

  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="en">
      <Box clone margin={0} className={classes.formControlRoot}>
        <KeyboardTimePicker
          fullWidth
          disabled={disabled}
          helperText={''}
          error={!!errors}
          // InputProps={{className: classes.input}}
          keyboardIcon={<AccessTime color="primary" />}
          required={isRequired}
          label={label}
          value={value}
          name={name}
          onChange={e => {
            onChange(e)
            onBlur(e)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          {...dateProps}
        />
      </Box>

      <Box margin={0} className={classes.formControlRoot}>
        <FormHelperText>{helperText}</FormHelperText>
        <FormHelperText children={errors} error={!!errors} />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default TimePickerField
