import React, { useState } from "react";

import { FormSelectInput } from "../../../common";

//MaterialUI
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import { Error } from "@material-ui/icons";

//Translation
import { useTranslation } from "react-i18next";

//Data
import { connect } from "react-redux";

//Utils
import moment from "moment";
import _ from "lodash";

const ExpDocuments = ({ profile }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const [month, setMonth] = useState("All");

  if (!profile) return <Typography></Typography>;

  const documentsExp = profile.documents.map((doc) => {
    if (doc.exp !== null) {
      return {
        exp: moment(new Date(doc.exp)).format("MMM YYYY"),
      };
    }
    return null;
  });

  let months = [];
  if (documentsExp.length) {
    months = _.uniqBy(documentsExp, "exp").map((doc) => {
      if (doc) {
        return {
          label: doc.exp,
          value: doc.exp,
        };
      }
      return null;
    });
  }

  const documentsList = profile.documents
    .filter((doc) => {
      if (month === "All") {
        return doc;
      }
      let date = moment(new Date(doc.exp)).format("MMM YYYY");
      if (date === month) return doc;
      return null;
    })
    .map((document, i) => (
      <Grid container key={(document, +i)}>
        <Box clone paddingY={1}>
          <Grid item md={2} sm={3} xs={2}>
            <Box clone padding={1}>
              <Error fontSize="small" />
            </Box>
          </Grid>
        </Box>

        <Grid item md={8} sm={7} xs={7}>
          <Box paddingY={1}>
            <Typography variant="h5" color="textPrimary">
              {document.name}
            </Typography>

            <Typography variant="subtitle2" color="textSecondary">
              {document.exp}
            </Typography>
          </Box>

          <Divider variant="fullWidth" />
        </Grid>
      </Grid>
    ));
  return (
    <Box paddingY={2}>
      <FormSelectInput
        label={t("common:month")}
        selectOptions={
          months.length === 1 && months[0] === undefined
            ? [{ label: isEnglish ? "All" : "الكل", value: "All" }]
            : [
                { label: isEnglish ? "All" : "الكل", value: "All" },
                ...months.filter((month) => month !== null),
              ]
        }
        onChange={(e) => setMonth(e)}
        value={month}
      />
      <Box paddingY={2}>
        <Grid container>{documentsList}</Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ employee }) => ({
  profile: employee.profile,
});

export default connect(mapStateToProps)(ExpDocuments);
