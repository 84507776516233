function descendingComparator(a, b, orderBy) {
  switch (orderBy) {
    case "employeeId":
      if (Number(b.id) < Number(a.id)) {
        return -1;
      }
      if (Number(b.id) > Number(a.id)) {
        return 1;
      }
      return 0;
    case "employeeName":
      const nameB = b.user ? b.user?.fullName : b?.fullName;
      const nameA = a.user ? a.user?.fullName : a?.fullName;
      if (nameB < nameA) {
        return -1;
      }
      if (nameB > nameA) {
        return 1;
      }
      return 0;
    case "department":
      if (
        b.department.name < a.department.name ||
        b.department.nameAr < a.department.nameAr
      ) {
        return -1;
      }
      if (
        b.department.name > a.department.name ||
        b.department.nameAr > a.department.nameAr
      ) {
        return 1;
      }
      return 0;
    case "position":
      if (
        b.position.name < a.position.name ||
        b.position.nameAr < a.position.nameAr
      ) {
        return -1;
      }
      if (
        b.position.name > a.position.name ||
        b.position.nameAr > a.position.nameAr
      ) {
        return 1;
      }
      return 0;
    case "status":
      if (b.employmentStatus < a.employmentStatus) {
        return -1;
      }
      if (b.employmentStatus > a.employmentStatus) {
        return 1;
      }
      return 0;
    case "location":
      if (
        b.location.name < a.location.name ||
        b.location.nameAr < a.location.nameAr
      ) {
        return -1;
      }
      if (
        b.location.name > a.location.name ||
        b.location.nameAr > a.location.nameAr
      ) {
        return 1;
      }
      return 0;
    case "lastUpdated":
      if (new Date(b.lastUpdated) < new Date(a.lastUpdated)) {
        return -1;
      }
      if (new Date(b.lastUpdated) > new Date(a.lastUpdated)) {
        return 1;
      }
      return 0;

    case "netSalary":
      if (b.baseSalary < a.baseSalary) {
        return -1;
      }
      if (b.baseSalary > a.baseSalary) {
        return 1;
      }
      return 0;

    default:
      return orderBy;
  }
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
