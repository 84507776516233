import {
  EDIT_LEAVE_REQUEST,
  EDIT_LEAVE_REQUEST_LOADING,
  EDIT_OVERTIME_REQUEST,
  EDIT_RESIGNATION_REQUEST,
  EDIT_UPDATE_REQUEST,
  EDIT_GENERAL_REQUEST,
  REQUEST,
  REQUESTS,
  REQUEST_APPROVERS_STATUS,
  REQUEST_LOADING,
} from "../actions/types";

const initialState = {
  requests: [],
  request: null,
  requestLoading: true,
  requestsLoading: true,
  leaveRequestLoading: false,
  approversStatus: [],
  approversStatusLoading: true,
};

export const requestsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUESTS:
      return {
        ...state,
        requests: payload,
        requestsLoading: false,
      };
    case REQUEST_LOADING:
      return {
        ...state,
        requestLoading: true,
      };
    case REQUEST:
      return {
        ...state,
        request: payload,
        requestLoading: false,
      };
    case EDIT_LEAVE_REQUEST_LOADING:
      return {
        ...state,
        leaveRequestLoading: payload,
      };
    case EDIT_LEAVE_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          status: payload.request.status,
          leaveRequest: payload,
        },
        requests: state.requests.map((request) => {
          if (request.id === payload.request.id) {
            return {
              ...request,
              status: payload.request.status,
              leaveRequest: payload,
            };
          }
          return request;
        }),
        leaveRequestLoading: false,
      };

    case EDIT_OVERTIME_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          status: payload.request.status,
          overtimeRequest: payload,
        },
        requests: state.requests.map((request) => {
          if (request.id === payload.request.id) {
            return {
              ...request,
              status: payload.request.status,
              overtimeRequest: payload,
            };
          }
          return request;
        }),
      };
    case EDIT_UPDATE_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          status: payload.request.status,
          lastModified: payload.request.lastModified,
          lastModifiedBy: payload.request.lastModifiedBy,
          updateProfileRequest: payload.request.updateProfileRequest,
        },
        requests: state.requests.map((request) => {
          if (request.id === payload.request.id) {
            return {
              ...state.request,
              status: payload.request.status,
              lastModified: payload.request.lastModified,
              lastModifiedBy: payload.request.lastModifiedBy,
              updateProfileRequest: payload.request.updateProfileRequest,
            };
          }
          return request;
        }),
      };
    case EDIT_RESIGNATION_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          status: payload.request.status,
          lastModified: payload.request.lastModified,
          lastModifiedBy: payload.request.lastModifiedBy,
          resignationRequest: payload.request.resignationRequest,
        },
        requests: state.requests.map((request) => {
          if (request.id === payload.request.id) {
            return {
              ...state.request,
              status: payload.request.status,
              lastModified: payload.request.lastModified,
              lastModifiedBy: payload.request.lastModifiedBy,
              resignationRequest: payload.request.resignationRequest,
            };
          }
          return request;
        }),
      };

    case EDIT_GENERAL_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          status: payload.request.status,
          lastModified: payload.request.lastModified,
          lastModifiedBy: payload.request.lastModifiedBy,
          generalRequest: payload.request.generalRequest,
        },
        requests: state.requests.map((request) => {
          if (request.id === payload.request.id) {
            return {
              ...state.request,
              status: payload.request.status,
              lastModified: payload.request.lastModified,
              lastModifiedBy: payload.request.lastModifiedBy,
              generalRequest: payload.request.generalRequest,
            };
          }
          return request;
        }),
      };
    case REQUEST_APPROVERS_STATUS:
      return {
        ...state,
        approversStatus: payload,
        approversStatusLoading: false,
      };

    default:
      return state;
  }
};
