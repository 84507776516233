import { Box, Button, Grid, Typography } from "@material-ui/core";

import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmDeleteForm = ({ setOpenDeleteModal, onClickConfirm }) => {
  const { t } = useTranslation("common");
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant="h3">
          {t("common:modificationDeleteConfirm")}
        </Typography>
      </Grid>
      <Box
        clone
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        paddingX={2}
        paddingY={2}
      >
        <Grid item container md={12} sm={12} xs={11}>
          <Grid item md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickConfirm}
            >
              {t("common:confirm")}
            </Button>
          </Grid>
          <Grid item md={3}>
            <Button
              variant="outlined"
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("common:cancel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ConfirmDeleteForm;
