import { Box, Grid } from "@material-ui/core";

import ApproveRequests from "./ApproveRequests";
import Portal from "./Portal";

const Access = () => {
  return (
    <>
      <Box clone minHeight={"400px"} paddingY={4} paddingX={2}>
        <Grid container spacing={6}>
          <ApproveRequests />
          <Portal />
        </Grid>
      </Box>
    </>
  );
};

export default Access;
