import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  fetchMutliplePayroll,
  fetchEmployeesMutliplePayrollLoading,
} from "../../../../redux/actions";
import { CompanyStructureModal } from "../../../common";
import { REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL } from "../../../../redux/actions/types";
import { DatePickerComponent, LoadingComponent } from "../components";
import { createPDF, filterEmployees, getXlsxData } from "../utils";
import { normalizeData } from "../data";
import * as XLSX from "xlsx";

/**
 * Reports Modal is a component that displays a modal with a list of headers
 * select boxes allows the user to select which headers to display in the report
 * and a button to generate the report.
 * @category Components - Web
 * @namespace ReportsModal
 * @param {import("./types").ReportModalProps} props
 * @returns {React.JSX.Element} - <ReportsModal />
 */

const ReportsModal = ({
  isVisible,
  closeModal,
  documentHeaders,
  department,
  position,
  location,
  searchQuery,
  month,
}) => {
  const [documentSelectedHeaders, setDocumentSelectedHeaders] =
    useState(documentHeaders);
  const dispatch = useDispatch();
  const { payroll, multipleMonthsPayroll, multipleMonthsPayrollLoading } =
    useSelector((state) => state.employees);

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const [monthToDisplay, setMonthToDisplay] = useState(
    moment(month).startOf("month").format("DD-MM-YYYY"),
  );
  const [displayCustomDates, setDisplayCustomDates] = useState(false);
  const [dateRange, setDateRange] = useState({
    date: moment().format("YYYY-MM-DD"),
    endDate: undefined,
  });
  const [documentBody, setDocumentBody] = useState([]);

  const memoizedHandleCheckboxChange = useCallback(
    (event) => {
      setDocumentSelectedHeaders(
        documentSelectedHeaders.map((field) =>
          field.value === event.target.name
            ? { ...field, active: !field.active }
            : field,
        ),
      );
    },
    [documentSelectedHeaders],
  );

  const handleCloseDatePicker = useCallback(
    (value) => {
      setDateRange({ date: value.date, endDate: value.endDate });
      setMonthToDisplay(`${value.date} /  ${value.endDate}`);
      dispatch(
        fetchMutliplePayroll({
          month: value.date,
          to_month: value.endDate,
        }),
      ).then(() => {
        dispatch(fetchEmployeesMutliplePayrollLoading(false));
      });

      setDisplayCustomDates(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange],
  );

  useEffect(() => {
    const employees = !dateRange.endDate
      ? payroll
      : multipleMonthsPayroll.flatMap((employee) => employee);

    const filteredEmployees = filterEmployees(
      employees,
      department,
      position,
      location,
      searchQuery,
    );

    const sortedEmployees = filteredEmployees.sort(function (a, b) {
      return moment(a.month).isBefore(moment(b.month))
        ? -1
        : moment(a.month).isAfter(moment(b.month))
        ? 1
        : 0;
    });

    const normalizedEmployees = normalizeData(
      sortedEmployees,
      documentSelectedHeaders,
      isEnglish,
      dateRange.date,
      dateRange.endDate,
    );

    setDocumentBody(normalizedEmployees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, multipleMonthsPayroll, documentSelectedHeaders]);

  const createXlsx = () => {
    let xlsxData = getXlsxData(documentBody, closeModal, t, isEnglish);

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(xlsxData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      moment().format("YYYY-MM-DD"),
    );
    XLSX.writeFile(
      workbook,
      `payroll-report-${moment().format("YYYY-MM-DD")}.xlsx`,
    );
  };

  return (
    isVisible && (
      <CompanyStructureModal
        open={isVisible}
        onClose={closeModal}
        fadeIn={isVisible}
        title={t("payrollReport")}
      >
        <>
          {multipleMonthsPayrollLoading ? (
            <LoadingComponent />
          ) : (
            <>
              <Box paddingX={4}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="h5">{`${t(
                      "date",
                    )} : ${monthToDisplay}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      disableRipple
                      variant="text"
                      color="primary"
                      style={{
                        textDecoration: "underline",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        setDisplayCustomDates(true);
                        dispatch({
                          type: REMOVE_ALL_EMPLOYEES_MUTLIPLE_PAYROLL,
                        });
                      }}
                    >
                      {t("customDates")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box
                style={{ overflowY: "auto" }}
                height="auto"
                maxHeight={"60vh"}
                paddingX={4}
                paddingY={4}
              >
                <Typography variant="h5">Fields: </Typography>
                <FormControl>
                  <FormGroup>
                    {documentSelectedHeaders.map((field) => {
                      return (
                        <FormControlLabel
                          key={field.value}
                          control={
                            <Checkbox
                              checked={field.active}
                              onChange={memoizedHandleCheckboxChange}
                              name={field.value}
                            />
                          }
                          label={field.label}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                margin={2}
              >
                <Box clone marginX={2}>
                  <Button
                    disabled={multipleMonthsPayrollLoading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      createPDF(
                        documentBody,
                        closeModal,
                        t,
                        isEnglish,
                        monthToDisplay,
                      );
                    }}
                  >
                    <Typography>{t("generatePdf")}</Typography>
                  </Button>
                </Box>

                <Box clone marginX={2}>
                  <Button
                    disabled={multipleMonthsPayrollLoading}
                    variant="contained"
                    color="primary"
                    onClick={createXlsx}
                  >
                    {t("generateXlsx")}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </>
        <DatePickerComponent
          displayCustomDates={displayCustomDates}
          closeModal={handleCloseDatePicker}
          t={t}
          month={month}
        />
      </CompanyStructureModal>
    )
  );
};

export default ReportsModal;
