import { AnnouncementsComponent } from "../../../components/Employer";
import { Box } from "@material-ui/core";
import { PageHeader } from "../../../components/common";
import React from "react";
import { paths } from "../../../utils";
import { useTranslation } from "react-i18next";

const Announcements = () => {
  const { t } = useTranslation(["common", "breadcrumbs"]);

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.announcements.name}`),
      isLast: true,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader paths={navigationPaths} title={t(paths.announcements.name)} />
      <AnnouncementsComponent />
    </Box>
  );
};

export default Announcements;
