import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { CustomTabPanel } from "../../../../components/common";
import { getTabLabels } from "../../../../components/pages/EmployeeProfile/utils";
import {
  fetchEmployeeDeposits,
  fetchEmployeeDetails,
  fetchEmployeeNextDeposit,
} from "../../../../redux/actions";
import { PageHeader } from "../../../components/common";
import {
  Attendances,
  EmployeeProfileDocuments,
  EmployeeSettings,
  Overtime,
  Overview,
  Payroll,
  Vacations,
} from "../../../components/Employer";
import Indemnity from "../../../components/Employer/EmployeeProfile/Indemnity";
import { paths } from "../../../utils/index";

const EmployeeProfile = ({
  employeesDetails,
  fetchEmployeeDetails,
  employeeLoading,
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
  employeeDocumentTypes,
  company,
  profile,
  user,
}) => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation(["common", "employeeProfile", "breadcrumbs"]);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let foundEmployee = employeesDetails.find((emp) => emp.id === employeeId);

    if (foundEmployee) {
      setEmployee(foundEmployee);
      fetchEmployeeDeposits({ employeeId: +employeeId });
      fetchEmployeeNextDeposit({ employeeId: +employeeId });
    } else {
      fetchEmployeeDetails({ employeeId: +employeeId });
    }
  }, [
    fetchEmployeeDetails,
    employeesDetails,
    employeeId,
    fetchEmployeeDeposits,
    fetchEmployeeNextDeposit,
  ]);

  const authorizedTabs = getTabLabels(t, user, company);

  const allTabComponents = [
    {
      label: t("overview"),
      component: <Overview employee={employee} />,
    },
    {
      label: t("vacations"),
      component: <Vacations employee={employee} />,
    },
    {
      label: t("payroll"),
      component: <Payroll employee={employee} />,
    },
    {
      label: t("overtime"),
      component: <Overtime employee={employee} />,
    },
    {
      label: t("documents"),
      component: <EmployeeProfileDocuments employee={employee} />,
    },

    {
      label: t("attendances"),
      component: <Attendances employee={employee} />,
    },

    {
      label: t("indemnity"),
      component: <Indemnity employee={employee} />,
    },

    {
      label: t("settings"),
      component: <EmployeeSettings employee={employee} />,
    },
  ];

  const tabComponents = allTabComponents.filter((component) =>
    authorizedTabs.includes(component.label)
  );

  const tabs = tabComponents.map((tab, idx) => (
    <Tab
      disableRipple
      key={tab.label}
      label={tab.label}
      idx={idx}
      style={{
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: "auto",
        textTransform: "none",
        "& + &": {
          marginLeft: 24,
        },
      }}
    />
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    return event;
  };

  if (employeeLoading || !employee)
    return <Typography align="center">Loading</Typography>;

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.employees.name}`),
      link: paths.employees.link,
    },
    {
      name: employee?.fullName,
      isLast: true,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader paths={navigationPaths} paddingBottom={1} />

      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={matchesSM ? "on" : "auto"}
        TabScrollButtonProps={{ disabled: false }}
        style={{ marginTop: 5 }}
      >
        {tabs}
      </Tabs>

      <Grid item md={12} xs={12}>
        {tabComponents.map((tab, idx) => {
          return (
            <CustomTabPanel value={value} index={idx} key={idx}>
              {tab.component}
            </CustomTabPanel>
          );
        })}
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {
  fetchEmployeeDetails,
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
};

const mapStateToProps = ({ employees, company, employee, auth }) => ({
  company: company.profile,
  employeesDetails: employees.employeesDetails,
  employeeLoading: employees.employeeLoading,
  employeeDocumentTypes: employees.documentTypes,
  profile: employee.profile,
  user: auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);
