import { Box, Hidden, IconButton } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import React from "react";

import useStyles from "../../../styles";
import EmployeeAvatar from "../../EmployeeAvatar";
import ViewMoreAction from "../ViewMoreAction";

const DepartmentEmployees = ({ department, anchorEl, setAnchorEl }) => {
  const classes = useStyles();

  const openEmployees = Boolean(anchorEl.employees);
  const idEmployees = openEmployees ? "employee-popper" : undefined;

  const openViewMore = (event) => {
    setAnchorEl({ ...anchorEl, employees: event.currentTarget });
  };

  const closeViewMore = () => {
    setAnchorEl({ ...anchorEl, employees: null });
  };

  function getDepartmentEmployees(department) {
    let employees = [];
    department.positions.forEach((position) =>
      employees.push(...position.employees)
    );
    return employees;
  }
  return (
    <Hidden mdDown>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {department.positions.length
          ? getDepartmentEmployees(department)
              .slice(0, 10)
              .map((employee) => (
                <EmployeeAvatar
                  employee={employee}
                  key={
                    employee.user ? employee.user?.fullName : employee?.fullName
                  }
                />
              ))
          : null}

        <IconButton
          disableRipple
          size="small"
          className={classes.menuButton}
          onClick={openViewMore}
        >
          <MoreHoriz />
        </IconButton>
        <ViewMoreAction
          department={department}
          idEmployees={idEmployees}
          classes={classes}
          openEmployees={openEmployees}
          anchorEl={anchorEl}
          closeViewMore={closeViewMore}
        />
      </Box>
    </Hidden>
  );
};

export default DepartmentEmployees;
