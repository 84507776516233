import { Box, Grid, Typography } from "@material-ui/core";
import { AccessTime, Cancel, Check } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const StatusField = ({ approvers, status }) => {
  const { t } = useTranslation(["common"]);

  const approversList = approvers.map((employee) => {
    return (
      <>
        <Grid item md={6}>
          <Typography>{employee?.fullName}</Typography>
        </Grid>

        <Grid item md={6}>
          {employee.status === "APPROVED" ? (
            <Check style={{ color: "#60CA6D" }} />
          ) : employee.status === "PENDING" ? (
            <AccessTime style={{ color: "#C4CDD6" }} />
          ) : (
            <Cancel style={{ color: "red" }} />
          )}
        </Grid>
      </>
    );
  });
  return (
    <>
      <Box clone minHeight={"50px"}>
        <Grid item md={3} xs={6}>
          <Typography color="primary">{t("requests:requestStatus")}</Typography>
        </Grid>
      </Box>
      <Box clone height={"100%"}>
        <Grid item container alignItems="center" md={3} xs={6}>
          <Grid item md={12}>
            <Typography>{status}</Typography>
          </Grid>
          {approversList}
        </Grid>
      </Box>
    </>
  );
};

export default StatusField;
