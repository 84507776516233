// import React, { useState } from "react";

// import ExpiringDocumentsModal from "../../common/ExpiringDocumentsModal";
import HomeCard from "../../common/HomeCard";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const TrialEmployees = ({ employees }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);
  const isEnglish = language === "en";

  // const [openModal, setOpenModal] = useState(false);
  // const [modalData, setModalData] = useState(null);

  const labels = [
    t("common:id"),
    t("common:employeeName"),
    t("common:department"),
    t("common:employmentDate"),
    t("common:remainingDays"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={(label, +i)} colSpan={2} />
  ));

  const today = new Date();

  const isOnTrial = (employee) => {
    const employment_date = new Date(employee.employmentDate);
    const days = moment(today).diff(employment_date, "days");
    return days <= 100;
  };

  const data = employees
    .filter((employee) => {
      return (
        employee.employmentStatus === "TRIAL_PERIOD" && isOnTrial(employee)
      );
    })
    .map((employee) => {
      const employment_date = new Date(employee.employmentDate);
      const days = moment(today).diff(employment_date, "days");
      const remainingDays = 100 - days;
      return {
        id: employee.id,
        employeeName: employee.fullName,
        employmentDate: employee.employmentDate,
        department: isEnglish
          ? employee.department.name
          : employee.department.nameAr,
        remainingDays: remainingDays,
      };
    });

  const rows = data.map((entry, i) => {
    return (
      <TableRow
        // onClick={() => {
        //   setModalData(entry);
        //   setOpenModal(true);
        // }}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.id}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.employeeName}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.department}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.employmentDate}
        </RequestTableCell>

        <RequestTableCell colSpan={2} align={isEnglish ? "left" : "right"}>
          {entry.remainingDays}
        </RequestTableCell>
      </TableRow>
    );
  });

  return (
    <HomeCard title={t("home:trialEmployeeCountdown")}>
      <StyledHomeTable header={header} body={rows} data={data} />
      {/* <ExpiringDocumentsModal
        isEnglish={isEnglish}
        openModal={openModal}
        setOpenModal={setOpenModal}
        request={modalData}
        t={t}
      /> */}
    </HomeCard>
  );
};

const mapStateToProps = ({ employees }) => ({
  employees: employees.employees,
});

export default connect(mapStateToProps)(TrialEmployees);
