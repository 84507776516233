import { Chip, IconButton, Typography } from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
} from "../../../../../../components/common";
import { deleteApproverRule } from "../../../../../../redux/actions";
import ApproversForm from "./ApproversForm";

const Row = ({ rule, deleteApproverRule, companyOwnerUser }) => {
  const { t } = useTranslation("common");

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleDeleteRule = (ruleId) => {
    deleteApproverRule({ approverRuleId: ruleId });
  };

  const getDepartmentNames = () => {
    const names = rule?.departments?.map((department) => department.name);
    return names;
  };

  const getLocationsNames = () => {
    const names = rule?.locations?.map((location) => location.name);
    return names;
  };

  return (
    <>
      <CustomTableRow hover role="checkbox">
        <CustomTableCell>
          {getDepartmentNames().map((department, i) => {
            return (
              <Chip
                color="primary"
                variant="outlined"
                key={i}
                label={department}
                style={{ marginRight: 2 }}
              />
            );
          })}
        </CustomTableCell>

        <CustomTableCell>
          {getLocationsNames().map((location, i) => {
            return (
              <Chip
                color="primary"
                variant="outlined"
                key={i}
                label={location}
                style={{ marginRight: 2 }}
              />
            );
          })}
        </CustomTableCell>

        <CustomTableCell>
          {rule?.users?.map((user) => {
            if (user.employee !== null) {
              return (
                <Typography variant="subtitle2" color="textPrimary">
                  {user.employee?.fullName}
                </Typography>
              );
            } else {
              return (
                <Typography variant="subtitle2" color="textPrimary">
                  {companyOwnerUser?.fullName}
                </Typography>
              );
            }
          })}
        </CustomTableCell>

        <CustomTableCell forActionLabel>
          <IconButton
            color="primary"
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton color="primary" onClick={() => handleDeleteRule(rule.id)}>
            <DeleteOutline />
          </IconButton>
        </CustomTableCell>
      </CustomTableRow>
      <CompanyStructureModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        fadeIn={openEditModal}
        title={t("editRule")}
      >
        <ApproversForm setOpen={setOpenEditModal} rule={rule} />
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  companyOwnerUser: auth.user,
});

const mapDispatchToProps = {
  deleteApproverRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Row);
