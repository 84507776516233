//Material UI
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core";
//Actions
import { createDocuments, updateDocument } from "../../../../redux/actions";

import { Add } from "@material-ui/icons";
//Components
import Document from "./Document";
import React from "react";
import { connect } from "react-redux";
//Date
import moment from "moment";
//Translation
import { useTranslation } from "react-i18next";

const DocumentsInformation = ({
  documents,
  createDocuments,
  updateDocument,
}) => {
  const { t } = useTranslation(["companyCreate", "common"]);

  const handleBlur = (document) => {
    updateDocument(document);
  };

  const documentList = documents.map((document) => (
    <Document
      key={`Document ${document.id}`}
      document={document}
      handleBlur={handleBlur}
    />
  ));

  const useStyles = makeStyles(
    (theme) => ({
      addButton: {
        textTransform: "none",
        "&:hover": {
          backgroundColor: theme.palette.common.lightBlue,
          color: "white",
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Box clone display="flex" justifyContent="flex-end">
        <Grid item md={12} xs={12}>
          <Button
            color="primary"
            variant="contained"
            type="button"
            endIcon={<Add />}
            className={classes.addButton}
            onClick={() => {
              createDocuments([
                {
                  name: t("companyCreate:enterName"),
                  exp: moment(new Date()).format("YYYY-MM-DD"),
                  locationId: "",
                },
              ]);
            }}
          >
            <Typography>{t("companyCreate:addDocument")}</Typography>
          </Button>
        </Grid>
      </Box>
      {documentList}
    </Grid>
  );
};

const mapStateToProps = ({ errors, company }) => ({
  documents: company.documents,
  errors,
});

const mapDispatchToProps = {
  createDocuments,
  updateDocument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsInformation);
