import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  createRecurringSalaryModification,
  updateRecurringSalaryModification,
} from "../../../../redux/actions";

import DatePickerField from "../../../common/DatePickerField";
import FormInputField from "../../../common/FormInputField";
import { FormSelectInput } from "../../../common";
import LeaveFile from "../../../common/LeaveFile";
import { Save } from "@material-ui/icons";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const RecurringModificationForm = ({
  _title,
  modification,
  setModification,
  setClose,
  createRecurringSalaryModification,
  updateRecurringSalaryModification,
}) => {
  const { t } = useTranslation("employeeSalary");
  const [sign, setSign] = useState(modification.amount >= 0 ? "+" : "-");

  const handleSwitchChange = () => {
    setModification({
      ...modification,
      receiveDuringVacation: !modification.receiveDuringVacation,
    });
  };
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid
        item
        container
        spacing={3}
        justifyContent="center"
        style={{ margin: 4 }}
      >
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("description")}
            value={modification.reason}
            onChange={(e) =>
              setModification({ ...modification, reason: e.target.value })
            }
            type="text"
            id="description"
            autoComplete="off"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container justifyContent="center">
            <Grid item md={2} xs={2}>
              <FormSelectInput
                label={"+/-"}
                value={sign}
                selectOptions={["+", "-"]}
                onChange={(e) => setSign(e)}
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <FormInputField
                label={t("amount")}
                type="number"
                helperText={t("amountNote")}
                value={
                  _title === t("employeeProfile:recurringEarnings") ||
                  t("editRecurringModification")
                    ? modification.amount
                    : -Math.abs(modification.amount)
                }
                onChange={(e) =>
                  setModification({
                    ...modification,
                    amount: e.target.value,
                  })
                }
                id="amount"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <DatePickerField
            label={t("startDate")}
            value={
              new Date(modification.startDate) < new Date()
                ? new Date()
                : new Date(modification.startDate)
            }
            helperText={t("startNote")}
            minDate={new Date()}
            onChange={(e) =>
              setModification({
                ...modification,
                startDate: moment(e).format("yyyy-MM-DD"),
              })
            }
            disableToolbar={false}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DatePickerField
            label={t("endDate")}
            value={modification.endDate ? new Date(modification.endDate) : null}
            helperText={t("noEnd")}
            minDate={
              modification.startDate &&
              new Date(modification.startDate) >= new Date()
                ? new Date(modification.startDate)
                : new Date()
            }
            onChange={(e) =>
              setModification({
                ...modification,
                endDate: moment(e).format("yyyy-MM-DD"),
              })
            }
            disableToolbar={false}
          />
        </Grid>

        <Grid item md={6}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={modification.receiveDuringVacation}
                onChange={() => handleSwitchChange()}
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="subtitle2" color="textPrimary">
                {t("includeOnVacation")}
              </Typography>
            }
          />
        </Grid>

        <Grid item md={6}>
          <LeaveFile
            value={modification.attachment}
            setValue={(newFile) =>
              setModification({ ...modification, attachment: newFile })
            }
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} style={{ margin: 10 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width={"100%"}
        >
          <Button
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={() =>
              modification.id
                ? updateRecurringSalaryModification(
                    {
                      ...modification,
                      amount:
                        sign === "+"
                          ? modification.amount >= 0
                            ? modification.amount
                            : modification.amount * -1
                          : modification.amount >= 0
                          ? modification.amount * -1
                          : modification.amount,
                      startDate: moment(
                        new Date(modification.startDate)
                      ).format("YYYY-MM-DD"),
                      endDate: modification.endDate
                        ? moment(new Date(modification.endDate)).format(
                            "YYYY-MM-DD"
                          )
                        : null,
                      receiveDuringVacation: modification.receiveDuringVacation,
                      attachment: modification.attachment,
                    },
                    setClose
                  )
                : createRecurringSalaryModification(
                    {
                      ...modification,
                      amount:
                        sign === "+"
                          ? modification.amount >= 0
                            ? modification.amount
                            : modification.amount * -1
                          : modification.amount >= 0
                          ? modification.amount * -1
                          : modification.amount,
                      startDate: moment(
                        new Date(modification.startDate)
                      ).format("YYYY-MM-DD"),
                      endDate: modification.endDate
                        ? moment(new Date(modification.endDate)).format(
                            "YYYY-MM-DD"
                          )
                        : null,
                      receiveDuringVacation: modification.receiveDuringVacation,
                      attachment: modification.attachment,
                    },
                    setClose
                  )
            }
          >
            <Typography>{t("save")}</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  createRecurringSalaryModification,
  updateRecurringSalaryModification,
};

export default connect(null, mapDispatchToProps)(RecurringModificationForm);
