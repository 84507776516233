import { Box, List, useTheme } from "@material-ui/core";
import getMenuItems, {
  attendanceRoute,
  documentsRoute,
  employeessRoute,
  overtimeRoute,
  requestsRoute,
} from "./menuItems";

import SidebarMenuItem from "./SidebarMenuItem";
import { useLocation } from "react-router";

const renderSidebarMenuItems = ({ items, path }) => (
  <Box>
    <List>
      {items?.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </Box>
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;

  if (item?.items) {
    ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        icon={item.icon}
        link={item?.link && item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item?.items,
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu({ user, employeeProfile }) {
  const location = useLocation();
  const theme = useTheme();

  let menuItems = getMenuItems(user);

  let employeeAdditionalRoutes = [];

  const hasPermission =
    user?.hasDocumentAccess ||
    user?.hasAttendanceAccess ||
    user?.hasVacationsAccess;

  if (!user.isCompanyOwner && employeeProfile?.company?.overtimeEnabled) {
    let newMenuItems = menuItems?.map((section) => {
      return {
        heading: section.heading,
        items: [...section.items, overtimeRoute],
      };
    });

    menuItems = newMenuItems;
  }

  if (hasPermission) {
    employeeAdditionalRoutes.push(employeessRoute);
  }

  if (user?.hasDocumentAccess) {
    employeeAdditionalRoutes.push(documentsRoute);
  }

  if (user?.hasAttendanceAccess) {
    employeeAdditionalRoutes.push(attendanceRoute);
  }

  if (user?.hasVacationsAccess) {
    employeeAdditionalRoutes.push(requestsRoute);
  }

  return (
    <>
      {menuItems?.map((section) => (
        <Box
          key={section.heading}
          bgcolor={theme.palette.background.default}
          paddingTop={5}
        >
          <List
            // subheader={<ListSubheader>{t(section.heading)}</ListSubheader>}
            component="div"
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname,
            })}

            {/* {hasPermission && (
              <ListSubheader>{t("portalAccess")}</ListSubheader>
            )} */}

            {employeeAdditionalRoutes.map((item) => {
              return (
                <SidebarMenuItem
                  key={item.name}
                  name={item.name}
                  link={item.link}
                  badge={item.badge}
                  icon={item.icon}
                />
              );
            })}
          </List>
        </Box>
      ))}
    </>
  );
}

export default SidebarMenu;
