import "./Amiri-Regular-normal";

import moment from "moment";

import staffiiLogo from "../images/staffiiIcon.png";

export const getPdfTemplate = (
  pdf,
  pageWidth,
  pdfWidth,
  currentHeight,
  t,
  isEnglish,
  companyName,
  logo,
  hasSubtitle = false
) => {
  pdf.setTextColor("#002553");

  pdf.setFont("Amiri-Regular");

  function checkImageExists(imageUrl, callback) {
    var img = new Image();
    img.onload = function () {
      callback(true);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = imageUrl;
  }

  let imageExists = checkImageExists(logo, function (exists) {
    if (exists) {
      return true;
    } else {
      return false;
    }
  });

  let _logo = new Image();
  if (imageExists) {
    _logo.src = logo;
  } else {
    _logo.src = staffiiLogo;
  }

  pdf.addImage(_logo, 10, 10, 30, 30);

  const printDateTitle = t("common:pdfPrintDate");
  const printDate = moment(new Date()).format("YYYY-MM-DD");
  pdf.setFontSize(8);
  pdf.text(pdfWidth - 50, currentHeight, printDateTitle, "left");
  pdf.text(pdfWidth - 25, currentHeight, printDate, "left");

  const printTimeTitle = t("common:pdfPrintTime");
  const printTime = moment(new Date()).format("HH:MM");
  pdf.setFontSize(8);
  pdf.text(pdfWidth - 50, currentHeight + 3, printTimeTitle, "left");
  pdf.text(pdfWidth - 25, currentHeight + 3, printTime, "left");

  const title = companyName;

  pdf.setFontSize(20);
  pdf.text(title, pageWidth / 2, currentHeight + 10, "center");

  if (hasSubtitle === true) {
    const subtitle = t("common:salarySlipSubtitle");
    pdf.setFontSize(12);
    pdf.text(subtitle, pageWidth / 2, currentHeight + 18, "center");
  }
};
