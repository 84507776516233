import React, { useState } from "react";

//Components
import ProfileImage from "./ProfileImage";
import ProfileInformation from "./ProfileInformation";
import AddressInformation from "./AddressInformation";
import ContactInformation from "./ContactInformation";

//MaterialUI
import {
  Grid,
  useMediaQuery,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Edit, Publish } from "@material-ui/icons";

//Translation
import { useTranslation } from "react-i18next";

//Redux
import { createProfileUpdateRequest } from "../../../../../redux/actions";

import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const File = ({
  touched,
  values,
  onChange,
  onBlur,
  errors,
  profile,
  createProfileUpdateRequest,
}) => {
  const { t } = useTranslation("common");
  const [edit, setEdit] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleCreateProfileUpdate = () => {
    if (Object.keys(updatedProfile).length !== 0) {
      let newObj = updatedProfile;
      if (updatedProfile["nationality"] === "NK") {
        newObj = { ...newObj, nationality: null };
      }
      if (
        updatedProfile["address"] &&
        updatedProfile.address["country"] === "NK"
      ) {
        newObj = { ...newObj, address: { ...newObj.address, country: null } };
      }
      if (
        updatedProfile["poc"] &&
        updatedProfile.poc["address"] &&
        updatedProfile.poc.address["country"] === "NK"
      ) {
        newObj = {
          ...newObj,
          poc: {
            ...newObj.poc,
            address: { ...newObj.poc.address, country: null },
          },
        };
      }
      createProfileUpdateRequest(newObj, history);
    } else {
      setEdit(false);
    }
  };

  const updateRequests = profile.requests.filter(
    (request) => request.status === "PENDING" && request.updateProfileRequest
  );

  if (updateRequests.length > 0) {
    return (
      <Box clone minHeight={"400px"} paddingY={1} paddingX={matchesMD ? 4 : 10}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={3}
        >
          <Grid item md={8}>
            <Typography variant="h3" align="center">
              {t("requestExist")}
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              style={{ textDecoration: "none" }}
              component={Link}
              to={`/request/${updateRequests[updateRequests.length - 1].id}`}
            >
              <Button variant="contained" color="primary">
                {t("viewRequest")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box clone minHeight={"400px"} paddingY={1} paddingX={matchesMD ? 4 : 10}>
      <Grid container alignItems="center" justifyContent="flex-end">
        {!edit && (
          <Grid item>
            <Button
              style={{ color: "#67C57D" }}
              onClick={handleEdit}
              endIcon={<Edit />}
            >
              <Typography variant="h5" style={{ color: "#67C57D" }}>
                {t("edit")}
              </Typography>
            </Button>
          </Grid>
        )}
        <Box clone paddingY={5}>
          <Grid container justifyContent="space-between">
            <ProfileImage
              profile={profile}
              edit={edit}
              updatedProfile={updatedProfile}
              setUpdatedProfile={setUpdatedProfile}
            />
            <ProfileInformation
              profile={profile}
              values={values}
              edit={edit}
              updatedProfile={updatedProfile}
              setUpdatedProfile={setUpdatedProfile}
              onBlur={onBlur}
              errors={errors}
              touched={touched}
              onChange={onChange}
            />
            <AddressInformation
              profile={profile}
              values={values}
              edit={edit}
              updatedProfile={updatedProfile}
              setUpdatedProfile={setUpdatedProfile}
              onBlur={onBlur}
              errors={errors}
              touched={touched}
              onChange={onChange}
            />
            <ContactInformation
              profile={profile}
              values={values}
              edit={edit}
              updatedProfile={updatedProfile}
              setUpdatedProfile={setUpdatedProfile}
              onBlur={onBlur}
              errors={errors}
              touched={touched}
              onChange={onChange}
            />
          </Grid>
        </Box>
        {edit && (
          <Grid item>
            <Button
              style={{ color: "#67C57D" }}
              onClick={handleCreateProfileUpdate}
              endIcon={<Publish />}
            >
              <Typography variant="h5" style={{ color: "#67C57D" }}>
                {t("requestUpdate")}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
const mapStateToProps = ({ employee }) => ({
  profile: employee.profile,
});

const mapDispatchToProps = {
  createProfileUpdateRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(File);
