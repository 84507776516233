import {Box, Input, InputAdornment, makeStyles} from '@material-ui/core'

import React from 'react'
import {SearchSharp} from '@material-ui/icons'

const useStyles = makeStyles(
  theme => ({
    input: {
      color: theme.palette.common.lightBlue,
      fontWeight: 'bold',
    },
  }),
  {index: 1},
)

const SearchInput = ({onChange, placeholder, position, marginTop}) => {
  const classes = useStyles()
  return (
    <Box clone width={'100%'} marginTop={2}>
      <Input
        inputProps={{className: classes.input}}
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position={position}>
            <SearchSharp />
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SearchInput
