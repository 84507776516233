import {
  Box,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { CustomTabPanel } from "../../../components/common";
import { PageHeader } from "../../components/common";
import { Password } from "../../components/AccountSettings/index";
import { connect } from "react-redux";
import { paths } from "../../utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AccountSettings = ({
  employeesDetails,
  fetchEmployeeDetails,
  employeeLoading,
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
  employeeDocumentTypes,
  company,
  profile,
  user,
}) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation(["common", "employeeProfile", "breadcrumbs"]);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const allTabComponents = [
    {
      label: t("security"),
      component: <Password />,
    },
  ];

  const tabs = allTabComponents.map((tab, idx) => (
    <Tab
      disableRipple
      key={tab.label}
      label={tab.label}
      idx={idx}
      style={{
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: "auto",
        textTransform: "none",
        "& + &": {
          marginLeft: 24,
        },
      }}
    />
  ));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    return event;
  };

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.dashboard.account.name}`),
      link: paths.dashboard.account.link,
    },
  ];

  return (
    <Box margin={2}>
      <PageHeader paths={navigationPaths} paddingBottom={1} />

      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={matchesSM ? "on" : "auto"}
        TabScrollButtonProps={{ disabled: false }}
        style={{ marginTop: 5 }}
      >
        {tabs}
      </Tabs>

      <Grid item md={12} xs={12}>
        {allTabComponents.map((tab, idx) => {
          return (
            <CustomTabPanel value={value} index={idx} key={idx}>
              {tab.component}
            </CustomTabPanel>
          );
        })}
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ employees, company, employee, auth }) => ({
  company: company.profile,
  employeesDetails: employees.employeesDetails,
  employeeLoading: employees.employeeLoading,
  employeeDocumentTypes: employees.documentTypes,
  profile: employee.profile,
  user: auth.user,
});

export default connect(mapStateToProps)(AccountSettings);
