import { Box } from "@material-ui/core";

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={4}>{children}</Box>}
    </div>
  );
};

export default CustomTabPanel;
