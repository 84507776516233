import {Box} from '@material-ui/core'
import React from 'react'
import {styled} from '@material-ui/core/styles'

const StyledBox = styled(Box)({
  '& img': {
    height: 60,
  },
})

const Logo = props => {
  return (
    <StyledBox>
      <img alt="Logo" src="/images/staffiiLogo.png" {...props} />
    </StyledBox>
  )
}

export default Logo
