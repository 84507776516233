import React from "react";

//Material UI
import { Collapse, Grid, List, ListItem, Typography } from "@material-ui/core";

//Translation
import { useTranslation } from "react-i18next";

const ResourceDetail = ({ resource, open }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("announcement");
  const isEnglish = language === "en";

  let description = isEnglish ? resource.description : resource.descriptionAr;
  description = description
    .split("/n")
    .map((line) => <Typography key={line}>{line}</Typography>);

  const links = resource.links.map((link) => (
    <ListItem key={link.link}>
      <a href={link.link} target="_blank" rel="noreferrer">
        {isEnglish
          ? link.description
            ? link.description
            : link.link
          : link.descriptionAr
          ? link.descriptionAr
          : link.link}
      </a>
    </ListItem>
  ));

  const files = resource.files.map((file) => (
    <ListItem key={file.file}>
      <a href={file.file} download target="_blank" rel="noreferrer">
        {isEnglish
          ? file.description
            ? file.description
            : file.file
          : file.descriptionAr
          ? file.descriptionAr
          : file.file}
      </a>
    </ListItem>
  ));

  return (
    <Collapse in={open} timeout="auto" component="tr" unmountOnExit>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          {description}
        </Grid>
        {files.length !== 0 ||
          (links.length !== 0 && (
            <Grid item md={12} xs={12}>
              <Typography variant="h3" color="primary">
                {t("attachments")}
              </Typography>
            </Grid>
          ))}
        {files.length !== 0 && (
          <Grid item md={6} xs={12}>
            <Typography variant="h4" color="secondary">
              {t("files")}
            </Typography>
            <List>{files}</List>
          </Grid>
        )}
        {links.length !== 0 && (
          <Grid item md={6} xs={12}>
            <Typography variant="h4" color="secondary">
              {t("links")}
            </Typography>
            <List>{links}</List>
          </Grid>
        )}
      </Grid>
    </Collapse>
  );
};

export default ResourceDetail;
