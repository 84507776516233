import "jspdf-autotable";

import defaultProfilePic from "../../../images/profile_pic_default.png";
import { getPdfTemplate } from "../../../helpers/getPdfTemplate";
import jsPDF from "jspdf";

export const createEmployeeProfilePdf = (
  t,
  isEnglish,
  profile,
  employeeStatus
) => {
  const pdf = new jsPDF();
  var pdfWidth = pdf.internal.pageSize.width;
  const pageWidth = pdfWidth || pdf.internal.pageSize.getWidth();
  const currentHeight = 15;

  getPdfTemplate(
    pdf,
    pageWidth,
    pdfWidth,
    currentHeight,
    t,
    isEnglish,
    profile.company.name,
    profile.company.logo
  );

  let profilePicture = new Image();
  profilePicture.src =
    profile.image === null ? defaultProfilePic : profile.image;
  pdf.addImage(profilePicture, 20, 66, 20, 20);

  // Employee Id
  const employeeIdField = t("employeeProfile:employeeID");
  const employeeId = profile.referenceNumber
    ? profile.referenceNumber
    : profile.id;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 55, employeeIdField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 62, employeeId, "left");

  // Employment Date
  const employmentDateField = t("employeeProfile:employmentDate");
  const employmentDate = profile.employmentDate;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 75, employmentDateField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 82, employmentDate, "left");

  // Gender
  const employeeGenderField = t("employeeProfile:gender");
  const employeeGender = profile.gender;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 95, employeeGenderField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 102, employeeGender, "left");

  // Phone
  const employeePhoneField = t("employeeProfile:number");
  const employeePhone = profile.phoneNumber ? profile.phoneNumber : "-";
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 115, employeePhoneField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 122, employeePhone, "left");

  // Nationality
  const employeeNationalityField = t("employeeProfile:nationality");
  const employeeNationality = profile.nationality.name;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 135, employeeNationalityField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 142, employeeNationality, "left");

  // Status
  const employeeStatusField = t("employeeProfile:status");
  const employeeStatusValue = isEnglish
    ? employeeStatus.name
    : employeeStatus.nameAr;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 155, employeeStatusField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 162, employeeStatusValue, "left");

  // DOB
  const employeeDobField = t("employeeProfile:dateOfBirth");
  const employeeDob = profile.dateOfBirth;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 175, employeeDobField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 182, employeeDob, "left");

  // Address
  const employeeAddressField = t("employeeProfile:address");
  const employeeAddress = `${
    profile.address.country ? profile.address.country.name : "-"
  } - ${profile.address.address1} - ${profile.address.address2}`;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 195, employeeAddressField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 202, employeeAddress, "left");

  // POC
  const employeePocField = t("employeeProfile:poc");
  const employeePoc = `${profile.poc.name} - ${profile.poc.email} - ${profile.poc.number}`;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 215, employeePocField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 222, employeePoc, "left");

  // POC Address
  const employeePocAddressField = t("employeeProfile:pocAddress");
  const employeePocAddress = `${
    profile.poc.address.country ? profile.poc.address.country.name : "-"
  } - ${profile.poc.address.address1} - ${profile.poc.address.address2}`;
  pdf.setFontSize(10);
  pdf.text(48, currentHeight + 235, employeePocAddressField, "left");
  pdf.setFontSize(11);
  pdf.text(48, currentHeight + 242, employeePocAddress, "left");

  pdf.save("profile.pdf");
};

export const getTabLabels = (t, user, company) => {
  let labels = [
    t("overview"),
    t("vacations"),
    t("payroll"),
    t("overtime"),
    t("documents"),
    t("attendances"),
    t("indemnity"),
    t("settings"),
  ];

  if ((user.isCompanyOwner && !company?.overtimeEnabled) || user.isEmployee) {
    const index = labels.indexOf(t("overtime"));
    labels.splice(index, 1);
  }

  if (user.isEmployee) {
    const overviewIndex = labels.indexOf(t("overview"));
    labels.splice(overviewIndex, 1);
  }

  if (user.isEmployee) {
    const settingsIndex = labels.indexOf(t("settings"));
    labels.splice(settingsIndex, 1);
  }

  if (user.isEmployee && !user.hasAttendanceAccess) {
    const attendanceIndex = labels.indexOf(t("attendances"));
    labels.splice(attendanceIndex, 1);
  }

  if (user.isEmployee && !user.hasDocumentAccess) {
    const documentsIndex = labels.indexOf(t("documents"));
    labels.splice(documentsIndex, 1);
  }

  // Remove payroll
  if (user.isEmployee) {
    const payrollIndex = labels.indexOf(t("payroll"));
    labels.splice(payrollIndex, 1);
  }

  // Add indemnity
  if (user.isEmployee && !user.hasIndemnityAccess) {
    const indemnityIndex = labels.indexOf(t("indemnity"));
    labels.splice(indemnityIndex, 1);
  }

  if (user.isEmployee && !user.hasVacationsAccess) {
    const vacationsIndex = labels.indexOf(t("vacations"));
    labels.splice(vacationsIndex, 1);
  }

  return labels;
};
