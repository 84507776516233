import HomeCard from "../../common/HomeCard";
import { Link } from "react-router-dom";
import React from "react";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
// import { isFutureVacation } from "../../../helpers/isFutureVacation";
// import { isLeaveRequestApproved } from "../../../helpers/isLeaveRequestApproved";
// import { normalizeLeaveRequest } from "../../../helpers/normalizeLeaveRequest";
// import { sortByNewest } from "../../../helpers/sortByNewest";
import { useTranslation } from "react-i18next";
import moment from "moment";

const UpcomingEmployeeVacations = ({ requests, employees }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);

  const isEnglish = language === "en";

  const labels = [
    t("common:employeeName"),
    t("common:startDate"),
    t("common:endDate"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={(label, +i)} />
  ));

  ///// deprecated code : get upcoming employee vacations from requests only /////
  // const data = requests
  //   .filter((request) => {
  //     if (isLeaveRequestApproved(request) && isFutureVacation(request)) {
  //       return request;
  //     }
  //     return false;
  //   })

  //   .map((request) => normalizeLeaveRequest(request))
  //   .sort((currentReq, nextRequest) => sortByNewest(currentReq, nextRequest));

  // set end date if leave debit has been created with amount
  const setEndDate = (leave) => {
    let endDate = null;
    if (
      moment(leave.endDate, "dd-mm-yyyy").isSame(
        moment(leave.date, "dd-mm-yyyy"),
      ) &&
      Number(leave.amount) >= 1
    ) {
      endDate = moment(leave.date).add(leave.amount - 1, "days");
    } else {
      endDate = moment(leave.endDate);
    }
    return endDate;
  };

  // check if employee is on vacation in the future
  const isFutureVacation = (leave) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const compareDate = moment(today, "YYYY-MM-DD");
    const isFuture = compareDate.isBefore(leave.date, "days");
    return isFuture;
  };

  // sort leave debits by start date
  const sortLeaveDebits = (leave, nextLeave) => {
    const aDate = moment(leave.startDate, "DD-MM-YYYY");
    const bDate = moment(nextLeave.startDate, "DD-MM-YYYY");
    if (aDate.isBefore(bDate)) {
      return -1;
    } else if (aDate.isAfter(bDate)) {
      return 1;
    } else {
      return 0;
    }
  };

  const data = employees
    .map((employee) => {
      return employee.leaveBalance.leavedebits
        .filter(
          (leave) =>
            leave.kind !== "SHORT" &&
            isFutureVacation(leave) &&
            !leave.balanceAdjustment,
        )
        .map((leave) => {
          let leaveEndDate = setEndDate(leave);
          return {
            name: employee.fullName,
            startDate: moment(leave.date).format("DD-MM-YYYY"),
            endDate: leaveEndDate.format("DD-MM-YYYY"),
            amount: leave.amount,
          };
        });
    })
    .flat(1)
    .sort((leave, nextLeave) => sortLeaveDebits(leave, nextLeave));

  const rows = data.map((entry, i) => {
    return (
      <TableRow
        component={Link}
        to={`request/${entry.id}`}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={4} align={isEnglish ? "left" : "right"}>
          {entry.name}
        </RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.startDate}</RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.endDate}</RequestTableCell>
      </TableRow>
    );
  });
  return (
    <HomeCard
      title={t("home:upcomingEmployeeVacations")}
      navigationAction={"/requests"}
    >
      <StyledHomeTable header={header} body={rows} data={data} />
    </HomeCard>
  );
};

export default UpcomingEmployeeVacations;
