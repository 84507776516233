import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";

import { CompanyStructureModal } from "../../../../../../components/common";
import { createPDF, getXlsxData } from "../utils";
import { normalizeData } from "../data";
import * as XLSX from "xlsx";

/**
 * Reports Modal is a component that displays a modal with a list of headers
 * select boxes allows the user to select which headers to display in the report
 * and a button to generate the report.
 * @category Components - Web
 * @namespace ReportsModal
 * @param {import("./types").ReportModalProps} props
 * @returns {React.JSX.Element} - <ReportsModal />
 */

const ReportsModal = ({
  isVisible,
  closeModal,
  documentHeaders,
  month,
  employee,
  indemnity,
}) => {
  console.log(month);
  const [documentSelectedHeaders, setDocumentSelectedHeaders] =
    useState(documentHeaders);

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const [documentBody, setDocumentBody] = useState([]);

  const memoizedHandleCheckboxChange = useCallback(
    (event) => {
      setDocumentSelectedHeaders(
        documentSelectedHeaders.map((field) =>
          field.value === event.target.name
            ? { ...field, active: !field.active }
            : field,
        ),
      );
    },
    [documentSelectedHeaders],
  );

  useEffect(() => {
    const normalizedEmployees = normalizeData(
      employee,
      documentSelectedHeaders,
      isEnglish,
      moment(month).format("YYYY-MM-DD"),
      indemnity,
    );

    setDocumentBody(normalizedEmployees);
  }, [employee, month, documentSelectedHeaders]);

  const createXlsx = () => {
    let xlsxData = getXlsxData(documentBody, closeModal, t, isEnglish);

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet(xlsxData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      moment().format("YYYY-MM-DD"),
    );
    XLSX.writeFile(
      workbook,
      `indemnity-slip-${employee?.fullname}-${moment(month).format(
        "YYYY-MM-DD",
      )}.xlsx`,
    );
  };

  return (
    isVisible && (
      <CompanyStructureModal
        open={isVisible}
        onClose={closeModal}
        fadeIn={isVisible}
        title={t("indemnitySlip")}
      >
        <>
          (
          <>
            <Box paddingX={4}>
              <Grid container alignItems="center"></Grid>
            </Box>
            <Box
              style={{ overflowY: "auto" }}
              height="auto"
              maxHeight={"60vh"}
              paddingX={4}
              paddingY={4}
            >
              <Typography variant="h5">Fields: </Typography>
              <FormControl>
                <FormGroup>
                  {documentSelectedHeaders.map((field) => {
                    return (
                      <FormControlLabel
                        key={field.value}
                        control={
                          <Checkbox
                            checked={field.active}
                            onChange={memoizedHandleCheckboxChange}
                            name={field.value}
                          />
                        }
                        label={field.label}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              margin={2}
            >
              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createPDF(documentBody, closeModal, t, isEnglish, month);
                  }}
                >
                  <Typography>{t("generatePdf")}</Typography>
                </Button>
              </Box>

              <Box clone marginX={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createXlsx}
                >
                  {t("generateXlsx")}
                </Button>
              </Box>
            </Box>
          </>
          )
        </>
      </CompanyStructureModal>
    )
  );
};

export default ReportsModal;
