import {
  Box,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import { CustomTableCell, CustomTableRow } from "../../../components/common";

import React from "react";
import TablePagination from "./TablePagination";
import { useTranslation } from "react-i18next";

const Table = ({
  data,
  labels,
  body,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  withPagination,
  employerDashboard,
  tableContainerWidth,
  hasActionLabel,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 5);
    setPage(0);
  };

  const headerTitles = labels?.map((label, i) => (
    <CustomTableCell key={(label, +i)}>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align={
          hasActionLabel && i === labels.length - 1
            ? "center"
            : isEnglish
            ? "left"
            : !isEnglish && "right"
        }
      >
        {label}
      </Typography>
    </CustomTableCell>
  ));

  return (
    <>
      <TableContainer style={{ width: tableContainerWidth }}>
        {data && data?.length > 0 && (
          <MuiTable>
            <TableHead>
              <CustomTableRow>{headerTitles}</CustomTableRow>
            </TableHead>
            <TableBody>{body}</TableBody>
          </MuiTable>
        )}
        {data?.length === 0 && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            margin={4}
          >
            <Typography color="primary" align="center">
              {t("noRecords")}
            </Typography>
          </Box>
        )}
      </TableContainer>
      {(employerDashboard || withPagination) && data?.length > 0 && (
        <TablePagination
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default Table;
