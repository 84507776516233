import { Card, CardActions, CardHeader, List } from "@material-ui/core";

import { DetailsItem } from "../../common";
import React from "react";
import { useTranslation } from "react-i18next";

const EmployeeBasicDetails = (props) => {
  const {
    referenceNumber,
    email,
    phone,
    leaveDate,
    employmentDate,
    maritalStatus,
    gender,
    dateOfBirth,
    note,
    ...other
  } = props;

  const { t } = useTranslation("common");

  return (
    <Card
      style={{
        borderRadius: 20,
      }}
      {...other}
    >
      <CardHeader title={t("basicDetails")} />

      <List>
        <DetailsItem label={t("referenceNumber")} value={referenceNumber} />
        <DetailsItem label={t("email")} value={email ? email : ""} />
        <DetailsItem label={t("phoneNumber")} value={phone} />
        <DetailsItem label={t("gender")} value={gender} />
        <DetailsItem label={t("dob")} value={dateOfBirth} />
        <DetailsItem label={t("employmentDate")} value={employmentDate} />
        <DetailsItem label={t("leaveDate")} value={leaveDate} />
        <DetailsItem label={t("maritalStatus")} value={maritalStatus} />
        <DetailsItem label={t("note")} value={note} />
      </List>

      <CardActions></CardActions>
    </Card>
  );
};

export default EmployeeBasicDetails;
