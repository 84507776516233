//Material UI
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
//Components
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
} from "../../../../common";
import React, { useState } from "react";

import { Add } from "@material-ui/icons";
import LocationForm from "./LocationForm";
import LocationRow from "./LocationRow";
import { connect } from "react-redux";
//Styles
import useStyles from "../../styles";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const Location = ({ locations }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation(["common", "companyProfile"]);

  const locationsList = locations.map((location) => (
    <LocationRow location={location} key={location.id} />
  ));

  return (
    <Box clone marginY={3}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <Box clone bgcolor="#F6F8FA">
            <TableHead>
              <CustomTableRow>
                <CustomTableCell colSpan={matchesMD ? 4 : 11}>
                  <Typography color="primary" variant="h5">
                    {t("companyProfile:locations")}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell colSpan={matchesMD ? 4 : 1}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      className={classes.addDepartment}
                      variant="contained"
                      color="primary"
                      endIcon={<Add />}
                      onClick={() => setOpenModal(true)}
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: matchesSM && "10px",
                      }}
                    >
                      {t("companyProfile:addLocation")}
                    </Button>
                  </Box>

                  <CompanyStructureModal
                    height="350px"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    fadeIn={openModal}
                    title={t("companyProfile:addLocation")}
                  >
                    <LocationForm setOpen={setOpenModal} />
                  </CompanyStructureModal>
                </CustomTableCell>
              </CustomTableRow>
            </TableHead>
          </Box>
          <TableBody>{locationsList}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  locations: company.locations,
});

export default connect(mapStateToProps)(Location);
