import { Box, Button, Grid, Typography } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { CompanyStructureModal } from "../../../../components/common";
import React from "react";
import { updateEmployee } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const EmployeeDeleteModal = (props) => {
  const { openDeleteModal, setOpenDeleteModal, employeeId } = props;
  const { t } = useTranslation(["common", "employeeProfile"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(updateEmployee({ id: +employeeId, archived: true }))
      .then(() => {
        setOpenDeleteModal(false);

        history.push("/employeesList");
        // add succes alert here
      })
      .catch((error) => {
        <Alert severity="error">
          {t("employeeProfile:failedToDeleteEmployee")}
        </Alert>;
      });
  };

  return (
    <CompanyStructureModal
      open={openDeleteModal}
      fadeIn={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      title={t("common:deleteEmployee")}
    >
      <Grid container spacing={3} style={{ margin: 5 }}>
        <Grid item md={12}>
          <Typography variant="h3">
            {t("common:employeeDeleteConfirm")}
          </Typography>
        </Grid>
        <Box
          clone
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          paddingX={2}
          paddingY={2}
        >
          <Grid item container md={12} sm={12} xs={11}>
            <Grid item md={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDelete}
              >
                {t("common:confirm")}
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                variant="outlined"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t("common:cancel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </CompanyStructureModal>
  );
};

export default EmployeeDeleteModal;
