import React from "react";

import { Grid, Box, useMediaQuery, Button } from "@material-ui/core";
import { Clear } from "@material-ui/icons/";
import { useTheme } from "@material-ui/styles";

//Components
import FormInputField from "../../../common/FormInputField";
import DatePickerField from "../../../common/DatePickerField";

//Translation
import { useTranslation } from "react-i18next";

const RecurringSalaryModification = ({ state, onChange, handleRemove }) => {
  const { t } = useTranslation(["common", "employeeAdd"]);

  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const paddingY = matchesMD ? 0 : 3;
  const paddingX = matchesSM ? 0 : 3;

  const removeButton = (
    <Box clone paddingY={paddingY} paddingX={paddingX}>
      <Grid item xs={12} md={1}>
        <Grid container justifyContent={matchesSM ? "flex-end" : "center"}>
          <Button variant="text" onClick={handleRemove}>
            <Clear />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Grid container>
      {matchesSM && removeButton}
      <Box clone paddingY={paddingY} paddingX={paddingX}>
        <Grid item xs={12} md={3}>
          <FormInputField
            label={t("employeeAdd:modificationType")}
            name="reason"
            onChange={onChange}
            type="text"
            value={state.reason}
            id="reason"
            autoComplete="new-password"
          />
        </Grid>
      </Box>
      <Box clone paddingY={paddingY} paddingX={paddingX}>
        <Grid item xs={12} md={3}>
          <FormInputField
            label={t("employeeAdd:value")}
            name="amount"
            type="number"
            helperText={t("employeeAdd:valueEg")}
            onChange={onChange}
            value={state.amount}
            id="amount"
            autoComplete="new-password"
          />
        </Grid>
      </Box>
      <Box clone paddingY={paddingY} paddingX={paddingX}>
        <Grid item xs={12} md={3}>
          <DatePickerField
            label={t("common:month")}
            name="month"
            onChange={onChange}
            value={state.month}
            disableToolbar={false}
          />
        </Grid>
      </Box>
      {!matchesSM && removeButton}
    </Grid>
  );
};

export default RecurringSalaryModification;
