import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormInputField } from "../../../../../components/common";
import LeaveFile from "../../../../../components/common/LeaveFile";

export const PayrollForm = ({
  data,
  idx,
  uploadData,
  setData,
  setDisabled,
}) => {
  const { t } = useTranslation(["common", "upload", "employeeSalary"]);
  const [payrollData, setPayrollData] = useState(data);
  const [error, setError] = useState("");

  // handle data changes
  const handleChange = (field, value) => {
    setPayrollData({ ...payrollData, [field]: value });
  };

  // set error and validate data
  useEffect(() => {
    let errors = {};

    // set errors
    if (!payrollData.id) {
      errors.id = t("upload:idError");
    }
    if (!payrollData.amount) {
      errors.amount = t("upload:amountError");
    }
    if (!payrollData.date) {
      errors.date = t("upload:dateError");
    }
    setError(errors);

    // normalize data
    if (payrollData.type.toLowerCase() !== "reccuring") {
      if (payrollData.hasOwnProperty("end_date")) {
        delete payrollData.end_date;
      }
    } else {
      if (!payrollData.hasOwnProperty("end_date")) {
        payrollData.end_date = "";
      }
      if (!payrollData.hasOwnProperty("recieve_during_vacation")) {
        payrollData.recieve_during_vacation = true;
      }
    }

    // update data
    let temp = uploadData;
    temp[idx] = payrollData;
    setData(temp);
    // eslint-disable-next-line
  }, [payrollData, idx, setData, uploadData]);

  if (Object.keys(error).length > 0) {
    setDisabled(true);
  } else {
    setDisabled(false);
  }
  return (
    <Grid container spacing={1}>
      <Grid
        container
        justifyContent="flex-start"
        style={{ marginLeft: "25px" }}
        item
        xs={12}
        lg={12}
        md={12}
      >
        <Typography variant="h2" color="primary">
          {payrollData.type.toLowerCase() === "reccuring"
            ? t("employeeSalary:addRecurringModification")
            : t("employeeSalary:addModification")}
        </Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("fullName")}
            name="fullName"
            value={payrollData.full_name ? payrollData.full_name : ""}
            onChange={(e) => handleChange("full_name", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired={true}
            label={t("id")}
            name="id"
            errors={error && error.id}
            value={payrollData.id ? payrollData.id : ""}
            onChange={(e) => handleChange("id", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            label={t("reason")}
            name="reason"
            value={payrollData.reason ? payrollData.reason : ""}
            onChange={(e) => handleChange("amount", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <FormInputField
            isRequired={true}
            label={t("amount")}
            name="amount"
            errors={error && error.amount}
            value={payrollData.amount ? payrollData.amount : ""}
            onChange={(e) => handleChange("amount", e.target.value)}
          />
        </Grid>
      </Grid>
      {payrollData.type.toLowerCase() !== "reccuring" ? (
        <>
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            lg={12}
            md={12}
            style={{ marginInline: "20px" }}
          >
            <Grid item xs={12} lg={12} md={12}>
              <FormInputField
                isRequired={true}
                label={t("date")}
                name="date"
                errors={error && error.date}
                value={payrollData.date ? payrollData.date : ""}
                onChange={(e) => handleChange("date", e.target.value)}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid item xs={11} lg={11} md={11}>
              <FormInputField
                isRequired={true}
                label={t("date")}
                name="date"
                errors={error && error.date}
                value={payrollData.date ? payrollData.date : ""}
                onChange={(e) => handleChange("date", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid item xs={11} lg={11} md={11}>
              <FormInputField
                label={t("endDate")}
                name="endDate"
                value={payrollData.end_date ? payrollData.end_date : ""}
                onChange={(e) => handleChange("end_date", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              md={11}
              xs={11}
              lg={11}
            >
              <Typography variant="body1" color="primary">
                {t("employeeSalary:includeOnVacation")}
              </Typography>

              <Checkbox
                checked={
                  payrollData.recieve_during_vacation
                    ? payrollData.recieve_during_vacation
                    : false
                }
                onChange={(e) => {
                  handleChange("balance_adjustment", e.target.checked);
                }}
                style={{ marginInline: "20px" }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item xs={11} lg={11} md={11}>
          <Typography color="primary" variant="h6" style={{ fontSize: "16px" }}>
            {t("document")}
          </Typography>
          <LeaveFile
            value={payrollData.document ? payrollData.document : null}
            setValue={(file) => handleChange("document", file)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
