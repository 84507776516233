import { Box, Button, Grid, Typography } from "@material-ui/core";

import { Add } from "@material-ui/icons";
import DocumentModal from "./DocumentModal/index";
import DeleteDocumentModal from "./DeleteDocumentModal/index";
import Item from "./Item";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Documents = ({ data, forCompanyPage }) => {
  const { t } = useTranslation("common");

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [documentState, setDocumentState] = useState(null);

  const documents = forCompanyPage ? data : data.documents;
  const employeeId = !forCompanyPage && data.id;
  console.log(documents);
  console.log(employeeId);
  const documentItems = documents?.map((doc) => {
    return (
      <Grid item xs={12} lg={4}>
        <Item
          employeeId={!forCompanyPage && employeeId}
          doc={doc}
          key={doc.number}
          setOpen={setOpen}
          setOpenDelete={setOpenDeleteModal}
          setDocumentState={setDocumentState}
          forCompanyPage={forCompanyPage}
        />
      </Grid>
    );
  });

  const DocumentsList = () => {
    return documentItems.length > 0 ? (
      documentItems
    ) : (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="40vh"
      >
        <Typography align="center" variant="h5" color="secondary">
          {t("noRecords")}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item container direction="row-reverse" xs={12} lg={12}>
          <Button
            endIcon={<Add color="inherit" />}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("addDocument")}
          </Button>
        </Grid>
        <DocumentsList />
      </Grid>
      <DocumentModal
        open={open}
        setOpen={setOpen}
        documentState={documentState}
        setDocumentState={setDocumentState}
        forCompanyPage={forCompanyPage}
      />
      <DeleteDocumentModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        documentState={documentState}
        setDocumentState={setDocumentState}
        employeeId={!forCompanyPage && employeeId}
      />
    </>
  );
};

export default Documents;
