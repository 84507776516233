//Material UI
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core/";

import { Link } from "react-router-dom";
import React from "react";
import theme from "../../styles/theme";
//Translation
import { useTranslation } from "react-i18next";

const StyledListItem = ({
  title,
  to,
  icon,
  onClick,
  disabled = false,
  inset = false,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: isEnglish ? "left" : "right",
    },
    button: {
      "&:hover": {
        backgroundColor: "white",
        color: theme.palette.common.lightBlue,
        fontWeight: "bolder",
      },
    },
    icon: {
      color: theme.palette.common.blue,
      "&:hover": {
        color: theme.palette.common.lightBlue,
      },
    },
    iconAlign: {
      display: isEnglish ? "contents" : undefined,
    },
  }));

  const classes = useStyles();
  return to ? (
    <ListItem
      disabled={disabled}
      disableGutters
      className={classes.root}
      classes={{ button: classes.button }}
      onClick={onClick}
      component={Link}
      to={to}
      button
    >
      {icon && !isEnglish ? (
        <ListItemIcon
          classes={{ root: classes.iconAlign }}
          className={classes.icon}
        >
          {icon}
        </ListItemIcon>
      ) : (
        ""
      )}
      <ListItemText
        inset={inset}
        primary={title}
        primaryTypographyProps={{
          style: {
            color: theme.palette.common.yellow,
            fontSize: "1rem",
            fontWeight: "bolder",
          },
        }}
      />
      {icon && isEnglish ? (
        <ListItemIcon
          classes={{ root: classes.iconAlign }}
          className={classes.icon}
        >
          {icon}
        </ListItemIcon>
      ) : (
        ""
      )}
    </ListItem>
  ) : (
    <ListItem
      disabled={disabled}
      disableGutters
      className={classes.root}
      classes={{ button: classes.button }}
      onClick={onClick}
      button
    >
      {icon && !isEnglish ? (
        <ListItemIcon
          classes={{ root: classes.iconAlign }}
          className={classes.icon}
        >
          {icon}
        </ListItemIcon>
      ) : (
        ""
      )}
      <ListItemText
        inset={inset}
        primary={title}
        primaryTypographyProps={{
          style: {
            color: theme.palette.common.yellow,
            fontSize: "1rem",
            fontWeight: "bolder",
          },
        }}
      />
      {icon && isEnglish ? (
        <ListItemIcon
          classes={{ root: classes.iconAlign }}
          className={classes.icon}
        >
          {icon}
        </ListItemIcon>
      ) : (
        ""
      )}
    </ListItem>
  );
};

export default StyledListItem;
