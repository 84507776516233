import { SET_CURRENT_USER, REGISTER_SUCCESS } from "../actions/types";

const initialState = {
  user: null,
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return { ...state, user: payload };

    case REGISTER_SUCCESS:
      return state;

    default:
      return state;
  }
};
