//Components

//Moment
import moment from "moment";
import React from "react";
//Translation
import { useTranslation } from "react-i18next";

import {
  requestsKind,
  requestsStatus,
} from "../../../../../components/common/DropDownsData";
import { RequestsTable } from "../../../common";

const ResignationReq = ({ requests, title, icon }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const headerLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:startDate"),
    t("common:createdOn"),
    t("common:status"),
    t("common:lastModified"),
    t("common:modifiedBy"),
  ];

  let resignations = requests
    .filter((request) => request.resignationRequest)
    .map((request) => {
      const kind = () => {
        let kindObj = requestsKind.find((kind) => kind.value === request.kind);
        if (kindObj) {
          if (isEnglish) return kindObj.name;
          return kindObj.nameAr;
        }
        return "";
      };
      const status = () => {
        let statusObj = requestsStatus.find(
          (status) => status.value === request.status
        );
        if (isEnglish) return statusObj.name;
        return statusObj.nameAr;
      };
      return {
        id: request.id,
        reason: request.resignationRequest.reason,
        kind: kind(),
        startDate: moment(new Date(request.resignationRequest.date)).format(
          "DD-MM-yyyy"
        ),
        createdOn: moment(new Date(request.createdOn)).format(
          "DD-MM-yyyy hh:mm a"
        ),
        status: status(),
        lastModified: moment(new Date(request.lastModified)).format(
          "DD-MM-yyyy hh:mm a"
        ),
        lastModifiedBy: request.lastModifiedBy?.fullName,
      };
    });

  return (
    <RequestsTable
      title={title}
      icon={icon}
      data={resignations}
      labels={headerLabels}
      toRow={"/request"}
    />
  );
};

export default ResignationReq;
