// Material UI
import { Box, Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Components
import {
  CompanyStructureModal,
  FormSelectInput,
  SectionTitle,
} from "../../../common";
import { NotesSharp, TouchApp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
// Store
import {
  createEmployeeAttendance,
  fetchEmployeeActiveAttendance,
  updateEmployeeAttendance,
} from "../../../../redux/actions/employee";

import AttendanceTable from "../../../common/AttendanceTable";
import CheckInModal from "./Modals/CheckInModal";
import CheckOutModal from "./Modals/CheckOutModal";
// Analytics
import ReactGA from "react-ga";
import { connect } from "react-redux";
// Hooks
import useGeoLocation from "../../../common/useGeoLocation";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
// Translations
import { useTranslation } from "react-i18next";
//calculte checkin distance
import { calcDistance } from "../../../common/utils";

const EmployeeAttendance = ({
  profile,
  locations,
  activeAttendance,
  fetchEmployeeActiveAttendance,
  createEmployeeAttendance,
  updateEmployeeAttendance,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  ReactGA.pageview(window.location.pathname);
  const profileId = profile?.id;
  const [attendanceId, setAttendanceId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");

  const [openNewModal, setOpenNewModal] = useState(false);
  const [openCheckOutModal, setOpenCheckOutModal] = useState(false);

  const theme = useTheme();
  const { location, setLocation } = useGeoLocation();

  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (activeAttendance.length === 0 && profileId) {
      fetchEmployeeActiveAttendance(profileId);
    }
  }, [profileId, activeAttendance.length, fetchEmployeeActiveAttendance]);

  const handleCheckIn = () => {
    if (!("allowed" in location) || !location.loaded) {
      setOpenModal(true);
    } else {
      setOpenNewModal(true);
    }
  };

  const handlePendingCheckout = (id) => {
    if (!("allowed" in location) || !location.loaded) {
      setAttendanceId(id);
      setOpenCheckOutModal(true);
    }
    const selectedLocation = activeAttendance[0].location;
    console.log("selectedLocation", selectedLocation);
    let distance = calcDistance(
      selectedLocation.latitude ? selectedLocation.latitude : 0,
      location.coordinates.latitude,
      selectedLocation.longitude ? selectedLocation.longitude : 0,
      location.coordinates.longitude,
    );
    const { checkInDistanceLimit } = profile.company;
    console.log(
      Number(checkInDistanceLimit) !== 0 &&
        (isNaN(distance) || Number(distance) > Number(checkInDistanceLimit)),
    );
    if (
      checkInDistanceLimit === null ||
      Number(checkInDistanceLimit) === 0 ||
      Number(distance) <= Number(checkInDistanceLimit)
    ) {
      updateEmployeeAttendance({
        id,
        latitude:
          location && location.allowed ? location.coordinates.latitude : "",
        longitude:
          location && location.allowed ? location.coordinates.longitude : "",
      });
    } else if (
      Number(checkInDistanceLimit) !== 0 &&
      (isNaN(distance) || Number(distance) > Number(checkInDistanceLimit))
    ) {
      setError(
        t("checkOutDistanceLimitError").replace(
          "{.}",
          checkInDistanceLimit * 1000,
        ),
      );
    }
  };

  if (!profile) return <></>;
  if (!activeAttendance) return <></>;
  if (!location) return <></>;

  return (
    <>
      <CompanyStructureModal
        open={openNewModal}
        onClose={() => setOpenNewModal(false)}
        fadeIn={openNewModal}
        title={t("checkIn")}
      >
        <Box margin={4}>
          {error && (
            <Grid item md={8} xs={12}>
              <Alert onClick={() => setError("")} severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <FormSelectInput
            isRequired={true}
            value={location.locationId}
            selectOptions={profile.locations.map((location) => ({
              label: isEnglish ? location.name : location.nameAr,
              value: location.id,
            }))}
            onChange={(e) => setLocation({ ...location, locationId: e })}
            label={t("location")}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const selectedLocation = profile.locations.find(
                  (el) => location.locationId === el.id,
                );
                const distance = calcDistance(
                  selectedLocation.latitude,
                  location.coordinates.latitude,
                  selectedLocation.longitude,
                  location.coordinates.longitude,
                );
                const { checkInDistanceLimit } = profile.company;
                console.log(checkInDistanceLimit);
                if (
                  checkInDistanceLimit === null ||
                  Number(checkInDistanceLimit) === 0 ||
                  Number(distance) <= Number(checkInDistanceLimit)
                ) {
                  createEmployeeAttendance({
                    latitude:
                      location && location.allowed
                        ? location.coordinates.latitude
                        : "",
                    longitude:
                      location && location.allowed
                        ? location.coordinates.longitude
                        : "",
                    locationId: location.locationId,
                  });
                  setOpenNewModal(false);
                } else if (
                  isNaN(distance) ||
                  Number(distance) > Number(checkInDistanceLimit)
                ) {
                  setError(
                    t("checkInDistanceLimitError").replace(
                      "{.}",
                      checkInDistanceLimit * 1000,
                    ),
                  );
                }
              }}
            >
              {t("checkIn")}
            </Button>
          </Box>
        </Box>
      </CompanyStructureModal>
      <CheckInModal
        t={t}
        location={location}
        openCheckInModal={openModal}
        onClickCancel={() => {
          setOpenModal(false);
        }}
        onClickCheckIn={() => {
          setOpenModal(false);
          setOpenNewModal(true);
        }}
        onClickLoaded={() => setOpenModal(false)}
        onClickNotLoaded={() => setOpenModal(false)}
        onCloseModal={() => setOpenModal(false)}
      />

      <CheckOutModal
        t={t}
        location={location}
        openCheckOutModal={openCheckOutModal}
        onClickCancel={() => {
          setOpenCheckOutModal(false);
        }}
        onClickCheckOut={() => {
          updateEmployeeAttendance({
            id: attendanceId,
            latitude: "",
            longitude: "",
          });
          setOpenCheckOutModal(false);
        }}
        onClickLoaded={() => setOpenCheckOutModal(false)}
        onClickNotLoaded={() => setOpenCheckOutModal(false)}
        onCloseModal={() => setOpenCheckOutModal(false)}
      />
      <Grid item md={11} xs={12}>
        <Box
          minHeight={"700px"}
          bgcolor="white"
          paddingX={matchesMD ? 2 : 6}
          marginBottom={3}
          paddingY={3}
          borderRadius={10}
        >
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box
                paddingBottom={5}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <SectionTitle
                  title="Check In / Check Out"
                  icon={<NotesSharp color="primary" />}
                />

                <Button
                  style={{ width: "195px", height: "50px" }}
                  variant="contained"
                  color="primary"
                  endIcon={<TouchApp />}
                  onClick={() => handleCheckIn()}
                >
                  {t("checkIn")}
                </Button>
              </Box>
            </Grid>
            {error && (
              <Grid item md={8} xs={12}>
                <Alert onClick={() => setError("")} severity="error">
                  {error}
                </Alert>
              </Grid>
            )}
            <AttendanceTable
              employeeDashboard
              attendances={activeAttendance}
              handlePendingCheckout={handlePendingCheckout}
              hasLocation={profile.location}
              profile={profile}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ employee, company }) => ({
  profile: employee.profile,
  activeAttendance: employee.activeAttendance,
  locations: company.locations,
});

const mapDispatchToProps = {
  fetchEmployeeActiveAttendance,
  createEmployeeAttendance,
  updateEmployeeAttendance,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAttendance);
