import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import { CustomTableRow } from "../../../common";
import HolidayItem from "./HolidayItem";
import { TablePagination } from "../../../../redesign/components/common";
import { connect } from "react-redux";
//Translation
import { useTranslation } from "react-i18next";

const HolidayTable = ({
  officialHolidays,

  open,
  setOpen,
  setHoliday,
}) => {
  const { t } = useTranslation("common");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("start");

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "name":
        if (b.name < a.name || b.nameAr < a.nameAr) {
          return -1;
        }
        if (b.name > a.name || b.nameAr > a.nameAr) {
          return 1;
        }
        return 0;
      case "start":
        if (new Date(b.start) < new Date(a.start)) {
          return -1;
        }
        if (new Date(b.start) > new Date(a.start)) {
          return 1;
        }
        return 0;
      case "end":
        if (new Date(b.end) < new Date(a.end)) {
          return -1;
        }
        if (new Date(b.end) > new Date(a.end)) {
          return 1;
        }
        return 0;
      default:
        return orderBy;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "name", label: t("name") },
    { id: "startDate", label: t("startDate") },
    { id: "endDate", label: t("endDate") },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      setOrderBy(property);
      onRequestSort(event, property);
    };

    const useStyles = makeStyles(() => ({
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }));

    const classes = useStyles();

    return (
      <TableHead>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                whiteSpace: "nowrap",
                borderBottom: "none",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </CustomTableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const data = stableSort(officialHolidays, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((holiday, idx) => (
      <HolidayItem
        holiday={holiday}
        key={`${holiday.name}-${idx}`}
        open={open}
        setOpen={setOpen}
        setHoliday={setHoliday}
      />
    ));

  return (
    <>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>{data}</TableBody>
        </Table>
        {data.length === 0 && (
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Typography color="primary" align="center">
              {t("noRecords")}
            </Typography>
          </Box>
        )}
      </TableContainer>
      <TablePagination
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  officialHolidays: company.vacation.officialHolidays,
});

export default connect(mapStateToProps)(HolidayTable);
