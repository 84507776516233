import { Box, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";

import { CompanyStructureModal } from "./components/common";
import { updateTerms } from "./redux/actions";

const TermsAndPrivacy = ({ user, updateTerms }) => {
  const { t } = useTranslation("home");

  const markdown = `
Privacy Notice

Thank you for choosing to be part of our community at Staffii Company L.L.C
("Company," "we," "us," or "our"). We are committed to protecting your personal
information and your right to privacy. If you have any questions or concerns about
this privacy notice or our practices with regard to your personal information, please
contact us at info@joinstaffii.com.
This privacy notice describes how we might use your information if you:
- Visit our website at http://joinstaffi.com
- Engage with us in other related ways - including any sales, marketing, or
events
In this privacy notice, if we refer to:
- "Website," we are referring to any website of ours that references or links to
this policy
- "Services," we are referring to our Website, and other related services,
including any sales, marketing, or events
The purpose of this privacy notice is to explain to you in the clearest way possible
what information we collect, how we use it, and what rights you have in relation to it.
If there are any terms in this privacy notice that you do not agree with, please
discontinue use of our Services immediately.
Please read this privacy notice carefully, as it will help you understand what we
do with the information that we collect.
1. WHAT INFORMATION DO WE COLLECT?
2. HOW DO WE USE YOUR INFORMATION?
3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
4. WHO WILL YOUR INFORMATION BE SHARED WITH?
5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
6. HOW LONG DO WE KEEP YOUR INFORMATION?
7. HOW DO WE KEEP YOUR INFORMATION SAFE?
8. WHAT ARE YOUR PRIVACY RIGHTS?
9. CONTROLS FOR DO-NOT-TRACK FEATURES
10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
11. DO WE MAKE UPDATES TO THIS NOTICE?
12. HOW CAN YOU CONTACT US ABOUT HIS NOTICE?
13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
FROM YOU?

**1. WHAT INFORMATION DO WE COLLECT?**

Personal information you disclose to us
In short: We collect personal data that you provide to us.
We collect personal information that you voluntarily provide to us when you register
on the Website, express an interest in obtaining information about us or our products
and Services, when you participate in activities on the Website (such as by posting
messages in our online forums or entering competitions, contests or giveaways) or
otherwise when you contact us.
The personal information that we collect depends on the context of your interactions
with us and the Website, the choices you make and the products and features you
use. The personal information we collect may include the following:
Personal Information Provided by You. We collect names; phone numbers; email
addresses; mailing addresses; job titles; usernames; passwords; contact
preferences; contact or authentication data; billing addresses; debit/credit card
numbers; and other similar information.
Payment Data. We may collect data necessary to process your payment if you make
purchases, such as your payment instrument number (such as a credit card number),
and the security code associated with your payment instrument. All payment data is
stored by _________
You may find their privacy notice link(s) here: ________
All personal information that you provide to us must be true, complete and accurate,
and you must notify us of any changes to such personal information.
Information automatically collect
In Short: Some information - such as your Internet Protocol (IP) address and/or
browser and device characteristics - is collected automatically when you visit our
Website.
We automatically collect certain information when you visit, use or navigate the
Website. This information does not reveal your specific identity (like your name or
contact information) but may include device and usage information, such as your IP
address, browser and device characteristics, operating system, language
preferences, referring URLs, device name, country, location, information about how
and when you use our Website and other technical information. This information is
primarily needed to maintain the security and operation of our Website, and for our
internal analytics and reporting purposes.
Like many businesses, we also collect information through cookies and similar
technologies.
- Log and Usage Data. Log and usage data is service-related, diagnostic, usage
and performance information our servers automatically collect when you
access or use our Website and which we record in log files. Depending on how
you interact with us, this log data may include your IP address, device
information, browser type and settings and information about your activity in
the Website (such as the date/time stamps associated with your usage, pages
and files viewed, searches and other actions you take such as which features
you use), device event information (such as system activity, error reports
(sometimes called 'crash dumps') and hardware settings).
- Device Data. We collect device data such as information about your computer,
phone, tablet or other device you use to access the Website. Depending on
the device used, this device data may include information such as your IP
address (or proxy server), device and application identification numbers,
location, browser type, hardware model Internet service provider and/or mobile
carrier, operating system and system configuration information.
- Location Data. We collect location data such as information about your
device's location, which can be either precise or imprecise. How much
information we collect depends on the type and settings of the device you use
to access the Website. For example, we may use GPS and other technologies
to collect geolocation data that tells us your current location (based on your IP
address). You can opt out of allowing us to collect this information either by
refusing access to the information or by disabling your Location setting on your
device. Note however, if you choose to opt out, you may not be able to use
certain aspects of the Services.

**2. HOW DO WE USE YOUR INFORMATION?**

In Short: We process your information for purposes based on legitimate business
interests, the fulfillment of our contract with you, compliance with our legal
obligations, and/or your consent.
We use personal information collected via our Website for a variety of business
purposes described below. We process your personal information for these purposes
in reliance on our legitimate business interests, in order to enter into or perform a
contract with you, with your consent, and/or for compliance with our legal obligations.
We indicate the specific processing grounds we rely on next to each purpose listed
below.
We use the information collect or receive:
- To facilitate account creation and logon process. If you choose to link your
account with us to a third-party account (such as your Google or Facebook
account), we use the information you allowed us to collect from those third
parties to facilitate account creation and logon process for the performance of
the contract.
- To post testimonials. We post testimonials on our Website that may contain
personal information. Prior to posting a testimonial, we will obtain your consent
to use your name and the content of the testimonial. If you wish to update, or
delete your testimonial, please contact us at info@joinstaffii.com and be sure
to include your name, testimonial location, and contact information.
- Request feedback. We may use your information to request feedback and to
contact you about your use of our Website.
- To enable user-to-user communications. We may use your information in
order to enable user-to-user communications with each user's consent.
- To manage user accounts. We may use your information for the purposes of
managing our account and keeping it in working order.
- To send administrative information to you. We may use your personal
information to send you product, service and new feature information and/or
information about changes to our terms, conditions, and policies.
- To protect our Services. We may use your information as part of our efforts
to keep our Website safe and secure (for example, for fraud monitoring and
prevention).
- To enforce our terms, conditions and policies for business purposes, to
comply with legal and regulatory requirements or in connection with our
Contract.
- To respond to legal requests and prevent harm. If we receive a subpoena
or other legal request, we may need to inspect the data we hold to determine
how to respond.
- For other business purposes. We may use your information for other
business purposes, such as data analysis, identifying usage trends,
determining the effectiveness of our promotional campaigns and to evaluate
and improve our Website, products, marketing and your experience. We may
use and store this information in aggregated and anonymized form so that it is
not associated with individual end users and does not include personal
information.

**3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?**


In short: We only share information with your consent, to comply with laws, to provide you with
services, to protect your rights, or to fulfill business obligations.
We may process or share your data that we hold based on the following legal basis:
- Consent: We may process your data if you have given us specific consent to
use your personal information for a specific purpose.
- Legitimate Interests: We may process your data when it is reasonably
necessary to achieve our legitimate business interests.
- Performance of a Contract: Where we have entered into a contract with you,
we may process your personal information to fulfill the terms of our contract.
- Legal Obligations: We may disclose your information where we are legally
required to do so in order to comply with applicable law, governmental
requests, a judicial proceeding, court order, or legal process, such as in
response to a court order or a subpoena (including in response to public
authorities to meet national security or law enforcement requirements).
- Vital Interests: We may disclose your information where we believe it is
necessary to investigate, prevent, or take action regarding potential violations
of our policies, suspected fraud, situations involving potential threats to the
safety of any person and illegal activities, or as evidence in litigation in which
we are involved.
More specifically, we may need to process your data or share your personal
information in the following situations:
- Business Transfers. We may share or transfer your information in connection
with, or during negotiations of, any merger, sale of company assets, financing,
or acquisition of all or a portion of our business to another company.
- Google Maps Platform APls. We may share your information with certain
Google Maps Platform APIs (e.g., Google Maps API, Place API). To find out
more about Google's Privacy Policy, please refer to this link. We use certain
Google Maps Platform APIs to retrieve certain information when you make
location-specific requests. This includes: Get the location only when they click
'Check In' and 'Check Out'; and other similar information. A full list of what we
use information for can be found in this section and in the previous section
titled "HOW DO WE USE YOUR INFORMATION?". We obtain and store on
your device ('cache') your location. You may revoke your consent anytime by
contacting us at the contact details provided at the end of this document. The
Google Maps Platform APls that we use store and access cookies and other
information on your devices. If you are a user currently in the European
Economic Area (EU countries, Iceland, Liechtenstein and Norway) or United
Kingdom, please take a look at our Cookie Notice.
- Vendors, Consultants and Other Third-Party Service Providers. We may
share your data with third-party vendors, service providers, contractors or
agents who perform services for us or on our behalf and require access to
such information to do that work. Examples include: payment processing, data
analysis, email delivery, hosting services, customer service and marketing
efforts. We may allow selected third parties to use tracking technology on the
Website, which will enable them to collect data on our behalf about how you
interact with our Website over time. This information may be used to, among
other things, analyze and track data, determine the popularity of certain
content, pages or features, and better understand online activity. Unless
described in this notice, Iwe do not share, sell, rent or trade any of your
information with third parties for their promotional purposes.
- Affiliates. We may share your information with our affiliates, in which case we
will require those affiliates to honor this privacy notice. Affiliates include our
parent company and any subsidiaries, joint venture partners or other
companies that we control or that are under common control with us.
- Business Partners. We may share your information with our business
partners to offer you certain products, services or promotions.

**4.   WHO WILL YOUR INFORMATION BE SHARED WITH?**

In short: We only share information with the following categories of third parties.
We only share and disclose your information with the following categories of third
parties. If we have processed your data based on your consent and you wish to
revoke your consent, please contact us using the contact details provided in the
section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
- Cloud Computing Services
- Data Analytics Services
- Data Storage Service Providers

**5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?**

in Short: We may use cookies and other tracking technologies to collect and store
your information.
We may use cookies and similar tracking technologies (like web beacons and pixels)
to access or store information. Specific information about how we use such
technologies and how you can refuse certain cookies is set out in our Cookie Notice.

**6. HOW LONG DO WE KEEP YOUR INFORMATION?**

In Short: We keep your information for as long as necessary to fulfill the purposes
outlined in this privacy notice unless otherwise required by law.
We will only keep your personal information for as long as it is necessary for the
purposes gel out in this privacy notice, unless a longer retention period is required or
permitted by law (such as tax, accounting or other legal requirements). No purpose in
this notice will require us keeping your personal information for longer than the period
ef lime in which users have an account with us.
When we have no ongoing legitimate business need to process your personal
information, we will either delete or anonymize such information, or; if this is not
possible (for example, because your personal information has been stored in backup
archives) then we will securely store your personal information and isolate it from
any further processing until deletion is possible

**7. HOW DO WE KEEP YOUR INFORMATION SAFE?**

In short: We aim to protect your personal information through a system of organizational and
technical security measures.
We have implemented appropriate technical and organizational security measures
designed to protect the security of any personal information we process. However,
despite our safeguards and efforts to secure your information, no electronic
transmission over the Internet or information storage technology can be guaranteed
to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
or other unauthorized third parties will not be able to defeat our security, and
improperly collect, access, steal, or modify your information. Although we will do our
best to protect your personal information, transmission of personal information to and
from our Website is at your own risk. You should only access the Website within a
secure environment.

**8. WHAT ARE YOUR PRIVACY RIGHTS?**

In Short: You may review, change, or terminate your account at any time.
If you are a resident in the EA or UK and you believe we are unlawfully processing
your personal information, you also have the right to complain to your local data
protection supervisory authority. You can find their contact details here:
https://ec.europa.eu/justice/data-protection/bodies/authorities/index en.htm.
If you are a resident in Switzerland, the contact details for the data protection
authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
Account Information
If you would at any time like to review or change the information in your account or
terminate your account, you can:
- Contact us using the contact information provided.
- Log in to your account settings and update your user account.
Upon your request to terminate your account, we will deactivate or delete your
account and information from our active databases. However, we may retain some
information in our files to prevent fraud, troubleshoot problems, assist with any
investigations, enforce our Terms of Use and/or comply with applicable legal
requirements.
Cookies and similar technologies: Most Web browsers are set to accept cookies
by default. If you prefer, you can usually choose to set your browser to remove
cookies and to reject cookies. If you choose to remove cookies or reject cookies, this
could affect certain features or services of our Website. To opt-out of interest-based
advertising by advertisers on our Website visit http://www.aboutads.info/choices/.
Opting out of email marketing: You can unsubscribe from our marketing email list
at any time by clicking on the unsubscribe link in the emails that we send or by
contacting us using the details provided below. You will then be removed from the
marketing email list - however, we may still communicate with you, for example to
send you service-related emails that are necessary for the administration and use of
your account, to respond to service requests, or for other non-marketing purposes.
To otherwise opt-out, you may:
- Access your account settings and update your preferences.
- Contact us using the contact information provided.

**9. CONTROLS FOR DO-NOT-TRACK FEATURES**

Most web browsers and some mobile operating systems and mobile applications
include a Do-Not-Track ("DNT") feature or setting you can activate to signal your
privacy preference not to have data about your online browsing activities monitored
and collected. At this stage no uniform technology standard for recognizing and
implementing DNT signals has been finalized. As such, we do not currently respond
to DNT browser signals or any other mechanism that automatically communicates
your choice not to be tracked online. If a standard for online tracking is adopted that
we must follow in the future, we will inform you about that practice in a revised
version of this privacy notice.

**10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC**

PRIVACY RIGHTS?
In Short: Yes, if you are a resident of California, you are granted specific rights
regarding access to your personal information.
California Civil Code Section 1798.83, also known as the "Shine The Light" law,
permits our users who are California residents to request and obtain from us, once a
year and free of charge, information about categories of personal information (if any)
we disclosed to third parties for direct marketing purposes and the names and
addresses of all third parties with which we shared personal information in the
immediately preceding the calendar year. If you are a California resident and would like
to make such a request, please submit your request in writing to us using the contact
information provided below.
If you are under 18 years of age, reside in California, and have a registered account
with the Website, you have the right to request removal of unwanted data that you
publicly post on the Website. To request removal of such data, please contact us
using the contact information provided below, and include the email address
associated with your account and a statement that you reside in California. We will
make sure the data is not publicly displayed on the Website, but please be aware that
the data may not be completely or comprehensively removed from all our systems
(e.g. backups, etc.).

**11. DO WE MAKE UPDATES TO THIS NOTICE?**

In Short: Yes, we will update this notice as necessary to stay compliant with
relevant laws.
We may update this privacy notice from time to time. The updated version will be
indicated by an updated "Revised" date and the updated version will be effective as
soon as it is accessible. If we make material changes to this privacy notice, we may
notify you either by prominently posting a notice of such changes or by directly
sending you a notification. We encourage you to review this privacy notice frequently
to be informed of how we arg protecting your information.

**12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?**

If you have questions or comments about this notice, you may email us at
info@joinstaffi.com or by post to:
Staffii Company L.L.C.
Hawally, Block 4, Al-Baghli Tower, Basement 1 Office 17
Kuwait

**13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE**

DATA WE COLLECT FROM YOU?
Based on the applicable laws of your country, you may have the right to request
access to the personal information we collect from you, change that information, or
delete it in some circumstances. To request to review, update, or delete your
personal information, please visit: www.joinstaffii.com
`;
  return (
    <CompanyStructureModal
      open={user && !user.profile.hasAcceptedTerms}
      onClose={() => {}}
      fadeIn={true}
      title={t("acceptTerms")}
    >
      <Box
        height="auto"
        maxHeight="60vh"
        style={{ overflowY: "auto" }}
        margin={4}
      >
        <ReactMarkdown children={markdown} />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        style={{ margin: 10 }}
      >
        <Button
          onClick={() => updateTerms({ hasAcceptedTerms: true })}
          variant="contained"
          color="primary"
        >
          {t("accept")}
        </Button>
      </Box>
    </CompanyStructureModal>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  updateTerms,
};
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndPrivacy);
