import React, { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import ScheduleItem from "./ScheduleItem";
import { days as availableDays } from "../../../../../../../components/common/DropDownsData";

const ShiftsForm = ({ schedulesState, setSchedulesState }) => {
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    let daysSelectedFromState = [];

    if (schedulesState?.length > 0) {
      daysSelectedFromState = schedulesState
        .map((sched) => sched.days)
        .reduce((prev, current) => [...prev, ...current]);
    }

    if (selectedDays.length === 0) {
      let newState = [];
      for (let i = 0; i < availableDays.length; i++) {
        if (
          daysSelectedFromState.length > 0 &&
          daysSelectedFromState.includes(availableDays[i].value)
        ) {
          newState.push({ ...availableDays[i], selected: true });
        } else {
          newState.push(availableDays[i]);
        }
      }

      setSelectedDays(newState);
    }
  }, [schedulesState, selectedDays.length]);

  return (
    <Box overflow="scroll" height="600px">
      {schedulesState?.map((schedule, idx) => {
        return (
          <ScheduleItem
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            schedule={schedule}
            schedulesState={schedulesState}
            setSchedulesState={setSchedulesState}
            scheduleIdx={idx}
          />
        );
      })}
    </Box>
  );
};

export default ShiftsForm;
