import { yupResolver } from "@hookform/resolvers/yup";
// Materia UI
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as yup from "yup";

// Actions
import {
  createAnonymousEmployeeAttendance,
  resetErrors,
  updateAnonymousEmployeeAttendance,
} from "../../../redux/actions";
// Components
import { FormInputField } from "../../common";
// Hooks
import useGeoLocation from "../../common/useGeoLocation";

const schema = yup.object().shape({
  civilId: yup.string().required(),
  phoneNumber: yup.string().required(),
});

const Form = ({
  createAnonymousEmployeeAttendance,
  updateAnonymousEmployeeAttendance,
  formErrors,
  resetErrors,
}) => {
  const location = useGeoLocation();
  console.log("🚀 ~ file: form.js:34 ~ location:", location);

  const CHECKIN = "CHECKIN";
  const CHECKOUT = "CHECKOUT";
  const [submitType, setSubmitType] = useState(CHECKIN);
  const [success, setSuccess] = useState("");
  const [alert, setAlert] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const isEnglish = language === "en";

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ civilId, phoneNumber }) => {
    const latitude =
      location && location?.location.allowed
        ? `${location?.location.coordinates.latitude}`
        : "";

    const longitude =
      location && location?.location.allowed
        ? `${location?.location.coordinates.longitude}`
        : "";

    resetErrors();
    if (submitType === CHECKIN) {
      createAnonymousEmployeeAttendance(
        { civilId, phoneNumber, latitude, longitude },
        () => setSuccess(t("checkedInSuccesfull"))
      );
    }
    if (submitType === CHECKOUT) {
      updateAnonymousEmployeeAttendance(
        { civilId, phoneNumber, latitude, longitude },
        () => setSuccess(t("checkedOutSuccesfull"))
      );
    }
  };

  useEffect(() => {
    if (!location?.location.loaded || !location?.location.allowed) {
      setAlert(t("grantLocationAccess"));
    } else if (location?.location.loaded && location?.location.allowed) {
      setAlert("");
    }
  }, [location, t]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-test="anonymous-attendance-form"
      >
        <Grid container justifyContent="space-between" spacing={2}>
          {alert.length > 0 && (
            <Grid item md={12} xs={12}>
              <Alert severity="error" onClick={() => setAlert("")}>
                {alert}
              </Alert>
            </Grid>
          )}
          {success && !formErrors && (
            <Grid item md={12} xs={11}>
              <Alert severity="success" onClick={() => setSuccess("")}>
                {success}
              </Alert>
            </Grid>
          )}
          {formErrors && (
            <Grid item md={12} xs={11}>
              <Alert severity="error" onClick={() => resetErrors()}>
                {formErrors}
              </Alert>
            </Grid>
          )}

          <Box clone pb={2}>
            <Grid item md={12}>
              <Typography
                variant="h2"
                color="primary"
                align={isEnglish ? "left" : "right"}
              >
                {t("logAttendance")}
              </Typography>
            </Grid>
          </Box>

          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <FormInputField
                error={!!errors.civilId}
                inputRef={register}
                helperText={errors.civilId?.message}
                label={t("civilId")}
                name="civilId"
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <FormInputField
                error={!!errors.phoneNumber}
                inputRef={register}
                helperText={errors.phoneNumber?.message}
                label={t("phoneNumber")}
                name="phoneNumber"
              />
            </Box>
          </Grid>

          <Grid item md={6} xs={5}>
            <Button
              onClick={() => {
                setSubmitType(CHECKIN);
              }}
              type="submit"
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "green",
              }}
            >
              {t("checkIn")}
            </Button>
          </Grid>
          <Grid item container justifyContent="flex-end" md={6} xs={5}>
            <Button
              onClick={() => {
                setSubmitType(CHECKOUT);
              }}
              type="submit"
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "maroon",
              }}
            >
              {t("checkOut")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = ({ errors }) => ({
  formErrors: errors,
});

const mapDispatchToProps = {
  createAnonymousEmployeeAttendance,
  updateAnonymousEmployeeAttendance,
  resetErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
