import Layout from "./Layout/index";
import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core";

const Dashboard = (props) => {
  const { user, employeeProfile } = props;

  const theme = useTheme();

  if (!user) return <Redirect to="/login" />;

  return (
    <Layout
      user={user}
      employeeProfile={employeeProfile}
      navColor={theme.palette.background.default}
      {...props}
    />
  );
};

const mapStateToProps = ({ company, requests }) => ({
  company: company.profile,
  requests: requests.requests,
});

export default connect(mapStateToProps)(Dashboard);
