import { ArrowBackIos, ArrowForwardIos, DateRange } from "@material-ui/icons";
//Material UI
import { Box, FormHelperText } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Date
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DatePickerField = ({
  openTo,
  label,
  yearMonthView,
  name,
  onChange,
  onBlur = () => {},
  errors,
  disableFuture,
  isRequired,
  disableToolbar = true,
  value = "",
  helperText = "",
  disabled = false,
  min = new Date("1/1/1950"),
  max,
  fullWidth = true,
  ...dateProps
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const useStyles = makeStyles(
    (theme) => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      input: {
        color: theme.palette.primary.main,

        "& .MuiFormLabel-root": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid #002A6E",
        },
        "& .MuiInput-underline.Mui-disabled:before": {
          borderBottomStyle: "solid",
        },
      },
      formControlRoot: {
        justifyContent: "flex-start",
        padding: 4,
        "& .MuiButtonBase-root MuiIconButton-root": {
          color: theme.palette.common.staffiBlue,
        },

        "& .MuiFormLabel-root": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 500,
        },
        "& .MuiInputLabel-formControl": {
          color: theme.palette.primary.main,
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
        "& .MuiInputLabel-shrink": {
          transformOrigin: isEnglish ? "top left" : "top right",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid #002A6E",
        },
        "& .MuiInput-underline.Mui-disabled:before": {
          borderBottomStyle: "solid",
        },
        "& .Mui-disabled": {
          color: "black",
        },
        "& .MuiFormHelperText-root": {
          textAlign: "right",
        },
      },
      calendar: {
        "& .MuiIconButton-root.Mui-disabled": {
          color: "#002A6E",
        },
      },
    }),
    { index: 1 }
  );

  const classes = useStyles();
  const theme = useTheme();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="en">
      <Box clone margin={0} className={classes.formControlRoot}>
        <KeyboardDatePicker
          minDate={min}
          maxDate={max}
          openTo={openTo}
          autoOk={true}
          views={yearMonthView ? ["year", "month"] : ["year", "month", "date"]}
          rightArrowIcon={
            <ArrowForwardIos style={{ color: theme.palette.primary.main }} />
          }
          leftArrowIcon={
            <ArrowBackIos style={{ color: theme.palette.primary.main }} />
          }
          disableFuture={disableFuture}
          animateYearScrolling={true}
          fullWidth={fullWidth}
          disabled={disabled}
          helperText={""}
          error={!!errors}
          disableToolbar={disableToolbar}
          InputProps={{ className: classes.input }}
          variant="inline"
          format="DD-MM-yyyy"
          required={isRequired}
          label={label}
          value={value}
          name={name}
          onChange={(e) => {
            onChange(e);
            onBlur(e);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          keyboardIcon={<DateRange color="primary" />}
          {...dateProps}
        />
      </Box>

      <Box margin={0} className={classes.formControlRoot}>
        <FormHelperText>{helperText}</FormHelperText>
        <FormHelperText children={errors} error={!!errors} />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
