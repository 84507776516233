import AttendanceSystem from "./AttendanceSystem";
import AutoDeduct from "./AutoDeduct";
import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const AttendanceSettings = ({ edit }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Typography variant="h2" color="textPrimary">
        {t("attendanceSettings")}
      </Typography>
      <AttendanceSystem edit={edit} />
      <AutoDeduct edit={edit} />
    </>
  );
};

export default AttendanceSettings;
