export const filterEmployeesByQuery = (employees, query) => {
  let filteredEmployees = employees.filter((employee) => {
    const name = employee.user
      ? employee?.user?.fullName
      : employee?.fullName
      ? employee?.fullName
      : "-";

    return (
      name?.toLowerCase().includes(query.toLowerCase()) ||
      employee.id.includes(query) ||
      employee?.referenceNumber?.includes(query)
    );
  });

  return filteredEmployees;
};
