//Material UI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import {
  LoadingSpinnerComponent,
  PageHeader,
  TablePagination,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
//Actions
import { fetchDocuments } from "../../../redux/actions";
import { fetchEmployeeDocuments } from "../../../redux/actions/company";
import { CustomTableCell, CustomTableRow, SearchInput } from "../../common";
import AutocompleteInput from "../../common/AutocompleteInput";
//Components
import DocumentItem from "./DocumentItem";
//Data & Utils
import { filterDocuments } from "./utils";

const Documents = ({
  allEmployeeDocuments,
  employeeDocuments,
  employeeDocumentsLoading,
  allEmployeeDocumentsLoading,
  documentsLoading,
  departments,
  positions,
  documentTypes,
  user,
}) => {
  ReactGA.pageview(window.location.pathname);
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation(["common"]);
  const isEnglish = language === "en";
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id),
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ]),
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);

  const [documentType, setDocumentType] = useState([]);
  const [query, setQuery] = useState("");

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "referenceNumber":
        if (b.referenceNumber < a.referenceNumber) {
          return -1;
        }
        if (b.id > a.id) {
          return 1;
        }
        return 0;
      case "documentName":
        if (b.name < a.name) {
          return -1;
        }
        if (b.name > a.name) {
          return 1;
        }
        return 0;
      case "employeeName":
        const nameB = b.employee.user
          ? b.employee.user?.fullName
          : b.employee?.fullName;
        const nameA = a.employee.user
          ? a.employee.user?.fullName
          : a.employee?.fullName;
        if (nameB < nameA) {
          return -1;
        }
        if (nameB > nameA) {
          return 1;
        }
        return 0;
      case "department":
        if (
          b.employee.department.name < a.employee.department.name ||
          b.employee.department.nameAr < a.employee.department.nameAr
        ) {
          return -1;
        }
        if (
          b.employee.department.name > a.employee.department.name ||
          b.employee.department.nameAr > a.employee.department.nameAr
        ) {
          return 1;
        }
        return 0;
      case "position":
        if (
          b.employee.position.name < a.employee.position.name ||
          b.employee.position.nameAr < a.employee.position.nameAr
        ) {
          return -1;
        }
        if (
          b.employee.position.name > a.employee.position.name ||
          b.employee.position.nameAr > a.employee.position.nameAr
        ) {
          return 1;
        }
        return 0;

      case "expirationDate":
        if (new Date(b.exp) < new Date(a.exp)) {
          return -1;
        }
        if (new Date(b.exp) > new Date(a.exp)) {
          return 1;
        }
        return 0;

      case "documentType":
        if (b.type.name < a.type.name) {
          return -1;
        }
        if (b.type.name > a.type.name) {
          return 1;
        }
        return 0;

      default:
        return orderBy;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "referenceNumber", label: t("common:documentReferenceNumber") },
    { id: "documentName", label: t("common:documentName") },
    { id: "employeeName", label: t("common:employeeName") },
    { id: "department", label: t("common:department") },
    { id: "position", label: t("common:position") },
    { id: "expirationDate", label: t("common:expirationDate") },
    { id: "documentType", label: t("common:documentType") },
  ];

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,

      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      setOrderBy(property);
      onRequestSort(event, property);
    };

    const useStyles = makeStyles(() => ({
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }));

    const classes = useStyles();

    return (
      <TableHead>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <CustomTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let documents = user.isCompanyOwner
    ? allEmployeeDocuments
    : employeeDocuments;

  const documentsList = filterDocuments(
    documents,
    department,
    position,
    documentType,
    query,
  );

  const listOfDocuments = stableSort(
    documentsList,
    getComparator(order, orderBy),
  )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((document) => (
      <DocumentItem
        isEnglish={isEnglish}
        document={document}
        key={`${document.id}-${document.referenceNumber}`}
      />
    ));

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.documents.name}`),
      isLast: true,
    },
  ];

  useEffect(() => {
    dispatch(fetchEmployeeDocuments());
  }, [dispatch]);
  return (
    <Grid container>
      <Grid item md={11} xs={12}>
        <Grid
          container
          justifyContent={matchesMD ? "center" : "flex-start"}
          spacing={4}
        >
          <Grid item md={12} xs={9}>
            <PageHeader
              paths={navigationPaths}
              title={t(`breadcrumbs:${paths.documents.header}`)}
            />
          </Grid>
          <Grid item md={12} xs={9}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <AutocompleteInput
                  value={department}
                  options={departments.map((dep) => ({
                    label: isEnglish ? dep.name : dep.nameAr,
                    value: dep.id,
                  }))}
                  multiple={true}
                  onChange={(e) => setDepartment(e)}
                  label={t("common:department")}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <AutocompleteInput
                  value={position}
                  options={_positions().map((pos) => ({
                    label: isEnglish ? pos.name : pos.nameAr,
                    value: pos.id,
                  }))}
                  multiple={true}
                  onChange={(e) => setPosition(e)}
                  label={t("common:position")}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <AutocompleteInput
                  value={documentType}
                  options={documentTypes.map((type) => ({
                    label: isEnglish ? type.name : type.nameAr,
                    value: type.id,
                  }))}
                  multiple={true}
                  onChange={(e) => setDocumentType(e)}
                  label={t("common:documentType")}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <SearchInput
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={t("common:search")}
                  position="start"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={10}>
            <Box clone bgcolor="white" borderRadius={15}>
              <TableContainer>
                {employeeDocumentsLoading ||
                (user.isCompanyOwner && allEmployeeDocumentsLoading) ? (
                  <LoadingSpinnerComponent />
                ) : (
                  <Table>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>{listOfDocuments}</TableBody>
                  </Table>
                )}
              </TableContainer>
            </Box>

            <TablePagination
              count={documents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  allEmployeeDocuments: company.allEmployeeDocuments,
  employeeDocuments: company.employeeDocuments,
  employeeDocumentsLoading: company.employeeDocumentsLoading,
  allEmployeeDocumentsLoading: company.allEmployeeDocumentsLoading,
  departments: company.departments,
  positions: company.positions,
  documentTypes: company.documentTypes,
  user: auth.user,
});

const mapDispatchToProps = {
  fetchDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
