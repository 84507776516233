import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../common";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createOvertimeRequest } from "../../../../../redux/actions";

import moment from "moment";

const OvertimeRequestModal = ({ open, setOpen }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [overtimeRequest, setOvertimeRequest] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
    amount: "",
  });
  const [error, setError] = useState("");

  return (
    <CompanyStructureModal
      open={open}
      onClose={() => setOpen(false)}
      fadeIn={open}
      title={t("overtimeRequest")}
      height="300px"
    >
      <Grid container justifyContent="center">
        <Grid item container spacing={3} style={{ margin: 4 }}>
          {error && (
            <Grid item md={8} xs={12}>
              <Alert onClick={() => setError("")} severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item md={6} xs={6}>
            <DatePickerField
              isRequired
              label={t("startDate")}
              name="startDate"
              onChange={(e) =>
                setOvertimeRequest({
                  ...overtimeRequest,
                  date: moment(e).format("yyyy-MM-DD"),
                })
              }
              value={overtimeRequest.date}
              disableToolbar={false}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormInputField
              isRequired
              label={t("amount")}
              name="amount"
              type="number"
              onChange={(e) => {
                setOvertimeRequest({
                  ...overtimeRequest,
                  amount: e.target.value,
                });
              }}
              value={overtimeRequest.amount}
              id="amount"
              helperText={t("maximumOvertime")}
            />
          </Grid>
        </Grid>
        {/* <Grid item md={6} xs={6}>
            <TimePickerField
              isRequired
              label={t('startTime')}
              name="startTime"
              onChange={e =>
                setOvertimeRequest({
                  ...overtimeRequest,
                  startTime: moment(e),
                })
              }
              value={overtimeRequest.startTime}
            />
          </Grid> */}

        <Grid item md={12} xs={12} style={{ margin: 10 }}>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(
                    createOvertimeRequest(overtimeRequest, () =>
                      setOpen(false),
                    ),
                  );
                }}
              >
                <Typography>{t("confirm")}</Typography>
              </Button>
            </Box>
            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                <Typography>{t("cancel")}</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};
export default OvertimeRequestModal;
