import { Box, CircularProgress, Grid } from "@material-ui/core";

import CompanyDocumentsButtons from "./Buttons/CompanyDocumentsButtons";
import { CompanyStructureModal } from "../../../../../components/common";
import EmployeeProfileDocumentsButtons from "./Buttons/EmployeeProfileDocumentsButtons";
import Form from "./Form";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

const DocumentModal = ({
  forCompanyPage,
  open,
  setOpen,
  documentState,
  setDocumentState,
  addDocumentLoading = false,
}) => {
  const { t } = useTranslation("common");
  const title = documentState ? t("editDocument") : t("addDocument");

  const [modifiedDocument, setModifiedDocument] = useState(null);
  const [modifiedFiles, setModifiedFiles] = useState(null);

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (!documentState && forCompanyPage) {
      setModifiedDocument({
        name: "",
        exp: null,
        location: "",
      });
      setModifiedFiles([]);
    } else if (documentState && forCompanyPage) {
      const { files, ...rest } = documentState;
      setModifiedDocument({ ...rest });
      setModifiedFiles(files);
    } else if (documentState) {
      const { files, ...rest } = documentState;
      setModifiedDocument({ ...rest });
      setModifiedFiles(files);
    } else {
      setModifiedDocument({
        name: "",
        referenceNumber: "",
        issueDate: null,
        exp: null,
        type: {
          id: "",
        },
      });
      setModifiedFiles([]);
    }
  }, [documentState, forCompanyPage]);

  const handleCloseModal = () => {
    if (documentState !== null) {
      setOpen(false);
      setDocumentState(null);
    } else {
      setOpen(false);
      if (forCompanyPage) {
        setModifiedDocument({
          name: "",
          exp: null,
          location: "",
        });
      } else {
        setModifiedDocument({
          name: "",
          referenceNumber: "",
          issueDate: null,
          exp: null,
          type: {
            id: "",
          },
        });
      }

      setModifiedFiles([]);
    }
  };

  const AcionButtons = forCompanyPage ? (
    <CompanyDocumentsButtons
      documentState={documentState}
      setDocumentState={setDocumentState}
      setOpen={setOpen}
      modifiedDocument={modifiedDocument}
      setModifiedDocument={setModifiedDocument}
      modifiedFiles={modifiedFiles}
      setModifiedFiles={setModifiedFiles}
      setAlert={setAlert}
    />
  ) : (
    <EmployeeProfileDocumentsButtons
      documentState={documentState}
      setDocumentState={setDocumentState}
      setOpen={setOpen}
      modifiedDocument={modifiedDocument}
      setModifiedDocument={setModifiedDocument}
      modifiedFiles={modifiedFiles}
      setModifiedFiles={setModifiedFiles}
    />
  );

  return (
    <CompanyStructureModal
      open={open}
      onClose={handleCloseModal}
      fadeIn={open}
      title={title}
    >
      <Box margin={4}>
        {addDocumentLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Box
              height="auto"
              minHeight={"40vh"}
              maxHeight={"60vh"}
              max
              style={{ overflowY: "auto" }}
            >
              <Grid>
                {alert && (
                  <Alert severity="error">
                    Please Set Documnet Expiry date !
                  </Alert>
                )}
              </Grid>
              <Grid item container xs={12} lg={12} spacing={4}>
                <Form
                  modifiedDocument={modifiedDocument}
                  setModifiedDocument={setModifiedDocument}
                  modifiedFiles={modifiedFiles}
                  setModifiedFiles={setModifiedFiles}
                  edit={documentState}
                  forCompanyPage={forCompanyPage}
                />
              </Grid>
              {AcionButtons}
            </Box>
          </Grid>
        )}
      </Box>
    </CompanyStructureModal>
  );
};

export default DocumentModal;
