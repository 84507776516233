import { Grid, TableCell, styled } from "@material-ui/core";
import React, { useEffect } from "react";

import CompanyDocuments from "./CompanyDocuments";
import EmployeeDocuments from "./EmployeeDocuments";
import EmployeesOnVacation from "./EmployeesOnVacation";
import OvertimeRequests from "./OvertimeRequests";
import TrialEmployees from "./TrialEmployees";
import ExpiringContracts from "./ExpiringContracts";
import { PageHeader } from "../../../redesign/components/common";
import Profile from "./Profile";
import ReactGA from "react-ga";
import { Redirect } from "react-router";
import UpcomingEmployeeVacations from "./UpcomingEmployeeVacations";
import VacationRequests from "./VacationRequests";
import { connect } from "react-redux";
//Redux
import {
  fetchRequests,
  fetchEmployeesLeaveBalance,
} from "../../../redux/actions";
import { paths } from "../../../redesign/utils/index";
import { useTranslation } from "react-i18next";

const Home = ({
  user,
  requests,
  fetchRequests,
  company,
  vacations,
  fetchEmployeesLeaveBalance,
}) => {
  const { t } = useTranslation("breadcrumbs");
  ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    fetchRequests();
    fetchEmployeesLeaveBalance();
  }, [fetchRequests, fetchEmployeesLeaveBalance]);
  if (!user) return <Redirect to="/login" />;
  return (
    <>
      <Grid item container justifyContent="center" md={12}>
        <Grid item lg={12} md={10} xs={9}>
          <PageHeader title={t(paths.dashboard.header)} paddingBottom={2} />
        </Grid>
        <VacationRequests requests={requests} />
        {company?.overtimeEnabled && <OvertimeRequests requests={requests} />}

        <Profile requests={requests} />
        <EmployeeDocuments />
        <CompanyDocuments />
        <EmployeesOnVacation requests={requests} employees={vacations} />
        <UpcomingEmployeeVacations requests={requests} employees={vacations} />
        <TrialEmployees />
        <ExpiringContracts />
        {/* redpoly */}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ auth, requests, company, employees }) => ({
  company: company.profile,
  user: auth.user,
  requests: requests.requests,
  vacations: employees.employeesLeaveBalance,
});

const mapDispatchToProps = {
  fetchRequests,
  fetchEmployeesLeaveBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

export const RequestTableCell = styled(TableCell)(() => ({
  backgroundColor: "white",
  borderBottom: "none",
  textDecoration: "none",
  textTransform: "none",
  paddingLeft: 0,
  whiteSpace: "nowrap",
}));
