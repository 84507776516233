import {
  AccountCircleRounded,
  Add,
  AddAlarm,
  AnnouncementOutlined,
  Assignment,
  AttachMoney,
  Attachment,
  BeachAccess,
  Business,
  BusinessCenter,
  CloudUploadRounded,
  DateRangeTwoTone,
  Description,
  HomeRounded,
  ListAlt,
  LocalAtm,
  LocationOn,
  MonetizationOnRounded,
  People,
  Schedule,
  Settings,
  SupervisorAccount,
} from "@material-ui/icons";

const employerRoutes = [
  {
    heading: "general",
    items: [
      {
        name: "dashboard",
        icon: HomeRounded,
        link: "/",
      },
      {
        name: "employees",
        icon: People,
        link: "/employeesList",
        items: [
          {
            name: "payroll",
            icon: AttachMoney,
            link: "/payroll",
          },
          {
            name: "addEmployee",
            icon: Add,
            link: "/add/employee",
          },
          {
            name: "depositSalary",
            icon: LocalAtm,
            link: "/deposits",
          },
          {
            name: "hypotheticalIndemnities",
            icon: AttachMoney,
            link: "/hypotheticalIndemnities",
          },
        ],
      },
      {
        name: "company",
        icon: BusinessCenter,

        // link: "/company",

        items: [
          {
            name: "file",
            icon: Business,
            link: "/company/file",
          },
          {
            name: "locationsAndDepartments",
            icon: LocationOn,
            link: "/company/locationsAndDepartments",
          },
          {
            name: "documents",
            icon: Description,
            link: "/company/documents",
          },
          {
            name: "companySettings",
            icon: Settings,
            link: "/company/settings",
          },

          {
            name: "officialHolidays",
            icon: BeachAccess,
            link: "/company/holidays",
          },
          {
            name: "resources",
            icon: Attachment,
            link: "/company/resources",
          },
          {
            name: "userAccessAndPermissions",
            icon: SupervisorAccount,
            link: "/company/userAccessAndPermissions",
          },
        ],
      },
      {
        name: "Attendance",
        icon: Schedule,
        link: "/attendance",
      },
      {
        name: "Requests",
        icon: Assignment,
        link: "/requests",
        // badge: "",
      },
      {
        name: "Documents",
        icon: Description,
        link: "/documents",
      },
      // {
      //   name: "Company Profile",
      //   icon: Business,
      //   link: "/company/profile",
      // },
      {
        name: "Announcements",
        icon: AnnouncementOutlined,
        link: "/announcements",
      },
      {
        name: "upload",
        icon: CloudUploadRounded,
        link: "/upload",
      },
    ],
  },
];

const employeeRoutes = [
  {
    heading: "myProfile",
    items: [
      {
        name: "home",
        icon: HomeRounded,
        link: "/",
      },
      {
        name: "vacations",
        icon: DateRangeTwoTone,
        link: "/employee/vacation",
      },
      {
        name: "attendance",
        icon: Schedule,
        link: "/employee/attendance",
      },
      {
        name: "salary",
        icon: MonetizationOnRounded,
        link: "/employee/salary",
      },
      {
        name: "profile",
        icon: AccountCircleRounded,
        link: "/employee/profile",
      },
      {
        name: "employeeRequests",
        icon: Assignment,
        link: "/employee/employeeRequests",
      },
    ],
  },
];

export const overtimeRoute = {
  name: "overtime",
  icon: AddAlarm,
  link: "/employee/overtime",
};

export const employeessRoute = {
  name: "employees",
  icon: People,
  link: "/employeesList",
};

export const documentsRoute = {
  name: "documents",
  icon: Description,
  link: "/documents",
};

export const attendanceRoute = {
  name: "attendances",
  icon: ListAlt,
  link: "/attendance",
};

export const requestsRoute = {
  name: "requests",
  icon: Assignment,
  link: "/requests",
};

const getMenuItems = (user) => {
  if (user.isCompanyOwner) return employerRoutes;

  return employeeRoutes;
};

export default getMenuItems;
