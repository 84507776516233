export const filterEmployeesByStatus = (employees, selectedStatus) => {
  let filteredEmployees = employees.filter((employee) => {
    if (selectedStatus?.length === 0) {
      return employee;
    }

    return selectedStatus.some(
      (stat) => stat.value === employee.employmentStatus
    );
  });

  return filteredEmployees;
};
