//Material UI
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
//Icons
import { Send } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  createDeposit,
  fetchEmployeeNextDeposit,
} from "../../../redux/actions";
//Components
import { DatePickerField } from "../../common";
import SalaryModification from "./SalaryModification";
//Styles
import useStyles from "./styles";
import { getTotalToBeDeposited } from "./utils";

const DepositForm = ({
  employeeAllowances,
  selected,
  total,
  closeModal,
  deposited,
  employeeProfileDeposit,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const dispatch = useDispatch();
  const isEnglish = language === "en";
  const classes = useStyles();
  const [date, setDate] = useState(new Date());
  const [selectedAllowances, setSelectedAllowances] = useState([]);
  const [nextDeposit, setNextDeposit] = useState([]);
  const [nextDepositSalary, setNextDepositSalary] = useState(null);
  const uniqueDeposit = selected.length === 1;

  let earnings = [];
  let deductions = [];
  let allowances = [];
  let overtimeCredits = [];

  if (uniqueDeposit) {
    if (nextDeposit.length !== 0) {
      earnings = nextDeposit.salaryModifications.filter(
        (allowance) => Number(allowance.amount) > 0,
      );
      deductions = nextDeposit.salaryModifications.filter(
        (allowance) => Number(allowance.amount) < 0,
      );
      allowances = nextDeposit.recurringSalaryModifications;
      overtimeCredits = nextDeposit.overtimeCredits;
    } else {
      earnings = selected[0].nextSalaryDeposit.salaryModifications.filter(
        (allowance) => Number(allowance.amount) > 0,
      );
      deductions = selected[0].nextSalaryDeposit.salaryModifications.filter(
        (allowance) => Number(allowance.amount) < 0,
      );
      allowances = selected[0].nextSalaryDeposit.recurringSalaryModifications;
    }
  }
  console.log("DepositForm -> selected", selected[0]);
  const normalizeAllowances = () => {
    return selectedAllowances.map((allowance) => {
      return allowance.id;
    });
  };
  useEffect(() => {
    if (uniqueDeposit) {
      dispatch(
        fetchEmployeeNextDeposit({
          employeeId: +selected[0].id,
          month: moment(date).format("YYYY-MM-DD"),
        }),
      ).then((data) => {
        if (data) {
          setNextDeposit(data);
          setNextDepositSalary(data.netSalary);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selected, date]);

  const depositNow = () => {
    if (uniqueDeposit) {
      dispatch(
        createDeposit(
          {
            employeeIds: selected.map((employee) => employee.id),
            month: employeeProfileDeposit
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(date).format("YYYY-MM-DD"),
            recurringSalaryModifications: normalizeAllowances(),
          },
          () => {
            closeModal();
          },
        ),
      ).then((deposit) => {
        if (deposit?.hadDeposit.length > 0) {
          deposited("ALREADY_EXISTED");
        } else {
          deposited();
        }
      });
    } else {
      dispatch(
        createDeposit(
          {
            employeeIds: selected.map((employee) => employee.id),
            month: employeeProfileDeposit
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(date).format("YYYY-MM-DD"),
          },
          () => {
            closeModal();
            deposited();
          },
        ),
      );
    }
  };

  let totalToBeDeposited = getTotalToBeDeposited(
    uniqueDeposit ? +nextDepositSalary : total,
    selectedAllowances,
    // earnings,
    // deductions,
  );

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        spacing={6}
      >
        <Grid item md={7} sm={7}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Box
              height="auto"
              maxHeight="60vh"
              paddingY={3}
              paddingX={3}
              dir={isEnglish ? "ltr" : "rtl"}
              style={{ overflowY: "auto" }}
            >
              {!employeeProfileDeposit && uniqueDeposit && (
                <Grid item md={12}>
                  <DatePickerField
                    name="Month to deposit"
                    max={
                      selected[0].leaveDate
                        ? new Date(selected[0].leaveDate)
                        : null
                    }
                    onChange={(e) => setDate(new Date(e))}
                    value={date}
                    label={t("common:forMonth")}
                    disableToolbar={false}
                  />
                </Grid>
              )}
              {!uniqueDeposit && (
                <Typography
                  align={isEnglish ? "left" : "right"}
                  variant="h2"
                  style={{ marginTop: 10 }}
                >
                  <span style={{ color: "primary" }}>
                    {t("common:forMonth")}:
                  </span>{" "}
                  <span style={{ color: "secondary" }}>
                    {moment(date).format("MMMM YYYY")}
                  </span>
                </Typography>
              )}
              <Grid item md={12}>
                {uniqueDeposit && (
                  <Typography
                    align={isEnglish ? "left" : "right"}
                    variant="h2"
                    style={{ marginTop: 10 }}
                  >
                    {`${t("common:baseSalary")}: ${selected[0].baseSalary} ${t(
                      "common:currency",
                    )}`}
                  </Typography>
                )}
                <Typography
                  align={isEnglish ? "left" : "right"}
                  variant="h2"
                  style={{ marginTop: 10 }}
                >
                  {uniqueDeposit
                    ? `${t("common:netSalary")}: `
                    : `${t("common:total")}: `}
                  {`${
                    uniqueDeposit
                      ? Number(nextDepositSalary).toFixed(3)
                      : total.toFixed(3)
                  } ${t("common:currency")}`}
                </Typography>
                <FormHelperText
                  style={{
                    textAlign: isEnglish ? "left" : "right",
                    marginTop: 10,
                  }}
                >
                  {t("common:depositNote")}
                </FormHelperText>
              </Grid>

              {uniqueDeposit && (
                <>
                  <SalaryModification
                    sectionTitle={`${t("common:earnings")}:`}
                    modifications={earnings}
                  />
                  <SalaryModification
                    sectionTitle={`${t("common:overtimeCredits")}:`}
                    modifications={overtimeCredits}
                  />
                  <SalaryModification
                    sectionTitle={`${t("common:deductions")}:`}
                    modifications={deductions}
                  />

                  <SalaryModification
                    sectionTitle={`${t("common:allowancesToInclude")}:`}
                    allowances={allowances}
                    selectedAllowances={selectedAllowances}
                    setSelectedAllowances={setSelectedAllowances}
                  />
                </>
              )}
              {uniqueDeposit && (
                <Grid item md={12}>
                  <Typography
                    align={isEnglish ? "left" : "right"}
                    variant="h2"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {`${t("common:totalSalaryEarned")}: ${Number(
                      totalToBeDeposited,
                    ).toFixed(2)} ${t("common:currency")}`}
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          style={{ marginBottom: 4 }}
        >
          <Grid item>
            <Button
              onClick={closeModal}
              variant="contained"
              className={classes.cancelButton}
            >
              {t("common:cancel")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={() => depositNow()}
              variant="contained"
              className={classes.sendButton}
              endIcon={<Send />}
            >
              {t("common:proceed")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DepositForm;
