import {
  Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

const DepartmentAccordion = ({
  title,
  children,
  departmentId,
  secondaryAction,
  employeeAvatars,
  description,
  hasPositions,
}) => {
  const [expandAccordion, setExpandAccordion] = useState(false);
  const handleExpand = () => {
    setExpandAccordion(!expandAccordion);
  };
  return (
    <Accordion
      expanded={expandAccordion}
      TransitionProps={{ timeout: 0, unmountOnExit: true }}
      elevation={0}
    >
      <AccordionSummary>
        <Grid container alignItems="space-between">
          <Grid item lg={1} xs={1}>
            {expandAccordion ? (
              <ExpandLess color="primary" onClick={handleExpand} />
            ) : (
              <ExpandMore
                color={hasPositions ? "primary" : "secondary"}
                onClick={hasPositions ? handleExpand : () => {}}
              />
            )}
          </Grid>
          <Grid item lg={4} xs={4}>
            {title}
          </Grid>

          <Grid item lg={5} xs={5}>
            <Typography
              component={"a"}
              target="_blank"
              rel="noreferrer"
              href={`/employeesList?department=${departmentId}&location=&status=&order=null&orderBy=null&position`}
              variant="subtitle2"
              color="primary"
            >
              {description}
            </Typography>
          </Grid>
          <Grid item container direction="row-reverse" lg={2} xs={2}>
            {secondaryAction}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default DepartmentAccordion;

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "none",
      transition: "none",
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: "#F6F8FA",
    display: "block",
  },
})(MuiAccordionDetails);
