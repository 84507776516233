import React from "react";
import { connect } from "react-redux";

//Components
import { CustomTableCell, CustomTableRow } from "../../../common";

//Material UI
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
} from "@material-ui/core";

import { useTheme } from "@material-ui/styles";

//Translation
import { useTranslation } from "react-i18next";
import Resource from "./Resource";

const Resources = ({ resources }) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation(["common", "companyProfile"]);

  const resourcesList = resources.map((resource) => (
    <Resource resource={resource} key={resource.id} />
  ));

  return (
    <Grid item xs={12}>
      <Box
        clone
        minHeight={"400px"}
        boxShadow="none"
        borderRadius={10}
        borderwidth={1}
      >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <Box clone bgcolor="#F6F8FA">
              <TableHead>
                <CustomTableRow>
                  <CustomTableCell colSpan={matchesMD ? 4 : 11}>
                    <Typography color="primary" variant="h5">
                      {t("companyProfile:resources")}
                    </Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </TableHead>
            </Box>
            <TableBody>{resourcesList}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ company }) => ({
  resources: company.resources,
});

export default connect(mapStateToProps)(Resources);
