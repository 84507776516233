import { Box, Card, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Notifications = ({ announcements }) => {
  ReactGA.pageview(window.location.pathname);
  const { i18n: language } = useTranslation();
  const isEnglish = language === "en";
  return (
    <Box clone paddingY={6} maxHeight={"100%"}>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item md={10} xs={10}>
          <Typography variant="h2" color="primary">
            Notifications
          </Typography>
        </Grid>
        {announcements.map((announcement) => (
          <Grid item md={10} xs={10}>
            <Box
              key={announcement.announcement.id}
              display="flex"
              flexDirection="row"
              alignItems="center"
              clone
              height={"70px"}
              borderRadius={10}
              padding={3}
            >
              <Card component={Link} to={`/notification/${announcement.id}`}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item md={4} xs={3}>
                    <Typography
                      variant="h3"
                      color={announcement.seen ? "primary" : "secondary"}
                    >
                      {isEnglish
                        ? announcement.announcement.title
                        : announcement.announcement.titleAr}
                    </Typography>
                  </Grid>

                  <Grid item md={4} xs={3}>
                    <Typography color="primary">
                      {moment(
                        new Date(announcement.announcement.postDate)
                      ).format("DD-MM-yyyy hh:mm:a")}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ employee }) => ({
  announcements: employee.profile?.announcements || [],
});

export default connect(mapStateToProps)(Notifications);
