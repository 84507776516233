import { Box, Grid, useMediaQuery } from "@material-ui/core";
//Components
import {
  DatePickerField,
  FormFileField,
  FormInputField,
  Section,
} from "../../../common";

import React from "react";
import { connect } from "react-redux";
//Date
import moment from "moment";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const IdentificationDocuments = ({
  state,
  setState,
  civilIdDocumentType,
  passportDocumentType,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("employeeAdd");

  const isEnglish = language === "en";

  console.log("_______CIVIL ID DOCUMENT TYPE_______", civilIdDocumentType);
  console.log("_______PASSPORT DOCUMENT TYPE_______", passportDocumentType);

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const paddingY = matchesMD ? 0 : 3;

  const { civilId, passport, files } = state;

  const handleChange = (key) => (name) => (eventOrValue) => {
    const value =
      eventOrValue?.target?.value ?? moment(eventOrValue).format("yyyy-MM-DD");
    setState((state) => ({
      ...state,
      [key]: { ...state[key], [name]: value },
    }));
  };

  const handleCivilIdChange = handleChange("civilId");
  const handlePassportChange = handleChange("passport");

  const handleFileChange =
    (name) =>
    ({ target: { files } }) =>
      setState((state) => ({
        ...state,
        files: { ...state.files, [name]: files && files[0] },
      }));

  const deleteFile = (name) => () =>
    setState((state) => ({
      ...state,
      files: { ...state.files, [name]: null },
    }));

  return (
    <Section bgcolor="white">
      <Grid container spacing={3}>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("civilId")}
              name="civilIdReferenceNumber"
              onChange={handleCivilIdChange("referenceNumber")}
              value={civilId.referenceNumber}
              id="civilIdReferenceNumber"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("passportNumber")}
              name="passportReferenceNumber"
              onChange={handlePassportChange("referenceNumber")}
              value={passport.referenceNumber}
              id="passportReferenceNumber"
              autoComplete="new-password"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("documentType")}
              name="civilIdDocumentType"
              disabled
              value={
                isEnglish
                  ? civilIdDocumentType?.name
                  : civilIdDocumentType?.nameAr
              }
              id="civilIdDocumentType"
              autoComplete="civilIdDocumentType"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <FormInputField
              label={t("documentType")}
              name="passportIdDocumentType"
              disabled
              value={
                isEnglish
                  ? passportDocumentType?.name
                  : passportDocumentType?.nameAr
              }
              id="passportDocumentType"
              autoComplete="passportDocumentType"
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              disableFuture={true}
              label={t("civilIdIssueDate")}
              name="civilIdIssueDate"
              onChange={handleCivilIdChange("issueDate")}
              value={civilId.issueDate}
              disableToolbar={false}
            />
          </Grid>
        </Box>

        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              disableFuture={true}
              label={t("passportIssueDate")}
              name="passportIssueDate"
              onChange={handlePassportChange("issueDate")}
              value={passport.issueDate}
              disableToolbar={false}
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              label={t("civilIdExpirationDate")}
              name="civilIdExp"
              onChange={handleCivilIdChange("exp")}
              value={civilId.exp}
              disableToolbar={false}
            />
          </Grid>
        </Box>
        <Box clone paddingY={paddingY}>
          <Grid item md={6} xs={12}>
            <DatePickerField
              label={t("passportExpirationDate")}
              name="passportExpirationDate"
              onChange={handlePassportChange("exp")}
              value={passport.exp}
              disableToolbar={false}
            />
          </Grid>
        </Box>
        <Grid
          container
          spacing={8}
          justifyContent={matchesMD ? "center" : undefined}
          alignItems={matchesMD ? "center" : undefined}
        >
          <Box clone paddingY={paddingY}>
            <Grid item md={6} xs={12}>
              <FormFileField
                fileId="civil-id-file"
                placeholder={t("civilIdPhoto")}
                label={t("civilIdPhoto")}
                name="civilIdFile"
                file={files.civilId}
                onChange={handleFileChange("civilId")}
                deleteFile={deleteFile("civilId")}
                style={{ display: "none" }}
                download={false}
              />
            </Grid>
          </Box>
          <Box clone paddingY={paddingY}>
            <Grid item md={6} xs={12}>
              <FormFileField
                fileId="passport-file"
                placeholder={t("passportPhoto")}
                label={t("passportPhoto")}
                name="passportFile"
                file={files.passport}
                onChange={handleFileChange("passport")}
                deleteFile={deleteFile("passport")}
                style={{ display: "none" }}
                download={false}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

const mapStateToProps = ({ employees }) => ({
  documentTypes: employees.documentTypes,
});

export default connect(mapStateToProps)(IdentificationDocuments);
