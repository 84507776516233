import { Button, Grid, IconButton } from "@material-ui/core";
import React, { useState } from "react";

import { FilterList } from "@material-ui/icons";
import Filters from "./Filters";
import { SearchInput } from "../../../../../components/common";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const Actions = ({
  selectedDepartments,
  setSelectedDepartments,
  selectedPositions,
  setSelectedPositions,
  selectedStatus,
  setSelectedStatus,
  selectedLocations,
  setSelectedLocations,
  showSalary,
  setShowSalary,
  setQuery,
  setOpenModal,
  user,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const { t } = useTranslation("common");

  const history = useHistory();

  return (
    <Grid
      item
      container
      alignItems="center"
      lg={12}
      style={{ padding: "20px", marginBottom: "20px" }}
    >
      <Grid item lg={3} xs={9}>
        <SearchInput
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("search")}
          position="start"
        />
      </Grid>
      <Grid item lg={3} xs={3}>
        <IconButton onClick={() => setShowFilters(!showFilters)}>
          <FilterList color="primary" />
        </IconButton>
      </Grid>
      <Grid item container direction="row-reverse" lg={6} xs={12}>
        {user.isCompanyOwner && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 5 }}
              onClick={() => history.push("/upload")}
            >
              {t("upload")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 5 }}
              onClick={() => history.push("/add/employee")}
            >
              {t("newEmployee")}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              {t("export")}
            </Button>
          </>
        )}
      </Grid>

      {showFilters && (
        <Grid item container lg={12} style={{ marginTop: 10 }}>
          <Filters
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            selectedPositions={selectedPositions}
            setSelectedPositions={setSelectedPositions}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
            showSalary={showSalary}
            setShowSalary={setShowSalary}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Actions);
