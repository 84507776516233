import {
  AddFileButton,
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../../../components/common";
import { Grid, useMediaQuery } from "@material-ui/core";

import FilesList from "../../../FilesList";
import React from "react";
import { connect } from "react-redux";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const EmployeeProfileDocumentForm = ({
  documentTypes,
  modifiedDocument,
  setModifiedDocument,
  modifiedFiles,
  setModifiedFiles,
  edit,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["employeeProfile", "employeeAdd"]);
  const isEnglish = language === "en";
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <>
      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          onChange={(e) => {
            setModifiedDocument({ ...modifiedDocument, name: e.target.value });
          }}
          placeholder={t("employeeProfile:enterName")}
          label={t("employeeProfile:documentName")}
          value={modifiedDocument.name}
          id="name"
          autoComplete="new-password"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          label={t("employeeProfile:documentNumber")}
          onChange={(e) => {
            setModifiedDocument({
              ...modifiedDocument,
              referenceNumber: e.target.value,
            });
          }}
          placeholder={t("employeeProfile:enterNumber")}
          value={modifiedDocument.referenceNumber}
          id="documentNumber"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          label={t("employeeProfile:documentIssue")}
          onChange={(e) => {
            setModifiedDocument({
              ...modifiedDocument,
              issueDate: moment(e).format("yyyy-MM-DD"),
            });
          }}
          value={modifiedDocument.issueDate}
          disableToolbar={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          label={t("employeeProfile:documentExpirationDate")}
          onChange={(e) => {
            setModifiedDocument({
              ...modifiedDocument,
              exp: moment(e).format("yyyy-MM-DD"),
            });
          }}
          value={modifiedDocument.exp}
          disableToolbar={false}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FormSelectInput
          label={t("employeeAdd:documentType")}
          name="documentType"
          selectOptions={documentTypes.map((type) => ({
            value: type.id,
            label: isEnglish ? type.name : type.nameAr,
          }))}
          onChange={(e) => {
            setModifiedDocument({
              ...modifiedDocument,
              type: { id: Number(e) },
            });
          }}
          value={modifiedDocument.type.id}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent={lgUp ? "flex-end" : "flex-start"}
        alignItems="flex-end"
        lg={6}
        md={6}
        xs={12}
      >
        <Grid item>
          <AddFileButton
            fileId={`document_id${document.id}`}
            documentId={document.id}
            onChange={(e) => {
              const { file } = e[0];
              setModifiedFiles((prevState) => [
                ...prevState,
                {
                  file,
                  documentId: +modifiedDocument.id,
                  uuid: Number(Date.now()),
                },
              ]);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FilesList files={modifiedFiles} setFiles={setModifiedFiles} editable />
      </Grid>
    </>
  );
};

const mapStateToProps = ({ employees, auth }) => ({
  documentTypes: employees.documentTypes,
});

export default connect(mapStateToProps)(EmployeeProfileDocumentForm);
