import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//Translation
import { useTranslation } from "react-i18next";

const FormSelectInput = ({
  fullEvent = null,
  label,
  helperText,
  selectOptions,
  value,
  name,
  multiple = false,
  onChange = () => {},
  isRequired,
  errors,
  defaultValue,
  disabled,
  ...inputProps
}) => {
  const [option, setOption] = useState(value ?? "");

  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  useEffect(() => {
    setOption(value);
  }, [value]);

  const handleChange = (event) => {
    if (fullEvent) fullEvent(event);
    onChange(event.target.value);
  };

  const useStyles = makeStyles(
    (theme) => ({
      error: {
        boxShadow: `0 0 0 0.11rem ${theme.palette.common.red}`,
        borderRadius: theme.spacing(0.5),
      },
      selectIcon: {
        "& .MuiSelect-selectMenu:": {
          justifyContent: isEnglish ? "flex-start" : "flex-end",
        },
        "& .MuiSelect-icon": {
          right: isEnglish ? 0 : "95%",
          left: isEnglish ? null : 0,
        },
      },
      select: {
        color: theme.palette.primary.main,

        textTransform: "uppercase",

        "& .MuiSelect-icon": {
          color: theme.palette.primary.main,
        },

        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.common.lightBlue,
          textTransform: "uppercase",
          fontWeight: "bolder",
        },
        "&:active": {
          backgroundColor: "transparent",
          color: theme.palette.common.lightBlue,
          textTransform: "uppercase",
          fontWeight: "bolder",
        },
        "&:focus": {
          backgroundColor: "transparent",
          color: theme.palette.common.lightBlue,
          textTransform: "uppercase",
          fontWeight: "bolder",
        },
      },
      formControlRoot: {
        justifyContent: "flex-start",
        padding: 4,

        "& .MuiFormLabel-root": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
          fontWeight: 500,
        },

        "& .MuiSelect-select.MuiSelect-select": {
          paddingRight: "0px",
        },

        "& .MuiInputLabel-formControl": {
          color: theme.palette.primary.main,
          padding: isEnglish ? 0 : 0,
          margin: isEnglish ? 0 : 0,
          left: isEnglish ? 0 : null,
          right: isEnglish ? null : 0,
        },
        "& .MuiInputLabel-shrink": {
          transformOrigin: isEnglish ? "top left" : "top right",
        },

        "& .MuiSelect-icon": {
          color: theme.palette.primary.main,
        },

        "& .MuiInput-underline.Mui-disabled:before": {
          borderBottomStyle: "solid",
        },
        "& .MuiInputBase-input MuiInput-input Mui-disabled Mui-disabled": {
          backgroundColor: "transparent",
        },

        "& .MuiInputBase-root.Mui-disabled": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
        },

        "& .MuiInputBase-root.Mui-disabled:after": {
          backgroundColor: "transparent",
        },
        "& .MuiFormHelperText-root": {
          textAlign: isEnglish ? "left" : "right",
        },
      },
    }),
    { index: 1 },
  );

  const classes = useStyles();

  const dataOptions = selectOptions.map((option, i) => (
    <MenuItem
      style={{ justifyContent: isEnglish ? "flex-start" : "flex-end" }}
      className={classes.select}
      value={option?.value ?? option}
      key={(option?.value ?? option) + i}
    >
      {option?.label ?? option}
    </MenuItem>
  ));

  return (
    <FormControl
      required={isRequired}
      fullWidth
      className={classes.formControlRoot}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        className={classes.selectIcon}
        multiple={multiple}
        id={name}
        name={name}
        inputProps={{ className: classes.select }}
        onChange={handleChange}
        value={option}
        {...inputProps}
      >
        {dataOptions}
      </Select>
      <FormHelperText children={helperText} error={inputProps.error} />
      <FormHelperText children={errors} error={!!errors} />
    </FormControl>
  );
};

export default FormSelectInput;
