import { Paper, Popover, makeStyles } from "@material-ui/core";

//Components
import { CompanyStructureModal } from "../../../../common";
import PositionForm from "./PositionForm";
import React from "react";
import { Save } from "@material-ui/icons";
import StyledListItem from "../../../../common/StyledListItem";
import StyledMenuUserItem from "../../../../common/StyledMenuItem";
import { connect } from "react-redux";
import { deletePosition } from "../../../../../redux/actions";
//Translation
import { useTranslation } from "react-i18next";

const PositionMenu = ({
  openModal,
  setOpenModal,
  anchorEl,
  handleClose,
  openPosition,
  idPosition,
  departmentId,
  position,
  deletePosition,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const useStyles = makeStyles((theme) => ({
    popover: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Popover
        id={idPosition}
        open={openPosition}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.popover}>
          <StyledMenuUserItem>
            <StyledListItem
              onClick={() => setOpenModal(true)}
              title={t("companyProfile:editPosition")}
            />
          </StyledMenuUserItem>
          <StyledMenuUserItem>
            <StyledListItem
              disabled={position?.employees?.length}
              onClick={() => deletePosition({ id: position.id }, departmentId)}
              title={t("companyProfile:deletePosition")}
            />
          </StyledMenuUserItem>
        </Paper>
      </Popover>
      <CompanyStructureModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fadeIn={openModal}
        title={t("companyProfile:editPosition")}
        icon={<Save />}
        iconText={t("common:save")}
      >
        <PositionForm
          setOpen={setOpenModal}
          departmentId={departmentId}
          position={position}
        />
      </CompanyStructureModal>
    </>
  );
};

const mapDispatchToProps = {
  deletePosition,
};

export default connect(null, mapDispatchToProps)(PositionMenu);
