//Material UI
import { Avatar, Box, Checkbox, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";

//Components
import { CustomTableCell } from "../../common";

const EmployeeItem = ({
  employee,
  employeesStatus,
  isItemSelected,
  labelId,
  handleClick,
}) => {
  const [status] = useState(
    employeesStatus.find(
      (_status) => _status.value === employee.employmentStatus,
    ),
  );
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  let total = +employee.totalNetSalary;
  // employee.nextSalaryDeposit.salaryModifications.forEach(
  //   (mod) => (total += +mod.amount)
  // );
  // employee.nextSalaryDeposit.recurringSalaryModifications.forEach(
  //   (mod) => (total += +mod.amount)
  // );

  return (
    <Box clone whiteSpace="nowrap">
      <TableRow
        style={{
          whiteSpace: "nowrap",
          borderBottom: "none",
          textDecoration: "none",
        }}
        align={isEnglish ? "left" : "right"}
        onClick={(event) => handleClick(event, employee)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={
          employee.user
            ? employee.user?.fullName + employee.id
            : employee?.fullName + employee.id
        }
        selected={isItemSelected}
      >
        <CustomTableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </CustomTableCell>
        <CustomTableCell>{employee.id}</CustomTableCell>
        <CustomTableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {employee.user ? employee.user?.fullName : employee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.department.name : employee.department.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? employee.position.name : employee.position.nameAr}
        </CustomTableCell>
        <CustomTableCell>
          {isEnglish ? status.name : status.nameAr}
        </CustomTableCell>
        <CustomTableCell>{employee.lastUpdated}</CustomTableCell>
        <CustomTableCell>{total} </CustomTableCell>
        <CustomTableCell>
          {employee.locations.map(
            (loc, index) =>
              `${isEnglish ? loc.name : loc.nameAr} ${
                (employee.locations.length > 1) &
                (index >= 0) &
                (loc !== employee.locations.at(-1))
                  ? " - "
                  : ""
              }`,
          )}
        </CustomTableCell>
      </TableRow>
    </Box>
  );
};

export default EmployeeItem;
