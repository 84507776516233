import { TableRow } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const CustomTableRow = ({
  children,
  to,
  borderBottom,
  backgroundColor,
  selected,
  ariaChecked,
  role,
  key,
  tabIndex,
  onClick = () => {},
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const theme = useTheme();
  if (to === null) {
    return (
      <TableRow
        onClick={onClick}
        style={{
          whiteSpace: "nowrap",
          borderBottom: borderBottom ? "unset" : "none",
          textDecoration: "none",
          backgroundColor: backgroundColor
            ? theme.palette.common.aliceBlue
            : undefined,
        }}
        align={isEnglish ? "left" : "right"}
        selected={selected}
        aria-checked={ariaChecked}
        role={role}
        key={key}
        tabIndex={tabIndex}
      >
        {children}
      </TableRow>
    );
  }
  return to ? (
    <TableRow
      component={RouterLink}
      to={to}
      style={{
        whiteSpace: "nowrap",
        borderBottom: borderBottom ? "unset" : "none",
        textDecoration: "none",
        backgroundColor: backgroundColor
          ? theme.palette.common.aliceBlue
          : undefined,
      }}
      align={isEnglish ? "left" : "right"}
      selected={selected}
      aria-checked={ariaChecked}
      role={role}
      key={key}
      tabIndex={tabIndex}
    >
      {children}
    </TableRow>
  ) : (
    <TableRow
      style={{
        whiteSpace: "nowrap",
        borderBottom: borderBottom ? "unset" : "none",
        textDecoration: "none",
        backgroundColor: backgroundColor
          ? theme.palette.common.aliceBlue
          : undefined,
      }}
      align={isEnglish ? "left" : "right"}
      selected={selected}
      aria-checked={ariaChecked}
      role={role}
      key={key}
      tabIndex={tabIndex}
    >
      {children}
    </TableRow>
  );
};

export default CustomTableRow;
