import { Box, Paper, Popover, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Components
import { CompanyStructureModal } from "../../../../common";
import DepartmentForm from "./DepartmentForm";
import PositionForm from "./PositionForm";
import React from "react";
import StyledListItem from "../../../../common/StyledListItem";
import StyledMenuUserItem from "../../../../common/StyledMenuItem";
import { connect } from "react-redux";
import { deleteDepartment } from "../../../../../redux/actions/structure";
//Translation
import { useTranslation } from "react-i18next";

const DepartmentActions = ({
  department,
  openDepartment,
  idDepartment,
  anchorEl,
  openModal,
  setOpenModal,
  closeDepartmentActions,
  deleteDepartment,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(["common", "companyProfile"]);

  const openEditModal = () => {
    setOpenModal({ ...openModal, edit: true });
  };

  const closeEditModal = () => {
    setOpenModal({ ...openModal, edit: false });
  };
  const openAddModal = () => {
    setOpenModal({ ...openModal, add: true });
  };

  const closeAddModal = () => {
    setOpenModal({ ...openModal, add: false });
  };

  const useStyles = makeStyles((theme) => ({
    popover: {
      backgroundColor: theme.palette.common.white,
    },
  }));

  const classes = useStyles();
  let employees = [];
  department.positions.forEach(
    (position) => (employees = [...employees, ...position.employees])
  );
  return (
    <>
      <Popover
        classes={{ paper: classes.popover }}
        idDepartment={idDepartment}
        open={openDepartment}
        anchorEl={anchorEl.department}
        onClose={closeDepartmentActions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.popover}>
          <StyledMenuUserItem>
            <StyledListItem
              onClick={openEditModal}
              title={t("companyProfile:editDepartment")}
            />
          </StyledMenuUserItem>

          <StyledMenuUserItem>
            <StyledListItem
              onClick={openAddModal}
              title={t("companyProfile:addPosition")}
            />
          </StyledMenuUserItem>
          <StyledMenuUserItem>
            <StyledListItem
              disabled={employees.length}
              onClick={() => deleteDepartment({ id: department.id })}
              title={t("companyProfile:deleteDepartment")}
            />
          </StyledMenuUserItem>
        </Paper>
      </Popover>
      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          open={openModal.edit}
          onClose={closeEditModal}
          fadeIn={openModal.edit}
          title={t("companyProfile:editDepartment")}
          height={matchesSM ? "350px" : "300px"}
        >
          <DepartmentForm setOpen={setOpenModal} department={department} />
        </CompanyStructureModal>
      </Box>
      <Box bgcolor="#F6F8FA">
        <CompanyStructureModal
          open={openModal.add}
          onClose={closeAddModal}
          fadeIn={openModal.add}
          title={t("companyProfile:addPosition")}
        >
          <PositionForm setOpen={setOpenModal} departmentId={department.id} />
        </CompanyStructureModal>
      </Box>
    </>
  );
};

const mapDispatchToProps = {
  deleteDepartment,
};

export default connect(null, mapDispatchToProps)(DepartmentActions);
