import { yupResolver } from "@hookform/resolvers/yup";
// MaterialUI
import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
// Actions
import {
  resetErrors,
  updateCompanyAddress,
  updateCompanyProfile,
  updatePoc,
} from "../../../redux/actions";
import CompanyInformation from "./Forms/CompanyInformation";
import ContactInformation from "./Forms/ContactInformation";
import DocumentsInformation from "./Forms/DocumentsInformation";
// Components
import FormStepper from "./FormStepper";
// Validators
import { companySchema, pocSchema } from "./Schema";

const CompanyCreate = ({
  company,
  address,
  poc,
  pocAddress,
  updateCompanyProfile,
  updateCompanyAddress,
  updatePoc,
  errorFields,
  resetErrors,
}) => {
  ReactGA.pageview(window.location.pathname);
  // Translation
  const { t } = useTranslation("companyCreate");

  // States
  const [step, setStep] = useState(0);
  const steps = [
    t("companyInformation"),
    t("documents"),
    t("contactInformation"),
  ];
  const history = useHistory();

  const companyMethods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...company,
      sectorId: company?.sector?.id ?? "",
      address: {
        ...address,
        country: address?.country?.code ?? "",
      },
    },
    resolver: yupResolver(companySchema),
  });

  const pocMethods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...poc,
      address: {
        ...pocAddress,
        country: pocAddress?.country?.code ?? "",
      },
    },
    resolver: yupResolver(pocSchema),
  });

  useEffect(() => {
    resetErrors();
  }, [step, resetErrors]);

  const companyReset = companyMethods.reset;
  useEffect(() => {
    companyReset({
      ...company,
      sectorId: company?.sector?.id ?? "",
      address: {
        ...address,
        country: address?.country?.code ?? "",
      },
    });
  }, [address, company, companyReset]);

  const getStepForm = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <CompanyInformation errorFields={errorFields} {...companyMethods} />
        );
      case 1:
        return <DocumentsInformation />;
      case 2:
        return <ContactInformation errorFields={errorFields} {...pocMethods} />;
      default:
        break;
    }
  };

  const handleCompanySubmit = ({
    address: companyAddress,
    ...companyProfile
  }) => {
    updateCompanyProfile(companyProfile);
    let country =
      companyAddress.country === "NK" ? null : companyAddress.country;
    let addressTemp = { ...companyAddress, country };
    updateCompanyAddress(addressTemp);

    setStep((prevStep) => prevStep + 1);
  };

  const handleDocumentSubmit = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
  };

  const handlePocSubmit = ({ address: pocAddress, ...pocData }) => {
    let country = pocAddress.country === "NK" ? null : pocAddress.country;
    let addressTemp = { ...pocAddress, country };

    updatePoc(pocData, addressTemp, history);
  };

  const onSubmit = (step) => {
    switch (step) {
      case 0:
        return companyMethods.handleSubmit(handleCompanySubmit);
      case 1:
        return handleDocumentSubmit;
      case 2:
        return pocMethods.handleSubmit(handlePocSubmit);

      default:
        throw Error(`No handleSubmit for ${step}`);
    }
  };
  return (
    <Box clone bgcolor="white" borderRadius={20} padding={5} marginY={5}>
      <Grid container justifyContent="center">
        <form noValidate onSubmit={onSubmit(step)}>
          <FormStepper step={step} setStep={setStep} steps={steps}>
            <Grid item md={10} xs={9}>
              {getStepForm(step)}
            </Grid>
          </FormStepper>
        </form>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({
  errors,
  company: { profile, address, poc, pocAddress },
}) => ({
  company: profile,
  address,
  poc,
  pocAddress,
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = {
  updateCompanyProfile,
  updateCompanyAddress,
  updatePoc,
  resetErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
