export const filterEmployeesByLocation = (employees, selectedLocations) => {
  // eslint-disable-next-line array-callback-return
  let filteredEmployees = employees.filter((employee) => {
    if (selectedLocations?.length === 0) {
      return employee;
    }

    let employeeLocations = employee.locations.map((loc) => loc.id);
    let selectedLocationsIds = selectedLocations.map((loc) => loc.value);
    for (const location of selectedLocationsIds) {
      return employeeLocations.includes(location);
    }
  });

  return filteredEmployees;
};
