import EmployeeBasicDetails from "../EmployeeBasicDetails";
import EmployeeContactInfo from "../EmployeeContactInfo";
import EmployeeHeader from "../EmployeeHeader";
// import ExpDocuments from "../../../../components/pages/EmployeeProfile/Overview/ExpDocuments";
import { Grid } from "@material-ui/core";
import React from "react";
import ResignationReq from "./ResignationReq";
import UpdateProfileReq from "./UpdateProfileReq";
import VacationReq from "./VacationReq";
import { useTranslation } from "react-i18next";

const Overview = (props) => {
  const {
    employee: {
      id,
      gender,
      maritalStatus,
      dateOfBirth,
      note,
      referenceNumber,
      phoneNumber,
      employmentDate,
      leaveDate,
      requests,
      address: {
        address1,
        address2,
        cityOrTown,
        country: { name },
        postCode,
        paciNumber,
        stateOrProvince,
        zipCode,
      },
      poc: {
        email: pocEmail,
        name: pocName,
        number: pocNumber,
        address: {
          address1: pocAddress1,
          address2: pocAddress2,
          cityOrTown: pocCityOrTown,
          country: { name: pocCountryName },
          postCode: pocPostCode,
          paciNumber: pocPaciNumber,
          stateOrProvince: pocStateOrProvince,
          zipCode: pocZipCode,
        },
      },
    },
  } = props;

  const { t } = useTranslation("common");

  const email = props.employee.user
    ? props.employee.user.email
      ? props.employee.user.email
      : ""
    : "";


  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <EmployeeHeader employee={props} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <EmployeeBasicDetails
          referenceNumber={referenceNumber?.length ? referenceNumber : id}
          email={email}
          leaveDate={leaveDate}
          phone={phoneNumber}
          employmentDate={employmentDate}
          gender={gender}
          maritalStatus={maritalStatus}
          dateOfBirth={dateOfBirth}
          note={note}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <EmployeeContactInfo
          address1={address1}
          address2={address2}
          cityOrTown={cityOrTown}
          country={name}
          postCode={postCode}
          paciNumber={paciNumber}
          stateOrProvince={stateOrProvince}
          zipCode={zipCode}
          pocEmail={pocEmail}
          pocName={pocName}
          pocNumber={pocNumber}
          pocAddress1={pocAddress1}
          pocAddress2={pocAddress2}
          pocCityOrTown={pocCityOrTown}
          pocCountryName={pocCountryName}
          pocPostCode={pocPostCode}
          pocPaciNumber={pocPaciNumber}
          pocStateOrProvince={pocStateOrProvince}
          pocZipCode={pocZipCode}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <VacationReq title={t("vacationRequest")} requests={requests} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <UpdateProfileReq
          title={t("updateProfileRequest")}
          requests={requests}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ResignationReq title={t("resignationRequest")} requests={requests} />
      </Grid>
    </Grid>
  );
};

export default Overview;
