import { styled, useMediaQuery } from "@material-ui/core";

import MobileNav from "../MobileNav";
import React from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useCallback } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SIDE_NAV_WIDTH = 310;

const useMobileNav = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const handlePathnameChange = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  useEffect(
    () => {
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
};

const Layout = ({ children, sections, navColor, user, employeeProfile }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();

  return (
    <>
      <TopNav user={user} onMobileNavOpen={mobileNav.handleOpen} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          user={user}
          employeeProfile={employeeProfile}
        />
      )}
      {!lgUp && (
        <MobileNav
          user={user}
          employeeProfile={employeeProfile}
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.isOpen}
          sections={sections}
          onMobileNavOpen={mobileNav.handleOpen}
        />
      )}
      <LayoutRoot>
        <LayoutContainer
          style={{
            paddingLeft:
              lgUp && isEnglish ? SIDE_NAV_WIDTH : !lgUp && isEnglish ? 0 : 0,
            paddingRight:
              lgUp && isEnglish
                ? 0
                : !lgUp && !isEnglish
                ? 0
                : !lgUp && isEnglish
                ? 0
                : SIDE_NAV_WIDTH,
          }}
        >
          {children}
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default Layout;

const LayoutRoot = styled("div")(({ theme, isEnglish }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));

const LayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  marginTop: 80,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 60,
  },
}));
