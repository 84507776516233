import { Box, Button, SvgIcon } from "@material-ui/core";

import { EditOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

const CompanyProfileHeader = ({ company }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginY={5}
      >
        <Box flexGrow={1}>
          <img
            alt="Company Logo"
            src={company?.logo ?? ""}
            style={{
              height: 150,
              width: 300,
            }}
          />
        </Box>

        <Box flexGrow={0}>
          <Button
            style={{ marginRight: 20, textTransform: "none" }}
            color="inherit"
            component={Link}
            to={`/company/edit`}
            endIcon={
              <SvgIcon>
                <EditOutlined />
              </SvgIcon>
            }
          >
            {t("edit")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CompanyProfileHeader;
