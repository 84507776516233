import React from "react";
//Components
import { Link } from "react-router-dom";
import { StyledNavLink } from "./stlyes";

const NavLink = ({ href, title, ...rest }) => {
  return (
    <StyledNavLink component={Link} href={href} underline="none" {...rest}>
      {title}
    </StyledNavLink>
  );
};

export default NavLink;
