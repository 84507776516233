import "jspdf-autotable";

import jsPDF from "jspdf";
import moment from "moment";

import { getPdfTemplate } from "../../../../../helpers/getPdfTemplate";
import getCorrectTimezone from "../../../../../utils";
import { calcDistance } from "../../../../../components/common/utils";

const attendanceTableHeaders = (t) => [
  t("employeeName"),
  t("checkInTime"),
  t("checkOutTime"),
  t("location"),
  t("checkInDistance"),
  t("checkOutDistance"),
  t("duration"),
  t("month"),
];

export const checkInDistanceToDisplay = (attendance, t) => {
  if (
    !attendance.checkInLatitude ||
    !attendance.checkInLongitude ||
    !attendance.location
  ) {
    return "-";
  }

  return `${calcDistance(
    attendance.checkInLatitude,
    attendance.location.latitude,
    attendance.checkInLongitude,
    attendance.location.longitude,
  )} ${t("kilometers")}`;
};

export const checkOutDistanceToDisplay = (attendance, t) => {
  if (
    !attendance.checkOutLatitude ||
    !attendance.checkOutLongitude ||
    !attendance.location
  ) {
    return "-";
  }

  return `${calcDistance(
    attendance.checkOutLatitude,
    attendance.location.latitude,
    attendance.checkOutLongitude,
    attendance.location.longitude,
  )} ${t("kilometers")}`;
};

export const calculateDuration = (attendance) => {
  if (attendance.checkIn && attendance.checkOut) {
    let diffInMins =
      (new Date(attendance.checkOut).getTime() -
        new Date(attendance.checkIn).getTime()) /
      1000 /
      60;
    let hours = diffInMins / 60;
    let fullHours = Math.floor(hours).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let mins = (hours - fullHours) * 60;
    let fullMins = Math.round(mins).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${fullHours}:${fullMins}`;
  } else {
    return "-";
  }
};

export const createAttendacePdf = (
  t,
  isEnglish,
  company,
  department,
  location,
  position,
  month,
  employeesList,
  // multipleMonths = false
) => {
  const pdf = new jsPDF();
  var pdfWidth = pdf.internal.pageSize.width;
  const pageWidth = pdfWidth || pdf.internal.pageSize.getWidth();
  const currentHeight = 15;

  getPdfTemplate(
    pdf,
    pageWidth,
    pdfWidth,
    currentHeight,
    t,
    isEnglish,
    company.name,
    company.logo,
  );

  const getMonthToDisplay = () => {
    let startMonth = moment(employeesList[0].checkIn).format("yyyy-MM");
    let endMonth = moment(
      employeesList[employeesList.length - 1].checkIn,
    ).format("yyyy-MM");
    if (startMonth === endMonth) {
      return startMonth;
    } else {
      return `${startMonth} - ${endMonth}`;
    }
  };

  // Location
  const locationField = t("locationsField");
  const locationValue = employeesList[0].employee?.locations.map((location) => {
    let locations = [];
    isEnglish ? locations.push(location.name) : locations.push(location.nameAr);
    if (locations.length > 1) {
      return locations.join(", ");
    } else {
      return locations[0];
    }
  });

  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 50, locationField, "left");
  pdf.text(45, currentHeight + 50, locationValue, "left");

  // Department
  const departmentField = t("departmentsField");
  const departmentValue = isEnglish
    ? employeesList[0].employee?.department.name
    : employeesList[0].employee?.department.nameAr;

  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 60, departmentField, "left");
  pdf.setFontSize(12);
  pdf.text(45, currentHeight + 60, departmentValue, "left");

  // Positon
  const positonField = t("positionsField");
  const positionValue = isEnglish
    ? employeesList[0].employee?.position.name
    : employeesList[0].employee?.position.nameAr;

  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 70, positonField, "left");
  pdf.text(45, currentHeight + 70, positionValue, "left");

  // Positon
  const monthField = t("monthField");
  const monthValue = getMonthToDisplay();
  pdf.setFontSize(12);
  pdf.text(15, currentHeight + 80, monthField, "left");
  pdf.text(45, currentHeight + 80, monthValue, "left");

  const tableHeaderStyles = {
    fillColor: [255, 255, 255],
    textColor: "#7387A1",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 7,
    cellPadding: 2,
    overflow: "visible",
  };

  const tableBodyStyles = {
    fillColor: [255, 255, 255],
    textColor: "#133761",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 7,
    cellPadding: 2,
    overflow: "visible",
  };

  const headStyles = isEnglish
    ? { ...tableHeaderStyles, halign: "left" }
    : { ...tableHeaderStyles, font: "Amiri-Regular", halign: "right" };

  const bodyStyles = {
    ...tableBodyStyles,
    font: "Amiri-Regular",
    halign: "left",
  };

  const headers = attendanceTableHeaders(t);

  const body = employeesList.map((attendance) => {
    let attendanceRow = [];

    attendanceRow.push(attendance.employee?.fullName);
    attendanceRow.push(
      moment(getCorrectTimezone(attendance.checkIn)).format("LLL"),
    );
    attendanceRow.push(
      attendance.checkOut
        ? moment(getCorrectTimezone(attendance.checkOut)).format("LLL")
        : "-",
    );
    attendanceRow.push(
      attendance.location
        ? isEnglish
          ? attendance.location.name
          : attendance.location.nameAr
        : "-",
    );
    attendanceRow.push(checkInDistanceToDisplay(attendance, t));
    attendanceRow.push(checkOutDistanceToDisplay(attendance, t));
    attendanceRow.push(calculateDuration(attendance));
    attendanceRow.push(moment(attendance.date).format("yyyy-MM"));

    return attendanceRow;
  });

  pdf.autoTable({
    startY: currentHeight + 90,
    styles: { fillColor: [255, 255, 255] },
    head: [headers],
    body: body,
    theme: "plain",
    tableLineColor: "#D6DBE3",
    tableLineWidth: 0.2,
    headStyles: headStyles,
    bodyStyles: bodyStyles,
  });

  pdf.save(`${company.name}-attendance-${month}.pdf`);
};
export const normalizeData = (employees, t) => {
  return employees.map((employee) => {
    return {
      employeeName: employee.employee?.fullName,
      checkInTime: employee.checkIn
        ? moment(getCorrectTimezone(employee.checkIn)).format("LLL")
        : "-",
      checkOutTime: employee.checkOut
        ? moment(getCorrectTimezone(employee.checkOut)).format("LLL")
        : "-",
      location: employee.location
        ? employee.location.name
        : employee.employee?.locations[0]?.name,
      checkInDistance: checkInDistanceToDisplay(employee, t),
      checkOutDistance: checkOutDistanceToDisplay(employee, t),
      duration: calculateDuration(employee),
      month: moment(employee.date).format("yyyy-MM"),
    };
  });
};

export const getHeaders = (employeeObj, t) => {
  if (employeeObj) {
    const headers = Object.keys(employeeObj);
    const translatedHeaders = headers.map((head) => t(head));

    return translatedHeaders;
  }
};

export const getBody = (employees) => {
  const employeeValues = employees.map((employee) => Object.values(employee));

  return employeeValues;
};

export const getXlsxData = (employees, t) => {
  const _employees = normalizeData(employees, t);
  const headers = getHeaders(_employees[0], t);
  const body = getBody(_employees);

  const data = [headers, ...body];

  return data;
};
