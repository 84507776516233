//MaterialUI
import {
  Grid,
  Button,
  Box,
  Typography,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import {
  CompanyStructureModal,
  FormInputField,
} from "../../../../../../components/common";
import React, { useState } from "react";

import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";

const Form = ({ updateCompanyProfile, company }) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [companyState, setCompany] = useState({
    ...company,
  });

  const handleUpdate = () => {
    updateCompanyProfile(companyState);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid item md={12} xs={12}>
        <FormControlLabel
          control={
            <>
              <IconButton color="primary" onClick={() => setIsModalOpen(true)}>
                <Edit />
              </IconButton>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#3D5E77",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {companyState?.checkInDistanceLimit
                  ? companyState?.checkInDistanceLimit * 1000 +
                    " " +
                    t("meters")
                  : t("notSet")}
              </Typography>
            </>
          }
          labelPlacement="start"
          label={
            <Typography
              variant="subtitle2"
              style={{
                marginInlineEnd: 8,
                color: "#3D5E77",
              }}
            >
              {t("checkinDistanceLimit")}
            </Typography>
          }
        />
      </Grid>

      <CompanyStructureModal
        open={isModalOpen}
        fadeIn={true}
        title={t("updateCheckinDistanceLimit")}
      >
        <Box height="auto" maxHeight="90vh" margin={4}>
          <Grid item lg={12} xs={12}>
            <FormInputField
              label={t("companyProfile:checkInDistanceLimit")}
              id="checkInDistanceLimit"
              name="checkInDistanceLimit"
              type="number"
              onChange={(e) => {
                setCompany({
                  ...companyState,
                  checkInDistanceLimit: e.target.value / 1000.0,
                });
              }}
              value={companyState?.checkInDistanceLimit * 1000}
              helperText={t("companyProfile:checkInDistanceLimitHelperText")}
              autoComplete="checkInDistanceLimit"
            />
            <Grid
              item
              container
              direction="row-reverse"
              lg={12}
              xs={12}
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                >
                  {t("save")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CompanyStructureModal>
    </>
  );
};

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(null, mapDispatchToProps)(Form);
