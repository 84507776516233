import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";

import { CompanyStructureModal } from "../../../../../components/common";
import GeneratePdfButton from "./GeneratePdfButton";
import GenerateXlsxButton from "./GenerateXlsxButton";
import React from "react";
import { normalizeData } from "./createPDF";
import { useTranslation } from "react-i18next";

const GenerateReportModal = ({
  filteredEmployees,
  openModal,
  setOpenModal,
  pdfFields,
  setPdfFields,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const handleCheckboxChange = (event) => {
    const modifiedFields = pdfFields.map((field) => {
      if (field.value === event.target.name) {
        field.active = !field.active;
      }
      return field;
    });

    setPdfFields(modifiedFields);
  };

  const employeesForPdf = normalizeData(
    filteredEmployees,
    pdfFields,
    isEnglish
  );

  return (
    <CompanyStructureModal
      open={openModal}
      onClose={() => setOpenModal(false)}
      fadeIn={openModal}
      title={t("selectFieldsToGenerateReport")}
    >
      <Box
        style={{ overflowY: "auto" }}
        height="auto"
        maxHeight={"60vh"}
        margin={4}
      >
        <FormControl>
          <FormGroup>
            {pdfFields.map((field) => {
              return (
                <FormControlLabel
                  key={field.value}
                  control={
                    <Checkbox
                      checked={field.active}
                      onChange={handleCheckboxChange}
                      name={field.value}
                    />
                  }
                  label={field.label}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        margin={2}
      >
        <GeneratePdfButton
          employeesForPdf={employeesForPdf}
          pdfFields={pdfFields}
          setPdfFields={setPdfFields}
          setOpenModal={setOpenModal}
        />

        <GenerateXlsxButton
          employeesForPdf={employeesForPdf}
          pdfFields={pdfFields}
          setPdfFields={setPdfFields}
          setOpenModal={setOpenModal}
        />
      </Box>
    </CompanyStructureModal>
  );
};

export default GenerateReportModal;
