import { MenuItem, Typography } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import FormInputField from "./FormInputField";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//Translation
import { useTranslation } from "react-i18next";

const AutocompleteInput = ({
  flags = false,
  fullEvent = null,
  label,
  helperText,
  options,
  multiple = false,
  value,
  name,
  groupBy,
  disabled,
  onChange = () => {},
  forcePopupIcon = true,
  isRequired,
  errors,
  variant,
  ...inputProps
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInput-root": {
        direction: isEnglish ? "ltr" : "rtl",
      },
      "& .MuiIconButton-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
        textAlign: isEnglish ? "left" : "right",
      },

      "& .MuiListItem-root": {
        justifyContent: isEnglish ? "flex-start" : "flex-end",
      },
      "& .MuiInputLabel-formControl": {
        display: "flex",
        flexDirection: isEnglish ? "row" : "row-reverse",
        top: 0,
        paddingRight: isEnglish ? 0 : 4,
        // position: "relative",
      },
      "& .MuiAutocomplete-endAdornment": {
        right: isEnglish ? 0 : "auto",
        left: !isEnglish ? 0 : "auto",
      },
      "& .MuiAutocomplete-clearIndicator": {
        visibility: "hidden",
      },
    },
    select: {
      color: theme.palette.primary.main,
      padding: 0,
      margin: 0,
      left: isEnglish ? 0 : null,
      right: isEnglish ? null : 0,
      justifyContent: isEnglish ? "flex-start" : "flex-end",
      textTransform: "uppercase",
    },

    selectInput: {
      "& .MuiInputLabel-formControl": {
        color: theme.palette.primary.main,
        padding: isEnglish ? 0 : 0,
        margin: isEnglish ? 0 : 0,
        left: isEnglish ? 0 : null,
        right: isEnglish ? null : 0,
      },
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Autocomplete
      ChipProps={{
        deleteIcon: forcePopupIcon ? "inherit" : <></>,
      }}
      forcePopupIcon={forcePopupIcon}
      autoComplete={false}
      multiple={multiple}
      className={classes.root}
      disabled={disabled}
      id="country-select-demo"
      options={options}
      classes={{
        option: classes.select,
      }}
      name={name}
      onChange={(_e, v) => {
        onChange(v);
      }}
      value={value}
      {...inputProps}
      autoHighlight
      groupBy={(option) => option.isFavorite}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <MenuItem className={classes.select}>
          {flags && <span>{countryToFlag(option.value)}</span>}
          <Typography>{option.label} </Typography>
        </MenuItem>
      )}
      renderInput={(params) => {
        params.inputProps.autoComplete = "new-password";
        return (
          <FormInputField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            helperText={helperText}
            errors={errors}
            label={label}
            {...params}
            isAutocomplete
            variant={variant}
          />
        );
      }}
    />
  );
};

export default AutocompleteInput;
