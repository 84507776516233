// Material UI
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React from "react";

// Components
import CustomTableCell from "../../common/CustomTableCell";
import CustomTableRow from "../../common/CustomTableRow";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&.MuiIconButton-root": {
      color: theme.palette.common.blue,
    },
    "&.Mui-disabled": {
      color: theme.palette.common.lightBlue,
    },
  },
}));

const EmployeeRow = ({ employee, t, isEnglish }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const overtimeEnabled = employee.employee.company.overtimeEnabled;

  const difference =
    employee.modifications.length > 0
      ? employee.modifications
          .map((modification) => +modification.amount)
          .reduce((a, b) => +a + +b)
      : 0;

  return (
    <React.Fragment>
      <CustomTableRow>
        <CustomTableCell>
          <IconButton
            className={classes.iconButton}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CustomTableCell>
        <CustomTableCell
          component="th"
          scope="row"
          style={{
            whiteSpace: "nowrap",
            borderBottom: "none",
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Avatar alt="Logo" src={employee.employee.image} />
            <Box clone paddingX={1}>
              <Typography variant="subtitle2" color="textPrimary">
                {employee.employee.user
                  ? employee.employee.user?.fullName
                  : employee.employee?.fullName}
              </Typography>
            </Box>
          </Box>
        </CustomTableCell>

        <CustomTableCell>{employee.salary.totalSalary}</CustomTableCell>

        <CustomTableCell>
          <span
            style={{
              color:
                difference > 0 ? "green" : difference < 0 ? "red" : "black",
            }}
          >
            {difference}
          </span>
        </CustomTableCell>
      </CustomTableRow>
      <CustomTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 1 }}>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    align={isEnglish ? "left" : "right"}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("salaryDetail")}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box display="flex" flexDirection="row">
                    <Typography
                      style={{ fontWeight: "bold" }}
                      component="div"
                      align={isEnglish ? "left" : "right"}
                    >
                      {t("baseSalary")}:{" "}
                    </Typography>
                    <Typography align={isEnglish ? "left" : "right"}>
                      {employee.employee.baseSalary}
                    </Typography>
                  </Box>
                </Grid>
                {employee.modifications.length !== 0 && (
                  <Grid item md={12} xs={12}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      component="div"
                      align={isEnglish ? "left" : "right"}
                    >
                      {t("modifications")}
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <CustomTableRow>
                          <CustomTableCell>{t("reason")}</CustomTableCell>
                          <CustomTableCell>{t("amount")}</CustomTableCell>
                        </CustomTableRow>
                      </TableHead>
                      <TableBody>
                        {employee.modifications.map((modification, idx) => (
                          <TableRow
                            key={
                              modification.amount + modification.reason + idx
                            }
                          >
                            <CustomTableCell>
                              {modification.reason}
                            </CustomTableCell>
                            <CustomTableCell>
                              {modification.amount}
                            </CustomTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {employee.recurringModifications.length !== 0 && (
                  <Grid item md={12} xs={12}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      component="div"
                      align={isEnglish ? "left" : "right"}
                    >
                      {t("recurringModifications")}
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <CustomTableRow>
                          <CustomTableCell>{t("reason")}</CustomTableCell>
                          <CustomTableCell>{t("amount")}</CustomTableCell>
                        </CustomTableRow>
                      </TableHead>
                      <TableBody>
                        {employee.recurringModifications.map(
                          (modification, idx) => (
                            <TableRow
                              key={
                                modification.amount + modification.reason + idx
                              }
                            >
                              <CustomTableCell>
                                {modification.reason}
                              </CustomTableCell>
                              <CustomTableCell>
                                {modification.amount}
                              </CustomTableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {overtimeEnabled && (
                  <Grid item md={12} xs={12}>
                    <Box display="flex" flexDirection="row">
                      <Typography
                        style={{ fontWeight: "bold" }}
                        component="div"
                        align={isEnglish ? "left" : "right"}
                      >
                        {t("overtime")}:{" "}
                      </Typography>
                      <Typography align={isEnglish ? "left" : "right"}>
                        {employee.overtimeCost}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </CustomTableRow>
    </React.Fragment>
  );
};

export default EmployeeRow;
