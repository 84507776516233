//Material UI
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  DeleteOutline,
  Edit,
  FileCopyOutlined,
  Save,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";

//Components
import { CompanyStructureModal } from "../../../common";
import ConfirmDeleteForm from "./ConfirmDeleteForm";
import ModificationForm from "./ModificationForm";
import { connect } from "react-redux";
import { deleteSalaryModification } from "../../../../redux/actions";
import moment from "moment";
//Translation
import { useTranslation } from "react-i18next";

const PayrollItem = ({
  item,
  employeeId,
  editable = false,
  deleteSalaryModification,
}) => {
  const { t } = useTranslation("employeeSalary");
  const [edit, setEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modification, setModification] = useState({ ...item, employeeId });

  useEffect(() => {
    setModification({ ...item, employeeId });
  }, [item, employeeId]);

  const handleDelete = () => {
    deleteSalaryModification({ id: item?.id }, () => setOpenDeleteModal(false));
  };

  return (
    <>
      <TableRow hover role="checkbox" key={modification.description}>
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {modification.reason}
          </Typography>
        </TableCell>
        <TableCell>
          {edit ? (
            <TextField />
          ) : (
            <Typography variant="subtitle2" color="textPrimary">
              {modification.amount}
            </Typography>
          )}
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ whiteSpace: "nowrap" }}
          >
            {modification.month
              ? moment(new Date(modification.month)).format("MMM-YYYY")
              : moment(new Date(modification.startDate)).format("MMM-YYYY")}
          </Typography>
        </TableCell>

        {editable && (
          <TableCell>
            {edit ? (
              <IconButton color="primary" onClick={() => setEdit(false)}>
                <Save />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={() => setEdit(true)}>
                <Edit />
              </IconButton>
            )}
          </TableCell>
        )}
        <TableCell>
          {modification.attachment ? (
            <a
              href={modification.attachment}
              target="_blank"
              download
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <FileCopyOutlined />
            </a>
          ) : (
            <Typography>-</Typography>
          )}
        </TableCell>

        <TableCell>
          <IconButton color="primary" onClick={() => setOpenDeleteModal(true)}>
            <DeleteOutline />
          </IconButton>
        </TableCell>
      </TableRow>
      <CompanyStructureModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        fadeIn={openDeleteModal}
        title={t("employeeSalary:confirmDeleteModification")}
      >
        <Box height={"auto"} maxHeight="90vh" margin={4}>
          <ConfirmDeleteForm
            setOpenDeleteModal={setOpenDeleteModal}
            onClickConfirm={handleDelete}
          />
        </Box>
      </CompanyStructureModal>
      {editable && (
        <CompanyStructureModal
          open={edit}
          onClose={() => setEdit(false)}
          fadeIn={edit}
          title={t("editModification")}
        >
          <ModificationForm
            modification={modification}
            setModification={setModification}
            setClose={() => setEdit(false)}
          />
        </CompanyStructureModal>
      )}
    </>
  );
};

const mapDispatchToPros = {
  deleteSalaryModification,
};

export default connect(null, mapDispatchToPros)(PayrollItem);
