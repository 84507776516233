import { Card, CardHeader, Grid } from "@material-ui/core";
import React, { useState } from "react";

import ResourceDetail from "./ResourceDetail";
import ResourceModal from "./ResourceModal";
import ResourcesAccordion from "./ResourcesAccordion";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Resources = ({ resources }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const [selectedResource, setSelectedResource] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const resourcesList = resources.map((resource) => (
    <>
      <ResourcesAccordion
        title={isEnglish ? resource.name : resource.nameAr}
        hasAttachments={resource.files.length || resource.links.length}
        onClickIcon={() => {
          setSelectedResource(resource);
          setOpenModal(true);
        }}
      >
        <ResourceDetail
          resource={resource}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </ResourcesAccordion>
    </>
  ));

  return (
    <>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <Card>
            <CardHeader title={t("resources")} />
            {resourcesList}
          </Card>
        </Grid>
      </Grid>
      <ResourceModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        resource={selectedResource}
        setResource={setSelectedResource}
        t={t}
        isEnglish={isEnglish}
      />
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  resources: company.resources,
});

export default connect(mapStateToProps)(Resources);
