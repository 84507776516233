import { Grid, useMediaQuery } from "@material-ui/core";
import {
  AddFileButton,
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../../../components/common";

import moment from "moment/moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import FilesList from "../../../FilesList";

const CompanyDocumentForm = ({
  companyLocations,
  modifiedDocument,
  setModifiedDocument,
  modifiedFiles,
  setModifiedFiles,
  edit,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["employeeProfile", "employeeAdd"]);
  const isEnglish = language === "en";
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  let locationValue =
    modifiedDocument?.location?.id ?? modifiedDocument.location;

  return (
    <>
      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          onChange={(e) => {
            setModifiedDocument({ ...modifiedDocument, name: e.target.value });
          }}
          placeholder={t("employeeProfile:enterName")}
          label={t("employeeProfile:documentName")}
          value={modifiedDocument.name}
          id="name"
          autoComplete="new-password"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          label={t("employeeProfile:documentExpirationDate")}
          onChange={(e) => {
            setModifiedDocument({
              ...modifiedDocument,
              exp: moment(e).format("yyyy-MM-DD"),
            });
          }}
          value={modifiedDocument.exp}
          disableToolbar={false}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FormSelectInput
          value={locationValue}
          selectOptions={companyLocations.map((location) => ({
            label: isEnglish ? location.name : location.nameAr,
            value: location.id,
          }))}
          onChange={(e) => {
            setModifiedDocument({ ...modifiedDocument, location: e });
          }}
          label={t("common:location")}
        />
      </Grid>

      <Grid
        item
        container
        justifyContent={lgUp ? "flex-end" : "flex-start"}
        alignItems="flex-end"
        lg={6}
        md={6}
        xs={12}
      >
        <Grid item>
          <AddFileButton
            fileId={`document_id${document.id}`}
            documentId={document.id}
            onChange={(e) => {
              const { file } = e[0];
              setModifiedFiles((prevState) => [
                ...prevState,
                {
                  file,
                  documentId: document.id,
                  uuid: Number(Date.now()),
                },
              ]);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FilesList files={modifiedFiles} setFiles={setModifiedFiles} editable />
      </Grid>
    </>
  );
};

const mapStateToProps = ({ company }) => ({
  companyLocations: company.locations,
});

export default connect(mapStateToProps)(CompanyDocumentForm);
