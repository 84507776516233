import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
  CompanyStructureModal,
  DatePickerField,
  FormInputField,
} from "../../../../../components/common";

import { Alert } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import moment from "moment/moment";
import { updateOrCreateOvertimeCredit } from "../../../../../redux/actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AddCredit = ({
  employee,
  open,
  setOpen,
  updateOrCreateOvertimeCredit,
}) => {
  const [overtimeRequest, setOvertimeRequest] = useState({
    employeeId: employee.id,
    date: moment(new Date()).format("yyyy-MM-DD"),
    amount: "",
  });

  const [error, setError] = useState("");

  const { t } = useTranslation("common");

  return (
    <CompanyStructureModal
      open={open}
      onClose={() => setOpen(false)}
      fadeIn={open}
      title={t("addOvertimeCredit")}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item container md={12} spacing={3} style={{ margin: 10 }}>
          {error && (
            <Grid item md={8} xs={12}>
              <Alert onClick={() => setError("")} severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item md={6} xs={6}>
            <DatePickerField
              isRequired
              label={t("startDate")}
              name="date"
              onChange={(e) =>
                setOvertimeRequest({
                  ...overtimeRequest,
                  date: moment(e).format("yyyy-MM-DD"),
                })
              }
              value={overtimeRequest.date}
              disableToolbar={false}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <FormInputField
              isRequired
              label={t("amount")}
              name="amount"
              type="number"
              onChange={(e) => {
                setOvertimeRequest({
                  ...overtimeRequest,
                  amount: e.target.value,
                });
              }}
              value={overtimeRequest.amount}
              id="amount"
              helperText={t("maximumOvertime")}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} style={{ margin: 10 }}>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateOrCreateOvertimeCredit(overtimeRequest, () =>
                    setOpen(false)
                  )
                }
              >
                <Typography>{t("confirm")}</Typography>
              </Button>
            </Box>
            <Box clone marginX={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                <Typography>{t("cancel")}</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CompanyStructureModal>
  );
};

const mapDispatchToProps = {
  updateOrCreateOvertimeCredit,
};
export default connect(null, mapDispatchToProps)(AddCredit);
