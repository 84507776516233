import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { getComparator, stableSort } from "../../../helpers";
import { EnhancedTable } from "../../common";
import EmployeeRow from "./EmployeeRow";

//Material UI

const EmployeesTable = ({ filteredEmployees, showSalary, setShowSalary }) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");

  let withoutSalaryLabels = [
    { id: "employeeId", label: t("employeeID") },
    { id: "employeeName", label: t("employeeName") },
    { id: "department", label: t("department") },
    { id: "position", label: t("position") },
    { id: "status", label: t("status") },
    { id: "location", label: t("locations") },
    { id: "lastUpdated", label: t("lastUpdated") },
  ];

  const withSalaryLabels = [
    ...withoutSalaryLabels,
    { id: "netSalary", label: t("netSalary") },
  ];

  let labels = showSalary ? withSalaryLabels : withoutSalaryLabels;

  let body = stableSort(filteredEmployees, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((employee) => (
      <EmployeeRow
        showSalary={showSalary}
        setShowSalary={setShowSalary}
        employee={employee}
        key={
          employee.user
            ? employee.user?.fullName + employee.id
            : employee?.fullName + employee.id
        }
      />
    ));

  return (
    <EnhancedTable
      data={filteredEmployees}
      labels={labels}
      body={body}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      showSalary={showSalary}
      setShowSalary={setShowSalary}
      employeesPage
      withPagination
    />
  );
};

export default EmployeesTable;
