import { Box, Grid } from "@material-ui/core";

import { FormImageField } from "../../../../../components/common";
import React from "react";
import { connect } from "react-redux";
import { updateCompanyProfile } from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const CompanyImage = ({ updateCompanyProfile, company, edit }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  return (
    <Box clone paddingBottom={3}>
      <Grid item md={12} sm={12} xs={12}>
        <FormImageField
          label={t("uploadLogo")}
          name="logo"
          imageId={"one"}
          image={company?.logo ? company.logo : null}
          onChange={(e) =>
            updateCompanyProfile({
              ...company,
              logo: e.target.files[0],
            })
          }
        />
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

const mapDispatchToProps = {
  updateCompanyProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyImage);
