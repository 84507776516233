import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Actions
import { login } from "../../../redux/actions";

// Layout
import { Grid, Button, Typography, Box } from "@material-ui/core";

// Icons
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Link } from "react-router-dom";
import AuthenticationInput from "../../common/AuthenticationInput";

const schema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const Form = ({ login, errorFields }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("login");

  const isEnglish = language === "en";

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ email, password }) => {
    login({ email, password });
  };

  const [visible, setVisible] = useState(false);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} data-test="login-form">
        <Grid container justifyContent="space-between" spacing={2}>
          <Box clone pb={2}>
            <Grid item md={12}>
              <Typography
                variant="h2"
                color="primary"
                align={isEnglish ? "left" : "right"}
              >
                {t("logInWithEmail")}
              </Typography>
            </Grid>
          </Box>

          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <AuthenticationInput
                error={!!errors.email}
                inputRef={register}
                helperText={errors.email?.message}
                name="email"
                label={t("email")}
                icon={<PermIdentityOutlinedIcon />}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box clone width="100%">
              <AuthenticationInput
                name="password"
                error={!!errors.password}
                helperText={errors.password?.message}
                inputRef={register}
                label={t("password")}
                type={visible ? "text" : "password"}
                icon={
                  visible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )
                }
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(!visible)}
              />
            </Box>
          </Grid>

          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}

          <Grid item md={6} xs={5}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ExitToAppRoundedIcon />}
              style={{
                color: "#FFF",
                backgroundColor: "#3D5E77",
              }}
            >
              {t("signIn")}
            </Button>
          </Grid>
          <Grid item md={6} xs={5}>
            <Typography
              component={Link}
              to="/forgotPassword"
              align="right"
              style={{ color: "#4E9AF8" }}
            >
              {t("forgetPassword")}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = ({ errors }) => ({
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Form);
