import "jspdf-autotable";

import "../../../helpers/Amiri-Regular-normal";

import jsPDF from "jspdf";

import { getBody, getHeaders, payrollPdfFields } from "./data";

export const filterEmployees = (
  employees,
  department,
  position,
  location,
  query,
) => {
  return employees.filter((employee) => {
    const matchesDepartment =
      department.length === 0 ||
      department.some((dep) => dep.value === employee.employee.department.id);
    const matchesPosition =
      position.length === 0 ||
      position.some((pos) => pos.value === employee.employee.position.id);
    const matchesLocation =
      location.length === 0 ||
      location.some((loc) =>
        employee.employee.locations
          .map((empLoc) => empLoc.id)
          .includes(loc.value),
      );
    const fullName = employee.employee.user
      ? employee.employee.user.fullName
      : employee.employee.fullName || "-";
    const matchesQuery =
      fullName.toLowerCase().includes(query.toLowerCase()) ||
      employee.employee.id.includes(query);

    return (
      matchesDepartment && matchesPosition && matchesLocation && matchesQuery
    );
  });
};

const getTotalSum = (employees, idx) => {
  let sum = 0;
  for (const employee of employees) {
    sum += Number(employee[idx]);
  }

  return sum.toFixed(3);
};

export const createPDF = (employeesForPdf, closeModal, t, isEnglish, month) => {
  const pdf = new jsPDF("landscape");

  // Styling
  const tableHeaderStyles = {
    fillColor: [255, 255, 255],
    textColor: "#7387A1",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 4,
    cellPadding: 2,
    overflow: "visible",
  };

  const tableBodyStyles = {
    fillColor: [255, 255, 255],
    textColor: "#133761",
    lineColor: "#D6DBE3",
    lineWidth: 0.2,
    valign: "middle",
    fontSize: 4,
    cellPadding: 2,
    overflow: "visible",
  };

  const headStyles = isEnglish
    ? { ...tableHeaderStyles, halign: "left" }
    : { ...tableHeaderStyles, font: "Amiri-Regular", halign: "right" };

  const bodyStyles = isEnglish
    ? { ...tableBodyStyles, halign: "left" }
    : { ...tableBodyStyles, font: "Amiri-Regular", halign: "right" };

  // Pdf Headers

  const headers = getHeaders(employeesForPdf[0], t, isEnglish);

  let titleHeight = 15;
  let heightForTable = 30;

  const headerObjValues = payrollPdfFields(t)?.filter((field) =>
    headers?.includes(field.label),
  );

  const headerValues = headerObjValues.map((header) => header.value);

  let body = getBody(employeesForPdf, isEnglish);

  let sumOfValues = [];
  let addExtraRow = false;

  let sumCalculationValueExist =
    headerValues.includes("earnings") ||
    headerValues.includes("deductions") ||
    headerValues.includes("grossSalary") ||
    headerValues.includes("overTimeCost") ||
    headerValues.includes("baseSalary") ||
    headerValues.includes("totalSalary") ||
    headerValues.includes("netSalary") ||
    headerValues.includes("termination") ||
    headerObjValues.includes("resignation") ||
    headerObjValues.includes("vacationAccurals");

  if (sumCalculationValueExist) {
    for (let idx in headerObjValues) {
      if (headerObjValues[idx].value === "earnings") {
        const earningsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = earningsTotal;
      } else if (headerObjValues[idx].value === "deductions") {
        const deductionsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = deductionsTotal;
      } else if (headerObjValues[idx].value === "grossSalary") {
        const grossSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = grossSalaryTotal;
      } else if (headerObjValues[idx].value === "overTimeCost") {
        const overTimeCostTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = overTimeCostTotal;
      } else if (headerObjValues[idx].value === "baseSalary") {
        const baseSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = baseSalaryTotal;
      } else if (headerObjValues[idx].value === "totalSalary") {
        const totalSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = totalSalaryTotal;
      } else if (headerObjValues[idx].value === "netSalary") {
        const netSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = netSalaryTotal;
      } else if (headerObjValues[idx].value === "termination") {
        const terminationTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = terminationTotal;
      } else if (headerObjValues[idx].value === "resignation") {
        const resignationTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = resignationTotal;
      } else if (headerObjValues[idx].value === "vacationAccrual") {
        const vacationAccuralsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = vacationAccuralsTotal;
      } else {
        sumOfValues[idx] = "";
      }
    }
    addExtraRow = true;
    sumOfValues[0] = t("sumOfValues");
    if (isEnglish) {
      body = [...body, [], sumOfValues];
    } else {
      body = [...body, [], sumOfValues.reverse()];
    }
  }

  const title = `${t("payrollReport")} ${month}`;

  const pageWidth =
    pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

  pdf.setTextColor("#002553");

  if (!isEnglish) {
    pdf.setFont("Amiri-Regular");
  }

  pdf.text(title, pageWidth / 2, titleHeight, { align: "center" });

  let drawCell = function (data) {
    var pdf = data.doc;
    const rows = data.table.body;
    if (data.row.index === rows.length - 2) {
      data.cell.styles.lineWidth = 0;
      pdf.line(0, 0, 0, 0);
      pdf.setLineWidth(0);
    }
    if (data.row.index === rows.length - 1) {
      data.cell.styles.lineWidth = 0;
      if (isEnglish) {
        pdf.setFont("bold");
      } else {
        pdf.setFont("Amiri-Regular");
      }
      // pdf.setFontSize("4");
      pdf.line(0, 0, 0, 0);
      pdf.setTextColor(0, 0, 0);
      pdf.setLineWidth(0);
    }
  };

  pdf.autoTable({
    startY: heightForTable,
    styles: { fillColor: [255, 255, 255] },
    head: [headers],
    body: body,
    theme: "plain",
    tableLineColor: "#D6DBE3",
    tableLineWidth: 0.2,
    headStyles: headStyles,
    bodyStyles: bodyStyles,
    willDrawCell: addExtraRow && drawCell,
  });

  pdf.save(`payroll-report-${month}.pdf`);
  closeModal();
};

export const getCsvData = (
  employeesForCsv,
  setPdfFields,
  payrollPdfFields,
  setOpenModal,
  t,
  isEnglish,
) => {
  const headers = getHeaders(employeesForCsv[0], t, isEnglish);
  const body = getBody(employeesForCsv, isEnglish);

  const data = [headers, ...body];

  return data;
};

export const getXlsxData = (employeesForCsv, closeModal, t, isEnglish) => {
  const headers = getHeaders(employeesForCsv[0], t, isEnglish);
  const body = getBody(employeesForCsv, isEnglish);

  let data = [headers, ...body];

  const headerObjValues = payrollPdfFields(t)?.filter((field) =>
    headers?.includes(field.label),
  );

  const headerValues = headerObjValues.map((header) => header.value);

  let sumOfValues = [];

  let sumCalculationValueExist =
    headerValues.includes("earnings") ||
    headerValues.includes("deductions") ||
    headerValues.includes("grossSalary") ||
    headerValues.includes("overTimeCost") ||
    headerValues.includes("baseSalary") ||
    headerValues.includes("totalSalary") ||
    headerValues.includes("netSalary") ||
    headerValues.includes("termination") ||
    headerObjValues.includes("resignation") ||
    headerObjValues.includes("vacationAccurals");

  if (sumCalculationValueExist) {
    for (let idx in headerObjValues) {
      if (headerObjValues[idx].value === "earnings") {
        const earningsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = earningsTotal;
      } else if (headerObjValues[idx].value === "deductions") {
        const deductionsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = deductionsTotal;
      } else if (headerObjValues[idx].value === "grossSalary") {
        const grossSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = grossSalaryTotal;
      } else if (headerObjValues[idx].value === "overTimeCost") {
        const overTimeCostTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = overTimeCostTotal;
      } else if (headerObjValues[idx].value === "baseSalary") {
        const baseSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = baseSalaryTotal;
      } else if (headerObjValues[idx].value === "totalSalary") {
        const totalSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = totalSalaryTotal;
      } else if (headerObjValues[idx].value === "netSalary") {
        const netSalaryTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = netSalaryTotal;
      } else if (headerObjValues[idx].value === "termination") {
        const terminationTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = terminationTotal;
      } else if (headerObjValues[idx].value === "resignation") {
        const resignationTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = resignationTotal;
      } else if (headerObjValues[idx].value === "vacationAccrual") {
        const vacationAccuralsTotal = isEnglish
          ? getTotalSum(body, idx)
          : getTotalSum(body, headerObjValues.length - 1 - idx);
        sumOfValues[idx] = vacationAccuralsTotal;
      } else {
        sumOfValues[idx] = "";
      }
    }
    sumOfValues[0] = t("sumOfValues");
    if (isEnglish) {
      data = [...data, [], sumOfValues];
    } else {
      data = [...data, [], sumOfValues.reverse()];
    }
  }

  closeModal();
  return data;
};
