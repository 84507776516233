import moment from "moment/moment";

export let indemnityPdfFields = (t) => [
  {
    value: "EMPLOYEE_NAME",
    label: t("name"),
    active: true,
  },
  {
    value: "ID",
    label: t("id"),
    active: true,
  },
  {
    value: "DEPARTMENT",
    label: t("department"),
    active: true,
  },
  {
    value: "POSITION",
    label: t("position"),
    active: true,
  },
  {
    value: "Month",
    label: t("month"),
    active: true,
  },
  {
    value: "EMPLOYMENT_DATE",
    label: t("employmentDate"),
    active: true,
  },
  {
    value: "LEAVING_DATE",
    label: t("leavingDate"),
    active: true,
  },
  {
    value: "TOTAL_PAID_LEAVE_DAYS",
    label: t("totalPaidLeaveDays"),
    active: true,
  },
  {
    value: "TOTAL_SICK_DAYS",
    label: t("totalSickDays"),
    active: true,
  },
  {
    value: "ANNUAL_LEAVE_BALANCE",
    label: t("annualLeaveBalance"),
    active: true,
  },
  {
    value: "BASIC_SALARY",
    label: t("baseSalary"),
    active: true,
  },
  {
    value: "RESIGNATION_ACCURAL",
    label: t("resignationAccural"),
    active: true,
  },
  {
    value: "TERMINATION_ACCURAL",
    label: t("terminationAccural"),
    active: true,
  },
  {
    value: "VACAION_ACCURAL",
    label: t("vacationAccural"),
    active: true,
  },
];

export const normalizeData = (
  employee,
  myArray,
  isEnglish,
  month,
  indemnity,
) => {
  console.log("employee", employee);
  console.log("indemnity", indemnity);
  console.log("month", month);
  const normalizedEmployees = [];

  const fields = myArray
    .filter((field) => field.active)
    .map((field) => field.value);

  let modifiedEmployee = {};

  if (fields.includes("EMPLOYEE_NAME")) {
    modifiedEmployee["name"] = employee.fullName;
  }

  if (fields.includes("ID")) {
    modifiedEmployee["id"] = employee.referenceNumber
      ? employee.referenceNumber
      : employee.id;
  }

  if (fields.includes("Month")) {
    let _month = employee.month
      ? moment(employee.month).format("yyyy-MM")
      : month;
    console.log("month", _month);
    modifiedEmployee["month"] = _month;

    if (fields.includes("DEPARTMENT")) {
      modifiedEmployee["department"] = isEnglish
        ? employee.department.name
        : employee.department.nameAr;
    }

    if (fields.includes("POSITION")) {
      modifiedEmployee["position"] = isEnglish
        ? employee.position.name
        : employee.position.nameAr;
    }

    if (fields.includes("BASIC_SALARY")) {
      modifiedEmployee["baseSalary"] = Number(employee.baseSalary).toFixed(3);
    }

    if (fields.includes("EMPLOYMENT_DATE")) {
      modifiedEmployee["employmentDate"] = employee.employmentDate ?? "";
    }

    if (fields.includes("LEAVING_DATE")) {
      modifiedEmployee["leavingDate"] = employee.leavingDate ?? "";
    }

    if (fields.includes("TOTAL_PAID_LEAVE_DAYS")) {
      let leavedebits = employee.leaveBalance.leavedebits;
      console.log("leavedebits", leavedebits);
      let totalPaidLeaveDays = 0;

      if (leavedebits) {
        totalPaidLeaveDays = leavedebits.reduce((acc, curr) => {
          return curr.kind === "ANNUAL" || curr.kind === "CASUAL"
            ? acc + curr.amount
            : acc;
        }, 0);

        console.log("totalPaidLeaveDays", totalPaidLeaveDays);
        modifiedEmployee["totalPaidLeaveDays"] = totalPaidLeaveDays;
      }
    }

    if (fields.includes("TOTAL_SICK_DAYS")) {
      let sickDays = employee.leaveBalance.sickBalance;
      modifiedEmployee["totalSickDays"] = sickDays;
      console.log("sickDays", sickDays);
    }

    if (fields.includes("ANNUAL_LEAVE_BALANCE")) {
      let annualBalance = employee.leaveBalance.annualBalance;
      modifiedEmployee["annualLeaveBalance"] = annualBalance;
      console.log("annualBalance", annualBalance);
    }

    if (fields.includes("RESIGNATION_ACCURAL")) {
      let resignationAccural = indemnity.resignation;
      modifiedEmployee["resignationAccural"] = resignationAccural;
    }

    if (fields.includes("TERMINATION_ACCURAL")) {
      let terminationAccural = indemnity.termination;
      modifiedEmployee["terminationAccural"] = terminationAccural;
    }

    if (fields.includes("VACAION_ACCURAL")) {
      let vacationAccural = indemnity.accrual.vacation;
      modifiedEmployee["vacationAccural"] = vacationAccural;
    }
  }
  normalizedEmployees.push(modifiedEmployee);
  console.log("modifiedemployee", modifiedEmployee);
  console.log("normalizedEmployees", normalizedEmployees);
  return normalizedEmployees;
};

export const getHeaders = (employeeObj, t, isEnglish) => {
  if (employeeObj) {
    const headers = Object.keys(employeeObj);
    const translatedHeaders = headers.map((head) => t(head));

    if (isEnglish) {
      return translatedHeaders;
    } else {
      return translatedHeaders.reverse();
    }
  }
};

export const getBody = (employees, isEnglish) => {
  const employeeValues = employees.map((employee) => Object.values(employee));

  if (isEnglish) {
    return employeeValues;
  } else {
    let reversedValues = employeeValues.map((arrayValue) => {
      arrayValue.reverse();
      return arrayValue;
    });
    return reversedValues;
  }
};
