import {
  Card,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";

import React from "react";
import { connect } from "react-redux";
import { updateEmployee } from "../../../../../redux/actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const EmployeeSettings = ({ employee, updateEmployee }) => {
  const { t } = useTranslation("companyProfile");

  const [enableCheckinOutsideLocation, setEnableCheckinOutsideLocation] =
    useState(employee?.checkInOutsideLocation);

  const handleSwitchChange = () => {
    setEnableCheckinOutsideLocation(!enableCheckinOutsideLocation);

    updateEmployee({
      id: employee?.id,
      checkInOutsideLocation: !enableCheckinOutsideLocation,
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <Card style={{ minHeight: "20vh" }}>
          <CardHeader title={t("companyProfile:checkinDetails")} />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={enableCheckinOutsideLocation}
                onChange={() => handleSwitchChange()}
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="subtitle2" color="textPrimary">
                {t("companyProfile:enableCheckinOutsideLocation")}
              </Typography>
            }
          />
        </Card>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  updateEmployee,
};

export default connect(null, mapDispatchToProps)(EmployeeSettings);
