import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { Close, InsertDriveFile } from "@material-ui/icons";

import { getFileName } from "../../helpers/getFileName";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const LeaveFile = ({
  value,
  setValue,
  disabled = false,
  errors = "",
  removeFileState,
}) => {
  const { t } = useTranslation("common");
  const inputRef = useRef();

  const openInputWizard = () => inputRef?.current?.click();

  const handleChange = (event) => {
    setValue(event.target.files[0]);
  };

  const clearField = () => {
    setValue(null);
    if (inputRef?.current?.value) inputRef.current.value = "";
    if (removeFileState) removeFileState();
  };

  return (
    <>
      <FileBox>
        <Box onClick={openInputWizard}>
          <Grid
            item
            container
            direction={value ? "row" : "column"}
            alignItems="center"
            md={value ? 6 : 1}
          >
            <Grid item style={{ paddingTop: 2, paddingBottom: 2 }}>
              <InsertDriveFile />
            </Grid>
            <Grid item>
              {value && value.length > 0 ? (
                <Typography
                  style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
                >
                  {getFileName(value)}
                </Typography>
              ) : value && value.name ? (
                <Typography
                  style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
                >
                  {value.name}
                </Typography>
              ) : (
                <Typography
                  style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
                >
                  {t("addFile")}
                </Typography>
              )}
            </Grid>

            {value && (
              <Grid item>
                <StyledIconButton onClick={clearField}>
                  <Close />
                </StyledIconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </FileBox>

      {!!errors?.primaryImage?.message && (
        <FormHelperText error={!!errors?.primaryImage}>
          {errors?.primaryImage?.message}
        </FormHelperText>
      )}

      <HiddenField
        type="file"
        inputRef={inputRef}
        inputProps={{ accept: "image/*,.pdf" }}
        disabled={disabled}
        onChange={handleChange}
      />
    </>
  );
};

export default LeaveFile;

const HiddenField = styled(TextField)({ display: "none" });

const FileBox = styled(Box)(() => ({
  position: "relative",
  p: 1,
  cursor: "pointer",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  zIndex: 3,
  color: "black",
}));
