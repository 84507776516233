import { Add, Edit } from "@material-ui/icons";
//Material UI
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";

import Document from "./Document";
import { connect } from "react-redux";
//Redux
import { createDocuments } from "../../../../redux/actions";
//Moment
import moment from "moment";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
//Translation
import { useTranslation } from "react-i18next";

const Documents = ({ documents, createDocuments, locations }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const documentList = documents.map((document) => (
    <Document
      key={document.name}
      document={document}
      edit={edit}
      companyLocations={locations}
    />
  ));
  return (
    <Box clone minHeight={"400px"} paddingY={5} paddingX={matchesXS ? 4 : 10}>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h2" color="textPrimary">
              {t("companyProfile:companyDocuments")}
            </Typography>
            <Button
              disableRipple
              className={classes.addButton}
              variant="contained"
              color={edit ? "secondary" : "primary"}
              startIcon={isEnglish ? <Edit /> : null}
              endIcon={isEnglish ? null : <Edit />}
              onClick={() => setEdit(!edit)}
            >
              <Typography>
                {edit ? t("common:done") : t("common:edit")}
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className={classes.addButton}
              color="primary"
              variant="contained"
              endIcon={<Add />}
              onClick={() => {
                createDocuments([
                  {
                    name: "",
                    exp: moment(new Date()).format("YYYY-MM-DD"),
                  },
                ]);
                setEdit(true);
              }}
            >
              <Typography>{t("companyProfile:addDocument")}</Typography>
            </Button>
          </Box>
        </Grid>
        {documentList}
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ company }) => ({
  documents: company.documents,
  locations: company.locations,
});

const mapDispatchToProps = {
  createDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
