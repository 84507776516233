import { yupResolver } from "@hookform/resolvers/yup";
// layout
import { Box, Button, Grid, Typography } from "@material-ui/core";
//icons
import {
  ExitToAppRounded,
  PermIdentityOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
//Translation
import { useTranslation } from "react-i18next";
//Redux
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";

//Actions
import { register } from "../../../redux/actions";
import AuthenticationInput from "../../common/AuthenticationInput";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is a required field"),
  email: yup.string().required("Email is a required field").email(),
  password: yup.string().required("Password is a required field").min(8),
});

const Form = ({ signup, errorFields }) => {
  const { t } = useTranslation(["common", "register"]);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ email, fullName, password }) => {
    signup(
      {
        email,
        fullName,
        password1: password,
        password2: password,
        isCompanyOwner: true,
        isEmployee: false,
      },
      history
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} data-test="register-form">
        <Grid container justifyContent="space-between" spacing={2}>
          <Box clone pb={2}>
            <Grid item md={12}>
              <Typography variant="h2" color="primary">
                {t("register:signup")}
              </Typography>
            </Grid>
          </Box>
          <Grid item md={12} xs={12}>
            <Box clone width={"100%"}>
              <AuthenticationInput
                error={!!errors?.fullName}
                inputRef={register}
                name="fullName"
                label={t("common:fullName")}
                helperText={errors?.fullName?.message}
                icon={<PermIdentityOutlined />}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box clone width={"100%"}>
              <AuthenticationInput
                error={!!errors.email}
                inputRef={register}
                helperText={errors.email?.message}
                name="email"
                label={t("common:email")}
                icon={<MailOutlineIcon />}
              />
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box clone width={"100%"}>
              <AuthenticationInput
                name="password"
                error={!!errors.password}
                inputRef={register}
                helperText={errors.password?.message}
                label={t("register:password")}
                type={visible ? "text" : "password"}
                icon={
                  visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />
                }
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(!visible)}
              />
            </Box>
          </Grid>

          {errorFields.map((errorField, idx) => (
            <Box key={errorField.code + idx} clone width="100%">
              <Typography style={{ color: "red" }}>
                {errorField.message}
              </Typography>
            </Box>
          ))}

          <Grid item md={12} xs={12}>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ExitToAppRounded />}
              style={{
                color: "#FFF",
                backgroundColor: "#3D5E77",
              }}
            >
              {t("register:signup")}
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <Link to="/">
              <Typography style={{ color: "#4E9AF8" }}>
                {t("register:alreadyRegistered?")}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = ({ errors }) => ({
  errorFields: Object.values(errors ?? {}).flat(),
});

const mapDispatchToProps = {
  signup: register,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
