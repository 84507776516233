//Icons
import { Add, Edit } from "@material-ui/icons";
//Material UI
import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
//Redux
import { createPosition, updatePosition } from "../../../../../redux/actions";

//Components
import { FormInputField } from "../../../../common";
import { connect } from "react-redux";
//Styles
import useStyles from "../../styles";
//Translation
import { useTranslation } from "react-i18next";

const PositionForm = ({
  position,
  departmentId,
  createPosition,
  updatePosition,
  setOpen,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [positionState, setPosition] = useState(
    position ?? { name: "", nameAr: "" }
  );
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item container md={12} spacing={3} style={{ margin: 4 }}>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired
            label={t("common:name")}
            value={positionState.name}
            onChange={(e) =>
              setPosition({ ...positionState, name: e.target.value })
            }
            id="name"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <FormInputField
            isRequired
            label={t("common:arabicName")}
            value={positionState.nameAr}
            onChange={(e) =>
              setPosition({ ...positionState, nameAr: e.target.value })
            }
            id="arabicName"
            autoComplete="new-password"
          />
        </Grid>
      </Grid>
      <Box
        clone
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        paddingX={2}
        paddingY={2}
      >
        <Grid item md={12} sm={12} xs={11} style={{ margin: 10 }}>
          <Button
            disableRipple
            className={classes.actionButton}
            variant="contained"
            onClick={
              position
                ? () =>
                    updatePosition(
                      {
                        ...positionState,
                        positionId: position.id,
                        departmentId,
                      },
                      setOpen
                    )
                : () =>
                    createPosition({ ...positionState, departmentId }, setOpen)
            }
            endIcon={position ? <Edit /> : <Add />}
          >
            <Typography>
              {position ? t("common:save") : t("common:add")}
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

const mapDispatchToProps = {
  createPosition,
  updatePosition,
};

export default connect(null, mapDispatchToProps)(PositionForm);
