import {Box, Container, styled} from '@material-ui/core'

import React from 'react'

const StyledBox = styled(Box)(({theme, background, bgcolor}) => ({
  background: theme.palette[background],
}))

const Section = ({children, maxWidth, disableGutters = false, ...boxProps}) => (
  <StyledBox pt={5} pb={5} {...boxProps}>
    <Container disableGutters={disableGutters} maxWidth={maxWidth}>
      {children}
    </Container>
  </StyledBox>
)

export default Section
