import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  createSalaryModification,
  updateSalaryModification,
} from "../../../../redux/actions";

import DatePickerField from "../../../common/DatePickerField";
import FormInputField from "../../../common/FormInputField";
import { FormSelectInput } from "../../../common";
import LeaveFile from "../../../common/LeaveFile";
import { Save } from "@material-ui/icons";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ModificationForm = ({
  _title,
  modification,
  setModification,
  setClose,
  createSalaryModification,
  updateSalaryModification,
}) => {
  const { t } = useTranslation(["employeeProfile", "employeeSalary"]);
  const [sign, setSign] = useState(modification.amount >= 0 ? "+" : "-");
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid
        item
        container
        spacing={3}
        justifyContent="center"
        style={{ margin: 4 }}
      >
        <Grid item md={6} xs={12}>
          <FormInputField
            label={t("employeeSalary:description")}
            value={modification.reason}
            onChange={(e) =>
              setModification({ ...modification, reason: e.target.value })
            }
            id="reason"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container justifyContent="center">
            <Grid item md={2} xs={2}>
              <FormSelectInput
                label={"+/-"}
                value={sign}
                selectOptions={["+", "-"]}
                onChange={(e) => setSign(e)}
              />
            </Grid>
            <Grid item md={10} xs={10}>
              <FormInputField
                label={t("employeeSalary:amount")}
                type="number"
                helperText={t("employeeSalary:amountNote")}
                value={
                  _title === t("employeeProfile:earnings") ||
                  t("employeeSalary:addModification")
                    ? modification.amount
                    : -Math.abs(modification.amount)
                }
                onChange={(e) =>
                  setModification({ ...modification, amount: e.target.value })
                }
                id="amount"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <DatePickerField
            label={t("employeeSalary:month")}
            value={new Date(modification.month)}
            yearMonthView
            min={new Date("2020-01-01")}
            openTo="month"
            onChange={(e) =>
              setModification({
                ...modification,
                month: moment(e).format("yyyy-MM-DD"),
              })
            }
            disableToolbar={false}
          />
        </Grid>
        <Grid item md={12}>
          <LeaveFile
            value={modification.attachment}
            setValue={(newFile) =>
              setModification({ ...modification, attachment: newFile })
            }
            removeFileState={() =>
              setModification({ ...modification, attachment: "" })
            }
          />
        </Grid>
      </Grid>
      <Grid item md={12} xs={12} style={{ margin: 10 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width={"100%"}
        >
          <Button
            color="primary"
            variant="contained"
            endIcon={<Save />}
            onClick={() =>
              modification.id
                ? updateSalaryModification(
                    {
                      ...modification,
                      amount:
                        sign === "+"
                          ? modification.amount >= 0
                            ? modification.amount
                            : modification.amount * -1
                          : modification.amount >= 0
                          ? modification.amount * -1
                          : modification.amount,
                      month: moment(new Date(modification.month)).format(
                        "YYYY-MM-DD"
                      ),
                      attachment: modification.attachment,
                      removeAttachment: modification.attachment ? false : true,
                    },
                    setClose
                  )
                : createSalaryModification(
                    {
                      ...modification,
                      amount:
                        sign === "+"
                          ? modification.amount >= 0
                            ? modification.amount
                            : modification.amount * -1
                          : modification.amount >= 0
                          ? modification.amount * -1
                          : modification.amount,
                      month: moment(new Date(modification.month)).format(
                        "YYYY-MM-DD"
                      ),
                      attachment: modification.attachment,
                      removeAttachment: false,
                    },
                    setClose
                  )
            }
          >
            <Typography>{t("employeeSalary:save")}</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  createSalaryModification,
  updateSalaryModification,
};

export default connect(null, mapDispatchToProps)(ModificationForm);
