import { Button, Typography } from "@material-ui/core";

import React from "react";
import { Save } from "@material-ui/icons";
import { connect } from "react-redux";
import moment from "moment/moment";
import { updateSalaryModification } from "../../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const UpdateModificationButton = ({
  modification,
  isEarning,
  updateSalaryModification,
  handleClose,
}) => {
  const { t } = useTranslation(["employeeSalary"]);

  return (
    <Button
      color="primary"
      variant="contained"
      endIcon={<Save />}
      onClick={() =>
        updateSalaryModification(
          {
            ...modification,
            amount: isEarning
              ? modification.amount >= 0
                ? modification.amount
                : modification.amount * -1
              : modification.amount >= 0
              ? modification.amount * -1
              : modification.amount,
            month: moment(new Date(modification.month)).format("YYYY-MM-DD"),
            attachment: modification.attachment,
            removeAttachment: modification.attachment ? false : true,
          },
          handleClose
        )
      }
    >
      <Typography>{t("employeeSalary:save")}</Typography>
    </Button>
  );
};

const mapDispatchToProps = {
  updateSalaryModification,
};

export default connect(null, mapDispatchToProps)(UpdateModificationButton);
