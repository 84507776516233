import { Avatar, Box, Typography } from "@material-ui/core";
import { CustomTableCell, CustomTableRow } from "../../../../components/common";

import AttendanceCheckoutCell from "./AttendanceCheckoutCell";
import React from "react";
import { calculateAttendanceDuration } from "../../../helpers/calculateAttendanceDuration";
import { distanceToDisplay } from "../../../helpers/distanceToDisplay";
import getCorrectTimezone from "../../../../utils";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";

const AttendanceRow = ({
  attendance,
  employerDashboard,
  employeeDashboard,
  employeeDashboardProfile,
  employeeWithAccess,
  setAttendanceForModal,
  setOpenModal,
  hasLocation,
  handlePendingCheckout,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";

  const checkInLatitude = attendance?.checkInLatitude;
  const checkInLongitude = attendance?.checkInLongitude;
  const locationLatitude = attendance?.location?.latitude;
  const locationLongitude = attendance?.location?.longitude;

  // prettier-ignore
  const attendanceLocationName = attendance.location ? isEnglish ? attendance.location.name : attendance.location.nameAr : "-"

  // prettier-ignore
  const checkInDistanceLabel = distanceToDisplay(checkInLatitude,checkInLongitude,locationLatitude,locationLongitude,hasLocation,t);

  const checkOutLatitude = attendance?.checkOutLatitude;
  const checkOutLongitude = attendance?.checkOutLongitude;

  // prettier-ignore
  const checkOutDistanceLabel = distanceToDisplay(checkOutLatitude, checkOutLongitude,locationLatitude,locationLongitude, hasLocation,t);

  const attendanceDuration = calculateAttendanceDuration(attendance);

  const hasNotCheckedOut = attendance.checkOut === null;

  const isCompanyOwner = employerDashboard;

  const isCheckoutPending = isCompanyOwner && hasNotCheckedOut;
  // prettier-ignore
  const isEmployeeWithAccess = employeeDashboard && employeeWithAccess && hasNotCheckedOut;
  const isEmployee = employeeDashboardProfile && hasNotCheckedOut;

  const handleOpen = () => {
    setAttendanceForModal({
      ...attendance,
      checkIn: moment(getCorrectTimezone(attendance.checkIn)),
    });
    setOpenModal(true);
  };

  return (
    <CustomTableRow key={attendance.id}>
      <CustomTableCell align={isEnglish ? "left" : "right"}>
        {moment(getCorrectTimezone(attendance.checkIn)).format("LLL")}
      </CustomTableCell>

      <AttendanceCheckoutCell
        attendance={attendance}
        isCheckoutPending={isCheckoutPending}
        isEmployeeWithAccess={isEmployeeWithAccess}
        isEmployee={isEmployee}
        handleOpen={handleOpen}
        handlePendingCheckout={handlePendingCheckout}
      />

      <CustomTableCell>
        <Typography>{attendanceLocationName}</Typography>
      </CustomTableCell>

      <CustomTableCell align={isEnglish ? "left" : "right"}>
        <Typography>{checkInDistanceLabel}</Typography>
      </CustomTableCell>
      <CustomTableCell align={isEnglish ? "left" : "right"}>
        <Typography>{checkOutDistanceLabel}</Typography>
      </CustomTableCell>

      <CustomTableCell>{attendanceDuration}</CustomTableCell>
      {employerDashboard && (
        <>
          <CustomTableCell>
            {attendance.image ? (
              <Box clone height={"40px"} width={"40px"}>
                <Avatar alt="employee-checkin" src={attendance.image} />
              </Box>
            ) : (
              <Typography>-</Typography>
            )}
          </CustomTableCell>
          <CustomTableCell>
            {attendance.imageCheckout ? (
              <Box clone height={"40px"} width={"40px"}>
                <Avatar
                  alt="employee-checkout"
                  src={attendance.imageCheckout}
                />
              </Box>
            ) : (
              <Typography>-</Typography>
            )}
          </CustomTableCell>
        </>
      )}
      <CustomTableCell>
        {isCompanyOwner && <Edit onClick={handleOpen} />}
      </CustomTableCell>
    </CustomTableRow>
  );
};

export default AttendanceRow;
