import ModificationTable from "./ModificationTable";
import React from "react";
import { useTranslation } from "react-i18next";

const RecurringEarnings = ({ employee, recurringEarnings, enableActions }) => {
  const { t } = useTranslation(["common", "employeeSalary", "employeeProfile"]);

  const headerLabels = [
    t("common:reason"),
    t("common:amount"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:file"),
    t("common:edit"),
    t("common:delete"),
  ];

  return (
    <ModificationTable
      employee={employee}
      label={t("employeeProfile:recurringEarnings")}
      actionLabel={t("employeeProfile:addRecurringEarning")}
      headerLabels={headerLabels}
      data={recurringEarnings}
      enableActions={enableActions}
      isRecurring
      isEarning
    />
  );
};

export default RecurringEarnings;
