import { Button, Card, CardHeader } from "@material-ui/core";
import React, { useState } from "react";

import AddPermission from "./AddPermission";
import Row from "./Row";
import Table from "../../../../common/Table";
import { connect } from "react-redux";
import { fetchEmployeesWithPermissions } from "../../../../../../redux/actions";
import { useTranslation } from "react-i18next";

const PortalAccess = ({
  permissionEmployees,
  fetchEmployeesWithPermissions,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  const [openModal, setOpenModal] = useState(false);

  const labels = [
    t("common:permissions"),
    t("common:branches"),
    t("common:departments"),
    t("common:user"),
    t("common:action"),
  ];

  const tableBody = permissionEmployees?.map((employee, i) => {
    return <Row employee={employee} key={i} />;
  });
  return (
    <>
      <Card>
        <CardHeader
          title={t("companyProfile:portalAccessPermissions")}
          subheader={t("companyProfile:portalAccessPermissionsDescription")}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              {t("add")}
            </Button>
          }
        />

        <Table
          data={permissionEmployees}
          labels={labels}
          body={tableBody}
          hasActionLabel
        />
      </Card>
      <AddPermission openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

const mapStateToProps = ({ employees, company }) => ({
  permissionEmployees: employees.permissionEmployees,
});

const mapDispatchToProps = {
  fetchEmployeesWithPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalAccess);
