import { Add } from "@material-ui/icons";
import AddCredit from "./AddCredit";
import { Button } from "@material-ui/core";
import React from "react";
import { RequestsTable } from "../../../common";
import { connect } from "react-redux";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const OvertimeCredits = ({ employee, overtimeCredits, month }) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);

  const headerLabels = [
    t("id"),
    t("date"),
    t("overtimeHours"),
    t("overtimeCosts"),
  ];

  let overtimeCreditsData = overtimeCredits
    ?.filter(
      (record) => month === moment(new Date(record.date)).format("yyyy-MM")
    )

    .map((record) => {
      return {
        id: record.id,
        date: moment(new Date(record.date)).format("DD-MM-yyyy"),
        amount: record.amount,
        cost: Number(record.overtimeCost).toFixed(3),
      };
    });

  return (
    <>
      <RequestsTable
        title={t("overtimeCredits")}
        data={overtimeCreditsData}
        labels={headerLabels}
        action={
          <Button
            size="small"
            endIcon={<Add />}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => setOpen(true)}
          >
            {t("creditAdd")}
          </Button>
        }
      />
      <AddCredit employee={employee} open={open} setOpen={setOpen} />
    </>
  );
};

const mapStateToProps = ({ employee }) => ({
  overtimeCredits: employee.overtimeCredits,
});

export default connect(mapStateToProps)(OvertimeCredits);
