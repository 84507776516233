//Components
import {
  AddFileButton,
  DatePickerField,
  DocumentFile,
  FormInputField,
  FormSelectInput,
} from "../../../common";
//Material UI
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
//Actions
import {
  createDocumentFiles,
  deleteDocument,
  deleteDocumentFile,
  updateDocument,
  updateDocumentFile,
} from "../../../../redux/actions";

import { Delete } from "@material-ui/icons";
//Redux
import { connect } from "react-redux";
//Date
import moment from "moment";
//Translation
import { useTranslation } from "react-i18next";

const Document = ({
  document,
  deleteDocument,
  updateDocument,
  createDocumentFiles,
  updateDocumentFile,
  deleteDocumentFile,
  companyLocations,
  edit,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";

  const [documentState, setDocument] = useState({
    ...document,
    location: document?.location?.id ?? null,
  });

  useEffect(() => {
    setDocument({
      ...document,
      location: document?.location?.id ?? null,
    });
  }, [document]);

  const files = document.files.map((doc) => (
    <DocumentFile
      key={`file ${doc.id}`}
      edit={edit}
      file={doc}
      updateFile={(e) =>
        updateDocumentFile({
          id: doc.id,
          file: e.target.files[0],
          documentId: doc.document.id,
        })
      }
      deleteFile={() =>
        deleteDocumentFile({ id: doc.id }, { documentId: document.id })
      }
    />
  ));

  return (
    <>
      <Box
        clone
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={12} xs={12}>
          <Typography variant="h3">{t("common:document")}</Typography>
          {edit && (
            <IconButton onClick={() => deleteDocument(document)}>
              <Delete color="error" />
            </IconButton>
          )}
        </Grid>
      </Box>
      <Grid item md={6} xs={12}>
        <FormInputField
          isRequired
          label={t("companyProfile:documentName")}
          name="documentName"
          placeholder={t("companyProfile:enterName")}
          disabled={!edit}
          onChange={(e) => {
            setDocument({ ...document, name: e.target.value });
          }}
          onBlur={(e) => updateDocument({ ...document, name: e.target.value })}
          value={documentState.name}
          id="documentName"
          autoComplete="new-password"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <DatePickerField
          isRequired
          disabled={!edit}
          label={t("companyProfile:documentExpiration")}
          name="documentexp"
          onChange={(e) =>
            setDocument({
              ...document,
              exp: moment(e).format("yyyy-MM-DD"),
            })
          }
          onBlur={(e) =>
            updateDocument({
              ...document,
              exp: moment(e).format("yyyy-MM-DD"),
            })
          }
          value={documentState.exp}
          disableToolbar={false}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormSelectInput
          disabled={!edit}
          value={documentState.location}
          selectOptions={companyLocations.map((location) => ({
            label: isEnglish ? location.name : location.nameAr,
            value: location.id,
          }))}
          onChange={(e) => {
            setDocument({ ...document, location: e });
            updateDocument({
              ...document,
              locationId: e,
            });
          }}
          label={t("location")}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography>
          {t("common:attachments")} ({files.length})
        </Typography>
      </Grid>
      <Grid container>{files}</Grid>
      {edit && (
        <Grid item md={12} xs={12}>
          <AddFileButton
            fileId={`document_id${document.id}`}
            onChange={createDocumentFiles}
            documentId={document.id}
          />
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateDocument,
  createDocumentFiles,
  updateDocumentFile,
  deleteDocumentFile,
  deleteDocument,
};

export default connect(null, mapDispatchToProps)(Document);
