import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";

// Translations
import { useTranslation } from "react-i18next";

const LocationNotLoaded = ({ onClick }) => {
  const { t } = useTranslation("common");
  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Box paddingBottom={2}>
          <Typography align="center">{t("locationLoadFail")}</Typography>
        </Box>
      </Grid>
      <Grid item container justifyContent="flex-end" md={12} sm={12} xs={12}>
        <Button variant="contained" onClick={onClick}>
          {t("close")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default LocationNotLoaded;
