// Action Types
import {
  ADD_DEPARTMENT,
  ADD_LOCATION,
  ADD_POSITION,
  DEPARTMENTS,
  EDIT_DEPARTMENT,
  EDIT_LOCATION,
  EDIT_POSITION,
  GET_ERRORS,
  LOCATIONS,
  POSITIONS,
  REMOVE_DEPARTMENT,
  REMOVE_POSITION,
} from "./types";
//Mutations
import {
  CREATE_DEPARTMENT,
  CREATE_LOCATION,
  CREATE_POSITION,
  DELETE_DEPARTMENT,
  DELETE_POSITION,
  UPDATE_DEPARTMENT,
  UPDATE_LOCATION,
  UPDATE_POSITION,
} from "./Mutations";
//Queries
import { DEPARTMENTS_QUERY, LOCATIONS_QUERY, POSITIONS_QUERY } from "./Queries";

// Graphql
import client from "./client";

export const fetchLocations = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: LOCATIONS_QUERY,
      variables: null,
    });

    const locations = res.data.locations;
    dispatch({ type: LOCATIONS, payload: locations });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
export const createLocation = (location, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_LOCATION,
      variables: location,
    });
    const resLocation = res.data.updateOrCreateLocation.location;
    dispatch({ type: ADD_LOCATION, payload: resLocation });
    if (setOpen) setOpen(false);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateLocation = (location, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_LOCATION,
      variables: location,
    });
    const resLocation = res.data.updateOrCreateDepartment.department;
    dispatch({ type: EDIT_LOCATION, payload: resLocation });
    if (setOpen) setOpen(false);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchDepartments = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: DEPARTMENTS_QUERY,
      variables: null,
    });

    const departments = res.data.departments;
    dispatch({ type: DEPARTMENTS, payload: departments });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchPositions = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: POSITIONS_QUERY,
      variables: null,
    });

    const positions = res.data.positions;
    dispatch({ type: POSITIONS, payload: positions });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createDepartment = (department, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_DEPARTMENT,
      variables: department,
    });
    const resDepartment = res.data.updateOrCreateDepartment.department;
    dispatch({ type: ADD_DEPARTMENT, payload: resDepartment });

    if (setOpen) setOpen(false);
    return resDepartment;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteDepartment = (department) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_DEPARTMENT,
      variables: department,
    });
    const status = res.data.deleteDepartment.status;
    if (status) {
      dispatch({ type: REMOVE_DEPARTMENT, payload: department });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deletePosition = (position, departmentId) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_POSITION,
      variables: position,
    });
    const status = res.data.deletePosition.status;
    if (status) {
      dispatch({
        type: REMOVE_POSITION,
        payload: { ...position, department: { id: departmentId } },
      });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createPosition = (position, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_POSITION,
      variables: position,
    });
    const resPosition = res.data.updateOrCreatePosition.position;
    dispatch({ type: ADD_POSITION, payload: resPosition });
    if (setOpen) setOpen(false);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateDepartment = (department, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_DEPARTMENT,
      variables: department,
    });
    const resDepartment = res.data.updateOrCreateDepartment.department;
    dispatch({ type: EDIT_DEPARTMENT, payload: resDepartment });
    if (setOpen) setOpen(false);
    return resDepartment;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updatePosition = (position, setOpen) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_POSITION,
      variables: position,
    });
    const resPosition = res.data.updateOrCreatePosition.position;
    dispatch({ type: EDIT_POSITION, payload: resPosition });
    if (setOpen) setOpen(false);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
