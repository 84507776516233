import React from "react";
import { makeStyles, TableCell, TableHead, TableSortLabel } from "@material-ui/core";

import { CustomTableRow } from "../../../common";


const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHeaders({ order, orderBy, onSorting, headCells, isEnglish }) {
  const handleSort = (property) => onSorting(property);

  const classes = useStyles();

  return (
    <TableHead>
      <CustomTableRow>
        <TableCell
          style={{
            whiteSpace: "nowrap",
            borderBottom: "none",
          }}
          align={isEnglish ? "left" : "right"}
        />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              whiteSpace: "nowrap",
              borderBottom: "none",
            }}
            align={isEnglish ? "left" : "right"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </CustomTableRow>
    </TableHead>
  );
}

export default EnhancedTableHeaders;