import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Input,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx/xlsx.mjs";
import { FormSelectInput } from "../../../../components/common";
import { EmployeesForm } from "./Forms/employeesForm";
import {
  CloudDownloadOutlined,
  CloudUploadRounded,
  CloudUploadTwoTone,
} from "@material-ui/icons";
import { VacationsForm } from "./Forms/vacationsForm";
import { uploadOptions } from "../../../../components/common/DropDownsData";
import { PayrollForm } from "./Forms/payrollForm";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";

const UploadComponent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "upload"]);
  const isEnglish = language === "en";

  const fileRef = useRef(null);

  const [fileData, setFileData] = useState(null);
  const [uploadData, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [response, setResponse] = useState(false);
  const [faultyForms, setFaultyForms] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const ab = await file.arrayBuffer();
    const workbook = XLSX.read(ab);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(worksheet, {
      raw: false,
      dateNF: "yyyy-mm-dd",
    });
    const parsedData = data.map((row) => {
      const parsedRow = {};
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const value = row[key];
          parsedRow[key] =
            value.toLowerCase() === "true"
              ? true
              : value.toLowerCase() === "false"
              ? false
              : value;
        }
      }
      return parsedRow;
    });

    setFileData(parsedData);
    setData(parsedData);
    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    const getFaultyForms = () => {
      let newData = [];
      if (response !== true && response !== false) {
        const employeesWithErrors = Object.keys(response?.details);
        if (employeesWithErrors === "error") {
          setFaultyForms(fileData);
        } else {
          employeesWithErrors.forEach((employee) => {
            const employeeData = fileData.find((data, idx) => {
              if (idx === parseInt(employee)) {
                return data;
              } else {
                return null;
              }
            });
            newData.push(employeeData);
          });
        }
      }
      setFaultyForms(newData);
    };
    getFaultyForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const forms = (option, fileData, upload = uploadData) => {
    if (option === "Employees" && fileData != null) {
      let Form;
      try {
        Form = fileData.map((data, idx) => {
          return (
            <EmployeesForm
              data={data}
              idx={idx}
              uploadData={upload}
              setData={setData}
              setDisabled={setDisabled}
            />
          );
        });
        return Form;
      } catch (e) {
        console.log(e);
      }
    } else if (option === "Vacations" && fileData != null) {
      let Form;
      try {
        Form = fileData.map((data, idx) => {
          return (
            <VacationsForm
              data={data}
              idx={idx}
              uploadData={upload}
              setData={setData}
              setDisabled={setDisabled}
            />
          );
        });
        return Form;
      } catch (e) {
        console.log(e);
      }
    } else if (option === "Payroll" && fileData != null) {
      let Form;
      try {
        Form = fileData.map((data, idx) => {
          return (
            <PayrollForm
              data={data}
              idx={idx}
              uploadData={upload}
              setData={setData}
              setDisabled={setDisabled}
            />
          );
        });
        return Form;
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = `/downloadTemplates/${option.toLowerCase()}.xlsx`;
    anchor.download = `${option.toLowerCase()}.xlsx`;
    anchor.click();
  };

  const company_id = useSelector((state) => state.company.profile)?.id;

  // let submitData = { company: company_id, type: option, data: uploadData };
  const createFormData = (data, type, company) => {
    let formData = new FormData();
    formData.append("company", company);
    formData.append("type", type);
    formData.append("data", JSON.stringify(data));
    data.forEach((d, idx) => {
      if (d.hasOwnProperty("civilId_document")) {
        formData.append(`${d.id}_civilId`, d.civilId_document);
      }
      if (d.hasOwnProperty("passport_document")) {
        formData.append(`${d.id}_passport`, d.passport_document);
      }
      if (d.hasOwnProperty("document")) {
        formData.append(`${idx}_document`, d.document);
      }
    });
    return formData;
  };
  // prettier-ignore
  const handleSubmit = () => {
    // Send modified data to the backend
    const token = localStorage.getItem("token");

    let formData = createFormData(uploadData, option, company_id);
    // Example code to send data to the backend:
    fetch(`${process.env.REACT_APP_BE_URL}submit_data/`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: formData,
      
    }).then((response) => {
      if(response.ok){
        setResponse(true);
      } else {
        response.json().then((data) => {
        setResponse(data);});
      }
    })
  };
  const handleSuccess = () => {
    return (
      <Card style={{ marginTop: "10px" }}>
        <Box paddingX={2} paddingY={1}>
          <Alert severity="success">
            {t(`${option}`)}+{t("upload:success")}
          </Alert>
        </Box>
      </Card>
    );
  };
  const handleFailure = (data) => {
    let details = data?.details;
    return (
      <Card style={{ marginTop: "10px" }}>
        <Box paddingX={2} paddingY={1}>
          <Alert
            severity="error"
            style={{ borderRadius: "10px", marginTop: "5px" }}
          >
            {t("upload:failure")}
          </Alert>
          <List>
            {Object.entries(details).map(([key, value], i) => (
              <ListItem style={{ color: "red" }} key={key}>
                {i + 1} - {value}
              </ListItem>
            ))}
          </List>
        </Box>
      </Card>
    );
  };
  return (
    <>
      <Card>
        <Box paddingX={2} paddingY={1}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <Typography color="primary">
                {t("upload:uploadOptions")}
              </Typography>
              <FormSelectInput
                disabled={fileData ? true : false}
                selectOptions={uploadOptions.map((option) => ({
                  value: option.value,
                  label: isEnglish ? option.name : option.nameAr,
                }))}
                onChange={(e) => {
                  setOption(e);
                  setOpen(true);
                }}
                value={option ? option : ""}
              />
            </Grid>
            {!response && option && open && (
              <Grid container direction="row" item lg={12} xs={12}>
                <Grid container direction="column" item sx={12} md={6} lg={6}>
                  <Typography color="primary" style={{ marginTop: "10px" }}>
                    {t("upload:uploadFile")}
                  </Typography>
                  <Input
                    type="file"
                    style={{
                      marginTop: "17px",
                      display: "none",
                    }}
                    onChange={(file) => {
                      handleFileChange(file);
                      setLoading(true);
                    }}
                    inputRef={fileRef}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => fileRef.current.click()}
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "200px",
                    }}
                    endIcon={
                      <CloudUploadTwoTone
                        style={{ marginInline: "5px", scale: "1.25" }}
                      />
                    }
                  >
                    {t("upload:chooseFile")}
                  </Button>

                  {option === "Employees" && t("upload:employeeUploadHelper")}
                  {option === "Vacations" && t("upload:vacationUploadHelper")}
                  {option === "Payroll" && t("upload:payrollUploadHelper")}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Grid item md={6} xs={12}>
                    <Typography color="primary" style={{ marginTop: "10px" }}>
                      {t("upload:downloadTemplate")}
                    </Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleDownload}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        width: "200px",
                      }}
                      endIcon={
                        <CloudDownloadOutlined style={{ scale: "1.25" }} />
                      }
                    >
                      {t("upload:downloadTemplate")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Card>
      {loading && (
        <Box
          bgcolor={"white"}
          padding={2}
          marginTop={2}
          borderRadius={15}
          boxShadow={2}
        >
          <Grid container justifyContent="center">
            <Typography variant="h6" color="primary">
              {t("upload:loading")}
            </Typography>
          </Grid>
        </Box>
      )}
      {response === true ? (
        handleSuccess()
      ) : (
        <>
          {response !== false && response !== true && (
            <Grid>
              <Grid>{handleFailure(response)}</Grid>
              <Grid>
                {faultyForms &&
                  forms(option, faultyForms, faultyForms).map((form, idx) => (
                    <Grid item lg={12} xs={12}>
                      <Box
                        marginTop={1}
                        marginBottom={1}
                        borderRadius={15}
                        paddingY={2}
                        bgcolor={"white"}
                        boxShadow={2}
                        key={idx}
                      >
                        {form}
                        {idx === faultyForms.length - 1 && (
                          <Grid
                            container
                            style={{ marginTop: "20px", marginBottom: "10px" }}
                            justifyContent="center"
                            item
                            lg={12}
                            xs={12}
                          >
                            <Button
                              style={{
                                color: "white",
                                backgroundColor: "#7cc777",
                                "&:hover": {
                                  backgroundColor: "#7cc777",
                                },
                                width: "200px",
                                fontSize: "18px",
                              }}
                              disabled={disabled}
                              variant="contained"
                              onClick={handleSubmit}
                              endIcon={<CloudUploadRounded />}
                            >
                              {t("upload:submit")}
                            </Button>
                          </Grid>
                        )}
                      </Box>
                      {idx < faultyForms.length - 1 && (
                        <Divider
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          <Grid>
            {response === false && fileData && fileData.length === 0 && (
              <Grid item lg={12} xs={12}>
                <Box
                  marginTop={1}
                  marginBottom={1}
                  borderRadius={15}
                  paddingY={2}
                  bgcolor={"white"}
                  boxShadow={2}
                >
                  <Grid
                    container
                    style={{ marginTop: "20px", marginBottom: "10px" }}
                    justifyContent="center"
                  >
                    <Typography variant="h6" color="primary">
                      {t("upload:noData")}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid>
            {response === false &&
              option &&
              fileData != null &&
              forms(option, fileData).map((form, idx) => (
                <Grid item lg={12} xs={12}>
                  <Box
                    marginTop={1}
                    marginBottom={1}
                    borderRadius={15}
                    paddingY={2}
                    bgcolor={"white"}
                    boxShadow={2}
                  >
                    {form}
                    {idx === fileData.length - 1 && (
                      <Grid
                        container
                        style={{ marginTop: "20px", marginBottom: "10px" }}
                        justifyContent="center"
                        item
                        lg={12}
                        xs={12}
                      >
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#7cc777",
                            "&:hover": {
                              backgroundColor: "#7cc777",
                            },
                            width: "200px",
                            fontSize: "18px",
                          }}
                          disabled={disabled}
                          variant="contained"
                          onClick={handleSubmit}
                          endIcon={<CloudUploadRounded />}
                        >
                          {t("upload:submit")}
                        </Button>
                      </Grid>
                    )}
                  </Box>
                  {idx < fileData.length - 1 && (
                    <Divider
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};
export default UploadComponent;
