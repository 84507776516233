import {useEffect, useState} from 'react'

const useGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    allowed: false,
    coordinates: {latitude: '', longitude: ''},
    locationId: '',
  })

  const onSuccess = location => {
    setLocation({
      loaded: true,
      allowed: true,
      coordinates: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      },
      locationId: location.locationId,
    })
  }

  const onError = error => {
    setLocation({
      loaded: true,
      error,
    })
  }

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation is not supported in this browser',
      })
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError)
  }, [])
  return {location, setLocation}
}

export default useGeoLocation
