import React, { useState } from "react";

import { DatePickerField } from "../../common";
import ExpiringDocumentsModal from "../../common/ExpiringDocumentsModal";
import HomeCard from "../../common/HomeCard";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchExpiringCompanyDocuments } from "../../../redux/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CompanyDocuments = ({
  companyDocuments,
  fetchExpiringCompanyDocuments,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home"]);

  const isEnglish = language === "en";

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [expMonth, setExpMonth] = useState(null);

  const handleChange = (e) => {
    fetchExpiringCompanyDocuments({ exp: moment(e).format("yyyy-MM-DD") });
    setExpMonth(e);
  };

  const labels = [
    t("common:documentReferenceNumber"),
    t("common:documentName"),
    t("common:documentExp"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={i} />
  ));

  const data = companyDocuments.map((document) => ({
    documentReferenceNumber: document.referenceNumber,
    documentName: document.name,
    exp: moment(new Date(document.exp)).format("DD-MM-yyyy"),
  }));

  const rows = data.map((entry, i) => {
    return (
      <TableRow
        onClick={() => {
          setModalData(entry);
          setOpenModal(true);
        }}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={4} align={isEnglish ? "left" : "right"}>
          {entry.documentReferenceNumber}
        </RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.documentName}</RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.exp}</RequestTableCell>
      </TableRow>
    );
  });

  return (
    <HomeCard
      title={t("home:documentsAboutToExpireCompany")}
      secondaryAction={
        <DatePickerField
          disableToolbar="false"
          yearMonthView
          openTo="month"
          onChange={(e) => handleChange(e)}
          label={expMonth ? t("home:month") : t("home:nextThreeMonth")}
          value={expMonth && expMonth}
        />
      }
    >
      <StyledHomeTable header={header} body={rows} data={data} key={header} />
      <ExpiringDocumentsModal
        isEnglish={isEnglish}
        openModal={openModal}
        setOpenModal={setOpenModal}
        request={modalData}
        t={t}
      />
    </HomeCard>
  );
};

const mapStateToProps = ({ company }) => ({
  companyDocuments: company.expiringCompanyDocuments,
});

const mapDispatchToProps = {
  fetchExpiringCompanyDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDocuments);
