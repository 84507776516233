import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CompanyStructureModal, Section } from "../../common";
import React, { useState } from "react";

import Field from "./Field";
import MultilineInput from "../../common/MultilineInput";
import { connect } from "react-redux";
import moment from "moment";
import { updateOvertimeRequest } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const Overtime = ({
  leave,
  request,
  updateOvertimeRequest,
  user,
  employeeProfile,
  requests,
}) => {
  const { t } = useTranslation("requests");

  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [notes, setNotes] = useState("");

  const hasHrAccess =
    user.isEmployee && employeeProfile && employeeProfile.hrAccess;

  const getAmount = () => {
    return `${leave?.amount} ${t("hours")}`;
  };

  return (
    <>
      <Section>
        <Box clone bgcolor="white" borderRadius={10} marginY={10}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography variant="h3" color="primary">
                {t("overtimeDetails")}
              </Typography>
            </Grid>

            <Field
              label={t("date")}
              value={moment(new Date(leave?.date)).format("DD-MM-yyyy")}
            />

            <Field label={t("amount")} value={getAmount()} />

            {(hasHrAccess || user.isCompanyOwner) && (
              <>
                {request.status === "PENDING" && (
                  <Grid item md={12} xs={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box clone marginRight={1}>
                        <Button
                          onClick={() => setOpen(true)}
                          variant="contained"
                          color="primary"
                        >
                          <Typography>{t("approve")} </Typography>
                        </Button>
                      </Box>
                      <Box clone marginRight={1}>
                        <Button
                          onClick={() => setOpenDecline(true)}
                          variant="contained"
                          color="secondary"
                        >
                          <Typography>{t("decline")} </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
        <CompanyStructureModal
          open={open}
          onClose={() => setOpen(false)}
          fadeIn={open}
          title={t("approveOvertimeRequest")}
        >
          <Grid container direction="column">
            <Grid item md={12} style={{ margin: 10 }}>
              <MultilineInput
                fullWidth
                multiline
                rows={2}
                label={t("notes")}
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder="optional"
              />
            </Grid>
            <Grid item md={12} style={{ margin: 10 }}>
              <Box
                paddingY={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    updateOvertimeRequest(
                      {
                        employeeId: request.employee.id,
                        id: request.id,
                        status: "APPROVED",
                        notes,
                      },
                      () => setOpen(false)
                    )
                  }
                >
                  <Typography>{t("yes")} </Typography>
                </Button>

                <Button
                  onClick={() => setOpen(false)}
                  variant="contained"
                  color="secondary"
                >
                  <Typography>{t("no")} </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CompanyStructureModal>
        <CompanyStructureModal
          open={openDecline}
          onClose={() => setOpenDecline(false)}
          fadeIn={openDecline}
          title={t("declineOvertime")}
        >
          <Grid container direction="column">
            <Grid item md={12} style={{ margin: 4 }}>
              <MultilineInput
                fullWidth
                multiline
                rows={2}
                label={t("notes")}
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder="optional"
              />
            </Grid>
            <Grid item md={12} style={{ margin: 4 }}>
              <Box
                paddingY={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateOvertimeRequest(
                      {
                        employeeId: request.employee.id,
                        id: request.id,
                        status: "DECLINED",
                        notes,
                      },
                      () => setOpenDecline(false)
                    );
                  }}
                >
                  <Typography>{t("yes")} </Typography>
                </Button>

                <Button
                  onClick={() => setOpenDecline(false)}
                  variant="contained"
                  color="secondary"
                >
                  <Typography>{t("no")} </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CompanyStructureModal>
      </Section>
    </>
  );
};

const mapStateToProps = ({ auth, employee }) => ({
  user: auth.user,
  employeeProfile: employee.profile,
});

const mapDispatchToProps = {
  updateOvertimeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overtime);
